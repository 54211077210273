import React from 'react';

import { DateInput } from '@summer/ui-components';

const EmployerStartDateQuestion = ({
  formik: { values, errors, setFieldValue, handleBlur, touched, isSubmitting },
}) => {
  return (
    <DateInput
      name="employerStartDate"
      value={values.employerStartDate}
      error={errors.employerStartDate}
      touched={touched.employerStartDate}
      onChange={setFieldValue}
      onBlur={handleBlur}
      isSubmitting={isSubmitting}
      label="Start Date"
    />
  );
};

export default EmployerStartDateQuestion;
