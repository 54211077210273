import React from 'react';
import styled from 'styled-components';

import InputContainer, { FormInput } from '../Input';

import { COLORS } from '../../../helpers/constants/styleguide';

class TextInput extends React.Component {
  static defaultProps = {
    disabled: false,
    type: 'text',
    onFocus: () => {},
    onBlur: () => {},
    placeholder: '',
  };

  renderInputText = handleFocusChange => {
    const {
      placeholder,
      onChange,
      value,
      name,
      maxLength,
      onBlur,
      type,
      disabled,
      validate,
      autoComplete,
      readOnly,
      inputMode = 'text',
      noFormik,
      onKeyPress,
    } = this.props;

    return (
      <Input
        id={`${name}-input`}
        as={noFormik && 'input'}
        inputMode={inputMode}
        type={type}
        name={name}
        maxLength={maxLength}
        validate={validate}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={handleFocusChange}
        disabled={disabled}
        autoComplete={autoComplete}
        readOnly={readOnly}
        onKeyPress={onKeyPress}
        onBlur={e => {
          handleFocusChange();
          onBlur(e);
        }}
      />
    );
  };

  render() {
    const { error, touched, height, isSubmitting, label, helpMessage, name } =
      this.props;
    return (
      <InputContainer
        id={name}
        error={error}
        touched={touched}
        height={height}
        isSubmitting={isSubmitting}
        label={label}
        helpMessage={helpMessage}
      >
        {this.renderInputText}
      </InputContainer>
    );
  }
}

const Input = styled(FormInput)`
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  margin: auto 0;
  caret-color: ${COLORS.darkerGrey};

  &:focus {
    outline: none;
  }
`;

export default TextInput;
