import { finish } from 'constants/onboardingSteps';

export const checkOnboardingStep = (currentStep, currentPath) => {
  return (
    !currentStep ||
    currentStep === currentPath ||
    currentPath === '/error' ||
    currentPath === '/mfa/sms' ||
    currentPath === '/onboard/creditkarma/oauth'
  );
};

export const validOnboardingSteps = [
  '/error',
  '/onboard/create-account',
  '/onboard/verify',
  '/onboard/welcome',
  '/onboard/questions',
  '/onboard/loan-info',
  '/onboard/sync',
];

export const partnerApiBorrowerSkipLocation = (
  idrWizardSteps,
  idrFormCurrentStep,
  isIdrRecommended,
  isRefiRecommended,
) => {
  let location;

  const skipIntoIdrMinimumStep = 2;
  if (idrFormCurrentStep >= skipIntoIdrMinimumStep) {
    location = idrWizardSteps[idrFormCurrentStep];
  } else if (isIdrRecommended) {
    location = '/repayment';
  } else if (isRefiRecommended) {
    location = '/refinancing';
  } else {
    location = finish;
  }
  return location;
};
