import React from 'react';
import styled from 'styled-components';

import {
  Header,
  FlexCenter,
  Text,
  SvgImage,
  SummerBasicIdr,
} from '@summer/ui-components';

import SubmitToLoanServicer from 'components/shared/SubmitToLoanServicer';
import { Link } from 'components/shared/typography';
import { fsaIdrFormLink, fsaIdrToolLink } from 'constants/externalLinks/fsa';
import { TabletSize } from 'constants/styleguide';

const IdrSubmitBasic = () => {
  return (
    <Container data-testid="idr-submit-basic">
      <SummerBasicIcon SvgComponent={SummerBasicIdr} />
      <TextContainer>
        <InstructionsHeader h3>Instructions</InstructionsHeader>
        <Text paragraph>
          You can submit IDR forms directly to your loan servicer! Here&rsquo;s
          how:
        </Text>
        <InstructionsList>
          <li>
            <Text paragraph>
              To enroll in IDR, switch IDR plans, or recertify or recalculate
              your current plan, you can{' '}
              <Link underline inheritColor href={fsaIdrFormLink}>
                download the form from Federal Student Aid
              </Link>
              , or{' '}
              <Link underline inheritColor href={fsaIdrToolLink}>
                use their IDR tool.
              </Link>
            </Text>
          </li>
          <li>
            <Text paragraph>
              You&rsquo;ll answer similar questions about your financial
              situation and may need to provide proof of your income. Depending
              on your situation, this could be a tax return or a pay stub.
            </Text>
          </li>
          <li>
            <Text paragraph>
              Select the plan recommended above or use the tool from Federal
              Student Aid to re-explore your options.
            </Text>
          </li>
          <li>
            <Text paragraph>
              <SubmitToLoanServicer />
            </Text>
          </li>
        </InstructionsList>
      </TextContainer>
    </Container>
  );
};

const Container = styled(FlexCenter)`
  padding: 30px 90px;
  width: 100%;

  @media (max-width: ${TabletSize}) {
    padding: 0 16px;
    flex-direction: column;
  }
`;

const InstructionsHeader = styled(Header)`
  margin-bottom: 12px;
`;

const TextContainer = styled.div`
  margin-left: 98px;

  @media (max-width: ${TabletSize}) {
    margin-top: 16px;
    margin-left: 0px;
  }
`;

const SummerBasicIcon = styled(SvgImage)`
  max-width: 300px;

  @media (max-width: ${TabletSize}) {
    max-width: 200px;
  }
`;

const InstructionsList = styled.ol`
  margin-left: 24px;
`;

export default IdrSubmitBasic;
