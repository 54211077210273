import React from 'react';
import styled from 'styled-components';

import {
  SavingSafe,
  Header,
  FlexRow,
  SavingsPiggyBank,
  SplitPath,
  GuideCard,
} from '@summer/ui-components/src';

const Resources = () => (
  <Container>
    <Header as="h2">Resource Center</Header>
    <ResourceCards>
      <GuideCard
        icon={SavingSafe}
        headerText="Emergency savings basics"
        contentText="Why cash savings are important, how to build them up, and what counts as an emergency."
      />
      <GuideCard
        icon={SavingsPiggyBank}
        headerText="Choosing a Savings Account"
        contentText="Make your cash savings work for you by opening an account with a high yield interest rate."
      />
      <GuideCard
        icon={SplitPath}
        headerText="Prioritizing Financial Goals"
        contentText="How to balance your financial priorities such as credit card debt, cash savings, and retirement investing in a way that works for you."
      />
    </ResourceCards>
  </Container>
);

const Container = styled.div`
  width: 100%;
`;

const ResourceCards = styled(FlexRow)`
  margin-top: 16px;
  gap: 12px;
  align-items: stretch;

  & > * {
    height: auto;
    width: calc(100% / 3);
  }
`;

export default Resources;
