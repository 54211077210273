export const syncMarginMap = {
  myPlans: {
    'desktop': '-100px -80px',
    'small desktop': '-100px -80px',
    'tablet': '-100px -80px',
    'mobile': '-16px',
  },
  myLoans: {
    'desktop': '0',
    'small desktop': '0',
    'tablet': '0',
    'mobile': '0',
  },
  forgiveness: {
    'desktop': '0',
    'small desktop': '0',
    'tablet': '0',
    'mobile': '0',
  },
  idr: {
    'desktop': '-56px -100% -32px',
    'small desktop': '-56px -32px -32px',
    'tablet': '-56px -32px -32px',
    'mobile': '-40px -16px -48px',
  },
  pslf: {
    'desktop': '-32px -100% -16px',
    'small desktop': '-32px -32px -16px',
    'tablet': '-16px',
    'mobile': '-16px -16px 0px',
  },
  scheduleConsultation: {
    'desktop': '0',
    'small desktop': '0 0 81px',
    'tablet': '0 0 81px',
    'mobile': '0',
  },
  modal: {
    'desktop': '0',
    'small desktop': '0',
    'tablet': '0',
    'mobile': '0',
  },
};
