import { format, isAfter, parseISO } from 'date-fns';
import { every, some } from 'lodash';

import { pslfWaiverSummerDueDate } from 'constants/pslf';
import { hasLocalStorage } from 'services/featureFlags';
import { disallowBreaks } from 'utils/text';

export const areAllLoansIneligible = normalizedLoans =>
  every(normalizedLoans, [
    'pslfEligibilityAndConsolidationRec.pslfEligible',
    false,
  ]);

export const areAllLoansEligible = normalizedLoans =>
  every(normalizedLoans, [
    'pslfEligibilityAndConsolidationRec.pslfEligible',
    true,
  ]);

export const hasLoansToConsolidate = normalizedLoans =>
  some(normalizedLoans, [
    'pslfEligibilityAndConsolidationRec.shouldConsolidate',
    true,
  ]);

export const formatEmployerDates = (
  employerStartDate,
  employerEndDate,
  delimiter,
) => {
  const dateFormat = 'MMM dd, yyyy';
  return employerStartDate
    ? `${disallowBreaks(
        format(parseISO(employerStartDate), dateFormat),
      )} ${delimiter} ${
        employerEndDate
          ? disallowBreaks(format(parseISO(employerEndDate), dateFormat))
          : 'Present'
      }`
    : '';
};

export const summerPslfReviewBenefitsCutoff = () => {
  const currentDate = new Date();
  return isAfter(currentDate, pslfWaiverSummerDueDate);
};

/* These two functions help us bypass the PSLF start page if a user has
 * gotten to the sign step in PSLF at least once.
 * This is necessary because if a user completes a form, but instead of signing
 * goes back to make an edit, we change that form's status back to "started"
 * in the database.
 */
export const markPslfCompleteLocalStorage = () => {
  if (hasLocalStorage()) {
    localStorage.setItem('hasCompletedPslf', 'true');
  }
};
export const checkPslfCompleteLocalStorage = () =>
  localStorage.getItem('hasCompletedPslf');

export const markPslfWaiverSeenLocalStorage = () => {
  if (hasLocalStorage()) {
    localStorage.setItem('hasSeenWaiverExpiryNotice', 'true');
  }
};
export const checkPslfWaiverSeenLocalStorage = () =>
  localStorage.getItem('hasSeenWaiverExpiryNotice');
