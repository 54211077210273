import React from 'react';
import styled from 'styled-components';

import {
  Header,
  Text,
  FlexRow,
  SvgImage,
  BrowserChrome,
  BrowserFirefox,
  BrowserEdge,
} from '@summer/ui-components';

import ModalPopup from 'components/shared/ModalPopup';
import { Link } from 'components/shared/typography';
import {
  chromeLink,
  edgeLink,
  firefoxLink,
} from 'constants/externalLinks/browsers';
import { isBrowserInvalid } from 'utils/common';

class BrowserModal extends React.Component {
  state = {
    isOpen: false,
  };

  componentDidMount() {
    if (isBrowserInvalid()) {
      this.setState({ isOpen: true });
    }
  }

  render() {
    return (
      <ModalPopup
        isOpen={this.state.isOpen}
        closeSize={0}
        togglePopup={() => this.setState({ isOpen: false })}
        width={700}
        component={
          <Container>
            <HeaderContainer>
              <Header center>
                Sorry, you’re using a browser we don’t support
              </Header>
            </HeaderContainer>
            <TextContainer>
              <Text center large>
                Please use one of these options to improve your experience
              </Text>
            </TextContainer>
            <IconContainer>
              <div>
                <SvgImage width={80} SvgComponent={BrowserChrome} />
                <Text large>
                  <Link href={chromeLink}>Google Chrome</Link>
                </Text>
                <Text small color="secondary">
                  Version 73+
                </Text>
              </div>
              <div>
                <SvgImage width={80} SvgComponent={BrowserFirefox} />
                <Text large>
                  <Link href={firefoxLink}>Firefox</Link>
                </Text>
                <Text small color="secondary">
                  Version 65+
                </Text>
              </div>
              <div>
                <SvgImage width={72} SvgComponent={BrowserEdge} />
                <Text large>
                  <Link href={edgeLink}>Microsoft Edge</Link>
                </Text>
                <Text small color="secondary">
                  Version 18+
                </Text>
              </div>
            </IconContainer>
          </Container>
        }
      />
    );
  }
}

const Container = styled.div`
  padding-top: 32px;
  padding-bottom: 48px;
`;

const HeaderContainer = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;

const TextContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;
`;

const IconContainer = styled(FlexRow)`
  justify-content: space-between;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;

  & > div > div:last-child {
    margin-top: 8px;
  }
`;

export default BrowserModal;
