import React, { Component } from 'react';

import { Text, MoneyInput } from '@summer/ui-components';

import {
  Label,
  LabelContainer,
  Question,
  SmallTextInputContainer,
} from 'components/pages/idr/wizard/shared/styles';
import InfoTooltip from 'components/shared/InfoTooltip';
import { Link } from 'components/shared/typography';
import { irsGetTaxReturnLink } from 'constants/externalLinks/irs';
import { validateNumber } from 'utils/validate';

const AGITooltip = () => (
  <>
    <Text small>
      You can find your AGI (Adjusted Gross Income) on last year&apos;s tax
      return, on Form 1040 (in line 11), or by requesting a tax transcript by
      clicking <Link href={irsGetTaxReturnLink}>here</Link>. You will need to
      provide proof income later by submitting your last federal tax return.
    </Text>
    <br />
    <Text small>
      If you don&apos;t have any income, enter $0. You would be eligible for a
      $0 monthly payment.
    </Text>
  </>
);

class AdjustedGrossIncome extends Component {
  componentWillUnmount() {
    this.props.formik.setFieldValue(`tellUsAboutYou.adjustedGrossIncome`, '');
  }

  render() {
    const {
      formik: {
        values,
        errors,
        handleBlur,
        touched,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
      },
    } = this.props;

    return (
      <Question id="adjustedGrossIncome">
        <LabelContainer>
          <Label>
            What was your adjusted gross income (AGI) as reported on your
            federal taxes last year?
            <InfoTooltip
              width={400}
              size={14}
              top={2}
              leftOffset={6}
              text={<AGITooltip />}
            />
          </Label>
        </LabelContainer>
        <SmallTextInputContainer>
          <MoneyInput
            name="tellUsAboutYou.adjustedGrossIncome"
            value={values.adjustedGrossIncome}
            error={errors.adjustedGrossIncome}
            touched={touched.adjustedGrossIncome}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            isSubmitting={isSubmitting}
            onBlur={handleBlur}
            validate={validateNumber()}
          />
        </SmallTextInputContainer>
      </Question>
    );
  }
}

export default AdjustedGrossIncome;
