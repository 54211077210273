import { map } from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import {
  FlexRow,
  Header,
  Text,
  Lottie,
  LoadingBlueLottie,
  DotsLottie,
  CheckmarkLottie,
} from '@summer/ui-components';

import { isTestEnv } from 'utils/common';

const tickDuration = isTestEnv() ? 1 : 1200; // ms

const LoadingAnimation = ({
  header,
  waveSpeed,
  checklist = [],
  onAnimationEnd,
  subText,
  testId,
}) => {
  const totalDurationInTicks = checklist?.length * 2 + 1;
  const [ticks, setTicks] = useState(0);
  const intervalRef = useRef(null);

  useEffect(function startAnimation() {
    intervalRef.current = setInterval(function tick() {
      setTicks(ticks => ticks + 1);
    }, tickDuration);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(
    function endAnimation() {
      if (ticks > totalDurationInTicks) {
        if (onAnimationEnd) {
          onAnimationEnd();
        }
      }
      return () => {};
    },
    [ticks, onAnimationEnd, totalDurationInTicks],
  );

  return (
    <>
      <Header h2 center>
        {header}
      </Header>

      <WavesContainer data-testid={testId}>
        <Lottie
          autoplay={true}
          loop={true}
          speed={waveSpeed}
          animationData={LoadingBlueLottie}
        />
      </WavesContainer>
      <AnimationContainer>
        {map(checklist, (step, idx) => {
          const active = idx * 2 < ticks;
          const firstActiveTick = idx * 2 + 1 === ticks;

          return (
            <AnimationRow key={step} active={active}>
              {active && (
                <CheckmarkContainer>
                  {firstActiveTick && (
                    <Lottie
                      autoplay={true}
                      loop={false}
                      speed={1.1}
                      animationData={DotsLottie}
                    />
                  )}
                  {!firstActiveTick && (
                    <Lottie
                      autoplay={true}
                      loop={false}
                      speed={1.2}
                      animationData={CheckmarkLottie}
                    />
                  )}
                </CheckmarkContainer>
              )}
              <Text bold color="secondary">
                {step}
              </Text>
            </AnimationRow>
          );
        })}
      </AnimationContainer>
      <Text as="span" size="large" text-align="center">
        {subText}
      </Text>
    </>
  );
};

const WavesContainer = styled.div`
  height: 12px;
  margin-top: 40px;
  margin-bottom: 48px;
`;

const CheckmarkContainer = styled.div`
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin-right: 16px;
`;

const AnimationContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 240px;
`;

const AnimationRow = styled(FlexRow)`
  margin-bottom: 24px;
  align-items: flex-start;

  opacity: ${props => (props.active ? '1' : '0')};

  transition: opacity 300ms ease-in;
`;

export default LoadingAnimation;
