import React from 'react';
import styled from 'styled-components';

import { Header } from '@summer/ui-components';

import { OnboardingContainer } from 'components/pages/onboarding/shared';
import SecurityFooter from 'components/pages/onboarding/shared/SecurityFooter';
import SecurityHeader from 'components/pages/onboarding/shared/SecurityHeader';

export const MfaPageContainer = ({ header, children, testId }) => (
  <>
    <OnboardingContainer data-testid={testId}>
      {header && (
        <>
          <SecurityHeader />
          <HeaderContainer>
            <Header h2 center>
              {header}
            </Header>
          </HeaderContainer>
        </>
      )}
      {children}
    </OnboardingContainer>
    <SecurityFooter />
  </>
);

const HeaderContainer = styled.div`
  margin-bottom: 24px;
`;
