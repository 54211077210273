import { random } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import {
  Header,
  FlexRow,
  FlexColumn,
  Icon,
  IconMedallion,
  BackgroundSvg,
  Text,
  SyncInfinity,
  COLORS,
} from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import { MobileSize } from 'constants/styleguide';
import { hexToRgba } from 'utils/common';

const MissingInfoHeader = ({ platformType }) => {
  const isMobile = platformType === MOBILE;

  const backgroundRotateDeg = React.useMemo(() => {
    return random(90, 180);
  }, []);

  return (
    <BackgroundContainer>
      <BackgroundShape
        fill={hexToRgba(COLORS.jade, 0.16)}
        rotate={backgroundRotateDeg}
        SvgComponent={BackgroundSvg}
      />
      <Content>
        <TextContainer isMobile={isMobile}>
          <SyncTitle>
            {!isMobile && (
              <InfinitySymbolImage
                SvgComponent={SyncInfinity}
                color={COLORS.jade}
              />
            )}
            <Header as="h2">
              It looks like we&rsquo;re missing some crucial information
            </Header>
          </SyncTitle>
          <Text paragraph>
            Please upload your FSA loan file to ensure we have all the data we
            need.
          </Text>
        </TextContainer>
        {!isMobile && (
          <ImageContainer>
            <img alt="Sync Info" src="/images/compare-plan.png" width="100%" />
          </ImageContainer>
        )}
      </Content>
    </BackgroundContainer>
  );
};

const BackgroundContainer = styled.div`
  position: relative;
  background-color: ${hexToRgba(COLORS.jade, 0.12)};
  width: 100%;
  top: 0;
  left: 0;
  padding: 24px 48px;
  overflow: hidden;
  z-index: 0;

  @media (max-width: ${MobileSize}) {
    padding: 32px 24px 24px;
  }
`;

const BackgroundShape = styled(Icon)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: ${({ rotate }) => `rotate(${rotate}deg)`} scale(48);
  z-index: 1;

  @media (max-width: ${MobileSize}) {
    transform: ${({ rotate }) => `rotate(${rotate}deg)`} scale(30);
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 840px;
  margin: 0 auto;
  justify-content: space-between;
  z-index: 2;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
    align-items: center;
  }
`;

const TextContainer = styled(FlexColumn)`
  margin-right: 32px;
  align-items: flex-start;
  text-align: left;
  max-width: 426px;
  padding: 32px 0;

  @media (max-width: ${MobileSize}) {
    padding: 0;
    margin-right: 0;
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  max-width: 314px;

  @media (max-width: ${MobileSize}) {
    max-width: 288px;
  }
`;

const InfinitySymbolImage = styled(IconMedallion)`
  margin-right: 16px;
`;

const SyncTitle = styled(FlexRow)`
  align-items: center;
  margin-bottom: 16px;

  @media (max-width: ${MobileSize}) {
    margin-bottom: 16px;
  }
`;

export default Platform(MissingInfoHeader);
