import { join, trim } from 'lodash';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import {
  FlexCenter,
  FlexColumn,
  FlexRow,
  Text,
  Strong,
  PiggyBankNew,
  StopWatch,
  IconMedallion,
  COLORS,
} from '@summer/ui-components';

import Platform, { MOBILE, SMALL_DESKTOP } from 'components/hoc/Platform';
import { MobileSize, TabletSize } from 'constants/styleguide';
import { hexToRgba } from 'utils/common';
import { yearsAndMonths } from 'utils/date';
import { dollars } from 'utils/numbers';

const OverpaymentInfo = ({ platformType, values }) => {
  const fontSize = platformType !== MOBILE ? 'medium' : 'small';
  const {
    overpaymentAmount,
    overpaymentTotalPayment,
    totalSavings,
    overpaymentTotalTime,
    termsDiff,
  } = values;

  const timeTotal = useMemo(
    () => yearsAndMonths(overpaymentTotalTime),
    [overpaymentTotalTime],
  );

  const timeSaved = useMemo(() => yearsAndMonths(termsDiff), [termsDiff]);

  const yearsPunctuation =
    timeSaved.years && timeSaved.months && platformType !== MOBILE ? ',' : '';

  return (
    <Container>
      <OverpaymentSummary>
        <div>
          <Text paragraph as="span" size={fontSize}>
            With an{' '}
          </Text>
          <Text paragraph as="span" color="summer" size={fontSize}>
            <Strong data-testid="overpaymentAmount">
              extra {dollars(overpaymentAmount)}
            </Strong>{' '}
          </Text>
          <Text paragraph as="span" size={fontSize}>
            applied towards your monthly loan payment, you would pay
            {platformType === SMALL_DESKTOP ? <br /> : ''}
            {platformType !== MOBILE ? ' a total of' : ''}{' '}
          </Text>
          <Text paragraph as="span" color="summer" size={fontSize}>
            <Strong data-testid="overpaymentTotalPayment">
              {dollars(overpaymentTotalPayment)}
            </Strong>{' '}
          </Text>
          {platformType === SMALL_DESKTOP ? <br /> : ''}
          <Text paragraph as="span" size={fontSize}>
            over{' '}
          </Text>
          <Text paragraph as="span" color="summer" size={fontSize}>
            <Strong data-testid="overpaymentTotalTime">
              {trim(join([timeTotal.years, timeTotal.months], ' '))}
            </Strong>
            .
          </Text>
        </div>
      </OverpaymentSummary>
      <TimeAndInterestContainer>
        <TimeAndInterestWrapper>
          <Timeframe>
            <IconContainer>
              <IconMedallion
                SvgComponent={StopWatch}
                color={COLORS.azure}
                size={48}
              />
            </IconContainer>
            <Text paragraph color="secondary" size="small">
              <Strong>Time saved</Strong>
            </Text>
            <Text paragraph color="summer">
              <Strong data-testid="overpaymentSavedYears">
                {timeSaved.years + yearsPunctuation}
              </Strong>
            </Text>
            <Text paragraph color="summer">
              <Strong data-testid="overpaymentSavedMonths">
                {timeSaved.months}
              </Strong>
            </Text>
          </Timeframe>
          <Divider />
          <InterestPayment>
            <IconContainer>
              <IconMedallion
                SvgComponent={PiggyBankNew}
                color={COLORS.azure}
                size={48}
              />
            </IconContainer>
            <Text paragraph color="secondary" size="small">
              <Strong>Money saved</Strong>
            </Text>
            <Text paragraph color="summer">
              <Strong data-testid="overpaymentInterestSaved">
                {dollars(totalSavings)}
              </Strong>
            </Text>
            <Text paragraph color="summer" size="small">
              <Strong>
                ({platformType !== MOBILE ? 'interest saved' : 'in interest'})
              </Strong>
            </Text>
          </InterestPayment>
        </TimeAndInterestWrapper>
      </TimeAndInterestContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  border: solid 1px ${hexToRgba(COLORS.azure, 0.1)};
  margin-top: 24px;

  @media (max-width: ${TabletSize}) {
    flex-direction: column;
    border: none;
  }
`;

const OverpaymentSummary = styled(FlexCenter)`
  text-align: center;
  max-width: 316px;
  background-color: ${hexToRgba(COLORS.azure, 0.1)};
  padding: 24px 32px;
  @media (max-width: ${TabletSize}) {
    max-width: none;
    padding: 16px 24px;
    width: 100%;
  }
`;

const TimeAndInterestContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 189px;
  max-width: 356px;
  align-items: center;
  @media (max-width: ${TabletSize}) {
    min-height: 192px;
    max-width: none;
  }
`;

const TimeAndInterestWrapper = styled(FlexRow)`
  width: 100%;
  align-items: baseline;
`;

const Timeframe = styled(FlexColumn)`
  flex: 1;
  padding: 32px 16px;
  align-items: center;
  @media (max-width: ${MobileSize}) {
    padding: 24px 8px 24px 0px;
  }
`;

const IconContainer = styled.div`
  margin-bottom: 8px;
`;

const Divider = styled.div`
  align-self: center;
  width: 1px;
  height: 112px;
  border: dashed 1px ${hexToRgba(COLORS.azure, 0.1)};
`;

const InterestPayment = styled(FlexColumn)`
  flex: 1;
  padding: 32px 16px;
  align-items: center;
  @media (max-width: ${MobileSize}) {
    padding: 24px 0px;
  }
`;

export default Platform(React.memo(OverpaymentInfo));
