export const interestsAffiliations = [
  'Engineering',
  'Law',
  'Technology',
  'Physics',
  'Art',
  'Medicine',
  'Science',
];

export const ethnicity = [
  'American Indian or Alaska Native',
  'Arab',
  'Asian',
  'Black/African American',
  'Hispanic/Latino',
  'Native Hawaiian or Other Pacific Islander',
  'White',
  'Other',
];

export const gpaMapping = {
  '4 - 5': 5,
  '3.5 - 3.99': 3.99,
  '3 - 3.49': 3.49,
  '2 - 3': 3,
  '1 - 2': 2,
};

export const gpaRanges = Object.keys(gpaMapping);
