import { push as redirectTo } from 'connected-react-router';
import { map } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { USStateDisplayNameMapping } from '@simplifidev/shared/dist/constants/states';

import {
  Header,
  Icon,
  ArrowUp,
  FlexRow,
  Container as GridContainer,
  ResponsiveColumn,
  Row,
  Text,
  COLORS,
} from '@summer/ui-components';

import PrivacyPolicyLink from 'components/shared/PrivacyPolicyLink';
import LandingPage from 'components/shared/dashboard/LandingPage';
import { fullWidthContainerInPageCss } from 'components/shared/dashboard/Page';
import { StateFilterOptions } from 'constants/prismic';
import { MobileSize } from 'constants/styleguide';
import {
  fetchAllPrograms,
  setFilters,
} from 'redux/actions/forgivenessFinder.actions';
import {
  getFilteredPrograms,
  getForgivenessFinderPrograms,
  getForgivenessFinderFilters,
} from 'redux/selectors/forgivenessFinder.selectors';
import { getUserStateOfResidence } from 'redux/selectors/profile.selectors';
import {
  getUserHasFederalLoans,
  getUserHasPrivateLoans,
} from 'redux/selectors/user.selectors';

import ForgivenessProgramFilters from './components/ForgivenessProgramFilters';
import ForgivenessProgramsList from './components/ForgivenessProgramsList';

class ForgivenessFinder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: {},
      showFilters: true,
    };

    this.setFilterDefaults(props);
  }

  componentDidMount() {
    this.props.fetchAllPrograms();
  }

  handleToggleFilters = () => {
    this.setState(({ showFilters }) => ({
      showFilters: !showFilters,
    }));
  };

  handleFilter = (name, value) => {
    const filters = { ...this.props.filters };
    filters[name] = value;
    this.props.setFilters(filters);
  };

  handleProgramClick = uid => () => {
    const { redirectTo, location } = this.props;
    redirectTo(`${location.pathname}/${uid}`);
  };

  normalizePrograms = programs => {
    return map(programs, program => {
      const { name, awardPreview, description } = program.data || {};
      const { text } = description[0] || {};
      return {
        uid: program.uid,
        title: name,
        award: awardPreview,
        description: text,
      };
    });
  };

  setFilterDefaults = ({
    stateOfResidence,
    hasFederalLoans,
    hasPrivateLoans,
  }) => {
    const loanType = this.determineLoanType(hasFederalLoans, hasPrivateLoans);
    const state = this.determineStateOfResidence(stateOfResidence);

    if (stateOfResidence && loanType) {
      this.props.setFilters({
        state,
        loanType,
      });
    }
  };

  determineStateOfResidence = stateOfResidence => {
    // Note: Allowing undefined to be returned as we can still use
    // forgiveness finder tool after skipping onboarding and not having state info
    return StateFilterOptions.find(
      ({ label }) => label === USStateDisplayNameMapping[stateOfResidence],
    )?.value;
  };

  determineLoanType = (hasFederalLoans, hasPrivateLoans) => {
    if (hasFederalLoans) {
      return 'Federal';
    }
    if (hasPrivateLoans) {
      return 'Private';
    }

    return null;
  };

  render() {
    const filterProgramList = getFilteredPrograms(
      this.props.programList,
      this.props.filters,
    );
    const { showFilters } = this.state;

    return (
      <LandingPage
        headerTheme="sand"
        headerName="Forgiveness Finder"
        subHeader={
          <Text paragraph weight="medium">
            We have a robust list of federal and private forgiveness programs
            that you may qualify for based on where you live and your
            occupation.
          </Text>
        }
      >
        <GridContainer>
          <Row>
            <ResponsiveColumn desktopCol={12} mobileCol={12} tabletCol={12}>
              <ContentContainer>
                <CollapseTrigger onClick={this.handleToggleFilters}>
                  <Header h4 color="secondary">
                    Filter programs
                  </Header>
                  <Arrow
                    open={showFilters}
                    SvgComponent={ArrowUp}
                    fill={COLORS.medGrey}
                  />
                </CollapseTrigger>
                <FiltersContainer>
                  <ForgivenessProgramFilters
                    show={showFilters}
                    onChange={this.handleFilter}
                    filtersValues={this.props.filters}
                  />
                </FiltersContainer>

                <ListContainer>
                  <ForgivenessProgramsList
                    handleProgramClick={this.handleProgramClick}
                    forgivenessPrograms={this.normalizePrograms(
                      filterProgramList,
                    )}
                  />
                </ListContainer>
              </ContentContainer>
              <PrivacyPolicyLinkForgivenessFinderPage />
            </ResponsiveColumn>
          </Row>
        </GridContainer>
      </LandingPage>
    );
  }
}

const ContentContainer = styled.div`
  position: relative;
  margin-top: 32px;
  padding-top: 16px;
  z-index: 1;
  background-color: ${COLORS.lightestGrey};
  ${fullWidthContainerInPageCss}
`;

const FiltersContainer = styled.div`
  background-color: ${COLORS.lighterGrey};
  ${fullWidthContainerInPageCss}
`;

const CollapseTrigger = styled(FlexRow)`
  width: 100%;
  cursor: pointer;
  margin-bottom: 16px;
  justify-content: flex-end;

  .icon {
    margin-left: 8px;
  }

  @media (max-width: ${MobileSize}) {
    justify-content: center;
    padding: 16px 0;
    margin-bottom: 0;
    border-bottom: solid 1px ${COLORS.grey};
  }
`;

const Arrow = styled(Icon)`
  cursor: pointer;
  margin-top: 3px;
  transition: transform 0.3s ease-out;
  transform: ${props => (props.open ? 'rotate(0deg)' : 'rotate(180deg)')};
`;

const ListContainer = styled.div`
  margin-top: 24px;
`;

const PrivacyPolicyLinkForgivenessFinderPage = styled(PrivacyPolicyLink)`
  justify-content: flex-start;
  text-align: left;
  padding: 14px 32px 70px;

  @media (max-width: ${MobileSize}) {
    padding: 30px 16px 70px;
  }
`;

const mapStateToProps = state => ({
  programList: getForgivenessFinderPrograms(state),
  filters: getForgivenessFinderFilters(state),
  stateOfResidence: getUserStateOfResidence(state),
  hasFederalLoans: getUserHasFederalLoans(state),
  hasPrivateLoans: getUserHasPrivateLoans(state),
});

export default connect(mapStateToProps, {
  fetchAllPrograms,
  redirectTo,
  setFilters,
})(ForgivenessFinder);
