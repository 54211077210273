import md5 from 'blueimp-md5';
import { format } from 'date-fns';
import { get, isEmpty, find } from 'lodash';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { FormTypes } from '@simplifidev/shared/dist/constants/forms';

import {
  Text,
  Strong,
  Header,
  FlexColumn,
  FlexRow,
  COLORS,
} from '@summer/ui-components';

import HellosignAlert from 'components/common/HellosignAlert';
import SignRequestRow from 'components/common/SignRequestRow';
import Platform, { MOBILE } from 'components/hoc/Platform';
import PrivacyPolicyLink from 'components/shared/PrivacyPolicyLink';
import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';
import {
  marketingSiteLink,
  pslfGuideLink,
} from 'constants/externalLinks/marketingSite';
import { TabletSize, MobileSize } from 'constants/styleguide';
import { useHellosign, useAlertHellosignError } from 'hooks/hellosign';
import {
  updateSignatureRequestJwt,
  fetchSignatureRequestJwt,
} from 'redux/actions/signatureRequests.actions';
import {
  getErrorMessage,
  isLoadingWithInit,
} from 'redux/selectors/ui.selectors';
import { hexToRgba } from 'utils/common';
import { track } from 'utils/track';

const buildEmployerUrl = employerParams => {
  const dataParams = Object.keys(employerParams).filter(
    param => param !== 'hash',
  );

  const queryString = dataParams
    .map(key => {
      return `${key}=${encodeURIComponent(employerParams[key])}`;
    })
    .join('&');

  return queryString;
};

// TODO: Remove this method when old links expire.
const isEmployerUrlValid = employerParams => {
  const hasEmptyNonHashParam = find(
    employerParams,
    (val, key) => isEmpty(val) && key !== 'hash',
  );
  if (hasEmptyNonHashParam) {
    return false;
  }

  const employerParamsUrl = buildEmployerUrl(employerParams);
  const urlHash = md5(employerParamsUrl);
  return urlHash === employerParams.hash;
};

const decodeJwt = token =>
  JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('utf8'));

// TODO: Remove this method once old links expire.
const getEmployerSignQueryDataFromParams = queryParams => {
  return {
    token: queryParams.get('token'),
    signRequestId: queryParams.get('signRequestId'),
    employerFirstName: queryParams.get('employerFirstName'),
    employerCompany: queryParams.get('employerCompany'),
    employerStartDate: queryParams.get('employerStartDate'),
    employerEndDate: queryParams.get('employerEndDate'),
    userFirstName: queryParams.get('userFirstName'),
    userLastName: queryParams.get('userLastName'),
    userEmail: queryParams.get('userEmail'),
    utm_source: queryParams.get('utm_source'),
    utm_campaign: queryParams.get('utm_campaign'),
    hash: queryParams.get('hash'),
  };
};

const getEmployerSignQueryDataFromToken = queryParams => {
  const token = queryParams.get('token');
  const decodedData = decodeJwt(token);
  return {
    token,
    ...decodedData,
  };
};

const getEmployerSignQueryData = (useOldParamsMethods, queryParams) => {
  if (useOldParamsMethods) {
    return getEmployerSignQueryDataFromParams(queryParams);
  }
  return getEmployerSignQueryDataFromToken(queryParams);
};

const EmployerPage = ({
  dispatch,
  platformType,
  windowWidth,
  employerSignURL,
  location,
  isLoading,
  serverError,
}) => {
  const isMobile = platformType === MOBILE;
  const headerRef = useRef(null);
  const [backgroundHeight, setBackgroundHeight] = useState();
  const queryParams = useMemo(
    () => new URLSearchParams(decodeURI(window.location.search)),
    [],
  );
  const useOldParamsMethods = queryParams.has('hash');

  const [queryData] = useState(
    getEmployerSignQueryData(useOldParamsMethods, queryParams),
  );
  // If this only has a token, there's no need to check the hash,
  // since a JWT is self-evident of a lack of tampering.
  const [isUrlValid] = useState(
    useOldParamsMethods ? isEmployerUrlValid(queryData) : true,
  );
  if (!isUrlValid) {
    // redact the value of the query parameter token
    queryParams.set('token', '**redacted**');
    throw new Error(
      `Employer page missing required URL params: ${queryParams}`,
    );
  }
  const isExpired = serverError === 'Not Found';
  const alertHellosignError = useAlertHellosignError(serverError);

  useEffect(() => {
    setBackgroundHeight(headerRef.current.offsetTop);
  }, [windowWidth]);

  useEffect(() => {
    dispatch(
      fetchSignatureRequestJwt({
        token: queryData.token,
        signRequestId: queryData.signRequestId,
      }),
    );
  }, [dispatch, location.search, queryData]);

  const signRequest = {
    formType: FormTypes.pslf,
    signers: [employerSignURL],
  };

  const employerEndDate =
    queryData.employerEndDate && queryData.employerEndDate !== 'null'
      ? format(new Date(queryData.employerEndDate), 'MMM yyyy')
      : 'Present';

  let signRequestTitle = `${queryData.userFirstName} ${queryData.userLastName}`;
  let signRequestSubtitle = isMobile
    ? `${queryData.employerCompany}`
    : `${queryData.employerCompany}, ${format(
        new Date(queryData.employerStartDate),
        'MMM yyyy',
      )} — ${employerEndDate}`;

  if (isExpired) {
    signRequest.signers = [];
    signRequestTitle = `This link has expired because ${queryData.userFirstName} has made changes`;
    signRequestSubtitle =
      'Check your email for a more recent email from Summer';
  }

  const [handleButtonClick] = useHellosign(
    () =>
      dispatch(
        fetchSignatureRequestJwt({
          token: queryData.token,
          signRequestId: queryData.signRequestId,
        }),
      ),
    {
      onOpen: () => {
        track('Employer Open Document', {
          borrowerEmail: queryData.userEmail,
          signRequestId: queryData.signRequestId,
        });
      },
      onSign: data => {
        track('Employer Sign', {
          borrowerEmail: queryData.userEmail,
          signRequestId: queryData.signRequestId,
        });
        dispatch(
          updateSignatureRequestJwt(queryData.token, data.signatureId, true),
        );
      },
    },
  );

  const mailHref = `mailto:${supportAddress}?cc=${queryData.userEmail}`;

  return (
    <Container>
      <Background backgroundHeight={backgroundHeight} />
      <TopContainer>
        <Header>
          Hi, {queryData.employerFirstName}! {queryData.userFirstName} is
          requesting your help.
        </Header>
        <VerifyEmploymentContainer>
          <Text paragraph>
            Please verify their employment so they can qualify for Public
            Service Loan Forgiveness and save on their student loan debt.
          </Text>
        </VerifyEmploymentContainer>
        {alertHellosignError && (
          <HellosignAlertContainer>
            <HellosignAlert />
          </HellosignAlertContainer>
        )}
        <div ref={headerRef} />
        <SignRequestRow
          signRequest={signRequest}
          onClick={handleButtonClick}
          signRequestTitle={signRequestTitle}
          signRequestSubtitle={signRequestSubtitle}
          isLoading={isLoading || alertHellosignError}
          isExpired={isExpired}
        />
        <SomethingMissingContainer>
          <Text paragraph>
            <Strong>Something missing or incorrect? </Strong>
            Email Summer at <Link href={mailHref}>{supportAddress}</Link> and
            copy {queryData.userFirstName} at {queryData.userEmail}.
          </Text>
        </SomethingMissingContainer>
      </TopContainer>
      <Divider />
      <BottomContainer>
        <PslfHeader>
          <Header as={isMobile ? 'h2' : 'h1'}>
            Public Service Loan Forgiveness with Summer
          </Header>
        </PslfHeader>
        <PslfInfo>
          <ListHeaderContainer>
            <Header as="h5">What is Summer?</Header>
          </ListHeaderContainer>
          <Text paragraph>
            <Link inheritColor underline href={marketingSiteLink}>
              Summer
            </Link>{' '}
            is a social enterprise that helps student loan borrowers enroll in
            free federal savings and forgiveness programs, including Public
            Service Loan Forgiveness. As part of our service, we provide a free
            tool to help non-profit and government employers with the
            certification process for Public Service Loan Forgiveness.
          </Text>
          <ListHeaderContainer>
            <Header as="h5">What is Public Service Loan Forgiveness?</Header>
          </ListHeaderContainer>
          <Text paragraph>
            Public Service Loan Forgiveness is a federal program that allows
            borrowers working in the public and non-profit sectors to have their
            remaining debt forgiven after 10 years of qualifying payments. You
            can read more about it{' '}
            <Link inheritColor underline href={pslfGuideLink}>
              here
            </Link>
            .
          </Text>
          <PrivacyPolicyPSLFEmployerPage />
        </PslfInfo>
      </BottomContainer>
    </Container>
  );
};

const PrivacyPolicyPSLFEmployerPage = styled(PrivacyPolicyLink)`
  margin-top: 45px;
  justify-content: flex-start;
`;

const Container = styled(FlexColumn)`
  padding: 80px 64px;
  max-width: 1440px;

  @media (max-width: ${MobileSize}) {
    padding: 0 24px 96px;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(${props => props.backgroundHeight}px + 48px);
  background-image: linear-gradient(
    to bottom,
    rgba(252, 254, 255, 0.53),
    ${hexToRgba(COLORS.azure, '0.05')}
  );
  z-index: -1;

  transition: height 1s;

  @media (max-width: ${TabletSize}) {
    height: calc(${props => props.backgroundHeight}px + 72px);
  }

  @media (max-width: ${MobileSize}) {
    height: calc(${props => props.backgroundHeight}px + 80px);
  }
`;

const VerifyEmploymentContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;

  @media (max-width: ${MobileSize}) {
    margin-bottom: 16px;
  }
`;

const HellosignAlertContainer = styled.div`
  margin-bottom: 24px;
`;

const SomethingMissingContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;

  @media (max-width: ${MobileSize}) {
    margin-top: 16px;
    margin-bottom: 0;
  }
`;

const TopContainer = styled(FlexColumn)`
  flex: 1 0 auto;
`;

const BottomContainer = styled(FlexRow)`
  @media (max-width: ${MobileSize}) {
    flex-direction: column;
  }
`;

const PslfHeader = styled.div`
  max-width: 492px;
  text-align: left;
  padding-right: 48px;

  @media (max-width: ${MobileSize}) {
    max-width: 100%;
    padding-right: 0;
    padding-bottom: 16px;
  }
`;

const Divider = styled.div`
  border-top: 1px solid ${COLORS.grey};
  margin: 40px 0;

  @media (max-width: ${MobileSize}) {
    margin: 24px 0;
  }
`;

const PslfInfo = styled.div`
  max-width: 640px;

  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

const ListHeaderContainer = styled.div`
  &:first-child {
    margin-top: 10px;
  }

  margin-top: 16px;
  margin-bottom: 8px;
`;

const mapStateToProps = state => ({
  employerSignURL: get(state, 'signatureRequests[0]'),
  isLoading: isLoadingWithInit(state, 'fetchSignatureRequestJwt'),
  serverError: getErrorMessage(state, 'fetchSignatureRequestJwt'),
});

export default connect(mapStateToProps)(Platform(EmployerPage));
