import { keys, compact, clone } from 'lodash';

const sortElementsByDOMPosition = elements =>
  clone(elements).sort((a, b) => {
    if (a === b || !a?.compareDocumentPosition) {
      return 0;
    }
    if (a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_PRECEDING) {
      // b comes before a
      return 1;
    }
    return -1;
  });

export const scrollToError = errors => {
  const errorFields = compact(
    keys(errors).map(fieldName => document.getElementById(fieldName)),
  );

  const sortedErrorFields = sortElementsByDOMPosition(errorFields);
  const [firstFieldWithError] = sortedErrorFields;

  setImmediate(() =>
    firstFieldWithError?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    }),
  );
};
