import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'fast-text-encoding';
import './errorTracking';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';
import smoothscroll from 'smoothscroll-polyfill';

// eslint-disable-next-line import/order
import store, { persistor } from 'redux/store';

import App from 'components/App';
import ScrollToTop from 'components/ScrollToTop';
import BrowserModal from 'components/common/BrowserModal';
import ErrorPage from 'components/pages/Error';
import history from 'redux/history';
import { HelpWidget } from 'services/chat';
import { isBrowserInvalid } from 'utils/common';

smoothscroll.polyfill();
window.helpWidget = new HelpWidget();

render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConnectedRouter history={history}>
        <ScrollToTop>
          <Switch>
            {isBrowserInvalid() ? (
              <BrowserModal />
            ) : (
              <>
                <Route exact path="/error" component={ErrorPage} />
                <App />
              </>
            )}
          </Switch>
        </ScrollToTop>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
