import { push as redirectTo } from 'connected-react-router';
import React from 'react';
import { useDispatch, connect } from 'react-redux';
import styled from 'styled-components';

import { Header, Text, Button, FancyCard } from '@summer/ui-components';

import Link from 'components/shared/typography/Link';
import { fidelityOverPaymentsReturnLink } from 'constants/externalLinks/partner';
import { MobileSize } from 'constants/styleguide';
import { getUserPartnerKey } from 'redux/selectors/user.selectors';

const RecCardContent = ({ partnerKey }) => {
  const dispatch = useDispatch();
  const handleCTA = React.useCallback(() => {
    dispatch(redirectTo('/overpayments'));
  }, [dispatch]);

  const isFidelity = partnerKey === 'fidelity';

  return (
    <CardContentContainer>
      <ContentContainer>
        <Header h2>Make proactive payments now</Header>
        <Text paragraph weight="medium" size="medium">
          Increasing the amount you pay now can lower the amount of interest you
          pay in the long run. Use {isFidelity ? 'Fidelity\u2019s' : 'our'}{' '}
          savings calculator to see how much you can save in the long run by
          making
          {isFidelity ? ' proactive ' : ' '}
          payments now.
        </Text>
      </ContentContainer>
      <CTAContainer>
        {isFidelity ? (
          <Link href={fidelityOverPaymentsReturnLink} target="_self">
            <Button width={150}>Return to Fidelity</Button>
          </Link>
        ) : (
          <Button onClick={handleCTA} width={144}>
            Get Started
          </Button>
        )}
      </CTAContainer>
    </CardContentContainer>
  );
};

const OverpaymentsRec = ({ platformType, partnerKey }) => {
  return (
    <FancyCard
      title="PAY OFF FASTER"
      color="violet"
      testId="rec-card-overpayments"
    >
      <RecCardContent platformType={platformType} partnerKey={partnerKey} />
    </FancyCard>
  );
};

const CardContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: inherit;
  align-items: center;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;

    > * {
      margin-bottom: 16px;
      :last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const ContentContainer = styled.div`
  margin-right: 8px;
  max-width: 80%;

  > * {
    margin-bottom: 8px;
  }

  :last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${MobileSize}) {
    max-width: 100%;
  }
`;

const CTAContainer = styled.div`
  text-align: center;
  align-self: flex-end;

  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

const mapStateToProps = state => ({
  partnerKey: getUserPartnerKey(state),
});

export default connect(mapStateToProps)(OverpaymentsRec);
