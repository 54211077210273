import { push as redirect } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  FlexCenter,
  FlexColumn,
  FlexRow,
  LoadingBars,
  Text,
  Icon,
  SummerLogo,
  COLORS,
} from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { MobileSize } from 'constants/styleguide';
import { getCurrentStep, logout } from 'redux/actions/user.actions';
import { getOnboardingCurrentStep } from 'redux/selectors/onboarding.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { getUserId } from 'redux/selectors/user.selectors';

const logoHrefs = {
  company: 'https://www.meetsummer.com/company',
  harrys: 'https://www.meetsummer.com/harrys',
  earnup: 'https://www.meetsummer.com/earnup',
  steady: 'https://www.meetsummer.com/steady',
  cff: 'https://www.meetsummer.com/cff',
  freelancers: 'https://www.meetsummer.com/freelancers',
  asurion: 'https://www.meetsummer.com/asurion',
  default: 'https://www.meetsummer.com',
};

class OnBoarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: props.path,
    };
  }

  componentDidMount() {
    if (!this.props.currentStep && this.props.currentUserId !== null) {
      this.props.getCurrentStep();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentStep !== this.props.currentStep) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  render() {
    const isErrorPage = this.props.match.path === '/error';
    const isUserLogin = this.props.currentUserId !== null;
    const isEmployerPage = this.props.match.path === '/employer-signature';

    const logoHref =
      logoHrefs[this.props.match.params.partner] ?? logoHrefs.default;

    const onClick = () => {
      window.open(logoHref, '_blank');
    };

    return (
      <Container>
        {this.props.isLoading ? (
          <LoadingContainer>
            <LoadingBars color={COLORS.azure} />
          </LoadingContainer>
        ) : (
          <>
            <Header>
              <Logo
                onClick={onClick}
                width={140}
                height={35}
                SvgComponent={SummerLogo}
                fill={COLORS.lightBlue}
              />
              {isUserLogin && !isErrorPage && !isEmployerPage && (
                <Text small right bold color="secondary">
                  <Link inheritColor onClick={this.props.logout}>
                    Logout
                  </Link>
                </Text>
              )}
            </Header>
            <Body>{this.props.children}</Body>
          </>
        )}
      </Container>
    );
  }
}

const Container = styled(FlexColumn)`
  width: 100%;
  height: 100%;
`;

const LoadingContainer = styled(FlexCenter)`
  flex: 1;
`;

const Header = styled(FlexRow)`
  justify-content: space-between;
  width: 100%;
  padding: 24px 24px;
  height: 80px;
  align-items: center;
  & > * {
    width: 200px; // to center items
  }
`;

const Logo = styled(Icon)`
  width: 140px;
  height: 35px;
`;

const Body = styled(FlexColumn)`
  flex: 1 0;
  align-items: center;
  margin-top: 8px;

  @media (max-width: ${MobileSize}) {
    margin-top: 8px;
  }
`;

const mapStateToProps = state => ({
  currentStep: getOnboardingCurrentStep(state),
  isLoading: isLoading(state, 'fetchCurrentStep'),
  currentUserId: getUserId(state),
});

export default connect(mapStateToProps, { getCurrentStep, redirect, logout })(
  OnBoarding,
);
