import React from 'react';

import { Alert } from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { openChat } from 'services/chat';

const HellosignAlert = () => {
  return (
    <Alert theme="warning" dismissable={false}>
      Our signing tool is currently experiencing issues, please try refreshing
      the page. If that doesn&rsquo;t work,{' '}
      <Link underline inheritColor onClick={openChat}>
        message us
      </Link>
      .
    </Alert>
  );
};

export default HellosignAlert;
