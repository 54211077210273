/* eslint-disable react/display-name */
import React, { Component, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { FlexColumn } from '@summer/ui-components';

import IDRFooter from 'components/pages/idr/wizard/layout/IDRFooter';
import IDRHeader from 'components/pages/idr/wizard/layout/IDRHeader';
import IDRPages from 'components/pages/idr/wizard/layout/IDRPages';
import GradStatus from 'components/pages/idr/wizard/steps/YourLoansPlaid/QuestionnaireWizard/steps/GradStatus';
import InIdrPlan from 'components/pages/idr/wizard/steps/YourLoansPlaid/QuestionnaireWizard/steps/InIdrPlan';
import ParentPlus from 'components/pages/idr/wizard/steps/YourLoansPlaid/QuestionnaireWizard/steps/ParentPlus';
import PlanType from 'components/pages/idr/wizard/steps/YourLoansPlaid/QuestionnaireWizard/steps/PlanType';
import RecertificationDate from 'components/pages/idr/wizard/steps/YourLoansPlaid/QuestionnaireWizard/steps/RecertificationDate';
import { MobileSize } from 'constants/styleguide';
import useTrackStepChanges from 'hooks/Wizard/useTrackStepChanges';
import { useWizard } from 'hooks/Wizard/useWizard';
import {
  checkIfThisIsLastStep,
  getCurrentIdrForm,
  getIdrCalculations,
  getIdrWizardSteps,
  getStepFromLocation,
} from 'redux/selectors/idr.selectors';
import { getPlaidLoans } from 'redux/selectors/loans.selectors';
import {
  getQuestionnaireInitialStep,
  getShouldShowQuestionnaire,
} from 'redux/selectors/ui.selectors';
import { getIdrOnboardingQuestion } from 'redux/selectors/user.selectors';
import { getNormalizedPlaidLoans } from 'utils/loans';
import { pickProfile, pickFinancialProfile } from 'utils/profile';

class IDRForm extends Component {
  componentDidMount = () => {
    this.props.setValues(this.props.idrState);
  };

  componentDidUpdate = prevProps => {
    /**
     * only do this for POP actions to handle users that use their browser's
     * back button instead of the IDR back button
     */
    if (
      this.props.history.action === 'POP' &&
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      const current = this.props.idrCurrentStep;
      const previous = getStepFromLocation({
        router: {
          location: prevProps.location,
        },
        IDR: {
          calculations: this.props.idrCalculations,
        },
      });

      if (current === previous - 1) {
        this.handleStepBack(current);
      }
    }
  };

  handleStepBack = step => {
    let stepBack = step;
    if (typeof stepBack !== 'number') {
      stepBack = undefined;
    }
    this.props.setValues(this.props.idrState);
    const prevStep = stepBack || this.props.idrCurrentStep - 1;
    this.props.saveIdrCurrentStep(prevStep);
  };

  handleClose = () => {
    const { values, idrCurrentStep } = this.props;
    const { formId } = this.props.idrState;
    let payload = {
      form: { formId },
      currentStep: idrCurrentStep,
    };

    if (idrCurrentStep === 1) {
      // Tell Us About You
      payload = {
        ...payload,
        financialProfile: pickFinancialProfile(values.tellUsAboutYou),
        profile: pickProfile(values.tellUsAboutYou),
      };
    }

    this.props.updateCurrentIdrForm(payload, { close: true });
  };

  render() {
    const {
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      idrWizardSteps,
      idrCurrentStep,
      isValid,
      isStepBackValid,
      isSubmitting,
      location,
      setFieldTouched,
      setFieldValue,
      setTouched,
      submitForm,
      touched,
      validateForm,
      values,
      resetSubmitCount,
      incrementSubmitCount,
      currentStepSubmitCount,
      isThisLastStep,
      plaidLoanQuestionnaireSteps,
      questionnaireNavigation,
      shouldShowQuestionnaire,
    } = this.props;

    const { moveToStep } = questionnaireNavigation;

    return (
      <Form>
        <IDRHeader
          handleClose={this.handleClose}
          wizardSteps={idrWizardSteps}
          currentStep={idrCurrentStep}
          handleStepBack={this.handleStepBack}
          location={location}
          moveQuestionnaireToStep={moveToStep}
        />
        <Content>
          <FormContent>
            <IDRPages
              idrValues={values}
              idrTouched={touched}
              idrErrors={errors}
              idrHandlers={{
                isSubmitting,
                handleChange,
                handleSubmit,
                incrementSubmitCount,
                handleBlur,
                submitForm,
                setFieldValue,
                setFieldTouched,
                setTouched,
                validateForm,
              }}
              isValid={isValid}
              resetSubmitCount={resetSubmitCount}
              currentStepSubmitCount={currentStepSubmitCount}
              plaidLoanQuestionnaireSteps={plaidLoanQuestionnaireSteps}
            />
          </FormContent>
        </Content>
        {/* hide IDR footer if questionnaire footer is rendered */}
        {!shouldShowQuestionnaire && (
          <IDRFooter
            errors={errors}
            isThisLastStep={isThisLastStep}
            handleStepBack={this.handleStepBack}
            isStepBackValid={isStepBackValid}
            isCurrentStepValid={isValid}
            currentStep={idrCurrentStep}
            handleSubmit={handleSubmit}
            resetSubmitCount={resetSubmitCount}
            incrementSubmitCount={incrementSubmitCount}
            currentStepSubmitCount={currentStepSubmitCount}
            questionnaireNavigation={questionnaireNavigation}
          />
        )}
      </Form>
    );
  }
}

const Content = styled.div`
  align-items: center;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 32px 32px 0 32px;

  @media (max-width: ${MobileSize}) {
    padding: 16px 16px 0 16px;
    overflow: visible;
    flex: 1;
    height: unset;
  }
`;

const FormContent = styled.div`
  padding-bottom: 96px;

  @media (max-width: ${MobileSize}) {
    padding-bottom: 32px;
  }
`;

const Form = styled(FlexColumn)`
  height: 100%;
`;

const mapStateToProps = state => ({
  idrCurrentStep: getStepFromLocation(state),
  idrWizardSteps: getIdrWizardSteps(state),
  isThisLastStep: checkIfThisIsLastStep(state),
  idrState: getCurrentIdrForm(state),
  initialStep: getQuestionnaireInitialStep(state),
  isCurrentlyEnrolledInIDR: getIdrOnboardingQuestion(state),
  /* TODO: [ch29629] Use selector getPlaidFederalLoans */
  plaidLoans: getNormalizedPlaidLoans(getPlaidLoans(state)),
  shouldShowQuestionnaire: getShouldShowQuestionnaire(state),
  idrCalculations: getIdrCalculations(state),
});

// As a short-term solution, instead of refactoring the IDRForm to be a functional component, wrapping it with a funcational component so that we can use this hook.
const IDRFormContainer = props => {
  const { isCurrentlyEnrolledInIDR, plaidLoans, initialStep } = props;

  const config = useMemo(
    () => [
      {
        title: 'Graduate Status',
        render: props => <GradStatus {...props} />,
      },
      {
        title: 'Idr Plan',
        render: props => <InIdrPlan {...props} />,
      },
      {
        title: 'Plan Type',
        render: props => <PlanType {...props} />,
        condition: isCurrentlyEnrolledInIDR === 'yes',
      },
      {
        title: 'Recertification Date',
        render: props => <RecertificationDate {...props} />,
        condition: isCurrentlyEnrolledInIDR === 'yes',
      },
      {
        title: 'Parent Plus',
        render: props => <ParentPlus {...props} />,
        condition: plaidLoans.some(
          loan => loan.federalLoanType?.isConsolidation,
        ),
      },
    ],
    [isCurrentlyEnrolledInIDR, plaidLoans],
  );

  const { steps, navigation } = useWizard(
    {
      config,
      initialPath: initialStep,
    },
    useTrackStepChanges,
  );

  return (
    <IDRForm
      {...props}
      plaidLoanQuestionnaireSteps={steps}
      questionnaireNavigation={navigation}
    />
  );
};

export default withRouter(connect(mapStateToProps)(IDRFormContainer));
