import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import PlanCard from 'components/pages/idr/wizard/steps/ReviewYourPlan/NewRYP/PlanCard';
import { setIdrSelectedPathId } from 'redux/actions/idr.actions';
import { shouldUsePlaidFlow } from 'redux/selectors/plaid.selectors';

const CurrentPlan = ({ isPslfOn, shouldUsePlaidFlow, currentPath }) => {
  let path = currentPath;
  let loansSource = null;

  if (shouldUsePlaidFlow) {
    // TODO: [ch30192] Remove after Python is updated to send null planType for loanSource of plaid
    path = {
      ...currentPath,
      planType: [],
    };
    loansSource = 'plaid';
  }

  return (
    <PlanCard
      key="current-path"
      isPslfOn={isPslfOn}
      path={path}
      isCurrentPath
      loansSource={loansSource}
    />
  );
};

const Plans = ({ isPslfOn, shouldUsePlaidFlow, paths, selectPath }) => {
  return paths.map((path, idx) => (
    <PlanCard
      key={idx}
      isPslfOn={isPslfOn}
      path={path}
      showRecBadge={paths.length > 1}
      selectPath={() => {
        selectPath(path.id);
      }}
      loansSource={shouldUsePlaidFlow ? 'plaid' : null}
    />
  ));
};

const PlanOptions = ({
  isPslfOn,
  currentPath,
  paths,
  setIdrSelectedPathId,
  handleSubmit,
  incrementSubmitCount,
  shouldUsePlaidFlow,
}) => {
  return (
    <PlansContainer>
      <CurrentPlan
        isPslfOn={isPslfOn}
        currentPath={currentPath}
        shouldUsePlaidFlow={shouldUsePlaidFlow}
      />
      <Plans
        isPslfOn={isPslfOn}
        shouldUsePlaidFlow={shouldUsePlaidFlow}
        paths={paths}
        selectPath={pathId => {
          setIdrSelectedPathId(pathId);
          incrementSubmitCount();
          handleSubmit();
        }}
      />
    </PlansContainer>
  );
};

const PlansContainer = styled.div`
  max-width: 1024px;
  margin: auto;
`;

const mapStateToProps = state => ({
  shouldUsePlaidFlow: shouldUsePlaidFlow(state),
});

export default connect(mapStateToProps, { setIdrSelectedPathId })(PlanOptions);
