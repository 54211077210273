import { get, isNil } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { FormStatus } from '@simplifidev/shared/dist/constants/forms';

import {
  TabGroup,
  Text,
  Container as GridContainer,
  ResponsiveColumn,
  Row,
} from '@summer/ui-components';

import PslfWaiverAlert from 'components/pages/pslf/dashboard/PslfWaiverAlert';
import WhatsNextFooter from 'components/pages/pslf/dashboard/WhatsNextFooter';
import CompletePage from 'components/pages/pslf/dashboard/myPslfCertifications/CompletePage';
import StartPage from 'components/pages/pslf/dashboard/myPslfCertifications/StartPage';
import PrivacyPolicyLink from 'components/shared/PrivacyPolicyLink';
import LandingPage from 'components/shared/dashboard/LandingPage';
import Link from 'components/shared/typography/Link';
import { usePlatform } from 'hooks/platform';
import { fetchLoans } from 'redux/actions/loans.actions';
import { fetchPslfForms } from 'redux/actions/pslf.actions';
import { getLoansForPslf } from 'redux/selectors/loans.selectors';
import { getUserPslfForms } from 'redux/selectors/pslf.selectors';
import { isLoadingWithInit } from 'redux/selectors/ui.selectors';
import { pslfLoanSchema } from 'schemas/loans';
import { forceShowPslfCompletePage } from 'services/featureFlags/flags';
import { getNormalizedFsaLoans } from 'utils/loans';
import {
  checkPslfCompleteLocalStorage,
  hasLoansToConsolidate,
} from 'utils/pslf';

const Subheader = () => (
  <Text paragraph weight="medium">
    <Link
      to={{ pathname: '/guides/pslf', state: { goBack: true } }}
      inheritColor
      underline
      inheritWeight
    >
      PSLF
    </Link>{' '}
    is a federal program for borrowers who work in Public Service. It forgives
    any remaining federal student debt after 120 qualifying payments while
    working in public service.
  </Text>
);

const PslfDashboard = ({
  pslfForms,
  isLoading,
  dispatch,
  location,
  /* [sc-31819] Hides the Loan Forgiveness Tracker feature */
  // isFetchLoansLoading,
  userNormalizedLoansForPslf,
}) => {
  useEffect(() => {
    dispatch(fetchPslfForms());
    dispatch(fetchLoans());
  }, [dispatch]);

  const numberOfForms = get(pslfForms, 'length');

  // TODO [ch12771] remove this when we launch PSLF
  // We need this because of how we feature-flag the PSLF redux state
  // A page refresh is required after turning on the flag in order for the
  // PSLF redux state to populate.
  if (isNil(numberOfForms)) {
    window.location.reload(false);
  }

  const bypassStartPage = forceShowPslfCompletePage(location);

  // If a user has one form, we show start page if they are at a step
  // before Hellosign. Otherwise they should be shown the complete page
  // where they sign the form.
  const showStartPage =
    (numberOfForms === 1
      ? get(pslfForms, '[0].status') === FormStatus.STARTED &&
        checkPslfCompleteLocalStorage() !== 'true'
      : numberOfForms < 1) && !bypassStartPage;

  const startPageConfig = {
    content: <StartPage />,
  };

  const completePageConfig = {
    content: <CompletePage />,
    footer: <WhatsNextFooter />,
  };

  const hasLoansForConsolidation = hasLoansToConsolidate(
    userNormalizedLoansForPslf,
  );

  const { windowWidth } = usePlatform();

  return (
    <LandingPage
      headerTheme="gold"
      headerName="Public Service Loan Forgiveness (PSLF)"
      subHeader={<Subheader />}
    >
      <GridContainer>
        <GridRow>
          {/* TODO: [ch25377] Add properties for smallDesktopCol and check widths 1025px-1110px */}
          <ResponsiveColumn
            desktopCol={windowWidth > 1200 ? 8 : 12}
            mobileCol={12}
            tabletCol={12}
          >
            <PslfWaiverAlert />
            <TabGroup
              baseUrl="/pslf"
              tabs={[
                {
                  title: 'My PSLF Certifications',
                  ...(showStartPage ? startPageConfig : completePageConfig),
                  loading: isLoading,
                },
              ]}
            />
            <WhatsNextFooter
              shouldShowConsolidateAlert={hasLoansForConsolidation}
            />
            <PrivacyPolicyLinkPSLFPage />
          </ResponsiveColumn>
        </GridRow>
      </GridContainer>
    </LandingPage>
  );
};

const GridRow = styled(Row)`
  position: relative;
  margin-top: 32px;
  z-index: 1;
  justify-content: center;
  padding-bottom: 32px;
`;

const PrivacyPolicyLinkPSLFPage = styled(PrivacyPolicyLink)`
  justify-content: flex-start;
  text-align: left;
  padding: 22px 0px 35px;
`;

const mapStateToProps = state => ({
  pslfForms: getUserPslfForms(state),
  isLoading:
    isLoadingWithInit(state, 'fetchPslfForms') ||
    isLoadingWithInit(state, 'fetchLoans'),
  isFetchLoansLoading: isLoadingWithInit(state, 'fetchLoans'),
  userNormalizedLoansForPslf: getNormalizedFsaLoans(
    getLoansForPslf(state),
    pslfLoanSchema,
  ),
});

export default connect(mapStateToProps)(PslfDashboard);
