import React from 'react';
import styled from 'styled-components';

import { Card, Header, SvgImage, Text } from '@summer/ui-components/src';

import { ReactComponent as CelebrationSvg } from 'components/pages/employerContributionV1/dashboard/assets/celebration.svg';
import SlcLandingPage from 'components/pages/employerContributionV1/shared/slcLandingPage';
import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';
import { MobileSize, TabletSize } from 'constants/styleguide';

const SlcSuccessPage = () => {
  return (
    <SlcLandingPage>
      <Container>
        <SuccessCard>
          <Card>
            <CardContent>
              <MainContent>
                <TextHolder>
                  <Header as="h1">
                    Hooray! You are successfully enrolled in student loan
                    contribution!
                  </Header>
                  <Item>
                    <Text paragraph>
                      Be on the lookout for an email from the Summer team for
                      details around what to expect next including when you
                      should expect your next contribution payment to be made.
                    </Text>
                  </Item>
                  <Item>
                    <strong>Questions?</strong>{' '}
                    <Link href={`mailto:${supportAddress}`}>
                      {supportAddress}
                    </Link>
                  </Item>
                </TextHolder>
                <SvgImage
                  SvgComponent={CelebrationSvg}
                  width={297}
                  height={297}
                />
              </MainContent>
              <Text paragraph small color="secondary">
                This contribution from your employer may not cover your entire
                monthly student loan bill. Make sure you check with your loan
                servicer to make sure you are paying your bill on time and in
                full to avoid penalties.
              </Text>
            </CardContent>
          </Card>
        </SuccessCard>
      </Container>
    </SlcLandingPage>
  );
};

const Container = styled.div`
  justify-content: center;
  display: flex;
`;

const SuccessCard = styled.div`
  position: relative;
  z-index: 1;
  max-width: 850px;
  margin: 16px 0;
  @media (min-width: ${MobileSize}) {
    margin-top: 100px;
  }
`;

const TextHolder = styled.div`
  @media (min-width: ${TabletSize}) {
    margin-right: 64px;
  }
`;

const Item = styled.div`
  margin: 1.5em 0;
`;

const CardContent = styled.div`
  text-align: left;
  @media (min-width: ${MobileSize}) {
    margin: 8px 16px;
  }
`;

const MainContent = styled.div`
  @media (min-width: ${MobileSize}) {
    flex-direction: row;
  }
  display: flex;
  flex-direction: column;
`;

export default SlcSuccessPage;
