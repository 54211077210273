import {
  FETCH_ALL_FF_PROGRAMS,
  FETCH_FF_PROGRAM_BY_UID,
  SET_FF_PROGRAMS,
  SET_FF_FILTERS,
} from 'constants/actionTypes';

export const fetchAllPrograms = (page, pageSize) => ({
  type: FETCH_ALL_FF_PROGRAMS,
  payload: { page, pageSize },
});

export const fetchProgramById = uid => ({
  type: FETCH_FF_PROGRAM_BY_UID,
  payload: {
    uid,
  },
});

export const setPrograms = docs => ({
  type: SET_FF_PROGRAMS,
  payload: {
    docs,
  },
});

export const setFilters = filters => ({
  type: SET_FF_FILTERS,
  payload: {
    filters,
  },
});
