import { set } from 'lodash/fp';

import {
  TOGGLE_IDR_QUESTIONNAIRE,
  SET_QUESTIONNAIRE_INITIAL_STEP,
} from 'constants/actionTypes';

const initialState = {
  questionnaire: {
    shouldShowQuestionnaire: false,
    initialStep: '1',
  },
};

const idr = (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_IDR_QUESTIONNAIRE:
      return set('questionnaire.shouldShowQuestionnaire', payload, state);

    case SET_QUESTIONNAIRE_INITIAL_STEP:
      return set('questionnaire.initialStep', payload, state);

    default:
      return state;
  }
};

export default idr;
