import React from 'react';

import { Strong } from '@summer/ui-components';

import {
  List,
  ListItem,
  SmallTitle,
  GuideText,
} from 'components/pages/resourceCenter/guides/shared';
import Section from 'components/pages/resourceCenter/guides/shared/Section';
import { Link } from 'components/shared/typography';
import { nsldsFindServicerLink, nsldsLink } from 'constants/externalLinks/fsa';
import { idrGuideLink } from 'constants/externalLinks/marketingSite';
import { freCreditReportLink } from 'constants/externalLinks/resources';

class RecentGraduatesGuide extends React.Component {
  render() {
    return (
      <>
        <Section title="Loan Basics 101">
          <GuideText>
            Time to learn a few key ideas that&rsquo;ll help you through the
            rest of the process. Let&rsquo;s start at the beginning.
          </GuideText>

          <SmallTitle>What is a loan?</SmallTitle>
          <GuideText>
            Simple question, right? Not always. A <Strong>loan</Strong> is money
            you borrowed from a <Strong>lender</Strong>—whether that&rsquo;s the
            US government or a bank—that you have to pay back. But not all loans
            are the same. Here are the two types of loans you may have:
          </GuideText>

          <List>
            <ListItem>
              Federal loans: Money you&rsquo;ve borrowed from the U.S.
              government. These kinds of loans usually have lower interest rates
              (we&rsquo;ll get to what that means in a bit) and more flexible
              options if you can&rsquo;t make you payments.
            </ListItem>
            <ListItem>
              Private loans: Money you&rsquo;ve borrowed from a bank or a credit
              union.
            </ListItem>
          </List>

          <GuideText>
            <Strong>Note:</Strong> Keep in mind, loans aren&rsquo;t the same
            thing as grants. If you received a grant for school, you don&rsquo;t
            have to pay that money back.
          </GuideText>

          <SmallTitle>
            What does &ldquo;principal balance&rdquo; mean?
          </SmallTitle>
          <GuideText>
            The <Strong>principal balance</Strong> on your loan is the remaining
            amount of money owed on your loan. Keep in mind, the principal
            balance doesn&rsquo;t include interest.
          </GuideText>

          <SmallTitle>How does interest work?</SmallTitle>
          <GuideText>
            <Strong>Interest</Strong> is the amount of money you have to pay
            back on top of what you borrowed. You can think of it as a fee you
            pay to borrow money.
          </GuideText>

          <GuideText>
            Your <Strong>interest rate</Strong> tells you exactly how much extra
            you owe on your loan. The higher the interest rate, the more
            expensive your loan, and the more you’ll have to pay back.
          </GuideText>

          <SmallTitle>Can you give me an example?</SmallTitle>
          <GuideText>
            Say that you borrowed $35,000 for school at a 6% interest rate.
            You&rsquo;re going to have to pay back $389 per month over 10 years,
            which adds up to $46,629 in total. The extra $11,629 that makes up
            the difference between what you borrowed and what you&rsquo;ll need
            to pay back is the <Strong>interest</Strong> on your loan, the
            amount you pay for being able to borrow.
          </GuideText>

          <SmallTitle>You&rsquo;re not alone</SmallTitle>
          <GuideText>
            Here are some stats about student loan borrowers:
          </GuideText>

          <List>
            <ListItem>
              Average debt load for new graduates: <Strong>$37,172</Strong>
            </ListItem>
            <ListItem>
              Average monthly student loan payment: <Strong>$393</Strong>
            </ListItem>
            <ListItem>
              Number of Americans with student loans:{' '}
              <Strong>44 million</Strong>
            </ListItem>
            <ListItem>
              Ratio of graduates with student debt: <Strong>3 in 4</Strong>
            </ListItem>
            <ListItem>
              Total amount of student debt in the United States:{' '}
              <Strong>$1.4 trillion</Strong>
            </ListItem>
          </List>
        </Section>

        <Section title="Track Down Your Loans">
          <GuideText>
            So how do you know how much money you&rsquo;ve borrowed&ndash;and
            who your lenders are? We can help.
          </GuideText>

          <SmallTitle>How do I find my federal loans?</SmallTitle>
          <GuideText>
            You can find all your federal loans at the{' '}
            <Link href={nsldsLink}>
              National Student Loan Data System (NSLDS) website
            </Link>
            . If you&rsquo;ve already added your federal loans to your Summer
            account, then you’re already finished with this step and can review
            your loans on your account.
          </GuideText>

          <SmallTitle>What about my private loans?</SmallTitle>
          <GuideText>
            You can find all your private loans on your credit report. You can
            get a free credit report{' '}
            <Link href={freCreditReportLink}>here</Link>, which should list all
            of your loans. It&rsquo;s a great way to make sure you’re not
            leaving out any of your loans.
          </GuideText>
        </Section>

        <Section title="Know Your Servicers">
          <GuideText>
            When it comes to paying back loans, your servicer handles all your
            payments and account details. It&rsquo;s time to get to know your
            servicer(s) and what they do.
          </GuideText>

          <SmallTitle>What is a servicer?</SmallTitle>
          <GuideText>
            Your <Strong>servicer</Strong> is the company that collects your
            payments. Sometimes, your lender and servicer are the same
            institution, but more often than not they&rsquo;re different. Your{' '}
            <Strong>lender</Strong> is the financial institution that provided
            the loan, whether that&rsquo;s the government or a bank.
          </GuideText>

          <GuideText>
            If you have federal loans, you can find your servicer at the{' '}
            <Link href={nsldsFindServicerLink}>
              National Student Loan Data System (NSLDS)
            </Link>{' '}
            website or, if you uploaded your loans to your Summer account, you
            can find your servicer there.
          </GuideText>

          <GuideText>
            Here is a list of the largest federal loan servicers
          </GuideText>
          <List>
            <ListItem>AidVantage</ListItem>
            <ListItem>Edfinancial</ListItem>
            <ListItem>MOHELA</ListItem>
            <ListItem>Nelnet</ListItem>
            <ListItem>ECSI</ListItem>
          </List>

          <GuideText>
            If you have <Strong>private loans</Strong>, you can find your
            servicer by checking with your lender. If your loans are ever moved
            to a different servicer, you should receive an email or letter
            letting you know.
          </GuideText>

          <SmallTitle>Stay in touch with your servicer</SmallTitle>
          <GuideText>
            Whenever any of your contact information changes, make sure to let
            your servicer know. That way they can keep you up to date on
            anything that changes with your loans.
          </GuideText>
        </Section>

        <Section title="Know When And How Much Pay">
          <GuideText>
            Keeping up to date on what you owe each month and when you have to
            make payments is the best way to make sure you never fall behind.
          </GuideText>

          <SmallTitle>
            First, check to see if your loans are in a grace period
          </SmallTitle>
          <GuideText>
            After you leave school, your loans are in what&rsquo;s called a
            &ldquo;grace period.&rdquo; That means you don&rsquo;t need to make
            a payment on your loans right away.
          </GuideText>

          <List>
            <ListItem>
              If you have federal Direct Loans, you have a 6 month grace period.
            </ListItem>
            <ListItem>
              With federal Perkins Loans, you have a 9 month grace period.
            </ListItem>
            <ListItem>
              For private loans, you&rsquo;ll typically have grace period
              between 6 and 9 months, but you should check with your servicer so
              you know the exact dates.
            </ListItem>
            <ListItem>
              If you are or were a graduate student and have undergraduate
              loans, your undergraduate loans don&rsquo;t have a grace period.
              You&rsquo;ll have to start repayment as soon as you finish school.
            </ListItem>
          </List>

          <SmallTitle>Find your monthly payment</SmallTitle>
          <GuideText>
            Your servicer will tell you how much you owe each month. If you ever
            think that you&rsquo;re going to miss a payment, consider{' '}
            <Link href={idrGuideLink}>income-driven repayment (IDR)</Link> or
            reach out to yourservicer right away.
          </GuideText>

          <GuideText>
            If you have <Strong>federal loans</Strong>, you&rsquo;re
            automatically placed in a 10-year standard repayment plan. Four
            months after you leave school, you have the option of enrolling in a
            government repayment plan that lowers your monthly payment. Summer
            will let you know when the time comes to see if it’s a good fit for
            you.
          </GuideText>

          <GuideText>
            For <Strong>private loans</Strong>, your monthly payment depends on
            what the terms of your loan were when you took it out. Check with
            your servicer to find out how much you&rsquo;ll need to pay each
            month.
          </GuideText>

          <SmallTitle>Set up automatic-payments</SmallTitle>
          <GuideText>
            If you have enough money in the bank (and your servicer allows it),
            setting up automatic monthly payments, also known as{' '}
            <Strong>auto-debit</Strong> or <Strong>direct debit</Strong> is a
            great way to make sure you never fall behind. Most servicers will
            even give you a 0.25% interest rate reduction if you set up
            auto-payments, which can save you up to thousands of dollars in the
            long run.
          </GuideText>

          <SmallTitle>Set up calendar reminders</SmallTitle>
          <GuideText>
            Once you know when you have to start repaying your loans, set up
            recurring calendar reminders—whether that&rsquo;s a note on the
            calendar on your wall or on your favorite calendar app—so that you
            never miss a payment.
          </GuideText>
        </Section>

        <Section title={'If You Can\u2019t Make Your Payment'}>
          <GuideText>
            Missing a payment can really hurt. It can damage your credit score,
            which can mess up things like getting a car or renting an apartment.
            It&rsquo;s nearly impossible to get rid of your student loans (as
            much as you might wish you could), so it&rsquo;s best to stay on top
            of them. But don&rsquo;t worry: we can help with that.
          </GuideText>

          <SmallTitle>
            What if I think I&rsquo;m going to miss a payment?
          </SmallTitle>
          <GuideText>
            If you don’t think you’ll be able to make a monthly payment,
            consider{' '}
            <Link href={idrGuideLink}>income-driven repayment (IDR)</Link> as an
            option, or there are options known as <Strong>deferment</Strong>{' '}
            &amp; <Strong>forbearance</Strong> that can let you to temporarily
            pause payment on your loans. These options could make your loan
            balance grow, but it&rsquo;s a lot better than missing a payment,
            which could hurt you more in the long run. Summer’s free payment
            estimator tool can help calculate your estimated payment in an
            income-driven repayment plan. It’s worth staying proactive when it
            comes to your loans; don’t wait to miss a payment before taking
            action.
          </GuideText>

          <SmallTitle>More options for federal loans</SmallTitle>
          <GuideText>
            If you have got <Strong>federal loans</Strong>, you have got more
            options than people with private loans if you run into trouble
            keeping up with your monthly payments. You can enroll in{' '}
            <Link href={idrGuideLink}>
              programs that could help you lower your monthly payments
            </Link>{' '}
            so you don&rsquo;t miss any. If you don&rsquo;t have a job right
            now, you could even lower your monthly payment to $0 per month. Keep
            your information up to date with Summer, and we’ll help make sure
            you never miss a payment.
          </GuideText>
        </Section>

        <Section title="Tips To Save Money">
          <GuideText>
            There are options that could help you save money while you make
            payments on your loans. Here are a few tips for conserving your
            cash.
          </GuideText>

          <SmallTitle>Pay on time to save</SmallTitle>
          <GuideText>
            If you have federal loans and make your first 12 monthly payments on
            time, you could get a rebate on the amount you borrowed. For federal
            Direct Loans, the rebate is 0.5%. For Federal Direct Grad PLUS
            loans, you could get a rebate of 1.5%. That&rsquo;s money back in
            your bank account. Awesome!
          </GuideText>

          <SmallTitle>
            Pay down accrued interest before you enter repayment
          </SmallTitle>
          <GuideText>
            If your loans accrue interest while you are in school and you
            don&rsquo;t pay it off, you&rsquo;ll have what&rsquo;s called{' '}
            <Strong>capitalized interest</Strong>. Essentially, that means that
            you’ll be paying interest on interest, which could greatly increase
            the cost of your loan in the long run. Summer will let you know know
            when it’s time to pay down your accrued interest so you can make
            sure you’re maximizing your savings.
          </GuideText>

          <SmallTitle>Pay off your high interest loans first</SmallTitle>
          <GuideText>
            If you can&rsquo;t make payments on all of your loans, you should
            generally pay off your highest interest loans first. If you&rsquo;re
            ever in this situation, make sure to contact your servicer to see if
            they can find a payment plan that works better for you.
          </GuideText>

          <SmallTitle>Overpay on your loans if you can</SmallTitle>
          <GuideText>
            Just received a bonus at work or some extra birthday money from
            Grandma? It&rsquo;s a good idea to contact your servicer to let them
            know you’d like to make an extra payment on your loans. If you have
            federal loans, though, you should only overpay if you&rsquo;re not
            enrolled in an income-driven repayment plan.
          </GuideText>

          <SmallTitle>Consider refinancing</SmallTitle>
          <GuideText>
            <Strong>Refinancing</Strong> your loans means getting a new loan
            that pays off your old loan. People refinance loans to get better
            terms with the new loan.
          </GuideText>

          <GuideText>
            For federal loans, you should think twice before refinancing, which
            will cause you to lose the federal loan benefits like loan
            forgiveness programs and lower monthly payments if you run into
            trouble. For private loans, you should generally refinance your
            loans if you qualify. Summer will stay in touch with you to see if
            refinancing is a good fit for you.
          </GuideText>
        </Section>

        <Section title="Other Helpful Tips">
          <GuideText>
            Here are a few more things to know when it comes to paying off your
            student loans.
          </GuideText>

          <SmallTitle>Think about consolidation</SmallTitle>
          <GuideText>
            Consolidation lets you to combine all of your loans into one. This
            lets you make one payment to one servicer instead of paying several
            different servicers. It&rsquo;s important to keep in mind, however,
            that consolidating a federal loan could keep you from taking
            advantage of some of the benefits federal loans offer, like lowering
            your monthly payments or even pausing your payments.
          </GuideText>

          <SmallTitle>Watch out for scams</SmallTitle>
          <GuideText>
            There are tons of student loan relief scams out there, so make sure
            to keep an eye out and avoid them like the plague. Any government
            program for loan repayment are free for you to enroll in, so you
            never need to pay a third party to help you get into these programs.
          </GuideText>

          <SmallTitle>Find out about employer contributions</SmallTitle>
          <GuideText>
            Check with your job to see if they contribute to paying down your
            student loans. If you’re negotiating a job offer, ask your potential
            employer if making contributions is something they&rsquo;re willing
            to do.
          </GuideText>

          <SmallTitle>Get your tax benefits</SmallTitle>
          <GuideText>
            You can take a student loan interest rate deduction on your taxes of
            up to $2,500. If you&rsquo;ve got an accountant your work with for
            your taxes, make sure you check with them about getting any
            deductions you can. Tools like Turbo Tax can also help you get these
            deductions.
          </GuideText>
        </Section>
      </>
    );
  }
}

export default RecentGraduatesGuide;
