import React from 'react';
import styled from 'styled-components';

import { COLORS } from '../../helpers/constants/styleguide';

import { Text } from '../typography';
import Icon, { CheckNew } from '../Icon';

const CheckList = ({ items }) => {
  return (
    <List>
      {items.map((item, i) => (
        <li key={i}>
          <CheckmarkIcon />
          <Text paragraph>{item}</Text>
        </li>
      ))}
    </List>
  );
};

const CheckmarkIcon = () => (
  <Icon width={13} SvgComponent={CheckNew} fill={COLORS.jade} />
);

const List = styled.ul`
  list-style-type: none;

  & li {
    display: flex;
    margin-bottom: 16px;
    align-items: baseline;

    :last-child {
      margin-bottom: 0;
    }

    .text {
      display: inline;
    }

    .icon {
      display: inline;
      margin-right: 8px;
    }
  }
`;

export default CheckList;
