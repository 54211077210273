import { object, number, string, boolean } from 'yup';

const federalLoanTypeSchema = {
  description: string().required(),
  id: number().required(),
  type: string().required(),
  isDirect: boolean().required(),
  isFFEL: boolean().required(),
  isPerkins: boolean().required(),
  isSubsidized: boolean().required(),
  isConsolidation: boolean().required(),
  isParentPlus: boolean().required(),
};

export const plaidFederalLoanSchema = object()
  .shape({
    federalLoanType: object()
      .required()
      .shape({ ...federalLoanTypeSchema }),
    dateBorrowed: string().required(),
    currentBalance: number().required(),
    amountBorrowed: number().when('currentBalance', {
      is: currentBalance => ![0, null].includes(currentBalance),
      then: number().notOneOf([0]).required(),
      otherwise: number().nullable(),
    }),
    interestRate: number().when('currentBalance', {
      is: currentBalance => ![0, null].includes(currentBalance),
      then: number().notOneOf([0]).required(),
      otherwise: number().nullable(),
    }),
    outstandingInterestBalance: number().when('currentBalance', {
      is: currentBalance => ![0, null].includes(currentBalance),
      then: number().required(),
      otherwise: number().nullable(),
    }),
  })
  .noUnknown(false);
