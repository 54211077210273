import { createSelector } from 'reselect';

export const getFileCollectionsState = state => state.fileCollections;

export const getRetirementMatchDocuments = createSelector(
  getFileCollectionsState,
  fileCollections => {
    return fileCollections.retirementMatchPaymentProof || [];
  },
);

export const getTuitionReimbursementAttachments = createSelector(
  getFileCollectionsState,
  filesCollections => filesCollections.tuitionReimbursementAttachment || [],
);

export const getTuitionReimbursementPaymentProof = createSelector(
  getFileCollectionsState,
  filesCollections => filesCollections.tuitionReimbursementPaymentProof || [],
);

export const getTuitionReimbursementCompletionProof = createSelector(
  getFileCollectionsState,
  filesCollections =>
    filesCollections.tuitionReimbursementCompletionProof || [],
);

export const getEmployerContributionStatement = createSelector(
  getFileCollectionsState,
  filesCollections => filesCollections.studentLoanContribution || [],
);
