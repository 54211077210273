import { lowerCase, isBoolean } from 'lodash';
import map from 'lodash/fp/map';

export const dataToOptions = (data, valueName, labelName) => {
  return map(
    item => ({
      label: item[labelName],
      value: item[valueName].toString(),
    }),
    data,
  );
};

export const arrayToOptions = data => {
  return map(
    item => ({
      label: item,
      value: item,
    }),
    data,
  );
};

// this function converts 'true' / 'false' to boolean values
// handles accidentally passing already converted values
export const stringToBool = input => {
  if (isBoolean(input)) {
    return input;
  }
  if (lowerCase(input) === 'true') {
    return true;
  }
  if (lowerCase(input) === 'false') {
    return false;
  }

  return null;
};

// this function converts true / false to string values
// handles accidentally passing already converted values
export const boolToString = input => {
  if (input === true || lowerCase(input) === 'true') {
    return 'true';
  }
  if (input === false || lowerCase(input) === 'false') {
    return 'false';
  }

  return null;
};
