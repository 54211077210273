import { differenceInCalendarDays, format, parseISO } from 'date-fns';

export const timeRemainingUntilDate = futureDate => {
  /* this function will take a date and return a string
   * of the number of weeks until that date, (ex. '5 weeks'), or, if
   * there are less than two weeks between the dates, it will return
   * a string of the number of days(ex. '8 days').
   * @param {string} - a date string
   * @returns {string}
   */
  const numberOfDays = differenceInCalendarDays(
    parseISO(futureDate),
    new Date(),
  );

  if (numberOfDays < 1) {
    // don't return negative numbers of dates if the futureDate has already passed
    return '0 days';
  }
  if (numberOfDays < 14) {
    return `${numberOfDays} days`;
  }
  return `${Math.floor(numberOfDays / 7)} weeks`;
};

export const yearsAndMonths = totalMonths => {
  const numberOfYears = Math.floor(totalMonths / 12);
  const remainingMonths = totalMonths % 12;
  return {
    years: `${
      numberOfYears
        ? `${numberOfYears} year${numberOfYears > 1 ? 's' : ''}`
        : ''
    }`,
    months: `${
      numberOfYears > 0 && remainingMonths === 0
        ? ''
        : `${remainingMonths} month${remainingMonths === 1 ? '' : 's'}`
    }`,
  };
};

export const generateDayMonthYearCopy = inputDate => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = date.toLocaleString('default', {
    month: 'short',
    timeZone: 'UTC',
  });
  const day = date.toLocaleString('default', {
    day: '2-digit',
    timeZone: 'UTC',
  });

  return `${day} ${month} ${year}`;
};

export const formatDate = (date, dateFormat) => {
  const dateToBeFormatted = typeof date === 'string' ? parseISO(date) : date;

  if (!isValidDate(dateToBeFormatted)) {
    return '-';
  }

  return format(dateToBeFormatted, dateFormat);
};

export const formatDateRange = (
  startDate,
  endDate,
  dateFormat,
  separator = 'to',
) => {
  const formattedStartDate = formatDate(startDate, dateFormat);
  const formattedEndDate = formatDate(endDate, dateFormat);

  return `${formattedStartDate} ${separator} ${formattedEndDate}`;
};

export const isValidDate = date => {
  if (Object.prototype.toString.call(date) === '[object Date]') {
    if (Number.isNaN(date?.getTime())) {
      return false;
    }

    return true;
  }

  return false;
};
