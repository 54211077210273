import React from 'react';
import styled from 'styled-components';

import {
  IconMedallion,
  LoanSync,
  Button,
  Text,
  FlexColumn,
  COLORS,
} from '@summer/ui-components';

import { TabletSize } from 'constants/styleguide';

const EmptyServicersDisplay = ({ openPlaid, setIsPlaidDone, isPlaidReady }) => {
  const handleSync = () => {
    openPlaid();
    setIsPlaidDone(false);
  };

  return (
    <Container>
      <IconMedallion
        SvgComponent={LoanSync}
        size={64}
        color={COLORS.lightBlue}
      />
      <TextContainer>
        <Text bold large paragraph>
          Loan Sync
        </Text>
        <Text paragraph>
          Please sync your student loans so that you can start matching your
          student loan payments towards your retirement account. The exact
          amount of the match that you will receive is determined by your
          employer&rsquo;s plan rules.
        </Text>
      </TextContainer>
      <Button
        type="button"
        kind="open-plaid"
        primary
        width={160}
        onClick={handleSync}
        isLoading={isPlaidReady}
      >
        Sync a loan
      </Button>
    </Container>
  );
};

const Container = styled(FlexColumn)`
  padding: 56px 94px 32px;
  align-items: center;
  text-align: center;

  @media (max-width: ${TabletSize}) {
    padding: 48px 24px;
  }
`;

const TextContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;

  & > * {
    margin-bottom: 4px;
  }
`;

export default EmptyServicersDisplay;
