import { push as redirectTo } from 'connected-react-router';
import { format, parseISO } from 'date-fns';
import _, { find, isFunction, isNil } from 'lodash';
import fp from 'lodash/fp';
import React, { useEffect, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button, COLORS, FancyCard, Header, Text } from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import { MobileSize } from 'constants/styleguide';
import { fetchIdrForms, moveToIdrCurrentStep } from 'redux/actions/idr.actions';
import { fetchLoans } from 'redux/actions/loans.actions';
import { getCurrentIdrFormStep } from 'redux/selectors/idr.selectors';
import { getRepaymentPlanTypes } from 'redux/selectors/loans.selectors';
import {
  checkUserEnrolledInIDR,
  getEstIdrMonthlyPayment,
  getIdrCurrentState,
  getIdrPendingState,
} from 'redux/selectors/recEngine.selectors';
import { getIdrProgress } from 'utils/idr';
import { listPlanTypeNames } from 'utils/loans';
import { disallowBreaks } from 'utils/text';

const IdrCurrentPendingCardContent = ({
  dateActive,
  dateSubmitted,
  currentPendingRepaymentPlanTypes,
}) => {
  const headerText = listPlanTypeNames(currentPendingRepaymentPlanTypes);
  const date = dateActive || dateSubmitted;
  const dateTextPrefix =
    (dateActive && 'Since') || (dateSubmitted && 'Submitted');
  const dateText =
    date && `${dateTextPrefix} ${format(parseISO(date), 'MMM d, yyyy')}`;

  return (
    <CardContentContainer>
      <ContentContainer>
        <Header color="secondary" h2>
          {headerText}
        </Header>
        <Text paragraph weight="medium" size="medium" color="secondary">
          {dateText}
        </Text>
      </ContentContainer>
    </CardContentContainer>
  );
};

const useIdrCardState = ({ idrCurrentStep }) => {
  const dispatch = useDispatch();
  return React.useMemo(() => {
    if (idrCurrentStep > 0) {
      return {
        state: 'progress',
        progress: getIdrProgress(idrCurrentStep),
        callToAction: {
          text: 'Continue',
          onClick: () =>
            dispatch(moveToIdrCurrentStep(idrCurrentStep || 1, 'myPlan')),
        },
      };
    }
    return {};
  }, [dispatch, idrCurrentStep]);
};

const IdrRecCardContent = ({ isCurrentlyEnrolledInIDR, callToAction }) => {
  const dispatch = useDispatch();
  const headerText = isCurrentlyEnrolledInIDR
    ? 'Update your income-driven repayment plan (IDR)'
    : 'Enroll in income-driven repayment (IDR)';
  const contentText = isCurrentlyEnrolledInIDR
    ? 'You’re already in an IDR plan, but since there are four types of plans, Summer can make sure you’re enrolled in one that saves you the most money.'
    : 'IDR is a free federal program where repayment plans are based on your personal income. Summer helps find the best IDR plan to lower your monthly payments (with possible forgiveness as well).';

  const handleCTA = React.useCallback(() => {
    if (isFunction(callToAction?.onClick)) {
      callToAction.onClick();
    } else {
      dispatch(redirectTo('/repayment'));
    }
  }, [callToAction, dispatch]);

  return (
    <CardContentContainer>
      <ContentContainer>
        <Header h2>{headerText} </Header>
        <Text paragraph weight="medium" size="medium">
          {contentText}
        </Text>
      </ContentContainer>
      <CTAContainer>
        <Button onClick={handleCTA} width={144}>
          {callToAction?.text ?? 'Get Started'}
        </Button>
      </CTAContainer>
    </CardContentContainer>
  );
};

const IdrRec = ({
  estIdrMonthlyPayment,
  isCurrentlyEnrolledInIDR,
  platformType,
  idrCurrentStep,
  current,
  currentIdrPlan,
  pending,
  pendingIdrPlan,
  repaymentPlanTypes,
}) => {
  const isMobile = platformType === MOBILE;
  const cardState = useIdrCardState({ idrCurrentStep });
  const dispatch = useDispatch();

  const recCardHeaderInfo = useMemo(() => {
    const title = 'Est. monthly payment:';

    if (!isNil(estIdrMonthlyPayment)) {
      return [
        {
          title,
          value: estIdrMonthlyPayment,
          animate: true,
        },
      ];
    }
    return [];
  }, [estIdrMonthlyPayment]);

  const {
    dateActive,
    dateSubmitted,
    monthlyPayment,
    totalPayment,
    repaymentPlanTypeIds,
  } = (current && currentIdrPlan) || (pending && pendingIdrPlan) || {};

  const currentPendingRepaymentPlanTypes = _.flow(
    fp.map(id => find(repaymentPlanTypes, { id })),
    fp.compact,
  )(repaymentPlanTypeIds);

  const currentPendingCardHeaderInfo = useMemo(() => {
    const valueColor = current ? COLORS.darkerGrey : COLORS.darkGrey;
    return [
      {
        title: disallowBreaks('Per month:'),
        value: monthlyPayment,
        valueColor,
      },
      {
        title: disallowBreaks('Total to repay:'),
        value: totalPayment,
        valueColor,
      },
    ];
  }, [current, monthlyPayment, totalPayment]);

  const headerBackgroundStyle = useMemo(() => {
    if (current) {
      return 'solid';
    }
    if (pending) {
      return 'stripes';
    }
    return 'none';
  }, [current, pending]);

  // we need to fetch all idr save data in case user is in progress
  useEffect(() => {
    dispatch(fetchLoans());
    dispatch(fetchIdrForms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const isCurrentOrPending = current || pending;
  const headerDirection = isMobile && !isCurrentOrPending ? 'column' : 'row';

  return (
    <FancyCard
      title={
        isCurrentOrPending
          ? 'INCOME-DRIVEN REPAYMENT'
          : 'LOWER MONTHLY PAYMENTS'
      }
      shortTitle={isCurrentOrPending ? 'IDR' : ''}
      headerInfo={
        isCurrentOrPending ? currentPendingCardHeaderInfo : recCardHeaderInfo
      }
      color="magenta"
      backgroundStyle={headerBackgroundStyle}
      headerBorderWidth={isCurrentOrPending ? null : cardState.progress}
      testId={
        isCurrentlyEnrolledInIDR ? 'rec-card-idr-manage' : 'rec-card-idr-enroll'
      }
      headerDirection={headerDirection}
    >
      {isCurrentOrPending ? (
        <IdrCurrentPendingCardContent
          dateActive={dateActive}
          dateSubmitted={dateSubmitted}
          currentPendingRepaymentPlanTypes={currentPendingRepaymentPlanTypes}
        />
      ) : (
        <IdrRecCardContent
          isCurrentlyEnrolledInIDR={isCurrentlyEnrolledInIDR}
          callToAction={cardState.callToAction}
        />
      )}
    </FancyCard>
  );
};

const CardContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: inherit;
  align-items: center;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
    > * {
      margin-bottom: 16px;
      :last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const ContentContainer = styled.div`
  margin-right: 8px;
  max-width: 80%;

  > * {
    margin-bottom: 8px;
  }

  :last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${MobileSize}) {
    max-width: 100%;
  }
`;

const CTAContainer = styled.div`
  text-align: right;
  align-self: flex-end;
  width: 144px;

  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

const mapStateToProps = state => ({
  estIdrMonthlyPayment: getEstIdrMonthlyPayment(state),
  idrCurrentStep: getCurrentIdrFormStep(state),
  isCurrentlyEnrolledInIDR: checkUserEnrolledInIDR(state),
  currentIdrPlan: getIdrCurrentState(state),
  pendingIdrPlan: getIdrPendingState(state),
  repaymentPlanTypes: getRepaymentPlanTypes(state),
});

export default Platform(connect(mapStateToProps)(IdrRec));
