import { map, every, get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  FormStatus,
  FormTypes,
} from '@simplifidev/shared/dist/constants/forms';

import {
  FlexRow,
  FlexColumn,
  Button,
  BrokenLink,
  CheckSmall,
  FileIcon,
  Icon,
  Text,
  LoadingBars,
  COLORS,
} from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { TabletSize, MobileSize } from 'constants/styleguide';
import { hexToRgba } from 'utils/common';

const getSignRequestTitle = (formType, signRequestTitle) => {
  if (formType === FormTypes.idr) {
    return 'IDR Application Form';
  }
  if (formType === FormTypes.consolidation) {
    return 'Loan Consolidation Application Form';
  }
  if (formType === FormTypes.pslf) {
    return signRequestTitle;
  }
  return null;
};

const getButtonText = role => {
  if (role === 'spouse') {
    return 'Spouse’s Signature';
  }
  if (role === 'employer') {
    return 'Review & Sign';
  }
  return 'Sign & Complete';
};

const EditPslf = ({ editButtonHandler, status }) => {
  if (status === FormStatus.STARTED) {
    return (
      <ContinueButton small secondary onClick={editButtonHandler}>
        Continue
      </ContinueButton>
    );
  }

  return (
    <Edit bold color="summer">
      <Link onClick={editButtonHandler}>Edit</Link>
    </Edit>
  );
};

const SignButton = ({ signer, onClick, isLoading }) => {
  const { role, complete, embeddedSignURL } = signer || {};

  const completeText = role === 'spouse' ? `Spouse Signed` : 'Completed';

  if (complete) {
    return (
      <>
        <CompleteIndicator>
          {isLoading ? (
            <LoadingBars color={COLORS.blueBlack} />
          ) : (
            <>
              <CheckMarkIconStyled
                width={15}
                SvgComponent={CheckSmall}
                stroke={COLORS.darkerGrey}
              />
              <Text as="span" bold size="medium">
                {completeText}
              </Text>
            </>
          )}
        </CompleteIndicator>
      </>
    );
  }

  return (
    <>
      <CustomButton
        isLoading={isLoading}
        onClick={() => {
          if (embeddedSignURL) {
            onClick(embeddedSignURL);
          }
        }}
      >
        {getButtonText(role)}
      </CustomButton>
    </>
  );
};

const SignRequestRow = ({
  signRequest,
  onClick,
  isLoading,
  showEditButton,
  status,
  signRequestTitle,
  signRequestSubtitle,
  editButtonHandler,
  showBottomComponent,
  bottomComponent,
  isExpired,
}) => {
  const formType = get(signRequest, 'formType');
  const signers = get(signRequest, 'signers');
  const finishSigning = every(signers, 'complete');

  return (
    <SignRequestContainer>
      <SignRowContainer>
        <SignRequestTitle formType={formType}>
          {isExpired ? (
            <FileIconStyled
              width={30}
              SvgComponent={BrokenLink}
              fill={COLORS.medGrey}
            />
          ) : (
            <FileIconStyled SvgComponent={FileIcon} stroke={COLORS.medGrey} />
          )}
          <FlexColumn>
            <Title
              finishSigning={finishSigning}
              formType={formType}
              bold
              size="large"
            >
              {getSignRequestTitle(formType, signRequestTitle)}
            </Title>
            <Text>{signRequestSubtitle}</Text>
          </FlexColumn>
        </SignRequestTitle>

        <SignRequestButtons>
          {showEditButton && (
            <EditPslf editButtonHandler={editButtonHandler} status={status} />
          )}
          {map(signers, signer => {
            return (
              <SignButton
                key={JSON.stringify(signer)}
                formType={formType}
                signer={signer}
                onClick={onClick}
                isLoading={isLoading}
              />
            );
          })}
        </SignRequestButtons>
      </SignRowContainer>
      {showBottomComponent && bottomComponent}
    </SignRequestContainer>
  );
};

const SignRequestContainer = styled(FlexColumn)`
  box-shadow: 0 0 4px 0 ${COLORS.grey};
`;

const SignRowContainer = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px 24px;
  background-color: ${COLORS.white};

  @media (max-width: ${TabletSize}) {
    flex-direction: column;
    align-items: flex-start;

    & > div {
      margin-bottom: 16px;
    }

    & > *:last-child {
      margin-bottom: 0;
    }

    @media (max-width: ${MobileSize}) {
      padding: 16px 12px 12px 16px;
  }
`;

const FileIconStyled = styled(Icon)`
  margin-right: 16px;
`;

const SignRequestTitle = styled(FlexRow)`
  flex: 1 1 auto;
  align-items: ${({ formType }) =>
    formType === FormTypes.pslf ? `flex-start` : `center`};
  margin-right: 16px;
`;

const Title = styled(Text)`
  margin-top: ${({ formType }) => (formType === FormTypes.pslf ? `-4px` : 0)};
  margin-bottom: ${({ formType }) => (formType === FormTypes.pslf ? `4px` : 0)};
`;

const SignRequestButtons = styled(FlexRow)`
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: center;

  & > * {
    margin-right: 8px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

const CompleteIndicator = styled.div`
  width: auto;
  text-align: center;
  background-color: ${hexToRgba(COLORS.jade, '0.1')};
  border-radius: 3px;
  padding: 14px 16px;
`;

const CheckMarkIconStyled = styled(Icon)`
  margin-right: 8px;
  display: inline;
`;

const Edit = styled(Text)`
  padding-right: 16px;
`;

const ContinueButton = styled(Button)`
  width: 120px;
`;

const CustomButton = styled(Button)`
  padding: 14px 16px;
  width: auto;
`;

SignRequestRow.propTypes = {
  signRequest: PropTypes.shape({
    formType: PropTypes.string.isRequired,
    signers: PropTypes.arrayOf(
      PropTypes.shape({
        role: PropTypes.string.isRequired,
        signatureRequestsId: PropTypes.string.isRequired,
        embeddedSignURL: PropTypes.string,
        complete: PropTypes.bool.isRequired,
      }),
    ),
  }).isRequired,
};

export default SignRequestRow;
