import { keys, pick } from 'lodash';

import { SET_TUITION_REIMBURSEMENT_UI } from 'constants/actionTypes';

const initialState = {
  showCompleteModal: false,
  completedStep: '',
};

const tuitionReimbursementUiReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case SET_TUITION_REIMBURSEMENT_UI: {
      return { ...state, ...pick(payload, keys(initialState)) };
    }

    default:
      return state;
  }
};

export default tuitionReimbursementUiReducer;
