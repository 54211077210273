import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { PLAID_LINK_CUSTOMIZATION_NAMES } from '@simplifidev/shared/dist/constants/plaidLink';

import {
  FlexRow,
  Strong,
  Header,
  Text,
  Button,
  IconMedallion,
  SyncInfinity,
  Accordion,
  Checkmark,
  Icon,
  COLORS,
} from '@summer/ui-components';

import { PlaidOutageAlert } from 'components/common/SyncLoans/PlaidOutageAlert';
import Platform, { MOBILE } from 'components/hoc/Platform';
import Page from 'components/shared/dashboard/Page';
import { MobileSize } from 'constants/styleguide';
import { usePlaid } from 'hooks/plaid';
import { fetchAllLoans } from 'redux/actions/loans.actions';
import { getIsPlaidOutage } from 'redux/selectors/user.selectors';

const EmptyStateHeader = ({ isMobile, open, isPlaidOutage }) => {
  return (
    <HeaderBackground>
      <Content>
        <Title>
          <IconSection>
            <HeaderIcon SvgComponent={SyncInfinity} color={COLORS.jade} />
          </IconSection>
          <TitleText>
            <Header as={isMobile ? 'h3' : 'h2'}>
              Let&rsquo;s sync your loans!
            </Header>
          </TitleText>
        </Title>
        <HeaderText paragraph size={isMobile ? 'medium' : 'large'}>
          <Strong>
            Sync all of your loans to see if they are eligible for repayment and
            forgiveness programs.
          </Strong>
        </HeaderText>
      </Content>
      <ButtonContainer>
        <Button
          width={!isMobile ? 192 : null}
          onClick={open}
          disabled={isPlaidOutage}
        >
          Sync Now
        </Button>
      </ButtonContainer>
    </HeaderBackground>
  );
};

const CheckmarkIcon = () => (
  <Icon width={24} SvgComponent={Checkmark} stroke={COLORS.darkGreen} />
);

const MyStudentLoansNoLoans = ({ platformType, isPlaidOutage }) => {
  const isMobile = platformType === MOBILE;

  const { open, isLoading: isPlaidLoading } = usePlaid({
    linkCustomizationName: PLAID_LINK_CUSTOMIZATION_NAMES.loanSync,
    successAction: fetchAllLoans,
  });

  const config = [
    {
      openByDefault: true,
      title: 'What are the benefits of syncing my loans?',
      content: (
        <List>
          <li>
            <CheckmarkIcon />
            <Text paragraph>
              <Strong>We check your eligibility</Strong> for federal forgiveness
              programs such as Public Service Loan Forgiveness and income-driven
              repayment plans.
            </Text>
          </li>
          <li>
            <CheckmarkIcon />
            <Text paragraph>
              Receive a <Strong>customized repayment plan</Strong> based on your
              situation that <Strong>saves you money</Strong>.
            </Text>
          </li>
          <li>
            <CheckmarkIcon />
            <Text paragraph>
              Your data is encrypted end-to-end, and your credentials will never
              be made accessible to us.
            </Text>
          </li>
        </List>
      ),
    },
    {
      title: 'I don’t remember my servicer login credentials',
      content: (
        <Text paragraph>
          If you can’t remember your credentials, please visit your servicer’s
          online portal to either look-up your username or reset your password.
        </Text>
      ),
    },
  ];

  return (
    <Page
      backgroundStyle="circle1"
      themeColor={COLORS.jade}
      renderPageHeader={() => (
        <EmptyStateHeader
          isMobile={isMobile}
          open={open}
          isPlaidOutage={isPlaidOutage}
        />
      )}
      isLoading={isPlaidLoading}
    >
      <PageContainer>
        {isPlaidOutage && <StyledPlaidOutageAlert />}
        <PageContent>
          <AccordionContainer config={config} />
          <ComparePlanImage
            alt="Compare Plans"
            src="/images/compare-plan-new.png"
          />
        </PageContent>
      </PageContainer>
    </Page>
  );
};

const pageMaxWidth = '1130px';

const PageContainer = styled.div`
  margin-top: 40px;
  max-width: ${pageMaxWidth};
`;

const HeaderBackground = styled(FlexRow)`
  padding-bottom: 40px;
  max-width: ${pageMaxWidth};
  margin: auto;

  @media (max-width: ${MobileSize}) {
    text-align: center;
    flex-direction: column;
  }
`;

const HeaderIcon = styled(IconMedallion)`
  margin-right: 16px;
`;

const IconSection = styled.div`
  align-self: flex-start;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;

  @media (min-width: ${MobileSize}) {
    align-self: flex-start;
  }
`;

const TitleText = styled.div`
  margin: auto;
`;

const Content = styled(FlexRow)`
  height: 100%;
  max-width: 450px;
  align-items: center;
  flex-direction: column;
`;

const HeaderText = styled(Text)`
  margin-top: 16px;
`;

const StyledPlaidOutageAlert = styled(PlaidOutageAlert)`
  margin-bottom: 24px;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;

  @media (max-width: ${MobileSize}) {
    align-items: center;
    flex-direction: column-reverse;
  }
`;

const AccordionContainer = styled(Accordion)`
  margin-right: 64px;

  @media (max-width: ${MobileSize}) {
    margin-right: 0;
  }
`;

const List = styled.ul`
  list-style-type: none;

  & li {
    display: flex;
    margin-bottom: 16px;
    align-items: flex-start;

    :last-child {
      margin-bottom: 0;
    }

    .text {
      display: inline;
    }

    .icon {
      display: inline;
      margin-right: 13px;
      margin-top: 3px;
    }
  }
`;

const ComparePlanImage = styled.img`
  max-width: 440px;
  width: 50%;
  height: fit-content;

  @media (max-width: ${MobileSize}) {
    max-width: 100%;
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  align-self: flex-end;
  margin-left: auto;
  z-index: 2; // put in front of Circles in Page header

  @media (max-width: ${MobileSize}) {
    width: 100%;
    margin-top: 16px;
  }
`;

const mapStateToProps = state => ({
  isPlaidOutage: getIsPlaidOutage(state),
});

export default Platform(connect(mapStateToProps)(MyStudentLoansNoLoans));
