import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Header,
  Text,
  FlexColumn,
  Lottie,
  Icon,
  CheckNew,
  FlexCenter,
  ModalPopupBottomPadding,
  ModalPopupHorizontalPadding,
  ModalPopupHorizontalPaddingMobile,
  Button,
  COLORS,
  SubmitCelebrationLottie,
} from '@summer/ui-components';

import ContactSupportText from 'components/common/ContactSupportText';
import PslfBanner from 'components/pages/idr/dashboard/CompletedView/PslfBanner';
import ModalPopup from 'components/shared/ModalPopup';
import { Link } from 'components/shared/typography';
import { fidelityReturnLink } from 'constants/externalLinks/partner';
import { MobileSize, TabletSize } from 'constants/styleguide';
import { usePlatform } from 'hooks/platform';
import { getPslfRecommendation } from 'redux/selectors/recEngine.selectors';
import {
  getUserPartnerKey,
  userWorksInPublicSector,
} from 'redux/selectors/user.selectors';
import { hexToRgba } from 'utils/common';

const CheckmarkIcon = () => (
  <Icon
    width={13}
    SvgComponent={CheckNew}
    fill={hexToRgba(COLORS.darkGreen, 0.6)}
  />
);

const modalWidth = 800;

const partnerSpecificInfo = {
  fidelity: {
    buttonText: 'Return to Fidelity',
    redirectUrl: fidelityReturnLink,
  },
};

const SuccessModal = ({ isRecommendedPslf, partnerKey, isPublicSector }) => {
  const { isMobile } = usePlatform();

  const modalContentClassName = useMemo(() => {
    if (isPublicSector === true) {
      return 'pslf';
    }
    if (partnerKey in partnerSpecificInfo) {
      return 'partner';
    }

    return '';
  }, [isPublicSector, partnerKey]);

  return (
    <ModalPopup
      openByDefault
      width={modalWidth}
      component={() => {
        const imgWidth = isMobile ? '240px' : '295px';
        const imgHeight = isMobile ? '201px' : '286px';

        return (
          <>
            <ModalContent className={modalContentClassName}>
              <FlexCenter>
                <ImageWrapper width={imgWidth} height={imgHeight}>
                  <Lottie
                    autoplay={true}
                    loop={false}
                    speed={0.5}
                    animationData={SubmitCelebrationLottie}
                  />
                </ImageWrapper>
              </FlexCenter>
              <Info>
                <Header as="h2">
                  Nice work! We&rsquo;ll take it from here.
                </Header>
                <Text as="p" paragraph>
                  Keep paying your current amount until you’ve heard from your
                  servicer.
                </Text>
                <List>
                  <li>
                    <CheckmarkIcon />
                    <Text paragraph>
                      Our team will double-check your paperwork.
                    </Text>
                  </li>
                  <li>
                    <CheckmarkIcon />
                    <Text paragraph>
                      We&rsquo;ll send it to your servicer for approval.
                    </Text>
                  </li>
                  <li>
                    <CheckmarkIcon />
                    <Text paragraph>
                      In 4-8 weeks, your servicer will send you confirmation of
                      your new monthly payment.
                    </Text>
                  </li>
                </List>
                {partnerKey in partnerSpecificInfo &&
                  isPublicSector === false && (
                    <PartnerWrapper>
                      <ContactSupportText />
                      <StyledLink
                        href={partnerSpecificInfo[partnerKey].redirectUrl}
                        inheritColor={true}
                        inheritWeight={true}
                        target="_self"
                      >
                        <Button type="button">
                          {partnerSpecificInfo[partnerKey].buttonText}
                        </Button>
                      </StyledLink>
                    </PartnerWrapper>
                  )}
              </Info>
            </ModalContent>
            {isRecommendedPslf && (
              <PslfBannerContainer>
                <PslfBanner />
              </PslfBannerContainer>
            )}
          </>
        );
      }}
    />
  );
};

const PslfBannerContainer = styled.div`
  @media (min-width: ${modalWidth}px) {
    margin-bottom: -${ModalPopupBottomPadding};
  }

  margin-left: -${ModalPopupHorizontalPadding};
  margin-right: -${ModalPopupHorizontalPadding};

  @media (max-width: ${MobileSize}) {
    margin-bottom: -${ModalPopupBottomPadding};
    margin-left: -${ModalPopupHorizontalPaddingMobile};
    margin-right: -${ModalPopupHorizontalPaddingMobile};
    padding: 24px 24px 66px;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -32px;

  &.pslf {
    padding-bottom: 16px;
  }

  &.partner {
    padding-top: 32px;
  }

  @media (max-width: ${MobileSize}) {
    overflow: scroll;
    flex-direction: column;
    padding: 0;
  }
`;

const ImageWrapper = styled.div`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

const Info = styled(FlexColumn)`
  & > h2 {
    margin-bottom: 16px;
  }
  & > p:not(:last-child) {
    margin-bottom: 16px;
  }
  @media (max-width: ${TabletSize}) {
    margin-top: 16px;
    margin-bottom: 24px;
  }
`;

const PartnerWrapper = styled.div`
  & > p {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  justify-content: flex-end;
`;

const List = styled.ul`
  list-style-type: none;

  & li {
    display: flex;
    margin-bottom: 8px;
    align-items: baseline;

    :last-child {
      margin-bottom: 0;
    }

    .text {
      display: inline;
    }

    .icon {
      display: inline;
      margin-right: 16px;
    }
  }
`;

const mapStateToProps = state => ({
  isRecommendedPslf: getPslfRecommendation(state),
  partnerKey: getUserPartnerKey(state),
  isPublicSector: userWorksInPublicSector(state),
});

export default connect(mapStateToProps)(SuccessModal);
