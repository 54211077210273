import styled from 'styled-components';

import { MobileSize } from 'constants/styleguide';

const FormDrawersContainer = styled.div`
  max-width: 766px;
  margin: 0 auto;

  @media (max-width: ${MobileSize}) {
    margin: 0 -16px;
  }
`;

export default FormDrawersContainer;
