import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import WizardContent from 'components/layout/wizard/WizardContent';
import { getCurrentStepFromNavigation } from 'hooks/Wizard/utils';
import { savePslfCurrentStep } from 'redux/actions/pslf.actions';
import { getCurrentPslfFormId } from 'redux/selectors/pslf.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';

const PslfWizardContent = ({
  children,
  isLoading,
  formId,
  navigation,
  dispatch,
  backgroundColor,
}) => {
  const currentStep = getCurrentStepFromNavigation(navigation);

  useEffect(() => {
    if (formId) {
      dispatch(
        savePslfCurrentStep({
          currentStep,
          form: { formId },
        }),
      );
    }
  }, [formId, dispatch, currentStep]);
  return (
    <WizardContent isLoading={isLoading} backgroundColor={backgroundColor}>
      {children}
    </WizardContent>
  );
};

const mapStateToProps = state => ({
  isLoading: isLoading(state, 'fetchCurrentPslfForm'),
  formId: getCurrentPslfFormId(state),
});

export default connect(mapStateToProps)(PslfWizardContent);
