import { filter, isNil, isEmpty, map, includes } from 'lodash';
import React from 'react';

import { LoadingBars, COLORS } from '@summer/ui-components';

import { Servicer } from 'components/common/ViewLoans/ServicersLoansTables/Servicer';

export const SERVICER_COLOR_LIST = [
  '#268F81',
  '#87C1B9',
  '#5DA8FF',
  '#A8D0FF',
  '#FCE799',
  '#FFAF87',
  '#FFD8C5',
  '#DE8BB3',
];

const ServicersLoansTables = ({
  normalizedLoans,
  allServicers,
  servicersToDisplay,
  active,
  onClickTableRow,
  columnsToShow,
  isLoading,
  tableName,
}) => {
  if (isLoading) {
    return <LoadingBars color={COLORS.azure} />;
  }

  return (
    <div>
      {map(allServicers, (servicerName, index) => {
        const predicate = { servicerName };

        if (!isNil(active)) {
          predicate.active = active;
        }

        const loans = filter(normalizedLoans, predicate);

        // all the urls for a servicer table should be the same
        const { servicerUrl } = loans.length !== 0 && loans[0];

        const shouldNotDisplayServicer =
          !isEmpty(servicersToDisplay) &&
          !includes(servicersToDisplay, servicerName);

        return shouldNotDisplayServicer ? null : (
          <Servicer
            color={SERVICER_COLOR_LIST[index]}
            key={servicerName || 'Unavailable'}
            servicerName={servicerName || 'Unavailable'}
            servicerUrl={servicerUrl}
            loans={loans}
            tableAction={onClickTableRow}
            columnsToShow={columnsToShow}
            tableIndex={index}
            tableName={tableName}
          />
        );
      })}
    </div>
  );
};

export default React.memo(ServicersLoansTables);
