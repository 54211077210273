import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  FlexColumn,
  FlexRow,
  Text,
  Header,
  Button,
  IconMedallion,
  CalloutQuestion,
  Consultation,
  COLORS,
} from '@summer/ui-components';

import Platform from 'components/hoc/Platform';
import SupportHeader from 'components/pages/support/dashboard/Header';
import ServicersModal from 'components/pages/support/dashboard/ServicersModal';
import Page from 'components/shared/dashboard/Page';
import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';
import { loanConsultationCalendlyLink } from 'constants/externalLinks/support';
import { MobileSize } from 'constants/styleguide';
import { fetchLoans } from 'redux/actions/loans.actions';
import { hasSyncedLoans } from 'redux/selectors/sync.selectors';
import {
  getHideSupportChat,
  getUserIsPremium,
} from 'redux/selectors/user.selectors';
import { openChat, openHelpCenter } from 'services/chat';
import { hexToRgba } from 'utils/common';

const Support = ({ hasSyncedLoans, isPremium, hideSupportChat }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLoans());
  }, [dispatch]);

  return (
    <>
      <Page themeColor={COLORS.sand} renderPageHeader={() => <SupportHeader />}>
        <Container>
          <Box>
            <IconContainer>
              <IconMedallion
                SvgComponent={CalloutQuestion}
                size={60}
                color={COLORS.azure}
                paintWith="both"
              />
            </IconContainer>
            <TextContainer>
              <Header h3>Send us a message</Header>
              <Text paragraph large>
                Have questions about your student loans or Summer? Send us a
                message at{' '}
                <Link underline inheritColor href={`mailto:${supportAddress}`}>
                  {supportAddress}
                </Link>{' '}
                and we&rsquo;ll get back to you as soon as possible. You can
                also{' '}
                <Link underline inheritColor onClick={openHelpCenter}>
                  search our Help Center
                </Link>{' '}
                for loan definitions and answers to common questions.
              </Text>
              <br />
              <Text paragraph large>
                ¿Habla español? Envíenos un correo electrónico a{' '}
                <Link underline inheritColor href={`mailto:${supportAddress}`}>
                  {supportAddress}
                </Link>{' '}
                para obtener ayuda.
              </Text>
            </TextContainer>
            {!hideSupportChat && (
              <ButtonContainer>
                <Button onClick={openChat} primary width={172} height={48}>
                  Send a message
                </Button>
              </ButtonContainer>
            )}
          </Box>
          {isPremium && (
            <Box>
              <IconContainer>
                <IconMedallion
                  SvgComponent={Consultation}
                  size={60}
                  color={COLORS.azure}
                  paintWith="both"
                />
              </IconContainer>
              <TextContainer>
                <Header h3>Schedule a consultation</Header>
                <Text paragraph large>
                  Have more in depth questions about your student loan
                  situation? Talk to our team for guidance.
                </Text>
              </TextContainer>
              <ButtonContainer>
                <Link
                  href={hasSyncedLoans ? loanConsultationCalendlyLink : null}
                  to={hasSyncedLoans ? null : '/schedule-consultation'}
                >
                  <Button primary width={172} height={48}>
                    Find a time
                  </Button>
                </Link>
              </ButtonContainer>
            </Box>
          )}
          <ContactServicerBox>
            <ContactInfoText>
              <Header h3 align="left">
                Contact your servicer if:
              </Header>
              <Issues>
                <ul>
                  <Text paragraph medium as="li">
                    You have questions about your monthly payments, statements,
                    or bills
                  </Text>
                  <Text paragraph medium as="li">
                    You want to check on an application or a form that was
                    submitted
                  </Text>
                  <Text paragraph medium as="li">
                    You want to report an error or an issue with your loans
                  </Text>
                </ul>
              </Issues>
            </ContactInfoText>

            <ButtonContainer>
              <ServicersModal>
                <Button primary width={172} height={48}>
                  <div>View contact info</div>
                </Button>
              </ServicersModal>
            </ButtonContainer>
          </ContactServicerBox>
        </Container>
      </Page>
    </>
  );
};

const Container = styled(FlexColumn)`
  width: 90%;
  margin-top: 32px;

  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

const Box = styled(FlexRow)`
  min-height: 200px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 2px ${COLORS.grey};
  border: solid 1px ${COLORS.lighterGrey};
  padding: 32px;
  align-items: center;
  margin-bottom: 32px;

  @media (max-width: ${MobileSize}) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 24px 24px;
    justify-content: center;
    border-bottom: solid 1px ${COLORS.grey};
  }
`;

const ContactServicerBox = styled(Box)`
  box-shadow: none;
  padding: 32px;
  background-color: ${hexToRgba(COLORS.azure, '0.05')};
  border: solid ${COLORS.azure};
  border-width: 8px 0 0 0;
  align-items: center;
`;

const Issues = styled.div`
  margin-bottom: 24px;

  & > ul {
    margin-top: 8px;
    margin-left: 24px;
    text-align: left;
  }
`;

const IconContainer = styled.div`
  margin-right: 48px;

  @media (max-width: ${MobileSize}) {
    margin: 0;
  }
`;

const TextContainer = styled.div`
  width: 55%;
  margin-right: 16px;

  @media (max-width: ${MobileSize}) {
    text-align: center;
    width: 100%;
    margin: 20px 0;
  }
`;

const ContactInfoText = styled(TextContainer)`
  width: 65%;
  margin-right: 0;

  @media (max-width: ${MobileSize}) {
    margin: 0;
  }
`;

const ButtonContainer = styled.div`
  margin-left: auto;
  align-self: flex-end;

  @media (max-width: ${MobileSize}) {
    margin: 0;
    width: 100%;
  }
`;

const mapStateToProps = state => ({
  hasSyncedLoans: hasSyncedLoans(state),
  isPremium: getUserIsPremium(state),
  hideSupportChat: getHideSupportChat(state),
});

export default connect(mapStateToProps)(Platform(Support));
