/* eslint-disable react/display-name */
import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';

import FormDrawersContainer from 'components/common/FormDrawersContainer';
import Content from 'components/pages/pslf/wizard/layout/PslfWizardContent';
import Footer from 'components/pages/pslf/wizard/layout/PslfWizardFooter';
import Header from 'components/pages/pslf/wizard/steps/submit/shared/Header';
import FormDrawers from 'components/shared/FormDrawers';
import { useCounter } from 'hooks/common';
import { useOnSuccess } from 'hooks/pslf';
import { updateCurrentPslfForm } from 'redux/actions/pslf.actions';
import { getUserProfile } from 'redux/selectors/profile.selectors';
import { getUserCurrentPslfForm } from 'redux/selectors/pslf.selectors';
import { personalInfoSchema } from 'schemas/pslf';

import ContactInfo from './sections/ContactInfo';
import PersonalInfo from './sections/PersonalInfo';

const YourContactInfo = ({
  navigation,
  currentPslfForm,
  profile,
  updateCurrentPslfForm,
}) => {
  useOnSuccess('updateCurrentPslfForm', navigation.next);

  // Used for FormDrawers error behavior.
  // We can't use formik's submitCount because it is read-only.
  const [submitCount, resetSubmitCount, incrementSubmitCount] = useCounter();

  const handleSave = ({
    firstName,
    lastName,
    dateOfBirth,
    streetAddress1,
    city,
    state,
    zipcode,
    phone,
  }) => {
    const { formId } = currentPslfForm;

    const formValuesToSave = {
      formId,
    };
    const profileValuesToSave = {
      firstName,
      lastName,
      dateOfBirth,
      streetAddress1,
      city,
      state,
      zipcode,
      phone,
    };

    updateCurrentPslfForm({
      form: formValuesToSave,
      profile: profileValuesToSave,
    });
  };

  const {
    firstName,
    lastName,
    dateOfBirth,
    streetAddress1,
    city,
    state,
    zipcode,
    phone,
  } = profile;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName,
        lastName,
        dateOfBirth,
        streetAddress1,
        city,
        state,
        zipcode,
        phone,
      }}
      onSubmit={handleSave}
      validationSchema={personalInfoSchema}
    >
      {formik => {
        const { handleSubmit, isValid, errors } = formik;

        return (
          <Content navigation={navigation}>
            <form>
              <Header navigation={navigation}>Your contact information</Header>
              <FormDrawersContainer>
                <FormDrawers
                  errors={errors}
                  submitCount={submitCount}
                  resetSubmitCount={resetSubmitCount}
                  forms={[
                    {
                      name: 'personal',
                      title: 'Personal Information',
                      form: show => (
                        <PersonalInfo formik={formik} show={show} />
                      ),
                      fields: ['firstName', 'lastName', 'dateOfBirth'],
                    },
                    {
                      name: 'contact',
                      title: 'Contact Information',
                      form: show => <ContactInfo formik={formik} show={show} />,
                      fields: [
                        'streetAddress1',
                        'city',
                        'state',
                        'zipcode',
                        'phone',
                      ],
                    },
                  ]}
                />
              </FormDrawersContainer>
              <Footer
                handleBack={navigation.back}
                isValid={isValid}
                submitCount={submitCount}
                handleSaveContinue={e => {
                  handleSubmit(e);
                  incrementSubmitCount();
                }}
                showPrivacyPolicy={true}
              />
            </form>
          </Content>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    profile: getUserProfile(state),
    currentPslfForm: getUserCurrentPslfForm(state),
  };
};

export default connect(mapStateToProps, { updateCurrentPslfForm })(
  YourContactInfo,
);
