import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { COLORS } from '../../../helpers/constants/styleguide';

import { Header } from '../../typography';
import { FlexRow } from '../../Flex';
import Collapse from '../Collapse';
import Icon, { ArrowUp } from '../../Icon';

const Drawer = ({
  openByDefault,
  title,
  children,
  ejectControl,
  show,
  onClick,
  onMouseDown,
}) => (
  <Collapse
    openByDefault={openByDefault}
    ejectControl={ejectControl}
    show={show}
    onClick={onClick}
    onMouseDown={onMouseDown}
    trigger={({ openState }) => (
      <CollapseTrigger data-testid={`drawer-${title}`}>
        <Header h5 color="secondary">
          {title}
        </Header>
        <Arrow open={openState} SvgComponent={ArrowUp} fill={COLORS.medGrey} />
      </CollapseTrigger>
    )}
  >
    <CollapseContent>{children}</CollapseContent>
  </Collapse>
);

Drawer.propTypes = {
  openByDefault: PropTypes.bool,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

Drawer.defaultProps = {
  openByDefault: false,
};

const CollapseTrigger = styled(FlexRow)`
  width: 100%;
  cursor: pointer;
  padding: 24px 32px;
  justify-content: space-between;
  border: 1px solid ${COLORS.grey};
`;

const Arrow = styled(Icon)`
  cursor: pointer;
  transform: ${props => (props.open ? 'rotate(0deg)' : 'rotate(180deg)')};

  transition: transform 0.3s ease-out;
`;

const CollapseContent = styled.div`
  background-color: ${COLORS.lightestGrey};
  border: 1px solid ${COLORS.lighterGrey};
  padding: 32px 32px 40px 32px;
`;

export default Drawer;
