import { get } from 'lodash';

import {
  FETCH_SIGNATURE_REQUEST_LIST_V3,
  NETWORK_SUCCESS,
  FETCH_SIGNATURE_REQUEST_JWT,
  UPDATE_SIGNATURE_REQUEST,
  UPDATE_SIGNATURE_REQUEST_JWT,
} from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import {
  setSignatureRequestList,
  setSignatureRequest,
} from 'redux/actions/signatureRequests.actions';
import handleSideEffects from 'redux/handleSideEffects';

const signatureRequestMiddleware = handleSideEffects({
  [FETCH_SIGNATURE_REQUEST_LIST_V3]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: `/v3/sign`,
        method: 'POST',
        body: {
          forms: payload.forms,
        },
        fromAction: FETCH_SIGNATURE_REQUEST_LIST_V3,
        label: 'fetchSignatureRequestListV3',
      }),
    );
  },
  [FETCH_SIGNATURE_REQUEST_JWT]: (
    dispatch,
    { payload: { token, signRequestId } },
  ) => {
    dispatch(
      apiRequest({
        url: `/v3/sign/${signRequestId}`,
        method: 'GET',
        fromAction: FETCH_SIGNATURE_REQUEST_JWT,
        label: 'fetchSignatureRequestJwt',
        headers: { Authorization: token },
      }),
    );
  },
  [UPDATE_SIGNATURE_REQUEST]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: `/v3/sign`,
        method: 'PATCH',
        body: {
          signatureRequestsId: payload.signatureRequestId,
          complete: payload.complete,
        },
        fromAction: UPDATE_SIGNATURE_REQUEST,
        label: 'updateSignatureRequest',
      }),
    );
  },
  [UPDATE_SIGNATURE_REQUEST_JWT]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: `/v3/sign/${payload.signatureRequestId}`,
        method: 'PATCH',
        body: {
          complete: payload.complete,
        },
        fromAction: UPDATE_SIGNATURE_REQUEST_JWT,
        label: 'updateSignatureRequestJwt',
        headers: { Authorization: payload.jwtToken },
      }),
    );
  },
  [NETWORK_SUCCESS]: (dispatch, { payload, meta }) => {
    if (meta.fromAction === FETCH_SIGNATURE_REQUEST_LIST_V3) {
      const signatureRequestList = get(payload, 'response', []);
      dispatch(setSignatureRequestList(signatureRequestList));
    }

    if (
      meta.fromAction === FETCH_SIGNATURE_REQUEST_JWT ||
      meta.fromAction === UPDATE_SIGNATURE_REQUEST_JWT
    ) {
      dispatch(setSignatureRequest(payload.response));
    }
  },
});

export default signatureRequestMiddleware;
