import React from 'react';
import './flexboxgrid.css';

export const Container = React.forwardRef(function GridContainer(
  { children, fullWidth, className },
  ref,
) {
  return (
    <div
      ref={ref}
      className={`container-fluid ${
        fullWidth ? ' full-width' : ' container'
      } ${className}`}
    >
      {children}
    </div>
  );
});

export const Row = ({
  children,
  start,
  center,
  end,
  top,
  middle,
  bottom,
  mobileStart,
  mobileCenter,
  mobileEnd,
  mobileTop,
  mobileMiddle,
  mobileBottom,
  tabletStart,
  tabletCenter,
  tabletEnd,
  tabletTop,
  tabletMiddle,
  tabletBottom,
  smallDesktopStart,
  smallDesktopCenter,
  smallDesktopEnd,
  smallDesktopTop,
  smallDesktopMiddle,
  smallDesktopBottom,
  desktopStart,
  desktopCenter,
  desktopEnd,
  desktopTop,
  desktopMiddle,
  desktopBottom,
  fullWidth,
  className,
  testId,
}) => (
  <div
    className={`row${start ? ' start' : ''}${center ? ' center' : ''}${
      end ? ' end' : ''
    }${top ? ' top' : ''}${middle ? ' middle' : ''}${bottom ? ' bottom' : ''}
    ${mobileStart ? ' start-mobile' : ''}${
      mobileCenter ? ' center-mobile' : ''
    }${mobileEnd ? ' end-mobile' : ''}${mobileTop ? ' top-mobile' : ''}${
      mobileMiddle ? ' middle-mobile' : ''
    }${mobileBottom ? ' bottom-mobile' : ''}
    ${tabletStart ? ' start-tablet' : ''}${
      tabletCenter ? ' center-tablet' : ''
    }${tabletEnd ? ' end-tablet' : ''}${tabletTop ? ' top-tablet' : ''}${
      tabletMiddle ? ' middle-tablet' : ''
    }${tabletBottom ? ' bottom-tablet' : ''} ${
      smallDesktopStart ? ' start-smallDesktop' : ''
    }${smallDesktopCenter ? ' center-smallDesktop' : ''}${
      smallDesktopEnd ? ' end-smallDesktop' : ''
    }${smallDesktopTop ? ' top-smallDesktop' : ''}${
      smallDesktopMiddle ? ' middle-smallDesktop' : ''
    }${smallDesktopBottom ? ' bottom-smallDesktop' : ''}
    ${desktopStart ? ' start-desktop' : ''}${
      desktopCenter ? ' center-desktop' : ''
    }${desktopEnd ? ' end-desktop' : ''}${desktopTop ? ' top-desktop' : ''}${
      desktopMiddle ? ' middle-desktop' : ''
    }${desktopBottom ? ' bottom-desktop' : ''}${
      fullWidth ? ' full-width' : ''
    } ${className}`}
    data-testid={testId}
  >
    {children}
  </div>
);

export const Column = ({ offset, col, children }) => (
  <div
    className={`${col ? `col-${col}` : 'col'}${
      offset ? ` col-offset-${offset}` : ''
    }`}
  >
    {children}
  </div>
);

export const ResponsiveColumn = ({
  mobileCol,
  tabletCol,
  smallDesktopCol,
  desktopCol,
  mobileOffset,
  tabletOffset,
  smallDesktopOffset,
  desktopOffset,
  mobileEnd,
  children,
  className,
}) => (
  <div
    className={`col-mobile-${mobileCol} col-tablet-${tabletCol} col-smallDesktop-${smallDesktopCol} col-desktop-${desktopCol} ${
      mobileEnd ? ' end-mobile' : ''
    }
    ${mobileOffset ? ` col-mobile-offset-${mobileOffset}` : ''}
    ${tabletOffset ? ` col-tablet-offset-${tabletOffset}` : ''}
    ${
      smallDesktopOffset ? ` col-smallDesktop-offset-${smallDesktopOffset}` : ''
    }
    ${desktopOffset ? ` col-desktop-offset-${desktopOffset}` : ''}
    ${className}`}
  >
    {children}
  </div>
);
