import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  SelectInput,
  Text,
  LoadingBars,
  COLORS,
} from '@summer/ui-components/src';

import { useClearFieldOnUnmount } from 'hooks/formik';
import { getInstitutionTuitions } from 'redux/selectors/collegeSavingsPlanner.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { dollars } from 'utils/numbers';

import { GridContainer } from '../shared';

const SearchByCollegeName = ({
  formik,
  isCalcDataLoading,
  institutionTuitions = [],
}) => {
  const { values, errors, touched, setFieldValue, isSubmitting, handleBlur } =
    formik;

  useClearFieldOnUnmount('yearlyTuition', null, setFieldValue);

  const options = useMemo(
    () =>
      institutionTuitions.map(({ institution, outStateTuition }) => ({
        label: institution,
        value: outStateTuition,
      })),
    [institutionTuitions],
  );

  return isCalcDataLoading ? (
    <LoadingBars color={COLORS.azure} />
  ) : (
    <GridContainer>
      <SelectInput
        name="yearlyTuition"
        label="Search by college name"
        placeholder="Start typing here"
        options={options}
        value={values.yearlyTuition}
        error={errors.yearlyTuition && 'Please make a selection'}
        touched={touched.yearlyTuition}
        isSubmitting={isSubmitting}
        onChange={setFieldValue}
        onBlur={handleBlur}
      />
      <EstimatedAmount>
        <Text bold>Estimated annual cost based on selected college</Text>
        <Text large bold data-testid="estimated-college-cost">
          ${' '}
          {values.yearlyTuition &&
            dollars(Number(values.yearlyTuition), { showSymbol: false })}
        </Text>
      </EstimatedAmount>
    </GridContainer>
  );
};

const EstimatedAmount = styled.div`
  margin-bottom: 25px;

  & > .text:first-child {
    margin-bottom: 8px;
  }

  & > .text:last-child {
    font-size: 24px;
    height: 48px;
    line-height: 48px;
  }
`;

const mapStateToProps = state => ({
  isCalcDataLoading: isLoading(state, 'fetchCSPCalculatorData'),
  institutionTuitions: getInstitutionTuitions(state),
});

export default connect(mapStateToProps)(SearchByCollegeName);
