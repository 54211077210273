import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { RadioButtons, Header, Text } from '@summer/ui-components';

import Content from 'components/pages/pslf/wizard/layout/PslfWizardContent';
import Footer from 'components/pages/pslf/wizard/layout/PslfWizardFooter';
import { pslfReadinessOptions } from 'constants/pslf';
import { useOnSuccess } from 'hooks/pslf';
import { updateCurrentPslfForm } from 'redux/actions/pslf.actions';
import { getUserCurrentPslfForm } from 'redux/selectors/pslf.selectors';
import { pslfReadinessSchema } from 'schemas/pslf';
import { stringToBool } from 'utils/toOptions';

const PslfReadiness = ({
  currentPslfForm,
  updateCurrentPslfForm,
  navigation,
}) => {
  useOnSuccess('updateCurrentPslfForm', navigation.next);

  const handleSave = ({ forbearanceRequested }) => {
    const { formId } = currentPslfForm;
    const valuesToSave = {
      formId,
      forbearanceRequested: stringToBool(forbearanceRequested),
    };
    updateCurrentPslfForm({ form: valuesToSave });
  };

  const initialValues = {
    forbearanceRequested: currentPslfForm.forbearanceRequested,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={pslfReadinessSchema}
      validateOnMount
      onSubmit={handleSave}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        isValid,
        submitCount,
      }) => (
        <Content navigation={navigation}>
          <form onSubmit={handleSubmit}>
            <Container>
              <PslfQuestionContainer>
                <Header as="h2">Where are you in your PSLF journey? </Header>
              </PslfQuestionContainer>
              <PslfQuestionContainer>
                <Text paragraph>
                  If you&rsquo;ve already been making payments and working in
                  public service for ten years or more, you might be ready for
                  forgiveness. You can choose to put your account into
                  forbearance while your application is processed, which means
                  payments won&rsquo;t be due, but those months won&rsquo;t
                  count toward your timeline if you haven&rsquo;t met all the
                  requirements.
                </Text>
              </PslfQuestionContainer>
              <RadioButtons
                name="forbearanceRequested"
                value={values.forbearanceRequested}
                error={errors.forbearanceRequested}
                touched={touched.forbearanceRequested}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                isSubmitting={isSubmitting}
                onBlur={handleBlur}
                numberOfCol={1}
                options={pslfReadinessOptions}
              />
            </Container>
            <Footer
              handleBack={navigation.back}
              isValid={isValid}
              submitCount={submitCount}
            />
          </form>
        </Content>
      )}
    </Formik>
  );
};

const PslfQuestionContainer = styled.div`
  margin-bottom: 16px;
`;

const Container = styled.div`
  max-width: 464px;
  margin: auto;
`;

const mapStateToProps = state => ({
  currentPslfForm: getUserCurrentPslfForm(state),
});

export default connect(mapStateToProps, { updateCurrentPslfForm })(
  PslfReadiness,
);
