import React from 'react';
import styled from 'styled-components';

import Cleave from 'cleave.js/react';
import InputContainer, { FormInput } from '../Input';

import { COLORS } from '../../../helpers/constants/styleguide';

class IdrPhoneInput extends React.Component {
  static defaultProps = {
    disabled: false,
    onFocus: () => {},
    onBlur: () => {},
    placeholder: '',
  };

  state = {
    initialValue: this.props.value,
  };

  handleChange = event => {
    this.props.onChange(this.props.name, event.target.rawValue);
  };

  renderInputText = handleFocusChange => {
    const { disabled, name, onBlur, placeholder, autoFocus } = this.props;

    return (
      <Input
        id={`${name}-input`}
        autoFocus={autoFocus}
        inputMode="tel"
        autoComplete="tel-national"
        type="tel"
        name={name}
        value={this.state.initialValue}
        options={{
          blocks: [0, 3, 0, 3, 0, 0, 4],
          delimiters: ['(', ')', ' ', ' ', '-', ' '],
          numericOnly: true,
          noImmediatePrefix: true,
          rawValueTrimPrefix: true,
        }}
        placeholder={placeholder}
        onChange={this.handleChange}
        onFocus={handleFocusChange}
        disabled={disabled}
        onBlur={e => {
          handleFocusChange();
          onBlur(e);
        }}
      />
    );
  };

  render() {
    const { error, touched, isSubmitting, label, helpMessage } = this.props;
    return (
      <InputContainer
        error={error}
        touched={touched}
        isSubmitting={isSubmitting}
        label={label}
        helpMessage={helpMessage}
      >
        {this.renderInputText}
      </InputContainer>
    );
  }
}

const Input = styled(FormInput.withComponent(Cleave))`
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  margin: auto 0;
  caret-color: ${COLORS.darkerGrey};

  &:focus {
    outline: none;
  }
`;

export default IdrPhoneInput;
