import React from 'react';
import styled from 'styled-components';

import Icon, { Close } from '../Icon';
import { Text } from '../typography';
import { COLORS } from '../../helpers/constants/styleguide';
import { FlexRow } from '../Flex';

class Tip extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: true,
    };
  }

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
    if (this.props.handleToggle) {
      this.props.handleToggle();
    }
  };

  render() {
    const { isOpen } = this.state;
    return (
      <>
        {isOpen && (
          <TipContainer>
            <div onClick={this.handleClose}>
              <CloseIcon SvgComponent={Close} fill={COLORS.medGrey} />
            </div>
            <Body>
              <Text small>{this.props.children}</Text>
            </Body>
          </TipContainer>
        )}
      </>
    );
  }
}

const TipContainer = styled(FlexRow)`
  position: relative;
  margin: 20px 0;
  width: 340px;
  flex-shrink: 0;
  background-color: ${COLORS.lightestGrey};
  border: 1px solid ${COLORS.grey};
`;

const CloseIcon = styled(Icon)`
  cursor: pointer;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
`;

const Body = styled.div`
  width: 280px;
  padding: 30px 0;
  margin: auto;
`;

export default Tip;
