import React from 'react';
import styled from 'styled-components';

import { COLORS, RadioButtons, Text } from '@summer/ui-components/src';

import { Label, LabelContainer, Question } from '../../../shared/styles';

const buttonOptions = [
  {
    value: 'true',
    label: 'Yes. I want to be automatically recertified every year.',
  },
  {
    value: 'false',
    label: 'No. I will submit my income information on my own every year.',
  },
];

const buttonCSS = `
      width: 100%;
      height: 40px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);

      input {
        margin-left: -50%;
      }
    `;

const ConsentToIrsDisclosure = ({ idrHandlers, touched, errors, values }) => {
  const { isSubmitting, handleChange, handleBlur } = idrHandlers;

  return (
    <Question>
      <TitleContainer>
        <Text bold weight="medium">
          TAX INFORMATION
        </Text>
      </TitleContainer>
      <QuestionContainer>
        <LabelContainer>
          <Label>
            Do you want to give the Department of Education permission to obtain
            your tax information from the IRS?
          </Label>
        </LabelContainer>
        <RadioButtons
          row
          design="smallRec"
          name="submit.consentToIrsDisclosure"
          touched={touched.consentToIrsDisclosure}
          isSubmitting={isSubmitting}
          error={errors.consentToIrsDisclosure}
          value={values.consentToIrsDisclosure}
          onChange={handleChange}
          onBlur={handleBlur}
          options={buttonOptions}
          buttonCSS={buttonCSS}
          numberOfCol={1}
        />
      </QuestionContainer>
    </Question>
  );
};

const TitleContainer = styled.div`
  border: 1px solid ${COLORS.grey};
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 ${COLORS.lighterGrey};
  height: 64px;
  padding: 22px;
`;

const QuestionContainer = styled.div`
  background-color: ${COLORS.lightestGrey};
  padding: 24px;
`;

export default ConsentToIrsDisclosure;
