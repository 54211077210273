import {
  IDENTIFY,
  TRACK_ACTION,
  TRACK_SERVER_SIDE,
} from 'constants/actionTypes';

export const identifyAction = () => ({
  type: IDENTIFY,
});

export const trackAction = (payload, meta) => ({
  type: TRACK_ACTION,
  payload,
  meta,
});

export const trackServerSide = (event, { properties, integrations } = {}) => ({
  type: TRACK_SERVER_SIDE,
  payload: {
    event,
    properties,
    integrations,
  },
});
