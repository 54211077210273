import { format as formatCurrency } from 'currency-formatter';
import { isNil, toNumber, isNaN } from 'lodash';

// These utils are duplicated in ui-components;
// changes must be made in both locations until they are consolidated

export const dollars = (amount, { precision = 0, showSymbol = true } = {}) => {
  if (typeof amount !== 'number') {
    return amount;
  }
  const formattedDollars =
    amount || precision
      ? formatCurrency(amount / 100, { code: 'USD', precision })
      : '$0';
  return showSymbol ? formattedDollars : formattedDollars.slice(1);
};

export const percentage = (
  amount,
  { precision = 2, showSymbol = true } = {},
) => {
  if (typeof amount !== 'number') {
    return amount;
  }
  const formattedPercentage = amount
    ? (amount * 100).toFixed(precision).toString()
    : '0';
  return showSymbol ? `${formattedPercentage}%` : formattedPercentage;
};

/* This function will convert a string to a number.
 * If passed null or empty string it will return null instead of 0
 * If number conversion produces NaN it will return null
 */
export const safeStringToNumber = string => {
  if (isNil(string) || string === '') {
    return null;
  }
  const number = toNumber(string);
  if (isNaN(number)) {
    return null;
  }
  return number;
};
