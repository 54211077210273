import React from 'react';
import styled from 'styled-components';

import { RadioButtons, Text } from '@summer/ui-components';

import { Question, Label } from 'components/pages/idr/wizard/shared/styles';
import { Link } from 'components/shared/typography';
import { servedInMilitaryLearnMoreLink } from 'constants/externalLinks/fsa';
import { useClearFieldOnUnmount } from 'hooks/formik';

const KeepMilitaryInterestCap = ({
  formik: {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    setFieldValue,
  },
}) => {
  useClearFieldOnUnmount('submit.keepMilitaryInterestCap', null, setFieldValue);

  return (
    <Question id="keepMilitaryInterestCap">
      <Label>
        Do you want to apply for/keep the military service interest rate cap on
        your loans?
      </Label>
      <HelperText>
        <Text paragraph>If yes, we will not consolidate your loans. </Text>
      </HelperText>
      <RadioButtons
        row
        design="smallRec"
        name="submit.keepMilitaryInterestCap"
        touched={touched.keepMilitaryInterestCap}
        isSubmitting={isSubmitting}
        error={errors.keepMilitaryInterestCap}
        value={values.keepMilitaryInterestCap}
        onChange={handleChange}
        onBlur={handleBlur}
        options={[
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ]}
      />
    </Question>
  );
};

const ServedInMilitary = ({
  formik,
  formik: { errors, values, touched, handleChange, handleBlur, isSubmitting },
}) => (
  <>
    <Question id="servedInMilitary">
      <Label>Have you ever served in the US military?</Label>
      <HelperText>
        <Text paragraph>
          Any loans you took out before your service may be eligible for an
          interest rate cap (6%) during the period you served. Consolidating
          your loans will make you ineligible for this benefit. We recommend
          contacting your servicer.{' '}
          <Link inheritColor underline href={servedInMilitaryLearnMoreLink}>
            Learn More
          </Link>
        </Text>
      </HelperText>
      <RadioButtons
        row
        design="smallRec"
        label="Hello"
        name="submit.servedInMilitary"
        touched={touched.servedInMilitary}
        isSubmitting={isSubmitting}
        error={errors.servedInMilitary}
        value={values.servedInMilitary}
        onChange={handleChange}
        onBlur={handleBlur}
        options={[
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ]}
      />
    </Question>
    {values.servedInMilitary === 'true' && (
      <KeepMilitaryInterestCap formik={formik} />
    )}
  </>
);

const HelperText = styled.div`
  margin-bottom: 12px;
`;

export default ServedInMilitary;
