import React from 'react';
import styled from 'styled-components';

import { Header } from '@summer/ui-components';

import { MobileSize } from 'constants/styleguide';

const SubmitHeader = ({ children }) => (
  <Container>
    <Header as="h2" center>
      {children}
    </Header>
  </Container>
);

const Container = styled.div`
  margin-bottom: 32px;

  @media (max-width: ${MobileSize}) {
    margin-bottom: 24px;
  }
`;

export default SubmitHeader;
