import React, { Component } from 'react';

import { Text, MoneyInput } from '@summer/ui-components';

import {
  Label,
  LabelContainer,
  Question,
  SmallTextInputContainer,
} from 'components/pages/idr/wizard/shared/styles';
import InfoTooltip from 'components/shared/InfoTooltip';
import { validateNumber } from 'utils/validate';

class SpouseExpectedAnnualGrossIncome extends Component {
  componentWillUnmount() {
    this.props.formik.setFieldValue(
      `tellUsAboutYou.spouseExpectedAnnualGrossIncome`,
      '',
    );
  }

  render() {
    const {
      formik: {
        values,
        errors,
        handleBlur,
        touched,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
      },
    } = this.props;

    return (
      <Question id="spouseExpectedAnnualGrossIncome">
        <LabelContainer>
          <Label>
            What is your spouse&rsquo;s expected annual gross taxable income?
          </Label>
          <InfoTooltip
            size={14}
            top={3}
            leftOffset={6}
            text={
              'If your spouse has income, then you are going to need to provide proof of this income later by submitting your spouse\u2019s paystubs or a written statement explaining your spouse\u2019s income.'
            }
          />
        </LabelContainer>
        <LabelContainer>
          <Label>
            <Text paragraph>
              {values.spouseReceivingUnemploymentIncome === 'false'
                ? 'Enter any other taxable income that your spouse is expecting in the next 12 months.'
                : 'Enter your spouse\u2019s expected income for the next 12 months.'}
            </Text>
          </Label>
        </LabelContainer>
        <SmallTextInputContainer>
          <MoneyInput
            name="tellUsAboutYou.spouseExpectedAnnualGrossIncome"
            value={values.spouseExpectedAnnualGrossIncome}
            error={errors.spouseExpectedAnnualGrossIncome}
            touched={touched.spouseExpectedAnnualGrossIncome}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            isSubmitting={isSubmitting}
            onBlur={handleBlur}
            validate={validateNumber()}
          />
        </SmallTextInputContainer>
      </Question>
    );
  }
}

export default SpouseExpectedAnnualGrossIncome;
