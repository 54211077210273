import React from 'react';
import styled from 'styled-components';

import { TextInput, SelectInput, NumberInput } from '@summer/ui-components';

import { Row } from 'components/pages/idr/wizard/shared/styles';
import { InputWrapper } from 'components/pages/idr/wizard/steps/Submit/shared/Inputs';
import { StatesPostal } from 'constants/states';
import { MobileSize } from 'constants/styleguide';

class Address extends React.Component {
  render() {
    const {
      formik: {
        values,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        touched,
        isSubmitting,
      },
    } = this.props;

    return (
      <>
        <InputWrapper>
          <TextInput
            label="Address"
            name="streetAddress1"
            touched={touched.streetAddress1}
            isSubmitting={isSubmitting}
            error={errors.streetAddress1}
            value={values.streetAddress1}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Address"
            autoComplete="street-address"
          />
        </InputWrapper>
        <AddressRow>
          <InputWrapper>
            <City>
              <TextInput
                name="city"
                touched={touched.city}
                isSubmitting={isSubmitting}
                error={errors.city}
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="City"
                autoComplete="address-level2"
              />
            </City>
          </InputWrapper>
          <InputWrapper>
            <NoWrapRow>
              <State>
                <SelectInput
                  name="state"
                  touched={touched.state}
                  isSubmitting={isSubmitting}
                  error={errors.state}
                  value={values.state}
                  options={StatesPostal}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  placeholder="State"
                  autoComplete="address-level1"
                />
              </State>
              <Zip>
                <NumberInput
                  limit={5}
                  name="zipcode"
                  touched={touched.zipcode}
                  error={errors.zipcode}
                  value={values.zipcode}
                  isSubmitting={isSubmitting}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  keepLeadingZeroes
                  placeholder="Zip"
                  autoComplete="postal-code"
                />
              </Zip>
            </NoWrapRow>
          </InputWrapper>
        </AddressRow>
      </>
    );
  }
}

const NoWrapRow = styled(Row)`
  flex: 1;
  flex-wrap: nowrap;
`;

const City = styled.div`
  flex: 1;
`;

const State = styled.div`
  flex: 1;
  @media (max-width: ${MobileSize}) {
    margin-right: 16px;
  }
`;

const Zip = styled.div`
  flex: 1;
`;

const AddressRow = styled(Row)`
  @media (max-width: ${MobileSize}) {
    flex-direction: column;
  }
`;

export default Address;
