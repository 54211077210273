import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { MoneyInput } from '@summer/ui-components';

import StaticField from 'components/common/ManageLoans/fields/shared/StaticField';
import InfoTooltip from 'components/shared/InfoTooltip';
import { isLoanFieldEditable } from 'redux/selectors/loans.selectors';
import { dollars } from 'utils/numbers';

const OutstandingInterestLabel = () => (
  <>
    <span>Outstanding Interest</span>
    <InfoTooltip
      text="This is how much interest you currently owe on your loans. Your outstanding interest plus your principal balance equals your current loan balance."
      top={2}
    />
  </>
);

class OutstandingInterest extends React.Component {
  componentWillUnmount() {
    if (this.props.canEdit) {
      this.props.formik.setFieldValue('outstandingInterestBalance', null);
    }
  }

  render() {
    const {
      formik: {
        values,
        errors,
        setFieldValue,
        setFieldTouched,
        handleBlur,
        touched,
        isSubmitting,
      },
      canEdit,
    } = this.props;
    return (
      <Container id="outstandingInterestBalance">
        <InputContainer>
          {canEdit ? (
            <MoneyInput
              name="outstandingInterestBalance"
              value={values.outstandingInterestBalance}
              error={errors.outstandingInterestBalance}
              touched={touched.outstandingInterestBalance}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              onBlur={handleBlur}
              isSubmitting={isSubmitting}
              height={40}
              label={<OutstandingInterestLabel />}
            />
          ) : (
            <StaticField
              label={<OutstandingInterestLabel />}
              value={dollars(values.outstandingInterestBalance)}
            />
          )}
        </InputContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  flex: 1;
`;

const InputContainer = styled.div`
  width: 100%;
`;

const mapStateToProps = (state, props) => {
  const loanId = props.formik.values.id;
  return {
    canEdit: isLoanFieldEditable(state, loanId, 'outstandingInterestBalance'),
  };
};

export default connect(mapStateToProps)(OutstandingInterest);
