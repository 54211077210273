import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { FlexColumn, TabGroup, Text } from '@summer/ui-components/src';

import Results from 'components/pages/collegeSavingsPlanner/calculator/results';
import YourInformation from 'components/pages/collegeSavingsPlanner/calculator/yourInformation';
import LandingPage from 'components/shared/dashboard/LandingPage';
import { MobileSize } from 'constants/styleguide';
import { fetchCSPCalculatorData } from 'redux/actions/collegeSavingsPlanner.actions';

import FurtherHelp from './furtherHelp';
import CollegeSavingsPlannerResourceCenter from './resourceCenter';

const CollegeSavingsPlannerCalculator = () => {
  const [resultsEnabled, setResultsEnabled] = useState(false);
  const enableResults = () => setResultsEnabled(true);
  const dispatch = useDispatch();

  const scrollToTop = () => {
    setImmediate(() => {
      const tab = document.getElementById(`styled-tab-list`);
      tab.scrollIntoView({ behavior: 'smooth' });
    });
  };

  useEffect(
    function fetchCalculatorDataOnMount() {
      dispatch(fetchCSPCalculatorData());
    },
    [dispatch],
  );

  return (
    <LandingPage
      headerTheme="orange"
      headerName="College Savings Planner"
      subHeader={
        <Subheader paragraph weight="medium">
          Use our tool to find out how much you need to save for college, how
          much you can expect in federal aid, and strategies for bridging the
          gap.
        </Subheader>
      }
    >
      <Content>
        <TabsContainer>
          <TabGroup
            baseUrl="/college-savings-planner/calculator"
            location={{
              pathname: '/college-savings-planner/calculator/your-info',
            }}
            tabs={[
              {
                title: 'Your Information',
                content: (
                  <YourInformation
                    enableResults={enableResults}
                    scrollToTop={scrollToTop}
                  />
                ),
              },
              {
                title: 'Results',
                content: <Results scrollToTop={scrollToTop} />,
                url: '/results',
                disabled: !resultsEnabled,
              },
            ]}
          />
        </TabsContainer>
        <FurtherHelp />
        <CollegeSavingsPlannerResourceCenter />
      </Content>
    </LandingPage>
  );
};

const Subheader = styled(Text)`
  margin: 16px 8px 0 8px;
`;

const TabsContainer = styled.div``;

const Content = styled(FlexColumn)`
  position: relative;
  padding: 48px 32px 144px;
  margin: 0 auto;
  max-width: 1080px;
  gap: 48px;
  z-index: 1;

  @media (max-width: ${MobileSize}) {
    padding: 28px 24px 0;
  }
`;

export default CollegeSavingsPlannerCalculator;
