import { push as redirectTo } from 'connected-react-router';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  FlexRow,
  Header,
  RetirementMatch,
  SvgImage,
  Text,
  Button,
  COLORS,
  Card,
  Checkbox,
} from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { rmGuideLink } from 'constants/externalLinks/marketingSite';
import { MobileSize } from 'constants/styleguide';
import { postRetirementMatchConsent } from 'redux/actions/retirementMatch.actions';
import { isLoadingFunctional } from 'redux/selectors/ui.selectors';
import { hexToRgba } from 'utils/common';

const RetirementMatchInstructions = () => {
  return (
    <>
      <Header as="h2" bold>
        Match qualifying student loan payments to your retirement plan
      </Header>
      <RoundedOrderedList>
        <OrderedListItem>
          <OrderedListTitle paragraph bold>
            Connect your loans
          </OrderedListTitle>
          <Text paragraph>
            Upload your student loan and payment information from your loan
            servicer.
          </Text>
        </OrderedListItem>
        <OrderedListItem>
          <OrderedListTitle paragraph bold>
            Confirm your enrollment
          </OrderedListTitle>
          <Text paragraph>
            Double check your information and continue to make student loan
            payments as usual.
          </Text>
        </OrderedListItem>
        <OrderedListItem>
          <OrderedListTitle paragraph bold>
            Enjoy your benefit
          </OrderedListTitle>
          <Text paragraph>
            Your employer and retirement provider will use this information to
            implement the matching contributions to your retirement plan.
          </Text>
        </OrderedListItem>
      </RoundedOrderedList>
    </>
  );
};

const RetirementMatchBenefitInfo = () => {
  return (
    <BenefitContent>
      <Text paragraph bold large>
        More about this benefit
      </Text>
      <br />
      <UnorderedList>
        <li>
          <Text paragraph>
            The exact amount of the match you receive is determined by your
            employer&rsquo;s plan rules.
          </Text>
        </li>
        <li>
          <Text paragraph>
            You can use both student loan matching and retirement deferrals to
            maximize your employer&rsquo;s matching policy, but if you&rsquo;re
            already receiving the maximum match from your employer&rsquo;s
            policy by contributing to your retirement plan, you won&rsquo;t
            receive an additional match for your student loan payments.
          </Text>
        </li>
        <li>
          <Text paragraph>
            You can read more about this new financial wellness benefit by
            reading this <Link href={rmGuideLink}>article</Link>.
          </Text>
        </li>
      </UnorderedList>
    </BenefitContent>
  );
};

const StartPage = ({
  openPlaid,
  setIsPlaidDone,
  isPlaidReady,
  setIsPlaidOpening,
}) => {
  const [retirementMatchOptIn, setRetirementMatchOptIn] = useState(false);
  const isConsentRequestLoading = useSelector(
    isLoadingFunctional('postRetirementMatchConsent'),
  );
  const dispatch = useDispatch();

  const handleBackup = () => {
    dispatch(
      postRetirementMatchConsent({
        onSuccess: () => {
          dispatch(redirectTo('/retirement-match/upload-documents'));
        },
      }),
    );
  };

  const handleSync = () => {
    dispatch(
      postRetirementMatchConsent({
        onSuccess: () => {
          openPlaid();
          setIsPlaidOpening(true);
          setIsPlaidDone(false);
        },
      }),
    );
  };

  return (
    <>
      <Card>
        <Container>
          <TitleContent>
            <ContentLeft>
              <SvgImage SvgComponent={RetirementMatch}></SvgImage>
            </ContentLeft>
            <ContentRight>
              <RetirementMatchInstructions />
            </ContentRight>
          </TitleContent>
          <RetirementMatchBenefitInfo />
          <Formik>
            <Checkbox
              name="hasOptedIntoRetirementMatch"
              label={
                <Text paragraph>
                  By connecting your loan, you are attesting that you are
                  connecting a student loan that was taken out under your own
                  name. Your employer may request additional verification, and
                  you hereby authorize Summer to share information for this
                  purpose.
                </Text>
              }
              onChange={() => setRetirementMatchOptIn(!retirementMatchOptIn)}
            />
          </Formik>
          <CardContent>
            <Button
              type="button"
              secondary
              borderless
              width={210}
              backgroundColor="#fafafa"
              onClick={() => handleSync()}
              isLoading={isPlaidReady || isConsentRequestLoading}
              disabled={!retirementMatchOptIn || isConsentRequestLoading}
            >
              I have private loans
            </Button>
            <Button
              type="button"
              width={160}
              onClick={() => handleBackup()}
              disabled={!retirementMatchOptIn || isConsentRequestLoading}
            >
              Upload my documents
            </Button>
          </CardContent>
        </Container>
      </Card>
    </>
  );
};

const Container = styled.div`
  @media (min-width: ${MobileSize}) {
    padding: 16px;
  }
`;

const TitleContent = styled(FlexRow)`
  display: flex;
  text-align: left;
  justify-content: center;

  flex-direction: column;
  @media (min-width: ${MobileSize}) {
    flex-direction: row;
  }
`;

const ContentLeft = styled.div`
  align-self: center;

  svg {
    width: 100%;
  }
  margin-bottom: 24px;
  @media (min-width: ${MobileSize}) {
    margin-right: 80px;
    margin-bottom: 0;
  }
`;

const ContentRight = styled.div`
  max-width: 430px;
  & > * {
    margin-top: 16px;
  }
`;

const BenefitContent = styled.div`
  padding: 14px 28px 30px 28px;
  margin: 0 0 28px 0;
  background: ${COLORS.lighterGrey};
`;

const CardContent = styled(FlexRow)`
  margin-top: 24px;
  display: flex;
  align-items: end;
  justify-content: flex-end;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
  }
`;

const RoundedOrderedList = styled.ol`
  counter-reset: li;
  list-style: none;
  padding: 0;
  margin-bottom 30px;
`;
const OrderedListItem = styled.li`
  position: relative;
  display: block;
  padding: 0 10px 0 40px;
  margin-top: 10px;
`;

const OrderedListTitle = styled(Text)`
  &:before {
    content: counter(li);
    counter-increment: li;
    position: absolute;
    left: 0px;
    margin-top: -3px;
    color: ${hexToRgba(COLORS.darkGreen, 0.7)};
    background: ${hexToRgba(COLORS.jade, 0.15)};
    height: 30px;
    width: 30px;
    line-height: 1.9;
    text-align: center;
    font-weight: bold;
    border-radius: 30px;
  }
`;

const UnorderedList = styled.ul`
  margin-left: 16px;
`;

export default StartPage;
