import React from 'react';
import styled from 'styled-components';

import {
  Text,
  Header,
  Strong,
  Email,
  Phone,
  Website,
  Icon,
  COLORS,
} from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { defaultResolutionGroupWebsiteLink } from 'constants/externalLinks/fsa';
import { MobileSize } from 'constants/styleguide';
import { hexToRgba } from 'utils/common';

const Checklist = ({ checklistConfig }) => {
  const { header, items } = checklistConfig;
  return (
    <Background color={COLORS.lightestGrey}>
      <ChecklistHeader>
        <Strong>{header}</Strong>
      </ChecklistHeader>
      <List>
        {items.map((item, idx) => (
          <Text as="li" paragraph key={idx}>
            {item}
          </Text>
        ))}
      </List>
    </Background>
  );
};

export const DefaultResolutionGroupText = ({ optionName }) => {
  const options = {
    rehabilitation: {
      title: 'Loan Rehabilitation',
      text: ' and tell them you want to start the loan rehabilitation process',
    },
    consolidation: {
      title: 'Loan Consolidation',
      text: ' and tell them you want to start the loan consolidation process',
    },
    settlement: {
      title: 'a Settlement',
      text: '',
    },
  };
  return (
    <>
      <DefaultResolutionHeader h4>
        How to Start {options[optionName].title}
      </DefaultResolutionHeader>
      <Text paragraph>
        The first step is to call your servicer or the Department of Education’s
        Default Resolution Group{options[optionName].text}.
      </Text>
    </>
  );
};

export const DefaultResolutionGroupContactInfo = () => (
  <DefaultResolutionBox>
    <Text bold paragraph>
      Default Resolution Group
    </Text>
    <Text paragraph>Mon – Fri, 8am – 10pm ET,</Text>
    <Text paragraph>Sat 8am – 6pm </Text>
    <ContactListItem small>
      <ContactListIcon SvgComponent={Phone} fill={COLORS.darkerGrey} />
      <Link underline inheritColor href="tel:18006213115">
        1-800-621-3115
      </Link>
    </ContactListItem>
    <ContactListItem small>
      <ContactListIcon SvgComponent={Email} fill={COLORS.darkerGrey} />
      <Link underline inheritColor href="mailto:customerservice@myeddebt.com">
        customerservice@myeddebt.com
      </Link>
    </ContactListItem>
    <ContactListItem small>
      <ContactListIcon SvgComponent={Website} fill={COLORS.darkerGrey} />
      <Link underline inheritColor href={defaultResolutionGroupWebsiteLink}>
        myeddebt.ed.gov/borrower
      </Link>
    </ContactListItem>
  </DefaultResolutionBox>
);

const DefaultResolutionGroupInfo = ({ optionName }) => {
  return (
    <>
      <DefaultResolutionGroupText optionName={optionName} />
      <DefaultResolutionGroupContactInfo />
    </>
  );
};

export const BlueBox = ({ children }) => {
  return (
    <Background color={hexToRgba(COLORS.azure, 0.1)}>{children}</Background>
  );
};

const DefaultDrawerContent = ({
  checklistConfig,
  middle,
  optionName,
  blueBoxContents,
  bottom,
}) => {
  return (
    <>
      {checklistConfig && <Checklist checklistConfig={checklistConfig} />}
      {middle}
      <DefaultResolutionGroupInfo optionName={optionName} />
      <BlueBox>{blueBoxContents}</BlueBox>
      {bottom}
    </>
  );
};

const Background = styled.div`
  background-color: ${({ color }) => color || COLORS.white};
  padding: 24px;
  :not(:last-child) {
    margin-bottom: 24px;
  }

  @media (max-width: ${MobileSize}) {
    margin-left: -16px;
    margin-right: -16px;
  }
`;

const ChecklistHeader = styled(Text)`
  margin-bottom: 16px;
`;

const List = styled.ul`
  margin-left: 24px;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const ContactListItem = styled(Text)`
  display: flex;
  flex-direction: row;
  margin: 16px 0;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ContactListIcon = styled(Icon)`
  margin-right: 8px;
`;

const DefaultResolutionBox = styled.div`
  background-color: ${COLORS.lighterGrey};
  padding: 16px 24px;
  margin: 16px 0 24px;

  @media (max-width: ${MobileSize}) {
    padding: 16px 16px 24px;
  }
`;

const DefaultResolutionHeader = styled(Header)`
  margin-bottom: 8px;
`;

export default DefaultDrawerContent;
