import React from 'react';

import { DateInput } from '@summer/ui-components';

const DateOfBirth = ({
  formik: { values, errors, setFieldValue, handleBlur, touched, isSubmitting },
}) => (
  <>
    <DateInput
      label="Date of Birth"
      name="submit.dateOfBirth"
      touched={touched.dateOfBirth}
      isSubmitting={isSubmitting}
      error={errors.dateOfBirth}
      value={values.dateOfBirth}
      onChange={setFieldValue}
      onBlur={handleBlur}
    />
  </>
);

export default DateOfBirth;
