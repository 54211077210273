import { Formik } from 'formik';
import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { RadioButtons } from '@summer/ui-components';

import { useOnSuccess } from 'hooks/idr';
import { usePlatform } from 'hooks/platform';
import { updateCurrentIdrForm } from 'redux/actions/idr.actions';
import { toggleIdrQuestionnaire } from 'redux/actions/ui.actions';
import { updateIsCurrentlyEnrolledInIdr } from 'redux/actions/user.actions';
import {
  getCurrentIdrFormId,
  getCurrentIdrFormStep,
} from 'redux/selectors/idr.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { getIdrOnboardingQuestion } from 'redux/selectors/user.selectors';
import { isCurrentlyEnrolledInIdrSchema } from 'schemas/idr';

import Footer from '../Footer';
import { FormContents, Label, HelperText } from '../shared';

const InIdrPlan = ({
  formId,
  isCurrentlyEnrolledInIdr,
  currentIdrFormStep,
  isLoading,
  navigation,
}) => {
  const { isMobile } = usePlatform();
  const dispatch = useDispatch();
  const { next, back, isLastStep } = navigation;

  const reviewYourPlansStep = 3;

  const onSuccess = () => {
    const financialProfile =
      // If not enrolled, clear plan and recert date from financial profile
      isCurrentlyEnrolledInIdr === 'no'
        ? {
            idrRepaymentPlanTypeId: null,
            idrRecertDate: null,
          }
        : {};

    if (isLastStep) {
      dispatch(
        updateCurrentIdrForm(
          {
            currentStep: reviewYourPlansStep,
            form: { formId },
            financialProfile,
          },
          {
            close: false,
            finish: false,
            onSuccess: () => dispatch(toggleIdrQuestionnaire(false)),
          },
        ),
      );
    } else if (isCurrentlyEnrolledInIdr === 'no') {
      dispatch(
        updateCurrentIdrForm(
          {
            currentStep: currentIdrFormStep,
            form: { formId },
            financialProfile,
          },
          { close: false, finish: false, onSuccess: next },
        ),
      );
    } else {
      next();
    }
  };

  useOnSuccess('updateIsCurrentlyEnrolledInIdr', onSuccess);

  const handleSave = ({ isCurrentlyEnrolledInIdr }) => {
    // Update users
    dispatch(updateIsCurrentlyEnrolledInIdr(isCurrentlyEnrolledInIdr));
  };

  return (
    <Formik
      initialValues={{ isCurrentlyEnrolledInIdr }}
      onSubmit={handleSave}
      validationSchema={isCurrentlyEnrolledInIdrSchema}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        isValid,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormContents>
              <Label
                h2
                align={isMobile ? 'center' : 'left'}
                id="isCurrentlyEnrolledInIdr"
              >
                Are you currently in an income-driven repayment (IDR) plan?
              </Label>
              <HelperText paragraph>
                You may be in an IDR plan if you submitted an application and
                provided a proof of income. Your IDR plan may be called Pay As
                You Earn (PAYE), Revised Pay As You Earn (REPAYE), Income-Based
                Repayment (IBR), or Income-Contingent Repayment (ICR).
              </HelperText>
              <RadioButtons
                name="isCurrentlyEnrolledInIdr"
                value={values.isCurrentlyEnrolledInIdr}
                error={errors?.isCurrentlyEnrolledInIdr}
                touched={touched.isCurrentlyEnrolledInIdr}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                numberOfCol={2}
                isSubmitting={isSubmitting}
                onBlur={handleBlur}
                options={[
                  { value: 'yes', label: 'Yes' },
                  { value: 'no', label: 'No' },
                ]}
              />
            </FormContents>
            <Footer
              handleBack={back}
              isBackLoading={isLoading}
              isNextDisabled={!isValid}
              isNextLoading={isLoading}
              isValid={isValid}
            />
          </form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => ({
  currentIdrFormStep: getCurrentIdrFormStep(state),
  isCurrentlyEnrolledInIdr: getIdrOnboardingQuestion(state),
  isLoading:
    isLoading(state, 'updateIsCurrentlyEnrolledInIdr') ||
    isLoading(state, 'updateCurrentIdrForm'),
  formId: getCurrentIdrFormId(state),
});

export default connect(mapStateToProps)(InIdrPlan);
