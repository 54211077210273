import {
  CREATE_SMS_FACTOR,
  SEND_SMS_FACTOR_OTP,
  SET_SMS_FACTOR,
  VERIFY_SMS_FACTOR,
} from 'constants/actionTypes';

export const createSmsFactor = ({ phoneNumber }, { onSuccess }) => ({
  type: CREATE_SMS_FACTOR,
  payload: {
    phoneNumber,
  },
  meta: { onSuccess },
});

export const verifySmsFactor = ({ code, phoneNumber }, { onSuccess }) => ({
  type: VERIFY_SMS_FACTOR,
  payload: {
    code,
    phoneNumber,
  },
  meta: { onSuccess },
});

export const setSmsFactor = ({ phoneNumber, status }) => ({
  type: SET_SMS_FACTOR,
  payload: {
    phoneNumber,
    status,
  },
});

export const sendSmsFactorOTP = ({ phoneNumber }, { onSuccess }) => ({
  type: SEND_SMS_FACTOR_OTP,
  payload: {
    phoneNumber,
  },
  meta: { onSuccess },
});
