import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// SVG Images - Keep in alphabetical order
export { ReactComponent as BalloonQuestion } from '../assets/svgImages/errorPage/balloonQuestion.svg';
export { ReactComponent as BetaLight } from '../assets/svgImages/betaLight.svg';
export { ReactComponent as BidenDebtRelief } from '../assets/svgImages/biden-debt-relief.svg';
export { ReactComponent as BriefcaseAid } from '../assets/svgImages/briefcase-aid.svg';
export { ReactComponent as BriefcasePlus } from '../assets/svgImages/briefcasePlus.svg';
export { ReactComponent as BrowserChrome } from '../assets/svgImages/browser-chrome.svg';
export { ReactComponent as BrowserFirefox } from '../assets/svgImages/browser-firefox.svg';
export { ReactComponent as BrowserEdge } from '../assets/svgImages/browser-edge.svg';
export { ReactComponent as CheckWatermark } from '../assets/svgImages/checkWatermark.svg';
export { ReactComponent as ConsolidateLoans } from '../assets/svgImages/consolidateLoans.svg';
export { ReactComponent as CreditCardCheck } from '../assets/svgImages/credit-card-check.svg';
export { ReactComponent as ErrorImage } from '../assets/svgImages/errorPage/errorCloud.svg';
export { ReactComponent as EmergencySavingsImage } from '../assets/svgImages/emergency-savings.svg';
export { ReactComponent as FormDownloadIcon } from '../assets/svgImages/formDownloadIcon.svg';
export { ReactComponent as LoansDocs } from '../assets/svgImages/loansDocs.svg';
export { ReactComponent as LockNew } from '../assets/svgImages/lockNew.svg';
export { ReactComponent as MoneyHand } from '../assets/svgImages/money-hand.svg';
export { ReactComponent as MoreDetails } from '../assets/svgImages/moreDetails.svg';
export { ReactComponent as RetirementMatch } from '../assets/svgImages/retirementMatch.svg';
export { ReactComponent as SummerBasicIdr } from '../assets/svgImages/summerBasicIdr.svg';
export { ReactComponent as SummerBasicPslf } from '../assets/svgImages/summerBasicPslf.svg';
export { ReactComponent as SummerBasicConsolidation } from '../assets/svgImages/summerBasicConsolidation.svg';
export { ReactComponent as TuitionReimbursement } from '../assets/svgImages/tuitionReimbursement.svg';
export { ReactComponent as SavingsTargetDartThrow } from '../assets/svgImages/savings-target-dart-throw.svg';
export { ReactComponent as Student } from '../assets/svgImages/student.svg';
export { ReactComponent as Tracker } from '../assets/svgImages/tracker.svg';
export { ReactComponent as WalletPainted } from '../assets/svgImages/wallet-painted.svg';

const SvgImage = ({
  SvgComponent,
  width,
  height,
  className,
  onClick,
  style,
}) => {
  return (
    <StyledSvgImage
      svgWidth={width}
      svgHeight={height}
      className={className}
      onClick={onClick}
      style={style}
    >
      <SvgComponent />
    </StyledSvgImage>
  );
};

const StyledSvgImage = styled.div.attrs({
  className: 'icon',
})`
  display: flex;
  justify-content: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};

  & svg {
    width: ${({ svgWidth }) =>
      svgWidth ? `${svgWidth}px !important` : 'auto'};
    height: ${({ svgHeight }) =>
      svgHeight ? `${svgHeight}px !important` : 'auto'};
  }
`;

SvgImage.propTypes = {
  SvgComponent: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SvgImage;
