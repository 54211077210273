import {
  BeggingHandCoin,
  HelpQuestionNetwork,
  RecruitingEmployeeGraduateMoney,
  SavingBankCash,
  SavingSafe,
  TimeMonthly,
} from '@summer/ui-components/src';

import {
  csp529PlanLink,
  cspConsiderLoansLink,
  cspFafsaLink,
  cspFederalVsPrivateLink,
  cspNavigatingFinancialAidLink,
  cspOtherFundingOptionsLink,
} from 'constants/externalLinks/marketingSite';

export const collegePlanningGuides = [
  {
    href: csp529PlanLink,
    icon: SavingSafe,
    headerText: 'How a 529 Plan Can Help',
    contentText:
      'Learn about the details and benefits of 529 plans and how they can help you plan for college',
  },
  {
    href: cspFafsaLink,
    icon: RecruitingEmployeeGraduateMoney,
    headerText: 'Everything You Need to Know About the FAFSA',
    contentText: 'How it works, how to apply, and what you need to know',
  },
  {
    href: cspNavigatingFinancialAidLink,
    icon: BeggingHandCoin,
    headerText: 'Navigating Financial Aid',
    contentText:
      'Financial aid eligibility and what goes into it, for both federal and school-specific programs',
  },
  {
    href: cspOtherFundingOptionsLink,
    icon: HelpQuestionNetwork,
    headerText: 'Exploring Other Funding Options',
    contentText:
      'How to access federal aid, grants, work study, and scholarships to close the college funding gap',
  },
  {
    href: cspConsiderLoansLink,
    icon: TimeMonthly,
    headerText: 'When to Consider Student Loans',
    contentText:
      'The pros and cons of taking out student loans and how to decide what works for you',
  },
  {
    href: cspFederalVsPrivateLink,
    icon: SavingBankCash,
    headerText: 'Federal v. Private Student Loans',
    contentText:
      'Determining what type of loan is the best fit for your situation and how you can prepare to navigate repayment',
  },
];
