import { object, string, number, boolean } from 'yup';

import { phoneNumberSchema, stateSchema } from 'schemas/shared';
import {
  newPasswordFieldValidation,
  passwordFieldValidation,
} from 'schemas/user';

export const createAccountValidationSchema = () =>
  object().shape({
    email: string()
      .validEmail('Please enter a valid email')
      .required('Please enter email'),
    password: newPasswordFieldValidation(),
    firstName: string().required('Please enter first name'),
    lastName: string().required('Please enter last name'),
  });

export const createAccountValidationSchema2 = partnerConfig =>
  object().shape({
    email: string()
      .validEmail('Please enter a valid email')
      .required('Please enter email'),
    password: newPasswordFieldValidation(),
    firstName: string().required('Please enter first name'),
    lastName: string().required('Please enter last name'),
    ...(partnerConfig.usePartnerCode &&
      !partnerConfig.partnerCodeOptional && {
        partnerCode: string().required(
          `Please enter a valid access code to sign up as a ${
            partnerConfig.isPremium ? 'Premium' : 'priority'
          } Summer partner`,
        ),
      }),
    ...(partnerConfig.signupValidation && {
      ...partnerConfig.signupValidation,
    }),
  });

export const partialDataCreateAccountValidationSchema = () =>
  object().shape({
    email: string()
      .min(1)
      .validEmail('Please enter a valid email')
      .required('Please enter email'),
    firstName: string().min(1).required('Please enter your first name'),
    lastName: string().min(1).required('Please enter your last name'),
  });

export const loansInfoValidationSchema = object().shape({
  federalLoansEstimate: number()
    .integer('Please enter a number (without decimals)')
    .max(Number.MAX_SAFE_INTEGER, 'Please enter a lower number')
    .required('Please enter an amount')
    .nullable(),
  loansInDefaultFederal: string()
    .nullable()
    .when('federalLoansEstimate', {
      is: val => val > 0,
      then: string().required('Please select an answer').nullable(),
    }),
  isCurrentlyEnrolledInIDR: string()
    .nullable()
    .when('federalLoansEstimate', {
      is: val => val > 0,
      then: string().required('Please select an answer').nullable(),
    }),
  privateLoansEstimate: number()
    .integer('Please enter a number (without decimals)')
    .max(Number.MAX_SAFE_INTEGER, 'Please enter a lower number')
    .required('Please enter an amount')
    .nullable(),
  loansInDefaultPrivate: string()
    .nullable()
    .when('privateLoansEstimate', {
      is: val => val > 0,
      then: string().required('Please select an answer').nullable(),
    }),
});

export const surveyValidationSchema = object().shape({
  gradStatus: string().required('Please select an answer').nullable(),
  troubleWithPayments: string().required('Please select an answer').nullable(),
  salary: number('Please enter a number (without decimals)')
    .integer('Please enter a number (without decimals)')
    .max(Number.MAX_SAFE_INTEGER, 'Please enter a lower number')
    .min(0, 'Please enter an amount no less than zero')
    .required('Please enter your expected household annual income')
    .nullable(),
  familyCount: number()
    .integer('Please enter a number (without decimals)')
    .min(1, 'Please include yourself')
    .max(100, 'Please enter a lower number')
    .required('Please enter the size of your family')
    .nullable(),
  state: stateSchema,
  isPublicSector: string().required('Please select an answer').nullable(),
  currentEmployer: string().nullable(),
  phone: phoneNumberSchema.nullable().when('hasOptedIntoPhoneContact', {
    is: true,
    then: string().required('Please enter your phone number').nullable(),
  }),
  hasOptedIntoPhoneContact: boolean().nullable(),
  creditScore: string().required('Please select an answer').nullable(),
});

export const loginValidationSchema = object().shape({
  email: string()
    .validEmail('Please enter a valid email')
    .required('Please enter email'),
  password: passwordFieldValidation('Please enter password'),
});

export const forgotPasswordValidationSchema = object().shape({
  email: string()
    .validEmail('Please enter a valid email')
    .required('Please enter email'),
});

export const resetPasswordValidationSchema = object().shape({
  password: newPasswordFieldValidation(),
});

export const verifyAccountValidationSchema = object().shape({
  email: string()
    .validEmail('Please enter a valid email')
    .required('Please enter a valid email'),
});

const createSmsFactorSchemaMessage =
  'Please enter an active, 10-digit cell phone number';
export const createSmsFactorSchema = object().shape({
  phoneNumber: string()
    .min(10, createSmsFactorSchemaMessage)
    .required(createSmsFactorSchemaMessage),
});

const verifySmsFactorSchemaMessage =
  'Please enter the 6-digit verification code';
export const verifySmsFactorSchema = object().shape({
  code: string()
    .min(6, verifySmsFactorSchemaMessage)
    .required(verifySmsFactorSchemaMessage),
});
