import React from 'react';
import styled from 'styled-components';

import {
  FlexRow,
  Text,
  Icon,
  CheckSmall,
  Caution,
  COLORS,
} from '@summer/ui-components';

const EligibilityCell = ({ value }) => {
  const { pslfEligible, shouldConsolidate } = value || {};

  if (shouldConsolidate === true) {
    return (
      <ConsolidationCell>
        <StyledCautionIcon
          width={16}
          SvgComponent={Caution}
          fill={COLORS.gold}
        />
        &nbsp;
        <Text as="span" paragraph weight="medium" smallTracking truncate>
          Consolidate
        </Text>
      </ConsolidationCell>
    );
  }

  if (pslfEligible === true) {
    return (
      <>
        <StyledIcon width={14} SvgComponent={CheckSmall} stroke={COLORS.jade} />
        &nbsp;
        <Text as="span" paragraph weight="medium" smallTracking>
          Eligible
        </Text>
      </>
    );
  }
  if (pslfEligible === false) {
    return (
      <Text paragraph weight="medium" smallTracking>
        Ineligible
      </Text>
    );
  }
  return (
    <Text paragraph weight="medium" smallTracking>
      Unknown
    </Text>
  );
};

const ConsolidationCell = styled(FlexRow)`
  justify-content: flex-end;
`;

const StyledCautionIcon = styled(Icon)`
  display: inline;
  position: relative;
  top: 2px;
`;

const StyledIcon = styled(Icon)`
  display: inline;
`;

export default EligibilityCell;
