/* eslint-disable react/display-name */
import React from 'react';
import styled from 'styled-components';

import { Text, COLORS } from '@summer/ui-components';

import Link from 'components/shared/typography/Link';
import { useWizard } from 'hooks/Wizard/useWizard';

const renderWizardSteps = steps => {
  return steps.map(step => step.render());
};

const NestedWizardDemo = ({
  navigation: parentNavigation,
  progress: parentProgress,
}) => {
  const config = React.useMemo(
    () => [
      {
        title: 'title -> Step1 nested',
        render: props => (
          <StepComponent {...props} color={COLORS.azure}>
            <Text>step1 nested</Text>
          </StepComponent>
        ),
      },
      {
        title: 'title -> Step2 nested',
        render: props => (
          <StepComponent {...props} color={COLORS.orange}>
            <Text>step2 nested</Text>
          </StepComponent>
        ),
      },
      {
        title: 'title -> Step3 nested',
        render: props => (
          <StepComponent {...props} color={COLORS.yellow}>
            <Text>step3 nested</Text>
          </StepComponent>
        ),
      },
      {
        title: 'title -> Step4',
        render: ({ navigation, key }) => (
          <StepComponent key={key} color={COLORS.orange}>
            {navigation.isLastStep ? (
              <Text>This is the Last Step!!</Text>
            ) : (
              <Text>step4 nested</Text>
            )}
          </StepComponent>
        ),
      },
    ],
    [],
  );

  const { steps, navigation } = useWizard({
    config,
    parentNavigation,
    parentProgress,
  });

  return (
    <Steps>
      <StepContainer>{renderWizardSteps(steps)}</StepContainer>
      <Link onClick={navigation.back}>Back</Link>
      <Link onClick={navigation.next}>Next</Link>
    </Steps>
  );
};

const StepContainer = styled.div`
  flex: 1 1 auto;
  height: 100%;
  padding: 10px;
  text-align: center;
`;
const StepComponent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ color }) => color};
`;
const Steps = styled.div`
  flex: 1 0 auto;
  width: 100%;
  height: 100%;
`;

export default NestedWizardDemo;
