import React from 'react';
import styled from 'styled-components';

import { Accordion } from '@summer/ui-components';

import { connectFsaAccordionConfig } from 'components/common/SyncLoans/ConnectFsa/ConnectFsaBody/ConnectFsaAccordion/config';
import Platform, { MOBILE } from 'components/hoc/Platform';
import { MobileSize } from 'constants/styleguide';

const ConnectFsaAccordion = ({ platformType, drawersToOpenByDefault }) => {
  const isMobile = platformType === MOBILE;

  return (
    <Container>
      <Accordion
        config={connectFsaAccordionConfig(isMobile, drawersToOpenByDefault)}
      />
    </Container>
  );
};

const Container = styled.div`
  max-width: 512px;
  padding: 24px 0px;

  @media (max-width: ${MobileSize}) {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 24px;
    margin-left: 0px;
    padding: 0;
  }
`;

export default Platform(ConnectFsaAccordion);
