import React from 'react';
import styled from 'styled-components';

import { Text, Alert, BooleanIcon } from '@summer/ui-components';

const LoanEligibilityAlert = ({ isPslfEligible }) => {
  const theme = isPslfEligible ? 'success' : 'neutral';
  let text;

  if (isPslfEligible === true) {
    text =
      'It looks like some of your repayments are eligible during this period. We recommend continuing – we’ll help you submit this employment certification.';
  } else if (isPslfEligible === false) {
    text =
      'Unfortunately the loan type or the repayment plan of this loan is not eligible for PSLF. However, we can still submit your certification if at least one of your other loans are eligible.';
  } else {
    text =
      'Due to missing information, we’re not sure if your repayments during this period were eligible. Don’t worry, we can still submit this certification and MOHELA Servicing will confirm.';
  }

  return (
    <Alert
      theme={theme}
      dismissable={false}
      showIcon
      customIcon={
        <IconContainer>
          <BooleanIcon value={isPslfEligible} size="small" />
        </IconContainer>
      }
    >
      <Text small paragraph>
        {text}
      </Text>
    </Alert>
  );
};

const IconContainer = styled.div`
  margin-right: 12px;
`;

export default LoanEligibilityAlert;
