import {
  ADD_EMERGENCY_SAVINGS_PAYROLL_PROVIDER,
  FETCH_EMERGENCY_SAVINGS_V1,
  POST_EMERGENCY_SAVINGS_V1,
  SET_EMERGENCY_SAVINGS,
} from 'constants/actionTypes';

export const setEmergencySavings = payload => ({
  type: SET_EMERGENCY_SAVINGS,
  payload,
});

export const postEmergencySavingsV1 = (payload, { onSuccess, onFailure }) => ({
  type: POST_EMERGENCY_SAVINGS_V1,
  payload,
  meta: { onSuccess, onFailure },
});

export const addEmergencySavingsPayrollProvider = payload => ({
  type: ADD_EMERGENCY_SAVINGS_PAYROLL_PROVIDER,
  payload,
});

export const fetchEmergencySavingsV1 = () => ({
  type: FETCH_EMERGENCY_SAVINGS_V1,
  payload: {},
});
