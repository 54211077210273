import React, { PureComponent } from 'react';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { FlexColumn } from '../../Flex';

const animationLength = 500; // milliseconds

class Collapse extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      animating: false,
      clearAnimate: null,
      contentHeight: 0,
      className: '',
    };
    this.collapseContentRef = React.createRef();
  }

  componentDidMount() {
    // On intial load the className is empty, preventing the collapse-open animation.
    // If controlled and not shown, set the className to collapse-close.
    // If not controlled and openByDefault, set the show state to true or else set the className to collapse-close.
    if (this.props.ejectControl) {
      if (!this.props.show && !this.props.openByDefault) {
        this.setState({
          className: 'collapse-close',
        });
      }
    } else if (this.props.openByDefault) {
      this.setState({ show: true });
    } else {
      this.setState({
        className: 'collapse-close',
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.clearAnimate);
  }

  componentDidUpdate(prevProps) {
    if (this.props.ejectControl) {
      if (this.props.show !== prevProps.show) {
        this.handleCollapse();
      }
    }
  }

  handleCollapse = () => {
    this.setState(
      prevState => ({
        contentHeight: this.collapseContentRef.current.scrollHeight,
        show: !prevState.show,
        animating: true,
        className: this.getClassName(prevState),
      }),
      () => {
        // this lets us track if the open/close animation is in progress
        // so that the overflow property can be set only after it is complete
        clearTimeout(this.state.clearAnimate);
        this.setState({
          clearAnimate: setTimeout(
            () => this.setState({ animating: false }),
            animationLength,
          ),
        });
      },
    );
  };

  onClick = this.props.ejectControl
    ? () => {
        this.props.onClick();
        this.handleCollapse();
      }
    : this.handleCollapse;

  getClassName = prevState => {
    // When the collapse is clicked, we need to get the correct className and set it here rather than
    // just using getShow in order to account for the on load scenario where the className is empty
    const show = this.props.ejectControl ? this.props.show : !prevState.show;
    const className = show ? 'collapse-open' : 'collapse-close';
    return className;
  };

  getShow = () => (this.props.ejectControl ? this.props.show : this.state.show);

  render() {
    const CollapseTrigger = this.props.trigger;
    const { onMouseDown, reverse } = this.props;

    const { onClick } = this;
    const show = this.getShow();
    const { animating } = this.state;

    return (
      <FlexColumn reverse={reverse}>
        <div
          onClick={onClick}
          onMouseDown={onMouseDown}
          className={show ? 'trigger-open' : 'trigger-close'}
        >
          <CollapseTrigger openState={show} />
        </div>
        <CollapseContent
          className={this.state.className}
          openState={show}
          contentHeight={this.state.contentHeight}
          ref={this.collapseContentRef}
          style={{ overflow: show && !animating ? 'visible' : 'hidden' }}
        >
          {this.props.children}
        </CollapseContent>
      </FlexColumn>
    );
  }
}

const openCollapse = contentHeight => keyframes`
  from {
   height:0;
  }
  to {
    height:${contentHeight}px;
  }
`;

const closeCollapse = contentHeight => keyframes`
  from {
   height:${contentHeight}px;
  }
  to {
    height:0;
  }
`;

const CollapseContent = styled.div`
  height: auto;
  &.collapse-open {
    ${({ contentHeight }) =>
      css`
        animation: ${openCollapse(contentHeight)} ${animationLength}ms ease;
      `};
  }
  &.collapse-close {
    ${({ contentHeight }) =>
      css`
        animation: ${closeCollapse(contentHeight)} ${animationLength}ms ease
          forwards;
      `};
  }
`;

Collapse.propTypes = {
  trigger: PropTypes.func.isRequired,
  openByDefault: PropTypes.bool,
  reverse: PropTypes.bool,
  show: PropTypes.bool,
  ejectControl: PropTypes.bool,
};

Collapse.defaultProps = {
  openByDefault: false,
  reverse: false,
};

export default Collapse;
