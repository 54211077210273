import styled from 'styled-components';

export { default as Link } from 'components/shared/typography/Link';

export const Strong = styled.strong`
  font-weight: 600;
  font-size: inherit;
`;

Strong.displayName = 'Strong';
