import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Text, Strong, SelectInput } from '@summer/ui-components';

import StaticField from 'components/common/ManageLoans/fields/shared/StaticField';
import InfoTooltip from 'components/shared/InfoTooltip';
import { loanTypeTooltipText } from 'constants/manageLoans';
import {
  getFederalLoanTypesOptions,
  getCurrentLoan,
  getLoanTypeNameById,
  isLoanFieldEditable,
} from 'redux/selectors/loans.selectors';

const FederalLoanTypeLabel = () => (
  <>
    <span>Loan Type</span>
    <InfoTooltip text={loanTypeTooltipText} top={2} />
  </>
);

const FederalLoanType = ({
  formik: { values, errors, setFieldValue, handleBlur, touched, isSubmitting },
  loan,
  loanTypesOptions,
  canEdit,
  selectedLoanTypeName,
  helperText,
}) => {
  const federalLoanTypeRaw = loan && loan.federalLoanTypeRaw;

  const showLoanTypeMessage = federalLoanTypeRaw && canEdit;

  return (
    <Container id="federalLoanTypeId">
      <SelectContainer>
        {canEdit ? (
          <SelectInput
            name="federalLoanTypeId"
            value={values.federalLoanTypeId}
            error={errors.federalLoanTypeId}
            touched={touched.federalLoanTypeId}
            options={loanTypesOptions}
            isSubmitting={isSubmitting}
            onChange={setFieldValue}
            onBlur={handleBlur}
            placeholder=""
            label={<FederalLoanTypeLabel />}
            subTitle={
              showLoanTypeMessage && (
                <Info>
                  <Text small left>
                    Your loan type was listed as:{' '}
                    <Strong>{federalLoanTypeRaw}</Strong>
                  </Text>
                </Info>
              )
            }
          />
        ) : (
          <StaticField
            label={<FederalLoanTypeLabel />}
            value={selectedLoanTypeName}
          />
        )}
        {!errors.federalLoanTypeId && (
          <Text small style={{ marginTop: '-20px' }}>
            {helperText}
          </Text>
        )}
      </SelectContainer>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  width: 100%;
`;

const SelectContainer = styled.div`
  width: 100%;
`;

const Info = styled.div`
  padding: 5px;
`;

const mapStateToProps = (state, props) => {
  const loanId = props.formik.values.id;
  const loanTypeId = props.formik.values.federalLoanTypeId;

  return {
    loan: getCurrentLoan(state),
    canEdit: isLoanFieldEditable(state, loanId, 'federalLoanTypeId'),
    loanTypesOptions: getFederalLoanTypesOptions(state),
    selectedLoanTypeName: getLoanTypeNameById(state, loanTypeId),
  };
};

export default connect(mapStateToProps)(FederalLoanType);
