import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {
  FlexColumn,
  FlexCenter,
  LoadingBars,
  COLORS,
} from '@summer/ui-components';

import BrowserModal from 'components/common/BrowserModal';
import ToastList from 'components/common/toast/ToastList';
import Sidebar from 'components/layout/dashboard/Sidebar';
import GlobalAlert from 'components/shared/GlobalAlert';
import MobileContentHeader from 'components/shared/dashboard/MobileContentHeader';
import { CollapseSidebarSize, SidebarWidth } from 'constants/styleguide';
import { getCurrentStep } from 'redux/actions/user.actions';
import { getOnboardingCurrentStep } from 'redux/selectors/onboarding.selectors';
import { isLoading, isSidebarVisible } from 'redux/selectors/ui.selectors';

class Dashboard extends React.Component {
  componentDidMount() {
    if (!this.props.currentStep) {
      this.props.getCurrentStep();
    }
  }

  render() {
    const {
      fetchCurrentStepLoading,
      sideBarVisible,
      children,
      mobileHeaderTitle,
    } = this.props;

    return fetchCurrentStepLoading ? (
      <LoadingContainer>
        <LoadingBars color={COLORS.azure} />
      </LoadingContainer>
    ) : (
      <DashboardContainer>
        <Sidebar />
        <Content className={sideBarVisible ? 'sideBarVisible' : ''}>
          <MobileContentHeader>{mobileHeaderTitle}</MobileContentHeader>
          <GlobalAlert />
          {children}
        </Content>
        <ToastList leftOffset={SidebarWidth} />
        <BrowserModal />
      </DashboardContainer>
    );
  }
}

const LoadingContainer = styled(FlexCenter)`
  height: 100%;
`;

const DashboardContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  overflow: hidden;
`;

const Content = styled(FlexColumn)`
  flex: 1;
  position: relative; /* allows hovering modal to be sized correctly */
  left: 0;
  height: 100%;
  overflow: hidden;
  background-color: ${COLORS.lightestGrey};

  @media (max-width: ${CollapseSidebarSize}) {
    &.sideBarVisible {
      left: ${SidebarWidth};
    }

    transition: left 0.3s ease-out;
  }
`;

const mapStatetoProps = state => ({
  currentStep: getOnboardingCurrentStep(state),
  fetchCurrentStepLoading: isLoading(state, 'fetchCurrentStep'),
  sideBarVisible: isSidebarVisible(state),
});

export default withRouter(
  connect(mapStatetoProps, { getCurrentStep })(Dashboard),
);
