import React from 'react';

import { alias, track, segmentIdentify } from 'utils/track';

const Track = WrappedComponent => {
  return class TrackWrapper extends React.Component {
    render() {
      return (
        <WrappedComponent
          {...this.props}
          segmentIdentify={segmentIdentify}
          track={track}
          alias={alias}
        />
      );
    }
  };
};

export default Track;
