import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  Text,
  Header,
  FlexRow,
  Button,
  Document,
  SvgImage,
  Student,
} from '@summer/ui-components';

import ContentHeader from 'components/shared/dashboard/ContentHeader';
import { MobileSize, TabletSize } from 'constants/styleguide';
import { setUiSetting } from 'redux/actions/user.actions';

const UserStillInSchool = () => {
  const dispatch = useDispatch();
  const setUserHasPreviousLoans = () =>
    dispatch(setUiSetting('userHasPreviousLoans', 'true'));

  return (
    <>
      <ContentHeader noIconOnMobile SvgComponent={Document}>
        Income-Driven Repayment (IDR)
      </ContentHeader>
      <Container>
        <SvgImage SvgComponent={Student} width={241} height={204} />
        <Content>
          <Header h3>Looks like you’re still in school.</Header>
          <Box>
            <Text paragraph>
              Because you’re still in school, you cannot yet apply for federal
              repayment programs. However, you can still check out your options
              now and we&rsquo;ll get you enrolled in the right plan before you
              enter repayment.
            </Text>
            <br />
            <Text paragraph>
              You can also enroll now if you have degrees from a previous degree
              or institution.
            </Text>
          </Box>
          <Box2>
            <Button onClick={setUserHasPreviousLoans} width={248}>
              See My Repayment Options
            </Button>
          </Box2>
        </Content>
      </Container>
    </>
  );
};

const Container = styled(FlexRow)`
  padding-top: 48px;

  @media (max-width: ${TabletSize}) {
    flex-direction: column;
    padding-top: 0;
  }

  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

const Box = styled.div`
  padding: 16px 0;
`;

const Box2 = styled.div`
  padding: 8px 0 24px;
  @media (max-width: ${TabletSize}) {
    text-align: center;
  }
`;

const Content = styled.div`
  width: 440px;
  margin-left: 40px;
  padding-top: 8px;

  @media (max-width: ${TabletSize}) {
    margin-left: 0;
    padding-top: 24px;
  }

  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

export default UserStillInSchool;
