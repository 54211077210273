import React from 'react';
import { connect } from 'react-redux';

import {
  AmountBorrowed,
  DisbursementDate,
  LoanPayoffDate,
  EducationLevel,
  IncludesParentPlus,
  InterestRate,
  FederalLoanType,
  OutstandingInterest,
  PrincipalBalance,
  RecertificationDate,
  RepaymentPlanType,
  Servicer,
} from 'components/common/ManageLoans/fields';
import Section from 'components/common/ManageLoans/shared/Section';
import {
  getFederalLoanTypes,
  getRepaymentPlanTypes,
} from 'redux/selectors/loans.selectors';
import {
  showIncludesParentPlus,
  showLoanPayoffDate,
  loanIsActive,
  showRecertificationDate,
} from 'utils/loans';

const LoansFormFieldsIdr = ({
  formik,
  formik: {
    values: {
      disbursementDate,
      federalLoanTypeId,
      includesParentPlus,
      principalBalance,
      repaymentPlanTypeId,
    },
  },
  servicerName,
  federalLoanTypes,
  repaymentPlanTypes,
}) => {
  const loanIsNotPaidOff = loanIsActive(principalBalance);
  return (
    <>
      <Section>
        {loanIsNotPaidOff && (
          <Servicer formik={formik} servicerName={servicerName} />
        )}
        <FederalLoanType formik={formik} />
        {loanIsNotPaidOff ? null : <div />}
      </Section>

      {showIncludesParentPlus(federalLoanTypeId, federalLoanTypes) && (
        <IncludesParentPlus formik={formik} />
      )}

      <Section>
        {loanIsNotPaidOff && <EducationLevel formik={formik} />}
        <DisbursementDate formik={formik} />
        {loanIsNotPaidOff ? null : <div />}
      </Section>

      <Section>
        <PrincipalBalance formik={formik} />
        {loanIsNotPaidOff ? <AmountBorrowed formik={formik} /> : <div />}
      </Section>

      <Section>
        {loanIsNotPaidOff && <OutstandingInterest formik={formik} />}
        {loanIsNotPaidOff && <InterestRate formik={formik} />}
      </Section>

      {showLoanPayoffDate(
        disbursementDate,
        federalLoanTypeId,
        includesParentPlus,
        principalBalance,
        federalLoanTypes,
      ) && <LoanPayoffDate formik={formik} />}

      <Section>
        {loanIsNotPaidOff && <RepaymentPlanType formik={formik} />}
        {showRecertificationDate(
          repaymentPlanTypeId,
          principalBalance,
          repaymentPlanTypes,
        ) ? (
          <RecertificationDate formik={formik} />
        ) : (
          <div />
        )}
      </Section>
    </>
  );
};

const mapStateToProps = state => ({
  federalLoanTypes: getFederalLoanTypes(state),
  repaymentPlanTypes: getRepaymentPlanTypes(state),
});

export default connect(mapStateToProps)(LoansFormFieldsIdr);
