import { random } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Header,
  LoadingBars,
  Icon,
  BackgroundSvg,
  Button,
  Text,
  FlexRow,
  COLORS,
} from '@summer/ui-components';

import ServicersLoansTables from 'components/common/ViewLoans/ServicersLoansTables';
import {
  xPaddingMobile,
  xPaddingDesktop,
  topPaddingDesktop,
  topPaddingMobile,
} from 'components/layout/wizard/WizardContent';
import Link from 'components/shared/typography/Link';
import { TabletSize, MobileSize } from 'constants/styleguide';
import { fetchRecommendations } from 'redux/actions/recEngine.actions';
import { getGetOutOfDefaultRecommendation } from 'redux/selectors/recEngine.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { openChat } from 'services/chat';
import { hexToRgba } from 'utils/common';
import { getNormalizedFsaLoans, findActiveServicers } from 'utils/loans';

const columnsToShow = [
  'loanType',
  'status',
  'repaymentPlan',
  'interestRate',
  'currentBalance',
];

const LoansPage = ({
  normalizedFsaLoans,
  isLoading,
  getOutOfDefaultRecommended,
  dispatch,
  navigation,
}) => {
  useEffect(
    function hitRecEngine() {
      // The soft "get out of default" recommendation determines
      // if any of a user's synced FSA loans are in default
      dispatch(fetchRecommendations());
    },
    [dispatch],
  );

  const allActiveServicers = findActiveServicers(normalizedFsaLoans);

  const hasLoansInDefault = getOutOfDefaultRecommended;

  const backgroundRotateDeg = useRef(random(90, 180));

  return isLoading ? (
    <LoadingBars />
  ) : (
    <>
      <HeaderContainer>
        <BackgroundShape
          fill={hexToRgba(COLORS.jade, 0.16)}
          rotate={backgroundRotateDeg?.current}
          SvgComponent={BackgroundSvg}
        />
        <HeaderContent>
          {hasLoansInDefault ? (
            <>
              <div>
                <Text small paragraph bold>
                  Sync complete!
                </Text>
                <Text small paragraph>
                  We&rsquo;ll use this info to recommend a next step for the
                  loans that are in default.
                </Text>
              </div>
              <Button width={165} onClick={navigation.next}>
                Continue
              </Button>
            </>
          ) : (
            <>
              <div>
                <Text small paragraph bold>
                  Looks like you don&rsquo;t have loans in default —
                  that&rsquo;s great!
                </Text>
                <Text small paragraph>
                  There&rsquo;s no need to continue. If this seems wrong,{' '}
                  <Link inheritColor underline onClick={openChat}>
                    send us a message
                  </Link>
                  .
                </Text>
              </div>
              <Link to="/plan">
                <Button width={165}>Return to My Plan</Button>
              </Link>
            </>
          )}
        </HeaderContent>
      </HeaderContainer>
      <div>
        <TableHeader>
          <Header h2>Federal Loans</Header>
        </TableHeader>
        <ServicersLoansTables
          normalizedLoans={normalizedFsaLoans}
          allServicers={allActiveServicers}
          servicersToDisplay={allActiveServicers}
          active={true}
          columnsToShow={columnsToShow}
        />
      </div>
    </>
  );
};

const HeaderContainer = styled.div`
  position: relative;
  background-color: ${hexToRgba(COLORS.jade, 0.12)};
  width: calc(100% + ${parseInt(xPaddingDesktop, 10) * 2}px);
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;

  padding: 24px 48px;
  margin-top: -${topPaddingDesktop};
  margin-left: -${xPaddingDesktop};
  margin-right: -${xPaddingDesktop};
  margin-bottom: 24px;

  @media (max-width: ${TabletSize}) {
    padding: 24px;
    width: calc(100% + ${parseInt(xPaddingMobile, 10) * 2}px);
    margin-top: -${topPaddingMobile};
    margin-left: -${xPaddingMobile};
    margin-right: -${xPaddingMobile};
  }
`;

const BackgroundShape = styled(Icon)`
  position: absolute;
  top: 0;
  left: 0;
  transform: ${({ rotate }) => `rotate(${rotate}deg)`} scale(30);
  z-index: -1;

  @media (max-width: ${TabletSize}) {
    transform: ${({ rotate }) => `rotate(${rotate}deg)`} scale(24);
  }
`;

const HeaderContent = styled(FlexRow)`
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;

    button {
      margin-top: 16px;
    }

    a {
      width: 100%;
    }
  }
`;

const TableHeader = styled.div`
  margin-bottom: 24px;
`;

const mapStateToProps = state => ({
  normalizedFsaLoans: getNormalizedFsaLoans(state.loans),
  isLoading:
    isLoading(state, 'fetchAllLoans') ||
    isLoading(state, 'fetchRecommendations'),
  getOutOfDefaultRecommended: getGetOutOfDefaultRecommendation(state),
});

export default connect(mapStateToProps)(LoansPage);
