import { push as redirectTo } from 'connected-react-router';
import { format, parseISO } from 'date-fns';
import { map, slice } from 'lodash';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  Button,
  Header,
  Text,
  FancyCard,
  FlexColumn,
  FlexRow,
  COLORS,
} from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import { Link } from 'components/shared/typography';
import { MobileSize } from 'constants/styleguide';
import { fetchPslfForms } from 'redux/actions/pslf.actions';
import { getUserPslfForms } from 'redux/selectors/pslf.selectors';
import {
  getAmountForgiven,
  getEstimatedForgivenessDate,
} from 'redux/selectors/recEngine.selectors';

const pendingStateMap = {
  completed: 'Waiting for employer signature',
  signed: 'In progress',
  processed: 'Complete',
};

const getHeaderBackgroundColor = (current, pending) => {
  if (current) {
    return 'solid';
  }
  if (pending) {
    return 'stripes';
  }
  return 'none';
};

const Certifications = ({ pslfForms }) => (
  <CertificationsContentContainer>
    <Text size="small" color="secondary">
      Recent certifications:
    </Text>
    <CertificationsList>
      {map(slice(pslfForms, 0, 2), (form, idx) => {
        const { employerName, employerStartDate, employerEndDate, status } =
          form;
        return (
          <CerificationContainer key={idx}>
            <EmployerNameAndDate>
              <Text bold>{employerName}</Text>
              <Text size="small">
                {employerStartDate &&
                  `${format(parseISO(employerStartDate), 'MMM yyyy')}${
                    employerEndDate
                      ? ` — ${format(parseISO(employerEndDate), 'MMM yyyy')}`
                      : ' — Present'
                  }`}
              </Text>
            </EmployerNameAndDate>
            <Text size="small" color="secondary" italic>
              {pendingStateMap[status]}
            </Text>
          </CerificationContainer>
        );
      })}
    </CertificationsList>
    <CTAContainer>
      <Link to="/pslf">
        {pslfForms.length > 2 ? 'View All' : 'More Details'}
      </Link>
    </CTAContainer>
  </CertificationsContentContainer>
);

const PslfPendingOrCurrentContent = ({
  current,
  estimatedForgivenessDate,
  pslfForms,
}) => {
  return (
    <>
      {current && estimatedForgivenessDate ? (
        <>
          <Text size="small" color="secondary">
            Estimated forgiveness date:
          </Text>
          <DateAndTrackLinkContainer>
            <Text bold size="large">
              {format(parseISO(estimatedForgivenessDate), 'MMM dd, yyyy')}
            </Text>
            <Link to="/pslf/tracker">Track</Link>
          </DateAndTrackLinkContainer>
          <Divider />
        </>
      ) : null}
      <Certifications pslfForms={pslfForms} />
    </>
  );
};

const PslfRecCardContent = () => {
  const dispatch = useDispatch();
  const handleCTA = React.useCallback(() => {
    dispatch(redirectTo('/pslf'));
  }, [dispatch]);

  return (
    <CardContentContainer>
      <ContentContainer>
        <Header h2>Certify for Public Service Loan Forgiveness (PSLF)</Header>
        <Text paragraph weight="medium" size="medium">
          You may qualify for Public Service Loan Forgiveness (PSLF), a program
          that will forgive ALL of your remaining loan balance after ten years.
          In order to enroll in PSLF, you must also be enrolled in an IDR
          (income-driven repayment) plan, which we’ve also recommended for you.
        </Text>
      </ContentContainer>
      <CTAContainer>
        <Button onClick={handleCTA} width={144}>
          Get Started
        </Button>
      </CTAContainer>
    </CardContentContainer>
  );
};

const PslfRec = ({
  current,
  pending,
  pslfForms,
  totalProjectedForgiveness,
  estimatedForgivenessDate,
  platformType,
}) => {
  const isMobile = platformType === MOBILE;
  const dispatch = useDispatch();
  const isCurrentOrPending = current || pending;
  const headerDirection = isMobile && !isCurrentOrPending ? 'column' : 'row';

  useEffect(() => {
    dispatch(fetchPslfForms());
  }, [dispatch]);

  const getHeaderInfo = React.useCallback(
    () =>
      (pending || current) && totalProjectedForgiveness
        ? [
            {
              title: 'Total projected forgiveness:',
              value: totalProjectedForgiveness,
              valueColor:
                pending && !current ? COLORS.azure : COLORS.darkerGrey,
            },
          ]
        : [],
    [current, pending, totalProjectedForgiveness],
  );

  return (
    <FancyCard
      title={
        isCurrentOrPending
          ? 'PUBLIC SERVICE LOAN FORGIVENESS'
          : 'LOAN FORGIVENESS'
      }
      shortTitle={isCurrentOrPending ? 'PSLF' : ''}
      color="gold"
      backgroundStyle={getHeaderBackgroundColor(current, pending)}
      // TODO [ch13163]: Add projected forgiveness value after Python calcs are available
      headerInfo={getHeaderInfo()}
      testId="rec-card-pslf"
      headerDirection={headerDirection}
    >
      {isCurrentOrPending ? (
        <PslfPendingOrCurrentContent
          current={current}
          pslfForms={pslfForms}
          estimatedForgivenessDate={estimatedForgivenessDate}
        />
      ) : (
        <PslfRecCardContent />
      )}
    </FancyCard>
  );
};

const CardContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: inherit;
  align-items: center;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;

    > * {
      margin-bottom: 16px;
      :last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const DateAndTrackLinkContainer = styled(FlexRow)`
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 16px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border: solid 1px ${COLORS.lighterGrey};
  margin-bottom: 16px;
`;

const ContentContainer = styled.div`
  margin-right: 8px;
  max-width: 80%;

  > * {
    margin-bottom: 8px;
  }

  :last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${MobileSize}) {
    max-width: 100%;
  }
`;

const CertificationsContentContainer = styled(FlexColumn)`
  justify-content: space-between;
  min-height: inherit;
  align-items: left;
`;

const CertificationsList = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 0 16px;

  & > * :not(:first-child) {
    margin-left: 16px;
  }

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
    & > * :not(:first-child) {
      margin-top: 8px;
      margin-left: 0;
    }
  }
`;

const CerificationContainer = styled(FlexRow)`
  & > * :last-child {
    flex: 1;
    text-align: right;
  }
  justify-content: space-between;
  width: 100%;
  background-color: ${COLORS.lightestGrey};
  padding: 12px 16px;
  @media (max-width: ${MobileSize}) {
    flex-direction: column;
    & > * :last-child {
      text-align: left;
    }
  }
`;

const EmployerNameAndDate = styled(FlexColumn)`
  flex: 2;
  & > * :first-child {
    margin-bottom: 4px;
  }
  @media (max-width: ${MobileSize}) {
    margin-bottom: 4px;
  }
`;

const CTAContainer = styled.div`
  text-align: right;
  align-self: flex-end;
  width: 144px;

  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

const mapStateToProps = state => ({
  pslfForms: getUserPslfForms(state),
  totalProjectedForgiveness: getAmountForgiven(state),
  estimatedForgivenessDate: getEstimatedForgivenessDate(state),
});

export default connect(mapStateToProps)(Platform(PslfRec));
