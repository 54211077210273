import React from 'react';
import styled from 'styled-components';

import { FlexRow, Text, RadioButtons, DateInput } from '@summer/ui-components';

import { MobileSize } from 'constants/styleguide';
import { useClearFieldOnUnmount } from 'hooks/formik';
import { initialState } from 'redux/reducers/IDR/submit.reducer';

const RadioQuestion = ({
  formik: { touched, isSubmitting, errors, values, handleChange, handleBlur },
  options,
  name,
  label,
}) => {
  return (
    <>
      <Label id={name}>
        <Text large bold>
          {label}
        </Text>
      </Label>
      <InputContainer>
        <RadioButtons
          name={`submit.${name}`}
          touched={touched[name]}
          isSubmitting={isSubmitting}
          error={errors[name]}
          value={values[name]}
          onChange={handleChange}
          onBlur={handleBlur}
          options={options}
          numberOfCol={options.length}
        />
      </InputContainer>
    </>
  );
};

const CurrentlyInGracePeriod = ({ formik, formik: { setFieldValue } }) => {
  const name = 'inGracePeriod';
  const label = 'Are you currently in your grace period?';

  useClearFieldOnUnmount(`submit.${name}`, initialState[name], setFieldValue);

  const options = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
  ];

  return (
    <RadioQuestion
      formik={formik}
      options={options}
      name={name}
      label={label}
    />
  );
};

const RemainInGracePeriod = ({ formik, formik: { setFieldValue } }) => {
  const name = 'remainInGracePeriod';
  const label =
    'Do you want to start the plan immediately or remain in your grace period?';

  useClearFieldOnUnmount(`submit.${name}`, initialState[name], setFieldValue);

  const options = [
    { value: 'false', label: 'Start Now' },
    { value: 'true', label: 'Delay Start' },
  ];

  return (
    <RadioQuestion
      formik={formik}
      options={options}
      name={name}
      label={label}
    />
  );
};

const EndDate = ({
  formik: { touched, isSubmitting, setFieldValue, errors, values, handleBlur },
}) => {
  const name = 'gracePeriodEndDate';
  const label = 'Grace Period End Date';

  useClearFieldOnUnmount(`submit.${name}`, initialState[name], setFieldValue);

  return (
    <InputContainer id={name}>
      <DateInput
        name={`submit.${name}`}
        label={label}
        touched={touched[name]}
        isSubmitting={isSubmitting}
        error={errors[name]}
        value={values[name]}
        onChange={setFieldValue}
        onBlur={handleBlur}
      />
    </InputContainer>
  );
};

const InGracePeriod = ({ formik }) => {
  return (
    <Container>
      {formik.values.deferment === 'no' && (
        <CurrentlyInGracePeriod formik={formik} />
      )}
      {formik.values.inGracePeriod === 'true' && (
        <RemainInGracePeriod formik={formik} />
      )}
      {formik.values.remainInGracePeriod === 'true' && (
        <EndDate formik={formik} />
      )}
    </Container>
  );
};

const Container = styled.div``;

const InputContainer = styled.div`
  max-width: 320px;

  @media (max-width: ${MobileSize}) {
    max-width: 100%;
  }
`;

const Label = styled(FlexRow)`
  margin-bottom: 16px;
`;

export default InGracePeriod;
