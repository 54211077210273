import { Formik } from 'formik';
import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { RadioButtons } from '@summer/ui-components';

import { usePlatform } from 'hooks/platform';
import { updateCurrentIdrForm } from 'redux/actions/idr.actions';
import {
  toggleIdrQuestionnaire,
  setQuestionnaireInitialStep,
} from 'redux/actions/ui.actions';
import { getHasLoansFromGradSchool } from 'redux/selectors/financialProfile.selectors';
import {
  getCurrentIdrFormId,
  getCurrentIdrFormStep,
} from 'redux/selectors/idr.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { hasLoansFromGradSchoolSchema } from 'schemas/idr';

import Footer from '../Footer';
import { FormContents, Label } from '../shared';

const GradStatus = ({
  currentIdrFormStep,
  formId,
  hasLoansFromGradSchool,
  isLoading,
  navigation,
}) => {
  const { isMobile } = usePlatform();
  const dispatch = useDispatch();

  const handleBack = () => {
    // Show user Your Loans screen
    dispatch(toggleIdrQuestionnaire(false));
    dispatch(setQuestionnaireInitialStep('1'));
  };

  const handleSave = ({ hasLoansFromGradSchool }) => {
    // Update financial profile
    dispatch(
      updateCurrentIdrForm(
        {
          currentStep: currentIdrFormStep,
          form: { formId },
          financialProfile: { hasLoansFromGradSchool },
        },
        { close: false, finish: false, onSuccess: navigation.next },
      ),
    );
  };

  return (
    <Formik
      initialValues={{ hasLoansFromGradSchool }}
      onSubmit={handleSave}
      validationSchema={hasLoansFromGradSchoolSchema}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        isValid,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormContents>
              <Label
                h2
                align={isMobile ? 'center' : 'left'}
                id="hasLoansFromGradSchool"
              >
                Were any of your loans used for grad school?
              </Label>
              <RadioButtons
                name="hasLoansFromGradSchool"
                value={values.hasLoansFromGradSchool}
                error={errors?.hasLoansFromGradSchool}
                touched={touched.hasLoansFromGradSchool}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                numberOfCol={2}
                isSubmitting={isSubmitting}
                onBlur={handleBlur}
                options={[
                  { value: 'true', label: 'Yes' },
                  { value: 'false', label: 'No' },
                ]}
              />
            </FormContents>
            <Footer
              handleBack={handleBack}
              isBackLoading={isLoading}
              isNextDisabled={!isValid}
              isNextLoading={isLoading}
              isValid={isValid}
            />
          </form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => ({
  currentIdrFormStep: getCurrentIdrFormStep(state),
  formId: getCurrentIdrFormId(state),
  hasLoansFromGradSchool: getHasLoansFromGradSchool(state).toString(),
  isLoading: isLoading(state, 'updateCurrentIdrForm'),
});

export default connect(mapStateToProps)(GradStatus);
