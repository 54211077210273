import { has } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import {
  Button,
  Container,
  Icon,
  ResponsiveColumn,
  Column,
  Row,
  Strong,
  Header,
  Text,
  COLORS,
} from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { marketingAddress } from 'constants/emailLinks';
import {
  CONSUMER_PRIVACY_NOTICE_LINK,
  PRIVACY_POLICY_LINK,
  TERMS_OF_USE_LINK,
} from 'constants/externalLinks/supportLinks';
import { MobileSize, TabletSize } from 'constants/styleguide';

import { ReactComponent as CNBC } from './assets/CNBC.svg';
import { ReactComponent as NewYorkTime } from './assets/NewYorkTimes.svg';
import { ReactComponent as Convo } from './assets/convo.svg';
import { ReactComponent as Fill } from './assets/fill.svg';
import { ReactComponent as Forbes } from './assets/forbes.svg';
import { ReactComponent as Handshake } from './assets/handshake.svg';
import { ReactComponent as House } from './assets/house.svg';
import { ReactComponent as Piggy } from './assets/piggy.svg';
import { ReactComponent as QrFace } from './assets/qr-face.svg';
import { ReactComponent as TechCrunch } from './assets/techcrunch.svg';
import wsj from './assets/wsj.png';
import yfi from './assets/yfi.png';

const featuredIn = [
  {
    link: 'https://www.nytimes.com/2018/11/16/your-money/student-loans-grace-period.html',
    icon: NewYorkTime,
    width: 140,
  },
  {
    link: 'https://www.forbes.com/sites/dianahembree/2018/11/01/new-report-finds-student-debt-burden-has-disastrous-domino-effect-on-millions-of-americans/?sh=72b1ceb912d1',
    icon: Forbes,
  },
  {
    link: 'https://techcrunch.com/2019/09/26/summer-wants-to-vanquish-student-loans-for-borrowers-and-now-has-10m-to-do-it/',
    icon: TechCrunch,
    width: 140,
  },
  {
    link: 'https://www.cnbc.com/2019/12/13/as-student-debt-grows-states-are-rolling-out-forgiveness-programs.html',
    icon: CNBC,
    width: 140,
  },
  {
    link: 'https://finance.yahoo.com/news/student-loan-assistance-185604599.html',
    img: yfi,
    width: 117,
  },
  {
    link: 'https://www.wsj.com/articles/mistakes-to-avoid-when-refinancing-student-loans-11588364571',
    img: wsj,
    width: 133,
  },
];

const AdpMarketing = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const isSecureEligible = queryParams.get('isSecureEligible') || '';

  const getHeaderText = () => {
    return isSecureEligible ? (
      'More about this benefit'
    ) : (
      <>
        Summer <Strong>makes it easy</Strong> to start saving
      </>
    );
  };

  const getIcons = () => {
    return isSecureEligible ? (
      <>
        <Icon SvgComponent={House} width={34} />
        <Text paragraph>
          <Header h3>Maximize Retirement Savings</Header>
          As of January 2024, your employer can match your student loan payments
          towards your retirement account under the Secure 2.0 Act.
        </Text>

        <Icon SvgComponent={Piggy} width={34} />
        <Text paragraph>
          <Header h3>Saving on Student Loans</Header>
          Once you&apos;ve enrolled in retirement matching, you can use
          Summer&apos;s student loan optimization tools for personalized
          recommendations for reducing your student debt.
        </Text>

        <Icon SvgComponent={Handshake} width={34} />
        <Text paragraph>
          <Header h3>Summer and ADP</Header>
          Get started with Summer and we&apos;ll work directly with ADP and your
          employer to see how much additional money can be put towards your
          retirement account.
        </Text>
      </>
    ) : (
      <>
        <Icon SvgComponent={QrFace} width={34} />
        <Text paragraph>
          <Header h3>See custom plans</Header>
          Summer looks at hundreds of federal, state, and private options to
          help you save on your loans.
        </Text>

        <Icon SvgComponent={Fill} width={34} />
        <Text paragraph>
          <Header h3>Enroll seamlessly online</Header>
          Once you&apos;ve found the right program, you can enroll online—no
          more complicated paperwork!
        </Text>

        <Icon SvgComponent={Convo} width={34} />
        <Text paragraph>
          <Header h3>Stay informed</Header>
          Summer will keep you updated on the latest student loan guidelines and
          help you stay on track.
        </Text>
      </>
    );
  };

  return (
    <MarketingContainer>
      <SectionMIE>
        <GridContainer>
          <Row center>
            <ResponsiveColumn desktopCol="10" tabletCol="12">
              <Header light align="center">
                {getHeaderText()}
              </Header>
              <MIESteps>{getIcons()}</MIESteps>
            </ResponsiveColumn>
          </Row>
        </GridContainer>
      </SectionMIE>

      <SectionFeatured>
        <GridContainer>
          <Row center>
            <ResponsiveColumn desktopCol="10" tabletCol="12" mobileCol="12">
              <FeaturedHeader>FEATURED IN:</FeaturedHeader>
              <FeaturedIcons>
                {featuredIn.map((item, index) => {
                  return (
                    <Link key={index} href={item.link}>
                      {has(item, 'img') && (
                        <img
                          alt=""
                          className="icon"
                          src={item.img}
                          width={item.width}
                        />
                      )}
                      {has(item, 'icon') && (
                        <Icon SvgComponent={item.icon} width={item.width} />
                      )}
                    </Link>
                  );
                })}
              </FeaturedIcons>
            </ResponsiveColumn>
          </Row>
        </GridContainer>
      </SectionFeatured>

      <SectionCta className="cta">
        <GridContainer>
          <Row center>
            <Column col="12">
              <Header color="light">Find your path out of student debt.</Header>
              <Button
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  })
                }
                secondary
                width={173}
              >
                Start saving today
              </Button>
            </Column>
          </Row>
        </GridContainer>
      </SectionCta>

      <SectionFooter>
        <GridContainer>
          <Row center>
            <ResponsiveColumn desktopCol="5" mobileCol="12" tabletCol="12">
              <Text size="extraSmall" align="left">
                © {new Date().getFullYear()} Summer, PBC
              </Text>
            </ResponsiveColumn>
            <ResponsiveColumn desktopCol="5" mobileCol="12" tabletCol="12">
              <FooterLinks>
                <Link href={`mailto:${marketingAddress}`}>
                  {marketingAddress}
                </Link>
                <div>
                  <Link to={TERMS_OF_USE_LINK} inheritColor underline>
                    Terms of Use
                  </Link>
                  <span> | </span>
                  <Link to={PRIVACY_POLICY_LINK} inheritColor underline>
                    Privacy Policy
                  </Link>
                  <span> | </span>
                  <Link
                    href={CONSUMER_PRIVACY_NOTICE_LINK}
                    inheritColor
                    underline
                  >
                    GLBA Privacy Notice
                  </Link>
                </div>
              </FooterLinks>
            </ResponsiveColumn>
          </Row>
        </GridContainer>
      </SectionFooter>
    </MarketingContainer>
  );
};

const GridContainer = styled(Container)`
  padding-left: 24px;
  padding-right: 24px;
`;

const MarketingContainer = styled.div`
  width: 100%;
`;

const SectionMIE = styled.section`
  background-color: rgba(236, 229, 218, 0.24); // we don't have color for this
  padding: 50px 0;

  h1 {
    margin-bottom: 60px;
  }
`;

const MIESteps = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: 8px 70px;
  text-align: center;

  .text {
    padding: 0 24px;
  }

  h3 {
    margin-bottom: 6px;
  }

  @media (max-width: ${TabletSize}) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;

    .icon {
      margin-top: 32px;
      :first-child {
        margin-top: 0;
      }
    }

    .text {
      padding: 0 32px;
    }
  }
`;

const SectionFeatured = styled.section`
  padding: 48px 0;
`;

const FeaturedHeader = styled.div`
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  letter-spacing: 2px;
  color: ${COLORS.medDarkGrey};
  margin-bottom: 24px;
`;

const FeaturedIcons = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  grid-gap: 30px;

  .icon {
    :hover {
      filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
    }
  }

  @media (max-width: 1167px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${TabletSize}) {
    grid-template-columns: repeat(2, 1fr);
    flex-direction: column;
    .icon {
      margin-bottom: 16px;
    }
  }
`;

const SectionCta = styled.section`
  background-color: ${COLORS.azure};
  padding: 100px 0;
  text-align: center;

  h1 {
    margin-bottom: 32px;
  }

  button {
    background-color: ${COLORS.white};
    border: 0;
  }
`;

const SectionFooter = styled.section`
  padding: 24px 0;

  @media (max-width: ${MobileSize}) {
    .text {
      text-align: center;
    }
  }
`;

const FooterLinks = styled.div`
  text-align: right;
  font-size: 14px;
  color: ${COLORS.darkerGrey};

  a {
    line-height: 1.8;
  }

  div {
    font-size: 12px;
    span {
      color: ${COLORS.grey};
    }
  }

  @media (max-width: ${MobileSize}) {
    text-align: center;
    margin-top: 8px;
  }
`;

export default AdpMarketing;
