import React, { memo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Text,
  FlexRow,
  RadioCircle,
  Statistic,
  COLORS,
} from '@summer/ui-components';

import InfoTooltip from 'components/shared/InfoTooltip';
import {
  getRefiLowestMonthlyPaymentValues,
  getRefiLowestTotalPaidValues,
} from 'redux/selectors/recEngine.selectors';

const RefiToggle = ({
  isLowestMonthlyPaymentSelected,
  setIsLowestMonthlyPaymentSelected,
  refiLowestMonthlyPaymentValues,
  refiLowestTotalPaidValues,
}) => {
  const data = isLowestMonthlyPaymentSelected
    ? refiLowestMonthlyPaymentValues
    : refiLowestTotalPaidValues;

  return (
    <Box>
      <RefiToggleHeader>
        <Text bold color="light">
          <span role="img" aria-label="emoji">
            🎉
          </span>{' '}
          Potential New Loan
        </Text>
      </RefiToggleHeader>
      <RefiToggleBox>
        <RefiValues>
          <RefiPaymentRow>
            <div data-testid="test-refi_toggle-term_of_loan">
              <LabelText extraSmall>Term of loan</LabelText>
              <FlexRow>
                <RefiStatistic
                  animate
                  bold
                  value={data.loanTerm / 12 || null}
                  format="number"
                  color="summer"
                  size="extraSmall"
                />
                {data.loanTerm && (
                  <TermText bold color="summer">
                    &ensp;years
                  </TermText>
                )}
              </FlexRow>
            </div>
            <div data-testid="test-refi_toggle-fixed_apr">
              <LabelText extraSmall>Fixed APR*</LabelText>
              <RefiStatistic
                animate
                bold
                value={data.apr}
                format="percentage"
                color="summer"
                size="extraSmall"
              />
            </div>
          </RefiPaymentRow>
          <RefiPaymentRow>
            <div data-testid="test-refi_toggle-total_loan_amount">
              <LabelText extraSmall>Total loan amount</LabelText>
              <RefiStatistic
                animate
                bold
                value={data.totalLoanAmount}
                format="dollars"
                color="summer"
                size="extraSmall"
              />
            </div>
            <div data-testid="test-refi_toggle-total_interest_to_pay">
              <LabelText extraSmall>Total interest to pay</LabelText>
              <RefiStatistic
                animate
                bold
                value={data.totalInterestToPay}
                format="dollars"
                color="summer"
                size="extraSmall"
              />
            </div>
          </RefiPaymentRow>
        </RefiValues>
        <Toggle>
          <ToggleButtons>
            <FlexRow>
              <RadioCircle
                checked={isLowestMonthlyPaymentSelected}
                name="lowest-monthly-payment"
                value="lowest-monthly-payment"
                onChange={() => {
                  setIsLowestMonthlyPaymentSelected(true);
                }}
              />
              <RadioLabel small>Lowest monthly payment</RadioLabel>
              <InfoTooltip
                text="Select this option to prioritize lower monthly payment amounts, which can result from a combination of lower interest rates and/or a longer loan term."
                top={1}
              />
            </FlexRow>
            <FlexRow>
              <RadioCircle
                checked={!isLowestMonthlyPaymentSelected}
                name="lowest-total-payment"
                value="lowest-total-payment"
                onChange={() => {
                  setIsLowestMonthlyPaymentSelected(false);
                }}
              />
              <RadioLabel small>Lowest total interest</RadioLabel>
              <InfoTooltip
                text="Select this option to pay the least amount of interest over the lifetime of the loan, which can result from a combination of lower interest rates and/or a shorter loan term."
                top={1}
              />
            </FlexRow>
          </ToggleButtons>
        </Toggle>
      </RefiToggleBox>
    </Box>
  );
};

const Box = styled.div`
  text-align: center;
`;

const RefiToggleHeader = styled.div`
  display: inline-block;
  padding: 5px 12px 6px;
  border-radius: 4px;
  background-color: ${COLORS.cobaltDark};
`;

const RefiToggleBox = styled.div`
  margin: -16px 0 16px;
  padding: 30px 0 0;
  border-radius: 4px;
  border: solid 1px ${COLORS.cobaltDark};
`;

const LabelText = styled(Text)`
  padding-bottom: 4px;
  &:last-child {
    padding-top: 16px;
  }
`;

const TermText = styled(Text)`
  padding-top: 2px;
`;

const RefiValues = styled.div`
  padding: 0 16px 16px;

  & > *:not(:last-child) {
    padding-bottom: 8px;
  }
`;

const RefiPaymentRow = styled(FlexRow)`
  justify-content: space-between;

  & > *:first-child {
    text-align: left;
    margin-right: 40px;
  }

  & > *:nth-child(2) {
    text-align: right;
  }
`;

const RefiStatistic = styled(Statistic)`
  margin-bottom: 4px;
`;

const Toggle = styled.div`
  background-color: ${COLORS.lighterGrey};
  border-radius: 0 0 4px 4px;
`;

const ToggleButtons = styled.div`
  padding: 16px;
  text-align: left;
  & > *:not(:last-child) {
    padding-bottom: 8px;
  }
`;

const RadioLabel = styled(Text)`
  margin-left: 8px;
`;

const mapStateToProps = state => ({
  refiLowestMonthlyPaymentValues: getRefiLowestMonthlyPaymentValues(state),
  refiLowestTotalPaidValues: getRefiLowestTotalPaidValues(state),
});

export default connect(mapStateToProps)(memo(RefiToggle));
