import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { COLORS, FlexCenter, LoadingBars } from '@summer/ui-components/src';

import SlcStartPage from 'components/pages/employerContribution/dashboard/startPage';
import SlcSuccessPage from 'components/pages/employerContribution/dashboard/successPage';
import { fetchSlcUser } from 'redux/actions/slc.actions';
import { isLoading, isLoadingWithInit } from 'redux/selectors/ui.selectors';
import { getInSLC } from 'redux/selectors/user.selectors';

const EmployerContribution = ({ inSLC, isLoading }) => {
  const dispatch = useDispatch();

  useEffect(
    function fetchSlcUserOnMount() {
      dispatch(fetchSlcUser());
    },
    [dispatch],
  );

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingBars color={COLORS.azure} />
      </LoadingContainer>
    );
  }

  if (inSLC) {
    return <SlcSuccessPage />;
  }

  return <SlcStartPage />;
};

const LoadingContainer = styled(FlexCenter)`
  height: 100%;
`;

const mapStateToProps = state => ({
  inSLC: getInSLC(state),
  isLoading:
    isLoadingWithInit(state, 'fetchSlcUser') ||
    isLoading(state, 'createSlcUser') ||
    isLoading(state, 'postPlaidIntegration'),
});

export default connect(mapStateToProps)(EmployerContribution);
