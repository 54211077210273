import { UPDATE_PROFILE, NETWORK_SUCCESS } from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import { setProfile } from 'redux/actions/profile.actions';
import handleSideEffects from 'redux/handleSideEffects';

const profileMiddleware = handleSideEffects({
  [UPDATE_PROFILE]: (dispatch, { payload: { profile } }) => {
    dispatch(
      apiRequest({
        url: '/v1/user/profile',
        method: 'POST',
        body: profile,
        fromAction: UPDATE_PROFILE,
        label: 'updateProfile',
      }),
    );
  },
  [NETWORK_SUCCESS]: (dispatch, { payload, meta }) => {
    if (meta.fromAction === UPDATE_PROFILE) {
      dispatch(setProfile(payload.response));
    }
  },
});

export default profileMiddleware;
