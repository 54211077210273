import { includes } from 'lodash';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Header, DateInput, Checkbox, FlexRow } from '@summer/ui-components';

const EmployerEndDateQuestion = ({
  formik: {
    values,
    errors,
    handleChange,
    setFieldValue,
    handleBlur,
    touched,
    isSubmitting,
  },
  currentPslfForm,
}) => {
  useEffect(() => {
    const stillEmployedIsChecked =
      currentPslfForm.employerStartDate && !currentPslfForm.employerEndDate;

    setFieldValue('stillEmployed', !!stillEmployedIsChecked);
  }, [currentPslfForm, setFieldValue]);

  return (
    <>
      <DateInput
        name="employerEndDate"
        value={values.employerEndDate}
        error={errors.employerEndDate}
        touched={touched.employerEndDate}
        onChange={setFieldValue}
        onBlur={handleBlur}
        isSubmitting={isSubmitting}
        label="End Date"
        disabled={values.stillEmployed}
      />
      <StillEmployedContainer
        isEndDateErrorDisplayed={
          (touched.employerEndDate || isSubmitting) && !!errors.employerEndDate
        }
      >
        <Header as="h5" color="secondary">
          OR
        </Header>
        <StillEmployedInputContainer>
          <Checkbox
            name="stillEmployed"
            label="Still employed"
            onChange={e => {
              handleChange(e);
              setTimeout(() => setFieldValue('employerEndDate', null), 0);
            }}
            checked={includes([values.stillEmployed], true)}
          />
        </StillEmployedInputContainer>
      </StillEmployedContainer>
    </>
  );
};

const StillEmployedContainer = styled(FlexRow)`
  top: ${({ isEndDateErrorDisplayed }) =>
    isEndDateErrorDisplayed ? `0px` : `-18px`};
  position: relative;
  align-items: center;
`;

const StillEmployedInputContainer = styled.div`
  margin-left: 8px;
  margin-top: 8px;
`;

export default EmployerEndDateQuestion;
