import { find } from 'lodash';

import { SET_SYNCS } from 'constants/actionTypes';

const initialState = {
  self: null,
};

const syncReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SYNCS: {
      return {
        self: find(payload, account => !account.is_spouse) || state.self,
      };
    }
    default:
      return state;
  }
};

export default syncReducer;
