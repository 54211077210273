import { USStatesDisplayName } from '@simplifidev/shared/dist/constants/states';

const USStatesPrismic = {
  Alabama: 'alabama',
  Alaska: 'alaska',
  Arizona: 'arizona',
  Arkansas: 'arkansas',
  California: 'california',
  Colorado: 'colorado',
  Connecticut: 'connecticut',
  Delaware: 'delaware',
  Florida: 'florida',
  Georgia: 'georgia',
  Hawaii: 'hawaii',
  Idaho: 'idaho',
  Illinois: 'illinois',
  Indiana: 'indiana',
  Iowa: 'iowa',
  Kansas: 'kansas',
  Kentucky: 'kentucky',
  Louisiana: 'louisiana',
  Maine: 'maine',
  Maryland: 'maryland',
  Massachusetts: 'massachusetts',
  Michigan: 'michigan',
  Minnesota: 'minnesota',
  Mississippi: 'mississippi',
  Missouri: 'missouri',
  Montana: 'montana',
  Nebraska: 'nebraska',
  Nevada: 'nevada',
  NewHampshire: 'new hampshire',
  NewJersey: 'new jersey',
  NewMexico: 'new mexico',
  NewYork: 'new york',
  NorthCarolina: 'north carolina',
  NorthDakota: 'north dakota',
  Ohio: 'ohio',
  Oklahoma: 'oklahoma',
  Oregon: 'oregon',
  Pennsylvania: 'pennsylvania',
  RhodeIsland: 'rhode island',
  SouthCarolina: 'south carolina',
  SouthDakota: 'south dakota',
  Tennessee: 'tennessee',
  Texas: 'texas',
  Utah: 'utah',
  Vermont: 'vermont',
  Virginia: 'virginia',
  Washington: 'washington',
  WestVirginia: 'west virginia',
  Wisconsin: 'wisconsin',
  Wyoming: 'wyoming',
  DistrictOfColumbia: 'district of columbia',
  AmericanSamoa: 'american Samoa',
  FederatedStatesOfMicronesia: 'federated states of micronesia',
  Guam: 'guam',
  MilitaryAmericas: 'military americas',
  MilitaryEurope: 'military europe',
  MilitaryPacific: 'military pacific',
  NorthernMarianaIslands: 'northern mariana islands',
  PuertoRico: 'puerto rico',
  RepublicOfPalau: 'republic of palau',
  USVirginIslands: 'virgin islands, U.S.',
};

export const StateFilterOptions = [
  {
    label: USStatesDisplayName.Alabama,
    value: USStatesPrismic.Alabama,
  },
  {
    label: USStatesDisplayName.Alaska,
    value: USStatesPrismic.Alaska,
  },
  {
    label: USStatesDisplayName.Arizona,
    value: USStatesPrismic.Arizona,
  },
  {
    label: USStatesDisplayName.Arkansas,
    value: USStatesPrismic.Arkansas,
  },
  {
    label: USStatesDisplayName.California,
    value: USStatesPrismic.California,
  },
  {
    label: USStatesDisplayName.Colorado,
    value: USStatesPrismic.Colorado,
  },
  {
    label: USStatesDisplayName.Connecticut,
    value: USStatesPrismic.Connecticut,
  },
  {
    label: USStatesDisplayName.Delaware,
    value: USStatesPrismic.Delaware,
  },
  {
    label: USStatesDisplayName.DistrictOfColumbia,
    value: USStatesPrismic.DistrictOfColumbia,
  },
  {
    label: USStatesDisplayName.Florida,
    value: USStatesPrismic.Florida,
  },
  {
    label: USStatesDisplayName.Georgia,
    value: USStatesPrismic.Georgia,
  },
  {
    label: USStatesDisplayName.Hawaii,
    value: USStatesPrismic.Hawaii,
  },
  {
    label: USStatesDisplayName.Idaho,
    value: USStatesPrismic.Idaho,
  },
  {
    label: USStatesDisplayName.Illinois,
    value: USStatesPrismic.Illinois,
  },
  {
    label: USStatesDisplayName.Indiana,
    value: USStatesPrismic.Indiana,
  },
  {
    label: USStatesDisplayName.Iowa,
    value: USStatesPrismic.Iowa,
  },
  {
    label: USStatesDisplayName.Kansas,
    value: USStatesPrismic.Kansas,
  },
  {
    label: USStatesDisplayName.Kentucky,
    value: USStatesPrismic.Kentucky,
  },
  {
    label: USStatesDisplayName.Louisiana,
    value: USStatesPrismic.Louisiana,
  },
  {
    label: USStatesDisplayName.Maine,
    value: USStatesPrismic.Maine,
  },
  {
    label: USStatesDisplayName.Maryland,
    value: USStatesPrismic.Maryland,
  },
  {
    label: USStatesDisplayName.Massachusetts,
    value: USStatesPrismic.Massachusetts,
  },
  {
    label: USStatesDisplayName.Michigan,
    value: USStatesPrismic.Michigan,
  },
  {
    label: USStatesDisplayName.Minnesota,
    value: USStatesPrismic.Minnesota,
  },
  {
    label: USStatesDisplayName.Mississippi,
    value: USStatesPrismic.Mississippi,
  },
  {
    label: USStatesDisplayName.Missouri,
    value: USStatesPrismic.Missouri,
  },
  {
    label: USStatesDisplayName.Montana,
    value: USStatesPrismic.Montana,
  },
  {
    label: USStatesDisplayName.Nebraska,
    value: USStatesPrismic.Nebraska,
  },
  {
    label: USStatesDisplayName.Nevada,
    value: USStatesPrismic.Nevada,
  },
  {
    label: USStatesDisplayName.NewHampshire,
    value: USStatesPrismic.NewHampshire,
  },
  {
    label: USStatesDisplayName.NewJersey,
    value: USStatesPrismic.NewJersey,
  },
  {
    label: USStatesDisplayName.NewMexico,
    value: USStatesPrismic.NewMexico,
  },
  {
    label: USStatesDisplayName.NewYork,
    value: USStatesPrismic.NewYork,
  },
  {
    label: USStatesDisplayName.NorthCarolina,
    value: USStatesPrismic.NorthCarolina,
  },
  {
    label: USStatesDisplayName.NorthDakota,
    value: USStatesPrismic.NorthDakota,
  },
  {
    label: USStatesDisplayName.Ohio,
    value: USStatesPrismic.Ohio,
  },
  {
    label: USStatesDisplayName.Oklahoma,
    value: USStatesPrismic.Oklahoma,
  },
  {
    label: USStatesDisplayName.Oregon,
    value: USStatesPrismic.Oregon,
  },
  {
    label: USStatesDisplayName.Pennsylvania,
    value: USStatesPrismic.Pennsylvania,
  },
  {
    label: USStatesDisplayName.RhodeIsland,
    value: USStatesPrismic.RhodeIsland,
  },
  {
    label: USStatesDisplayName.SouthCarolina,
    value: USStatesPrismic.SouthCarolina,
  },
  {
    label: USStatesDisplayName.SouthDakota,
    value: USStatesPrismic.SouthDakota,
  },
  {
    label: USStatesDisplayName.Tennessee,
    value: USStatesPrismic.Tennessee,
  },
  {
    label: USStatesDisplayName.Texas,
    value: USStatesPrismic.Texas,
  },
  {
    label: USStatesDisplayName.Utah,
    value: USStatesPrismic.Utah,
  },
  {
    label: USStatesDisplayName.Vermont,
    value: USStatesPrismic.Vermont,
  },
  {
    label: USStatesDisplayName.Virginia,
    value: USStatesPrismic.Virginia,
  },
  {
    label: USStatesDisplayName.Washington,
    value: USStatesPrismic.Washington,
  },
  {
    label: USStatesDisplayName.WestVirginia,
    value: USStatesPrismic.WestVirginia,
  },
  {
    label: USStatesDisplayName.Wisconsin,
    value: USStatesPrismic.Wisconsin,
  },
  {
    label: USStatesDisplayName.Wyoming,
    value: USStatesPrismic.Wyoming,
  },
  {
    label: USStatesDisplayName.AmericanSamoa,
    value: USStatesPrismic.AmericanSamoa,
  },
  {
    label: USStatesDisplayName.FederatedStatesOfMicronesia,
    value: USStatesPrismic.FederatedStatesOfMicronesia,
  },
  {
    label: USStatesDisplayName.Guam,
    value: USStatesPrismic.Guam,
  },
  {
    label: USStatesDisplayName.MilitaryAmericas,
    value: USStatesPrismic.MilitaryAmericas,
  },
  {
    label: USStatesDisplayName.MilitaryEurope,
    value: USStatesPrismic.MilitaryEurope,
  },
  {
    label: USStatesDisplayName.MilitaryPacific,
    value: USStatesPrismic.MilitaryPacific,
  },
  {
    label: USStatesDisplayName.NorthernMarianaIslands,
    value: USStatesPrismic.NorthernMarianaIslands,
  },
  {
    label: USStatesDisplayName.PuertoRico,
    value: USStatesPrismic.PuertoRico,
  },
  {
    label: USStatesDisplayName.RepublicOfPalau,
    value: USStatesPrismic.RepublicOfPalau,
  },
  {
    label: USStatesDisplayName.USVirginIslands,
    value: USStatesPrismic.USVirginIslands,
  },
];
