import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { FlexRow, Text, LoadingBars, COLORS } from '@summer/ui-components';

import { Row, Spacer } from 'components/pages/idr/wizard/shared/styles';
import { getValueForServicersToSendIDR } from 'redux/selectors/idr.selectors';
import { getServicers } from 'redux/selectors/servicers.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';

import { useSetServicerFields } from '../shared/servicersToSendIDR';

const ServicersToSendIDR = ({ isLoading, activeLoanServicerNames, formik }) => {
  useSetServicerFields(formik.setFieldValue);

  return (
    <>
      <Text paragraph>We will send your application to:</Text>
      {isLoading ? (
        LoadingBars
      ) : (
        <Row>
          <Container>
            <Label>Servicer Name</Label>
            <div>
              {activeLoanServicerNames.map(servicerName => (
                <Item key={servicerName}>{servicerName}</Item>
              ))}
            </div>
          </Container>
          <Spacer />
        </Row>
      )}
    </>
  );
};

const Container = styled.div`
  margin-bottom: 32px;
  margin-top: 24px;
  flex: 1;
`;

const Label = styled.div`
  margin-bottom: 8px;
  transition: all 0.4s ease;
  font-weight: 600;
  font-size: 16px;
  color: ${COLORS.darkerGrey};
`;

const Item = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.grey};
  width: 100%;
  min-height: 48px;
  position: relative;
  margin-bottom: 8px;
`;

const mapStateToProps = state => ({
  servicers: getServicers(state),
  activeLoanServicerNames: getValueForServicersToSendIDR(state),
  isLoading:
    isLoading(state, 'fetchAllLoans') || isLoading(state, 'fetchServicers'),
});

export default connect(mapStateToProps)(ServicersToSendIDR);
