import { findIndex } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import {
  FlexColumn,
  FlexRow,
  LoadingBars,
  COLORS,
  FlexCenter,
} from '@summer/ui-components';

import BlockingPage from 'components/pages/idr/wizard/shared/BlockingPage';
import SummerBasicSubmitPage from 'components/pages/idr/wizard/shared/SummerBasicSubmitPage';
import { DesktopHDSize, MobileSize } from 'constants/styleguide';
import { fetchServicers } from 'redux/actions/constants.actions';
import { moveToIdrCurrentStep } from 'redux/actions/idr.actions';
import { fetchAllLoans } from 'redux/actions/loans.actions';
import { getIsSummerBasicEnabled } from 'redux/selectors/flags.selectors';
import {
  doWeNeedSpouseInfo,
  doWeNeedSpouseSignature,
  getRequiredSupportingDocuments,
  showConfirmServicers,
  blockUserOnSubmitStep,
  selectedPathIncludesConsolidation,
  getSelectedPath,
} from 'redux/selectors/idr.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';

import ConfirmServicers from './sections/ConfirmServicers';
import ConsolidateLoans from './sections/ConsolidateLoans';
import References from './sections/References';
import SignAndSend from './sections/SignAndSend';
import UploadDocuments from './sections/UploadDocuments';
import YourContactInfo from './sections/YourContactInfo';
import Sidebar from './shared/Sidebar';

const Submit = ({
  values,
  errors,
  touched,
  idrHandlers,
  requiresSpouseInfo,
  requiresSpouseSignature,
  showConfirmServicers,
  requiredSupportingDocuments,
  isLoading,
  location,
  resetSubmitCount,
  currentStepSubmitCount,
  userShouldBeBlocked,
  includesConsolidation,
  updateCurrentIdrFormIsLoading,
  dispatch,
  selectedPath,
  showSummerBasic,
}) => {
  useEffect(
    function loadRequiredData() {
      // TODO: Consider hoisting these to IDRWizard or App.js
      dispatch(fetchServicers());
      dispatch(fetchAllLoans());
    },
    [dispatch],
  );

  useEffect(
    function checkForSelectedPath() {
      /* This useEffect prevents an error caused by a user moving between this
       * step and Review Your Plans with their browser's forward/back buttons.
       * Because we get a new 'batch' of paths each time the Review Your Plan step
       * is hit, if a user does not move to Submit via the "Select this plan" CTA,
       * their selectedPath will not be present on the redux state.
       */
      if (!selectedPath) {
        // Move user back to Review Your plan
        dispatch(moveToIdrCurrentStep(3));
      }
    },
    [selectedPath, dispatch],
  );

  const menuItems = useMemo(
    () => [
      ...(includesConsolidation
        ? [
            {
              title: 'Consolidate your loans',
              link: '/idr/submit/consolidate-loans',
            },
          ]
        : []),
      {
        title: 'Your contact info',
        link: '/idr/submit/contact-info',
      },
      ...(includesConsolidation
        ? [
            {
              title: 'Provide two references',
              link: '/idr/submit/references',
            },
          ]
        : []),
      ...(showConfirmServicers
        ? [
            {
              title: 'Confirm servicers',
              link: '/idr/submit/confirm-servicers',
            },
          ]
        : []),
      {
        title: 'Upload documents',
        link: '/idr/submit/upload-documents',
      },
      {
        title: 'Sign and send forms',
        link: '/idr/submit/sign-and-send',
      },
    ],
    [includesConsolidation, showConfirmServicers],
  );

  const getStepCount = () => {
    const stepIndex = findIndex(menuItems, {
      link: location.pathname,
    });
    // Need to check for selectedPath here so the error is not thrown
    // before the useEffect above has a chance to run
    if (selectedPath && stepIndex === -1) {
      throw new Error('Step index not found.');
    }
    return stepIndex + 1;
  };

  const showBlocker = !showSummerBasic && userShouldBeBlocked;
  const showSummerSaveSubmit = !showSummerBasic && !userShouldBeBlocked;

  return (
    <>
      {isLoading && <LoadingBars color={COLORS.azure} />}

      {showSummerBasic && <SummerBasicSubmitPage />}
      {showBlocker && <BlockingPage step="Submit" />}

      {showSummerSaveSubmit && (
        <Container isLoading={isLoading}>
          <>
            <Sidebar
              menuItems={menuItems}
              requiresSpouseInfo={requiresSpouseInfo}
              requiresSpouseSignature={requiresSpouseSignature}
            />
            <FormContainer>
              <StepHeaderStyled>
                {`Step ${getStepCount()} of ${menuItems.length} `}
              </StepHeaderStyled>
              {updateCurrentIdrFormIsLoading ? (
                <LoadingContainer>
                  <LoadingBars color={COLORS.azure} />
                </LoadingContainer>
              ) : (
                <Switch>
                  {/* add condition for consolidation required */}
                  <Route
                    exact
                    path="/idr/submit/consolidate-loans"
                    render={() => {
                      return (
                        <ConsolidateLoans
                          values={values}
                          errors={errors || {}}
                          touched={touched || {}}
                          idrHandlers={idrHandlers}
                          location={location}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/idr/submit/contact-info"
                    render={({ location, match, history }) => {
                      return (
                        <YourContactInfo
                          values={values}
                          errors={errors || {}}
                          touched={touched || {}}
                          idrHandlers={idrHandlers}
                          location={location}
                          match={match}
                          history={history}
                          resetSubmitCount={resetSubmitCount}
                          currentStepSubmitCount={currentStepSubmitCount}
                        />
                      );
                    }}
                  />
                  {/* add condition for consolidation required */}
                  <Route
                    exact
                    path="/idr/submit/references"
                    render={() => {
                      return (
                        <References
                          values={values}
                          errors={errors || {}}
                          touched={touched || {}}
                          idrHandlers={idrHandlers}
                          location={location}
                          resetSubmitCount={resetSubmitCount}
                          currentStepSubmitCount={currentStepSubmitCount}
                        />
                      );
                    }}
                  />

                  {showConfirmServicers ? (
                    <Route
                      exact
                      path="/idr/submit/confirm-servicers"
                      render={props => (
                        <ConfirmServicers
                          values={values}
                          errors={errors || {}}
                          touched={touched || {}}
                          idrHandlers={idrHandlers}
                          {...props}
                        />
                      )}
                    />
                  ) : null}
                  {requiredSupportingDocuments && (
                    <Route
                      exact
                      path="/idr/submit/upload-documents"
                      render={props => (
                        <UploadDocuments
                          values={values}
                          errors={errors || {}}
                          touched={touched || {}}
                          idrHandlers={idrHandlers}
                          currentStepSubmitCount={currentStepSubmitCount}
                          {...props}
                        />
                      )}
                    />
                  )}
                  <Route
                    exact
                    path="/idr/submit/sign-and-send"
                    render={props => (
                      <SignAndSend
                        values={values}
                        errors={errors || {}}
                        touched={touched || {}}
                        idrHandlers={idrHandlers}
                        currentStepSubmitCount={currentStepSubmitCount}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/idr/submit"
                    render={({ location, match, history }) => {
                      return (
                        <YourContactInfo
                          values={values}
                          errors={errors || {}}
                          touched={touched || {}}
                          idrHandlers={idrHandlers}
                          location={location}
                          match={match}
                          history={history}
                          resetSubmitCount={resetSubmitCount}
                          currentStepSubmitCount={currentStepSubmitCount}
                        />
                      );
                    }}
                  />
                </Switch>
              )}
            </FormContainer>
          </>
        </Container>
      )}
    </>
  );
};

const Container = styled(FlexRow)`
  width: 100%;
  justify-content: center;
  flex-shrink: 0;
  visibility: ${({ isLoading }) => (isLoading ? 'hidden' : 'visible')};
`;

const LoadingContainer = styled(FlexCenter)`
  height: 100vh;
  margin-top: -20vh;
`;

const FormContainer = styled(FlexColumn)`
  width: 100%;
  max-width: 766px;
  border-radius: 3px;
  padding: 0 18px 48px 18px;
  height: min-content;

  @media (max-width: ${MobileSize}) {
    padding: 8px 0 0;
  }
`;

const StepHeaderStyled = styled.div`
  visibility: hidden;
  text-align: center;
  font-size: 16px;
  color: ${COLORS.medGrey};
  margin-bottom: 8px;

  @media (max-width: ${parseInt(DesktopHDSize, 10) - 230}px) {
    visibility: ${({ isLoading }) => (isLoading ? 'hidden' : 'visible')};
  }
`;

const mapStateToProps = state => ({
  isLoading: isLoading(state, 'fetchAllLoans'),
  requiresSpouseInfo: doWeNeedSpouseInfo(state),
  requiresSpouseSignature: doWeNeedSpouseSignature(state),
  requiredSupportingDocuments: getRequiredSupportingDocuments(state),
  showConfirmServicers: showConfirmServicers(state),
  userShouldBeBlocked: blockUserOnSubmitStep(state),
  updateCurrentIdrFormIsLoading: isLoading(state, 'updateCurrentIdrForm'),
  includesConsolidation: selectedPathIncludesConsolidation(state),
  selectedPath: getSelectedPath(state),
  showSummerBasic: getIsSummerBasicEnabled(state),
});

export default connect(mapStateToProps)(Submit);
