import {
  POST_PLAID_INTEGRATION,
  DELETE_PLAID_INTEGRATION,
  CREATE_PLAID_LINK_TOKEN,
  CREATE_PLAID_UPDATE_LINK_TOKEN,
  REENABLE_PLAID_INTEGRATION,
  FETCH_PLAID_INTEGRATIONS,
  SET_PLAID_INTEGRATIONS,
  FETCH_PLAID_TRANSACTIONS,
  SET_PLAID_TRANSACTIONS,
} from 'constants/actionTypes';

export const postPlaidIntegration = (
  publicToken,
  institution,
  successAction,
) => ({
  type: POST_PLAID_INTEGRATION,
  payload: {
    publicToken,
  },
  institution,
  successAction,
});

export const deletePlaidIntegration = plaidIntegrationUuid => ({
  type: DELETE_PLAID_INTEGRATION,
  payload: { plaidIntegrationUuid },
});

export const createPlaidLinkToken = (linkCustomizationName, onSuccess) => ({
  type: CREATE_PLAID_LINK_TOKEN,
  payload: { linkCustomizationName },
  onSuccess,
});

export const createPlaidUpdateLinkToken = (
  linkCustomizationName,
  plaidIntegrationUuid,
  onSuccess,
) => ({
  type: CREATE_PLAID_UPDATE_LINK_TOKEN,
  payload: { linkCustomizationName, plaidIntegrationUuid },
  onSuccess,
});

export const reenablePlaidIntegration = (
  plaidIntegrationUuid,
  successAction,
) => ({
  type: REENABLE_PLAID_INTEGRATION,
  payload: { plaidIntegrationUuid },
  successAction,
});

export const fetchPlaidIntegrations = () => {
  return {
    type: FETCH_PLAID_INTEGRATIONS,
  };
};

export const setPlaidIntegrations = payload => ({
  type: SET_PLAID_INTEGRATIONS,
  payload,
});

export const fetchPlaidTransactions = () => {
  return {
    type: FETCH_PLAID_TRANSACTIONS,
  };
};

export const setPlaidTransactions = payload => {
  return {
    type: SET_PLAID_TRANSACTIONS,
    payload,
  };
};
