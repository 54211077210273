import { isNil } from 'lodash';
import { createSelector } from 'reselect';

import {
  getIsKitchenSinkEnabled,
  getIsRetirementMatchV1Enabled,
  getIsContributionsEnabled,
  getIsSummerBasicEnabled,
} from './flags.selectors';

export const getUserId = state => state.currentUser.id;
export const getUserEmail = state => state.currentUser.email;
export const getUserAuthMechanism = state => state.currentUser.authMechanism;
export const getUserNewEmail = state => state.currentUser.newEmail;
export const getUserIsVerified = state => state.currentUser.isVerified;

export const getIdrOnboardingQuestion = state =>
  state.currentUser.isCurrentlyEnrolledInIDR;

export const getUserPaymentDifficulty = state => {
  return state.currentUser.troubleWithPayments;
};

export const getUserSalary = state => {
  return state.currentUser.salary;
};

export const getUserFamilyCount = state => {
  return state.currentUser.familyCount;
};

export const getUserIsPublicSector = state => {
  return state.currentUser.isPublicSector;
};

export const getUserCreditScore = state => {
  return state.currentUser.creditScore;
};

export const getUserHasOptedIntoPhoneContact = state => {
  return state.currentUser.hasOptedIntoPhoneContact;
};

export const userIsStillInSchool = state => {
  return state.profile.gradStatus === 'attending';
};

export const userWorksInPublicSector = state => {
  const { isPublicSector } = state.currentUser;
  return isPublicSector === 'yes';
};

export const userMightWorkInPublicSector = state => {
  const { isPublicSector } = state.currentUser;
  return isPublicSector === 'yes' || isPublicSector === 'maybe';
};

export const getUserFederalLoansEstimate = state =>
  state.currentUser.federalLoansEstimate;

export const getUserHasFederalLoans = state =>
  state.currentUser.federalLoansEstimate > 0;

export const getLoansInDefaultFederalOnboardingQuestion = state =>
  state.currentUser.loansInDefaultFederal;

export const getUserPrivateLoansEstimate = state =>
  state.currentUser.privateLoansEstimate;

export const getUserHasPrivateLoans = state =>
  state.currentUser.privateLoansEstimate > 0;

export const getLoansInDefaultPrivateOnboardingQuestion = state =>
  state.currentUser.loansInDefaultPrivate;

export const getUserPartnerKey = state => state.currentUser.partnerKey;
export const getUserPartnerName = state => state.currentUser.partnerName;

export const getUserIsPremium = state => {
  // Note: KitchenSink flag is for enabling all features for demos
  // this flag ensures that the demo user is always premium
  const isKitchenSinkEnabled = getIsKitchenSinkEnabled(state);
  return isKitchenSinkEnabled ? true : state.currentUser.isPremium;
};

export const getUserIsDTC = createSelector(
  getUserPartnerKey,
  partnerKey => partnerKey === 'Default',
);

export const getUserIsAdp = createSelector(
  getUserAuthMechanism,
  authMechanism => authMechanism === 'adp',
);

export const userIndicatedHasFederalLoans = createSelector(
  getUserFederalLoansEstimate,
  loansEstimate => loansEstimate > 0,
);
export const userIndicatedHasPrivateLoans = createSelector(
  getUserPrivateLoansEstimate,
  loansEstimate => loansEstimate > 0,
);

export const getInSLC = state => state.slc.slcUser.enrolled;

export const getUserPartnerApiBorrowerId = state =>
  state.currentUser.partnerApiBorrowerId;

export const partnerApiUserCanSkipOnboarding = state =>
  !!getUserPartnerApiBorrowerId(state) &&
  !isNil(getUserFederalLoansEstimate(state)) &&
  !isNil(getUserPrivateLoansEstimate(state));

// TODO: Move these to ./flags.selectors.js
export const getIsMfaEnabled = state =>
  state.flags['sms-otp-global-flag'] === true;
export const getIsPaywallEnabled = state => state.flags.showPaywall === true;
export const getIsPlaidOutage = state => state.flags.isPlaidOutage === true;

export const getDefaultDashboardPage = createSelector(
  getIsRetirementMatchV1Enabled,
  getIsContributionsEnabled,
  (isRetirementMatchV1Enabled, isContributionsEnabled) => {
    if (isRetirementMatchV1Enabled) {
      return '/retirement-match/dashboard';
    }

    if (isContributionsEnabled) {
      return '/employer-contribution';
    }

    return '/plan';
  },
);

export const getHideSupportChat = createSelector(
  getUserId,
  getUserIsAdp,
  getIsRetirementMatchV1Enabled,
  getIsSummerBasicEnabled,
  (userId, userIsAdp, isRetirementMatchV1Enabled, isSummerBasicEnabled) => {
    if (isNil(userId)) {
      return true;
    }

    return userIsAdp
      ? isSummerBasicEnabled && !isRetirementMatchV1Enabled
      : isSummerBasicEnabled;
  },
);
