import { get } from 'lodash';
import fp from 'lodash/fp';

export const getEmergencySavings = state => get(state, 'emergencySavings', {});

export const calculateEmergencySavingsTarget = state => {
  const {
    housingExpense,
    utilityExpense,
    insuranceExpense,
    transportationExpense,
    debtExpense,
    groceryExpense,
    savings,
  } = get(state, 'emergencySavings', {});

  if (!housingExpense) {
    return null;
  }

  const expenses =
    housingExpense +
    utilityExpense +
    insuranceExpense +
    transportationExpense +
    debtExpense +
    groceryExpense;
  const target = expenses * 6 - savings;

  return target;
};

export const getEmergencySavingsPayrollProviders = fp.get(
  'emergencySavings.payrollProviders',
);

export const getEmergencySavingsDeposits = () => [
  {
    type: 'Auto-Deposit',
    amount: 6700,
    datePaid: new Date(),
    fundsSource: 'Payroll Provider',
  },
  {
    type: 'Auto-Deposit',
    amount: 6700,
    datePaid: new Date(),
    fundsSource: 'Payroll Provider',
  },
  {
    type: 'Deposit',
    amount: 43000,
    datePaid: new Date(),
    fundsSource: 'Checking Account',
  },
];
