import React from 'react';
import styled from 'styled-components';

import { Icon, Text, Header, COLORS } from '@summer/ui-components';

import { Link } from 'components/shared/typography';

const WelcomeCard = ({
  onClick,
  headerText,
  contentText,
  icon,
  iconStroke,
  iconFill,
  testId,
}) => {
  return (
    <Container testId={testId} onClick={onClick}>
      <StyledIcon
        width={48}
        height={48}
        SvgComponent={icon}
        stroke={iconStroke}
        fill={iconFill}
      />
      <Header h4 center>
        {headerText}
      </Header>
      <Text paragraph center>
        {contentText}
      </Text>
    </Container>
  );
};

const StyledIcon = styled(Icon)`
  margin: 8px auto 8px;
`;

const Container = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.white};
  padding: 20px 30px 40px;
  text-decoration: none;
  border-radius: 6px;
  border: solid 1px ${COLORS.grey};

  transition: box-shadow 0.3s ease;
  box-shadow: 0 2px 4px 0 ${COLORS.lighterGrey};

  :hover {
    opacity: 1;
    box-shadow: 0 2px 4px 0 ${COLORS.grey};
  }

  h4 {
    margin-bottom: 12px;
  }
`;

export default WelcomeCard;
