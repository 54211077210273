import { object, string, array, date, number, boolean } from 'yup';

import {
  NONE_OF_THE_ABOVE,
  GOVERNMENT_ORG,
  NON_PROFIT_501C3,
  NON_PROFIT_OTHER,
} from 'constants/pslf';
import { personalInfoSchema as personalInfoSchemaIdr } from 'schemas/idr';

export const employerNameSchema = object().shape({
  employerName: string()
    .nullable()
    .required('Please enter the name of your employer'),
});

export const employerTypeSchema = object().shape({
  employerType: string()
    .nullable()
    .oneOf([GOVERNMENT_ORG, NON_PROFIT_501C3, NON_PROFIT_OTHER], '\u0020')
    .required('Please make a selection'),
});

export const employerNonProfitTypeSchema = object().shape({
  employerNonProfitType: string()
    .nullable()
    .oneOf([NONE_OF_THE_ABOVE], '\u0020')
    .required('Please make a selection'),
});

export const pslfReadinessSchema = object().shape({
  forbearanceRequested: string()
    .nullable()
    .oneOf(['true', 'false'], '\u0020')
    .required('Please make a selection'),
});

export const employerPrimaryPurposeSchema = object().shape({
  employerPrimaryPurpose: array()
    .min(1, 'Please make a selection')
    .of(
      string().notOneOf(['none of the above'], 'Please make a valid selection'),
    ),
});

export const employerDatesSchema = object().shape({
  stillEmployed: boolean().nullable(),
  employerStartDate: date()
    .nullable()
    .required('Please enter your start date (MM/DD/YYYY)')
    .min('1900-01-01', 'Please enter a valid date')
    .max(new Date(), 'Please enter a valid date'),
  employerEndDate: date()
    .nullable()
    .when('stillEmployed', {
      is: true,
      then: date()
        .nullable()
        .notRequired()
        .oneOf([null], 'Please uncheck "Still employed"'),
    })
    .when('stillEmployed', {
      is: false,
      then: date()
        .nullable()
        .required('Please enter your end date (MM/DD/YYYY)')
        .min('1900-01-01', 'Please enter a valid date')
        .max(new Date(), 'Please enter a valid date'),
    })
    .when(
      'employerStartDate',
      (employerStartDate, schema) =>
        employerStartDate &&
        schema.min(employerStartDate, 'End date must be later than start date'),
    ),
});

export const employerHoursSchema = object().shape({
  employerHoursPerWeek: number().nullable().required('Please enter a number'),
  multipleEmployers: string()
    .nullable()
    .when('employerHoursPerWeek', {
      is: employerHoursPerWeek => employerHoursPerWeek < 30,
      then: string()
        .nullable()
        .notOneOf(['false'], '\u0020')
        .required('Please make a selection'),
    }),
});

// personalInfoSchema is shared with IDR
export const personalInfoSchema = object().shape(personalInfoSchemaIdr);

export const employerInfoSchema = userEmail =>
  object().shape({
    employerName: string()
      .nullable()
      .required('Please enter the name of your employer'),
    employerWebsite: string().nullable(),
    hrFirstName: string()
      .nullable()
      .required('Please enter the first name of your HR officer'),
    hrLastName: string()
      .nullable()
      .required('Please enter the last name of your HR officer'),
    hrEmail: string()
      .validEmail('Please enter a valid email address')
      .nullable()
      .test({
        name: 'notUserEmail',
        message: 'Employer contact email address cannot be your email address',
        test: value => userEmail?.toLowerCase() !== value?.toLowerCase(),
      })
      .required('Please enter the email of your HR officer'),
  });

export const employerEmailConfirmationSchema = object().shape({
  hrEmailCorrect: boolean().oneOf([true], 'Please certify before continuing'),
});
