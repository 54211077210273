import React from 'react';

import { TextInput } from '@summer/ui-components';

import { Row } from 'components/pages/idr/wizard/shared/styles';
import { InputWrapper } from 'components/pages/idr/wizard/steps/Submit/shared/Inputs';

const Name = ({
  formik: { values, errors, handleChange, handleBlur, touched, isSubmitting },
}) => {
  return (
    <Row>
      <InputWrapper>
        <TextInput
          label="First Name"
          name="firstName"
          touched={touched.firstName}
          isSubmitting={isSubmitting}
          error={errors.firstName}
          value={values.firstName || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="First Name"
        />
      </InputWrapper>
      <InputWrapper>
        <TextInput
          label="Last Name"
          name="lastName"
          touched={touched.lastName}
          isSubmitting={isSubmitting}
          error={errors.lastName}
          value={values.lastName || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Last Name"
        />
      </InputWrapper>
    </Row>
  );
};

export default Name;
