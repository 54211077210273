import { get, toUpper } from 'lodash';
import { createSelector } from 'reselect';

export const getUserProfile = state => state.profile;

export const getUserFirstName = createSelector(
  getUserProfile,
  profile => profile.firstName,
);

export const getUserLastName = createSelector(
  getUserProfile,
  profile => profile.lastName,
);

export const getUserFullName = createSelector(
  getUserFirstName,
  getUserLastName,
  (firstName, lastName) => `${firstName} ${lastName}`,
);

export const getUserInitials = createSelector(
  getUserFirstName,
  getUserLastName,
  (firstName, lastName) =>
    `${toUpper(get(firstName, '[0]', ''))}${toUpper(get(lastName, '[0]', ''))}`,
);

export const getUserStateOfResidence = createSelector(
  getUserProfile,
  profile => profile.state,
);

export const getUserStreetAddress1 = createSelector(
  getUserProfile,
  profile => profile.streetAddress1,
);

export const getUserCity = createSelector(
  getUserProfile,
  profile => profile.city,
);

export const getUserZipcode = createSelector(
  getUserProfile,
  profile => profile.zipcode,
);

export const getUserDateOfBirth = createSelector(
  getUserProfile,
  profile => profile.dateOfBirth,
);

export const getUserPhone = createSelector(
  getUserProfile,
  profile => profile.phone,
);

export const getUserGradStatus = createSelector(
  getUserProfile,
  profile => profile.gradStatus,
);

export const getUserCurrentEmployer = createSelector(
  getUserProfile,
  profile => profile.currentEmployer,
);

export const getProfileUpdatedAt = createSelector(
  getUserProfile,
  profile => profile.updatedAt,
);
