import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Header, RadioButtons } from '@summer/ui-components';

const MultipleEmployersQuestion = ({
  formik: {
    touched,
    isSubmitting,
    errors,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
  },
}) => {
  useEffect(() => {
    return () => setFieldValue('multipleEmployers', null);
  }, [setFieldValue]);

  return (
    <>
      <HeaderContainer>
        <Header as="h2">
          Did you work for two or more 501(c)(3) non-profit and/or government
          employers that added up to at least 30 hours of work per week?
        </Header>
      </HeaderContainer>
      <RadioButtons
        name="multipleEmployers"
        touched={touched.multipleEmployers}
        isSubmitting={isSubmitting}
        error={errors.multipleEmployers}
        value={values.multipleEmployers}
        onChange={handleChange}
        onBlur={handleBlur}
        options={[
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ]}
      />
    </>
  );
};

const HeaderContainer = styled.div`
  margin-bottom: 16px;
`;

export default MultipleEmployersQuestion;
