import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';

import Platform, { MOBILE } from '../../helpers/hoc/Platform';

import Modal from '../Modal';
import Button from '../Button';
import Icon, { Close } from '../Icon';

import { COLORS, MobileSize } from '../../helpers/constants/styleguide';

class ModalPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.openByDefault,
    };
    this.scrollElementRef = React.createRef();
  }

  togglePopup = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  renderModalComponent = () => {
    if (typeof this.props.component === 'object') {
      return this.props.component;
    }
    if (typeof this.props.component === 'function') {
      return this.props.component(this.togglePopup);
    }

    return null;
  };

  render() {
    // The modal's open state is controlled internally by default,
    // but you can optionally pass a prop and control it from outside the component
    const { isOpen } = isNil(this.props.isOpen) ? this.state : this.props;

    /* fullHeight:
     *  unset - display modal with even spacing above and below at all widths
     *  small - display modal with just margins at mobile sizes, normally
     *          otherwise
     *  otherwise - display modal with just margins at all sizes
     */
    const {
      buttonText,
      handleButtonClick,
      children,
      width,
      bodyPadding,
      backgroundColor,
      closeSize,
      closeZIndex,
      fullHeight,
      displayStyle,
      kind,
      platformType,
      track,
    } = this.props;

    return (
      <>
        <Trigger
          displayStyle={displayStyle}
          onClick={this.props.togglePopup || this.togglePopup}
        >
          {children}
        </Trigger>

        <Modal
          scrollElementRef={this.scrollElementRef}
          isOpen={isOpen}
          kind={kind}
          toggleModal={this.togglePopup}
          contentHeight={platformType === MOBILE ? 'inherit' : 'auto'}
          track={track}
        >
          <Container
            backgroundColor={backgroundColor}
            width={width}
            fullHeight={fullHeight}
          >
            <ModalBody
              ref={this.scrollElementRef}
              padding={bodyPadding}
              fullHeight={fullHeight}
            >
              {this.renderModalComponent()}
            </ModalBody>
            <CloseContainer closeSize={closeSize} closeZIndex={closeZIndex}>
              <Icon
                testId="modal-close"
                width={this.props.closeSize}
                height={this.props.closeSize}
                SvgComponent={Close}
                fill={COLORS.medGrey}
                onClick={this.props.togglePopup || this.togglePopup}
              />
            </CloseContainer>
            {buttonText && (
              <ButtonContainer>
                <Button
                  width={257}
                  onClick={
                    handleButtonClick ||
                    this.props.togglePopup ||
                    this.togglePopup
                  }
                >
                  {buttonText}
                </Button>
              </ButtonContainer>
            )}
          </Container>
        </Modal>
      </>
    );
  }
}

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeInUpAnimation = css`
  ${fadeInUp} 0.2s;
`;

const Trigger = styled.div`
  cursor: pointer;
  font-size: inherit;
  ${({ displayStyle }) => displayStyle && `display: ${displayStyle};`};
`;

const Container = styled.div`
  margin: 16px;
  position: relative;
  width: ${({ width }) => width || 560}px;
  background-color: ${({ backgroundColor }) => backgroundColor || COLORS.white};
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 ${COLORS.darkerGrey};
  height: inherit;

  // the modal component use MobileSize for moving to full page because we need here different case
  // we using 100vh until we are in MobileSize then we let modal take charge on size
  @media (max-width: ${({ width }) =>
      width > parseInt(MobileSize, 10) ? `${width}px` : MobileSize}) {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    margin: 0;
  }

  @media (max-width: ${MobileSize}) {
    height: inherit;
  }

  animation: ${fadeInUpAnimation};
`;

export const topPadding = '56px';
export const bottomPadding = '40px';
export const horizontalPadding = '40px';
export const horizontalPaddingMobile = '24px';

const ModalBody = styled.div`
  padding: ${({ padding }) =>
    padding || `${topPadding} ${horizontalPadding} ${bottomPadding}`};
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  border-radius: 3px;
  text-align: left;

  ${({ fullHeight, buttonText }) => `
    max-height: calc(100vh - ${fullHeight || !buttonText ? `32px` : `132px`});
  `};

  @media (max-width: ${MobileSize}) {
    padding: ${({ padding }) =>
      padding || `${topPadding} ${horizontalPaddingMobile} ${bottomPadding}`};
    max-height: unset;
    height: inherit;
  }

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const CloseContainer = styled.div`
  cursor: pointer;
  width: ${({ closeSize }) => closeSize}px;
  height: ${({ closeSize }) => closeSize}px;
  position: absolute;
  right: 20px;
  top: 20px;
  ${({ closeZIndex }) => closeZIndex && `z-index: ${closeZIndex};`}
`;

const ButtonContainer = styled.div`
  width: 277px;
  margin: 0 auto;
  padding: 20px 0;
  text-align: center;

  @media (max-width: ${MobileSize}) {
    width: calc(100% - 32px);
  }
`;

ModalPopup.defaultProps = {
  closeSize: 20,
  width: 640,
  openByDefault: false,
};

ModalPopup.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  closeSize: PropTypes.number,
  closeZIndex: PropTypes.number,
  width: PropTypes.number,
  openByDefault: PropTypes.bool,
  buttonText: PropTypes.string,
  handleButtonClick: PropTypes.func,
  children: PropTypes.element,
  bodyPadding: PropTypes.string,
  backgroundColor: PropTypes.string,
  fullHeight: PropTypes.oneOf(['unset', 'small', 'otherwise']),
  displayStyle: PropTypes.string,
  kind: PropTypes.string,
  isOpen: PropTypes.bool,
};

export default Platform(ModalPopup);
