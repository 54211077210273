import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Strong, Text } from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import Link from 'components/shared/typography/Link';
import { showConfirmIdrPlan } from 'redux/selectors/idr.selectors';
import { getPslfRecommendation } from 'redux/selectors/recEngine.selectors';

const HeaderSubTitle = ({
  platformType,
  isRecommendedPslf,
  showConfirmIdrPlan,
}) => {
  const isMobile = platformType === MOBILE;
  const originalText = (
    <>
      <Link
        to={{ pathname: '/guides/idr', state: { goBack: true } }}
        underline
        inheritColor
        inheritWeight
      >
        IDR
      </Link>{' '}
      is a free federal program that ties your monthly payment to your income
    </>
  );

  if (isRecommendedPslf && !showConfirmIdrPlan) {
    return (
      <Container>
        <Text paragraph weight="medium">
          {!isMobile && originalText}
          {!isMobile && `.${' '}`}
          <Strong>
            If you plan on receiving Public Service Loan Forgiveness, you must
            be enrolled in an IDR plan.{' '}
          </Strong>
          Summer can help you enroll in PSLF after you complete IDR.
        </Text>
      </Container>
    );
  }

  return (
    <Container>
      <Text paragraph weight="medium">
        {originalText}-with potential forgiveness options. Consolidation is part
        of this application if needed.
      </Text>
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 72px;
`;

const mapStateToProps = state => ({
  isRecommendedPslf: getPslfRecommendation(state),
  showConfirmIdrPlan: showConfirmIdrPlan(state),
});

export default Platform(connect(mapStateToProps)(HeaderSubTitle));
