import React from 'react';
import styled from 'styled-components';

import {
  Button,
  FlexCenter,
  Text,
  TabletSize,
} from '@summer/ui-components/src';
import Link from '@summer/ui-components/src/core/typography/Link';

import { collegeAidProReferralLink } from 'constants/externalLinks/collegeSavingsPlanner';

const CollegeAidProCTA = () => (
  <FurtherHelpContainer>
    <img
      src="/images/collegeSavingsPlanner/collegeAidProLogo.png"
      alt="College Aid Pro"
      height="44px"
    />
    <FurtherHelpText small paragraph>
      Our partners, College Aid Pro, have a range of resources to help with your
      college planning needs, including customized financial aid projections for
      every school in the country, a database of scholarships, and access to a
      dedicated college funding expert.
    </FurtherHelpText>
    <Link href={collegeAidProReferralLink}>
      <FurtherHelpCTAButton>Go to College Aid Pro</FurtherHelpCTAButton>
    </Link>
  </FurtherHelpContainer>
);

const FurtherHelpContainer = styled(FlexCenter)`
  background-color: #edf3f7;
  flex-direction: column;
  align-items: start;
  padding: 30px;

  @media (min-width: ${TabletSize}) {
    max-width: 320px;
  }
`;

const FurtherHelpText = styled(Text)`
  margin: 24px 0 24px 0;
`;

const FurtherHelpCTAButton = styled(Button)`
  white-space: nowrap;
  flex-shrink: 0;
  padding-left: 18px;
  padding-right: 18px;
  width: fit-content;
  height: fit-content;
`;

export default CollegeAidProCTA;
