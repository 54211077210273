import { isEmpty } from 'lodash';
import React from 'react';

import ServicersLoansTables from 'components/common/ViewLoans/ServicersLoansTables';
import PayOff from 'components/pages/loans/components/PayOff';
import { findInactiveServicers } from 'utils/loans';

const defaultColumnsToShow = [
  'loanType',
  'repaymentPlan',
  'interestRate',
  'currentBalance',
];

const LoansTable = ({
  loans,
  allActiveServicers,
  activeServicersToDisplay,
  allInactiveServicers,
  inactiveServicersToDisplay,
  openLoanDetails,
  columnsToShow = defaultColumnsToShow,
}) => {
  return (
    <>
      <ServicersLoansTables
        normalizedLoans={loans}
        allServicers={allActiveServicers}
        servicersToDisplay={activeServicersToDisplay}
        active={true}
        onClickTableRow={openLoanDetails}
        columnsToShow={columnsToShow}
      />

      <PayOff hide={isEmpty(findInactiveServicers(loans))}>
        <ServicersLoansTables
          normalizedLoans={loans}
          allServicers={allInactiveServicers}
          servicersToDisplay={inactiveServicersToDisplay}
          active={false}
          onClickTableRow={openLoanDetails}
          columnsToShow={columnsToShow}
        />
      </PayOff>
    </>
  );
};

export default LoansTable;
