import styled from 'styled-components';

import { COLORS } from '@summer/ui-components/src';

import { MobileSize } from 'constants/styleguide';

export const EmergencySavingsCard = styled.div`
  background: ${COLORS.white};
  min-height: 286px;
  padding: 25px 56px;
  border: solid 1px ${COLORS.lighterGrey};
  border-radius: 4px;
  box-shadow: 0 0 4px 0 ${COLORS.grey};
  width: 100%;

  @media (max-width: ${MobileSize}) {
    padding: 10px;
    align-items: center;
  }
`;
