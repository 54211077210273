import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  FlexCenter,
  FlexRow,
  Button,
  TextInput,
  Header,
  PasswordResetKey,
  Icon,
  COLORS,
} from '@summer/ui-components';

import GlobalError from 'components/shared/GlobalError';
import { Link } from 'components/shared/typography';
import { resetPassword } from 'redux/actions/user.actions';
import { getErrorMessage, isLoading } from 'redux/selectors/ui.selectors';
import { resetPasswordValidationSchema } from 'schemas/onboard';

class ResetPassword extends React.Component {
  constructor() {
    super();

    this.formInitialValues = {
      password: '',
    };

    this.state = {
      submitted: false,
    };
  }

  handleSubmit = values => {
    const { password } = values;
    const params = new URLSearchParams(this.props.location.search);
    const email = params.get('email');
    const token = params.get('token');

    this.props.resetPassword({
      email: decodeURIComponent(email),
      token,
      password,
    });

    this.setState({
      submitted: true,
    });
  };

  renderForm = formik => {
    const {
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      touched,
      isSubmitting,
      isValid,
    } = formik;

    const { submitted } = this.state;
    const { error } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        {/* Dummy input to disable new-password autocomplete */}
        <input
          type="password"
          name="dummy-password"
          style={{ display: 'none' }}
        />
        <TextInput
          label="Password"
          type="password"
          name="password"
          touched={touched.password}
          isSubmitting={isSubmitting}
          error={errors.password}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!error && submitted}
          autoComplete="new-password"
        />
        <GlobalError label="reset" />
        <Button
          disabled={!isValid || (!error && submitted)}
          isLoading={this.props.isLoading}
          type="submit"
        >
          {!error && submitted
            ? 'Your Password Has Been Reset!'
            : 'Update Password'}
        </Button>
      </Form>
    );
  };

  render() {
    return (
      <Container>
        <StyledIcon SvgComponent={PasswordResetKey} stroke={COLORS.azure} />
        <Title>
          <Header h4 center>
            Enter New Password
          </Header>
        </Title>

        <Formik
          initialValues={this.formInitialValues}
          validationSchema={resetPasswordValidationSchema}
          onSubmit={this.handleSubmit}
        >
          {formik => this.renderForm(formik)}
        </Formik>

        <LinkContainer>
          <Link to="/login"> Return to login</Link>
        </LinkContainer>
      </Container>
    );
  }
}

const Container = styled(FlexCenter)`
  width: 360px;
  flex-shrink: 0;
  flex-direction: column;
`;

const StyledIcon = styled(Icon)`
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
`;

const Title = styled.div`
  margin-bottom: 20px;
`;

const Form = styled(FlexRow.withComponent('form'))`
  flex-wrap: wrap;
`;

const LinkContainer = styled.div`
  margin: 16px;
`;

const mapStateToProps = state => ({
  isLoading: isLoading(state, 'reset'),
  error: getErrorMessage(state, 'reset'),
});

export default connect(mapStateToProps, { resetPassword })(ResetPassword);
