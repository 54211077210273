import { push as redirectTo } from 'connected-react-router';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button } from '@summer/ui-components/src';

import CollegeAidProCTA from 'components/pages/collegeSavingsPlanner/calculator/results/CollegeAidProCTA';
import DetailsDisplay from 'components/pages/collegeSavingsPlanner/calculator/results/DetailsDisplay';
import NeedMore from 'components/pages/collegeSavingsPlanner/calculator/results/NeedMore';
import OnTrack from 'components/pages/collegeSavingsPlanner/calculator/results/OnTrack';
import { TabletSize } from 'constants/styleguide';
import { getCollegeSavingsPlannerResults } from 'redux/selectors/collegeSavingsPlanner.selectors';

const Results = ({ scrollToTop }) => {
  const dispatch = useDispatch();

  const calculatorResults = useSelector(getCollegeSavingsPlannerResults);
  const { surplus, contributions, monthsUntilStart } = calculatorResults;

  const hasEnoughForCollege = surplus > 0;

  return (
    <ContentContainer>
      <ResultsContainer>
        {hasEnoughForCollege ? (
          <OnTrack surplus={surplus} monthsUntilStart={monthsUntilStart} />
        ) : (
          <NeedMore
            contributions={contributions}
            monthsUntilStart={monthsUntilStart}
          />
        )}
        <CollegeAidProCTA />
      </ResultsContainer>
      <DetailsDisplay calculatorResults={calculatorResults} />
      <GoBackContainer>
        <Button
          onClick={() => {
            scrollToTop();
            dispatch(redirectTo('/college-savings-planner/calculator'));
          }}
        >
          Back to edit information
        </Button>
      </GoBackContainer>
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  padding: 20px 16px 40px;

  @media (min-width: ${TabletSize}) {
    padding: 40px 32px 40px;
  }
`;

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;

  @media (min-width: ${TabletSize}) {
    flex-direction: row;
  }
`;

const GoBackContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export default Results;
