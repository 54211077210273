import { push as redirectTo } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  CardTable,
  Cell,
  CELL_TYPES,
  Button,
  Text,
  FlexRow,
  Icon,
  Cross,
  COLORS,
  NumbersPagination,
} from '@summer/ui-components/src';
import Link from '@summer/ui-components/src/core/typography/Link';

import { MobileSize, TabletSize } from 'constants/styleguide';
import { useClientSidePagination } from 'hooks/pagination';
import { MOBILE, usePlatform } from 'hooks/platform';
import { clearFileCollections } from 'redux/actions/fileCollections.actions';
import { hexToRgba } from 'utils/common';

const DocumentsDisplay = ({ retirementMatchDocuments }) => {
  const dispatch = useDispatch();
  const { platformType } = usePlatform();
  const isMobile = platformType === MOBILE;

  const numDocuments = retirementMatchDocuments.length;

  const limit = 10;
  const {
    dataToDisplay,
    overPageLimit: showPagination,
    numPages,
    currentLower,
    currentUpper,
    currentPageIndex,
    setActiveIndex,
  } = useClientSidePagination({ data: retirementMatchDocuments, limit });

  const handleUploadDocuments = () => {
    dispatch(clearFileCollections());
    dispatch(
      redirectTo('/retirement-match/upload-documents', {
        hasPreviouslyUploadedDocuments: true,
      }),
    );
  };

  return (
    <Container>
      <DocumentsContainer>
        <ButtonContainer>
          <Button
            kind="upload-documents"
            secondary
            width={250}
            onClick={handleUploadDocuments}
          >
            <StyledIcon
              SvgComponent={Cross}
              width={isMobile ? 22 : 24}
              fill={COLORS.azure}
            />
            Upload new documents
          </Button>
        </ButtonContainer>

        <CardTable
          columns={[
            {
              Header: 'Date Uploaded',
              Cell: ({ cell }) => (
                <Cell
                  value={cell.value}
                  type={CELL_TYPES.DATE}
                  dateFormat="dd MMM yyyy"
                />
              ),
              accessor: 'createdAt',
              show: true,
            },
            {
              Header: 'Name',
              accessor: 'name',
              show: true,
            },
          ]}
          data={dataToDisplay}
        />
        <BottomContainer>
          <Text>
            Showing {currentLower} &ndash; {currentUpper} of {numDocuments}{' '}
            Documents
          </Text>

          {showPagination && (
            <NumbersPagination
              total={numPages}
              activeIndex={currentPageIndex}
              onActiveIndexChange={setActiveIndex}
            />
          )}
        </BottomContainer>
      </DocumentsContainer>
      <ExploreContainer>
        <Text bold paragraph>
          Explore Summer
        </Text>
        <Text small paragraph>
          You&rsquo;re all set! Find all the ways to reduce your student debt by{' '}
          <Link underline to="/plan">
            exploring Summer&rsquo;s other tools
          </Link>{' '}
          or return to your retirement provider.
        </Text>
      </ExploreContainer>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 12px;
  padding-bottom: 28px;
  padding-left: 55px;
  padding-right: 55px;

  @media (max-width: ${TabletSize}) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .card-table > div {
    padding: 0;
  }
`;

const StyledIcon = styled(Icon)`
  display: inline;
  height: 24px;
  margin-right: 12px;
`;

const DocumentsContainer = styled.div`
  padding-top: 12px;
  padding-bottom: 8px;
  padding-left: 73px;
  padding-right: 73px;

  @media (max-width: ${MobileSize}) {
    padding: 12px 0 0 0;
  }
`;

const ButtonContainer = styled(FlexRow)`
  justify-content: flex-end;

  button {
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: 0;
    padding: 8px;

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: ${MobileSize}) {
    padding: 0 30px 0 30px;
  }
`;

const BottomContainer = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  padding: 0 10px;

  .text {
    margin-right: 8px;
  }

  @media (max-width: ${MobileSize}) {
    flex-direction: column-reverse;

    .text {
      margin-top: 16px;
      margin-right: 0;
    }
  }
`;

const ExploreContainer = styled.div`
  background-color: ${hexToRgba(COLORS.azure, 0.06)};
  margin-top: 28px;
  width: 100%;
  padding: 16px;
`;

export default DocumentsDisplay;
