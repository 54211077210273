import React from 'react';
import { startCase, toLower, replace } from 'lodash/string';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import { isNil } from 'lodash';
import { dollars, percentage } from '../../helpers/utils/numbers';
import { Text } from '../typography';

export const CELL_TYPES = {
  STRING: 'string',
  PERCENT: 'percent',
  MONEY: 'money',
  DATE: 'date',
  ELIGIBILITY: 'eligibility',
};

const generateCell = (
  type,
  value,
  align,
  size,
  useCustomTooltip,
  dateFormat,
) => {
  switch (type) {
    case CELL_TYPES.STRING: {
      const formattedValue = replace(
        startCase(toLower(value)),
        /ffel/gi,
        'FFEL',
      );
      return (
        <Text
          weight="medium"
          color="dark"
          truncate
          tooltip={formattedValue}
          paragraph
          smallTracking
          size={size}
          useCustomTooltip={useCustomTooltip}
        >
          {formattedValue}
        </Text>
      );
    }

    case CELL_TYPES.PERCENT:
      return (
        <>
          <Text
            weight="medium"
            color="dark"
            paragraph
            smallTracking
            align={align || 'right'}
            size={size}
          >
            {percentage(value, { showSymbol: true })}
          </Text>
        </>
      );

    case CELL_TYPES.MONEY:
      return (
        <>
          <Text
            weight="medium"
            color="dark"
            paragraph
            smallTracking
            align={align || 'right'}
            size={size}
          >
            <Text
              weight="medium"
              color="dark"
              paragraph
              smallTracking
              align={align || 'right'}
              size={size}
            >
              {dollars(value)}
            </Text>
          </Text>
        </>
      );

    case CELL_TYPES.DATE:
      return (
        <>
          <Text
            weight="medium"
            color="dark"
            paragraph
            smallTracking
            align={align}
            size={size}
          >
            {format(parseISO(value), dateFormat || 'MM / yyyy')}
          </Text>
        </>
      );

    default:
      return null;
  }
};

export const Cell = ({
  type,
  value,
  moreInfo,
  align,
  size,
  moreInfoSize,
  useCustomTooltip,
  dateFormat,
}) => (
  <>
    {isNil(value) ? (
      <Text smallTracking paragraph size={size}>
        -
      </Text>
    ) : (
      <>
        {generateCell(type, value, align, size, useCustomTooltip, dateFormat)}
        <Text
          color="secondary"
          truncate
          tooltip={moreInfo}
          smallTracking
          paragraph
          size={moreInfoSize ?? 'small'}
          useCustomTooltip={useCustomTooltip}
        >
          {moreInfo}
        </Text>
      </>
    )}
  </>
);

Cell.propTypes = {
  type: PropTypes.oneOf(Object.values(CELL_TYPES)).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  moreInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  align: PropTypes.oneOf(['center', 'left', 'right']),
  size: PropTypes.oneOf(['inherit', 'extraSmall', 'small', 'medium', 'large']),
  useCustomTooltip: PropTypes.bool,
  moreInfoSize: PropTypes.oneOf([
    'inherit',
    'extraSmall',
    'small',
    'medium',
    'large',
  ]),
  dateFormat: PropTypes.oneOf(['MM / yyyy', 'dd MMM yyyy', 'MMMM dd, yyyy']),
};
