import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Button, PhoneInput, Text, Alert } from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { mfaHelpLink } from 'constants/externalLinks/support';
import { createSmsFactor } from 'redux/actions/smsFactor.actions';
import { getSmsFactorPhoneNumber } from 'redux/selectors/smsFactor.selectors';
import { isLoading, getErrorMessage } from 'redux/selectors/ui.selectors';
import { createSmsFactorSchema } from 'schemas/onboard';

import { MfaPageContainer } from './shared';

const CreateSmsFactor = ({
  phoneNumber,
  setIsEditing,
  isLoading,
  dispatch,
  serverError,
}) => {
  const handleSave = values => {
    const formattedPhoneNumber = `+1${values.phoneNumber}`;
    dispatch(
      createSmsFactor(
        {
          phoneNumber: formattedPhoneNumber,
        },
        { onSuccess: () => setIsEditing(false) },
      ),
    );
  };

  const formattedInitialPhoneNumber = phoneNumber
    ? /\+?1?(.*)/.exec(phoneNumber)[1]
    : '';

  return (
    <MfaPageContainer header="Add your phone number for additional&nbsp;security">
      <TextContainer>
        <Text paragraph center>
          Enter the phone number you&rsquo;d like to use for account
          verification. We&rsquo;ll text you a code.
        </Text>
      </TextContainer>
      <Formik
        onSubmit={handleSave}
        initialValues={{
          phoneNumber: formattedInitialPhoneNumber,
        }}
        validationSchema={createSmsFactorSchema}
      >
        {({
          values,
          errors,
          handleBlur,
          handleSubmit,
          touched,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <PhoneInput
              label="Phone number"
              name="phoneNumber"
              touched={touched.phoneNumber}
              isSubmitting={isSubmitting}
              error={errors.phoneNumber}
              value={values.phoneNumber}
              onChange={setFieldValue}
              onBlur={handleBlur}
              disabled={isLoading}
              placeholder="Enter your phone number"
              autoFocus
            />
            <ButtonContainer>
              <Button type="submit" isLoading={isLoading}>
                Send code to this number
              </Button>
            </ButtonContainer>
            <LinkTextContainer>
              <Text secondary center>
                <Link href={mfaHelpLink}>I don&rsquo;t have a cell phone</Link>
              </Text>
            </LinkTextContainer>
            {/* TODO: Add more granular error handling as part of MFA v1 */}
            {serverError && (
              <Alert theme="warning" dismissable={false}>
                It looks like that phone number is invalid. Please enter a
                different number.
              </Alert>
            )}
          </Form>
        )}
      </Formik>
    </MfaPageContainer>
  );
};

const TextContainer = styled.div`
  margin-bottom: 16px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
`;

const Form = styled.form`
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
`;

const ButtonContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
`;

const LinkTextContainer = styled.div`
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
`;

const mapStateToProps = state => ({
  isLoading: isLoading(state, 'createSmsFactor'),
  serverError: getErrorMessage(state, 'createSmsFactor'),
  phoneNumber: getSmsFactorPhoneNumber(state),
});

export default connect(mapStateToProps)(CreateSmsFactor);
