import { Formik } from 'formik';
import {
  includes,
  debounce,
  forEach,
  isFunction,
  keys,
  mapValues,
} from 'lodash';
import React, { useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';

import { Modal, COLORS } from '@summer/ui-components';

import Footer from 'components/common/ManageLoans/Footer';
import Header from 'components/common/ManageLoans/Header';
import LoansFormFieldsIdr from 'components/common/ManageLoans/LoansFormFieldsIdr';
import LoansFormFieldsPslf from 'components/common/ManageLoans/LoansFormFieldsPslf';
import Content from 'components/common/ManageLoans/shared/Content';
import { getFormInitialValues } from 'components/common/ManageLoans/shared/utils';
import Platform, { MOBILE, TABLET } from 'components/hoc/Platform';
import { fieldsOnManageLoansIdr, fieldsOnPaidOffLoans } from 'constants/loans';
import {
  programTypes,
  xPaddingDesktop,
  xPaddingMobile,
  modalMargin,
} from 'constants/manageLoans';
import { MobileSize, TabletSize } from 'constants/styleguide';
import { useReanimateAlert } from 'hooks/common';
import useDimensions from 'hooks/useDimensions';
import {
  fetchRepaymentPlanTypes,
  fetchServicers,
} from 'redux/actions/constants.actions';
import { updateLoan } from 'redux/actions/loans.actions';
import { closeManageLoans, nextLoan } from 'redux/actions/ui.actions';
import {
  getCurrentLoan,
  getLoanFormProgramType,
  getCurrentLoanIdx,
  getLoanFormNumberOfLoans,
  getCanEditField,
  isLoanFormOpen,
  isLoanEditable,
  getRepaymentPlanTypes,
  getFederalLoanTypes,
} from 'redux/selectors/loans.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { getIdrLoanSchema, pslfLoanSchema } from 'schemas/loans';
import { loanIsActive } from 'utils/loans';
import { track } from 'utils/track';

const ManageLoans = ({
  canEditField,
  currentLoanIdx,
  dispatch,
  federalLoanTypes,
  isLoading,
  isOpen,
  loan,
  loanIsEditable,
  numberOfLoans,
  programType,
  repaymentPlanTypes,
  platformType,
}) => {
  const contentRef = useRef(null);
  const resetForm = useRef(null);
  const scrollElementRef = useRef(null);

  const [headerRef, { height: headerHeight }] = useDimensions();
  const [fieldsRef, { height: fieldsHeight }] = useDimensions();
  const [footerRef, { height: footerHeight }] = useDimensions();

  // fetch necessary constants values from server
  // TODO [ch7074] These should be moved to the root of the app
  useEffect(() => {
    dispatch(fetchServicers());
    dispatch(fetchRepaymentPlanTypes());
  }, [dispatch]);

  // reset form if moving to next loan
  useEffect(() => {
    if (isOpen) {
      if (isFunction(resetForm.current)) {
        resetForm.current();
      }
      contentRef.current.scrollTo(0, 0);
    }
  }, [isOpen, currentLoanIdx]);

  // We need this to get the correct height for the ModalContainer when
  // ManageLoans first opens
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [footerHeight]);

  const { alertDismissed, reanimateAlert } = useReanimateAlert();

  const isFinalLoan = currentLoanIdx === numberOfLoans - 1;
  const isPslf = programType === programTypes.pslf;

  const handleSubmit = debounce(
    values => {
      const { onSuccess, ...valuesToSubmit } = values;
      valuesToSubmit.hasBeenReviewed = true;

      if (valuesToSubmit.educationLevel === '') {
        // value needs to be a string because it uses radio buttons
        // but we want to save it as null for paid off loans
        valuesToSubmit.educationLevel = null;
      }

      const fieldsToSubmit = loanIsActive(valuesToSubmit.principalBalance)
        ? fieldsOnManageLoansIdr
        : fieldsOnPaidOffLoans;

      // don't submit fields that cannot be edited
      // either becase they are marked as uneditable
      // or because a loan is paid off
      forEach(keys(valuesToSubmit), field => {
        if (
          field !== 'id' &&
          field !== 'disbursementDateId' &&
          (!canEditField(valuesToSubmit.id, field) ||
            !includes(fieldsToSubmit, field))
        ) {
          delete valuesToSubmit[field];
        }
      });

      // ch24595
      if (valuesToSubmit.interestRate === '') {
        valuesToSubmit.interestRate = null;
      }

      const submit = (values, action) => dispatch(updateLoan(values, action));

      let successAction;

      if (onSuccess === 'closeManageLoans' || isFinalLoan) {
        successAction = closeManageLoans;
      } else if (onSuccess === 'nextLoan') {
        successAction = nextLoan;
      }

      const successActions = {
        closeManageLoans: () => dispatch(closeManageLoans()),
        nextLoan: () => dispatch(nextLoan()),
      };

      if (loanIsEditable) {
        submit(valuesToSubmit, successAction);
      } else {
        const actionName = isFinalLoan ? 'closeManageLoans' : onSuccess;
        successActions[actionName]();
      }
    },
    250,
    { leading: true, trailing: false },
  );

  const initialValues = useMemo(
    () => getFormInitialValues(loan, programType),
    [loan, programType],
  );
  const initialTouched = useMemo(
    () => mapValues(initialValues, () => true),
    [initialValues],
  );

  const validationSchema = useMemo(
    () =>
      isPslf
        ? pslfLoanSchema
        : getIdrLoanSchema(federalLoanTypes, repaymentPlanTypes, canEditField),
    [isPslf, federalLoanTypes, repaymentPlanTypes, canEditField],
  );

  const toggleModal = () => dispatch(closeManageLoans());

  const isMobileOrTablet = platformType === MOBILE || platformType === TABLET;

  return (
    <Modal
      scrollElementRef={scrollElementRef}
      track={track}
      isOpen={isOpen}
      toggleModal={toggleModal}
      contentHeight={
        isMobileOrTablet
          ? 'inherit'
          : `min(calc(100% - ${parseInt(modalMargin, 10) * 2}px), ${
              headerHeight + fieldsHeight
            }px)`
      }
    >
      <ModalContainer
        ref={scrollElementRef}
        dims={{ fieldsHeight, headerHeight, footerHeight }}
        id="manage-loans-modal"
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          initialTouched={initialTouched}
          validationSchema={validationSchema}
          validateOnMount
          onSubmit={handleSubmit}
        >
          {formik => {
            resetForm.current = formik.resetForm;

            const loanIsPaidOff = !loanIsActive(formik.values.principalBalance);

            return (
              <Content ref={contentRef} toggleModal={toggleModal}>
                <Header
                  formik={formik}
                  loanIsPaidOff={loanIsPaidOff}
                  innerRef={headerRef}
                  alertDismissed={alertDismissed}
                />
                <FieldsOuterContainer id="manage-loans-fields">
                  <FieldsInnerContainer
                    ref={fieldsRef}
                    footerHeight={footerHeight}
                  >
                    {isPslf ? (
                      <LoansFormFieldsPslf formik={formik} />
                    ) : (
                      <LoansFormFieldsIdr formik={formik} />
                    )}
                  </FieldsInnerContainer>
                  <Footer
                    formik={formik}
                    isLoading={isLoading}
                    innerRef={footerRef}
                    reanimateAlert={reanimateAlert}
                  />
                </FieldsOuterContainer>
              </Content>
            );
          }}
        </Formik>
      </ModalContainer>
    </Modal>
  );
};

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeInUpAnimation = css`
  ${fadeInUp} 0.2s;
`;

const ModalContainer = styled.div`
  margin: ${modalMargin};
  position: relative;
  width: ${TabletSize};
  height: 100%;
  max-height: min(
    100%,
    ${({ dims }) => dims.headerHeight + dims.fieldsHeight + dims.footerHeight}px
  );
  overflow: hidden;
  background-color: ${({ backgroundColor }) => backgroundColor || COLORS.white};
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 ${COLORS.darkerGrey};

  @media (max-width: ${TabletSize}) {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    width: 100vw;
    max-height: unset;
    margin: 0;
  }

  animation: ${fadeInUpAnimation};
`;

const FieldsOuterContainer = styled.div`
  flex: 1;
  width: 100%;
  overflow: scroll;
  scroll-behavior: smooth;
  background-color: ${COLORS.lightestGrey};
`;

const FieldsInnerContainer = styled.div`
  transform: translate(0, 0);
  padding-top: 48px;
  padding-bottom: ${({ footerHeight }) => footerHeight}px;
  padding-left: ${xPaddingDesktop};
  padding-right: ${xPaddingDesktop};

  @media (max-width: ${MobileSize}) {
    padding-top: 24px;
    padding-left: ${xPaddingMobile};
    padding-right: ${xPaddingMobile};
  }
`;

const mapStateToProps = state => ({
  programType: getLoanFormProgramType(state),
  numberOfLoans: getLoanFormNumberOfLoans(state),
  loan: getCurrentLoan(state),
  isLoading: isLoading(state, 'updateLoan'),
  currentLoanIdx: getCurrentLoanIdx(state),
  isOpen: isLoanFormOpen(state),
  canEditField: getCanEditField(state),
  loanIsEditable: isLoanEditable(state),
  repaymentPlanTypes: getRepaymentPlanTypes(state),
  federalLoanTypes: getFederalLoanTypes(state),
});

export default Platform(connect(mapStateToProps)(ManageLoans));
