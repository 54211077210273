import { isNil, throttle } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { SERVICER_COLOR_LIST } from 'components/common/ViewLoans/ServicersLoansTables';

const resizePie = ({ element, setPieWidth, setPieHeight }) => {
  if (isNil(element)) {
    return;
  }

  const { height: containerHeight, width: containerWidth } =
    element.getBoundingClientRect();

  setPieHeight(containerHeight);
  setPieWidth(containerWidth);
};

export const usePieConfig = ({ data }) => {
  const contentRef = useRef(null);
  const [pieWidth, setPieWidth] = useState(0);
  const [pieHeight, setPieHeight] = useState(0);

  useEffect(() => {
    resizePie({
      element: contentRef.current,
      setPieWidth,
      setPieHeight,
    });
    window.addEventListener(
      'resize',
      throttle(() => {
        resizePie({
          element: contentRef.current,
          setPieWidth,
          setPieHeight,
        });
      }, 10000),
    );
    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  const commonProperties = {
    colors: SERVICER_COLOR_LIST,
    enableRadialLabels: false,
    enableSlicesLabels: false,
    innerRadius: 0.9,
    margin: { top: 8 },
    data,
    animate: true,
    height: pieHeight,
    width: pieWidth,
  };

  return { commonProperties, contentRef };
};
