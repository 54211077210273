import React from 'react';
import map from 'lodash/map';
import styled from 'styled-components';

import { Text } from '../../typography';
import RadioButton from '../RadioButton';
import { FlexColumn, FlexRow } from '../../Flex';
import { MobileSize } from '../../../helpers/constants/styleguide';

// we only can have max 2 radio button in one row
const MAX_NUMBER_OF_COLUMN = 2;

class RadioButtons extends React.Component {
  renderButtons = () => {
    const { options } = this.props;

    return map(options, item => {
      const { value, label } = item;
      const checked = this.props.value === value;
      return this.renderButton(checked, label, value);
    });
  };

  renderButton = (checked, label, value) => {
    const { name, onChange, validate, backgroundColor, textColor } = this.props;
    const numberOfCol =
      this.props.numberOfCol > MAX_NUMBER_OF_COLUMN
        ? MAX_NUMBER_OF_COLUMN
        : this.props.numberOfCol;

    return (
      <Box numberOfCol={numberOfCol} key={value}>
        <RadioButton
          name={name}
          label={label}
          value={value}
          checked={checked}
          onChange={onChange}
          validate={validate}
          backgroundColor={backgroundColor}
          textColor={textColor}
        />
      </Box>
    );
  };

  render() {
    const { error, touched, isSubmitting, hideError, className, name } =
      this.props;
    const showErrorMessage = (touched || isSubmitting) && !!error;

    return (
      <MainBox className={className} id={name}>
        <Container>{this.renderButtons()}</Container>
        {!hideError && (
          <ErrorMessage>
            {showErrorMessage ? (
              <Text size="small" color="error">
                {error}
              </Text>
            ) : (
              ''
            )}
          </ErrorMessage>
        )}
      </MainBox>
    );
  }
}

const MainBox = styled(FlexColumn)`
  width: 100%;
`;

const Container = styled(FlexRow)`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin-top: -10px;
`;

const ErrorMessage = styled.div`
  margin-top: 8px;
  min-height: calc(14px * 1.5);
`;

const Box = styled.div`
  margin-right: 16px;
  margin-top: 16px;
  width: ${({ numberOfCol }) =>
    numberOfCol > 1 ? `calc(100% / ${numberOfCol} - 8px)` : 'auto'};

  @media (max-width: ${MobileSize}) {
    width: 100% !important;
    margin-right: 0 !important;
  }

  ${({ numberOfCol }) => {
    if (numberOfCol) {
      if (numberOfCol === 1) {
        return `width:100%; margin-right: 0;`;
      }
      const evenMargin = numberOfCol % 2 === 0 ? 0 : 16;
      const oddMargin = numberOfCol % 2 === 0 ? 16 : 0;

      return `
      &:nth-child(even){
        margin-right: ${evenMargin}px;
      }
      &:nth-child(odd){
        margin-right: ${oddMargin}px;
      }
      &:first-child{
        margin-right: 16px;
      }
     `;
    }

    return ``;
  }};
`;

export default RadioButtons;
