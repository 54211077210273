import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { RadioButtons, COLORS } from '@summer/ui-components';

import StaticField from 'components/common/ManageLoans/fields/shared/StaticField';
import Placeholder from 'components/common/ManageLoans/shared/Placeholder';
import Section from 'components/common/ManageLoans/shared/Section';
import InfoTooltip from 'components/shared/InfoTooltip';
import { isLoanFieldEditable } from 'redux/selectors/loans.selectors';

const IncludesParentPlusLabel = () => (
  <>
    <span>Did consolidation include Parent PLUS loan?</span>
    <InfoTooltip
      text="A Parent PLUS loan is a loan that your parent(s) took out for your education. If your loan consolidation included a Parent PLUS loan, select “Yes”."
      size={14}
    />
  </>
);

class IncludesParentPlus extends React.Component {
  componentWillUnmount() {
    if (this.props.canEdit) {
      this.props.formik.setFieldValue('includesParentPlus', null);
    }
  }

  render() {
    const {
      formik: {
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        isSubmitting,
      },
      canEdit,
    } = this.props;

    return (
      <Section>
        <Container id="includesParentPlus">
          {canEdit ? (
            <>
              <Label>
                <IncludesParentPlusLabel />
              </Label>
              <ButtonContainer>
                <RadioButtons
                  row
                  numberOfCol={2}
                  design="shadowed"
                  name="includesParentPlus"
                  touched={touched.includesParentPlus}
                  isSubmitting={isSubmitting}
                  error={errors.includesParentPlus}
                  value={values.includesParentPlus}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={[
                    { value: 'true', label: 'Yes' },
                    { value: 'false', label: 'No' },
                  ]}
                  buttonCSS={this.buttonCSS}
                />
              </ButtonContainer>
            </>
          ) : (
            <StaticField
              label={<IncludesParentPlusLabel />}
              value={values.includesParentPlus === 'true' ? 'Yes' : 'No'}
            />
          )}
        </Container>
        <Placeholder />
      </Section>
    );
  }
}

const Label = styled.div`
  margin-bottom: 8px;
  transition: all 0.4 ease;
  font-weight: 600;
  font-size: 16px;
  color: ${COLORS.darkerGrey};
`;

const Container = styled.div`
  flex: 1;
  width: 100%;
  margin-top: 8px;
`;

const ButtonContainer = styled.div``;

const mapStateToProps = (state, props) => {
  const loanId = props.formik.values.id;
  return {
    canEdit: isLoanFieldEditable(state, loanId, 'includesParentPlus'),
  };
};

export default connect(mapStateToProps)(IncludesParentPlus);
