import React from 'react';

import InfoPopup from 'components/pages/idr/wizard/shared/InfoPopup';
import { idrTaxCopy } from 'constants/idr';

const IdrInfoPopup = ({ children }) => (
  <InfoPopup
    kind="IDR pros cons"
    title="Income-driven repayment plans can lower your monthly payments"
    pros={[
      {
        title: 'Income-based payments',
        description: `Your payments adjust as your income changes (up or down)–even down to $0 if you’re out of work. You’ll submit new proof of your income once a year, or whenever your income decreases.`,
      },
      {
        title: 'Flexible as your life changes',
        description:
          'Enrolling in IDR is not a permanent decision. You can always change repayment plans as your financial situation changes.',
      },
      {
        title: 'Qualify for loan forgiveness',
        description: `Your remaining loan balance could be forgiven in 25 years or less, depending on the plan you select and how long you’ve already been paying.`,
      },
    ]}
    consTitle="However, there are two potential trade-offs worth considering:"
    cons={[
      {
        description:
          'You might end up paying more in the long-term. Because you’re paying for a longer period, you may pay more overall.',
      },
      {
        description: `Some loan forgiveness might be taxed. Unless you’re enrolled in Public Service Loan Forgiveness, you might be taxed on any amount that gets forgiven after your repayment period. ${idrTaxCopy}`,
      },
    ]}
  >
    {children}
  </InfoPopup>
);

export default IdrInfoPopup;
