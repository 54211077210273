import React from 'react';
import styled from 'styled-components';

import { Alert } from '@summer/ui-components';

const IdrCalculationAdjustmentAlert = () => {
  return (
    <StyledAlert theme="caution" dismissable={false}>
      The Saving on a Valuable Education (SAVE) plan has been temporarily
      blocked by a Federal Court ruling. We’ll keep borrowers informed of any
      updates on this quickly evolving situation. For now, you can still use
      Summer to enroll in the best IDR plan for you.
    </StyledAlert>
  );
};

const StyledAlert = styled(Alert)`
  background-color: #fffcede6;
  z-index: 2;
  margin-bottom: 16px;
  text-align: left;
`;

export default IdrCalculationAdjustmentAlert;
