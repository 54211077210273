import _, { isEmpty } from 'lodash';
import fp from 'lodash/fp';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Alert,
  Button,
  COLORS,
  FlexRow,
  Header,
  Strong,
  Text,
} from '@summer/ui-components';

import ConnectFsa from 'components/common/SyncLoans/ConnectFsa';
import ServicersLoansTables from 'components/common/ViewLoans/ServicersLoansTables';
import Platform, { MOBILE } from 'components/hoc/Platform';
import { loanConsultationCalendlyLink } from 'constants/externalLinks/support';
import { MobileSize, SmallDesktopSize, TabletSize } from 'constants/styleguide';
import { getUserFederalLoans } from 'redux/selectors/loans.selectors';
import { helpWidgetSize } from 'services/chat';
import { getNormalizedFsaLoans } from 'utils/loans';

const SyncView = ({ normalizedLoans, platformType, onClickBack }) => {
  const hasLoans = !isEmpty(normalizedLoans);
  const isMobile = platformType === MOBILE;

  const activeServicers = _.flow(
    fp.filter({ active: true }),
    fp.map('servicerName'),
    fp.uniq,
  )(normalizedLoans);

  const columnsToShow = [
    'loanType',
    'educationLevel',
    'status',
    'repaymentPlan',
    'interestRate',
    'currentBalance',
  ];

  return (
    <>
      {hasLoans ? (
        <Container>
          <Alert
            theme="neutral"
            dismissable={false}
            showIcon={true}
            callToAction={{
              label: 'Find a time',
              href: loanConsultationCalendlyLink,
              buttonWidth: isMobile ? null : 128,
              fullWidthMobileButton: true,
            }}
          >
            <Text small paragraph>
              Thanks for connecting your loans.{' '}
              <Strong>Continue to schedule a consultation.</Strong>
            </Text>
          </Alert>
          <LoansSection>
            <Header h2 secondary>
              Federal Loans
            </Header>
            <ServicersLoansTables
              normalizedLoans={normalizedLoans}
              allServicers={activeServicers}
              active={true}
              columnsToShow={columnsToShow}
            />
          </LoansSection>
        </Container>
      ) : (
        <DirectSyncContainer>
          <ConnectFsa page="scheduleConsultation" headerSize="h2" />
          <Footer>
            <FooterContainer>
              <Button secondary width={120} onClick={onClickBack}>
                Back
              </Button>
            </FooterContainer>
          </Footer>
        </DirectSyncContainer>
      )}
    </>
  );
};

const Container = styled.div`
  padding: 32px 0;
  margin: 0 auto;
  max-width: 1039px;
  width: 100%;

  @media (max-width: ${SmallDesktopSize}) {
    padding: 16px 16px 86px;
  }
`;

const LoansSection = styled.div`
  padding-top: 32px;

  h2 {
    padding-bottom: 24px;
  }
`;

const DirectSyncContainer = styled.div`
  height: calc(100% - 81px);
  overflow: scroll;

  @media (max-width: ${TabletSize}) {
    height: 100%;
  }
`;

const Footer = styled(FlexRow)`
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 16px;

  // keeps help widget from overlapping right button
  padding-right: calc(${helpWidgetSize.desktop} + 16px);

  @media (max-width: ${MobileSize}) {
    position: unset;
    flex: 0 0 auto;
    // keeps help widget from overlapping right button
    padding-right: calc(${helpWidgetSize.mobile} + 16px);
  }

  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${COLORS.white};
    opacity: 0.8;
  }

  &:after {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
  }
`;

const FooterContainer = styled.div`
  & > button {
    height: 100%;
  }
`;

const mapStateToProps = state => ({
  normalizedLoans: getNormalizedFsaLoans(getUserFederalLoans(state)),
});

export default Platform(connect(mapStateToProps)(SyncView));
