import React from 'react';
import styled from 'styled-components';

import { FlexColumn } from '@summer/ui-components';

const WizardContainer = ({ children }) => <Container>{children}</Container>;

const Container = styled(FlexColumn)`
  height: 100%;
  position: relative;
`;

export default WizardContainer;
