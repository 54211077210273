/* eslint-disable react/display-name */
import React from 'react';
import styled from 'styled-components';

import { Header, Text, COLORS } from '@summer/ui-components';

import Link from 'components/shared/typography/Link';
import WizardGif from 'hooks/Wizard/Demo/shared/WizardGif';
import { useWizard } from 'hooks/Wizard/useWizard';

import NestedWizardDemo from './NestedWizardDemo';

// the order is important
const config = [
  {
    title: 'title -> Step1',
    render: props => (
      <StepComponent {...props} color={COLORS.azure}>
        <Text>step1</Text>
      </StepComponent>
    ),
  },
  {
    title: 'title -> Step2',
    render: props => <NestedWizardDemo {...props} />,
  },
  {
    title: 'title -> Step3',
    render: props => (
      <StepComponent {...props} color={COLORS.yellow}>
        <Text>step3</Text>
      </StepComponent>
    ),
  },
  {
    title: 'title -> Step4',
    render: ({ navigation, key }) => (
      <StepComponent key={key} color={COLORS.orange}>
        {navigation.isLastStep ? (
          <Text>This is the Last Step!!</Text>
        ) : (
          <Text>step4</Text>
        )}
      </StepComponent>
    ),
  },
];

const renderWizardHeaders = headers => {
  return headers.map(({ title }, index) => (
    <HeaderContainer key={index}>
      <Header as="h5">{title}</Header>
    </HeaderContainer>
  ));
};

const renderWizardSteps = steps => {
  return steps.map(step => step.render());
};

const NestedDemo = () => {
  const { steps, headers, navigation } = useWizard({ config });
  return (
    <Container>
      <WizardGif />
      <Header as="h1">Here Will Be Demo Of The Wizard</Header>
      <Header as="h2">
        <span role="img" aria-label="emoji">
          🎢🔮
        </span>
      </Header>
      <Link onClick={navigation.back}>Back</Link>
      <Link onClick={navigation.next}>Next</Link>
      <Wizard>
        <Headers>{renderWizardHeaders(headers)}</Headers>
        <Steps>
          <StepContainer>{renderWizardSteps(steps)}</StepContainer>
        </Steps>
      </Wizard>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Headers = styled.div`
  display: flex;
`;
const HeaderContainer = styled.div`
  border: 1px solid;
  flex: 1 1 auto;
  padding: 10px;
  text-align: center;
`;
const StepContainer = styled.div`
  flex: 1 1 auto;
  height: 100%;
  padding: 10px;
  text-align: center;
`;
const StepComponent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ color }) => color};
`;
const Steps = styled.div`
  flex: 1 0 auto;
`;

const Wizard = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
`;

export default NestedDemo;
