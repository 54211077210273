import { find } from 'lodash';
import { createSelector } from 'reselect';

const getCollegeSavingsPlanner = state => state.collegeSavingsPlanner;

const getCollegeSavingsPlannerCalculatorData = createSelector(
  getCollegeSavingsPlanner,
  collegeSavingsPlanner => collegeSavingsPlanner.calculatorData,
);

export const getAverageTuitions = createSelector(
  getCollegeSavingsPlannerCalculatorData,
  calculatorData => calculatorData.averageTuitions,
);

export const getInstitutionTuitions = createSelector(
  getCollegeSavingsPlannerCalculatorData,
  calculatorData => calculatorData.institutionTuitions,
);

export const getAverageAidByMinIncome = createSelector(
  getCollegeSavingsPlannerCalculatorData,
  calculatorData => calculatorData.averageAidByMinIncome,
);

export const getCollegeSavingsPlannerInputs = createSelector(
  getCollegeSavingsPlanner,
  collegeSavingsPlanner => collegeSavingsPlanner.inputs,
);

export const getCollegeSavingsPlannerResults = createSelector(
  getCollegeSavingsPlanner,
  collegeSavingsPlanner => collegeSavingsPlanner.results,
);

export const getCollegeSavingsPlannerScholarships = createSelector(
  getCollegeSavingsPlanner,
  collegeSavingsPlanner =>
    collegeSavingsPlanner.scholarshipDatabase.scholarships,
);

export const getScholarshipByUid = (state, uid) => {
  const scholarships = getCollegeSavingsPlannerScholarships(state);
  return find(scholarships, { uid }) || null;
};
