import {
  SET_NEXT_STEP,
  SET_PAGE_INFO,
  FINISH_WELCOME,
  SET_CURRENT_STEP,
  GO_BACK_ONBOARDING,
  SET_POST_ONBOARDING_DESTINATION,
} from 'constants/actionTypes';

export const setOnboardingStep = (currentStep, persistStep) => ({
  type: SET_NEXT_STEP,
  payload: {
    currentStep,
  },
  meta: {
    persistStep,
  },
});

export const persistOnboardingStep = currentStep => ({
  type: SET_CURRENT_STEP,
  payload: {
    currentStep,
  },
});

export const goBackOnboarding = page => ({
  type: GO_BACK_ONBOARDING,
  payload: {
    page,
  },
});

export const finishWelcome = () => ({
  type: FINISH_WELCOME,
});

export const setPageInfo = (stepCount, stepMessage) => ({
  type: SET_PAGE_INFO,
  payload: {
    stepCount,
    stepMessage,
  },
});

export const setPostOnboardingDestination = postOnboardingDestination => ({
  type: SET_POST_ONBOARDING_DESTINATION,
  payload: {
    postOnboardingDestination,
  },
});
