import React from 'react';

import { PhoneInput } from '@summer/ui-components';

import { InputWrapper } from 'components/pages/idr/wizard/steps/Submit/shared/Inputs';
import { camelWithPrefix } from 'utils/text';

const ReferencePhone = ({
  prefix,
  formik: { values, errors, setFieldValue, handleBlur, touched, isSubmitting },
}) => {
  const namePrefix = 'submit.';

  const getFormikName = name => namePrefix + camelWithPrefix(prefix, name);
  const getFormikPropertyName = name => camelWithPrefix(prefix, name);

  return (
    <InputWrapper>
      <PhoneInput
        label="Phone Number"
        placeholder="Phone Number"
        name={getFormikName('phone')}
        touched={touched[getFormikPropertyName('phone')]}
        isSubmitting={isSubmitting}
        error={errors[getFormikPropertyName('phone')]}
        value={values[getFormikPropertyName('phone')]}
        onChange={setFieldValue}
        onBlur={handleBlur}
      />
    </InputWrapper>
  );
};

export default ReferencePhone;
