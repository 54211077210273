import React from 'react';

import GetOutOfDefault from 'components/pages/default/recommendation';
import ForgivenessFinderRec from 'components/pages/forgivenessFinder/recommendation';
import IdrRec from 'components/pages/idr/recommendation';
import OverpaymentsRec from 'components/pages/overpayments/recommendation';
import PslfRec from 'components/pages/pslf/recommendation';
import RefiRec from 'components/pages/refinancing/recommendation';

export const getRecCard = (
  cardType,
  { current = false } = {},
  { pending = false } = {},
) => {
  return {
    idr: <IdrRec key="IDR" current={current} pending={pending} />,
    pslf: <PslfRec key="PSLF" current={current} pending={pending} />,
    refi: <RefiRec key="REFI" />,
    overpayments: <OverpaymentsRec key="OVERPAYMENTS" />,
    getOutOfDefault: <GetOutOfDefault key="getOutOfDefault" />,
    forgivenessFinder: <ForgivenessFinderRec key="forgivenessFinder" />,
  }[cardType];
};
