import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  Header,
  Text,
  TextInput,
  Button,
  FlexColumn,
  COLORS,
} from '@summer/ui-components';

import EmailBox from 'components/pages/settings/EmailBox';
import GlobalError from 'components/shared/GlobalError';
import { TabletSize } from 'constants/styleguide';
import { updateEmailValidationSchema } from 'schemas/user';

const id = 'newEmail';

export default class UpdateEmail extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    error: PropTypes.string,
    currentEmail: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
    };

    const { currentEmail } = this.props;
    this.formInitialValues = {
      email: '',
      password: '',
      currentEmail,
    };
  }

  handleSubmit = values => {
    this.props.handleSubmit(values);

    this.setState({
      submitted: true,
    });

    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };

  renderForm = formik => {
    const {
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      touched,
      isSubmitting,
      isValid,
    } = formik;

    const { error } = this.props;
    const { submitted } = this.state;

    return (
      <Form onSubmit={handleSubmit}>
        <HeaderContainer>
          <Header h4 color="secondary">
            Update your Email address
          </Header>
        </HeaderContainer>

        <EmailBox currentEmail={values.currentEmail} />
        <Text bold id={id}>
          {' '}
          New Email{' '}
        </Text>

        <NewEmail>
          <Text light>
            If you change your email address, you&apos;ll have to re-verify
            account.
          </Text>
        </NewEmail>

        {/* Dummy input to disable email autocomplete */}
        <input type="email" name="dummy-email" style={{ display: 'none' }} />

        <TextInput
          name="email"
          touched={touched.email}
          isSubmitting={isSubmitting}
          error={errors.email}
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!error && submitted}
          inputMode="email"
          autoComplete="off"
        />

        <TextInput
          label="Password"
          name="password"
          type="password"
          touched={touched.password}
          isSubmitting={isSubmitting}
          error={errors.password}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!error && submitted}
          autoComplete="current-password"
        />

        <GlobalError label="updateEmail" />

        <Button
          disabled={!isValid || (!error && submitted)}
          isLoading={this.props.isLoading}
          type="submit"
        >
          {!error && submitted ? 'Email updated!' : 'Update'}
        </Button>
      </Form>
    );
  };

  render() {
    return (
      <Formik
        initialValues={this.formInitialValues}
        onSubmit={this.handleSubmit}
        validationSchema={updateEmailValidationSchema}
      >
        {formik => this.renderForm(formik)}
      </Formik>
    );
  }
}

const HeaderContainer = styled.div`
  border-bottom: 1px solid ${COLORS.grey};
  padding-bottom: 8px;
`;

const Form = styled(FlexColumn.withComponent('form'))`
  margin-top: 24px;
  margin-bottom: 24px;
  margin-right: 32px;
  max-width: 355px;

  @media (max-width: ${TabletSize}) {
    margin-right: auto;
    margin-left: auto;
  }
`;

const NewEmail = styled.div`
  margin-top: 8px;
  margin-bottom: 11px;
`;
