import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { MoneyInput } from '@summer/ui-components';

import StaticField from 'components/common/ManageLoans/fields/shared/StaticField';
import InfoTooltip from 'components/shared/InfoTooltip';
import { isLoanFieldEditable } from 'redux/selectors/loans.selectors';
import { dollars } from 'utils/numbers';

const PrincipalBalanceLabel = () => (
  <>
    <span>Principal Balance</span>
    <InfoTooltip
      text="This is the remaining principal balance of your loan. This is how much you currently owe on your loan excluding any interest."
      top={2}
    />
  </>
);

const PrincipalBalance = ({
  formik: {
    values,
    errors,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    touched,
    isSubmitting,
  },
  canEdit,
}) => (
  <Container id="principalBalance">
    <InputContainer>
      {canEdit ? (
        <MoneyInput
          name="principalBalance"
          value={values.principalBalance}
          error={errors.principalBalance}
          touched={touched.principalBalance}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          onBlur={handleBlur}
          isSubmitting={isSubmitting}
          height={40}
          label={<PrincipalBalanceLabel />}
        />
      ) : (
        <StaticField
          label={<PrincipalBalanceLabel />}
          value={dollars(values.principalBalance)}
        />
      )}
    </InputContainer>
  </Container>
);

const Container = styled.div`
  flex: 1;
`;

const InputContainer = styled.div`
  width: 100%;
`;

const mapStateToProps = (state, props) => {
  const loanId = props.formik.values.id;
  return { canEdit: isLoanFieldEditable(state, loanId, 'principalBalance') };
};

export default connect(mapStateToProps)(PrincipalBalance);
