import { format } from 'date-fns';

export const PSLF_TOTAL_REQUIRED_PAYMENTS = 120;

export const pslfWaiverExpiryDate = new Date('10/31/2022');
export const pslfWaiverExpiryDateText = format(
  pslfWaiverExpiryDate,
  'MMMM dd, yyyy',
);

export const pslfWaiverSummerDueDate = new Date('10/15/2022');
export const pslfWaiverSummerDueDateText = format(
  pslfWaiverSummerDueDate,
  'MMMM dd, yyyy',
);

export const GOVERNMENT_ORG = 'government org';
export const NON_PROFIT_501C3 = '501c3 non-profit';
export const NON_PROFIT_OTHER = 'non-501c3 non-profit';
export const PARTISAN_POLITICAL_ORG = 'partisan political org';
export const LABOR_UNION = 'labor union';
export const NONE_OF_THE_ABOVE = 'none of the above';

export const employerTypeOptions = [
  { value: GOVERNMENT_ORG, label: 'Government organization' },
  { value: NON_PROFIT_501C3, label: '501(c)(3) non-profit organization' },
  {
    value: NON_PROFIT_OTHER,
    label: 'Private non-profit that is NOT a 501(c)(3)',
  },
  { value: NONE_OF_THE_ABOVE, label: 'None of the above' },
];

export const employerNonProfitTypeOptions = [
  {
    value: PARTISAN_POLITICAL_ORG,
    label: 'Partisan political organization',
  },
  { value: LABOR_UNION, label: 'Labor union' },
  { value: NONE_OF_THE_ABOVE, label: 'None of the above' },
];

export const pslfReadinessOptions = [
  {
    value: 'true',
    label:
      'YES: I\u2019m ready for forgiveness and want to request a forbearance while my application is processed.',
  },
  {
    value: 'false',
    label:
      'NO: I\u2019m not ready for forgiveness yet, or I don\u2019t want to request a forbearance.',
  },
];

// TODO: [ch9914]: Make consistent with server spec
export const employerPrimaryPurposeOptions = [
  {
    name: 'emergency management',
    label: 'Emergency management',
  },
  {
    name: 'public service for the elderly',
    label: 'Public service for the elderly',
  },
  { name: 'military service', label: 'Military service' },
  { name: 'public health', label: 'Public health' },
  { name: 'public safety', label: 'Public safety' },
  { name: 'public education', label: 'Public education' },
  { name: 'law enforcement', label: 'Law enforcement' },
  {
    name: 'public library services',
    label: 'Public library services',
  },
  {
    name: 'public interest legal services',
    label: 'Public interest legal services',
  },
  {
    name: 'school library services',
    label: 'School library services',
  },
  {
    name: 'early childhood education',
    label: 'Early childhood education',
  },
  {
    name: 'other school-based services',
    label: 'Other school-based services',
  },
  {
    name: 'public services for individuals with disabilities',
    label: 'Public services for individuals with disabilities',
  },
  { name: 'none of the above', label: 'None of the above' },
];

export const hideSubmitSidebarSize = '1240px';

export const pslfIneligibleLoansCopy = `Payments you\u2019ve already made on Parent PLUS loans don\u2019t
qualify for PSLF, but they can qualify going forward once they\u2019re
consolidated into Direct loans. We can help you consolidate your loans and
enroll in a qualifying repayment plan for PSLF.`;

export const qualifiesOnlyWithConsolidationCopy =
  'Past payments on this type of loan qualify if it is consolidated.';
export const doesQualifyCopy =
  'This type of loan qualifies for past and future payments.';
export const doesNotQualifyCopy =
  'Past payments on this type of loan do not qualify for PSLF, but future payments may qualify if it is consolidated.';
