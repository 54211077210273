import _, { isEmpty, reject } from 'lodash';
import fp from 'lodash/fp';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  Button,
  COLORS,
  Cross,
  FlexRow,
  Header,
  Icon,
  IconMedallion,
  Pencil,
  Text,
  Trash,
} from '@summer/ui-components';

import ModalPopup from 'components/shared/ModalPopup';
import ServicerLogoOrName from 'components/shared/Servicer/ServicerLogoOrName';
import { Background } from 'components/shared/dashboard/LandingPage/LandingPageHeader';
import { MobileSize } from 'constants/styleguide';
import { MOBILE, usePlatform } from 'hooks/platform';
import { deletePlaidIntegration } from 'redux/actions/plaid.actions';
import { isLoading } from 'redux/selectors/ui.selectors';

const ModalContent = ({ openPlaid, plaidLoans, isLoading }) => {
  const [servicerToDisconnect, setServicerToDisconnect] = useState(null);
  const dispatch = useDispatch();

  const deleteLoan = plaidIntegrationUuid => {
    dispatch(deletePlaidIntegration(plaidIntegrationUuid));
  };

  const getServicer = servicer => {
    const { institutionId, servicerName } = servicer;
    const disconnectSelected =
      servicerToDisconnect === servicer.userIntegrationUuid;

    return (
      <ServicerLogoOrNameWrapper disconnectSelected={disconnectSelected}>
        <ServicerLogoOrName
          institutionId={institutionId}
          servicerName={servicerName}
        />
      </ServicerLogoOrNameWrapper>
    );
  };

  const { platformType } = usePlatform();
  const isMobile = platformType === MOBILE;

  const renderServicers = (servicers, isFederal) => {
    if (isEmpty(servicers)) {
      return null;
    }

    return (
      <>
        <LoanTypeLabels h3>
          {isFederal ? 'Federal' : 'Private'} Loans
        </LoanTypeLabels>
        {servicers.map((servicer, key) => {
          const disconnectSelected =
            servicerToDisconnect === servicer.userIntegrationUuid;

          return (
            <Servicer key={key} disconnectSelected={disconnectSelected}>
              {getServicer(servicer)}
              <DisconnectSection disconnectSelected={disconnectSelected}>
                {disconnectSelected && (
                  <>
                    <AreYouSureTitle>
                      <Text large bold>
                        {`Are you sure you want to disconnect your ${servicer.servicerName} loans?`}
                      </Text>
                    </AreYouSureTitle>
                    <Text paragraph>
                      If you disconnect, we may no longer be able to alert you
                      to changes or updates as they come in.
                    </Text>
                  </>
                )}
                <DisconnectButtonSection
                  disconnectSelected={disconnectSelected}
                >
                  {disconnectSelected && (
                    <CancelText
                      bold
                      onClick={() => setServicerToDisconnect(null)}
                      disconnectSelected={disconnectSelected}
                    >
                      Cancel
                    </CancelText>
                  )}
                  <DeleteButton
                    secondary
                    isLoading={isLoading}
                    width={disconnectSelected ? 174 : 144}
                    onClick={() => {
                      const { userIntegrationUuid } = servicer;
                      if (disconnectSelected) {
                        deleteLoan(userIntegrationUuid);
                      } else {
                        setServicerToDisconnect(userIntegrationUuid);
                      }
                    }}
                  >
                    <DeleteButtonContent>
                      <Icon
                        SvgComponent={Trash}
                        fill={COLORS.orange}
                        width={22}
                        height={22}
                        style={{ marginRight: '8px', cursor: 'pointer' }}
                      />
                      <DeleteButtonText>
                        <Text as="label" bold color="error">
                          {disconnectSelected ? ' Yes, ' : ''}Disconnect
                        </Text>
                      </DeleteButtonText>
                    </DeleteButtonContent>
                  </DeleteButton>
                </DisconnectButtonSection>
              </DisconnectSection>
            </Servicer>
          );
        })}
      </>
    );
  };

  const federalServicers = _.flow(
    fp.filter('isFederal'),
    fp.uniqBy('servicerName'),
  )(plaidLoans);

  let privateServicers = _.flow(
    fp.reject('isFederal'),
    fp.uniqBy('servicerName'),
  )(plaidLoans);

  // Do not duplicate the servicer under private servicer if it is already a federal servicer
  const federalServicerNames = federalServicers.map(s => s.servicerName);
  privateServicers = reject(privateServicers, s =>
    federalServicerNames.includes(s.servicerName),
  );

  return (
    <div>
      <HeaderSection>
        <Background headerTheme="grey">
          <HeaderContainer>
            <PencilIcon SvgComponent={Pencil} color={COLORS.darkerGrey} />
            <Header as={isMobile ? 'h3' : 'h1'}>
              Manage your student loan accounts
            </Header>
          </HeaderContainer>
        </Background>
      </HeaderSection>
      <ConnectButtonContainer>
        <ConnectButton width={255} secondary onClick={openPlaid}>
          <ConnectButtonContent>
            <Icon
              SvgComponent={Cross}
              fill={COLORS.jade}
              width={22}
              height={22}
              style={{ marginRight: '8px', cursor: 'pointer' }}
            />
            <ConnectButtonText>
              <Text as="label" bold>
                Connect Another Account
              </Text>
            </ConnectButtonText>
          </ConnectButtonContent>
        </ConnectButton>
      </ConnectButtonContainer>
      {renderServicers(federalServicers, true)}
      {renderServicers(privateServicers, false)}
    </div>
  );
};

const ManageLoansButton = ({ openPlaid, plaidLoans, isLoading }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ModalPopup
        isOpen={isOpen}
        togglePopup={() => setIsOpen(false)}
        kind="Manage Plaid Loans"
        width={1020}
        component={
          <ModalContent
            openPlaid={openPlaid}
            plaidLoans={plaidLoans}
            isLoading={isLoading}
          />
        }
        closeZIndex={1}
      />
      <Button secondary onClick={() => setIsOpen(true)} width={212}>
        Manage Loan Accounts
      </Button>
    </>
  );
};

const HeaderSection = styled.div`
  margin-top: -56px;
  margin-left: -40px;
  margin-right: -40px;

  & > div:first-child {
    position: relative;
    height: fit-content;
  }
`;

const HeaderContainer = styled(FlexRow)`
  padding: 40px 48px;

  & > * {
    z-index: 2; // put on top of header background
  }

  @media (max-width: ${MobileSize}) {
    padding: 24px 36px;
  }
`;

const PencilIcon = styled(IconMedallion)`
  margin-right: 16px;

  & path {
    stroke: none;
  }
`;

const ConnectButtonContainer = styled(FlexRow)`
  margin-top: 28px;
  justify-content: flex-end;
`;

const ConnectButton = styled(Button)`
  border: 1px solid #dfe3e8;
`;

const ConnectButtonContent = styled(FlexRow)`
  justify-content: center;
`;

const ConnectButtonText = styled.div`
  margin: auto 0;

  & .text:hover {
    cursor: pointer;
  }
`;

const LoanTypeLabels = styled(Header)`
  @media (min-width: ${MobileSize}) {
    &:first-of-type {
      margin-top: -24px;
    }
  }

  margin-top: 24px;
`;

const Servicer = styled(FlexRow)`
  margin: 11px 0 0;
  padding: 24px;
  border-radius: 3px;
  border: solid 1px #dfe3e8;
  justify-content: space-between;

  @media (max-width: ${MobileSize}) {
    ${props => props.disconnectSelected && 'flex-direction: column;'}
  }
`;

const ServicerLogoOrNameWrapper = styled(Text)`
  ${props =>
    props.disconnectSelected
      ? 'margin-top: 0;'
      : 'margin-top: auto; margin-bottom: auto;'}
`;

const DisconnectSection = styled.div`
  margin-left: 16px;
  ${props =>
    !props.disconnectSelected && 'margin-top: auto; margin-bottom: auto;'}

  @media (max-width: ${MobileSize}) {
    ${props => props.disconnectSelected && 'margin-top: 16px;'}
    margin-left: 0;
  }
`;

const DisconnectButtonSection = styled(FlexRow)`
  justify-content: flex-end;

  ${props =>
    props.disconnectSelected &&
    `
    margin-top: 16px;

    @media (max-width: ${MobileSize}) {
      flex-direction: column-reverse;
    }
  `}
`;

const AreYouSureTitle = styled.div`
  margin-bottom: 8px;
`;

const CancelText = styled(Text)`
  margin: auto 12px;

  &:hover {
    cursor: pointer;
  }

  ${props =>
    props.disconnectSelected &&
    `
    @media (max-width: ${MobileSize}) {
      margin-top: 16px;
      text-align: center;
    }
    `}
`;

const DeleteButton = styled(Button)`
  display: flex;
  flex-direction: row;
  border: 1px solid #dfe3e8;
`;

const DeleteButtonContent = styled(FlexRow)`
  justify-content: center;
`;

const DeleteButtonText = styled.div`
  margin-top: auto;

  & .text:hover {
    cursor: pointer;
  }
`;
const mapStateToProps = state => ({
  isLoading: isLoading(state, 'deletePlaidIntegration'),
});

export default connect(mapStateToProps)(ManageLoansButton);
