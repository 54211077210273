import React from 'react';
import styled from 'styled-components';

import { FlexColumn, COLORS } from '@summer/ui-components';

import CurrentPaymentInfo from 'components/pages/overpayments/dashboard/OverpaymentsTabs/Results/CurrentPaymentInfo';
import OverpaymentInfo from 'components/pages/overpayments/dashboard/OverpaymentsTabs/Results/OverpaymentInfo';
import SliderInfo from 'components/pages/overpayments/dashboard/OverpaymentsTabs/Results/SliderInfo';
import { MobileSize } from 'constants/styleguide';

const Results = ({ values, setFieldValue, sliderMax }) => {
  return (
    <ResultsContainer>
      <CurrentPaymentInfo values={values} />
      <Divider />
      <SliderInfo
        values={values}
        setFieldValue={setFieldValue}
        sliderMax={sliderMax}
      />
      <Divider />
      <OverpaymentInfo values={values} />
    </ResultsContainer>
  );
};

const ResultsContainer = styled(FlexColumn)`
  padding: 8px 32px 0px;
  @media (max-width: ${MobileSize}) {
    padding: 8px 16px 0px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border: solid 1px ${COLORS.lighterGrey};
`;

export default React.memo(Results);
