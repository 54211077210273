import React from 'react';
import styled from 'styled-components';

import { FlexCenter, Header, Text } from '@summer/ui-components';

import SecurityFooter from 'components/pages/onboarding/shared/SecurityFooter';
import SecurityHeader from 'components/pages/onboarding/shared/SecurityHeader';

const ADPSSOErrorPage = () => (
  <>
    <Container>
      <SecurityHeader />
      <HeaderBox>
        <Header center>Something went wrong</Header>
      </HeaderBox>
      <Box>
        <Text large paragraph align="center">
          We were not able to log you into your account. Please return to ADP
          and try again.
        </Text>
      </Box>
    </Container>
    <SecurityFooter />
  </>
);

const Box = styled.div`
  margin-bottom: 24px;
`;

const Container = styled(FlexCenter)`
  max-width: 480px;
  width: 100%;

  padding: 0 24px 72px;

  flex-shrink: 0;
  flex-direction: column;
`;

const HeaderBox = styled.div`
  margin-bottom: 24px;
`;

export default ADPSSOErrorPage;
