import { push as redirectTo } from 'connected-react-router';
import { Formik } from 'formik';
import React from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import styled from 'styled-components';

import { Button, COLORS, Text } from '@summer/ui-components/src';

import {
  setCSPInputs,
  postCSPCalculatorResults,
} from 'redux/actions/collegeSavingsPlanner.actions';
import { getCollegeSavingsPlannerInputs } from 'redux/selectors/collegeSavingsPlanner.selectors';
import { getErrorMessage } from 'redux/selectors/ui.selectors';
import { collegeSavingsPlannerSchema } from 'schemas/collegeSavingsPlanner';
import { scrollToError } from 'utils/formik';

import CollegeInformation from './CollegeInformation';
import CurrentFutureSavings from './CurrentFutureSavings';
import ExpectedAid from './ExpectedAid';

const resultsPath = '/college-savings-planner/calculator/results';

const YourInformation = ({
  errorMessage,
  enableResults = () => {},
  scrollToTop,
}) => {
  const dispatch = useDispatch();

  const initialValues = useSelector(getCollegeSavingsPlannerInputs);

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(setCSPInputs(values));
    const onSuccess = () => {
      enableResults();

      scrollToTop();

      dispatch(redirectTo(resultsPath));
    };

    const onFailure = () => {
      setSubmitting(false);
    };

    const calculateResultsPayload = {
      yearlyTuition: values.yearlyTuition,
      startYear: values.startYear,
      attendanceDuration: values.attendanceDuration,
      costIncrease: values.costIncrease,
      currentSavings: values.currentSavings,
      plannedContribution: values.plannedContribution,
      rateOfReturn: values.rateOfReturn,
      expectedAid: values.expectedAid,
    };

    dispatch(
      postCSPCalculatorResults(calculateResultsPayload, {
        onSuccess,
        onFailure,
      }),
    );
  };

  return (
    <ContentContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={collegeSavingsPlannerSchema}
      >
        {formik => {
          const { errors, handleSubmit, isSubmitting, isValid, submitCount } =
            formik;

          return (
            <form onSubmit={handleSubmit}>
              <section>
                <CollegeInformation formik={formik} />
              </section>
              <section>
                <CurrentFutureSavings formik={formik} />
              </section>
              <section>
                <ExpectedAid formik={formik} />
              </section>

              {errorMessage && (
                <ErrorText color="error" size="small">
                  An error has occurred, please try again later
                </ErrorText>
              )}
              <GetResultContainer>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  fakeDisabled={!isValid && submitCount > 0}
                  onClick={() => {
                    scrollToError(errors);
                  }}
                >
                  See results
                </Button>
              </GetResultContainer>
            </form>
          );
        }}
      </Formik>
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  padding: 24px 32px 40px 32px;

  h3 {
    margin-bottom: 16px;
  }

  section {
    padding-bottom: 24px;

    &:not(:first-child) {
      padding-top: 48px;
      border-top: 1px solid ${COLORS.medGrey};
    }
  }
`;

const GetResultContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const ErrorText = styled(Text)`
  margin-bottom: 5px;
`;

const mapStateToProps = state => ({
  errorMessage: getErrorMessage(state, 'postCSPCalculatorResults'),
});

export default connect(mapStateToProps)(YourInformation);
