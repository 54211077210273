import React from 'react';

import {
  GraduationCap,
  PiggyBank,
  HeartHand,
  Graph,
  PercentBubble,
} from '@summer/ui-components';

export const learningGuides = [
  {
    to: '/guides/recent-graduates',
    icon: GraduationCap,
    headerText: 'Recent Graduates Guide',
    contentText:
      'Learn the basics of student loans, from understanding interest to saving money while you repay.',
  },
  {
    to: '/guides/idr',
    icon: PiggyBank,
    headerText: 'Income-Driven Repayment Plan',
    contentText:
      'This is a government program that allows some borrowers to pay less each month.',
  },
  {
    to: '/guides/pslf',
    icon: HeartHand,
    headerText: 'Public Service Loan Forgiveness',
    contentText: 'Some jobs qualify for government forgiveness after 10 years.',
  },
  {
    to: '/guides/accrued-interest',
    icon: Graph,
    headerText: 'Save Hundreds by Paying Down Accrued Interest',
    contentText: (
      <>
        It&rsquo;s a confusing topic but we&rsquo;ll explain how paying it can
        save you money.
      </>
    ),
  },
  {
    to: '/guides/refinancing',
    icon: PercentBubble,
    headerText: 'Refinancing',
    contentText:
      'With good credit, a high salary, or a co-signer, refinancing could help you save.',
  },
];
