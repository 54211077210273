import { push as redirectTo } from 'connected-react-router';
import { Formik, Form } from 'formik';
import { Persist } from 'formik-persist';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Container, TabGroup } from '@summer/ui-components';

import Results from 'components/pages/overpayments/dashboard/OverpaymentsTabs/Results';
import YourLoans from 'components/pages/overpayments/dashboard/OverpaymentsTabs/YourLoans';
import PrivacyPolicyLink from 'components/shared/PrivacyPolicyLink';
import { MobileSize } from 'constants/styleguide';
import { yourLoansSchema } from 'schemas/overpayments';
import {
  setCurrentPaymentValues,
  setOverpaymentValues,
} from 'utils/overpayments';

const OverpaymentsForm = ({ formik }) => {
  const { values, errors, setFieldValue, handleSubmit, isValid, submitCount } =
    formik;
  const { currentBalance, currentMonthlyPayment, interestRate } = values;

  const sliderMax = useMemo(
    () =>
      Math.min(
        Math.floor(
          (currentBalance * (1 + interestRate / 12) - currentMonthlyPayment) /
            100,
        ),
        1000,
      ),
    [currentBalance, interestRate, currentMonthlyPayment],
  );

  const defaultOverpayment = useMemo(
    () =>
      sliderMax < 100
        ? (sliderMax / 2) * 100
        : Math.min(sliderMax * 100, 10000),
    [sliderMax],
  );

  return (
    <TabGroup
      baseUrl="/overpayments"
      tabs={[
        {
          title: 'Your Loans',
          content: (
            <Form
              onSubmit={e => {
                setFieldValue('overpaymentAmount', defaultOverpayment);
                if (isEmpty(errors)) {
                  setCurrentPaymentValues(values, setFieldValue);
                  setOverpaymentValues(
                    defaultOverpayment,
                    values,
                    setFieldValue,
                  );
                }
                handleSubmit(e);
              }}
            >
              <YourLoans formik={formik} />
              <Persist name="overpayments-your-loans" />
            </Form>
          ),
        },
        {
          title: 'Results',
          content: (
            <Results
              values={values}
              setFieldValue={setFieldValue}
              sliderMax={sliderMax}
            />
          ),
          url: '/results',
          disabled: !isValid || submitCount < 1,
        },
      ]}
    />
  );
};

const OverpaymentsTabs = () => {
  const dispatch = useDispatch();

  return (
    <Container>
      <TabsWrapper>
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            currentBalance: null,
            interestRate: null,
            currentMonthlyPayment: null,
            overpaymentAmount: 10000,
          }}
          validationSchema={yourLoansSchema}
          onSubmit={() => {
            dispatch(redirectTo('/overpayments/results'));
            const tab = document.getElementById(`styled-tab-list`);
            tab.scrollIntoView({ behavior: 'smooth' });
          }}
        >
          {formik => <OverpaymentsForm formik={formik} />}
        </Formik>
      </TabsWrapper>
      <PrivacyPolicyLinkOverpaymentsPage />
    </Container>
  );
};

const TabsWrapper = styled.div`
  height: 100%;
  width: 100%;
  max-width: 738px;
`;

const PrivacyPolicyLinkOverpaymentsPage = styled(PrivacyPolicyLink)`
  justify-content: flex-start;
  text-align: left;
  padding: 30px 40px 0px;

  @media (max-width: ${MobileSize}) {
    padding: 30px 24px 0px;
  }
`;

export default OverpaymentsTabs;
