import React from 'react';

import { InfoTooltip } from '@summer/ui-components/src';

import { useClearFieldOnUnmount } from 'hooks/formik';

import { GridContainer, MoneyInputCSP } from '../shared';

const EnterExpectedAmount = ({ formik }) => {
  useClearFieldOnUnmount('expectedAid', null, formik.setFieldValue);

  return (
    <GridContainer>
      <MoneyInputCSP
        name="expectedAid"
        label={
          <>
            How much financial aid and scholarships do you expect to receive?{' '}
            <InfoTooltip text="This is an estimate of the annual aid and scholarships you or your student might received based on salary and school-specific data." />
          </>
        }
        formik={formik}
      />
    </GridContainer>
  );
};

export default EnterExpectedAmount;
