import format from 'date-fns/format';
import { map, slice } from 'lodash';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import {
  Cross,
  Text,
  GuideCard,
  COLORS,
  Collapse,
} from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import Page from 'components/shared/dashboard/Page';
import { Link } from 'components/shared/typography';
import { blogLink } from 'constants/externalLinks/marketingSite';
import { helpCenterLink } from 'constants/externalLinks/support';
import {
  CollapseSidebarSize,
  MobileSize,
  TabletSize,
} from 'constants/styleguide';
import { useGetLastestPosts } from 'services/blog';

import { learningGuides } from './cardsContent';

const ResourceCenterHeader = () => (
  <Text as="h1" color="secondary" weight="medium">
    Resource Center
  </Text>
);
const blogPostsCards = posts => {
  return map(posts, (post, idx) => {
    const { title, link, pubDate } = post;
    return (
      <GuideCard
        headerText={title}
        href={link}
        type="blogpost"
        key={idx}
        footerText={format(new Date(pubDate), 'EEEE, MMM d, yyyy')}
      />
    );
  });
};

const ResourceCenter = ({ windowWidth, platformType }) => {
  const isMobile = platformType === MOBILE;
  const showDesktopHeader = useMemo(
    () => windowWidth > parseInt(CollapseSidebarSize, 10),
    [windowWidth],
  );

  const latestSummerPosts = useGetLastestPosts();

  return (
    <>
      <Page
        themeColor={COLORS.covidGreen}
        icon={Cross}
        renderPageHeader={() => (
          <BlogContent>
            {showDesktopHeader && <ResourceCenterHeader />}
            <GuideCardList>
              {blogPostsCards(
                isMobile ? slice(latestSummerPosts, 0, 3) : latestSummerPosts,
              )}
            </GuideCardList>

            {isMobile && (
              <Collapse
                reverse
                trigger={({ openState }) => (
                  <CollapseTrigger>
                    <Text center>
                      <Link>{openState ? 'Show Less' : 'Show More'}</Link>
                    </Text>
                  </CollapseTrigger>
                )}
              >
                <GuideCardList>
                  {blogPostsCards(
                    slice(latestSummerPosts, 3, latestSummerPosts.length),
                  )}
                </GuideCardList>
              </Collapse>
            )}
            <SearchMore>
              <Text>
                <Text as="span" bold>
                  For more information,{' '}
                </Text>
                search our{' '}
                <Link track underline inheritColor href={blogLink}>
                  blog
                </Link>{' '}
                or{' '}
                <Link track underline inheritColor href={helpCenterLink}>
                  help center
                </Link>
              </Text>
            </SearchMore>
          </BlogContent>
        )}
      >
        <Container>
          <Text as="h2" color="secondary" align="left">
            Additional Guides
          </Text>

          <GuideCardList>
            {map(learningGuides, (props, idx) => (
              <GuideCard {...props} key={idx} />
            ))}
          </GuideCardList>
        </Container>
      </Page>
    </>
  );
};

const BlogContent = styled.section`
  padding-top: 48px;
  padding-bottom: 32px;

  * {
    z-index: 1;
  }

  & > .text {
    max-width: 500px;

    :last-child {
      margin-bottom: 32px;
    }
  }

  @media (max-width: ${TabletSize}) {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  @media (max-width: ${MobileSize}) {
    padding-top: 0;
    padding-bottom: 32px;
  }
`;

const Container = styled.section`
  margin-top: 32px;
`;

const GuideCardList = styled.div`
  padding-top: 16px;
  display: grid;
  align-items: stretch;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;

  @media (max-width: ${TabletSize}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${MobileSize}) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`;

const CollapseTrigger = styled.div`
  margin-top: 16px;
`;

const SearchMore = styled.div`
  padding-top: 28px;

  @media (max-width: ${MobileSize}) {
    text-align: center;
    padding-top: 20px;
  }
`;

export default Platform(ResourceCenter);
