import { map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ForgivenessProgramItem from 'components/pages/forgivenessFinder/components/ForgivenessProgramItem';
import { MobileSize } from 'constants/styleguide';

const ForgivenessProgramsList = ({
  forgivenessPrograms,
  handleProgramClick,
}) => {
  return (
    <Box>
      {map(forgivenessPrograms, ({ title, award, description, uid }) => {
        return (
          <ForgivenessProgramItem
            key={uid}
            uid={uid}
            award={award}
            description={description}
            title={title}
            onClick={handleProgramClick}
          />
        );
      })}
    </Box>
  );
};

const Box = styled.div`
  & > div {
    margin-bottom: 16px;
  }

  @media (max-width: ${MobileSize}) {
    & > div {
      margin-bottom: 0;
    }
  }
`;

ForgivenessProgramsList.defaultProps = {
  forgivenessPrograms: [
    {
      uid: '1',
      title: 'New Mexico Teacher Loan Repayment Program',
      description:
        'For teachers in designated high-need teacher positions in public schools in New Mexico.',
      award: 'Awards based on application ',
    },
    {
      uid: '2',
      title: 'Public Service Loan Forgiveness',
      description:
        'If you’ve worked in public service or a non-profit organization, then you could qualify for Public Service Loan Forgiveness.',
      award: '100% of loan balance after 10 years',
    },
    {
      uid: '3',
      title: 'Teacher Student Loan Forgiveness',
      description:
        'Get your federal student loans forgiven if you teach for five consecutive years in an eligible school or service agency.',
      award: 'up to $17,500',
    },
  ],
};

ForgivenessProgramsList.propTypes = {
  forgivenessPrograms: PropTypes.array.isRequired,
  handleProgramClick: PropTypes.func.isRequired,
};

export default ForgivenessProgramsList;
