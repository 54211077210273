import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Alert, Strong } from '@summer/ui-components';

import { getNumCompletedPartTimePslfForms } from 'redux/selectors/pslf.selectors';
import { getCompletedPslfSignRequests } from 'redux/selectors/signatureRequests.selectors';

const PartTimeAlert = ({ numCompletedForms, numCompletedPartTimeForms }) => {
  if (numCompletedForms === 1 && numCompletedPartTimeForms === 1) {
    return (
      <Container>
        <Alert theme="warning" dismissable={false}>
          <Strong>Certify your other part-time employment.</Strong> To qualify
          for PSLF, your employment needs to add up to over 30 hours a week. You
          can complete another form for your other part-time employer and Summer
          will submit both forms together.
        </Alert>
      </Container>
    );
  }
  return null;
};

const Container = styled.div`
  margin-bottom: 16px;
`;

const mapStateToProps = state => ({
  numCompletedPartTimeForms: getNumCompletedPartTimePslfForms(state),
  numCompletedForms: get(getCompletedPslfSignRequests(state), 'length'),
});

export default connect(mapStateToProps)(PartTimeAlert);
