import React from 'react';
import styled from 'styled-components';

import Cleave from 'cleave.js/react';
import { Field } from 'formik';

import InputContainer, { FormInput, FormInputStyle } from '../Input';
import { COLORS } from '../../../helpers/constants/styleguide';
import { FlexRow } from '../../Flex';

class PercentageInput extends React.Component {
  state = {
    initValue:
      this.props.value || this.props.value === 0
        ? Number((this.props.value * 100).toFixed(2))
        : '',
  };

  cleave = null;

  // this code allows formik.resetForm() to work correctly
  componentDidUpdate(prevProps) {
    const { value, setFieldTouched, name } = this.props;
    if (
      prevProps.value !== value &&
      value !== this.cleave.getRawValue() / 100
    ) {
      this.cleave.setRawValue(value || value === 0 ? value * 100 : '');
      setFieldTouched(name, false);
    }
  }

  setFormikValue = event => {
    const { setFieldValue, name, setFieldTouched } = this.props;
    if (event.target.rawValue === '') {
      setFieldValue(name, '');
    } else {
      const valueInPercent = event.target.rawValue;
      const valueInDecimal = valueInPercent / 100;
      setFieldValue(name, valueInDecimal);
    }

    setTimeout(() => setFieldTouched(name));
  };

  renderInputText = (handleFocusChange, focus) => {
    const { placeholder, name, onBlur, validate } = this.props;
    return (
      <Container>
        <Field name={name} placeholder={placeholder} validate={validate}>
          {() => (
            <CleaveComponent
              inputMode="decimal"
              data-testid={`input-text-${name}`}
              id={`${name}-input`}
              name={name}
              value={this.state.initValue}
              onInit={cleave => {
                this.cleave = cleave;
              }}
              onChange={this.setFormikValue}
              options={{
                numeral: true,
                numeralIntegerScale: 2,
                numeralPositiveOnly: true,
              }}
              onFocus={handleFocusChange}
              onBlur={e => {
                handleFocusChange();
                onBlur(e);
              }}
            />
          )}
        </Field>
        <Symbol focus={focus}>%</Symbol>
      </Container>
    );
  };

  render() {
    const { error, touched, height, isSubmitting, label, helpMessage, name } =
      this.props;
    return (
      <InputContainer
        id={name}
        error={error}
        touched={touched}
        height={height}
        isSubmitting={isSubmitting}
        label={label}
        helpMessage={helpMessage}
      >
        {this.renderInputText}
      </InputContainer>
    );
  }
}

const Container = styled(FlexRow)`
  height: 100%;
  align-items: center;
`;

const CleaveComponent = styled(FormInput.withComponent(Cleave))`
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  caret-color: ${COLORS.darkerGrey};
  -moz-appearance: textfield;

  &:focus {
    outline: none;
  }
`;

const Symbol = styled(FormInputStyle)`
  margin-right: 18px;
  color: ${({ focus }) => (!focus ? COLORS.medGrey : '')};
`;

export default PercentageInput;
