import {
  NETWORK_SUCCESS,
  FETCH_EMERGENCY_SAVINGS_V1,
  POST_EMERGENCY_SAVINGS_V1,
  NETWORK_FAILURE,
} from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import { setEmergencySavings } from 'redux/actions/emergencySavings.actions';
import handleSideEffects from 'redux/handleSideEffects';

const emergencySavingsMiddleware = handleSideEffects({
  [FETCH_EMERGENCY_SAVINGS_V1]: (dispatch, { meta }) => {
    dispatch(
      apiRequest({
        url: '/v1/emergency-savings',
        method: 'GET',
        fromAction: FETCH_EMERGENCY_SAVINGS_V1,
        label: 'fetchEmergencySavingsV1',
        meta,
      }),
    );
  },

  [POST_EMERGENCY_SAVINGS_V1]: (dispatch, { payload, meta }) => {
    dispatch(
      apiRequest({
        url: '/v1/emergency-savings',
        method: 'POST',
        body: {
          ...payload,
        },
        fromAction: POST_EMERGENCY_SAVINGS_V1,
        label: 'postEmergencySavingsV1',
        meta,
      }),
    );
  },

  [NETWORK_SUCCESS]: (dispatch, { payload, meta }) => {
    if (meta.fromAction === FETCH_EMERGENCY_SAVINGS_V1) {
      dispatch(setEmergencySavings(payload.response.data));
    }

    if (meta.fromAction === POST_EMERGENCY_SAVINGS_V1 && meta.onSuccess) {
      dispatch(setEmergencySavings(payload.response.data));
      meta.onSuccess();
    }
  },

  [NETWORK_FAILURE]: (_, { meta }) => {
    if (meta.fromAction === POST_EMERGENCY_SAVINGS_V1) {
      if (meta.onFailure) {
        meta.onFailure();
      }
    }
  },
});

export default emergencySavingsMiddleware;
