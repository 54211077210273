import {
  CMS_SUCCESS,
  FETCH_ALL_FF_PROGRAMS,
  FETCH_FF_PROGRAM_BY_UID,
} from 'constants/actionTypes';
import { fetchDocByUid, fetchDocsByType } from 'redux/actions/cms.actions';
import { setPrograms } from 'redux/actions/forgivenessFinder.actions';
import handleSideEffects from 'redux/handleSideEffects';

const CMS_TYPE = 'forgiveness_programs';

const forgivenessFinderMiddleware = handleSideEffects({
  [FETCH_ALL_FF_PROGRAMS]: (dispatch, { payload: { page, pageSize } }) => {
    dispatch(
      fetchDocsByType({
        type: CMS_TYPE,
        page,
        pageSize,
        fromAction: FETCH_ALL_FF_PROGRAMS,
      }),
    );
  },
  [FETCH_FF_PROGRAM_BY_UID]: (dispatch, { payload: { uid } }) => {
    dispatch(
      fetchDocByUid({
        type: CMS_TYPE,
        uid,
        fromAction: FETCH_FF_PROGRAM_BY_UID,
      }),
    );
  },

  [CMS_SUCCESS]: (dispatch, { payload, meta }) => {
    if (meta.fromAction === FETCH_ALL_FF_PROGRAMS) {
      const docs = payload.response.results;
      dispatch(setPrograms(docs));
    }

    if (meta.fromAction === FETCH_FF_PROGRAM_BY_UID) {
      const docs = [payload.response];
      dispatch(setPrograms(docs));
    }
  },
});

export default forgivenessFinderMiddleware;
