/* eslint-disable no-console */
import {
  CMS_FETCH_DOC_BY_UID,
  CMS_FETCH_DOCS_BY_TYPE,
} from 'constants/actionTypes';
import { cmsFailure, cmsSuccess } from 'redux/actions/cms.actions';
import {
  finishLoading,
  showServerError,
  startLoading,
} from 'redux/actions/ui.actions';
import handleSideEffects from 'redux/handleSideEffects';
import cms from 'services/cms';

const onSuccess = ({ dispatch, response, meta }) => {
  dispatch(
    cmsSuccess({
      response,
      meta: {
        ...meta,
      },
    }),
  );

  if (meta.label) {
    dispatch(finishLoading({ label: meta.label }));
  }
};

const onFailure = ({ dispatch, error, meta }) => {
  const { fromAction } = meta;
  console.log(error);
  console.log(`Error from Action:${fromAction}`);
  dispatch(
    cmsFailure({
      error,
      meta: {
        ...meta,
      },
    }),
  );

  if (meta.label) {
    dispatch(
      showServerError({
        label: meta.label,
        error: {
          message: error.response.data.message,
          status: error.response.status,
        },
      }),
    );
    dispatch(finishLoading({ label: meta.label }));
  }
};

const cmsMiddleware = handleSideEffects({
  [CMS_FETCH_DOCS_BY_TYPE]: (dispatch, { payload, meta }) => {
    const { type, page, pageSize } = payload;
    if (meta.label) {
      dispatch(startLoading({ label: meta.label }));
    }
    cms
      .getAllByType({ type, page, pageSize })
      .then(response => onSuccess({ dispatch, response, meta }))
      .catch(error => onFailure({ dispatch, error, meta }));
  },
  [CMS_FETCH_DOC_BY_UID]: (dispatch, { payload, meta }) => {
    const { type, uid } = payload;
    if (meta.label) {
      dispatch(startLoading({ label: meta.label }));
    }
    cms
      .getDocByUid({ type, uid })
      .then(response => onSuccess({ dispatch, response, meta }))
      .catch(error => onFailure({ dispatch, error, meta }));
  },
});

export default cmsMiddleware;
