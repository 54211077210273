import React from 'react';

import { Alert, Text } from '@summer/ui-components';

import ContactSupport from 'components/pages/pslf/wizard/steps/yourEmployment/shared/ContactSupport';
import {
  PARTISAN_POLITICAL_ORG,
  LABOR_UNION,
  NONE_OF_THE_ABOVE,
} from 'constants/pslf';

const EmployerNonProfitTypeAlert = ({ employerNonProfitType }) => {
  let theme;
  let text;

  if (employerNonProfitType === PARTISAN_POLITICAL_ORG) {
    theme = 'warning';
    text = (
      <>
        😔 Unfortunately, partisan political organizations are not qualifying
        employers. However, you may qualify for PSLF while working for a
        different employer. <ContactSupport />
      </>
    );
  }

  if (employerNonProfitType === LABOR_UNION) {
    theme = 'warning';
    text = (
      <>
        😔 Unfortunately, labor unions are not qualifying employers. However,
        you may qualify for PSLF while working for a different employer.
        <ContactSupport />
      </>
    );
  }

  if (employerNonProfitType === NONE_OF_THE_ABOVE) {
    theme = 'success';
    text = (
      <>
        🎉 Hooray! Your employer qualifies as it is classed as a non-profit
        organization
      </>
    );
  }

  if (theme && text) {
    return (
      <Alert dismissable={false} showIcon={false} theme={theme}>
        <Text paragraph>{text}</Text>
      </Alert>
    );
  }

  return null;
};

export default React.memo(EmployerNonProfitTypeAlert);
