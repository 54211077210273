import React from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {
  ArrowUp,
  BubbleCustom,
  ChartLineUp,
  Checklist,
  Collapse,
  COLORS,
  CommonFileTextSearchCustom,
  EmergencyAlarm,
  FlexRow,
  FolderCustom,
  GraduationCapMinimal,
  HelpWheelCustom,
  Icon,
  OpenBook,
  OverpaymentsIcon,
  PresentationBoard,
  ProtectionShieldStar,
  QuestionCircle,
  SlcIcon,
  SummerLogo,
  Text,
  Tools,
} from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import {
  PRIVACY_POLICY_LINK,
  TERMS_OF_USE_LINK,
} from 'constants/externalLinks/supportLinks';
import { partnerConfig } from 'constants/partnerConfig';
import {
  CollapseSidebarSize,
  MobileSize,
  SidebarWidth,
} from 'constants/styleguide';
import { setSidebarVisibility } from 'redux/actions/ui.actions';
import { logout } from 'redux/actions/user.actions';
import {
  getIsCollegeSavingsPlannerToolEnabled,
  getIsContributionsEnabled,
  getIsEmergencySavingsDemoEnabled,
  getIsEmergencySavingsV1Enabled,
  getIsHideRetirementMatchSideBarEnabled,
  getIsNewCSPStartPageEnabled,
  getIsRetirementMatchDemo,
  getIsRetirementMatchV1Enabled,
  getIsSaveEnabled,
  getIsTuitionReimbursementEnabled,
} from 'redux/selectors/flags.selectors';
import { getUserInitials } from 'redux/selectors/profile.selectors';
import { getTuitionReimbursementServiceType } from 'redux/selectors/tuitionReimbursement.selectors';
import { isSidebarVisible } from 'redux/selectors/ui.selectors';
import {
  getUserIsPremium,
  getUserPartnerKey,
} from 'redux/selectors/user.selectors';
import { hexToRgba } from 'utils/common';
import {
  isToolsToggleOpenLocalStorage,
  marktoolsToggleOpenLocalStorage,
} from 'utils/sidebar';
import { getEducationAssistanceLabel } from 'utils/tuitionReimbursement';

const TOOLS_PAGES = [
  '/repayment',
  '/pslf',
  '/forgiveness-finder',
  '/refinancing',
  '/overpayments',
];

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toolsToggleOpen: this.toolsToggleOpen(),
    };
  }

  ToolMenuItem = props => {
    const { to } = props;
    const { userPartnerKey } = this.props;
    const hiddenToolsList =
      partnerConfig[userPartnerKey]?.hiddenToolsList || [];
    const shouldDisplayNavLink = !hiddenToolsList.includes(to.pathname);
    return shouldDisplayNavLink && <ToolNavLink {...props} />;
  };

  toolsToggleOpen = () => {
    return isToolsToggleOpenLocalStorage() || this.isToolsPage();
  };

  isToolsPage = () => {
    const { location } = this.props;

    return TOOLS_PAGES.includes(location.pathname);
  };

  toggleSidebar = () => {
    if (this.props.visible) {
      this.props.setSidebarVisibility(!this.props.visible);
    }
  };

  clickNonToolsLink = () => {
    this.toggleSidebar();
    this.setState({ toolsToggleOpen: false });
    marktoolsToggleOpenLocalStorage(false);
  };

  clickToolsLink = () => {
    this.toggleSidebar();
  };

  toggleTools = () => {
    const newState = !this.state.toolsToggleOpen;
    this.setState({ toolsToggleOpen: newState });
    marktoolsToggleOpenLocalStorage(newState);
  };

  collapseTrigger = () => {
    return (
      <CollapseTrigger>
        <MenuItemIcon width={18} height={18} SvgComponent={Tools} />
        <Text color="light" weight="medium">
          Tools
        </Text>
        <Arrow
          open={this.state.toolsToggleOpen}
          SvgComponent={ArrowUp}
          fill={COLORS.white}
        />
      </CollapseTrigger>
    );
  };

  render() {
    const {
      userInitials,
      logout,
      visible,
      showSLC,
      showCollegeSavingsPlanner,
      showNewCSPStartPage,
      showRetirementMatchV1,
      showRetirementMatchDemo,
      showTuitionReimbursement,
      showEmergencySavingsDemo,
      showEmergencySavingsV1,
      hideRetirementMatchSidebar,
      showSummerSave,
      tuitionReimbursementServiceType,
    } = this.props;

    const showRetirementMatch =
      (showRetirementMatchV1 || showRetirementMatchDemo) &&
      !hideRetirementMatchSidebar;

    return (
      <SidebarContainer className={visible ? 'open' : ''}>
        <OverflowContainer>
          <Logo>
            <Icon SvgComponent={SummerLogo} fill={COLORS.white} />
          </Logo>
          <Menu>
            {showSLC && (
              <MenuItemLink
                id="employer-contribution"
                onClick={this.clickNonToolsLink}
                to={{
                  pathname: '/employer-contribution',
                  state: { fromDashboard: true },
                }}
              >
                <MenuItemIcon width={18} height={18} SvgComponent={SlcIcon} />
                <Text color="light" weight="medium">
                  Student Loan Contribution
                </Text>
              </MenuItemLink>
            )}
            <MenuItemLink
              id="my-plan"
              onClick={this.clickNonToolsLink}
              to={{ pathname: '/plan', state: { fromDashboard: true } }}
            >
              <MenuItemIcon width={18} height={18} SvgComponent={Checklist} />
              <Text color="light" weight="medium">
                My Plan
              </Text>
            </MenuItemLink>

            <MenuItemLink
              id="loans"
              onClick={this.clickNonToolsLink}
              to={{ pathname: '/loans', state: { fromDashboard: true } }}
            >
              <MenuItemIcon
                width={18}
                height={18}
                SvgComponent={FolderCustom}
              />
              <Text color="light" weight="medium">
                My Student Loans
              </Text>
            </MenuItemLink>
            <Collapse
              trigger={this.collapseTrigger}
              ejectControl={true}
              show={this.state.toolsToggleOpen}
              onClick={this.toggleTools}
            >
              <>
                {showSummerSave && (
                  <>
                    <this.ToolMenuItem
                      id="repayment"
                      onClick={this.clickToolsLink}
                      to={{
                        pathname: '/repayment',
                        state: { fromDashboard: true },
                      }}
                    >
                      <MenuItemIcon
                        width={19}
                        height={19}
                        SvgComponent={CommonFileTextSearchCustom}
                      />
                      <Text color="light" weight="medium">
                        Income-Driven Repayment
                      </Text>
                    </this.ToolMenuItem>
                    <this.ToolMenuItem
                      id="pslf"
                      onClick={this.clickToolsLink}
                      to={{ pathname: '/pslf', state: { fromDashboard: true } }}
                    >
                      <MenuItemIcon
                        width={18}
                        height={18}
                        SvgComponent={ProtectionShieldStar}
                      />
                      <Text color="light" weight="medium">
                        Public Service Loan&nbsp;Forgiveness{' '}
                      </Text>
                    </this.ToolMenuItem>
                  </>
                )}
                <this.ToolMenuItem
                  id="forgiveness-finder"
                  onClick={this.clickToolsLink}
                  to={{
                    pathname: '/forgiveness-finder',
                    state: { fromDashboard: true },
                  }}
                >
                  <MenuItemIcon
                    width={18}
                    height={18}
                    SvgComponent={HelpWheelCustom}
                  />
                  <Text color="light" weight="medium">
                    Forgiveness Finder
                  </Text>
                </this.ToolMenuItem>
                <this.ToolMenuItem
                  id="refinancing"
                  onClick={this.clickToolsLink}
                  to={{
                    pathname: '/refinancing',
                    state: { fromDashboard: true },
                  }}
                >
                  <MenuItemIcon
                    width={19}
                    height={19}
                    SvgComponent={BubbleCustom}
                  />
                  <Text color="light" weight="medium">
                    Refinancing
                  </Text>
                </this.ToolMenuItem>

                <this.ToolMenuItem
                  id="overpayments"
                  onClick={this.clickToolsLink}
                  to={{
                    pathname: '/overpayments',
                    state: { fromDashboard: true },
                  }}
                >
                  <MenuItemIcon
                    width={19}
                    height={19}
                    SvgComponent={OverpaymentsIcon}
                  />
                  <Text color="light" weight="medium">
                    Extra Payments
                  </Text>
                </this.ToolMenuItem>

                {showCollegeSavingsPlanner && (
                  <this.ToolMenuItem
                    id="college-savings-planner"
                    onClick={this.clickToolsLink}
                    to={{
                      pathname: showNewCSPStartPage
                        ? '/college-savings-planner'
                        : '/college-savings-planner/calculator',
                      state: { fromDashboard: true },
                    }}
                  >
                    <MenuItemIcon
                      width={18}
                      height={18}
                      SvgComponent={GraduationCapMinimal}
                    />
                    <Text color="light" weight="medium">
                      College Savings Planner
                    </Text>
                  </this.ToolMenuItem>
                )}
                {showRetirementMatch && (
                  <this.ToolMenuItem
                    id="retirement-match"
                    onClick={this.clickToolsLink}
                    to={{
                      pathname: '/retirement-match/dashboard',
                      state: { fromDashboard: true },
                    }}
                  >
                    <MenuItemIcon
                      width={18}
                      height={18}
                      SvgComponent={ChartLineUp}
                    />
                    <Text color="light" weight="medium">
                      Retirement Match
                    </Text>
                  </this.ToolMenuItem>
                )}
                {showTuitionReimbursement && (
                  <this.ToolMenuItem
                    id="education-assistance"
                    onClick={this.clickToolsLink}
                    to={{
                      pathname: '/educational-assistance',
                      state: { fromDashboard: true },
                    }}
                  >
                    <MenuItemIcon
                      width={18}
                      height={18}
                      SvgComponent={OpenBook}
                    />
                    <Text color="light" weight="medium">
                      {getEducationAssistanceLabel(
                        tuitionReimbursementServiceType,
                      )}
                    </Text>
                  </this.ToolMenuItem>
                )}
                {(showEmergencySavingsDemo || showEmergencySavingsV1) && (
                  <this.ToolMenuItem
                    id="emergency-savings"
                    onClick={this.clickToolsLink}
                    to={{
                      pathname: '/emergency-savings',
                      state: { fromDashboard: true },
                    }}
                  >
                    <MenuItemIcon
                      width={18}
                      height={18}
                      SvgComponent={EmergencyAlarm}
                    />
                    <Text color="light" weight="medium">
                      Emergency Savings
                    </Text>
                  </this.ToolMenuItem>
                )}
              </>
            </Collapse>

            <MenuItemLink
              id="guides"
              onClick={this.clickNonToolsLink}
              to={{ pathname: '/guides', state: { fromDashboard: true } }}
            >
              <MenuItemIcon
                width={18}
                height={18}
                SvgComponent={PresentationBoard}
              />
              <Text color="light" weight="medium">
                Resource Center
              </Text>
            </MenuItemLink>

            <MenuItemLink
              id="support"
              onClick={this.clickNonToolsLink}
              to={{ pathname: '/support', state: { fromDashboard: true } }}
            >
              <MenuItemIcon
                width={18}
                height={18}
                SvgComponent={QuestionCircle}
              />
              <Text color="light" weight="medium">
                Support
              </Text>
            </MenuItemLink>
          </Menu>

          <SidebarBottom>
            <TermsLinks>
              <div>
                <Link href={TERMS_OF_USE_LINK} inheritColor underline>
                  Terms of Use
                </Link>
                <span> | </span>
                <Link href={PRIVACY_POLICY_LINK} inheritColor underline>
                  Privacy Policy
                </Link>
              </div>
            </TermsLinks>
            <UserNav
              id="user-settings"
              exact
              onClick={this.clickNonToolsLink}
              to={{ pathname: '/settings', state: { fromDashboard: true } }}
            >
              <ProfileIcon>
                <Text small color="light" weight="medium">
                  {userInitials}
                </Text>
              </ProfileIcon>
              <Text color="light" large weight="medium">
                My Account
              </Text>
            </UserNav>

            <Divider />

            <Logout onClick={logout}>
              <Text
                center
                color="light"
                weight="medium"
                data-testid="logout-button"
              >
                Logout
              </Text>
            </Logout>
          </SidebarBottom>
        </OverflowContainer>
      </SidebarContainer>
    );
  }
}

const menuItemStyles = `
  text-decoration: none;
  display: flex;
  position: relative;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 8px;
  align-items: flex-start;
  width: 100%;
  height: auto;
  transition: background-color 0.3s ease;

  .text {
    transition: color 0.3s ease;
  }

  svg {
    transition: transform 0.3s ease;

    path, g {
      fill: ${COLORS.white};
      transition: fill 0.3s ease;
    }
  }

  &:hover {
    background-color: ${hexToRgba(COLORS.white, 0.15)};
  }

  &.active {
    .text {
      color: ${COLORS.azure};
    }

    svg path, g {
      fill: ${COLORS.azure};
    }

    background-color: ${COLORS.white};
  }

  &:hover,
  &.active {
    .text {
      font-weight: 600 !important;
    }

    svg {
      transform: scale(1.07, 1.07);
    }
  }
`;

const CollapseTrigger = styled(FlexRow)`
  ${menuItemStyles}
  width: 100%;
  cursor: pointer;
`;

const MenuItemLink = styled(NavLink)`
  ${menuItemStyles}
`;

const ToolNavLink = styled(NavLink)`
  background-color: ${COLORS.ceruleanBlue};
  ${menuItemStyles}
`;

const Arrow = styled(Icon)`
  cursor: pointer;
  transform: ${props => (props.open ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: transform 0.3s ease-out;
  margin: auto 20px auto auto;
`;

const SidebarContainer = styled.div`
  width: ${SidebarWidth};
  height: 100%;
  background: ${COLORS.azure};
  z-index: 3;

  @media (max-width: ${CollapseSidebarSize}) {
    position: absolute;
    left: -${SidebarWidth};
    border-right-color: ${COLORS.lightestGrey};
    border-right-width: 1px;
    border-right-style: solid;

    &.open {
      left: 0;
    }

    transition: left 0.3s ease-out;
  }
`;

const OverflowContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${CollapseSidebarSize}) {
    overflow-y: scroll;
  }
`;

const Logo = styled.div`
  margin-top: 36px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
`;

const UserNav = styled(NavLink)`
  text-decoration: none;
  font-size: 20px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;

  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${hexToRgba(COLORS.white, 0.15)};
  }
`;

const SidebarBottom = styled.div`
  margin: auto 0 0;
`;

const Logout = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 12px 0;

  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${hexToRgba(COLORS.white, 0.15)};
  }
`;

const Menu = styled.div`
  @media (min-width: ${CollapseSidebarSize}) {
    overflow-y: scroll;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLORS.white};
  opacity: 0.4;
`;

const MenuItemIcon = styled(Icon)`
  padding-top: 1px;
  margin-right: 16px;
`;

const ProfileIcon = styled(FlexRow)`
  width: 36px;
  height: 36px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${hexToRgba(COLORS.white, 0.43)};
  justify-content: center;
  align-items: center;
`;

const TermsLinks = styled.div`
  text-align: center;
  font-size: 12px;
  color: ${COLORS.white};
  margin-top: 8px;

  a {
    line-height: 1.2;
  }

  div {
    font-size: 12px;
    span {
      color: ${COLORS.lightestGrey};
    }
  }

  @media (max-width: ${MobileSize}) {
    text-align: center;
    margin-top: 8px;
  }
`;

const mapStateToProps = state => ({
  userInitials: getUserInitials(state),
  userIsPremium: getUserIsPremium(state),
  userPartnerKey: getUserPartnerKey(state),
  visible: isSidebarVisible(state),
  showSLC: getIsContributionsEnabled(state),
  showCollegeSavingsPlanner: getIsCollegeSavingsPlannerToolEnabled(state),
  showNewCSPStartPage: getIsNewCSPStartPageEnabled(state),
  showRetirementMatchV1: getIsRetirementMatchV1Enabled(state),
  showRetirementMatchDemo: getIsRetirementMatchDemo(state),
  hideRetirementMatchSidebar: getIsHideRetirementMatchSideBarEnabled(state),
  showTuitionReimbursement: getIsTuitionReimbursementEnabled(state),
  showEmergencySavingsDemo: getIsEmergencySavingsDemoEnabled(state),
  showEmergencySavingsV1: getIsEmergencySavingsV1Enabled(state),
  showSummerSave: getIsSaveEnabled(state),
  tuitionReimbursementServiceType: getTuitionReimbursementServiceType(state),
});

export default withRouter(
  connect(mapStateToProps, { logout, setSidebarVisibility })(Sidebar),
);
