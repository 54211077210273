import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { COLORS } from '@summer/ui-components';

import ConsolidatePslfSubmitBasic from 'components/pages/pslf/wizard/steps/submit/PslfSummerBasicSubmitPage/scenarios/ConsolidatePslfSubmitBasic';
import PslfSubmitBasic from 'components/pages/pslf/wizard/steps/submit/PslfSummerBasicSubmitPage/scenarios/PslfSubmitBasic';
import { MobileSize } from 'constants/styleguide';
import { getLoansForPslf } from 'redux/selectors/loans.selectors';
import { pslfLoanSchema } from 'schemas/loans';
import { getNormalizedFsaLoans } from 'utils/loans';
import { hasLoansToConsolidate } from 'utils/pslf';

const PslfSummerBasicSubmitPage = ({ userNormalizedLoansForPslf }) => {
  const InstructionsContent = hasLoansToConsolidate(
    userNormalizedLoansForPslf,
  ) ? (
    <ConsolidatePslfSubmitBasic />
  ) : (
    <PslfSubmitBasic />
  );

  return (
    <PageContainer>
      <InstructionsContainer>{InstructionsContent}</InstructionsContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 48px 24px 24px;
  background-color: ${COLORS.lighterGrey};

  @media (max-width: ${MobileSize}) {
    padding: 0;
  }
`;

const InstructionsContainer = styled.div`
  max-width: 1200px;
  border-radius: 12px;
  margin-bottom: 32px;
  background-color: white;
  padding: 24px;
`;

const mapStateToProps = state => ({
  userNormalizedLoansForPslf: getNormalizedFsaLoans(
    getLoansForPslf(state),
    pslfLoanSchema,
  ),
});

export default connect(mapStateToProps)(PslfSummerBasicSubmitPage);
