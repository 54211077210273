import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Strong, Text, SelectInput } from '@summer/ui-components';

import StaticField from 'components/common/ManageLoans/fields/shared/StaticField';
import Placeholder from 'components/common/ManageLoans/shared/Placeholder';
import InfoTooltip from 'components/shared/InfoTooltip';
import {
  getCurrentLoan,
  isLoanFieldEditable,
} from 'redux/selectors/loans.selectors';
import {
  getServicerNameById,
  getServicersDropdownOptions,
} from 'redux/selectors/servicers.selectors';

const ServicerLabel = () => (
  <>
    <span>Servicer</span>
    <InfoTooltip
      text="Your servicer is the company that you make your monthly payments to. Servicers are usually different than the institution that gave you the original loan. They include companies like:  Navient, Nelnet, FedLoan, PHEAA, CornerStone, Granite State, Great Lakes, Edfinancial, HESC, MOHELA, OSLA, and AidVantage."
      top={2}
    />
  </>
);

class Servicer extends React.Component {
  componentWillUnmount() {
    if (this.props.canEdit) {
      this.props.formik.setFieldValue('servicerId', null);
    }
  }

  render() {
    const {
      formik: {
        values,
        errors,
        setFieldValue,
        handleBlur,
        touched,
        isSubmitting,
      },
      loan,
      servicersOptions,
      canEdit,
      selectedServicerName,
    } = this.props;

    const servicerRaw = loan && loan.servicerRaw;

    const showServicerMessage = servicerRaw && canEdit;

    return (
      <Container id="servicerId">
        <SelectContainer>
          {canEdit ? (
            <SelectInput
              name="servicerId"
              options={servicersOptions}
              value={values.servicerId}
              error={errors.servicerId}
              touched={touched.servicerId}
              isSubmitting={isSubmitting}
              onChange={setFieldValue}
              onBlur={handleBlur}
              placeholder=""
              label={<ServicerLabel />}
              subTitle={
                showServicerMessage && (
                  <Info>
                    <Text small left>
                      Your servicer was listed as:
                      <br />
                      <Strong>{servicerRaw}</Strong>
                      <br />
                      Select &lsquo;University / Other&rsquo; if your Servicer
                      is not listed below
                    </Text>
                  </Info>
                )
              }
            />
          ) : (
            <StaticField
              label={<ServicerLabel />}
              value={selectedServicerName}
            />
          )}
        </SelectContainer>
        <Placeholder />
      </Container>
    );
  }
}

const Container = styled.div`
  flex: 1;
  width: 100%;
`;

const SelectContainer = styled.div`
  width: 100%;
`;

const Info = styled.div`
  padding: 5px;
`;

const mapStateToProps = (state, props) => {
  const loanId = props.formik.values.id;
  const { servicerId } = props.formik.values;

  return {
    loan: getCurrentLoan(state),
    servicersOptions: getServicersDropdownOptions(state),
    canEdit: isLoanFieldEditable(state, loanId, 'servicerId'),
    selectedServicerName: getServicerNameById(state, servicerId),
  };
};

export default connect(mapStateToProps)(Servicer);
