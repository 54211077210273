import { useDispatch, useSelector } from 'react-redux';

import { useInterval, useTimeout } from 'hooks/common';
import { keepSessionAlive } from 'redux/actions/auth.actions';
import { getUserId } from 'redux/selectors/user.selectors';

const FIVE_MINUTES_MS = 5 * 60 * 1000;
const THIRTY_MINUTES_MS = 30 * 60 * 1000;

export const useKeepSessionAlive = ({
  intervalDuration = FIVE_MINUTES_MS,
  timeoutDuration = THIRTY_MINUTES_MS,
  onTimeout = () => {},
  enabled = false,
}) => {
  const dispatch = useDispatch();
  const isLoggedIn = !!useSelector(getUserId);

  const runInterval = enabled && isLoggedIn;

  const onInterval = () => dispatch(keepSessionAlive());

  useInterval(onInterval, runInterval ? intervalDuration : null);
  useTimeout(onTimeout, runInterval ? timeoutDuration : null);
};
