import { REHYDRATE } from 'redux-persist/lib/constants';

import { SET_SIDEBAR_VISIBILITY } from 'constants/actionTypes';

const initialState = {
  visible: false,
};

const sidebar = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SIDEBAR_VISIBILITY:
      return { ...state, visible: payload.visible };

    case REHYDRATE:
      return { ...((payload && payload.ui && payload.ui.sidebar) || {}) };

    default:
      return state;
  }
};

export default sidebar;
