/* eslint-disable no-param-reassign */
import Bowser from 'bowser';
import { reduce, isPlainObject } from 'lodash';

export const ObjectEmptyStringToNull = object => {
  return reduce(
    object,
    (result, value, key) => {
      if (value === '') {
        result[key] = null;
      } else if (isPlainObject(value)) {
        result[key] = ObjectEmptyStringToNull(value);
      } else {
        result[key] = value;
      }
      return result;
    },
    {},
  );
};

export const ObjectNullToEmptyString = object => {
  const newObject = reduce(
    object,
    (result, value, key) => {
      if (value === null) {
        result[key] = '';
      } else {
        result[key] = value;
      }
      return result;
    },
    {},
  );
  return newObject;
};

const hexToRbg = hex => {
  const hexInt = parseInt(hex.slice(1), 16);

  const r = (hexInt >> 16) & 255;
  const g = (hexInt >> 8) & 255;
  const b = hexInt & 255;

  return { r, g, b };
};

export const hexToRgba = (hex, opacity) => {
  const { r, g, b } = hexToRbg(hex);
  const a = opacity;

  return `rgba(${r},${g},${b},${a})`;
};

export const getMonochromeRBGValueForColor = hex => {
  const { r, b, g } = hexToRbg(hex);
  return (r + b + g) / 3;
};

export const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const isProductionEnv = () => {
  return (
    process.env.REACT_APP_NODE_ENV !== 'staging' &&
    process.env.REACT_APP_NODE_ENV !== 'sandbox' &&
    process.env.NODE_ENV !== 'development' &&
    (process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV) !== 'test'
  );
};

export const isTestEnv = () =>
  (process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV) === 'test';

export const isDevEnv = () =>
  (process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV) === 'development';

export const isBrowserInvalid = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getBrowserName() === 'Internet Explorer';
};
