import React from 'react';
import styled from 'styled-components';

import { FlexColumn, FlexRow, COLORS } from '@summer/ui-components';

import ConnectFsaAccordion from 'components/common/SyncLoans/ConnectFsa/ConnectFsaBody/ConnectFsaAccordion';
import NsldsUpload from 'components/common/SyncLoans/NsldsUpload';
import { MobileSize } from 'constants/styleguide';

const { lightestGrey, grey } = COLORS;

const ConnectFsaBody = ({ CustomCTA, bodyText }) => (
  <Container>
    <SyncBodyWrapper>
      <InfoContainer>
        {bodyText}
        <ConnectFsaAccordion drawersToOpenByDefault={[4]} />
      </InfoContainer>

      <FSAPortal>
        <FormContainer>
          <FSAImg src="/images/nslds/logo.png" />
          <NsldsUpload />
        </FormContainer>
      </FSAPortal>
    </SyncBodyWrapper>
    {CustomCTA && <CustomCTA />}
  </Container>
);

const Container = styled.div`
  width: 100%;
  padding: 24px 48px 0;

  @media (max-width: ${MobileSize}) {
    padding: 0;
  }
`;

const SyncBodyWrapper = styled(FlexRow)`
  max-width: 840px;
  margin: 0 auto;

  @media (max-width: ${MobileSize}) {
    flex-direction: column-reverse;
  }
`;

const InfoContainer = styled(FlexColumn)`
  flex: 1;
  margin-right: 48px;

  @media (max-width: ${MobileSize}) {
    background-color: ${lightestGrey};

    padding: 0 24px;
    margin-right: 0;
  }
`;

const FSAImg = styled.img`
  margin: auto;
  width: 100%;
  max-width: 278px;
  padding: 40px 0;
  @media (max-width: ${MobileSize}) {
    align-self: center;
  }
`;

const FSAPortal = styled.div`
  flex: 1;
  margin-bottom: 32px;

  @media (max-width: ${MobileSize}) {
    margin-bottom: 0px;
  }
`;

const FormContainer = styled(FlexColumn)`
  max-width: 384px;

  border-radius: 3px;
  border: 1px solid ${grey};
  background-color: ${lightestGrey};
  text-align: left;

  padding: 9px 40px 40px;

  @media (max-width: ${MobileSize}) {
    border: none;
    border-bottom: 1px solid ${grey};
    max-width: none;
    width: 100%;
    padding-top: 0;
  }
`;

export default ConnectFsaBody;
