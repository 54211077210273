import { includes, get, filter, map } from 'lodash';
import { createSelector } from 'reselect';

import { getCompletedPslfSignRequests } from 'redux/selectors/signatureRequests.selectors';

export const getUserPslfForms = state => get(state, 'pslf.pslfForms');

export const getUserCurrentPslfForm = state =>
  get(state, 'pslf.currentPslfForm');

export const getCurrentPslfFormId = createSelector(
  getUserCurrentPslfForm,
  currentPslfForm => get(currentPslfForm, 'formId'),
);

export const getEmployerStartDate = createSelector(
  getUserCurrentPslfForm,
  currentPslfForm => get(currentPslfForm, 'employerStartDate'),
);

export const getEmployerEndDate = createSelector(
  getUserCurrentPslfForm,
  currentPslfForm => get(currentPslfForm, 'employerEndDate'),
);

export const getEmployerType = createSelector(
  getUserCurrentPslfForm,
  currentPslfForm => get(currentPslfForm, 'employerType'),
);

export const getPslfCurrentStep = createSelector(
  getUserCurrentPslfForm,
  currentPslfForm => get(currentPslfForm, 'currentStep'),
);

export const getPslfLoanEligibility = state =>
  state.pslf.calculations.loanEligibility;

export const checkPslfNetworkSuccess = (state, key) =>
  get(state, `pslf.networkSuccess.${key}`);

const getPslfFormsForPartTimeEmployment = createSelector(
  getUserPslfForms,
  pslfForms => filter(pslfForms, { multipleEmployers: true }),
);

export const getNumCompletedPartTimePslfForms = createSelector(
  getCompletedPslfSignRequests,
  getPslfFormsForPartTimeEmployment,
  (completedSignRequests, partTimePslfForms) => {
    const completedFormIds = map(completedSignRequests, 'formId');
    const completedPartTimeForms = filter(partTimePslfForms, ({ formId }) =>
      includes(completedFormIds, formId),
    );
    return get(completedPartTimeForms, 'length');
  },
);
