import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import SignatureCanvas from 'react-signature-canvas';
import { Field } from 'formik';
import { COLORS } from '../../../helpers/constants/styleguide';
import Input from '../Input';

const SignatureInput = ({
  name,
  label,
  subTitle,
  helpMessage,
  error,
  touched,
  isSubmitting,
}) => {
  const signatureCanvas = useRef(null);
  useEffect(() => {
    if (isSubmitting) {
      signatureCanvas.current?.off();
    } else {
      signatureCanvas.current?.on();
    }
  }, [isSubmitting, signatureCanvas]);

  return (
    <Wrapper>
      <Input
        id={name}
        label={label}
        subTitle={subTitle}
        helpMessage={helpMessage}
        error={error}
        touched={touched}
        isSubmitting={isSubmitting}
        height="144px"
      >
        {handleFocusChange => (
          <Field name={name}>
            {({
              field: { onChange },
              form: { setFieldValue, setFieldTouched },
            }) => {
              const clearSignatureCanvas = () => {
                if (isSubmitting) {
                  return;
                }
                onChange(name);
                signatureCanvas.current?.clear();
                setFieldTouched(name);
                setFieldValue(name, null);
              };

              const saveSignatureCanvas = () => {
                onChange(name);
                setFieldTouched(name);
                setFieldValue(
                  name,
                  signatureCanvas.current
                    ?.getCanvas()
                    .toDataURL('image/jpeg')
                    .split(',')[1] || null,
                );
              };

              return (
                <>
                  <SignatureCanvas
                    canvasProps={{
                      name,
                      'id': name,
                      'data-testid': `input-signature-${name}`,
                      'style': {
                        width: '800px',
                        height: '100%',
                      },
                    }}
                    backgroundColor="rgba(255, 255, 255, 1)"
                    clearOnResize={false}
                    ref={signatureCanvas}
                    onBegin={() => handleFocusChange(true)}
                    onEnd={() => {
                      saveSignatureCanvas();
                      handleFocusChange(false);
                    }}
                  />
                  <ClearButton
                    type="button"
                    onClick={() => {
                      clearSignatureCanvas();
                      handleFocusChange(true);
                    }}
                    id={`${name}-clear`}
                    data-testid={`button-${name}-clear`}
                  >
                    Clear
                  </ClearButton>
                </>
              );
            }}
          </Field>
        )}
      </Input>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const ClearButton = styled.button`
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 6px 12px 6px 12px;
  border: 0px;
  font-size: 14px;
  background-color: transparent;
  text-decoration: underline;
  color: ${COLORS.orange};
  cursor: pointer;
`;

export default SignatureInput;
