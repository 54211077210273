import * as Sentry from '@sentry/browser';
import { pick } from 'lodash';
import LogRocket from 'logrocket';

import { track } from 'utils/track';

const RELEASE = process.env.REACT_APP_COMMIT_REVISION;
const SANDBOX_FLAG = process.env.REACT_APP_SANDBOX_ENV_FLAG;
const DSN = process.env.REACT_APP_SENTRY_DSN;
const APP_ID = process.env.REACT_APP_LOGROCKET_APP_ID;

const ENV = process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV;

// ! WARNING: These sanitizer functions MUST be above the LogRocket.init call below
export const requestSanitizer = request => {
  const pickedRequest = pick(request, ['url', 'method']);

  if (pickedRequest.url.includes('token=')) {
    pickedRequest.url = pickedRequest.url.replace(
      /token=[^&]*/,
      'token=REDACTED',
    );
  }

  return pickedRequest;
};

// ! WARNING: These sanitizer functions MUST be above the LogRocket.init call below
export const responseSanitizer = response => {
  return pick(response, ['duration', 'status']);
};

if (ENV !== 'development') {
  Sentry.init({
    dsn: DSN,
    environment: SANDBOX_FLAG || ENV,
    release: RELEASE,
  });

  if (APP_ID) {
    LogRocket.init(APP_ID, {
      network: {
        requestSanitizer,
        responseSanitizer,
      },
      dom: {
        inputSanitizer: true,
      },
      browser: {
        urlSanitizer: url => {
          let sanitizedUrl = url;

          // redact the value of the query parameter token
          sanitizedUrl = sanitizedUrl.replace(
            /token=([^&]*)/,
            'token=**redacted**',
          );

          return sanitizedUrl;
        },
      },
    });

    LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(scope => {
        scope.setExtra('sessionURL', sessionURL);
      });

      track('LogRocket', {
        sessionURL,
      });
    });
  }
}

export const setUserForErrorTracking = ({ email, id }) => {
  Sentry.configureScope(scope => {
    scope.setUser({ email, id });
  });

  if (APP_ID) {
    LogRocket.identify(email, { email, id });
  }
};
