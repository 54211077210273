import React from 'react';
import styled from 'styled-components';

import {
  FlexColumn,
  Header,
  Text,
  Strong,
  COLORS,
} from '@summer/ui-components';

import IdrInfoPopup from 'components/pages/idr/wizard/steps/ReviewYourPlan/popups/IdrInfoPopup';
import { Link } from 'components/shared/typography';
import { SmallDesktopSize, MobileSize } from 'constants/styleguide';
import { hexToRgba } from 'utils/common';

const EducationBanner = ({ isPslfOn }) => {
  const IdrInfo = () => (
    <>
      <Header h2>How do income-driven plans work?</Header>
      <InfoItems>
        <ul>
          <Text paragraph medium as="li">
            Your monthly payments are based on your income, and you pay for a
            longer period than under the Standard plan.
          </Text>
          <Text paragraph medium as="li">
            Get on track for forgiveness on the remaining balance once the
            repayment period ends if you recertify annually and are not in
            default.
          </Text>
          <Text paragraph medium as="li">
            <Strong>
              You&rsquo;re not locked in! You can switch out at any time. To
              stay in an IDR plan, you must recertify your income every year.
            </Strong>{' '}
            <IdrInfoPopup>
              <Link underline inheritColor>
                Learn more
              </Link>
              .
            </IdrInfoPopup>
          </Text>
        </ul>
      </InfoItems>
    </>
  );

  const PslfInfo = () => (
    <>
      <Header h2>You must enroll in IDR to get forgiveness</Header>
      <Text paragraph medium bold as="p">
        If you plan on receiving Public Service Loan Forgiveness, you must also
        be enrolled in an income-driven repayment plan. Otherwise, you
        won&rsquo;t receive forgiveness.
      </Text>
      <InfoItems>
        <ul>
          <Text paragraph medium as="li">
            Under the Standard plan, you&rsquo;d pay off your loans within the
            10-year repayment period and not gain any forgiveness.
          </Text>
          <Text paragraph medium as="li">
            If your income grows and you no longer qualify for IDR, then you may
            also be paying too much to receive forgiveness.
          </Text>
        </ul>
      </InfoItems>
    </>
  );

  return (
    <Background isPslfOn={isPslfOn}>
      <Content>
        <Info>{isPslfOn ? <PslfInfo /> : <IdrInfo />}</Info>
        <Chart
          src={
            isPslfOn
              ? '/images/idr/pslf-chart.png'
              : '/images/idr/idr-chart.png'
          }
        />
      </Content>
    </Background>
  );
};

const Background = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 32px;
  background-color: ${({ isPslfOn }) =>
    hexToRgba(isPslfOn ? COLORS.goldDark : COLORS.azure, '0.05')};
  margin-bottom: 32px;
  @media (max-width: ${SmallDesktopSize}) {
    flex-direction: column;
  }

  @media (max-width: ${MobileSize}) {
    padding: 20px 24px 24px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1024px;

  @media (max-width: ${SmallDesktopSize}) {
    flex-direction: column;
  }
`;

const Info = styled(FlexColumn)`
  justify-content: center;
  margin-right: 48px;

  & > h2 {
    margin-bottom: 4px;
  }

  & > p {
    margin: 8px 0px;
  }

  @media (max-width: ${SmallDesktopSize}) {
    margin-right: 0;
    margin-bottom: 12px;
  }
`;

const InfoItems = styled.div`
  & > ul {
    margin-top: 8px;
    margin-left: 24px;

    @media (max-width: ${SmallDesktopSize}) {
      margin-bottom: 24px;
    }
  }

  & > ul > li:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Chart = styled.img`
  width: 100%;
  max-width: 480px;
  align-self: center;
`;

export default EducationBanner;
