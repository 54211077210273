import {
  CREATE_SMS_FACTOR,
  NETWORK_SUCCESS,
  VERIFY_SMS_FACTOR,
  SEND_SMS_FACTOR_OTP,
} from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import { setSmsFactor } from 'redux/actions/smsFactor.actions';
import { hydrateUser } from 'redux/actions/user.actions';
import handleSideEffects from 'redux/handleSideEffects';

const smsFactorMiddleware = handleSideEffects({
  [CREATE_SMS_FACTOR]: (dispatch, { payload, meta }) => {
    dispatch(
      apiRequest({
        url: '/v1/mfa/sms',
        method: 'POST',
        body: payload,
        fromAction: CREATE_SMS_FACTOR,
        label: 'createSmsFactor',
        meta,
      }),
    );
  },

  [VERIFY_SMS_FACTOR]: (dispatch, { payload, meta }) => {
    dispatch(
      apiRequest({
        url: '/v1/mfa/sms/verify',
        method: 'POST',
        body: payload,
        fromAction: VERIFY_SMS_FACTOR,
        label: 'verifySmsFactor',
        meta,
      }),
    );
  },

  [SEND_SMS_FACTOR_OTP]: (dispatch, { payload, meta }) => {
    dispatch(
      apiRequest({
        url: '/v1/mfa/sms/send',
        method: 'POST',
        body: payload,
        fromAction: SEND_SMS_FACTOR_OTP,
        label: 'sendSmsFactorOTP',
        meta,
      }),
    );
  },

  [NETWORK_SUCCESS]: (dispatch, { payload, meta }) => {
    if ([VERIFY_SMS_FACTOR].includes(meta.fromAction)) {
      dispatch(hydrateUser());
    }

    if ([CREATE_SMS_FACTOR, VERIFY_SMS_FACTOR].includes(meta.fromAction)) {
      const { status, phoneNumber } = payload.response;

      dispatch(
        setSmsFactor({
          status,
          phoneNumber,
        }),
      );

      if (meta.onSuccess) {
        meta.onSuccess();
      }
    }

    if ([SEND_SMS_FACTOR_OTP].includes(meta.fromAction)) {
      if (meta.onSuccess) {
        meta.onSuccess();
      }
    }
  },
});

export default smsFactorMiddleware;
