import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled, { css, keyframes } from 'styled-components';

import { COLORS } from '@summer/ui-components';

import BasicInfo from 'components/pages/loans/components/LoanDetails/BasicInfo';
import Footer from 'components/pages/loans/components/LoanDetails/Footer';
import Header from 'components/pages/loans/components/LoanDetails/Header';
import PaymentInfo from 'components/pages/loans/components/LoanDetails/PaymentInfo';
import ServicerInfo from 'components/pages/loans/components/LoanDetails/ServicerInfo';
import { TabletSize } from 'constants/styleguide';

class LoanDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLoansIndex: props.initLoanIndex,
    };
  }

  componentDidUpdate(prevProps) {
    const { initLoanIndex } = this.props;
    if (initLoanIndex !== prevProps.initLoanIndex) {
      this.setState({ currentLoansIndex: initLoanIndex });
    }
  }

  nextLoan = () => {
    const { loans, closeLoanDetails } = this.props;
    const { currentLoansIndex } = this.state;

    if (currentLoansIndex === loans.length - 1) {
      closeLoanDetails();
      return;
    }

    this.setState(({ currentLoansIndex }) => ({
      currentLoansIndex: currentLoansIndex + 1,
    }));
  };

  previousLoan = () => {
    const { closeLoanDetails } = this.props;
    const { currentLoansIndex } = this.state;

    if (currentLoansIndex === 0) {
      closeLoanDetails();
      return;
    }

    this.setState(({ currentLoansIndex }) => ({
      currentLoansIndex: currentLoansIndex - 1,
    }));
  };

  render() {
    const { loans, closeLoanDetails } = this.props;
    const { currentLoansIndex } = this.state;
    const {
      amountBorrowed,
      currentBalance,
      dateBorrowed,
      dueDate,
      repaymentPlan,
      interestRate,
      interestType,
      loanType,
      monthlyPayment,
      outstandingInterestBalance,
      pslfMatchedMonths,
      principalBalance,
      school,
      servicerName,
      servicerPhone,
      servicerUrl,
      servicerEmail,
      status,
      totalPayments,
    } = loans[currentLoansIndex];

    return (
      <CSSTransition
        in={true}
        classNames="loan-details"
        timeout={{ enter: 500, exit: 300 }}
      >
        <Container>
          <Content>
            <Header
              servicerName={servicerName}
              loanType={loanType}
              closeLoanDetails={closeLoanDetails}
            />
            <BasicInfo
              amountBorrowed={amountBorrowed}
              currentBalance={currentBalance}
              dateBorrowed={dateBorrowed}
              repaymentPlan={repaymentPlan}
              interestRate={interestRate}
              interestType={interestType}
              outstandingInterestBalance={outstandingInterestBalance}
              principalBalance={principalBalance}
              school={school}
              status={status}
            />
            <PaymentInfo
              dueDate={dueDate}
              monthlyPayment={monthlyPayment}
              pslfMatchedMonths={pslfMatchedMonths}
              totalPayments={totalPayments}
            />
            <ServicerInfo
              servicerName={servicerName}
              servicerPhone={servicerPhone}
              servicerUrl={servicerUrl}
              servicerEmail={servicerEmail}
            />
            <Footer
              handleNext={this.nextLoan}
              handlePrevious={this.previousLoan}
            />
          </Content>
        </Container>
      </CSSTransition>
    );
  }
}

const slideInRight = keyframes`
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

const slideOutRight = keyframes`
   from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
`;

const slideInRightAnimation = css`
  ${slideInRight} 0.3s linear forwards;
`;

const slideOutRightAnimation = css`
  ${slideOutRight} 0.3s linear forwards;
`;

const Container = styled.section`
  width: 720px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  box-shadow: 0 2px 16px 0 rgba(143, 152, 165, 0.47);
  background-color: ${COLORS.white};

  @media (max-width: ${TabletSize}) {
    width: 100%;
    height: 100%;
  }

  &.loan-details-enter {
    animation: ${slideInRightAnimation};
  }

  &.loan-details-exit {
    animation: ${slideOutRightAnimation};
  }
`;

const Content = styled.div`
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 90px;

  @media (max-width: ${TabletSize}) {
    padding-bottom: 0;
  }
`;

const LoanDetailsTransitionWrapper = ({
  loans,
  initLoanIndex,
  isOpen,
  closeLoanDetails,
}) => (
  <TransitionGroup>
    {isOpen && (
      <CSSTransition
        in={isOpen}
        classNames="loan-details"
        timeout={{ enter: 500, exit: 300 }}
      >
        <LoanDetails
          loans={loans}
          initLoanIndex={initLoanIndex}
          closeLoanDetails={closeLoanDetails}
        />
      </CSSTransition>
    )}
  </TransitionGroup>
);

export default LoanDetailsTransitionWrapper;
