import { isNil } from 'lodash';

import {
  FETCH_SYNC_STATUS,
  DELETE_SYNC,
  NETWORK_SUCCESS,
} from 'constants/actionTypes';
import { trackServerSide } from 'redux/actions/analytics.actions';
import { apiRequest } from 'redux/actions/api.actions';
import { setIdrCurrentStep } from 'redux/actions/idr.actions';
import { fetchLoans } from 'redux/actions/loans.actions';
import { fetchSyncStatus, setSyncs } from 'redux/actions/sync.actions';
import { finishLoading, showServerError } from 'redux/actions/ui.actions';
import handleSideEffects from 'redux/handleSideEffects';
import { getCurrentIdrFormStep } from 'redux/selectors/idr.selectors';
import { track } from 'utils/track';

// 5 second poll interval 1 minute max
const pollTimeout = 5 * 1000;
const pollMax = 12;

const syncMiddleware = handleSideEffects({
  [FETCH_SYNC_STATUS]: (dispatch, { payload, successAction }) => {
    let url;

    if (isNil(payload.id)) {
      url = '/sync';
    } else {
      url = `/sync?id=${payload.id}`;
    }

    dispatch(
      apiRequest({
        url,
        method: 'GET',
        label: 'fetchSyncStatus',
        fromAction: FETCH_SYNC_STATUS,
        meta: {
          poll: payload.poll,
          id: payload.id,
          pollCount: payload.pollCount,
          continuePolling: response => {
            return !['failure', 'success'].includes(
              response.data[0].syncs[0].status,
            );
          },
          successAction,
        },
      }),
    );
  },

  [DELETE_SYNC]: (dispatch, { successAction }) => {
    dispatch(
      apiRequest({
        url: '/sync',
        method: 'DELETE',
        label: 'deleteSync',
        fromAction: DELETE_SYNC,
        meta: {
          successAction,
        },
      }),
    );
  },

  [NETWORK_SUCCESS]: (dispatch, { payload, meta }, state) => {
    const { response } = payload;

    if (meta.fromAction === FETCH_SYNC_STATUS) {
      const { poll, pollCount, successAction, id } = meta;

      dispatch(setSyncs(response));
      if (!poll) {
        if (successAction) {
          dispatch(successAction());
        }
        return;
      }

      const sync = response[0].syncs[0];

      if (sync.status === 'success') {
        dispatch(fetchLoans());

        const eventName = '[USER] Synced FSA loans';
        track(eventName);
        dispatch(
          trackServerSide(eventName, {
            properties: { location: window.location.pathname },
          }),
        );
      } else if (sync.status === 'failure') {
        dispatch(
          showServerError({
            label: 'fetchSyncStatus',
            error: {
              message: sync.result,
              status: null,
            },
          }),
        );
      } else if (poll) {
        if (pollCount === pollMax) {
          dispatch(
            showServerError({
              label: meta.label,
              error: {
                message: 'Unable to complete sync, timed out',
                status: null,
              },
            }),
          );
          dispatch(finishLoading({ label: meta.label }));
          return;
        }

        /* If the status is not 'success' or 'failure' it could be 'pending'
         * or 'started'. In that case try again after a delay
         */
        setTimeout(() => {
          dispatch(
            fetchSyncStatus(
              {
                id,
                poll,
                pollCount: pollCount + 1,
              },
              successAction,
            ),
          );
        }, pollTimeout);
      }
    } else if (meta.fromAction === DELETE_SYNC) {
      if (meta.successAction) {
        dispatch(meta.successAction());

        if (getCurrentIdrFormStep(state) > 2) {
          dispatch(setIdrCurrentStep(2));
        }
      }
    }
  },
});

export default syncMiddleware;
