import _, { keys, isBoolean } from 'lodash';
import fp from 'lodash/fp';

import {
  CLEAR_CURRENT_PSLF_FORM,
  SET_CURRENT_PSLF_FORM,
  SET_PSLF_CURRENT_STEP,
} from 'constants/actionTypes';
import { boolToString } from 'utils/toOptions';

const initialState = {
  formId: null,
  currentStep: null,
  employerName: '',
  employerType: '',
  employerNonProfitType: '',
  employerPrimaryPurpose: [],
  employerStartDate: '',
  employerEndDate: '',
  employerHoursPerWeek: null,
  forbearanceRequested: null,
  multipleEmployers: null,
  employerWebsite: '',
  hrFirstName: '',
  hrLastName: '',
  hrEmail: '',
  hrEmailCorrect: null,
  updatedAt: null,
  status: '',
};

const currentPslfFormReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_PSLF_FORM: {
      const currentFormFields = _.flow(
        fp.get('form'),
        fp.pick(keys(initialState)),
        fp.mapValues((value, key) => {
          if (value === null) {
            return initialState[key];
          }
          if (isBoolean(value)) {
            return boolToString(value);
          }
          return value;
        }),
      )(payload);
      return { ...initialState, ...currentFormFields };
    }
    case SET_PSLF_CURRENT_STEP: {
      return { ...state, currentStep: payload.currentStep };
    }
    case CLEAR_CURRENT_PSLF_FORM: {
      return initialState;
    }
    default:
      return state;
  }
};

export default currentPslfFormReducer;
