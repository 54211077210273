import React from 'react';
import styled from 'styled-components';

import { FlexRow, Text } from '@summer/ui-components';

import { MobileSize } from 'constants/styleguide';

import LastUpdated from './LastUpdated';
import UpdateOnboardingModal from './UpdateOnboardingModal';

const RecommendationsHeader = () => {
  return (
    <Container>
      <Text paragraph weight="medium" size="medium">
        Below are next steps we recommend for getting out of student loan debt.{' '}
        <UpdateOnboardingModal text="Need to update your info?" />
      </Text>
      <LastUpdated />
    </Container>
  );
};

const Container = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;

  > * {
    margin-bottom: 8px;
  }
  :last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${MobileSize}) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export default RecommendationsHeader;
