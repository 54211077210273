import _, { filter, isEmpty } from 'lodash';
import fp from 'lodash/fp';
import { createSelector } from 'reselect';

import { getConsolidationIncludedParentPlus } from 'redux/selectors/financialProfile.selectors';
import { showPlaidInIdr } from 'services/featureFlags/flags';
import { getNormalizedPlaidLoans } from 'utils/loans';
import { validatePlaidLoansForMissingInfo } from 'utils/validate';

const getPlaidLoans = state => state.plaidLoans;
export const getPlaidIntegrations = state => state.plaidIntegrations;

export const getPlaidFederalLoans = createSelector(
  getPlaidLoans,
  plaidLoans => {
    return filter(plaidLoans, { isFederal: true });
  },
);

export const hasFederalPlaidLoans = state => {
  const plaidLoans = getPlaidFederalLoans(state);
  return !isEmpty(plaidLoans);
};

export const getActiveFederalPlaidLoanServicerNames = createSelector(
  getPlaidFederalLoans,
  _.flow(
    getNormalizedPlaidLoans,
    fp.filter({ active: true }),
    fp.map('servicerName'),
    fp.uniq,
  ),
);

export const arePlaidFederalLoansMissingInfo = createSelector(
  getPlaidFederalLoans,
  getConsolidationIncludedParentPlus,
  (plaidFederalLoans, consolidationIncludedParentPlus) =>
    !isEmpty(plaidFederalLoans) &&
    validatePlaidLoansForMissingInfo(
      plaidFederalLoans,
      consolidationIncludedParentPlus,
    ),
);

export const shouldUsePlaidFlow = state =>
  showPlaidInIdr() && !arePlaidFederalLoansMissingInfo(state);
