import { toString } from 'lodash';

import { SET_CURRENT_IDR_FORM } from 'constants/actionTypes';

export const initialState = {
  userWillPursuePlc: '',
};

const yourLoansReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_IDR_FORM: {
      const { userWillPursuePlc } = payload;

      return {
        userWillPursuePlc: toString(userWillPursuePlc),
      };
    }
    default:
      return state;
  }
};

export default yourLoansReducer;
