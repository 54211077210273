import { isEmpty } from 'lodash';
import React from 'react';

import EmptyServicersDisplay from 'components/pages/retirementMatch/dashboard/Servicers/EmptyServicersDisplay';
import ServicersDisplay from 'components/pages/retirementMatch/dashboard/Servicers/ServicersDisplay';

const Servicers = ({
  PlaidTransactionsData = [],
  openPlaid,
  setIsPlaidDone,
  isPlaidReady,
}) => {
  const hasPlaidTransactionData = !isEmpty(PlaidTransactionsData);

  return hasPlaidTransactionData ? (
    <ServicersDisplay
      PlaidTransactionsData={PlaidTransactionsData}
      openPlaid={openPlaid}
      setIsPlaidDone={setIsPlaidDone}
      isPlaidReady={isPlaidReady}
    />
  ) : (
    <EmptyServicersDisplay
      openPlaid={openPlaid}
      setIsPlaidDone={setIsPlaidDone}
      isPlaidReady={isPlaidReady}
    />
  );
};

export default Servicers;
