import { push as redirectTo } from 'connected-react-router';
import { format, parseISO } from 'date-fns';
import { pick, truncate } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Header, Text, COLORS } from '@summer/ui-components';

import Platform, { MOBILE, TABLET } from 'components/hoc/Platform';
import WizardHeader from 'components/layout/wizard/WizardHeader';
import { TabletSize } from 'constants/styleguide';
import { clearCurrentPslfForm } from 'redux/actions/pslf.actions';
import { getUserCurrentPslfForm } from 'redux/selectors/pslf.selectors';

const PslfWizardHeader = ({
  progress,
  headers,
  currentStep,
  employerName,
  employerStartDate,
  employerEndDate,
  platformType,
  windowWidth,
  dispatch,
  handleHeaderClick,
}) => {
  const isMobile = platformType === MOBILE;
  const isMobileOrTablet = platformType === TABLET || platformType === MOBILE;

  const dateFormat = isMobile ? 'MM/dd/yy' : 'MMM dd, yyyy';

  const formattedEmployerName = isMobile
    ? truncate(employerName, { length: windowWidth < 350 ? 16 : 23 })
    : employerName;

  const formattedStartDate =
    employerStartDate && format(parseISO(employerStartDate), dateFormat);
  const formattedEndDate = employerEndDate
    ? format(parseISO(employerEndDate), dateFormat)
    : 'Present';

  const showEmployerBadge = !!employerName;

  return (
    <WizardHeader
      progress={progress}
      headers={headers}
      currentStep={currentStep}
      handleClose={() => {
        dispatch(clearCurrentPslfForm());
        dispatch(redirectTo('/pslf'));
      }}
      handleHeaderClick={handleHeaderClick}
    >
      <Title>
        <Header as={isMobile ? 'h4' : 'h3'}>
          Public Service Loan Forgiveness
        </Header>
      </Title>
      {showEmployerBadge && (
        <Badge>
          <Text as="span" bold size={isMobileOrTablet ? 'small' : 'medium'}>
            {formattedEmployerName}
          </Text>

          {employerStartDate && (
            <Text
              as="span"
              size={isMobileOrTablet ? 'small' : 'medium'}
              style={{ whiteSpace: 'nowrap' }}
            >
              &nbsp; {formattedStartDate} – {formattedEndDate}
            </Text>
          )}
        </Badge>
      )}
    </WizardHeader>
  );
};

const Title = styled.div``;

const Badge = styled.div`
  padding: 8px 12px;
  background-color: ${COLORS.lighterGrey};
  border: solid 1px ${COLORS.grey};
  border-radius: 3px;
  margin: 0 16px;

  @media (max-width: ${TabletSize}) {
    margin: 8px 0 0 0;
    white-space: nowrap;
    padding: 4px 8px;
  }
`;

const mapStateToProps = state => ({
  ...pick(getUserCurrentPslfForm(state), [
    'employerName',
    'employerStartDate',
    'employerEndDate',
  ]),
});

export default Platform(connect(mapStateToProps)(PslfWizardHeader));
