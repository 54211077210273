import { Formik } from 'formik';
import { includes, remove } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Header, Text, Checkboxes } from '@summer/ui-components';

import Content from 'components/pages/pslf/wizard/layout/PslfWizardContent';
import Footer from 'components/pages/pslf/wizard/layout/PslfWizardFooter';
import {
  NONE_OF_THE_ABOVE,
  employerPrimaryPurposeOptions,
} from 'constants/pslf';
import { useOnSuccess } from 'hooks/pslf';
import { updateCurrentPslfForm } from 'redux/actions/pslf.actions';
import { getUserCurrentPslfForm } from 'redux/selectors/pslf.selectors';
import { employerPrimaryPurposeSchema } from 'schemas/pslf';

import EmployerPrimaryPurposeAlert from './EmployerPrimaryPurposeAlert';

const EmployerPrimaryPurpose = ({
  currentPslfForm,
  updateCurrentPslfForm,
  navigation,
}) => {
  useOnSuccess('updateCurrentPslfForm', navigation.next);

  const handleSave = ({ employerPrimaryPurpose }) => {
    const { formId } = currentPslfForm;
    const valuesToSave = {
      formId,
      employerPrimaryPurpose,
    };

    updateCurrentPslfForm({ form: valuesToSave });
  };

  const handleChange = (event, values, setFieldValue) => {
    let newValues = [...values.employerPrimaryPurpose];
    const selectionName = event.target.id;

    if (!includes(values.employerPrimaryPurpose, selectionName)) {
      if (selectionName === NONE_OF_THE_ABOVE) {
        newValues = [];
      } else {
        remove(newValues, value => {
          return value === NONE_OF_THE_ABOVE;
        });
      }
      newValues.push(selectionName);
    } else {
      remove(newValues, value => {
        return value === selectionName;
      });
    }

    setFieldValue('employerPrimaryPurpose', newValues);
  };

  const initialValues = {
    employerPrimaryPurpose: currentPslfForm.employerPrimaryPurpose,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSave}
      validationSchema={employerPrimaryPurposeSchema}
      validateOnMount
    >
      {({
        values,
        errors,
        handleSubmit,
        touched,
        isSubmitting,
        setFieldValue,
        isValid,
        submitCount,
      }) => {
        return (
          <Content navigation={navigation}>
            <form onSubmit={handleSubmit}>
              <Container>
                <PslfQuestionHeader>
                  <Header as="h2">
                    Does your employer provide one the following functions as
                    its primary purpose?
                  </Header>
                </PslfQuestionHeader>
                <PslfQuestionText>
                  <Text paragraph>
                    In addition to 501(c)(3)s, the Department of Education
                    recognizes certain private non-profits on the basis that
                    they provide at least one of 13 public services as their
                    primary purpose (listed below).
                  </Text>
                </PslfQuestionText>
                <EmployerPrimaryPurposeOptions>
                  <Checkboxes
                    numberOfCol={2}
                    onChange={event =>
                      handleChange(event, values, setFieldValue)
                    }
                    selections={values.employerPrimaryPurpose}
                    options={employerPrimaryPurposeOptions}
                    touched={touched}
                    isSubmitting={isSubmitting}
                    hideError
                    errors={errors.employerPrimaryPurpose}
                  />
                </EmployerPrimaryPurposeOptions>
                <EmployerPrimaryPurposeAlert values={values} />
              </Container>
              <Footer
                handleBack={navigation.back}
                isValid={isValid}
                submitCount={submitCount}
              />
            </form>
          </Content>
        );
      }}
    </Formik>
  );
};

const PslfQuestionHeader = styled.div`
  margin-bottom: 16px;
`;

const PslfQuestionText = styled.div`
  margin-bottom: 16px;
`;

const EmployerPrimaryPurposeOptions = styled.div`
  margin-bottom: 24px;
`;

const Container = styled.div`
  max-width: 520px;
  margin: auto;
`;

const mapStateToProps = state => ({
  currentPslfForm: getUserCurrentPslfForm(state),
});

export default connect(mapStateToProps, {
  updateCurrentPslfForm,
})(EmployerPrimaryPurpose);
