import { useSelector } from 'react-redux';

import {
  getIsRetirementMatchV1Enabled,
  getIsContributionsEnabled,
} from 'redux/selectors/flags.selectors';
import {
  partnerApiUserCanSkipOnboarding,
  getUserAuthMechanism,
} from 'redux/selectors/user.selectors';

export const useUserShouldSkipOnboarding = () => {
  const isSLCEnabled = useSelector(getIsContributionsEnabled);

  const onboardingInfoFromPartnerApi = useSelector(
    partnerApiUserCanSkipOnboarding,
  );

  const isAdp = useSelector(getUserAuthMechanism) === 'adp';
  const isRMV1Enabled = useSelector(getIsRetirementMatchV1Enabled);

  const enabledRetirementMatch = isAdp && isRMV1Enabled;

  return isSLCEnabled || onboardingInfoFromPartnerApi || enabledRetirementMatch;
};
