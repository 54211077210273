import { set } from 'lodash/fp';
import { REHYDRATE } from 'redux-persist/lib/constants';

import { FINISH_LOADING, START_LOADING } from 'constants/actionTypes';

const initialState = {};

const loadingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case START_LOADING:
      return set(payload.label, true, state);

    case FINISH_LOADING:
      return set(payload.label, false, state);

    case REHYDRATE:
      return {};

    default:
      return state;
  }
};

export default loadingReducer;
