import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Text, FlexCenter } from '@summer/ui-components';

import OverpaymentsTabs from 'components/pages/overpayments/dashboard/OverpaymentsTabs/';
import OverpaymentsModal from 'components/pages/overpayments/recommendation/OverpaymentsModal';
import LandingPage from 'components/shared/dashboard/LandingPage';
import { Link } from 'components/shared/typography';
import { MobileSize } from 'constants/styleguide';
import { getOverpaymentsRecommendation } from 'redux/selectors/recEngine.selectors';

const Subheader = () => (
  <Text paragraph weight="medium">
    If you&rsquo;re able to pay more than the monthly minimum, you could pay off
    your loans sooner and save money on interest.{' '}
    <OverpaymentsModal displayStyle="inline">
      <Link inheritColor underline>
        Learn more
      </Link>
    </OverpaymentsModal>
  </Text>
);

const Overpayments = ({ isRecommendedOverpayments }) => (
  <LandingPage
    headerTheme="violet"
    headerName="Making extra payments"
    isRecommended={isRecommendedOverpayments}
    subHeader={<Subheader />}
  >
    <TabsContainer>
      <OverpaymentsTabs />
    </TabsContainer>
  </LandingPage>
);

const TabsContainer = styled(FlexCenter)`
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
  margin: 16px 16px 36px;
  @media (min-width: ${MobileSize}) {
    margin-top: 48px;
  }
`;

const mapStateToProps = state => ({
  isRecommendedOverpayments: getOverpaymentsRecommendation(state),
});

export default connect(mapStateToProps)(Overpayments);
