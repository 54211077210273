import React from 'react';

import { Form } from 'components/pages/idr/wizard/shared/styles';
import {
  SpouseDateOfBirth,
  SpouseEmail,
  SpouseName,
} from 'components/pages/idr/wizard/steps/Submit/fields';
import { useFormDrawerFocus } from 'components/shared/FormDrawers';

const SpouseInfo = ({ formik, show }) => {
  const formRef = React.useRef(null);
  useFormDrawerFocus(formRef, show);

  return (
    <Form ref={formRef}>
      <SpouseName formik={formik} />
      <SpouseEmail formik={formik} />
      <SpouseDateOfBirth formik={formik} />
    </Form>
  );
};

export default SpouseInfo;
