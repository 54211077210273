import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Text, COLORS } from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';
import { getHideSupportChat } from 'redux/selectors/user.selectors';
import { openChat } from 'services/chat';

const CallOrChat = ({ hideSupportChat }) => (
  <Container>
    <Divider />
    <Text paragraph center bold>
      Have questions? We’re here to help!
    </Text>
    <Text paragraph center>
      {!hideSupportChat && (
        <>
          <Link onClick={openChat}>Chat now</Link> or email
        </>
      )}
      {hideSupportChat && 'Email'} us at{' '}
      <Link href={`mailto:${supportAddress}`}>{supportAddress}</Link>
    </Text>
  </Container>
);

const Container = styled.div`
  max-width: 472px;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  & > * {
    margin-bottom: 12px;
  }

  & > .text {
    padding: 0 8px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLORS.grey};
  margin: 40px 0 32px;
`;

const mapStateToProps = state => ({
  hideSupportChat: getHideSupportChat(state),
});

export default connect(mapStateToProps)(CallOrChat);
