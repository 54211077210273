import React from 'react';
import styled from 'styled-components';
import { map, includes, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { Text } from '../../typography';
import { FlexColumn, FlexRow } from '../../Flex';
import Checkbox from '../Checkbox';

const MAX_NUMBER_OF_COLUMN = 2;

const Checkboxes = ({
  selections,
  options,
  onChange,
  numberOfCol,
  validate,
  errors,
  touched,
  isSubmitting,
  hideError,
}) => {
  const showErrorMessage = (touched || isSubmitting) && !isEmpty(errors);

  return (
    <MainBox>
      <Container>
        {map(options, item => {
          const { label, name } = item;
          const checkboxColumns =
            numberOfCol > MAX_NUMBER_OF_COLUMN
              ? MAX_NUMBER_OF_COLUMN
              : numberOfCol;
          return (
            <Checkbox
              key={name}
              name={name}
              label={label}
              onChange={onChange}
              numberOfCol={checkboxColumns}
              validate={validate}
              checked={includes(selections, name)}
            />
          );
        })}
      </Container>
      {!hideError && (
        <ErrorMessage>
          {showErrorMessage ? (
            <Text size="small" color="error">
              {errors[0]}
            </Text>
          ) : (
            ''
          )}
        </ErrorMessage>
      )}
    </MainBox>
  );
};

const MainBox = styled(FlexColumn)`
  width: 100%;
`;

const Container = styled(FlexRow)`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`;

const ErrorMessage = styled.div`
  min-height: calc(14px * 1.5);
`;

Checkboxes.propTypes = {
  selections: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  numberOfCol: PropTypes.number,
  validate: PropTypes.func,
  errors: PropTypes.array,
  touched: PropTypes.object,
  isSubmitting: PropTypes.bool,
  hideError: PropTypes.bool,
};

export default Checkboxes;
