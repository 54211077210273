import { object, string, bool, date, array, number } from 'yup';

import { employerNameHelpMessage } from 'components/pages/idr/wizard/steps/Submit/fields/EmployerName';
import { phoneNumberSchema, stateSchema } from 'schemas/shared';
import { addPrefixToKeys } from 'utils/object';

export const consolidateLoansSchema = object().shape({
  servedInMilitary: string().nullable().required('Please make a selection'),
  keepMilitaryInterestCap: string()
    .nullable()
    .when('servedInMilitary', {
      is: 'true',
      then: string()
        .nullable()
        .required('Please make a selection')
        .notOneOf(
          ['true'],
          'Unfortunately Summer cannot apply for the military ' +
            'interest rate cap for you. Please contact your servicer to do so. ' +
            'Once the military interest rate cap has been applied, you can come back to Summer',
        ),
    }),
});

export const personalInfoSchema = {
  firstName: string().required('Please enter your first name'),
  lastName: string().required('Please enter your last name'),
  formerName: string(),
  dateOfBirth: date()
    .nullable()
    .required('Please enter your date of birth')
    .min('1900-01-01', 'Please enter a valid date')
    .max(new Date(), 'Please enter a valid date'),
  streetAddress1: string()
    .nullable()
    .required('Please enter your street address'),
  city: string().nullable().required('Please enter your city'),
  state: stateSchema,
  zipcode: string()
    .nullable()
    .required('Please enter your zip code')
    .min(5, 'Please enter a 5-digit zip code'),
  phone: phoneNumberSchema
    .nullable()
    .required('Please enter your phone number'),
};

const employerSchema = {
  employerName: string().required(
    `Please enter your employer's name. ${employerNameHelpMessage}`,
  ),
  employerStreetAddress1: string().required(
    "Please enter your employer's street address",
  ),
  employerCity: string().required("Please enter your employer's city"),
  employerState: stateSchema,
  employerZipcode: string()
    .required("Please enter your employer's zip code")
    .min(5, 'Please enter a 5-digit zip code'),
};

export const spouseInfoSchema = userEmail => ({
  spouseFirstName: string().required(
    'Please enter your spouse\u2019s first name',
  ),
  spouseLastName: string().required(
    'Please enter your spouse\u2019s last name',
  ),
  spouseEmail: string()
    .validEmail('Please enter a valid email address')
    .test({
      name: 'notUserEmail',
      message:
        'Email address cannot be the same as the primary account holder\u2019s',
      test: value => userEmail?.toLowerCase() !== value?.toLowerCase(),
    })
    .required('Please enter your spouse\u2019s email address'),
  spouseDob: date()
    .required('Please enter your spouse\u2019s date of birth')
    .min('1900-01-01', 'Please enter a valid date')
    .max(new Date(), 'Please enter a valid date'),
});

export const yourContactInfoSchema = (
  userEmail,
  requiresSpouseInfo,
  includesConsolidation,
) =>
  object().shape({
    ...personalInfoSchema,
    ...(requiresSpouseInfo ? spouseInfoSchema(userEmail) : {}),
    ...(includesConsolidation ? employerSchema : {}),
  });

const referenceSchema = {
  firstName: string()
    .nullable()
    .required("Please enter your reference's first name"),
  lastName: string()
    .nullable()
    .required("Please enter your reference's last name"),
  email: string().validEmail('Please enter a valid email address').nullable(),
  phone: phoneNumberSchema
    .required('Please enter your phone number')
    .nullable(),
  streetAddress1: string().nullable().required('Please enter a street address'),
  city: string().nullable().required('Please enter a city'),
  state: stateSchema,
  zipcode: string()
    .nullable()
    .required('Please enter a zip code')
    .min(5, 'Please enter a 5-digit zip code'),
  relationshipToUser: string().nullable().required('Please enter a value'),
};

const getReferenceSchema = prefix =>
  object().shape(addPrefixToKeys(referenceSchema, prefix));

export const referencesSchema = getReferenceSchema('reference1').concat(
  getReferenceSchema('reference2'),
);

export const yourLoansSchema = object().shape({
  isValid: bool().equals([true], 'Loan has missing info'),
});

export const getYourLoansSchema = userHasPerkinsLoans =>
  object().shape({
    isValid: bool().equals([true], 'Loan has missing info'),
    userWillPursuePlc: userHasPerkinsLoans
      ? string()
          .nullable()
          .oneOf(['true', 'false'])
          .required('Please confirm before proceeding')
      : string().nullable().oneOf(['true', 'false']),
  });

export const hasLoansFromGradSchoolSchema = object().shape({
  hasLoansFromGradSchool: string()
    .oneOf(['true', 'false'])
    .required('Please select if you have loans from grad school'),
});

const isCurrentlyEnrolledInIdrError =
  'Please select if you are currently enrolled in IDR';
export const isCurrentlyEnrolledInIdrSchema = object().shape({
  isCurrentlyEnrolledInIdr: string()
    .nullable()
    .oneOf(['yes', 'no'], isCurrentlyEnrolledInIdrError)
    .required(isCurrentlyEnrolledInIdrError),
});

export const idrRecertDateSchema = object().shape({
  idrRecertDate: date()
    .nullable()
    .required('Please enter the recertification date for your IDR plan'),
});

export const idrRepaymentPlanTypeIdSchema = object().shape({
  idrRepaymentPlanTypeId: string().required(
    'Please select the IDR plan you are enrolled in',
  ),
});

export const consolidationIncludedParentPlusSchema = object().shape({
  consolidationIncludedParentPlus: string()
    .oneOf(['true', 'false'])
    .required(
      'Please select if any of your consolidated loans include Parent Plus loans',
    ),
});

export const supportingDocsPayFrequencyIrsDisclosureSchema = (
  userPayStubRequested,
  spousePayStubRequested,
) =>
  object().shape({
    consentToIrsDisclosure: string()
      .nullable()
      .required('Please make a selection'),
    isValid: bool().equals([true], 'files are missing'),
    ...(userPayStubRequested && {
      payFrequency: string().nullable().required('Please make a selection'),
    }),
    ...(spousePayStubRequested && {
      spousePayFrequency: string()
        .nullable()
        .required('Please make a selection for your spouse'),
    }),
  });

export const signAndSendSchema = object().shape({
  isValid: bool().equals([true], 'Please sign above to proceed'),
});

export const confirmServicersSchema = object().shape({
  servicersToSendIDR: array()
    .of(string())
    .required('Please choose at least one servicer'),
  deferment: string().nullable().required('Please make a selection'),
  multipleServicers: bool().required(),
});

export const confirmServicersSchemaConsolidation = object().shape({
  targetServicerId: string().nullable().required('Please choose a servicer'),
  deferment: string().nullable().required('Please make a selection'),
  multipleServicers: bool().required(),
  inGracePeriod: string()
    .nullable()
    .when('deferment', {
      is: 'no',
      then: string().nullable().required('Please make a selection'),
    }),
  remainInGracePeriod: string()
    .nullable()
    .when('inGracePeriod', {
      is: 'true',
      then: string().nullable().required('Please make a selection'),
      otherwise: string().nullable(),
    }),
  gracePeriodEndDate: date().when('remainInGracePeriod', {
    is: 'true',
    then: date().required('Please enter your grace period end date.'),
    otherwise: date().nullable(),
  }),
});

export const reviewYourPathsSchema = object().shape({
  selectedPathId: number()
    .nullable()
    .required('Please select a plan before proceeding'),
});
