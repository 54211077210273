import _ from 'lodash';
import fp from 'lodash/fp';

export const renderWizardSteps = steps => {
  return steps.map(step => step.render());
};

export const getCurrentStepFromNavigation = _.flow(
  fp.get('path.current'),
  fp.join('.'),
);
