import React from 'react';

import { Header, InfoTooltip } from '@summer/ui-components/src';

import { GridContainer, MoneyInputCSP, PercentageInputCSP } from './shared';

const CurrentFutureSavings = ({ formik }) => (
  <>
    <Header h3>Current and Future Savings</Header>
    <GridContainer>
      <MoneyInputCSP
        name="currentSavings"
        label="How much do you currently have saved for college?"
        formik={formik}
      />
      <MoneyInputCSP
        name="plannedContribution"
        label="How much do you plan to save for college each month?"
        formik={formik}
      />
      <PercentageInputCSP
        name="rateOfReturn"
        label={
          <>
            Expected annual rate of return on savings{' '}
            <InfoTooltip text="As a proxy, the average return of the S&P 500 for the last 20 years when adjusted for inflation was approximately 6-7%. Your actual rate of return will depend on your risk tolerance and time horizon." />
          </>
        }
        formik={formik}
      />
    </GridContainer>
  </>
);

export default CurrentFutureSavings;
