import {
  SET_LOANS,
  SET_PLAID_LOANS,
  SET_LOAN,
  FETCH_LOANS,
  FETCH_ALL_LOANS,
  UPDATE_LOAN,
} from 'constants/actionTypes';

export const fetchLoans = successAction => ({
  type: FETCH_LOANS,
  successAction,
});

export const fetchAllLoans = (successAction, onSuccess) => ({
  type: FETCH_ALL_LOANS,
  successAction,
  onSuccess,
});

export const updateLoan = (loan, successAction) => ({
  type: UPDATE_LOAN,
  loan,
  successAction,
});

export const setLoans = loans => ({
  type: SET_LOANS,
  payload: loans,
});

export const setPlaidLoans = loans => ({
  type: SET_PLAID_LOANS,
  payload: loans,
});

export const setLoan = loan => ({
  type: SET_LOAN,
  payload: loan,
});
