import React from 'react';

import { FancyCard } from '@summer/ui-components';

import { isTestEnv } from 'utils/common';

const FancyCardWebApp = props => (
  <FancyCard
    {...props}
    headerInfo={props.headerInfo?.map(headerInfo => ({
      ...headerInfo,
      animate: headerInfo.animate && !isTestEnv(),
    }))}
  />
);

export default FancyCardWebApp;
