import {
  FETCH_LOANS,
  FETCH_ALL_LOANS,
  NETWORK_SUCCESS,
  UPDATE_LOAN,
} from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import { setLoans, setPlaidLoans, setLoan } from 'redux/actions/loans.actions';
import handleSideEffects from 'redux/handleSideEffects';

const loansMiddleware = handleSideEffects({
  [FETCH_LOANS]: (dispatch, { successAction }) => {
    dispatch(
      apiRequest({
        url: '/loans',
        method: 'GET',
        fromAction: FETCH_LOANS,
        label: 'fetchLoans',
        meta: { successAction },
      }),
    );
  },

  [FETCH_ALL_LOANS]: (dispatch, { successAction, onSuccess }) => {
    dispatch(
      apiRequest({
        url: '/v2/loans',
        method: 'GET',
        fromAction: FETCH_ALL_LOANS,
        label: 'fetchAllLoans',
        meta: { successAction, onSuccess },
      }),
    );
  },

  [UPDATE_LOAN]: (dispatch, { loan, successAction }) => {
    dispatch(
      apiRequest({
        url: '/loans',
        method: 'PATCH',
        body: loan,
        fromAction: UPDATE_LOAN,
        label: 'updateLoan',
        meta: { successAction },
      }),
    );
  },

  [NETWORK_SUCCESS]: (dispatch, { payload, meta }) => {
    if (meta.fromAction === FETCH_LOANS) {
      dispatch(setLoans(payload.response));
      // eslint-disable-next-line no-unused-expressions
      meta.successAction && dispatch(meta.successAction());
    }

    if (meta.fromAction === FETCH_ALL_LOANS) {
      dispatch(setLoans(payload.response.fsa));
      dispatch(setPlaidLoans(payload.response.plaid));

      // eslint-disable-next-line no-unused-expressions
      meta.successAction && dispatch(meta.successAction());
      // eslint-disable-next-line no-unused-expressions
      meta.onSuccess && meta.onSuccess();
    }

    if (meta.fromAction === UPDATE_LOAN) {
      dispatch(setLoan(payload.response));
      // eslint-disable-next-line no-unused-expressions
      meta.successAction && dispatch(meta.successAction());
    }
  },
});

export default loansMiddleware;
