import React from 'react';
import styled from 'styled-components';

import {
  Header,
  Text,
  Button,
  FlexColumn,
  FlexCenter,
  Lottie,
  SubmitCelebrationLottie,
} from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import ModalPopup from 'components/shared/ModalPopup';
import { MobileSize } from 'constants/styleguide';

const PslfCompleteModal = ({ isOpen, togglePopup, platformType }) => {
  const isMobile = platformType === MOBILE;
  return (
    <ModalPopup
      isOpen={isOpen}
      togglePopup={togglePopup}
      width={770}
      component={() => {
        const imgWidth = isMobile ? '240px' : '295px';
        const imgHeight = isMobile ? '201px' : '286px';

        return (
          <Container>
            <FlexCenter>
              <ImageWrapper width={imgWidth} height={imgHeight}>
                <Lottie
                  autoplay={true}
                  loop={false}
                  speed={0.5}
                  animationData={SubmitCelebrationLottie}
                />
              </ImageWrapper>
            </FlexCenter>
            <Right>
              <Header as={isMobile ? 'h2' : 'h1'}>
                You’re all set! We’ll take it from here.
              </Header>
              <Text as="p" paragraph>
                We’ll turn your certification into official paperwork and
                collect your employer’s signature through our partner HelloSign.
              </Text>
              <Text as="p" paragraph>
                You’ll get a confirmation email from us once everything is
                complete.
              </Text>
            </Right>
            {isMobile && (
              <ButtonContainer>
                <ButtonWrapper>
                  <Button type="submit" onClick={togglePopup}>
                    Close
                  </Button>
                </ButtonWrapper>
              </ButtonContainer>
            )}
          </Container>
        );
      }}
    />
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 16px 24px 16px;
  @media (max-width: ${MobileSize}) {
    overflow: scroll;
    flex-direction: column;
    padding: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ButtonWrapper = styled.div`
  width: 135px;
`;

const ImageWrapper = styled.div`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

const Right = styled(FlexColumn)`
  padding: 16px 0 16px 40px;

  & > h1 {
    margin-bottom: 16px;
  }
  & > h2 {
    margin-bottom: 12px;
  }
  & > p:not(:last-child) {
    margin-bottom: 16px;
  }
  @media (max-width: ${MobileSize}) {
    margin: 16px 0;
    padding: 0;
  }
`;

export default Platform(PslfCompleteModal);
