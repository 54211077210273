import get from 'lodash/get';

export const getUiSetting = (state, key) => get(state.ui.settings, key);

export const isLoading = (state, key) => !!get(state.ui.loading, key);
// Curried version of isLoading for useSelector which expects one arg
export const isLoadingFunctional = key => state => isLoading(state, key);

/**
 * Loading selector with init true
 * 1. With this loading we can prevent multiply render of components
 *    when we need loading condition
 * 2. this loading is not good for loading that Happen after user interction
 */
export const isLoadingWithInit = (state, key) => {
  const loadingState = get(state.ui.loading, key);
  return loadingState ?? true;
};

export const getErrorMessage = (state, key) => {
  const { message } = get(state.ui.serverErrors, key) || {};
  return message;
};

export const isConnectFsaModalOpen = state => state.ui.connectFsaModal.isOpen;

export const getToastList = state => state.ui.toast;

export const isSidebarVisible = state => state.ui.sidebar.visible;

export const getS3UploadProgress = state => state.ui.s3UploadProgress;

export const getShouldShowQuestionnaire = state =>
  state.ui.idr.questionnaire.shouldShowQuestionnaire;

export const getQuestionnaireInitialStep = state =>
  state.ui.idr.questionnaire.initialStep;

export const getTuitionReimbursementUi = state =>
  state.ui.tuitionReimbursementUi;
