import React from 'react';
import styled from 'styled-components';

import { Text } from '@summer/ui-components';

import ServicerLogo, {
  doesServicerHaveLogo,
} from 'components/shared/Servicer/ServicerLogo';

const ServicerLogoOrName = ({ institutionId, servicerName }) => {
  if (doesServicerHaveLogo(institutionId)) {
    return (
      <ServicerLogo servicerName={servicerName} institutionId={institutionId} />
    );
  }
  return <Name>{servicerName}</Name>;
};

const Name = styled(Text)`
  max-width: 170px;
`;

export default ServicerLogoOrName;
