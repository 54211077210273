// App
export const RESET_APP = '[APP] Reset';
export const API_REQUEST = '[API] API Request';
export const NETWORK_PENDING = '[API] Network Pending';
export const NETWORK_SUCCESS = '[API] Network Success';
export const NETWORK_FAILURE = '[API] Network Failure';
export const NETWORK_CLEAR = '[API] Clear Network Success / Failure status';
export const DEFAULT_ACTION = '[APP] Default action';

// Onboarding
export const FETCH_CURRENT_STEP = '[ONBOARDING] Fetch current step';
export const SET_NEXT_STEP = '[ONBOARDING] Set next step';
export const SET_CURRENT_STEP = '[ONBOARDING] Set current step';
export const FINISH_WELCOME = '[ONBOARDING] finish welcome page';
export const GO_BACK_ONBOARDING = '[ONBOARDING] go back a page';
export const SET_PAGE_INFO = '[ONBOARDING] set info for page';
export const SET_POST_ONBOARDING_DESTINATION =
  '[ONBOARDING] set post onboarding destination';

// auth
export const LOGIN = '[AUTH] Login';
export const LOGOUT = '[AUTH] Logout';
export const SET_LOGOUT = '[AUTH] Logout user';
export const FORGOT_PASSWORD = '[AUTH] Forgot Password';
export const RESET_PASSWORD = '[AUTH] Reset Password';
export const CHECK_USER = '[AUTH] Check User';
export const SET_VERIFICATION_TOKEN = '[AUTH] Set verification token';
export const KEEP_SESSION_ALIVE = '[AUTH] Keep session alive';

// oauth
export const POST_OAUTH_CODE = '[OAUTH] Post authorization code';

// User
export const CREATE_ACCOUNT = '[USER] Create account';
export const CREATE_ACCOUNT_PARTIAL_DATA =
  '[USER] Create account with partial data';
export const FILL_SURVEY = '[USER] Fill survey';
export const SET_USER = '[USER] Set current user';
export const SET_USER_SURVEY_DATA = '[USER] Set survey data';
export const UPDATE_EMAIL = '[USER] Update email address';
export const UPDATE_PASSWORD = '[USER] Update password';
export const SET_USER_EMAIL = '[USER] Set user email address in ui';
export const VERIFY_USER_EMAIL = '[USER] Verify user email address';
export const CHECK_EMAIL_IS_VERIFIED = '[USER] Check user email is verified';
export const VERIFY_USER_EMAIL_SUCCESS =
  '[USER] Successfully verified user email address';
export const SEND_ACCOUNT_VERIFICATION_EMAIL =
  '[USER] Send account verification email';
export const SET_UI_SETTING = '[USER] Set ui setting';
export const SET_IN_SLC = '[USER] Set inSLC';

export const UPDATE_IS_CURRENTLY_ENROLLED_IN_IDR =
  '[USER] Update isCurrentlyEnrolledInIdr';
export const SET_IS_CURRENTLY_ENROLLED_IN_IDR =
  '[USER] Set isCurrentlyEnrolledInIdr';

export const HYDRATE_USER = '[USER] Hydrate user data';

// profile
export const UPDATE_PROFILE = '[PROFILE] Update fields on profile';
export const SET_PROFILE = '[PROFILE] Set fields on profile';

// financial profile
export const SET_FINANCIAL_PROFILE =
  '[FINANCIAL PROFILE] Set fields on financial profile';

// rec engine
export const FETCH_RECOMMENDATIONS =
  '[REC ENGINE] Fetch recommendations for user';
export const SET_RECOMMENDATIONS = '[REC ENGINE] Set recommendations for user';

// loans
export const FILL_LOAN_INFO = '[LOANS] Fill loan info';
export const SET_LOAN_INFO = '[LOANS] Set loan info';

export const SET_LOANS = '[LOANS] Set loans';
export const SET_PLAID_LOANS = '[LOANS] Set Plaid loans';
export const SET_LOAN = '[LOANS] Set loan';
export const FETCH_LOANS = '[LOANS] Fetch loans';
export const FETCH_ALL_LOANS = '[LOANS] Fetch all loans';
export const UPDATE_LOAN = '[LOANS] Edit a loan';

// plaid
export const POST_PLAID_INTEGRATION = '[PLAID] Post Plaid integration';
export const DELETE_PLAID_INTEGRATION = '[PLAID] Delete Plaid Integration';
export const CREATE_PLAID_LINK_TOKEN = '[PLAID] Create Plaid link token';
export const CREATE_PLAID_UPDATE_LINK_TOKEN =
  '[PLAID] Create Plaid Update mode link token';
export const REENABLE_PLAID_INTEGRATION =
  '[PLAID] Reenable and resync disabled plaid integration';
export const FETCH_PLAID_INTEGRATIONS = '[PLAID] Fetch Plaid Integrations';
export const SET_PLAID_INTEGRATIONS = '[PLAID] Set Plaid Integrations';
export const FETCH_PLAID_TRANSACTIONS = '[PLAID] Fetch Plaid Transactions';
export const SET_PLAID_TRANSACTIONS = '[PLAID] Set Plaid Transactions';

// ui
export const START_LOADING = '[UI] Start Loading';
export const FINISH_LOADING = '[UI] Finish Loading';
export const SHOW_SERVER_ERROR = '[UI] Show server error';
export const CLEAR_SERVER_ERROR = '[UI] Clear server error';
export const LOG_TOOLTIP = '[UI] User Open Tooltip';
export const SET_SIDEBAR_VISIBILITY = '[UI] Set Sidebar Open / Closed';
export const OPEN_EDIT_A_LOAN = '[UI] Open Manage Loans modal - Edit A Loan';
export const CLOSE_MANAGE_LOANS = '[UI] Close Manage Loans modal';
export const NEXT_LOAN = '[UI] Increment current loan index in modal';
export const PREV_LOAN = '[UI] Decrement current loan index in modal';
export const OPEN_CONNECT_FSA_MODAL = '[UI] Open Sync Loans modal';
export const CLOSE_CONNECT_FSA_MODAL = '[UI] Close Sync Loans modal';
export const OPEN_VERIFY_MODAL = '[UI] Open verify modal';
export const CLOSE_VERIFY_MODAL = '[UI] Close verify modal';
export const SET_S3_UPLOAD_PROGRESS = '[UI] Set S3 progress';
export const TOGGLE_IDR_QUESTIONNAIRE = '[UI] Toggle IDR Questionnaire';
export const SET_QUESTIONNAIRE_INITIAL_STEP =
  '[UI] Set IDR Questionnaire Initial Step';
export const SET_TUITION_REIMBURSEMENT_UI = '[UI] Set tuition reimbursement UI';

// constants
export const FETCH_LOAN_TYPES = '[CONSTANTS] Fetch loan types';
export const SET_LOAN_TYPES = '[CONSTANTS] Set loan types';
export const FETCH_SERVICERS = '[CONSTANTS] Fetch servicers';
export const SET_SERVICERS = '[CONSTANTS] Set servicers';
export const FETCH_REPAYMENT_PLAN_TYPES =
  '[CONSTANTS] Fetch repayment plan types';
export const SET_REPAYMENT_PLAN_TYPES = '[CONSTANTS] Set repayment plan types';

// services
export const IDENTIFY = '[ANALYTICS] Identify user';
export const TRACK_ACTION = '[ANALYTICS] Track action';
export const TRACK_SERVER_SIDE = '[ANALYTICS] Track server side';

// idr
export const FETCH_IDR_PATH_OPTIONS = '[IDR] Fetch IDR path options';
export const CLEAR_IDR_PATH_OPTIONS = '[IDR] Clear IDR plan options';
export const SET_IDR_PATH_OPTIONS = '[IDR] Set IDR plan options';
export const SET_IDR_SELECTED_PATH_ID = '[IDR] Set IDR selectedPathId';

export const SET_IDR_CURRENT_STEP = '[IDR] Set IDR step';
export const MOVE_TO_IDR_CURRENT_STEP = '[IDR] Move To current step';
export const SAVE_IDR_CURRENT_STEP = '[IDR] Save IDR step';

export const FETCH_IDR_FORMS = '[IDR] Fetch IDR forms';
export const SET_IDR_FORMS = '[IDR] Set IDR forms';
export const CREATE_IDR_FORM = '[IDR] Create IDR form';
export const UPDATE_CURRENT_IDR_FORM = '[IDR] Update current IDR form';
export const SET_CURRENT_IDR_FORM = '[IDR] Set IDR fields value in reducer';
export const IDR_NETWORK_SUCCESS = '[IDR] Track IDR network success';
export const IDR_NETWORK_CLEAR = '[IDR] Clear IDR network successs';

// PSLF
export const FETCH_PSLF_FORMS = '[PSLF] Fetch PSLF forms';
export const SET_PSLF_FORMS = '[PSLF] Set PSLF forms';
export const CREATE_PSLF_FORM = '[PSLF] Create PSLF form';
export const FETCH_CURRENT_PSLF_FORM = '[PSLF] Fetch current PSLF form';
export const SET_CURRENT_PSLF_FORM = '[PSLF] Set current PSLF form';
export const CLEAR_CURRENT_PSLF_FORM = '[PSLF] Clear current PSLF form';
export const UPDATE_CURRENT_PSLF_FORM = '[PSLF] Update current PSLF form';
export const SAVE_PSLF_CURRENT_STEP =
  '[PSLF] Save current step of current PSLF form';
export const SET_PSLF_CURRENT_STEP =
  '[PSLF] Set current step of current PSLF form';
export const FETCH_PSLF_CALCULATIONS = '[PSLF] Fetch PSLF calculations';
export const SET_PSLF_CALCULATIONS = '[PSLF] Set PSLF calculations';
export const PSLF_NETWORK_SUCCESS = '[PSLF] Track PSLF network success';
export const PSLF_NETWORK_CLEAR = '[PSLF] Clear PSLF network successs';

// Toast
export const SHOW_TOAST = '[TOAST] Show toast';
export const REMOVE_TOAST = '[TOAST] Remove toast';

// Sync
export const FETCH_SYNC_STATUS = '[SYNC] Fetch sync status';
export const DELETE_SYNC = '[SYNC] Delete sync';
export const SET_SYNCS = '[SYNC] Set syncs';

// upload files
export const UPLOAD_FILE = '[FILES] Upload user file';
export const UPLOAD_FILE_V4 = '[FILES] Upload user file v4';
export const UPLOAD_FILE_S3 = '[FILES] UPLOAD_FILE_S3';
export const FETCH_UPLOAD_URL = '[FILES] Fetch Upload url';
export const FETCH_UPLOAD_URL_V4 = '[FILES] Fetch Upload url v4';
export const FINISH_UPLOAD = '[FILES] Finish Upload user file';
export const FINISH_UPLOAD_V4 = '[FILES] Finish Upload user file v4';
export const FETCH_UPLOAD_URL_FORM = '[FILES] Fetch Upload url for form';
export const FINISH_UPLOAD_FORM = '[FILES] Finish Upload file for form';
export const SET_FILE = '[FILES] Set user file';
export const DELETE_FILE = '[FILES] Delete user file form s3';
export const SET_FILE_COLLECTIONS = '[FILES] Set file collections';
export const CLEAR_FILE_COLLECTIONS = '[FILES] Clear file collections state';
export const UPLOAD_ERROR = '[FILES] Error when upload file';
export const UPLOAD_SUCCESS = '[FILES] Upload file Success';
export const S3_REQUEST = '[S3] s3 api request';
export const REMOVE_FILES_WITH_ERROR = '[FILES] Remove files with error';
export const REMOVE_SINGLE_FILE_WITH_ERROR = '[FILES] Remove a file with error';
export const REMOVE_FILES_WITH_COLLECTION =
  '[FILES] Remove files with collection';
export const FETCH_FILE_COLLECTION =
  '[FILES] Get files of a specific collection type';
export const FETCH_FILE_DOWNLOAD_LINK = '[FILES] Fetch file download link';

// cms
export const CMS_FETCH_DOCS_BY_TYPE = '[CMS] Fetch docs by type';
export const CMS_FETCH_DOC_BY_UID = '[CMS] Fetch doc by uid';
export const CMS_SUCCESS = '[CMS] Network Success';
export const CMS_FAILURE = '[CMS] Network Failure';

// FF
export const FETCH_ALL_FF_PROGRAMS = '[FF] fetch all programs';
export const FETCH_FF_PROGRAM_BY_UID = '[FF] fetch program by id';
export const SET_FF_PROGRAMS = '[FF] Set programs';
export const SET_FF_FILTERS = '[FF] Set filters';

// signature Requests
export const FETCH_SIGNATURE_REQUEST_LIST_V3 =
  '[signature request] fetch list of signature request v3';
export const SET_SIGNATURE_REQUEST_LIST =
  '[signature request] set list of signature request';
export const FETCH_SIGNATURE_REQUEST_JWT =
  '[signature] Fetch signature request with JWT';
export const UPDATE_SIGNATURE_REQUEST =
  '[signature request] update signature request';
export const SET_SIGNATURE_REQUEST = '[signature] Set signature request';
export const UPDATE_SIGNATURE_REQUEST_JWT =
  '[signature request] update signature request with jwt';

// SMS Factor
export const CREATE_SMS_FACTOR = '[SMS Factor] Create sms factor';
export const VERIFY_SMS_FACTOR = '[SMS Factor] Verify sms factor';
export const SEND_SMS_FACTOR_OTP = '[SMS Factor] Send sms factor';
export const SET_SMS_FACTOR = '[SMS Factor] Set sms factor';

// Paywall
export const CREATE_STRIPE_CHECKOUT =
  '[Paywall] Create stripe checkout session';
export const UPDATE_STRIPE_STATUS = '[Paywall] Update stripe status';
export const SET_HAS_PAYMENT_SAVED = '[Paywall] Set hasPaymentSaved';

// SLC
export const SET_SLC_STEP = '[SLC] Set SLC step';

// College Savings Planner
export const FETCH_CSP_CALCULATOR_DATA =
  '[College Savings Planner] Fetch calculator data';
export const SET_CSP_CALCULATOR_DATA =
  '[College Savings Planner] Set calculator data';
export const SET_CSP_INPUTS = '[College Savings Planner] Set inputs';
export const SET_CSP_CALCULATOR_RESULTS =
  '[College Cost Planning] Set calculator results';
export const POST_CSP_CALCULATOR_RESULTS =
  '[College Cost Planning] Post calculator results';

// College Savings Planner Scholarship Database
export const FETCH_ALL_CSP_SCHOLARSHIPS =
  '[College Savings Planner] Fetch all scholarships';
export const FETCH_CSP_SCHOLARSHIP_BY_UID =
  '[College Savings Planner] Fetch scholarship by uid';
export const SET_CSP_SCHOLARSHIPS = '[College Savings Planner] Set programs';

// Tuition Reimbursement
export const FETCH_TUITION_REIMBURSEMENT =
  '[Tuition Reimbursement] Fetch tuition reimbursement';
export const SET_TUITION_REIMBURSEMENT =
  '[Tuition Reimbursement] Set tuition reimbursement';
export const POST_TUITION_REIMBURSEMENT_COURSE_REQUEST =
  '[Tuition Reimbursement] Post tuition reimbursement course request';
export const FETCH_TUITION_REIMBURSEMENT_PARTNER_CONFIG =
  '[Tuition Reimbursement] Fetch tuition reimbursement partner config';
export const SET_TUITION_REIMBURSEMENT_PARTNER_CONFIG =
  '[Tuition Reimbursement] Set tuition reimbursement partner config';
export const PATCH_TUITION_REIMBURSEMENT =
  '[Tuition Reimbursement] Patch tuition reimbursement';

// Emergency Savings
export const SET_EMERGENCY_SAVINGS =
  '[Emergency Savings] Set emeregency savings';
export const ADD_EMERGENCY_SAVINGS_PAYROLL_PROVIDER =
  '[Emergency Savings] Add emeregency savings payroll provider';
export const FETCH_EMERGENCY_SAVINGS_V1 =
  '[Emergency Savings] Get emergency savings v1';
export const POST_EMERGENCY_SAVINGS_V1 =
  '[Emergency Savings] Post emergency savings v1';

// Retirement Match
export const POST_RETIREMENT_MATCH_CONSENT =
  '[Retirement Match] Post retirement match consent';
export const CREATE_RETIREMENT_MATCH_MANUAL_MATCH =
  '[Retirement Match] Create retirement match manual match';
export const FETCH_RETIREMENT_MATCH = '[Retirement Match] Get retirement match';
export const SET_RETIREMENT_MATCH = '[Retirement Match] Set retirement match';

// Spinwheel
export const CREATE_SPINWHEEL_TOKEN = '[Spinwheel] Create spinwheel token';
export const CREATE_SPINWHEEL_USER = '[Spinwheel] Create spinwheel user';

// SLC
export const SET_SLC_USER = '[SLC] Set slc user';
export const FETCH_SLC_USER = '[SLC] Fetch slc user';
export const CREATE_SLC_USER = '[SLC] Create slc user';
