import {
  NETWORK_SUCCESS,
  FETCH_TUITION_REIMBURSEMENT,
  FETCH_TUITION_REIMBURSEMENT_PARTNER_CONFIG,
  NETWORK_FAILURE,
  POST_TUITION_REIMBURSEMENT_COURSE_REQUEST,
  PATCH_TUITION_REIMBURSEMENT,
} from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import {
  setTuitionReimbursement,
  setTuitionReimbursementPartnerConfig,
} from 'redux/actions/tuitionReimbursement.actions';
import handleSideEffects from 'redux/handleSideEffects';

const tuitionReimbursementMiddleware = handleSideEffects({
  [FETCH_TUITION_REIMBURSEMENT]: (dispatch, { meta }) => {
    dispatch(
      apiRequest({
        url: '/v1/tuition-reimbursement',
        method: 'GET',
        fromAction: FETCH_TUITION_REIMBURSEMENT,
        label: 'fetchTuitionReimbursement',
        meta,
      }),
    );
  },

  [POST_TUITION_REIMBURSEMENT_COURSE_REQUEST]: (
    dispatch,
    { payload, meta },
  ) => {
    dispatch(
      apiRequest({
        url: '/v1/tuition-reimbursement/application',
        method: 'POST',
        body: {
          ...payload,
        },
        fromAction: POST_TUITION_REIMBURSEMENT_COURSE_REQUEST,
        label: 'postTuitionReimbursementCourseRequest',
        meta,
      }),
    );
  },

  [PATCH_TUITION_REIMBURSEMENT]: (dispatch, { payload, meta }) => {
    const { uid, ...data } = payload;
    dispatch(
      apiRequest({
        url: `/v1/tuition-reimbursement/application/${uid}`,
        method: 'PATCH',
        body: data,
        fromAction: PATCH_TUITION_REIMBURSEMENT,
        label: 'patchTuitionReimbursement',
        meta,
      }),
    );
  },

  [FETCH_TUITION_REIMBURSEMENT_PARTNER_CONFIG]: (dispatch, { meta }) => {
    dispatch(
      apiRequest({
        url: '/v1/tuition-reimbursement/config',
        method: 'GET',
        fromAction: FETCH_TUITION_REIMBURSEMENT_PARTNER_CONFIG,
        label: 'fetchTuitionReimbursementPartnerConfig',
        meta,
      }),
    );
  },

  [NETWORK_SUCCESS]: (dispatch, { payload, meta }) => {
    if (
      meta.fromAction === FETCH_TUITION_REIMBURSEMENT ||
      meta.fromAction === POST_TUITION_REIMBURSEMENT_COURSE_REQUEST ||
      meta.fromAction === PATCH_TUITION_REIMBURSEMENT
    ) {
      dispatch(setTuitionReimbursement(payload.response));
      if (meta.onSuccess) {
        meta.onSuccess();
      }
    }

    if (meta.fromAction === FETCH_TUITION_REIMBURSEMENT_PARTNER_CONFIG) {
      dispatch(setTuitionReimbursementPartnerConfig(payload.response));
      if (meta.onSuccess) {
        meta.onSuccess();
      }
    }
  },

  [NETWORK_FAILURE]: (dispatch, { meta }) => {
    if (
      meta.fromAction === FETCH_TUITION_REIMBURSEMENT ||
      meta.fromAction === POST_TUITION_REIMBURSEMENT_COURSE_REQUEST ||
      meta.fromAction === FETCH_TUITION_REIMBURSEMENT_PARTNER_CONFIG ||
      meta.fromAction === PATCH_TUITION_REIMBURSEMENT
    ) {
      if (meta.onFailure) {
        meta.onFailure();
      }
    }
  },
});

export default tuitionReimbursementMiddleware;
