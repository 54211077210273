import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import {
  FlexCenter,
  FlexColumn,
  LoadingBars,
  Icon,
  BackgroundSvg,
  COLORS,
} from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import ScheduleACallAlert from 'components/layout/dashboard/alerts/ScheduleACallAlert';
import { MobileSize, TabletSize } from 'constants/styleguide';
import { hexToRgba } from 'utils/common';

const Page = ({
  children,
  renderPageHeader,
  themeColor,
  gradientColor,
  icon,
  isLoading,
  backgroundStyle,
  platformType,
}) => {
  const isMobile = platformType === MOBILE;

  return (
    <>
      {!isMobile && <ScheduleACallAlert />}
      <Container>
        {isLoading ? (
          <LoadingContainer>
            <LoadingBars color={COLORS.azure} />
          </LoadingContainer>
        ) : (
          <PageContent>
            {isMobile && (
              <MobileAlertContainer>
                <ScheduleACallAlert />
              </MobileAlertContainer>
            )}
            {isFunction(renderPageHeader) && (
              <PageHeader
                backgroundColor={
                  backgroundStyle === 'circle2'
                    ? COLORS.lightestGrey
                    : themeColor
                }
              >
                {icon && (
                  <Icon
                    SvgComponent={icon}
                    width={580}
                    height={580}
                    fill={hexToRgba(themeColor, 0.05)}
                  />
                )}
                {backgroundStyle === 'circle1' && (
                  <Circle1
                    fill={hexToRgba(themeColor, 0.12)}
                    SvgComponent={BackgroundSvg}
                  />
                )}
                {backgroundStyle === 'circle2' && (
                  <Circle2
                    themeColor={themeColor}
                    gradientColor={gradientColor}
                  />
                )}
                {renderPageHeader()}
              </PageHeader>
            )}
            {children}
          </PageContent>
        )}
      </Container>
    </>
  );
};

Page.propTypes = {
  renderPageHeader: PropTypes.func,
  isLoading: PropTypes.bool,
  center: PropTypes.bool,
  themeColor: PropTypes.string,
  gradientColor: PropTypes.string,
  icon: PropTypes.object,
  backgroundStyle: PropTypes.oneOf(['solid', 'circle1', 'circle2']),
};

Page.defaultProps = {
  renderPageHeader: null,
  isLoading: false,
  center: false,
  themeColor: COLORS.azure,
  backgroundStyle: 'solid',
};

const paddingBottom = '32px';
const paddingTopDesktop = '32px';
const paddingTopTablet = '24px';
const paddingTopMobile = '16px';
const paddingHorizDesktop = '72px';
const paddingHorizTablet = '24px';
const paddingHorizMobile = '16px';

export const fullWidthContainerInPageCss = css`
  width: calc(100% + ${parseInt(paddingHorizDesktop, 10) * 2}px);
  margin-left: -${paddingHorizDesktop};
  margin-right: -${paddingHorizDesktop};

  padding-left: ${paddingHorizDesktop};
  padding-right: ${paddingHorizDesktop};

  @media (max-width: ${TabletSize}) {
    width: calc(100% + ${parseInt(paddingHorizTablet, 10) * 2}px);

    margin-left: -${paddingHorizTablet};
    margin-right: -${paddingHorizTablet};

    padding-left: ${paddingHorizTablet};
    padding-right: ${paddingHorizTablet};
  }

  @media (max-width: ${MobileSize}) {
    width: calc(100% + ${parseInt(paddingHorizMobile, 10) * 2}px);

    margin-left: -${paddingHorizMobile};
    margin-right: -${paddingHorizMobile};

    padding-left: ${paddingHorizMobile};
    padding-right: ${paddingHorizMobile};
  }
`;

const MobileAlertContainer = styled.div`
  width: calc(100% + ${parseInt(paddingHorizMobile, 10) * 2}px);
  margin-top: -${paddingTopMobile};
  margin-bottom: ${paddingTopMobile};
  margin-left: -${paddingHorizMobile};
  margin-right: -${paddingHorizMobile};
`;

const PageHeader = styled.section`
  position: relative;
  overflow: hidden;
  background-color: ${props => hexToRgba(props.backgroundColor, 0.1)};

  ${fullWidthContainerInPageCss};
  margin-top: -${paddingTopDesktop};
  padding-top: ${paddingTopDesktop};

  @media (max-width: ${TabletSize}) {
    margin-top: -${paddingTopTablet};
    padding-top: ${paddingTopTablet};
  }

  @media (max-width: ${MobileSize}) {
    margin-top: -${paddingTopMobile};
    padding-top: ${paddingTopDesktop};
  }

  & > .icon {
    position: absolute;
    top: 56px;
    right: -100px;

    @media (max-width: ${MobileSize}) {
      top: 40px;
      right: -250px;
    }
  }
`;

const Circle1 = styled(Icon)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: rotate(172deg) scale(40);
  z-index: 1;

  @media (max-width: ${TabletSize}) {
    left: 48%;
    transform: rotate(184deg) scale(36);
  }

  @media (max-width: ${MobileSize}) {
    left: 25%;
    transform: rotate(188deg) scale(24);
  }
`;

const Circle2 = styled.div`
  position: absolute;
  top: -447px;
  left: -164px;
  width: 900px;
  height: 900px;
  border-radius: 50%;
  opacity: 0.05;
  background-blend-mode: multiply;
  background-color: ${props => props.themeColor};
  ${props =>
    props.gradientColor &&
    `
  background-image: linear-gradient(
    to bottom,
    ${props.gradientColor},
    ${props.gradientColor}
  );
  `}

  @media (max-width: ${TabletSize}) {
    left: -360px;
  }

  @media (max-width: ${MobileSize}) {
    left: -575px;
  }

  transition: left 0.6s ease-out;
`;

const Container = styled.div`
  flex: 1 0 0;
  align-items: center;
  padding-top: ${paddingTopDesktop};
  padding-bottom: ${paddingBottom};
  padding-left: ${paddingHorizDesktop};
  padding-right: ${paddingHorizDesktop};
  position: relative;
  overflow-y: scroll;

  @media (max-width: ${TabletSize}) {
    padding-top: ${paddingTopTablet};
    padding-left: ${paddingHorizTablet};
    padding-right: ${paddingHorizTablet};
  }

  @media (max-width: ${MobileSize}) {
    padding-top: ${paddingTopMobile};
    padding-left: ${paddingHorizMobile};
    padding-right: ${paddingHorizMobile};
  }
`;

const LoadingContainer = styled(FlexCenter)`
  height: 100%;
`;

const PageContent = styled(FlexColumn)`
  align-items: center;
`;

export default Platform(Page);
