import React from 'react';
import styled from 'styled-components';

import {
  Accordion,
  ResponsiveColumn,
  Row,
  Text,
  Container,
} from '@summer/ui-components';

import { usePlatform } from 'hooks/platform';

const PlaidSyncBody = () => {
  const { isSmallDesktop, isDesktop } = usePlatform();

  return (
    <StyledContainer>
      <Row>
        <ResponsiveColumn
          desktopCol={6}
          smallDesktopCol={6}
          tabletCol={12}
          mobileCol={12}
        >
          <Accordion
            config={[
              {
                title: 'What are the benefits of syncing my loans with Summer?',
                content: (
                  <Text paragraph>
                    Syncing your loans allows Summer to analyze detailed and
                    accurate loan data and confidently provide you with a
                    customized repayment plan.
                  </Text>
                ),
                openByDefault: true,
              },
              {
                title:
                  'What’s the difference between federal and private loans?',
                content: (
                  <Text paragraph>
                    Federal loans are issued directly or guaranteed by the
                    federal government given out when you fill out the FAFSA and
                    apply for financial aid. They have names like Direct, FFEL,
                    PLUS, Perkins, and Stafford.
                    <br />
                    <br />
                    Private loans are lent by private companies like banks. Some
                    common private lenders include Citizens Bank, College Ave,
                    CommonBond, Sallie Mae, and SoFi.
                    <br />
                    <br />
                    Not sure what type of loans you have? Sync with all your
                    servicers and we’ll detect the federal loans for you.
                  </Text>
                ),
              },
              {
                title: 'I don’t remember my student loan servicer credentials',
                content: (
                  <Text paragraph>
                    If you can not remember your credentials, please visit your
                    servicer’s online portal to either look-up your username or
                    reset your password.
                  </Text>
                ),
              },
            ]}
          />
        </ResponsiveColumn>

        <ResponsiveColumn
          smallDesktopCol={5}
          desktopCol={5}
          tabletCol={12}
          mobileCol={12}
          desktopOffset={1}
          smallDesktopOffset={1}
        >
          {(isDesktop || isSmallDesktop) && (
            <ComparePlansImage
              alt="Sync Info"
              src="/images/compare-plan-new.png"
            />
          )}
        </ResponsiveColumn>
      </Row>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  padding: 24px 0;
  text-align: left;
`;

const ComparePlansImage = styled.img`
  text-align: right;
  width: 100%;
  margin-top: 8px;
  margin-left: 8px;
`;

export default PlaidSyncBody;
