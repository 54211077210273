import {
  CREATE_RETIREMENT_MATCH_MANUAL_MATCH,
  FETCH_RETIREMENT_MATCH,
  NETWORK_SUCCESS,
  POST_RETIREMENT_MATCH_CONSENT,
} from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import { setRetirementMatch } from 'redux/actions/retirementMatch.actions';
import handleSideEffects from 'redux/handleSideEffects';

const retirementMatchMiddleware = handleSideEffects({
  [POST_RETIREMENT_MATCH_CONSENT]: (dispatch, { payload, meta }) => {
    dispatch(
      apiRequest({
        url: '/v1/retirement-match/consent',
        method: 'POST',
        body: {
          ...payload,
        },
        fromAction: POST_RETIREMENT_MATCH_CONSENT,
        label: 'postRetirementMatchConsent',
        meta,
      }),
    );
  },

  [CREATE_RETIREMENT_MATCH_MANUAL_MATCH]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: '/v1/retirement-match/manual-match',
        method: 'POST',
        body: {
          ...payload,
        },
        fromAction: CREATE_RETIREMENT_MATCH_MANUAL_MATCH,
        label: 'createRetirementMatchManualMatch',
      }),
    );
  },

  [FETCH_RETIREMENT_MATCH]: dispatch => {
    dispatch(
      apiRequest({
        url: '/v1/retirement-match',
        method: 'GET',
        fromAction: FETCH_RETIREMENT_MATCH,
        label: 'fetchRetirementMatch',
      }),
    );
  },

  [NETWORK_SUCCESS]: (dispatch, { payload, meta }) => {
    if (meta.fromAction === POST_RETIREMENT_MATCH_CONSENT) {
      dispatch(setRetirementMatch(payload.response.data));
      if (meta.onSuccess) {
        meta.onSuccess();
      }
    }

    if (meta.fromAction === FETCH_RETIREMENT_MATCH) {
      dispatch(setRetirementMatch(payload.response.data));
    }
  },
});

export default retirementMatchMiddleware;
