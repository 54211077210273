import React from 'react';

import { Drawer, Text } from '@summer/ui-components';

import {
  DrawerContent,
  DrawerContentColumn,
  DisplayData,
} from 'components/pages/loans/components/LoanDetails/shared';
import { Link } from 'components/shared/typography';

const ServicerInfo = ({
  servicerName,
  servicerPhone,
  servicerUrl,
  servicerEmail,
}) => (
  <Drawer title="Servicer Information">
    <DrawerContent>
      <DrawerContentColumn>
        <div>
          <Text color="secondary">Servicer</Text>
          <Text large bold>
            <DisplayData valueToCheck={servicerName}>
              {servicerUrl ? (
                <Link inheritColor underline href={servicerUrl}>
                  {servicerName}
                </Link>
              ) : (
                <Text size="large">{servicerName}</Text>
              )}
            </DisplayData>
          </Text>
        </div>
      </DrawerContentColumn>

      <DrawerContentColumn>
        <div>
          <Text color="secondary">Contact Details</Text>
          <DisplayData valueToCheck={servicerPhone || servicerEmail}>
            <Text large bold>
              {servicerPhone}
            </Text>

            <Text large bold>
              <Link inheritColor underline href={`mailto:${servicerEmail}`}>
                {servicerEmail}
              </Link>
            </Text>
          </DisplayData>
        </div>
      </DrawerContentColumn>
    </DrawerContent>
  </Drawer>
);

export default ServicerInfo;
