import React from 'react';

import { Alert } from '@summer/ui-components/src';

export const PlaidOutageAlert = ({ className }) => (
  <Alert theme="warning" dismissable={false} className={className}>
    We are currently experiencing issues with syncing loans. Please check back
    later and try again.
  </Alert>
);
