import {
  FETCH_PSLF_FORMS,
  SET_PSLF_FORMS,
  CREATE_PSLF_FORM,
  FETCH_CURRENT_PSLF_FORM,
  SET_CURRENT_PSLF_FORM,
  CLEAR_CURRENT_PSLF_FORM,
  UPDATE_CURRENT_PSLF_FORM,
  SAVE_PSLF_CURRENT_STEP,
  SET_PSLF_CURRENT_STEP,
  FETCH_PSLF_CALCULATIONS,
  SET_PSLF_CALCULATIONS,
  PSLF_NETWORK_SUCCESS,
  PSLF_NETWORK_CLEAR,
} from 'constants/actionTypes';

export const fetchPslfForms = () => ({
  type: FETCH_PSLF_FORMS,
});

export const setPslfForms = formsResponse => ({
  type: SET_PSLF_FORMS,
  payload: { formsResponse },
});

export const createPslfForm = ({ form }) => ({
  type: CREATE_PSLF_FORM,
  payload: { form },
});

export const fetchCurrentPslfForm = formId => ({
  type: FETCH_CURRENT_PSLF_FORM,
  payload: { formId },
});

export const setCurrentPslfForm = form => ({
  type: SET_CURRENT_PSLF_FORM,
  payload: { form },
});

export const clearCurrentPslfForm = () => ({
  type: CLEAR_CURRENT_PSLF_FORM,
});

export const updateCurrentPslfForm = ({
  currentStep,
  form,
  profile,
  status,
}) => {
  return {
    type: UPDATE_CURRENT_PSLF_FORM,
    payload: { currentStep, form, profile, status },
  };
};

export const savePslfCurrentStep = ({ currentStep, form: { formId } }) => {
  return {
    type: SAVE_PSLF_CURRENT_STEP,
    payload: { currentStep, form: { formId } },
  };
};

export const setPslfCurrentStep = currentStep => ({
  type: SET_PSLF_CURRENT_STEP,
  payload: { currentStep },
});

export const fetchPslfCalculations = formId => ({
  type: FETCH_PSLF_CALCULATIONS,
  payload: { formId },
});

export const setPslfCalculations = calculationsResponse => ({
  type: SET_PSLF_CALCULATIONS,
  payload: calculationsResponse,
});

export const pslfNetworkSuccess = ({ meta }) => ({
  type: PSLF_NETWORK_SUCCESS,
  meta,
});

export const pslfNetworkClear = ({ meta }) => {
  return {
    type: PSLF_NETWORK_CLEAR,
    meta,
  };
};
