import { keys, pick } from 'lodash';

import { SET_CSP_INPUTS } from 'constants/actionTypes';

const initialState = {
  yearlyTuition: null,
  startYear: null,
  attendanceDuration: null,
  costIncrease: 0.04,
  currentSavings: null,
  plannedContribution: null,
  rateOfReturn: 0.07,
  expectedAid: null,
  collegeCostMethod: null,
  expectedAidMethod: null,
  householdAnnualIncome: null,
};

const inputsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CSP_INPUTS: {
      return pick(payload, keys(initialState));
    }

    default:
      return state;
  }
};

export default inputsReducer;
