import { upperCase } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { Header, Icon, Close, COLORS } from '@summer/ui-components';

import { DisplayData } from 'components/pages/loans/components/LoanDetails/shared';
import { preventWidow } from 'utils/text';

const LoanDetailsHeader = ({ servicerName, loanType, closeLoanDetails }) => (
  <Container>
    <Header h4 center color="light">
      <DisplayData valueToCheck={servicerName}>
        {upperCase(servicerName)}
      </DisplayData>
    </Header>
    <Box>
      <Header center color="light">
        <DisplayData valueToCheck={loanType}>
          {preventWidow(`${loanType} Loan`)}
        </DisplayData>
      </Header>
    </Box>
    <CloseIcon
      onClick={closeLoanDetails}
      SvgComponent={Close}
      fill={COLORS.white}
    />
  </Container>
);

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 56px 32px 32px 32px;

  background-color: #0072af; // add to style guide?

  & > h4 {
    opacity: 0.6;
  }
`;

const Box = styled.div`
  margin-top: 8px;
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
`;

export default LoanDetailsHeader;
