import { get, pick } from 'lodash';

import {
  fieldsOnManageLoansIdr,
  fieldsOnManageLoansPslf,
} from 'constants/loans';
import { programTypes } from 'constants/manageLoans';
import { formatEmployerDates } from 'utils/pslf';
import { boolToString } from 'utils/toOptions';

export const getFormInitialValues = (loan, programType) => {
  const initialValues = {
    id: get(loan, 'id', undefined),
    amountBorrowed: get(loan, 'amountBorrowed', ''),
    disbursementDate: get(loan, 'disbursementDate', ''),
    disbursementDateId: get(loan, 'disbursementDateId', undefined),
    educationLevel: get(loan, 'educationLevel', ''),
    federalLoanTypeId: get(loan, 'federalLoanTypeId', ''),
    idrRecertificationDate: get(loan, 'idrRecertificationDate', null),
    includesParentPlus: boolToString(get(loan, 'includesParentPlus', null)),
    interestRate: get(loan, 'interestRate', ''),
    loanPayoffDate: get(loan, 'loanPayoffDate', null),
    outstandingInterestBalance: get(loan, 'outstandingInterestBalance', ''),
    principalBalance: get(loan, 'principalBalance', ''),
    repaymentPlanTypeId: get(loan, 'repaymentPlanTypeId', ''),
    servicerId: get(loan, 'servicerId', null),
  };

  const initialValueSets = {
    [programTypes.idr]: pick(initialValues, fieldsOnManageLoansIdr),
    [programTypes.pslf]: pick(initialValues, fieldsOnManageLoansPslf),
  };

  return initialValueSets[programType];
};

export const getHeaderCopy = (
  formType,
  programType,
  loanIsEditable,
  loanIsPaidOff,
  employerStartDate,
  employerEndDate,
) => {
  const paidOffLoanText =
    'Even though your loan is paid off, we need this information because it helps us determine which repayment plans are best for you.';

  const titlePrefix = loanIsEditable ? 'Edit' : 'View';
  const headerText = loanIsPaidOff
    ? paidOffLoanText
    : 'Please fill in any missing fields.';

  let title;
  if (programType === programTypes.pslf) {
    const delimiter = '—';
    title = formatEmployerDates(employerStartDate, employerEndDate, delimiter);
  } else {
    title = `${titlePrefix} a ${loanIsPaidOff ? 'Paid Off ' : ''}Loan`;
  }

  const subtitle =
    programType === programTypes.pslf ? 'LOAN ELIGIBILITY' : null;

  return { title, subtitle, headerText };
};
