import React, { useState } from 'react';
import styled from 'styled-components';
import { find, get, chunk } from 'lodash';
import PropTypes from 'prop-types';

import { COLORS, MobileSize } from '../../../helpers/constants/styleguide';

import { Text } from '../../typography';
import { FlexRow, FlexColumn } from '../../Flex';
import Collapse from '../Collapse';
import Icon, { ArrowUp } from '../../Icon';

const Accordion = ({ config, numColumns, align, className }) => {
  const [activeDrawer, setActiveDrawer] = useState(
    get(find(config, 'openByDefault'), 'title', ''),
  );

  const columnSize = Math.ceil(config.length / numColumns);
  const columnContentsArr = chunk(config, columnSize);

  return (
    <AccordionContainer numColumns={numColumns} className={className}>
      {columnContentsArr.map((columnContent, idx) => (
        <ColumnContainer key={idx}>
          {columnContent.map(
            ({ title, subTitle, content, openByDefault, onMouseDown }, idx) => (
              <DrawerContainer key={idx}>
                <Collapse
                  openByDefault={openByDefault}
                  ejectControl
                  show={activeDrawer === title}
                  onClick={() => {
                    if (activeDrawer !== title) {
                      setActiveDrawer(title);
                    } else {
                      setActiveDrawer('');
                    }
                  }}
                  onMouseDown={onMouseDown}
                  trigger={({ openState }) => (
                    <CollapseTrigger data-testid={`drawer-${title}`}>
                      <TitleContainer subTitle={subTitle}>
                        <Text align={align} bold paragraph>
                          {title}
                        </Text>
                        <Arrow
                          open={openState}
                          SvgComponent={ArrowUp}
                          fill={COLORS.darkerGrey}
                          subTitle={subTitle}
                        />
                      </TitleContainer>
                      <Text paragraph>{subTitle}</Text>
                    </CollapseTrigger>
                  )}
                >
                  <CollapseContent>{content}</CollapseContent>
                </Collapse>
              </DrawerContainer>
            ),
          )}
        </ColumnContainer>
      ))}
    </AccordionContainer>
  );
};

Accordion.propTypes = {
  config: PropTypes.array.isRequired,
  align: PropTypes.string,
};

Accordion.defaultProps = {
  config: [],
  numColumns: 1,
  align: 'left',
};

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
  }
`;

const ColumnContainer = styled(FlexColumn)`
  flex: 1 1 auto;

  :not(:last-child) {
    margin-right: 48px;
  }
`;

const DrawerContainer = styled.div`
  border-bottom: 2px solid ${COLORS.lighterGrey};
`;

const CollapseTrigger = styled(FlexColumn)`
  width: 100%;
  cursor: pointer;
  padding: 10px 0;
`;

const TitleContainer = styled(FlexRow)`
  justify-content: space-between;
  margin-bottom: ${({ subTitle }) => (subTitle ? '4px' : '0')};
`;

const Arrow = styled(Icon)`
  align-self: ${({ subTitle }) => (subTitle ? 'flex-end' : 'center')};
  margin-left: 8px;
  cursor: pointer;
  transform: ${props => (props.open ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: transform 0.3s ease-out;
`;

const CollapseContent = styled.div`
  padding: 0 0 24px;
`;

export default Accordion;
