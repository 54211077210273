import { COLORS } from '@summer/ui-components';

export const initZendeskSettings = () => {
  window.zESettings = {
    webWidget: {
      // Theme text color setting is in zendesk admin panel
      color: {
        theme: '#d6edff', // this is custom color for zendesk widget because they expect hex not rgba
        launcherText: COLORS.azure,
      },
    },
  };

  // because we use zendesk help center we need to enable it after closing widget
  // this related to openContact function
  if (!window.zE) {
    return;
  }

  window.zE('webWidget:on', 'close', () => {
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        helpCenter: {
          suppress: false,
        },
      },
    });
  });
};

export const zendeskWidgetIdentify = ({ firstName, lastName, email }) => {
  const name = firstName && lastName ? `${firstName} ${lastName}` : null;

  if (window.zE && name) {
    window.zE('webWidget', 'identify', {
      name,
      email,
    });

    window.zE('webWidget', 'prefill', {
      name: {
        value: name,
        readOnly: true,
      },
      email: {
        value: email,
        readOnly: true,
      },
    });
  }
};

export const openContact = () => {
  if (!window.zE) {
    return;
  }

  // because we use zendesk help center we need to disable it before open widget
  window.zE('webWidget', 'updateSettings', {
    webWidget: {
      helpCenter: {
        suppress: true,
      },
    },
  });

  // open widget
  window.zE('webWidget', 'open');
};

export const zendeskWidgetLogout = () => {
  if (!window.zE) {
    return;
  }
  window.zE('webWidget', 'logout');
};
