import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import WizardFooter from 'components/layout/wizard/WizardFooter';
import PrivacyPolicyLink from 'components/shared/PrivacyPolicyLink';
import { isLoading } from 'redux/selectors/ui.selectors';

const PslfWizardFooter = ({
  handleBack,
  handleSaveContinue,
  isLoading,
  isValid,
  submitCount,
  isNextDisabled,
  isNextFakeDisabled,
  showPrivacyPolicy,
  isThisLastStep,
}) => (
  <>
    {showPrivacyPolicy && <PrivacyPolicyPSLFFooter />}
    <WizardFooter
      isNextLoading={isLoading}
      isNextDisabled={isNextDisabled || (!isValid && submitCount > 0)}
      isNextFakeDisabled={isNextFakeDisabled}
      handleBack={handleBack}
      handleSaveContinue={handleSaveContinue}
      isBackLoading={isLoading}
      isBackDisabled={isLoading}
      isNextVisible={true}
      isThisLastStep={isThisLastStep}
    >
      pslf wizard footer here
    </WizardFooter>
  </>
);

const PrivacyPolicyPSLFFooter = styled(PrivacyPolicyLink)`
  margin: 20px 10px 10px;
`;

const mapStateToProps = state => ({
  isLoading:
    isLoading(state, 'createPslfForm') ||
    isLoading(state, 'updateCurrentPslfForm'),
});

export default connect(mapStateToProps)(PslfWizardFooter);
