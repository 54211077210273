import React from 'react';
import styled from 'styled-components';

import { Text, COLORS } from '@summer/ui-components';

import GlobalError from 'components/shared/GlobalError';
import OAuthButton from 'components/shared/OAuthButton';
/* eslint-disable-next-line import/no-named-as-default */

const oauthDisabled = !!process.env.REACT_APP_OAUTH_DISABLED;

// remove flag before deploying
const OAuthButtons = ({
  type,
  partner,
  partnerCode,
  usePartnerCode,
  partnerGuid,
  usePartnerGuid,
  identifierValue,
  borrowerId,
  oauthProviders,
}) => (
  <>
    {!oauthDisabled && (
      <>
        {oauthProviders.includes('google') && (
          <>
            <OAuthButton
              type={type}
              provider="google"
              partner={partner}
              partnerCode={partnerCode}
              usePartnerCode={usePartnerCode}
              usePartnerGuid={usePartnerGuid}
              partnerGuid={partnerGuid}
              identifierValue={identifierValue}
              borrowerId={borrowerId}
            />
            <Spacer />
          </>
        )}
        {oauthProviders.includes('facebook') && (
          <>
            <OAuthButton
              type={type}
              provider="facebook"
              partner={partner}
              partnerCode={partnerCode}
              usePartnerCode={usePartnerCode}
              usePartnerGuid={usePartnerGuid}
              partnerGuid={partnerGuid}
              identifierValue={identifierValue}
              borrowerId={borrowerId}
            />
            <Spacer />
          </>
        )}
        {oauthProviders.includes('cityOfAlexandria') && (
          <OAuthButton
            type={type}
            provider="cityOfAlexandria"
            partner={partner}
            partnerCode={partnerCode}
            usePartnerCode={usePartnerCode}
            identifierValue={identifierValue}
            borrowerId={borrowerId}
          />
        )}
        <GlobalError label="oauth" />
        <Divider>
          <Line />
          <Text bold color="secondary">
            OR
          </Text>
          <Line />
        </Divider>
      </>
    )}
  </>
);

const Spacer = styled.div`
  height: 16px;
  width: 100%;
`;

const Divider = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 32px 0;
`;

const Line = styled.span`
  align-self: center;
  height: 1px;
  width: calc(50% - 24px);
  border-top: solid 1px ${COLORS.grey};
`;

export default OAuthButtons;
