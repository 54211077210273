import { SET_PROFILE, UPDATE_PROFILE } from 'constants/actionTypes';

export const updateProfile = profile => ({
  type: UPDATE_PROFILE,
  payload: { profile },
});

export const setProfile = profile => ({
  type: SET_PROFILE,
  payload: { profile },
});
