import React from 'react';

import { Form } from 'components/pages/idr/wizard/shared/styles';
import {
  EmployerName,
  Address,
} from 'components/pages/idr/wizard/steps/Submit/fields';
import { useFormDrawerFocus } from 'components/shared/FormDrawers';

const EmployerInfo = ({ formik, show }) => {
  const formRef = React.useRef(null);
  useFormDrawerFocus(formRef, show);

  return (
    <Form ref={formRef}>
      <EmployerName formik={formik} />
      <Address formik={formik} prefix="employer" />
    </Form>
  );
};

export default EmployerInfo;
