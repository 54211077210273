import { object, number } from 'yup';

import { MONTHLY_PAYMENT_TOO_LOW_MESSAGE } from 'constants/overpayments';

export const yourLoansSchema = object().shape({
  currentBalance: number()
    .nullable()
    .required('Please enter your current loan balance'),
  interestRate: number().nullable().required('Please enter your interest rate'),
  currentMonthlyPayment: number()
    .when(
      ['interestRate', 'currentBalance'],
      (interestRate, currentBalance, schema) =>
        schema
          .max(
            currentBalance * (1 + interestRate / 12),
            'Your monthly payment is higher than your loan balance',
          )
          .min(
            (((currentBalance * interestRate) / 12) *
              (1 + interestRate / 12) ** 360) /
              ((1 + interestRate / 12) ** 360 - 1),
            MONTHLY_PAYMENT_TOO_LOW_MESSAGE,
          ),
    )
    .required('Please enter your current monthly payment')
    .nullable(),
});

export const overpaymentAmountSchema = maxValue =>
  object().shape({
    overpaymentAmount: number().max(maxValue, ' '),
  });
