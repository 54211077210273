import { push as redirectTo } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  COLORS,
  FlexColumn,
  FlexRow,
  Header,
  Text,
  Button,
  SvgImage,
  EmergencySavingsImage,
} from '@summer/ui-components/src';

import { MobileSize } from 'constants/styleguide';

import { EmergencySavingsCard } from '../shared';

const SavingsTargetStart = () => {
  const dispatch = useDispatch();

  return (
    <StyledEmergencySavingsCard>
      <FlexRow>
        <Content>
          <Header as="h2">Savings Target</Header>
          <Text paragraph>
            How much cash should you aim to have on hand in case of an
            emergency? We’ll help you set a goal to reach before you direct your
            savings toward other financial goals, like paying off debt or
            funding your retirement.
          </Text>
          <Button
            width={160}
            onClick={() => dispatch(redirectTo('/emergency-savings/wizard'))}
          >
            Calculate Target
          </Button>
        </Content>
        <Image>
          <SvgImage SvgComponent={EmergencySavingsImage}></SvgImage>
        </Image>
      </FlexRow>
    </StyledEmergencySavingsCard>
  );
};

const StyledEmergencySavingsCard = styled(EmergencySavingsCard)`
  border: 1px solid ${COLORS.grey};
  background-image: radial-gradient(
    circle at 87.5% 50%,
    ${COLORS.white} 25%,
    ${COLORS.grey} 0
  );

  @media (max-width: ${MobileSize}) {
    background-image: radial-gradient(
      circle 550px at 87.5% 50%,
      ${COLORS.white} 25%,
      ${COLORS.grey} 0
    );
  }
`;

const Content = styled(FlexColumn)`
  gap: 12px;
  width: 50%;
  justify-content: center;
  align-items: left;
`;

const Image = styled(FlexRow)`
  align-items: center;

  @media (min-width: ${MobileSize}) {
    position: absolute;
    right: 12.5%;
  }
`;

export default SavingsTargetStart;
