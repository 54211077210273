import { POST_OAUTH_CODE } from 'constants/actionTypes';

export const postOAuthCode = (provider, search) => ({
  type: POST_OAUTH_CODE,
  payload: {
    provider,
    search,
    referrer: document.referrer,
  },
});
