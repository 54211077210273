import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import styled from 'styled-components';

import { LoadingBars, FlexCenter, COLORS } from '@summer/ui-components';

import MyStudentLoansNoPlaidLoans from 'components/pages/loans/MyStudentLoansNoPlaidLoans';
import MyStudentLoansWithLoans from 'components/pages/loans/MyStudentLoansWithLoans';
import { fetchAllLoans } from 'redux/actions/loans.actions';
import { hasPlaidLoans } from 'redux/selectors/loans.selectors';
import { isLoadingWithInit } from 'redux/selectors/ui.selectors';

const MyStudentLoans = ({ hasPlaidLoans, isLoading }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllLoans());
  }, [dispatch]);

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingBars color={COLORS.azure} />
      </LoadingContainer>
    );
  }

  if (!hasPlaidLoans) {
    return <MyStudentLoansNoPlaidLoans />;
  }

  return <MyStudentLoansWithLoans />;
};

const LoadingContainer = styled(FlexCenter)`
  height: 100%;
`;

const mapStateToProps = state => ({
  hasPlaidLoans: hasPlaidLoans(state),
  isLoading: isLoadingWithInit(state, 'fetchAllLoans'),
});

export default connect(mapStateToProps)(MyStudentLoans);
