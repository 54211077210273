import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ScheduleACallAlert from 'components/layout/dashboard/alerts/ScheduleACallAlert';
import LandingPageHeader from 'components/shared/dashboard/LandingPage/LandingPageHeader';
import { CollapseSidebarSize } from 'constants/styleguide';
import { usePlatform } from 'hooks/platform';

const LandingPage = ({
  headerTheme,
  isRecommended,
  headerName,
  subHeader,
  children,
  logoSrc,
}) => {
  const { isMobile, isTablet } = usePlatform();

  return (
    <>
      {!isMobile && <ScheduleACallAlert />}
      <Container>
        {isMobile && <ScheduleACallAlert />}

        <LandingPageHeader
          headerTheme={headerTheme}
          isRecommended={isRecommended}
          headerName={headerName}
          subHeader={subHeader}
          isMobile={isMobile}
          logoSrc={logoSrc}
        />
        {logoSrc && !isMobile && !isTablet && <Logo src={logoSrc} />}
        {children}
      </Container>
    </>
  );
};

const Container = styled.div`
  overflow-y: scroll;
  flex: 1 0 0;
`;

const Logo = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 2;
  width: 140px;

  @media (max-width: ${CollapseSidebarSize}) {
    top: 80px;
  }
`;

LandingPage.propTypes = {
  headerTheme: PropTypes.string.isRequired,
  isRecommended: PropTypes.bool,
  headerName: PropTypes.string.isRequired,
  subHeader: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default LandingPage;
