import React from 'react';
import styled from 'styled-components';

import { Text, Strong } from '@summer/ui-components';

import DefaultDrawerContent from 'components/pages/default/wizard/layout/DefaultDrawerContent';
import TextTooltip from 'components/shared/TextTooltip';

export const checklistConfig = {
  header: 'This is your best option if:',
  items: [
    'You’ve already completed loan rehabilitation once before',
    'You don’t have loans that can be consolidated (you have one Direct loan)',
    'You are able to pay off most of your loans in a lump sum',
  ],
};

export const Middle = () => (
  <MiddleContainer>
    <Text paragraph>
      There are multiple ways you can settle based on your situation. In some
      cases, a small part of your total balance may be waived.
    </Text>
  </MiddleContainer>
);

export const BlueBoxContents = () => (
  <BlueBoxContainer>
    <Text bold>When you call your servicer:</Text>
    <Text paragraph>
      First, double check with them that you aren&rsquo;t able to try{' '}
      <Strong>loan rehabilitation</Strong> or{' '}
      <Strong>loan consolidation</Strong> to get your loans out of default.
    </Text>
    <Text paragraph>
      If those options aren&rsquo;t available, ask about the options for
      settling your loan. Some of the following options may be available to you
      depending on your servicer and situation.
    </Text>
    <Options>
      <Text bold>
        <TextTooltip text="Pay all your current principal balance and any unpaid interest, with future collection surcharges and fees waived">
          Pay all principal and interest
        </TextTooltip>
      </Text>
      <Text bold>
        <TextTooltip text="Pay all your current principal balance and half of your unpaid interest">
          Pay all principal and half of interest
        </TextTooltip>
      </Text>
      <Text bold>
        <TextTooltip text=" Pay the remainder of your principal and interest balance after a 10% discount">
          Pay 90% of all principal and interest
        </TextTooltip>
      </Text>
    </Options>
    <Text paragraph>
      With all of these options, you&rsquo;ll generally be expected to pay your
      student loan debt settlement in one lump sum, usually 90 days from the
      settlement agreement date.
    </Text>
  </BlueBoxContainer>
);

const PayOffYourLoans = () => (
  <DefaultDrawerContent
    optionName="settlement"
    checklistConfig={checklistConfig}
    middle={<Middle />}
    blueBoxContents={<BlueBoxContents />}
  />
);

const MiddleContainer = styled.div`
  margin-bottom: 16px;
`;

const BlueBoxContainer = styled.div`
  & > * {
    margin-bottom: 16px;

    :last-child {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
`;

const Options = styled.div`
  & > * {
    margin-bottom: 24px;
  }
`;

export default PayOffYourLoans;
