import { map, isFunction } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';

import { Text, FlexRow, COLORS } from '@summer/ui-components';

import { TabletSize } from 'constants/styleguide';
import { usePlatform } from 'hooks/platform';

import WizardClose from './WizardClose';

const WizardHeader = ({
  children,
  headers,
  progress,
  handleClose,
  currentStep,
  handleHeaderClick,
}) => {
  const onHeaderClick = step => () => {
    if (isFunction(handleHeaderClick) && step < currentStep) {
      handleHeaderClick(step);
    }
  };

  const { isMobile } = usePlatform();

  return (
    <Container>
      <Content>
        <HeaderText>{children}</HeaderText>
        <WizardClose handleClose={handleClose} />
      </Content>
      <Steps>
        {map(headers, ({ step, title }) => {
          const active = step <= currentStep;
          const isCurrentHeader = step === currentStep;
          // only show current header on mobile
          return isMobile && !isCurrentHeader ? null : (
            <Step key={title} onClick={onHeaderClick(step)}>
              <Text center bold color={active ? 'summer' : 'secondary'}>
                {title}
              </Text>
            </Step>
          );
        })}
      </Steps>
      <Progress progress={progress} />
    </Container>
  );
};

const Container = styled.div``;

const Content = styled(FlexRow)`
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid ${COLORS.grey};

  @media (max-width: ${TabletSize}) {
    padding: 16px 40px 16px 24px;
  }
`;

const HeaderText = styled(FlexRow)`
  align-items: center;

  @media (max-width: ${TabletSize}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Steps = styled(FlexRow)``;

const pointerCSS = css`
  cursor: pointer;
`;

const Step = styled.div`
  padding: 12px;
  flex: 1;
  ${({ onClick }) => onClick && pointerCSS}
`;

const Progress = styled.div`
  height: 5px;
  border-radius: 3px;
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, #eff0f4, #eff0f4);

  :before {
    content: '';
    display: block;
    height: inherit;
    width: ${({ progress }) => progress || 0}%;
    background-color: ${COLORS.azure};
    transition: all 0.3s ease;
  }
`;

export default WizardHeader;
