import { get, set } from 'lodash/fp';

import { RESET_APP } from 'constants/actionTypes';

const resetReducer = appReducer => (state, action) => {
  if (action.type === RESET_APP) {
    if (localStorage) {
      localStorage.clear();
    }
    let newState = {
      router: state.router,
    };

    if (action.payload) {
      const propertyPathToPreserve = action.payload?.preserve;
      const currentValue = get(propertyPathToPreserve, state);
      newState = set(propertyPathToPreserve, currentValue, newState);
    }

    return appReducer(newState, action);
  }

  return appReducer(state, action);
};

export default resetReducer;
