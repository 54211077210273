import React, { memo } from 'react';
import styled from 'styled-components';

import { FlexColumn, Text, Button, COLORS } from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { TabletSize, MobileSize } from 'constants/styleguide';
import { hexToRgba } from 'utils/common';

const PslfBanner = ({ buttonStyle }) => {
  const callToAction = 'Get started';
  return (
    <Container>
      <Content>
        <div>
          <WhatsNextText size="small" paragraph align="left">
            WHAT&rsquo;S NEXT
          </WhatsNextText>
          <Text size="large" bold paragraph align="left">
            Maximize your savings with loan forgiveness
          </Text>
          <Text paragraph align="left">
            If you work or plan to work in the public service or nonprofit
            career, you could have part of your loans forgiven. We&rsquo;ll help
            you get enrolled!
          </Text>
        </div>
        <LinkCTA to="/pslf">
          {buttonStyle === 'link' ? (
            callToAction
          ) : (
            <Button width={140}>{callToAction}</Button>
          )}
        </LinkCTA>
      </Content>
    </Container>
  );
};

const Container = styled(FlexColumn)`
  background-color: ${hexToRgba(COLORS.azure, 0.06)};
  justify-content: center;
  width: stretch;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: stretch;
  padding: 24px 32px;

  @media (max-width: ${TabletSize}) {
    padding: 32px 32px 56px;
  }

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
    padding: 24px;
  }
`;

const WhatsNextText = styled(Text)`
  letter-spacing: 1px;
  margin-bottom: 8px;
`;

const LinkCTA = styled(Link)`
  align-self: flex-end;
  margin-left: 16px;
  text-align: right;
  min-width: 140px;

  @media (max-width: ${MobileSize}) {
    margin-left: 0;
    margin-top: 16px;
    width: 100%;
  }
`;

export default memo(PslfBanner);
