import { push as redirectTo } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { USStateDisplayNameMapping } from '@simplifidev/shared/dist/constants/states';

import {
  Header,
  Text,
  COLORS,
  FlexRow,
  TabletSize,
} from '@summer/ui-components';

import { Link } from 'components/shared/typography';

import { state529Plans, national529Plans } from '../constants';
import { MarketplaceLayout } from '../layout';

import PlanCard from './planCard';

const CollegeSavingsPlanner529MarketplaceStatePage = ({ match }) => {
  const statePostalCode = match?.params?.state?.toUpperCase();
  const stateDisplayName = USStateDisplayNameMapping[statePostalCode];

  const dispatch = useDispatch();
  if (!stateDisplayName) {
    dispatch(redirectTo('/college-savings-planner/529-marketplace'));
  }

  const statePlans = state529Plans[statePostalCode] ?? [];
  const stateHasPlans = statePlans.length > 0;

  return (
    <MarketplaceLayout>
      <TopContainer>
        <BacklinkContainer>
          <Text large>{stateDisplayName}</Text>
          <Link to="/college-savings-planner/529-marketplace">Change</Link>
        </BacklinkContainer>
        {stateHasPlans && (
          <TextContainer>
            <Header h2>
              The state of {stateDisplayName} offers the following plan
            </Header>
            <Text paragraph>
              Every state has their own 529 Plan, but you don’t have to use your
              state’s plan if you’d prefer to house your college savings
              elsewhere.
            </Text>
          </TextContainer>
        )}
      </TopContainer>
      {statePlans.map(plan => (
        <PlanCard
          key={plan.name}
          name={plan.name}
          logoSrc={plan.logoSrc}
          stateDisplayName={stateDisplayName}
          stateTaxBenefitType={plan.stateTaxBenefitType}
          taxBenefitAmount={plan.taxBenefitAmount}
          moreInfoLink={plan.moreInfoLink}
        />
      ))}
      <NationalPlansContainer>
        <Header h5>
          {stateHasPlans && 'Other '}Top-rated plans nationwide:
        </Header>
        {national529Plans.map(plan => (
          <PlanCard
            key={plan.name}
            name={plan.name}
            logoSrc={plan.logoSrc}
            moreInfoLink={plan.moreInfoLink}
          />
        ))}
      </NationalPlansContainer>
    </MarketplaceLayout>
  );
};

const TopContainer = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
  flex-direction: row-reverse;

  @media (max-width: ${TabletSize}) {
    flex-direction: column;
  }

  @media (min-width: ${TabletSize}) {
    margin-top: 48px;
  }
`;

const TextContainer = styled.div`
  max-width: 869px;

  h2 {
    margin-bottom: 8px;
  }
`;

const BacklinkContainer = styled(FlexRow)`
  padding: 16px;
  border: 1px solid ${COLORS.grey};
  border-radius: 3px;
  margin-left: 24px;
  align-items: center;

  & > .text {
    padding-right: 8px;
  }

  & > a {
    padding-left: 8px;
    border-left: 1px solid ${COLORS.grey};
  }

  @media (max-width: ${TabletSize}) {
    align-self: flex-end;
    margin-left: unset;
    margin-bottom: 16px;
  }
`;

const NationalPlansContainer = styled.div`
  background-color: ${COLORS.lighterGrey};
  border-radius: 3px;
  width: 100%;
  padding: 32px;
  margin-top: 32px;

  h5 {
    margin-bottom: 16px;
  }
`;

export default CollegeSavingsPlanner529MarketplaceStatePage;
