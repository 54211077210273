import styled from 'styled-components';

import { FlexRow, COLORS } from '@summer/ui-components';

import { MobileSize, SmallDesktopSize } from 'constants/styleguide';

const HeaderContainer = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${MobileSize}) {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
`;

const SyncBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px;
  background-color: ${COLORS.lighterGrey};
  align-items: center;
  justify-content: space-between;

  & button {
    flex-shrink: 0.5;
  }

  @media (max-width: ${MobileSize}) {
    display: flex;
    flex-direction: column;

    & > span {
      margin-left: auto;
    }
  }
`;

const ContentContainer = styled.div`
  margin-right: 8px;
  max-width: 80%;

  @media (max-width: ${MobileSize}) {
    max-width: 100%;
    margin-bottom: 16px;
  }
`;

const DesktopHeader = styled.div`
  margin-bottom: 16px;

  @media (max-width: ${SmallDesktopSize}) {
    display: none;
  }
`;

const SectionHeader = styled.div`
  margin-bottom: 16px;
`;

const Left = styled.div`
  @media (max-width: ${MobileSize}) {
    margin-top: 16px;
  }
`;

const Container = styled.div`
  padding-top: 56px;
  max-width: 1040px;
  width: 100%;

  & > * {
    margin-bottom: 32px;
  }
  @media (max-width: ${SmallDesktopSize}) {
    padding-top: 0;
  }
`;

const AlertContainer = styled.div``;

export {
  HeaderContainer,
  SyncBox,
  ContentContainer,
  DesktopHeader,
  SectionHeader,
  Left,
  Container,
  AlertContainer,
};
