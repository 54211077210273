import React, { useMemo } from 'react';
import { isNil, range } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { COLORS } from '../../helpers/constants/styleguide';
import Button from '../Button';
import { FlexRow } from '../Flex';

const NumbersPagination = ({ total, activeIndex = 0, onActiveIndexChange }) => {
  const maxItemsShown = 7;
  const showEllipses = total > maxItemsShown;
  const showEllipsis1 = showEllipses && activeIndex > maxItemsShown / 2;
  const showEllipsis2 =
    showEllipses && activeIndex < total - 1 - maxItemsShown / 2;
  const itemsBeforeEllipsis = showEllipsis1 ? [0] : [];
  const itemsNearIndex = useMemo(() => {
    if (showEllipsis1 && showEllipsis2) {
      return range(Math.max(activeIndex - 1, 0), Math.min(activeIndex + 2));
    }
    if (showEllipsis1) {
      return range(Math.max(total - (maxItemsShown - 2), 0), total);
    }
    if (showEllipsis2) {
      return range(0, Math.min(maxItemsShown - 2, total));
    }
    return range(0, total);
  }, [total, activeIndex, showEllipsis1, showEllipsis2]);
  const itemsAfterEllipsis = showEllipsis2 ? [total - 1] : [];

  return (
    <FlexRow style={{ alignItems: 'center' }}>
      <StyledButton
        secondary
        borderless
        onClick={() => {
          if (!isNil(activeIndex) && !isNil(onActiveIndexChange)) {
            onActiveIndexChange(Math.max(activeIndex - 1, 0));
          }
        }}
        disabled={isNil(activeIndex) || activeIndex <= 0}
      >
        Previous
      </StyledButton>
      {itemsBeforeEllipsis.map(idx => (
        <PageItemButton
          key={idx}
          index={idx}
          onActiveIndexChange={onActiveIndexChange}
          active={idx === activeIndex}
        />
      ))}
      {showEllipsis1 && <Ellipsis />}
      {itemsNearIndex.map(idx => (
        <PageItemButton
          key={idx}
          index={idx}
          onActiveIndexChange={onActiveIndexChange}
          active={idx === activeIndex}
        />
      ))}
      {showEllipsis2 && <Ellipsis />}
      {itemsAfterEllipsis.map(idx => (
        <PageItemButton
          key={idx}
          index={idx}
          onActiveIndexChange={onActiveIndexChange}
          active={idx === activeIndex}
        />
      ))}
      <StyledButton
        secondary
        borderless
        onClick={() => {
          if (!isNil(activeIndex) && !isNil(onActiveIndexChange)) {
            onActiveIndexChange(Math.min(activeIndex + 1, total - 1));
          }
        }}
        disabled={
          isNil(activeIndex) || isNil(total) || activeIndex >= total - 1
        }
      >
        Next
      </StyledButton>
    </FlexRow>
  );
};

NumbersPagination.propTypes = {
  total: PropTypes.number,
  activeIndex: PropTypes.number,
  onActiveIndexChange: PropTypes.func,
};

const PageItemButton = ({ index, onActiveIndexChange, active }) => (
  <PageItemDiv
    className={active ? 'active' : ''}
    onClick={() => {
      if (!isNil(onActiveIndexChange)) {
        onActiveIndexChange(index);
      }
    }}
  >
    {index + 1}
  </PageItemDiv>
);

const Ellipsis = () => <EllipsisDiv>...</EllipsisDiv>;

const EllipsisDiv = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageItemDiv = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.grey};
  }

  &.active {
    background-color: ${COLORS.azure};
    color: ${COLORS.white};
  }
`;

const StyledButton = styled(Button)`
  padding: 0;
  width: unset;
  min-width: unset;

  &:first-child {
    margin-right: 8px;
  }

  &:last-child {
    margin-left: 8px;
  }
`;

export default NumbersPagination;
