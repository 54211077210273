import * as Sentry from '@sentry/browser';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useDispatch, useSelector } from 'react-redux';

import { PLAID_LINK_CUSTOMIZATION_NAMES } from '@simplifidev/shared/dist/constants/plaidLink';

import { trackServerSide } from 'redux/actions/analytics.actions';
import {
  postPlaidIntegration,
  createPlaidLinkToken,
} from 'redux/actions/plaid.actions';
import { isLoadingWithInit } from 'redux/selectors/ui.selectors';

export const PLAID_EVENTS = {
  open: 'OPEN',
  exit: 'EXIT',
  handoff: 'HANDOFF',
  transition: 'TRANSITION_VIEW',
  success: 'SUCCESS',
  error: 'ERROR',
};

export const usePlaid = ({
  linkCustomizationName = PLAID_LINK_CUSTOMIZATION_NAMES.default,
  successAction,
  plaidConfig = {},
} = {}) => {
  const [event, setEvent] = useState();
  const [linkToken, setLinkToken] = useState(null);
  const [institutionId, setInstitutionId] = useState(null);
  const dispatch = useDispatch();

  const isLinkTokenLoading = useSelector(state =>
    isLoadingWithInit(state, 'createPlaidLinkToken'),
  );

  const fetchPlaidLinkToken = useCallback(
    () => dispatch(createPlaidLinkToken(linkCustomizationName, setLinkToken)),
    [dispatch, linkCustomizationName],
  );

  useEffect(
    function fetchTokenOnMount() {
      fetchPlaidLinkToken();
    },
    [fetchPlaidLinkToken],
  );

  const onExit = useCallback(
    (error, metadata) => {
      if (error?.error_code === 'INVALID_LINK_TOKEN') {
        // generate new link token if current token is expired
        fetchPlaidLinkToken();
      } else if (error) {
        // Segment
        dispatch(
          trackServerSide('[Plaid] Link error', {
            properties: {
              error_type: error.error_type,
              error_code: error.error_code,
              error_message: error.error_message,
              institution_name: metadata?.institution.name,
              link_session_id: metadata?.link_session_id,
            },
          }),
        );

        // Sentry
        Sentry.withScope(scope => {
          scope.setTag('Plaid link institution', metadata?.institution.name);
          scope.setTag('Plaid link error code', error.error_code);
          scope.setLevel(Sentry.Severity.Info);
          scope.setExtra('error', error);
          scope.setExtra('metadata', metadata);

          Sentry.captureMessage(error.error_message);
        });
      }
    },
    [dispatch, fetchPlaidLinkToken],
  );

  const onSuccess = useCallback(
    (token, metadata) => {
      const institutionMetadata = metadata.institution;
      dispatch(postPlaidIntegration(token, institutionMetadata, successAction));
      setInstitutionId(institutionMetadata?.institution_id || null);
      setEvent('SUCCESS');
    },
    [dispatch, successAction],
  );

  const config = useMemo(() => {
    return {
      token: linkToken,
      onSuccess,
      onEvent: setEvent,
      onExit,
      ...plaidConfig,
    };
  }, [onSuccess, plaidConfig, onExit, linkToken]);

  const { open, ready } = usePlaidLink(config);

  const isLoading = useMemo(
    () => isLinkTokenLoading || ready !== true,
    [isLinkTokenLoading, ready],
  );

  return {
    open,
    event,
    isLoading,
    institutionId,
  };
};
