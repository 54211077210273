import { every, isEmpty, get, map } from 'lodash';
import React, { useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { FormTypes } from '@simplifidev/shared/dist/constants/forms';

import { Text, LoadingBars, COLORS } from '@summer/ui-components';

import HellosignAlert from 'components/common/HellosignAlert';
import SignRequestRow from 'components/common/SignRequestRow';
import Header from 'components/pages/idr/wizard/steps/Submit/shared/Header';
import { TabletSize } from 'constants/styleguide';
import { useHellosign, useAlertHellosignError } from 'hooks/hellosign';
import { trackServerSide } from 'redux/actions/analytics.actions';
import {
  fetchSignatureRequestListV3,
  updateSignatureRequest,
} from 'redux/actions/signatureRequests.actions';
import {
  selectedPathIncludesConsolidation,
  getCurrentIdrFormId,
} from 'redux/selectors/idr.selectors';
import { getSignatureRequests } from 'redux/selectors/signatureRequests.selectors';
import { isLoadingWithInit } from 'redux/selectors/ui.selectors';
import { hideChat, showChat } from 'services/chat';
import { filterSignatureRequestsForIdr } from 'utils/idr';

const getFormTypeList = ({ requiresConsolidationForm, formId }) => {
  const formTypes = [{ type: FormTypes.idr, id: formId }];
  if (requiresConsolidationForm) {
    formTypes.push({ type: FormTypes.consolidation, id: formId });
  }
  return formTypes;
};

const SignatureRequestsList = ({
  signatureRequestsList,
  isLoading,
  handleButtonClick,
}) => {
  if (isEmpty(signatureRequestsList)) {
    return <LoadingBars color={COLORS.azure} />;
  }

  return map(signatureRequestsList, signRequest => (
    <SignRequestRow
      key={signRequest.formType}
      signRequest={signRequest}
      onClick={handleButtonClick}
      isLoading={isLoading}
    />
  ));
};

const SignAndSend = props => {
  const {
    signatureRequestsList,
    dispatch,
    requiresConsolidationForm,
    isLoading,
    serverError,
    idrHandlers: { setFieldValue },
    formId,
    errors,
    currentStepSubmitCount,
  } = props;

  const notSignedError = currentStepSubmitCount > 0 && errors.isValid;

  const formsToGetSignatureRequest = getFormTypeList({
    requiresConsolidationForm,
    formId,
  });

  const idrSignatureRequestsList = useMemo(
    () =>
      filterSignatureRequestsForIdr(
        signatureRequestsList,
        formsToGetSignatureRequest,
        formId,
      ),
    [signatureRequestsList, formsToGetSignatureRequest, formId],
  );

  const userFinishAllSignatures = useMemo(() => {
    if (isEmpty(idrSignatureRequestsList)) {
      return false;
    }
    return every(idrSignatureRequestsList, signatureRequest =>
      every(signatureRequest.signers, 'complete'),
    );
  }, [idrSignatureRequestsList]);

  useEffect(
    function updateFormValidation() {
      setFieldValue('signAndSend.isValid', userFinishAllSignatures);
    },
    [userFinishAllSignatures, setFieldValue],
  );

  const alertHellosignError = useAlertHellosignError(serverError);

  const [handleButtonClick, hellosignClient] = useHellosign(
    () => dispatch(fetchSignatureRequestListV3(formsToGetSignatureRequest)),
    {
      onOpen: useCallback(() => {
        dispatch(trackServerSide('User opened IDR Hellosign modal'));
        if (window.innerWidth <= parseInt(TabletSize, 10)) {
          hideChat();
        }
      }, [dispatch]),
      onClose: useCallback(() => {
        showChat();
      }, []),
      onSign: useCallback(
        data => {
          dispatch(updateSignatureRequest(data.signatureId, true));
        },
        [dispatch],
      ),
    },
  );

  useEffect(
    function closeHellosign() {
      return () => {
        hellosignClient.close();
      };
    },
    [hellosignClient],
  );

  return (
    <>
      <Header>Sign and send the application forms</Header>
      <Text paragraph>
        We’ve pre-filled your forms with the information you provided. Now you
        just need to add any last few items of sensitive information (such as
        your social security number) and sign them.
      </Text>
      {alertHellosignError && (
        <HellosignAlertContainer>
          <HellosignAlert />
        </HellosignAlertContainer>
      )}
      <SignatureRequestsContainer>
        <SignatureRequestsList
          signatureRequestsList={idrSignatureRequestsList}
          isLoading={isLoading || alertHellosignError}
          handleButtonClick={handleButtonClick}
        />
      </SignatureRequestsContainer>
      {notSignedError && (
        <Text small color="error">
          {notSignedError}
        </Text>
      )}
    </>
  );
};

const SignatureRequestsContainer = styled.div`
  margin-top: 24px;

  & > div {
    margin-bottom: 16px;
  }
  & > div:last-child {
    margin-bottom: 16px;
  }
`;

const HellosignAlertContainer = styled.div`
  margin-top: 24px;
`;

const mapStateToProps = state => ({
  signatureRequestsList: getSignatureRequests(state),
  requiresConsolidationForm: selectedPathIncludesConsolidation(state),
  isLoading: isLoadingWithInit(state, 'fetchSignatureRequestListV3'),
  serverError: get(state, 'ui.serverErrors.fetchSignatureRequestListV3'),
  formId: getCurrentIdrFormId(state),
});

export default connect(mapStateToProps)(SignAndSend);
