import { random } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  Header,
  FlexRow,
  FlexColumn,
  Icon,
  BackgroundSvg,
  FileTextUpload,
  COLORS,
} from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import { MobileSize } from 'constants/styleguide';
import { hexToRgba } from 'utils/common';

import MissingInfoHeader from './MissingInfoHeader';

const ConnectFsaHeader = ({
  page,
  headerSize,
  headerContent,
  headerImgPath,
  platformType,
  mobileHeaderAlign,
  showMissingInfoHeader,
}) => {
  const isMobile = platformType === MOBILE;

  const backgroundRotateDeg = React.useMemo(() => {
    return random(90, 180);
  }, []);

  return showMissingInfoHeader ? (
    <MissingInfoHeader platformType={platformType} />
  ) : (
    <BackgroundContainer hasHeaderImg={headerImgPath}>
      <BackgroundShape
        fill={hexToRgba(COLORS.jade, 0.16)}
        rotate={backgroundRotateDeg}
        SvgComponent={BackgroundSvg}
      />
      <Content mobileHeaderAlign={mobileHeaderAlign}>
        <TextContainer
          page={page}
          isMobile={isMobile}
          hasHeaderContent={headerContent}
          mobileHeaderAlign={mobileHeaderAlign}
        >
          <SyncTitle hasHeaderContent={headerContent}>
            <UploadFileIcon>
              <Icon
                fill={COLORS.darkGreen}
                SvgComponent={FileTextUpload}
                width={28}
                height={28}
              />
            </UploadFileIcon>
            <Header as={isMobile ? 'h3' : headerSize}>
              Upload your loan file
            </Header>
          </SyncTitle>
          {headerContent}
        </TextContainer>
        {headerImgPath && (
          <ImageContainer>
            <img alt="Sync Info" src={headerImgPath} width="100%" />
          </ImageContainer>
        )}
      </Content>
    </BackgroundContainer>
  );
};

const BackgroundContainer = styled.div`
  position: relative;
  background-color: ${hexToRgba(COLORS.jade, 0.12)};
  width: 100%;
  top: 0;
  left: 0;
  padding: 24px 48px;
  overflow: hidden;
  z-index: 0;

  @media (max-width: ${MobileSize}) {
    padding: 24px;
    ${({ hasHeaderImg }) => (hasHeaderImg ? 'padding-bottom: 48px;' : '')}
  }
`;

const BackgroundShape = styled(Icon)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: ${({ rotate }) => `rotate(${rotate}deg)`} scale(48);
  z-index: 1;

  @media (max-width: ${MobileSize}) {
    transform: ${({ rotate }) => `rotate(${rotate}deg)`} scale(30);
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 840px;
  margin: 0 auto;
  justify-content: space-between;
  z-index: 2;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
    align-items: ${({ mobileHeaderAlign }) =>
      `${mobileHeaderAlign === 'left' ? 'flex-start' : 'center'}`};
  }
`;

const TextContainer = styled(FlexColumn)`
  margin-right: 32px;
  align-items: flex-start;
  text-align: left;
  max-width: 426px;
  padding: ${({ hasHeaderContent }) =>
    hasHeaderContent ? '32px 0px' : '24px 0px'};

  @media (max-width: ${MobileSize}) {
    padding: 32px 0;
    margin-right: 0;
    text-align: ${({ mobileHeaderAlign }) => `${mobileHeaderAlign}`};
  }
`;

const ImageContainer = styled.div`
  max-width: 314px;

  @media (max-width: ${MobileSize}) {
    max-width: 288px;
  }
`;

const UploadFileIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.white};
  width: 59px;
  height: 59px;
  border-radius: calc(59px / 2);
  margin-right: 16px;
`;

const SyncTitle = styled(FlexRow)`
  align-items: center;
  margin-bottom: ${({ hasHeaderContent }) =>
    hasHeaderContent ? '24px' : '0px'};

  @media (max-width: ${MobileSize}) {
    margin-bottom: 24px;
  }
`;

ConnectFsaHeader.propTypes = {
  mobileHeaderAlign: PropTypes.oneOf(['center', 'left']),
};

ConnectFsaHeader.defaultProps = {
  mobileHeaderAlign: 'center',
};

export default Platform(ConnectFsaHeader);
