import React from 'react';
import styled from 'styled-components';

import { COLORS } from '../../../helpers/constants/styleguide';

class RadioCircle extends React.Component {
  render() {
    const { checked, name, value, onChange, size } = this.props;

    return (
      <RadioContainer checked={checked} size={size}>
        <StyledRadio
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          type="radio"
          data-testid={`radio-circle-${value}`}
        />
      </RadioContainer>
    );
  }
}
export default RadioCircle;

const RadioContainer = styled.div`
  position: relative;
  background-color: ${COLORS.white};
  width: ${({ size }) => size || '1em'};
  min-width: ${({ size }) => size || '1em'};
  height: ${({ size }) => size || '1em'};
  min-height: ${({ size }) => size || '1em'};
  border-radius: 50%;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, 0.2);

  ${({ checked }) =>
    !checked &&
    `
    &:hover {
      background-color: ${COLORS.lightestGrey};
    }
  `};
`;

const StyledRadio = styled.input`
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-width: 0px;
  appearance: none;
  cursor: pointer;
  transition: background-color 0.1s ease;

  &:checked {
    background-color: ${COLORS.azure};
    width: 60%;
    height: 60%;
    margin: 20%;
    box-shadow: inset 0 -2px 7px 0 rgba(0, 0, 0, 0.06);
  }

  &:focus {
    background-color: ${({ checked }) =>
      checked ? COLORS.azure : COLORS.grey};
    outline: none;
  }
`;
