import { push as redirectTo } from 'connected-react-router';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  SelectInput,
  Header,
  Text,
  Button,
  FlexRow,
  MobileSize,
} from '@summer/ui-components';

import { States } from 'constants/states';

import { MarketplaceLayout } from './layout';

const CollegeSavingsPlanner529Marketplace = () => {
  const [state, setState] = useState(null);
  const dispatch = useDispatch();

  const onChange = (name, value) => setState(value);
  const onClick = () =>
    dispatch(
      redirectTo(
        `/college-savings-planner/529-marketplace/${state.toLowerCase()}`,
      ),
    );

  return (
    <MarketplaceLayout>
      <Container>
        <Header h2>What state are you looking to create your 529 Plan?</Header>
        <Text paragraph>
          Every state has their own 529 Plan, but you don’t have to use your
          state’s plan if you’d prefer to house your college savings elsewhere.
        </Text>
        <DropdownContainer>
          <SelectInput
            notFormik
            label="State"
            name="stateOfResidence"
            value={state}
            options={States}
            placeholder="Select State"
            onBlur={() => {}}
            onChange={onChange}
          />
          <Button disabled={!state} onClick={onClick} width={104}>
            See Plans
          </Button>
        </DropdownContainer>
      </Container>
      <USMap
        alt="United States map"
        src="/images/collegeSavingsPlanner/529marketplace/united-states.png"
      />
    </MarketplaceLayout>
  );
};

const Container = styled.div`
  max-width: 464px;

  & > .text {
    margin: 16px 0;
  }
`;

const DropdownContainer = styled(FlexRow)`
  align-items: center;

  button {
    height: 48px;
    margin-left: 16px;
  }

  @media (max-width: ${MobileSize}) {
    flex-direction: column;

    button {
      margin-left: unset;
      margin-top: -8px;
    }
  }
`;

const USMap = styled.img`
  max-width: 600px;
  width: 100%;
  margin-top: 32px;
`;

export default CollegeSavingsPlanner529Marketplace;
