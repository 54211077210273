import { format, isBefore, parseISO } from 'date-fns';
import { get, lowerCase, startCase } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { FlexRow, Text, COLORS } from '@summer/ui-components';

import InfoTooltip from 'components/shared/InfoTooltip';
import { getCurrentLoan } from 'redux/selectors/loans.selectors';
import { getEmployerEndDate } from 'redux/selectors/pslf.selectors';

const RepaymentPlanTypePslf = ({
  employerEndDate,
  pslfEligibleRepaymentPlan,
  repaymentPlan,
  repaymentPlanShortName,
  repaymentPlanStartDate,
  repaymentBeginDate,
}) => {
  /* repaymentBeginDate is origination of the loan
   * repaymentPlanStartDate is start of repayment plan, but only present on IDR plans
   * use repaymentPlanStartDate for start date of current plan,
   * with repaymentBeginDate as a fallback
   * */
  const planStartDate = repaymentPlanStartDate || repaymentBeginDate;

  const endOfEmploymentPeriod = parseISO(employerEndDate) || new Date();

  const repaymentPlanIsDuringEmploymentPeriod = isBefore(
    parseISO(planStartDate),
    endOfEmploymentPeriod,
  );

  const repaymentPlanName =
    repaymentPlanShortName || startCase(lowerCase(repaymentPlan));

  const tooltipText = (
    <>
      Repayment Plan determines how much and for how long you have to pay back
      your loans. All borrowers are automatically enrolled in the “Standard
      Plan” which means that you have to pay back your loans in 10 years.
      <br />
      <br />
      The standard 10-year and income-driven repayment plans such as REPAYE,
      PAYE, IBR, and ICR are eligible for Public Service Loan Forgiveness,
      however you will only get forgiveness if you are enrolled in one of the
      income-driven repayment plans.
    </>
  );

  return (
    <Container>
      <Label>
        <Text as="span" bold>
          Repayment Plan Type
        </Text>
        <InfoTooltip text={tooltipText} top={2} width={328} />
      </Label>
      {repaymentPlan && repaymentPlanIsDuringEmploymentPeriod ? (
        <>
          <RepaymentPlan>
            <Text>{repaymentPlanName}</Text>
            <Text small color="secondary">
              from {format(parseISO(planStartDate), 'MMM dd, yyyy')}
            </Text>
          </RepaymentPlan>
          <Info>
            <Text small>
              {pslfEligibleRepaymentPlan
                ? `The ${repaymentPlanName} plan is eligible for PSLF, however this
              eligibility may be overidden by other factors (i.e. your loan type
              or repayment status)`
                : `The ${repaymentPlanName} plan is not eligible for PSLF`}
            </Text>
          </Info>
        </>
      ) : (
        <Text color="secondary">Unknown</Text>
      )}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  width: 100%;
  margin-bottom: 16px;
`;

const Label = styled.div`
  margin-bottom: 8px;
`;

const RepaymentPlan = styled(FlexRow)`
  justify-content: space-between;
  align-items: baseline;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${COLORS.grey};
`;

const Info = styled.div`
  margin-top: 12px;
`;

const mapStateToProps = state => ({
  repaymentPlan: get(getCurrentLoan(state), 'repaymentPlan'),
  repaymentPlanShortName: get(getCurrentLoan(state), 'repaymentPlanShortName'),
  repaymentPlanStartDate: get(getCurrentLoan(state), 'repaymentPlanStartDate'),
  repaymentBeginDate: get(getCurrentLoan(state), 'repaymentBeginDate'),
  pslfEligibleRepaymentPlan: get(
    getCurrentLoan(state),
    'pslfEligibleRepaymentPlan',
  ),
  employerEndDate: getEmployerEndDate(state),
});

export default connect(mapStateToProps)(RepaymentPlanTypePslf);
