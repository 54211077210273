import React from 'react';
import styled from 'styled-components';

import { Header, COLORS, LoadingBars, Text } from '@summer/ui-components/src';

import { MobileSize } from 'constants/styleguide';

const InitialLoadingScreen = () => {
  return (
    <LoadingPageWrapper>
      <LoadingBars color={COLORS.azure} />
      <LoadingPageHeader h2>
        We are in the process of pulling your payment history from your
        servicer.
      </LoadingPageHeader>
      <List>
        <li>
          <Text>
            This data will be used to determine your employer&rsquo;s retirement
            match based on the terms of the program.
          </Text>
        </li>
        <li>
          <Text>
            Please be patient and allow up to 1 minute for us to fetch this
            data.
          </Text>
        </li>
      </List>
    </LoadingPageWrapper>
  );
};

const LoadingPageHeader = styled(Header)`
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (max-width: ${MobileSize}) {
    margin-top: 32px;
  }
`;

const LoadingPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 650px;
  width: 100%;
  font-weight: 600;
  padding-top: 72px;

  @media (max-width: ${MobileSize}) {
    padding-top: 15%;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const List = styled.ul`
  max-width: 570px;
  margin-bottom: 16px;
  & li {
    margin-bottom: 12px;
  }

  @media (max-width: ${MobileSize}) {
    margin-left: 32px;
    margin-bottom: 0;
  }
`;

export default InitialLoadingScreen;
