import { push as redirectTo } from 'connected-react-router';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { LoadingFullPage } from '@summer/ui-components';

import { usePrevious } from 'hooks/common';
import { fetchIdrForms, updateCurrentIdrForm } from 'redux/actions/idr.actions';
import { getCurrentIdrFormId } from 'redux/selectors/idr.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';

const EditIdrForm = ({ formId, isFetchFormsLoading, dispatch }) => {
  const finishedLoading =
    usePrevious(isFetchFormsLoading) && !isFetchFormsLoading;

  useEffect(
    function resetIdrForm() {
      if (formId) {
        dispatch(
          updateCurrentIdrForm({
            form: { formId },
            currentStep: 1,
          }),
        );
      } else {
        dispatch(fetchIdrForms());
      }

      if (!formId && finishedLoading) {
        dispatch(redirectTo('/repayment'));
      }
    },
    [dispatch, formId, finishedLoading],
  );

  return <LoadingFullPage />;
};

const mapStateToProps = state => ({
  formId: getCurrentIdrFormId(state),
  isFetchFormsLoading: isLoading(state, 'fetchIdrForms'),
});

export default connect(mapStateToProps)(EditIdrForm);
