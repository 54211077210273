import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { random } from 'lodash';

import { COLORS, MobileSize } from '../../helpers/constants/styleguide';

import Header from '../typography/Header';
import Icon from '../Icon';
import { ReactComponent as BackgroundSvg } from './backgroundShape.svg';

const ColorBadge = ({ shortLabel, children, colorName }) => {
  const backgroundRotateDeg = React.useMemo(() => {
    return random(90, 180);
  }, []);

  return (
    <BadgeContainer colorName={colorName}>
      <BackgroundShape
        fill={COLORS[`${colorName}Dark`]}
        rotate={backgroundRotateDeg}
        SvgComponent={BackgroundSvg}
      />
      <Label h5 color="light" shortLabel={shortLabel}>
        {children}
      </Label>
      <ShortLabel h5 color="light" shortLabel={shortLabel}>
        {shortLabel}
      </ShortLabel>
    </BadgeContainer>
  );
};

const truncateCss = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BadgeContainer = styled.div`
  position: relative;
  border-radius: 30px;
  background-color: ${({ colorName }) => COLORS[colorName]};
  padding: 5px 15px;
  overflow: hidden;
  z-index: 0;
  max-width: fit-content;
`;

const BackgroundShape = styled(Icon)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: ${({ rotate }) => `rotate(${rotate}deg)`} scale(7);
  z-index: 1;
`;

const Label = styled(Header)`
  position: relative;
  z-index: 2;
  letter-spacing: 1px;
  ${truncateCss};

  @media (max-width: ${MobileSize}) {
    display: ${({ shortLabel }) => shortLabel && 'none'};
  }
`;

const ShortLabel = styled(Header)`
  display: none;
  position: relative;
  z-index: 2;

  @media (max-width: ${MobileSize}) {
    display: ${({ shortLabel }) => shortLabel && 'inline'};
  }
`;

ColorBadge.defaultProps = {
  colorName: 'magenta',
};

ColorBadge.propTypes = {
  colorName: PropTypes.oneOf([
    'gold',
    'magenta',
    'cobalt',
    'violet',
    'raven',
    'sand',
  ]).isRequired,
  shortLabel: PropTypes.string,
  children: PropTypes.string.isRequired,
};

export default ColorBadge;
