import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

import { COLORS } from '../../../helpers/constants/styleguide';
import { dollars, percentage } from '../../../helpers/utils/numbers';
import AnimatedNumber from '../../AnimatedNumber';

const formatFunctions = {
  dollars: (value, precision) =>
    dollars(value, { precision, showSymbol: false }),
  percentage,
  number: value => value.toString(),
};

const colors = {
  default: COLORS.darkerGrey,
  secondary: COLORS.medGrey,
  summer: COLORS.azure,
};

const dollarSignSizes = {
  extraSmall: 12,
  small: 12,
  medium: 18,
  large: 20,
};

const dollarSignTop = {
  extraSmall: 1,
  small: 1,
  medium: 6,
  large: 5,
};

const numberSizes = {
  extraSmall: 18,
  small: 20,
  medium: 32,
  large: 40,
};

const Statistic = ({
  format,
  bold,
  value,
  animate,
  color,
  strikethrough,
  size,
  precision,
  className,
  testId,
}) => {
  const unavailable = isNil(value);
  const convertToString = value => {
    if (unavailable) {
      return 'Unavailable';
    }
    return formatFunctions[format](value, precision);
  };
  const showDollarSign = format === 'dollars' && !unavailable;
  const animateNumber = animate && !unavailable;

  return (
    <Container
      color={color}
      unavailable={unavailable}
      className={className}
      data-testid={testId}
    >
      {showDollarSign && (
        <DollarSign size={size} bold={bold}>
          $
        </DollarSign>
      )}
      <Number
        unavailable={unavailable}
        value={value}
        size={size}
        bold={bold}
        strikethrough={strikethrough}
      >
        {animateNumber && !unavailable ? (
          <AnimatedNumber
            value={value}
            formatValue={convertToString}
            fontSize={numberSizes[size]}
            stepPrecision={format === 'percentage' ? 4 : 0}
          />
        ) : (
          convertToString(value)
        )}
      </Number>
    </Container>
  );
};

const Container = styled.div`
  color: ${props => (props.unavailable ? COLORS.medGrey : colors[props.color])};
`;

const DollarSign = styled.span`
  font-size: ${props => dollarSignSizes[props.size]}px;
  font-weight: ${props => (props.bold ? '600' : 'normal')};
  position: relative;
  top: ${props => dollarSignTop[props.size]}px;
  vertical-align: top;
  display: inline-block;
`;

const Number = styled.span`
  font-size: ${props => {
    if (props.unavailable) {
      if (props.size === 'extraSmall') {
        return '18';
      }
      if (props.size === 'small') {
        return '20';
      }
      return '24';
    }
    if (
      props.size !== 'small' &&
      props.size !== 'extraSmall' &&
      props.value > 9999999
    ) {
      return '30';
    }
    return numberSizes[props.size];
  }}px;
  text-decoration: ${props =>
    props.strikethrough ? 'line-through' : 'initial'};
  font-weight: ${props => (props.bold ? '600' : 'normal')};

  vertical-align: top;
  display: inline-block;
`;

Statistic.propTypes = {
  format: PropTypes.oneOf(['dollars', 'percentage', 'number']),
  animate: PropTypes.bool,
  bold: PropTypes.bool,
  strikethrough: PropTypes.bool,
  color: PropTypes.string,
  value: PropTypes.number,
};

Statistic.defaultProps = {
  format: 'number',
  animate: false,
  bold: false,
  strikethrough: false,
  color: 'default',
  value: null,
  size: 'medium',
};

export const StorybookStatistic = Statistic;
export default memo(Statistic);
