import styled from 'styled-components';

import { MobileSize } from 'constants/styleguide';

const Placeholder = styled.div`
  width: 100%;
  flex: 1;

  @media (max-width: ${MobileSize}) {
    display: none;
  }
`;

export default Placeholder;
