import { format, parseISO } from 'date-fns';
import { isEmpty, lowerCase, map, startCase } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { FlexRow, Text, COLORS } from '@summer/ui-components';

import InfoTooltip from 'components/shared/InfoTooltip';
import { getLoanStatusHistoryForPslf } from 'redux/selectors/loans.selectors';

const LoanStatusHistory = ({ loanStatusHistory }) => {
  const tooltipText = (
    <>
      <Text small as="p">
        This shows the Loan Repayment Status for the employment period that you
        entered.
      </Text>
      <br />
      <Text small as="p">
        The Loan Repayment Status shows the different statuses of your loan as
        reported by Federal Student Aid. Most loans start out as “Originated”,
        move into “Grace Period” after you leave school, and then enter
        “Repayment” which is when you’re required to start making payments. Any
        periods in Forbearance, Deferment, or Default are not considered
        qualifying payments for PSLF.
      </Text>
    </>
  );

  return (
    <Container>
      <Label>
        <Text bold as="span">
          Loan Repayment Status
        </Text>
        <InfoTooltip text={tooltipText} top={2} width={300} />
      </Label>
      {isEmpty(loanStatusHistory) ? (
        <Text color="secondary">Unknown</Text>
      ) : (
        <>
          {map(
            loanStatusHistory,
            ({ loanStatusDescription, loanStatusEffectiveDate }) => (
              <LoanStatusRow key={loanStatusEffectiveDate}>
                <Text>{startCase(lowerCase(loanStatusDescription))}</Text>
                <Text right small color="secondary">
                  from{' '}
                  {format(parseISO(loanStatusEffectiveDate), 'MMM dd, yyyy')}
                </Text>
              </LoanStatusRow>
            ),
          )}
          <Info>
            <Text small>
              Periods in default, deferment, forbearance, or grace period are
              not considered qualifying payments.
            </Text>
          </Info>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  width: 100%;
  margin-bottom: 16px;
`;

const Label = styled.div`
  margin-bottom: 8px;
`;

const LoanStatusRow = styled(FlexRow)`
  justify-content: space-between;
  align-items: baseline;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${COLORS.grey};

  & > div:first-child {
    flex: 3;
    margin-right: 8px;
  }
  & > div:last-child {
    flex: 2;
    min-width: 120px;
  }
`;

const Info = styled.div`
  margin-top: 12px;
`;

const mapStateToProps = state => ({
  loanStatusHistory: getLoanStatusHistoryForPslf(state),
});

export default connect(mapStateToProps)(LoanStatusHistory);
