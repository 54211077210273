import React from 'react';
import styled from 'styled-components';

import { Text, FlexColumn, RadioButtons } from '@summer/ui-components';

const PayFrequency = ({ idrHandlers, touched, errors, values, userType }) => {
  const { isSubmitting, handleChange, handleBlur } = idrHandlers;

  const isSpouse = userType === 'spouseProofOfIncome';
  let title = 'What is your pay frequency?';
  let subheading =
    'Select how often you get paid to help us verify your income.';
  let submitName = 'submit.payFrequency';
  if (isSpouse) {
    title = 'What is your spouse\u2019s pay frequency?';
    subheading =
      'Select how often your spouse gets paid to help us verify their income.';
    submitName = 'submit.spousePayFrequency';
  }

  const buttonOptions = [
    { value: 'weekly', label: 'Weekly' },
    {
      value: 'biweekly',
      label: 'Biweekly (every other week)',
    },
    {
      value: 'semimonthly',
      label: 'Semimonthly (twice a month)',
    },
    {
      value: 'monthly',
      label: 'Monthly',
    },
  ];

  const buttonCSS = `
        width: 100%;
        height: 40px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);

        input {
          margin-left: -50%;
        }
      `;

  return (
    <div>
      <FlexColumn>
        <Text large bold>
          {title}
        </Text>
        <SubtextContainer>
          <Text>{subheading}</Text>
        </SubtextContainer>
      </FlexColumn>
      <RadioButtons
        design="smallRec"
        name={submitName}
        touched={isSpouse ? touched.spousePayFrequency : touched.payFrequency}
        isSubmitting={isSubmitting}
        error={isSpouse ? errors.spousePayFrequency : errors.payFrequency}
        value={isSpouse ? values.spousePayFrequency : values.payFrequency}
        onChange={handleChange}
        onBlur={handleBlur}
        options={buttonOptions}
        buttonCSS={buttonCSS}
        numberOfCol={1}
      />
    </div>
  );
};

const SubtextContainer = styled.div`
  margin: 16px 0;
`;

export default PayFrequency;
