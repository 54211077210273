import React from 'react';
import styled from 'styled-components';

import EducationBanner from 'components/pages/idr/wizard/steps/ReviewYourPlan/NewRYP/EducationBanner';
import PlanOptions from 'components/pages/idr/wizard/steps/ReviewYourPlan/NewRYP/PlanOptions';
import RypHeader from 'components/pages/idr/wizard/steps/ReviewYourPlan/NewRYP/RypHeader';
import FAQ from 'components/pages/idr/wizard/steps/ReviewYourPlan/NewRYP/faq';
import { MobileSize } from 'constants/styleguide';

const NewRYP = ({
  platformType,
  currentPath,
  paths,
  pslf,
  handleSubmit,
  incrementSubmitCount,
}) => {
  return (
    <Container>
      <Section>
        <RypHeader pslf={pslf} />
      </Section>
      <Section>
        <PlanOptions
          isPslfOn={pslf.isPslf}
          currentPath={currentPath}
          paths={paths}
          handleSubmit={handleSubmit}
          incrementSubmitCount={incrementSubmitCount}
        />
      </Section>
      <EducationBanner isPslfOn={pslf.isPslf} />
      <Section>
        <FAQ platformType={platformType} />
      </Section>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 -32px;

  @media (max-width: ${MobileSize}) {
    margin: 0 -16px;
  }
`;

const Section = styled.div`
  width: 100%;
  padding: 0px 32px;
  margin-bottom: 24px;

  @media (max-width: ${MobileSize}) {
    padding: 0px 16px;
  }
`;

export default NewRYP;
