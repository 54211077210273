import { get } from 'lodash';

export const getNerdWalletUrlPartnerData = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const subid = queryParams.get('subid');

  return {
    urlData: {
      partnerIdentifierData: {
        identifierValue: subid,
        identifierMetadata: {},
      },
    },
    isValidUrl: true,
  };
};

export const getNerdWalletIdentifierData = urlData => {
  return get(urlData, 'urlData.partnerIdentifierData');
};
