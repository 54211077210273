import { getFlag } from 'services/featureFlags';
import { isDevEnv, isProductionEnv } from 'utils/common';

export const forceShowPslfCompletePage = () => {
  return getFlag('forceShowPslfCompletePage') === 'true';
};

export const enableDTC = () => isDevEnv();

export const countPastPayments = () => {
  if (isProductionEnv()) {
    return true;
  }
  return getFlag('countPastPayments') !== 'false';
};

export const showPlaidInIdr = () => getFlag('showPlaidInIdr') === 'true';

export const enablePlaidConsolidation = () => true;
