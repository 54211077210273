import React, { Component } from 'react';

import { RadioButtons } from '@summer/ui-components';

import { Question, Label } from 'components/pages/idr/wizard/shared/styles';
import { validateString } from 'utils/validate';

class ChangeInIncome extends Component {
  componentWillUnmount() {
    this.props.formik.setFieldValue(`tellUsAboutYou.changeInIncome`, '');
  }

  render() {
    const {
      formik: {
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        isSubmitting,
      },
    } = this.props;
    return (
      <div>
        <Question id="changeInIncome">
          <Label>
            Has your income significantly changed since you filed your last
            federal income tax return?
          </Label>
          <RadioButtons
            row
            design="smallRec"
            name="tellUsAboutYou.changeInIncome"
            touched={touched.changeInIncome}
            isSubmitting={isSubmitting}
            error={errors.changeInIncome}
            value={values.changeInIncome}
            onChange={handleChange}
            onBlur={handleBlur}
            options={[
              { value: 'yes', label: 'Yes' },
              { value: 'no', label: 'No' },
              {
                value: 'not-filed',
                label: 'I haven\u2019t filed taxes in the last 2 years',
              },
            ]}
            validate={validateString()}
          />
        </Question>
      </div>
    );
  }
}

export default ChangeInIncome;
