export const marketingSiteLink = 'https://www.meetsummer.com/';
export const blogLink = 'https://www.meetsummer.com/blog';

export const bcorpLink =
  'https://www.meetsummer.com/post/summer-joins-the-b-corp-movement';

export const csp529PlanLink =
  'https://www.meetsummer.com/post/college-planning-how-a-529-plan-can-help';
export const cspFafsaLink =
  'https://www.meetsummer.com/post/college-planning-everything-you-need-to-know-about-the-fafsa';
export const cspNavigatingFinancialAidLink =
  'https://www.meetsummer.com/post/college-planning-navigating-financial-aid';
export const cspOtherFundingOptionsLink =
  'https://www.meetsummer.com/post/college-planning-exploring-other-funding-options';
export const cspConsiderLoansLink =
  'https://www.meetsummer.com/post/college-planning-when-to-consider-student-loans';
export const cspFederalVsPrivateLink =
  'https://www.meetsummer.com/post/college-planning-federal-v-private-student-loans';

export const pslfWaiverLink =
  'https://www.meetsummer.com/post/department-of-education-announces-pslf-and-idr-adjustment-updates';
export const pslfGuideLink =
  'https://www.meetsummer.com/post/public-service-loan-forgiveness-pslf-guide/';
export const refiGuideLink =
  'https://www.meetsummer.com/student-loan-refinancing-guide/';
export const idrGuideLink =
  'https://www.meetsummer.com/income-driven-repayment-idr-plan-guide/';
export const rmGuideLink =
  'https://www.meetsummer.com/post/secure-act-student-loan-retirement-match';
