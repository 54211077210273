import React from 'react';
import styled from 'styled-components';

import { FlexColumn, FlexRow, Text } from '@summer/ui-components';

import { MobileSize } from 'constants/styleguide';

export const Question = styled.div`
  align-items: center;
  margin-bottom: 8px;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
    width: 100%;
  }
`;

export const Label = ({ children }) => (
  <LabelBox>
    <Text large bold>
      {children}
    </Text>
  </LabelBox>
);
const LabelBox = styled.div`
  margin-bottom: 16px;
`;

export const LabelContainer = styled.span`
  display: flex;
`;

export const SmallTextInputContainer = styled.div`
  width: 40%;
  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

export const MediumTextInputContainer = styled.div`
  width: 50%;
`;

export const Row = styled(FlexRow)`
  & > * {
    margin-right: 24px;
  }
  & > *:last-child {
    margin-right: 0;
  }
  @media (max-width: 600px) {
    flex-wrap: wrap;
    & > * {
      margin-right: 0;
    }
  }
`;

export const Form = styled(FlexColumn.withComponent('form'))``;

export const Spacer = styled.div`
  flex: 1;
  @media (max-width: ${MobileSize}) {
    display: none;
  }
`;
