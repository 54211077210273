import React from 'react';
import styled from 'styled-components';

import { FlexColumn, Header, Text, COLORS } from '@summer/ui-components';

import { hexToRgba } from 'utils/common';

export const getSlcData = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return {
    firstName: queryParams.get('firstName'),
    lastName: queryParams.get('lastName'),
    email: queryParams.get('email'),
  };
};

export const SlcDetails = ({ partnerTitle }) => {
  const slcData = getSlcData();

  return (
    <Container>
      <SlcHeader>
        <Header h2>Welcome, {slcData.firstName}!</Header>
        <Text small color="default">
          These details came through from {partnerTitle}:
        </Text>
      </SlcHeader>
      <FlexColumn>
        <Header h5 color="default">
          Email Address
        </Header>
        <Text medium color="secondary">
          {slcData.email}
        </Text>
      </FlexColumn>
    </Container>
  );
};

const Container = styled(FlexColumn)`
  width: 100%;
  padding: 24px;
  margin-bottom: 15px;
  background-color: ${hexToRgba(COLORS.azure, '0.05')};
`;

const SlcHeader = styled.div`
  h2 {
    margin-bottom: 8px;
  }

  margin-bottom: 16px;
`;
