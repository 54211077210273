import React from 'react';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { COLORS } from '../../helpers/constants/styleguide';

import { FlexRow } from '../Flex';

export default class DotsPagination extends React.Component {
  render() {
    const { total, activeIndex, onActiveIndexChange, justifyContent } =
      this.props;

    return (
      <FlexRow style={{ justifyContent }}>
        {new Array(total).fill(undefined).map((elem, idx) => (
          <Dot
            key={idx}
            className={idx === activeIndex ? 'active' : ''}
            onClick={() => {
              if (!isNil(onActiveIndexChange)) {
                onActiveIndexChange(idx);
              }
            }}
          />
        ))}
      </FlexRow>
    );
  }
}

DotsPagination.propTypes = {
  total: PropTypes.number,
  activeIndex: PropTypes.number,
  onActiveIndexChange: PropTypes.func,
  justifyContent: PropTypes.oneOf([
    'center',
    'start',
    'end',
    'flex',
    'flex',
    'left',
    'right',
  ]),
};

const Dot = styled.div`
  background-color: ${COLORS.grey};
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 4px;
  cursor: pointer;

  &.active {
    background-color: ${COLORS.azure};
    width: 12px;
    height: 12px;
    margin: 2px;
  }
`;
