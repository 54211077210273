import React, { Component } from 'react';

import { RadioButtons } from '@summer/ui-components';

import {
  Question,
  Label,
  LabelContainer,
} from 'components/pages/idr/wizard/shared/styles';
import InfoTooltip from 'components/shared/InfoTooltip';
import { validateString } from 'utils/validate';

class SpouseHaveFsl extends Component {
  componentWillUnmount() {
    this.props.formik.setFieldValue(`tellUsAboutYou.spouseHasFsl`, '');
  }

  render() {
    const {
      formik: {
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        isSubmitting,
      },
    } = this.props;
    return (
      <div>
        <Question id="spouseHasFsl">
          <LabelContainer>
            <Label>Does your spouse have federal student loans?</Label>
            <InfoTooltip
              size={14}
              top={4}
              text="For some income-driven repayment plans, your spouses federal loans are taken into account."
            />
          </LabelContainer>
          <RadioButtons
            row
            design="smallRec"
            name="tellUsAboutYou.spouseHasFsl"
            touched={touched.spouseHasFsl}
            isSubmitting={isSubmitting}
            error={errors.spouseHasFsl}
            value={values.spouseHasFsl}
            onChange={handleChange}
            onBlur={handleBlur}
            options={[
              { value: 'true', label: 'Yes' },
              { value: 'false', label: 'No' },
            ]}
            validate={validateString()}
          />
        </Question>
      </div>
    );
  }
}

export default SpouseHaveFsl;
