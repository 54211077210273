import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { pslfNetworkClear } from 'redux/actions/pslf.actions';
import { checkPslfNetworkSuccess } from 'redux/selectors/pslf.selectors';

// This hook can be used to call a function after a
// network request has been completed successfully.
export const useOnSuccess = (requestLabel, onSuccess) => {
  const dispatch = useDispatch();
  const networkSuccess = useSelector(state =>
    checkPslfNetworkSuccess(state, requestLabel),
  );
  useEffect(() => {
    if (networkSuccess) {
      dispatch(pslfNetworkClear({ meta: { label: requestLabel } }));
      onSuccess();
    }
  }, [networkSuccess, requestLabel, dispatch, onSuccess]);
};
