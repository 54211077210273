import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { map, values } from 'lodash';
import PropTypes from 'prop-types';

import Text from '../typography/Text';
import Icon, { Caret } from '../Icon';

import { FlexRow } from '../Flex';

import { COLORS, MobileSize } from '../../helpers/constants/styleguide';

const height = 48;
const width = 224;
const yPadding = 12;
const xPadding = 16;

export const SelectOption = ({
  label,
  handleClick,
  textColor,
  icon,
  iconColor,
}) => {
  return (
    <OptionContainer
      tabIndex={0}
      onClick={handleClick}
      onKeyPress={handleClick}
      iconColor={iconColor}
    >
      {icon && (
        <Icon
          SvgComponent={icon}
          fill={iconColor}
          width={22}
          height={22}
          style={{ marginRight: '8px', cursor: 'pointer' }}
        />
      )}
      <Text as="label" bold color={textColor}>
        {label}
      </Text>
    </OptionContainer>
  );
};

const SelectButton = ({ label, selectOptions }) => {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  useEffect(() => {
    // Use the effect to close the dropdown if user clicks outside.
    const handleClickOutside = e => {
      if (ref.current.contains(e.target)) {
        return;
      }
      if (isOpen) {
        toggleDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleClickOutside);
    };
  }, [isOpen, toggleDropdown]);

  return (
    <Container ref={ref}>
      <ButtonContainer className={isOpen ? 'open' : ''}>
        <InnerContainer>
          <Text as="label" bold left>
            {label}
          </Text>
          <Arrow SvgComponent={Caret} fill={COLORS.darkerGrey} />
        </InnerContainer>
      </ButtonContainer>
      <ShadowContainer
        onClick={toggleDropdown}
        onKeyDown={toggleDropdown}
        className={isOpen ? 'open' : ''}
        tabIndex={0}
      />
      {isOpen && (
        <DropdownContainer>
          {map(
            selectOptions,
            ({ label, handleClick, textColor, icon, iconColor }) => (
              <SelectOption
                key={label}
                label={label}
                textColor={textColor}
                icon={icon}
                iconColor={iconColor}
                handleClick={() => {
                  toggleDropdown();
                  if (handleClick) {
                    handleClick();
                  }
                }}
              />
            ),
          )}
        </DropdownContainer>
      )}
    </Container>
  );
};

SelectOption.propTypes = {
  textColor: PropTypes.oneOf([
    'default',
    'secondary',
    'summer',
    'error',
    'light',
    'dark',
  ]),
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  icon: PropTypes.object,
  iconColor: PropTypes.oneOf(values(COLORS)),
};

SelectOption.defaultProps = {
  textColor: 'default',
  iconColor: COLORS.darkerGrey,
};

SelectButton.propTypes = {
  label: PropTypes.string.isRequired,
  selectOptions: PropTypes.arrayOf(PropTypes.shape(SelectOption.propTypes))
    .isRequired,
};

const boxShadow = '0 0 10px 0 rgba(65, 74, 88, 0.32)';

const Container = styled.div`
  position: relative;
  width: ${width}px;
  height: ${height}px;
  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

/* ButtonContainer and ShadowContainer are two separate components because we need to have box
 *  shadows on both the button and the dropdown without the shadow from one appearing over the
 *  other.
 * ShadowContainer handles the shadow and click functionality and ButtonContainer handles the text
 *  and background.
 * ButtonContainer has a higher z-index than the dropdown and sits above its shadow.
 */
const ShadowContainer = styled.div`
  width: ${width}px;
  height: ${height}px;
  position: absolute;
  border-radius: 3px;
  top: 0;
  left: 0;
  cursor: pointer;
  &:hover,
  &.open {
    box-shadow: ${boxShadow};
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2.5px ${COLORS.white}, 0px 0px 0px 4px ${COLORS.azure};
  }
  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  width: ${width}px;
  height: ${height}px;
  position: absolute;
  top: 0;
  left: 0;
  padding: ${yPadding}px ${xPadding}px;
  border-radius: 3px;
  border: solid 1px ${COLORS.grey};
  background-color: ${COLORS.white};
  pointer-events: none;
  &.open {
    border-radius: 3px 3px 0 0;
    z-index: 2;
  }
  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

const InnerContainer = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  & label {
    user-select: none;
    cursor: pointer;
  }
  @media (max-width: ${MobileSize}) {
    justify-content: center;
  }
`;

const Arrow = styled(Icon)`
  cursor: pointer;
  padding-left: 12px;
`;

const DropdownContainer = styled.div`
  position: absolute;
  top: ${height}px;
  left: 0;
  z-index: 1;
  width: ${width}px;
  border-radius: 0 0 3px 3px;
  border-left: solid 1px ${COLORS.grey};
  border-right: solid 1px ${COLORS.grey};
  border-bottom: solid 1px ${COLORS.grey};
  box-shadow: ${boxShadow};
  background-color: ${COLORS.white};
  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

const OptionContainer = styled(FlexRow)`
  padding: ${yPadding}px ${xPadding}px;
  cursor: pointer;
  background-color: ${COLORS.white};
  align-items: center;

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus-visible {
    background-color: ${COLORS.darkerGrey};
    outline: none;
    label {
      color: ${COLORS.white};
    }
    path,
    g {
      fill: ${COLORS.white} !important;
    }
  }
  & label {
    user-select: none;
    cursor: pointer;
  }
  &:last-child {
    border-radius: 0 0 3px 3px;
  }
  @media (max-width: ${MobileSize}) {
    justify-content: center;
  }
`;

export default SelectButton;
