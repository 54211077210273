import { createSelector } from 'reselect';

export const getOnboardingCurrentStep = state => state.onboarding.currentStep;

export const getOnboardingBadEmail = state => state.onboarding.badEmail;

export const getUserHasCompletedOnboarding = createSelector(
  state => state.profile,
  state => state.currentUser,
  (profile, currentUser) => {
    return !!(
      profile.state &&
      currentUser.creditScore &&
      currentUser.isPublicSector &&
      currentUser.familyCount !== null &&
      currentUser.salary !== null &&
      currentUser.federalLoansEstimate !== null &&
      currentUser.privateLoansEstimate !== null
    );
  },
);

export const getPostOnboardingDestination = state =>
  state.onboarding.postOnboardingDestination;
