import React from 'react';

import { Alert, Strong } from '@summer/ui-components';

const ConsolidateAlert = ({ shouldShowCTA }) => {
  const callToAction = shouldShowCTA
    ? {
        label: 'Explore Consolidation/IDR',
        buttonWidth: 240,
        fullWidthMobileButton: true,
        link: '/repayment',
      }
    : null;

  const content = shouldShowCTA ? (
    <>
      <Strong>Consolidate your loans to maximize forgiveness</Strong>
      <br />
      In order for past payments on FFEL and Perkins loans to be eligible for
      PSLF under the Limited Waiver and the IDR Adjustment, they will need to be
      consolidated. You can use our income-driven repayment (IDR) tool to
      consolidate these loans.
    </>
  ) : (
    <>
      <Strong>These loans need to be consolidated</Strong>
      <br />
      In order for past payments on these loans to be eligible for PSLF under
      the Limited Waiver and the IDR Adjustment, they will need to be
      consolidated. We&rsquo;ll help you do this next.
    </>
  );
  return (
    <Alert dismissable={false} theme="caution" callToAction={callToAction}>
      {content}
    </Alert>
  );
};
export default ConsolidateAlert;
