import React from 'react';
import styled from 'styled-components';

import {
  Text,
  SvgImage,
  COLORS,
  LockNew as LockSvg,
} from '@summer/ui-components';

import {
  ContentBox,
  IconContainer,
} from 'components/common/SyncLoans/ConnectFsa/shared';
import { Link } from 'components/shared/typography';
import {
  PRIVACY_POLICY_LINK,
  TERMS_OF_USE_LINK,
} from 'constants/externalLinks/supportLinks';
import { MobileSize } from 'constants/styleguide';

const SecurityInfo = ({ hideTermsOfUse, pageHasFooter }) => (
  <SecurityInfoBackground>
    <SecurityBox pageHasFooter={pageHasFooter}>
      <IconContainer>
        <SvgImage SvgComponent={LockSvg} />
        <LockText>
          <Text bold color="secondary">
            Your security is our priority. Your information is secure, and your
            data is SSL/TLS encrypted.
          </Text>
        </LockText>
      </IconContainer>
      {!hideTermsOfUse && (
        <PolicyContainer>
          <Text small color="secondary">
            Please see our{' '}
            <Link track inheritColor underline href={TERMS_OF_USE_LINK}>
              Terms of Use
            </Link>{' '}
            and{' '}
            <Link track inheritColor underline href={PRIVACY_POLICY_LINK}>
              Privacy Policy
            </Link>{' '}
            for more information.
          </Text>
        </PolicyContainer>
      )}
    </SecurityBox>
  </SecurityInfoBackground>
);

const SecurityInfoBackground = styled.div`
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: ${COLORS.lighterGrey};

  @media (max-width: ${MobileSize}) {
    background-color: none;
  }
`;

const SecurityBox = styled(ContentBox)`
  text-align: center;
  max-width: 840px;
  margin: 0 auto;
  padding-top: 24px;
  padding-bottom: 32px;

  @media (max-width: ${MobileSize}) {
    padding: 16px 24px ${({ pageHasFooter }) => (pageHasFooter ? '' : ' 48px')};
  }
`;

const LockText = styled.div`
  padding-left: 12px;
`;

const PolicyContainer = styled.div`
  margin-top: 8px;

  @media (max-width: ${MobileSize}) {
    text-align: left;
    padding-left: 46px;
  }
`;

export default SecurityInfo;
