import React, { Component } from 'react';

import { RadioButtons } from '@summer/ui-components';

import { Question, Label } from 'components/pages/idr/wizard/shared/styles';
import { validateString } from 'utils/validate';

class SpouseIncomeChange extends Component {
  constructor(props) {
    super(props);

    this.choiceOptions = this.props.limitChoices
      ? [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ]
      : [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
          {
            value: 'not-filed',
            label: 'Spouse hasn\u2019t filed taxes in the last 2 years',
          },
        ];
  }

  componentWillUnmount() {
    this.props.formik.setFieldValue(`tellUsAboutYou.spouseIncomeChange`, '');
  }

  render() {
    const {
      formik: {
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        isSubmitting,
      },
    } = this.props;
    return (
      <div>
        <Question id="spouseIncomeChange">
          <Label>
            Has your spouse&rsquo;s income significantly changed since your
            spouse filed his or her last federal income tax return?
          </Label>
          <RadioButtons
            row
            design="smallRec"
            name="tellUsAboutYou.spouseIncomeChange"
            touched={touched.spouseIncomeChange}
            isSubmitting={isSubmitting}
            error={errors.spouseIncomeChange}
            value={values.spouseIncomeChange}
            onChange={handleChange}
            onBlur={handleBlur}
            options={this.choiceOptions}
            validate={validateString()}
          />
        </Question>
      </div>
    );
  }
}

export default SpouseIncomeChange;
