import React from 'react';
import styled from 'styled-components';

import { PLAID_LINK_CUSTOMIZATION_NAMES } from '@simplifidev/shared/dist/constants/plaidLink';

import {
  FlexColumn,
  FlexCenter,
  Header,
  TabGroup,
  Text,
  Strong,
  Button,
  COLORS,
} from '@summer/ui-components';

import Documents from 'components/pages/employerContribution/dashboard/Documents';
import HelpFooter from 'components/pages/employerContribution/dashboard/helpFooter';
import SlcLandingPage from 'components/pages/employerContribution/shared/slcLandingPage';
import { MobileSize, SmallDesktopSize } from 'constants/styleguide';
import { usePlaid } from 'hooks/plaid';
import { usePlatform } from 'hooks/platform';
import { createSlcUser } from 'redux/actions/slc.actions';
import { hexToRgba } from 'utils/common';

const subheaderCopy =
  'Reach financial freedom faster with student loan contributions';

const SlcStartPage = () => {
  const { open, isLoading } = usePlaid({
    linkCustomizationName: PLAID_LINK_CUSTOMIZATION_NAMES.loanSync,
    successAction: createSlcUser({}),
  });

  return <CommonStartPage handleContinue={open} isLoading={isLoading} />;
};

const CommonStartPage = ({ handleContinue, isLoading }) => {
  const { isMobile } = usePlatform();

  const headerCopy = 'Student Loan Contribution';

  return (
    <SlcLandingPage
      headerCopy={headerCopy}
      subheaderCopy={isMobile ? subheaderCopy : ''}
    >
      <TabGroupContainer>
        <TabGroup
          baseUrl="/employer-contribution"
          tabs={[
            {
              title: 'Documents',
              url: '/documents',
              content: <Documents isLoading={isLoading} />,
            },
            {
              title: 'Servicers',
              content: (
                <Servicers
                  handleContinue={handleContinue}
                  isLoading={isLoading}
                  isMobile={isMobile}
                />
              ),
            },
          ]}
        />
      </TabGroupContainer>
      <HelpFooter />
    </SlcLandingPage>
  );
};

const Servicers = ({ handleContinue, isLoading, isMobile }) => {
  return (
    <Card>
      <TopContainer>
        <ImageContainer>
          <img
            src="/images/employerContribution/contribution-start.png"
            alt="SLC Start"
            width="100%"
          />
        </ImageContainer>
        <FlexColumn>
          {!isMobile && (
            <Header h2 align="left">
              {subheaderCopy}
            </Header>
          )}
          <OrderedList>
            <Text paragraph as="li" align="left">
              <Oval>
                <Strong>1</Strong>
              </Oval>
              <FlexColumn>
                <Strong>Connect your loans</Strong> Sync with your student loan
                servicer in just minutes
              </FlexColumn>
            </Text>
            <Text paragraph as="li" align="left">
              <Oval>
                <Strong>2</Strong>
              </Oval>
              <FlexColumn>
                <Strong>Get enrolled</Strong>
                Continue making your student loan payments as usual
              </FlexColumn>
            </Text>
            <Text paragraph as="li" align="left">
              <Oval>
                <Strong>3</Strong>
              </Oval>
              <FlexColumn>
                <Strong>Enjoy your benefit</Strong> Start receiving extra
                payments from your employer toward your student debt - at no
                cost to you
              </FlexColumn>
            </Text>
          </OrderedList>
        </FlexColumn>
      </TopContainer>
      <Text align="left" paragraph>
        By connecting your loan, you are attesting that you are connecting a
        student loan that was taken out under your own name. Your employer may
        request additional verification, and you hereby authorize Summer to
        share information for this purpose.
      </Text>
      <ContinueButton
        width={204}
        onClick={handleContinue}
        isLoading={isLoading}
      >
        Connect my servicers
      </ContinueButton>
    </Card>
  );
};

const TabGroupContainer = styled.div`
  position: relative;
  z-index: 10;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const Card = styled.div`
  position: relative;
  z-index: 10;

  display: flex;
  flex-direction: column;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 16px;

  background-color: ${COLORS.white};

  @media (max-width: ${SmallDesktopSize}) {
    align-items: center;
  }

  @media (max-width: ${MobileSize}) {
    padding: 24px;
  }
`;

const TopContainer = styled(FlexColumn)`
  margin-bottom: 16px;

  flex-direction: row;

  @media (max-width: ${SmallDesktopSize}) {
    flex-direction: column;
    align-items: center;
  }
`;

const ImageContainer = styled.div`
  max-width: 320px;
  margin-right: 40px;

  @media (max-width: ${SmallDesktopSize}) {
    margin-bottom: 24px;
  }

  @media (max-width: ${MobileSize}) {
    max-width: 240px;
    margin-right: 0;
  }
`;

const OrderedList = styled.ol`
  margin-bottom: 24px;
  margin-top: 24px;
  list-style-type: none;

  & > * {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  & .icon {
    margin-right: 16px;
  }

  @media (max-width: ${MobileSize}) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const Oval = styled(FlexCenter)`
  border-radius: 50%;
  width: 32px;
  min-width: 32px;
  height: 32px;
  margin-right: 12px;
  text-align: center;

  color: ${hexToRgba(COLORS.darkGreen, 0.5)};
  background-color: ${hexToRgba(COLORS.darkGreen, 0.06)};
`;

const ContinueButton = styled(Button)`
  margin-top: 16px;
  align-self: flex-end;
`;

export default SlcStartPage;
