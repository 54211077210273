import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  getIdrIsCurrent,
  getPslfForgivenessDate,
  getIdrIsPending,
  getPslfIsPending,
  getIdrRecommendation,
  getPslfRecommendation,
  getRefiRecommendation,
  getOverpaymentsRecommendation,
  getGetOutOfDefaultRecommendation,
  getForgivenessFinderRecommendation,
} from 'redux/selectors/recEngine.selectors';

import { getIsSaveEnabled } from '../redux/selectors/flags.selectors';

export const useMyPlanRecommendations = () => {
  const idrIsCurrent = useSelector(getIdrIsCurrent);
  const pslfIsCurrent = useSelector(getPslfForgivenessDate);
  const idrIsPending = useSelector(getIdrIsPending);
  const pslfIsPending = useSelector(getPslfIsPending);
  const idrRecommendation = useSelector(getIdrRecommendation);
  const pslfRecommendation = useSelector(getPslfRecommendation);
  const refiRecommendation = useSelector(getRefiRecommendation);
  const overpaymentsRecommendation = useSelector(getOverpaymentsRecommendation);
  const showSummerSave = useSelector(getIsSaveEnabled);
  const getOutOfDefaultRecommendation = useSelector(
    getGetOutOfDefaultRecommendation,
  );
  const forgivenessFinderRecommendation = useSelector(
    getForgivenessFinderRecommendation,
  );

  // memoize to only update recommendation when a selector changes
  return useMemo(() => {
    const idrCurrent = idrIsCurrent ? ['idr'] : [];
    const pslfCurrent = pslfIsCurrent && showSummerSave ? ['pslf'] : [];

    const idrPending = idrIsPending && showSummerSave ? ['idr'] : [];
    const pslfPending = pslfIsPending && showSummerSave ? ['pslf'] : [];

    const idrRecommended =
      idrRecommendation && !(idrIsPending || idrIsCurrent) && showSummerSave
        ? ['idr']
        : [];
    const pslfRecommended =
      pslfRecommendation && !pslfIsPending && showSummerSave ? ['pslf'] : [];
    const refiRecommended = refiRecommendation ? ['refi'] : [];
    const overpaymentRecommended = overpaymentsRecommendation
      ? ['overpayments']
      : [];
    const getOutOfDefaultRecommended = getOutOfDefaultRecommendation
      ? ['getOutOfDefault']
      : [];
    const forgivenessFinderRecommended = forgivenessFinderRecommendation
      ? ['forgivenessFinder']
      : [];

    return {
      current: [...idrCurrent, ...pslfCurrent],
      pending: [...idrPending, ...pslfPending],
      recommended: [
        ...idrRecommended,
        ...pslfRecommended,
        ...refiRecommended,
        ...overpaymentRecommended,
        ...getOutOfDefaultRecommended,
        ...forgivenessFinderRecommended,
      ],
    };
  }, [
    idrIsCurrent,
    pslfIsCurrent,
    idrIsPending,
    pslfIsPending,
    idrRecommendation,
    pslfRecommendation,
    refiRecommendation,
    overpaymentsRecommendation,
    getOutOfDefaultRecommendation,
    forgivenessFinderRecommendation,
    showSummerSave,
  ]);
};
