import React from 'react';
import styled from 'styled-components';

import { Text, COLORS } from '@summer/ui-components';

import WizardContent from 'components/layout/wizard/WizardContent';
import WizardFooter from 'components/layout/wizard/WizardFooter';
import { Link } from 'components/shared/typography';

import DefaultWizardContent from '../../layout/DefaultWizardContent';

import {
  DefaultOptionsContent,
  BoxContent as DefaultBoxContent,
} from './defaultOptionsContent';

const BoxContent = ({ onClick }) => {
  return (
    <>
      <BoxText>
        Since you weren&rsquo;t able to sync, we are not able to determine your
        best option.
      </BoxText>
      <SyncLink onClick={onClick}>Sync Now</SyncLink>
    </>
  );
};
const DefaultOptionsPage = ({ navigation, hasSyncedLoans }) => {
  return (
    <WizardContent backgroundColor={COLORS.white}>
      <DefaultWizardContent>
        <DefaultOptionsContent
          boxContent={
            hasSyncedLoans ? (
              <DefaultBoxContent />
            ) : (
              <BoxContent onClick={navigation.back} />
            )
          }
        />
      </DefaultWizardContent>
      <WizardFooter handleBack={navigation.back} isNextVisible={false} />
    </WizardContent>
  );
};

const BoxText = styled(Text)`
  margin-bottom: 16px;
`;

const SyncLink = styled(Link)`
  display: flex;
  justify-content: flex-end;
`;

export default DefaultOptionsPage;
