import { sumBy, findIndex } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { FlexRow, Header, Text } from '@summer/ui-components';

import NewLoansTable from 'components/common/ViewLoans/LoansTables/NewLoanTable';
import { Link } from 'components/shared/typography';
import { dollars } from 'utils/numbers';

export const Servicer = ({
  servicerName,
  servicerUrl,
  loans,
  tableAction,
  tableIndex,
  color,
  columnsToShow,
  tableName,
}) => {
  return (
    <ServicerContainer>
      <ServicerHeader>
        <Square color={color} />
        <Header h4>
          {servicerUrl ? (
            <Link href={servicerUrl} inheritColor>
              {servicerName}
            </Link>
          ) : (
            servicerName
          )}
        </Header>
      </ServicerHeader>

      <NewLoansTable
        loans={loans}
        onRowClick={tableAction}
        columnsToShow={columnsToShow}
        tableIndex={tableIndex}
        tableName={tableName}
      />
      <TotalBalance>
        <Text bold right>
          Total Current Balance:{' '}
          {findIndex(loans, { currentBalance: null }) === -1
            ? dollars(sumBy(loans, 'currentBalance'))
            : 'Unavailable'}
        </Text>
      </TotalBalance>
    </ServicerContainer>
  );
};
const Square = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 16px;
  background-color: ${({ color }) => color};
`;
const ServicerContainer = styled.div`
  align-self: flex-start;
  margin-bottom: 32px;
  width: 100%;

  & > .card-table {
    // account for padding in CardTable that leaves space for row box shadow on hover
    margin: 0 -10px;
    width: auto;
  }
`;
const ServicerHeader = styled(FlexRow)`
  margin-bottom: 16px;
`;

const TotalBalance = styled(FlexRow)`
  justify-content: flex-end;
  margin-top: 16px;
  margin-right: 16px;
`;
