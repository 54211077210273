import React, { useState } from 'react';
import { connect } from 'react-redux';

import { SmsFactorStatuses } from 'constants/mfa';
import { getSmsFactorStatus } from 'redux/selectors/smsFactor.selectors';

import CreateSmsFactor from './CreateSmsFactor';
import SignInAnimation from './SignInAnimation';
import VerifySmsFactor from './VerifySmsFactor';

const MfaSmsRoot = ({ status }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  if (isVerified) {
    return <SignInAnimation />;
  }

  if (status === null || isEditing) {
    return <CreateSmsFactor setIsEditing={setIsEditing} />;
  }

  if (status === SmsFactorStatuses.PENDING) {
    return (
      <VerifySmsFactor
        setIsVerified={setIsVerified}
        setIsEditing={setIsEditing}
      />
    );
  }

  if (status === SmsFactorStatuses.ACTIVE) {
    return <VerifySmsFactor setIsVerified={setIsVerified} />;
  }

  return null;
};

const mapStateToProps = state => ({
  status: getSmsFactorStatus(state),
});

export default connect(mapStateToProps)(MfaSmsRoot);
