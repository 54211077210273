import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Header, Text, TrashNew, Icon, COLORS } from '@summer/ui-components';

import ModalPopup from 'components/shared/ModalPopup';
import Link from 'components/shared/typography/Link';
import { MobileSize } from 'constants/styleguide';
import { fetchLoans } from 'redux/actions/loans.actions';
import { deleteSync } from 'redux/actions/sync.actions';

class DisableSync extends React.Component {
  onClick = () => {
    this.props.deleteSync(fetchLoans);
  };

  render() {
    const { togglePopup, isPopupOpen } = this.props;

    return (
      <ModalPopup
        {...this.props}
        togglePopup={togglePopup}
        isOpen={isPopupOpen}
        kind="Delete synced loans"
        fullHeight="small"
        width={700}
        bodyPadding="72px 32px 40px"
        closeSize={20}
        component={
          <>
            <HeaderContainer>
              <Header center>
                Are you sure you want to delete your loan details?
              </Header>
            </HeaderContainer>
            <TextContainer>
              <Text paragraph large>
                This will delete all loans associated with this Federal Student
                Aid account. You can always connect these loans again later if
                you wish.
              </Text>
            </TextContainer>
            <DeleteContainer>
              <Link inheritColor onClick={this.onClick}>
                <Text color="error">
                  <DeleteText>
                    <TrashIcon
                      SvgComponent={TrashNew}
                      width={16}
                      height={16}
                      fill={COLORS.orange}
                    />{' '}
                    Yes, delete these loans
                  </DeleteText>
                </Text>
              </Link>
            </DeleteContainer>
          </>
        }
      />
    );
  }
}

const HeaderContainer = styled.div`
  padding: 0 104px;
  margin-bottom: 24px;

  @media (max-width: ${MobileSize}) {
    padding: 0;
  }
`;

const TextContainer = styled.div`
  margin-bottom: 32px;
`;

const DeleteContainer = styled.div`
  margin-top: 32px;
  margin-right: 16px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${MobileSize}) {
    margin-right: 0;
    justify-content: center;
  }
`;

const DeleteText = styled.div`
  display: flex;
`;

const TrashIcon = styled(Icon)`
  display: inline-flex;
  margin-right: 8px;
  align-self: center;
  cursor: pointer;
`;

export default connect(null, {
  deleteSync,
  fetchLoans,
})(DisableSync);
