import { join, trim } from 'lodash';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import {
  FlexColumn,
  FlexRow,
  Text,
  Strong,
  COLORS,
} from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import { MobileSize, TabletSize } from 'constants/styleguide';
import { yearsAndMonths } from 'utils/date';
import { dollars } from 'utils/numbers';

const CurrentPaymentInfo = ({ platformType, values }) => {
  const fontSize = platformType !== MOBILE ? 'medium' : 'small';
  const {
    currentBalance,
    currentMonthlyPayment,
    currentTotalPayment,
    currentRemainingTerms,
  } = values;

  const { years, months } = useMemo(
    () => yearsAndMonths(currentRemainingTerms),
    [currentRemainingTerms],
  );

  const yearsPunctuation =
    years && months && platformType !== MOBILE ? ',' : '';

  return (
    <Container>
      <CurrentPaymentSummary>
        <Text paragraph size={fontSize}>
          With your current monthly loan payment of{' '}
          <Strong data-testid="currentMonthlyPayment">
            {dollars(currentMonthlyPayment)}
          </Strong>
          , you would pay
          {platformType !== MOBILE ? <br /> : ''} a total of{' '}
          <Strong data-testid="currentTotalPayment">
            {dollars(currentTotalPayment)}
          </Strong>
          {platformType !== MOBILE ? <br /> : ''} over{' '}
          <Strong data-testid="currentTotalTime">
            {trim(join([years, months], ' '))}
          </Strong>
          .
        </Text>
      </CurrentPaymentSummary>
      <TimeAndInterestContainer>
        <Timeframe>
          <FlexColumn>
            <TimeAndIntHeader>
              <Text paragraph color="secondary" size="small">
                <Strong>You&rsquo;ll be debt-free in</Strong>
              </Text>
            </TimeAndIntHeader>
            <Text paragraph>
              <Strong data-testid="currentPaymentYears">
                {years + yearsPunctuation}
              </Strong>
            </Text>
            <Text paragraph>
              <Strong data-testid="currentPaymentMonths">{months}</Strong>
            </Text>
          </FlexColumn>
        </Timeframe>
        <Divider />
        <InterestPayment>
          <FlexColumn>
            <TimeAndIntHeader>
              <Text paragraph color="secondary" size="small">
                <Strong>You&rsquo;ll pay</Strong>
              </Text>
            </TimeAndIntHeader>
            <Text paragraph>
              <Strong data-testid="currentTotalInterest">
                {dollars(currentTotalPayment - currentBalance)}
              </Strong>
            </Text>
            <Text paragraph>
              <Strong>in interest</Strong>
            </Text>
          </FlexColumn>
        </InterestPayment>
      </TimeAndInterestContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  border: solid 1px ${COLORS.lighterGrey};
  margin-bottom: 24px;

  @media (max-width: ${TabletSize}) {
    flex-direction: column;
    border: none;
    margin-bottom: 0;
  }
`;

const CurrentPaymentSummary = styled.div`
  text-align: center;
  max-width: 316px;
  background-color: ${COLORS.lighterGrey};
  padding: 24px 32px;

  @media (max-width: ${TabletSize}) {
    max-width: none;
    padding: 16px 24px;
    width: 100%;
  }
`;

const TimeAndInterestContainer = styled(FlexRow)`
  width: 100%;
  max-width: 356px;
  align-items: center;
  @media (max-width: ${TabletSize}) {
    max-width: none;
    width: 100%;
  }
`;

const TimeAndIntHeader = styled.div`
  margin-bottom: 4px;
`;

const Timeframe = styled.div`
  flex: 1;
  padding: 32px 16px;
  text-align: center;

  @media (max-width: ${MobileSize}) {
    padding: 32px 8px 32px 0px;
  }
`;

const Divider = styled.div`
  align-self: center;
  width: 1px;
  height: 96px;
  border: dashed 1px ${COLORS.lighterGrey};

  @media (max-width: ${MobileSize}) {
    height: 80px;
  }
`;

const InterestPayment = styled.div`
  flex: 1;
  padding: 32px 16px;
  text-align: center;

  @media (max-width: ${MobileSize}) {
    padding: 32px 0px;
  }
`;

export default Platform(React.memo(CurrentPaymentInfo));
