import React from 'react';
import styled from 'styled-components';

import { RadioButtons, COLORS } from '@summer/ui-components';

import InfoTooltip from 'components/shared/InfoTooltip';

class EducationLevel extends React.Component {
  componentWillUnmount() {
    if (this.props.canEdit) {
      this.props.formik.setFieldValue('educationLevel', null);
    }
  }

  render() {
    const { values, errors, handleChange, handleBlur, touched, isSubmitting } =
      this.props.formik;

    const tooltipText =
      'Select whether the loan was obtained for an undergraduate or graduate degree. If your loan was used for both an undergraduate and a graduate degree select "Graduate"';

    return (
      <Container id="educationLevel">
        <Label>
          <span>Degree</span>
          <InfoTooltip text={tooltipText} top={2} />
        </Label>
        <RadioButtons
          numberOfCol={2}
          design="shadowed"
          name="educationLevel"
          touched={touched.educationLevel}
          isSubmitting={isSubmitting}
          error={errors.educationLevel}
          value={values.educationLevel}
          onChange={handleChange}
          onBlur={handleBlur}
          options={[
            { value: 'undergraduate', label: 'Undergraduate' },
            { value: 'graduate', label: 'Graduate' },
          ]}
        />
      </Container>
    );
  }
}

const Label = styled.div`
  margin-bottom: 8px;
  transition: all 0.4 ease;
  font-weight: 600;
  font-size: 16px;
  color: ${COLORS.darkerGrey};
`;

const Container = styled.div`
  flex: 1;
`;

export default EducationLevel;
