import { pickBy } from 'lodash';
import React, { useMemo, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { FlexRow, Button, COLORS } from '@summer/ui-components';

import useScrollToErrorRow from 'components/common/ViewLoans/LoansTables/hooks/useScrollToErrorRow';
import { isRequiredFileMissing } from 'components/pages/idr/wizard/steps/Submit/sections/UploadDocuments';
import PrivacyPolicyLink from 'components/shared/PrivacyPolicyLink';
import { MobileSize } from 'constants/styleguide';
import {
  toggleIdrQuestionnaire,
  setQuestionnaireInitialStep,
} from 'redux/actions/ui.actions';
import { getIsSummerBasicEnabled } from 'redux/selectors/flags.selectors';
import {
  blockUserOnPlansStep,
  blockUserOnSubmitStep,
  getRequiredSupportingDocuments,
} from 'redux/selectors/idr.selectors';
import {
  getUserFederalLoans,
  getFederalLoanTypes,
  getRepaymentPlanTypes,
  userNeedsToRecertifyIdr,
  userRecertificationDateHasPassed,
  getCanEditField,
} from 'redux/selectors/loans.selectors';
import { shouldUsePlaidFlow } from 'redux/selectors/plaid.selectors';
import { getIsFsaLoanFileStale } from 'redux/selectors/sync.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { getIdrLoanSchema } from 'schemas/loans';
import { helpWidgetSize } from 'services/chat';
import { scrollToError as scrollToErrorElement } from 'utils/formik';
import { getNormalizedFsaLoans } from 'utils/loans';

const IDRFooter = ({
  handleStepBack,
  isStepBackValid,
  isCurrentStepValid,
  handleSubmit,
  isThisLastStep,
  userIsBlocked,
  resetSubmitCount,
  incrementSubmitCount,
  currentStepSubmitCount,
  idrIsLoading,
  disableSubmitOnYourLoans,
  currentStep,
  userFederalLoans,
  federalLoanTypes,
  repaymentPlanTypes,
  canEditField,
  location,
  errors,
  requiredSupportingDocuments,
  questionnaireNavigation,
  shouldUsePlaidFlow,
  showPrivacyPolicy,
}) => {
  const { pathname } = location;
  const dispatch = useDispatch();

  // We need loans here for the scrollToError functionality on the YourLoans table
  const normalizedLoans = useMemo(() => {
    return idrIsLoading
      ? []
      : getNormalizedFsaLoans(
          userFederalLoans,
          getIdrLoanSchema(federalLoanTypes, repaymentPlanTypes, canEditField),
        );
  }, [
    userFederalLoans,
    federalLoanTypes,
    repaymentPlanTypes,
    canEditField,
    idrIsLoading,
  ]);

  const scrollToErrorRow = useScrollToErrorRow(normalizedLoans, { idr: true });

  const animateOnError = useCallback(() => {
    if (pathname === '/idr/your-loans') {
      scrollToErrorRow();
    }

    if (pathname === '/idr/tell-us-about-you') {
      scrollToErrorElement(errors.tellUsAboutYou);
    }

    if (pathname === '/idr/submit/upload-documents') {
      scrollToErrorElement(
        pickBy(requiredSupportingDocuments, (fileList, collectionName) =>
          isRequiredFileMissing(requiredSupportingDocuments, collectionName),
        ),
      );
    }

    if (
      pathname === '/idr/submit/consolidate-loans' ||
      pathname === '/idr/submit/confirm-servicers'
    ) {
      scrollToErrorElement(errors);
    }
  }, [scrollToErrorRow, pathname, errors, requiredSupportingDocuments]);

  const hideSubmitButton =
    userIsBlocked ||
    disableSubmitOnYourLoans || // Your Loans
    currentStep === 3; // Review Your Plan

  const { numberOfSteps } = questionnaireNavigation;

  const handleBack = () => {
    resetSubmitCount();
    handleStepBack();
  };

  const handleNext = () => {
    if (!isCurrentStepValid) {
      animateOnError();
    }
    incrementSubmitCount();
    handleSubmit();
  };

  const handleNextWithPlaid = () => {
    if (currentStep === 2 && isCurrentStepValid) {
      dispatch(toggleIdrQuestionnaire(true));
      dispatch(setQuestionnaireInitialStep('1'));
    } else {
      handleNext();
    }
  };

  const handleBackWithPlaid = () => {
    if (currentStep === 3) {
      dispatch(toggleIdrQuestionnaire(true));
      dispatch(setQuestionnaireInitialStep(numberOfSteps.toString()));
    }
    handleBack();
  };

  return (
    <>
      {showPrivacyPolicy && <PrivacyPolicyIDRFooter />}
      <Footer>
        <Container>
          <Button
            secondary
            width={120}
            disabled={!isStepBackValid}
            isLoading={idrIsLoading}
            onClick={shouldUsePlaidFlow ? handleBackWithPlaid : handleBack}
          >
            Back
          </Button>
        </Container>
        <RightContainer>
          {!hideSubmitButton && (
            <Button
              width={176}
              type="submit"
              fakeDisabled={!isCurrentStepValid && currentStepSubmitCount > 0}
              isLoading={idrIsLoading}
              onClick={shouldUsePlaidFlow ? handleNextWithPlaid : handleNext}
            >
              {isThisLastStep ? 'Finish' : 'Save & Continue'}
            </Button>
          )}
        </RightContainer>
      </Footer>
    </>
  );
};

const PrivacyPolicyIDRFooter = styled(PrivacyPolicyLink)`
  position: relative;
  margin: 20px 30px 85px;

  @media (max-width: ${MobileSize}) {
    margin: 0px 30px 20px;
  }
`;

const Footer = styled(FlexRow)`
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 16px;

  // keeps help widget from overlapping right button
  padding-right: calc(${helpWidgetSize.desktop} + 16px);

  @media (max-width: ${MobileSize}) {
    position: unset;
    flex: 0 0 auto;
    // keeps help widget from overlapping right button
    padding-right: calc(${helpWidgetSize.mobile} + 16px);
  }

  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${COLORS.white};
    opacity: 0.8;
  }

  &:after {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
  }
`;

const Container = styled.div`
  & > button {
    height: 100%;
  }
`;

const RightContainer = styled(Container)`
  margin-left: 16px;
`;

const mapStateToProps = (state, { currentStep }) => ({
  idrIsLoading:
    isLoading(state, 'updateCurrentIdrForm') ||
    isLoading(state, 'fetchIdrPathOptions') ||
    isLoading(state, 'fetchLoans') ||
    isLoading(state, 'fetchRepaymentPlanTypes') ||
    isLoading(state, 'fetchLoanTypes') ||
    isLoading(state, 'fetchSignatureRequestListV3'),
  userIsBlocked:
    (currentStep === 3 && blockUserOnPlansStep(state)) ||
    (currentStep > 3 && blockUserOnSubmitStep(state)),
  disableSubmitOnYourLoans:
    currentStep === 2 &&
    !shouldUsePlaidFlow(state) && // do not block plaid users (recert info only comes from fsa)
    (userRecertificationDateHasPassed(state) ||
      userNeedsToRecertifyIdr(state) ||
      getIsFsaLoanFileStale(state)),
  federalLoanTypes: getFederalLoanTypes(state),
  userFederalLoans: getUserFederalLoans(state),
  repaymentPlanTypes: getRepaymentPlanTypes(state),
  canEditField: getCanEditField(state),
  requiredSupportingDocuments: getRequiredSupportingDocuments(state),
  shouldUsePlaidFlow: shouldUsePlaidFlow(state),
  showPrivacyPolicy: currentStep > 3 && !getIsSummerBasicEnabled(state),
});

export default withRouter(connect(mapStateToProps)(IDRFooter));
