import { sortBy, invert } from 'lodash';
import React, { useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Text,
  LoadingBars,
  COLORS,
  InfoTooltip,
} from '@summer/ui-components/src';

import { Link } from 'components/shared/typography';
import { estimatedAidSourceLink } from 'constants/externalLinks/collegeSavingsPlanner';
import { useClearFieldOnUnmount } from 'hooks/formik';
import {
  getAverageAidByMinIncome,
  getAverageTuitions,
} from 'redux/selectors/collegeSavingsPlanner.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { dollars } from 'utils/numbers';

import { GridContainer, MoneyInputCSP } from '../shared';

const useEstimateAidAmount = ({
  averageAidByMinIncome,
  averageTuitions,
  collegeCostMethod,
  householdAnnualIncome,
  yearlyTuition,
}) =>
  useMemo(() => {
    const incomeBracket = sortBy(averageAidByMinIncome, ['minIncome'])
      .reverse()
      .find(
        ({ minIncome }) =>
          typeof householdAnnualIncome === 'number' &&
          minIncome <= householdAnnualIncome,
      );

    const collegeType = invert(averageTuitions)[yearlyTuition];

    const aidAmountKey =
      (collegeCostMethod === 'Select college type' && collegeType) || 'all';

    return incomeBracket?.[aidAmountKey] || null;
  }, [
    averageAidByMinIncome,
    averageTuitions,
    collegeCostMethod,
    householdAnnualIncome,
    yearlyTuition,
  ]);

const EstimateByIncome = ({
  formik,
  isCalcDataLoading,
  averageAidByMinIncome,
  averageTuitions,
}) => {
  const { values, errors, setFieldValue } = formik;

  const {
    householdAnnualIncome,
    collegeCostMethod,
    expectedAid,
    yearlyTuition,
  } = values;

  const estimatedAidAmount = useEstimateAidAmount({
    averageAidByMinIncome,
    averageTuitions,
    collegeCostMethod,
    householdAnnualIncome,
    yearlyTuition,
  });

  useEffect(
    function setExpectedAidValue() {
      setFieldValue('expectedAid', estimatedAidAmount);
    },
    [estimatedAidAmount, setFieldValue],
  );

  useClearFieldOnUnmount('expectedAid', null, setFieldValue);

  return isCalcDataLoading ? (
    <LoadingBars color={COLORS.azure} />
  ) : (
    <GridContainer>
      <MoneyInputCSP
        name="householdAnnualIncome"
        label="Household Annual Income"
        formik={formik}
        error={errors.expectedAid}
      />
      <EstimatedAmount>
        <Text bold>
          Estimated Aid & Scholarship Amount{' '}
          <InfoTooltip
            text={
              <>
                Estimated financial aid and scholarship amount based on income
                and college type from{' '}
                <Link inheritColor underline href={estimatedAidSourceLink}>
                  National Center for Education Statistics
                </Link>
                .
              </>
            }
          />
        </Text>
        <Text large bold data-testid="estimated-aid-amount">
          $ {expectedAid && dollars(Number(expectedAid), { showSymbol: false })}
        </Text>
      </EstimatedAmount>
    </GridContainer>
  );
};

const EstimatedAmount = styled.div`
  margin-bottom: 25px;

  & > .text:first-child {
    margin-bottom: 8px;
  }

  & > .text:last-child {
    font-size: 24px;
    height: 48px;
    line-height: 48px;
  }
`;

const mapStateToProps = state => ({
  averageAidByMinIncome: getAverageAidByMinIncome(state),
  averageTuitions: getAverageTuitions(state),
  isCalcDataLoading: isLoading(state, 'fetchCSPCalculatorData'),
});

export default connect(mapStateToProps)(EstimateByIncome);
