import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  FlexCenter,
  FlexColumn,
  FlexRow,
  Button,
  Icon,
  SummerLogo,
  ErrorImage,
  SvgImage,
  Text,
  Header,
  COLORS,
} from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';
import { marketingSiteLink } from 'constants/externalLinks/marketingSite';
import { MobileSize } from 'constants/styleguide';
import { getHideSupportChat } from 'redux/selectors/user.selectors';
import { openChat } from 'services/chat';

class Error extends React.Component {
  render() {
    const { eventId, hideSupportChat } = this.props;

    return (
      <Container>
        <HeaderContainer>
          <a href={marketingSiteLink}>
            <Logo
              width={120}
              height={30}
              SvgComponent={SummerLogo}
              fill={COLORS.lightBlue}
            />
          </a>
        </HeaderContainer>
        <FlexCenter direction="column">
          <Body>
            <SvgImage SvgComponent={ErrorImage} width={182} height={177} />

            <Title>
              <Header h1 center>
                Sorry, we&rsquo;re having some technical issues
              </Header>
            </Title>

            <TextContainer>
              <Text large center>
                Please let us know and we&rsquo;ll get to the bottom of it right
                away.
              </Text>
              <Text>
                {!hideSupportChat && (
                  <>
                    <Link onClick={openChat}>Chat now</Link> or email
                  </>
                )}
                {hideSupportChat && 'Email'} us at{' '}
                <Link
                  href={`${`mailto:${supportAddress}`}?subject=Error Code:${eventId}`}
                >
                  {supportAddress}
                </Link>
              </Text>
            </TextContainer>
            <Button
              onClick={() => {
                window.location = '/';
              }}
            >
              Back to the app
            </Button>
            {eventId && (
              <Text style={{ fontSize: '10px' }} color="secondary" small>
                <br />
                Error Code: {eventId}
              </Text>
            )}
          </Body>
        </FlexCenter>
      </Container>
    );
  }
}

const Container = styled(FlexColumn)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Body = styled.div`
  max-width: 386px;
  width: 100%;
  margin-top: 60px;
  overflow: auto;
  text-align: center;

  @media (max-width: ${MobileSize}) {
    padding: 0 16px;
    margin-top: 0;
  }
`;

const HeaderContainer = styled(FlexRow)`
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  height: 80px;
  align-items: center;
  & > * {
    width: 200px; // to center items
  }
`;

const Logo = styled(Icon)`
  width: 120px;
  height: 30px;
`;

const Title = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
`;

const TextContainer = styled.div`
  & > * {
    margin-bottom: 24px;
  }
`;

const mapStateToProps = state => ({
  hideSupportChat: getHideSupportChat(state),
});

export default connect(mapStateToProps)(Error);
