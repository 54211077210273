import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const actionTypes = {
  init: 'init',
  setCurrentStep: 'setCurrentStep',
  setPreviousStep: 'setPreviousStep',
  setOverallProgress: 'setOverallProgress',
  setStepProgress: 'setStepProgress',
};

const defaultInitialState = {
  currentStep: 1,
  previousStep: null,
  progressByStep: [],
  overallProgress: 0,
};

const findIndex = (array, element) => {
  return array.findIndex(item => item === element);
};

export const useWizard = (props, ...plugins) => {
  // Destructure props
  const {
    config: userSteps,
    parentNavigation,
    parentProgress,
    initialPath: userInitialPath,
  } = props;

  // The Wizard instance
  const instanceRef = React.useRef({});
  const getInstance = () => instanceRef.current;

  Object.assign(getInstance(), {
    ...props,
    plugins,
    hooks: {
      useCurrentStep: [],
    },
  });

  plugins.forEach(plugin => plugin(getInstance().hooks));

  // The Wizard Reducer
  const reducer = useCallback((state, action) => {
    if (action.type === actionTypes.init) {
      return defaultInitialState;
    }
    if (action.type === actionTypes.setCurrentStep) {
      return { ...state, currentStep: action.payload };
    }
    if (action.type === actionTypes.setPreviousStep) {
      return { ...state, previousStep: action.payload };
    }
    if (action.type === actionTypes.setOverallProgress) {
      return { ...state, overallProgress: action.payload };
    }
    if (action.type === actionTypes.setStepProgress) {
      return { ...state, progressByStep: action.payload };
    }
    return state;
  }, []);

  const [state, dispatch] = React.useReducer(reducer, undefined, () =>
    reducer({}, { type: actionTypes.init }),
  );

  Object.assign(getInstance(), {
    state,
    dispatch,
  });

  // Build Wizard Navigation
  const level = React.useMemo(() => {
    if (!parentNavigation) {
      return 0;
    }
    return parentNavigation.level + 1;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const flow = React.useMemo(() => {
    return userSteps.reduce((flow, step, index) => {
      if (step.condition !== false) {
        flow.push(index + 1);
      }
      return flow;
    }, []);
  }, [userSteps]);

  const [next, back, isLastStep] = React.useMemo(() => {
    const currentStepIndex = findIndex(flow, state.currentStep);
    const isLastStep = flow.length === currentStepIndex + 1;
    const { next: nextParent, back: backParent } = parentNavigation || {};

    const next = () => {
      const nextStep = flow[currentStepIndex + 1];
      if (nextStep) {
        dispatch({
          type: actionTypes.setPreviousStep,
          payload: state.currentStep,
        });
        dispatch({
          type: actionTypes.setCurrentStep,
          payload: nextStep,
        });
      } else if (typeof nextParent === 'function') {
        nextParent();
      }
    };

    const back = (callback = null) => {
      const nextStep = flow[currentStepIndex - 1];
      if (nextStep) {
        dispatch({
          type: actionTypes.setPreviousStep,
          payload: state.currentStep,
        });
        dispatch({
          type: actionTypes.setCurrentStep,
          payload: nextStep,
        });
        if (typeof callback === 'function') {
          callback(nextStep);
        }
      } else if (typeof backParent === 'function') {
        backParent(callback);
      }
    };

    return [next, back, isLastStep];
  }, [state.currentStep, flow, parentNavigation]);

  const path = React.useRef([]);
  React.useMemo(() => {
    if (!parentNavigation) {
      path.current = [state.currentStep];
    } else {
      parentNavigation.path.current[level] = state.currentStep;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentStep]);

  const moveToStep = React.useCallback(
    step => {
      const isStepInflow = findIndex(flow, step) >= 0;

      if (isStepInflow) {
        dispatch({
          type: actionTypes.setPreviousStep,
          payload: step,
        });
        dispatch({
          type: actionTypes.setCurrentStep,
          payload: step,
        });
      }
    },
    [flow],
  );

  // set the flow initial step (run only one time)
  React.useMemo(() => {
    const { currentStep: parentCurrentStep, previousStep: parentPreviousStep } =
      parentNavigation || {};
    const initialPath =
      (parentNavigation && parentNavigation.userInitialPath) || userInitialPath;

    if (initialPath && !parentPreviousStep) {
      const initialPathArray = initialPath.split('.').map(Number);
      const initialStepIndex = findIndex(flow, initialPathArray[level]);

      if (initialStepIndex !== -1) {
        dispatch({
          type: actionTypes.setCurrentStep,
          payload: initialPathArray[level],
        });
      }

      return;
    }

    if (parentCurrentStep < parentPreviousStep) {
      dispatch({
        type: actionTypes.setCurrentStep,
        payload: flow[flow.length - 1],
      });
      return;
    }

    dispatch({
      type: actionTypes.setCurrentStep,
      payload: flow[0],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  Object.assign(getInstance(), {
    navigation: {
      next,
      back,
      isLastStep,
      currentStep: state.currentStep,
      previousStep: state.previousStep,
      numberOfSteps: flow.length,
      level,
      path: parentNavigation ? parentNavigation.path : path,
      userInitialPath,
      moveToStep,
    },
  });

  // Build Wizard Progress (V1 only work for one level nested)
  const updateProgressByStep = React.useCallback(
    percentage => {
      const currentStepIndex = findIndex(flow, state.currentStep);
      const progressByStep = [...state.progressByStep];
      progressByStep[currentStepIndex] = percentage;
      dispatch({ type: actionTypes.setStepProgress, payload: progressByStep });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.progressByStep, state.currentStep],
  );

  const updateProgress = React.useCallback(percentage => {
    dispatch({ type: actionTypes.setOverallProgress, payload: percentage });
  }, []);

  const progress = React.useMemo(
    () => ({
      updateProgressByStep,
      updateProgress,
      get overallProgress() {
        return state.overallProgress;
      },
      get progressByStep() {
        return state.progressByStep;
      },
    }),
    [
      state.progressByStep,
      state.overallProgress,
      updateProgressByStep,
      updateProgress,
    ],
  );

  React.useEffect(() => {
    if (parentProgress) {
      const currentStepIndex = findIndex(flow, state.currentStep);
      const percentage = (100 / flow.length) * currentStepIndex;

      dispatch({ type: actionTypes.setOverallProgress, payload: percentage });
      parentProgress.updateProgressByStep(percentage);
    } else {
      let percentage;
      const currentStepIndex = findIndex(flow, state.currentStep);
      const currentStepPercentage = progress.progressByStep[currentStepIndex];

      if (currentStepPercentage) {
        percentage =
          (100 * currentStepIndex + currentStepPercentage) / flow.length;
      } else {
        percentage = (100 * currentStepIndex) / flow.length;
      }

      updateProgress(percentage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentStep, state.progressByStep]);

  getInstance().progress = progress;

  // Build Wizard Headers
  const headers = React.useMemo(
    () =>
      userSteps.reduce((headers, { title, condition }, idx) => {
        if (condition !== false) {
          headers.push({ title, step: idx + 1 });
        }
        return headers;
      }, []),
    [userSteps],
  );
  getInstance().headers = headers;

  // Build Wizard Steps
  const setStepProps = React.useCallback(
    index => {
      return {
        key: index,
        navigation: getInstance().navigation,
        progress: getInstance().progress,
      };
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [next, back, isLastStep],
  );

  const stepRender = React.useCallback(
    (userStep, index) => {
      const stepIndex = state.currentStep - 1;
      if (stepIndex === index) {
        if (Object.prototype.hasOwnProperty.call(userStep, 'render')) {
          return () => userStep.render({ ...setStepProps(index) });
        }
        if (Object.prototype.hasOwnProperty.call(userStep, 'component')) {
          const StepComponent = () => (
            <userStep.component {...setStepProps(index)} />
          );
          return StepComponent;
        }
      }
      return function userStep() {
        return null;
      };
    },
    [state.currentStep, setStepProps],
  );

  const steps = React.useMemo(() => {
    return userSteps.map((userStep, index) => {
      const step = {
        id: index,
        setStepProps,
        render: stepRender(userStep, index),
      };
      return step;
    });
  }, [userSteps, setStepProps, stepRender]);

  getInstance().steps = steps;

  const reduxDispatch = useDispatch();

  // Each time the current step changes, run all registered useCurrentStep hooks
  React.useEffect(() => {
    const { navigation } = getInstance();

    /**
     * This condition prevents event from being tracked in parent wizard(s) if
     * the change is in a nested wizard
     */
    if (navigation.path.current.length === level + 1) {
      getInstance().hooks.useCurrentStep.forEach(hook =>
        hook(state.currentStep, {
          instance: getInstance(),
          dispatch: reduxDispatch,
        }),
      );
    }
  }, [state.currentStep, level, reduxDispatch]);

  return getInstance();
};
