import { SET_PLAID_LOANS } from 'constants/actionTypes';

const initialState = [];

const plaidLoansReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PLAID_LOANS: {
      return payload;
    }

    default:
      return state;
  }
};

export default plaidLoansReducer;
