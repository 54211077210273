/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Container from 'components/layout/wizard/WizardContainer';
import PslfWizardHeader from 'components/pages/pslf/wizard/layout/PslfWizardHeader';
import SubmitWizard from 'components/pages/pslf/wizard/steps/submit';
import YourEmploymentWizard from 'components/pages/pslf/wizard/steps/yourEmployment';
import YourLoans from 'components/pages/pslf/wizard/steps/yourLoans';
import GlobalError from 'components/shared/GlobalError';
import useTrackStepChanges from 'hooks/Wizard/useTrackStepChanges';
import { useWizard } from 'hooks/Wizard/useWizard';
import { renderWizardSteps } from 'hooks/Wizard/utils';
import { getPslfCurrentStep } from 'redux/selectors/pslf.selectors';
import { getUserPartnerKey } from 'redux/selectors/user.selectors';

const PslfWizard = ({ currentStep }) => {
  const config = useMemo(
    () => [
      {
        title: 'Your Employment',
        render: props => (
          <YourEmploymentWizard {...props} parentProgress={props.progress} />
        ),
      },
      {
        title: 'Your Loan Eligibility',
        component: YourLoans,
      },
      {
        title: 'Submit Your Certification',
        render: props => (
          <SubmitWizard {...props} parentProgress={props.progress} />
        ),
      },
    ],
    [],
  );
  const { steps, headers, progress, navigation } = useWizard(
    {
      config,
      initialPath: currentStep,
    },
    useTrackStepChanges,
  );
  const handleHeaderClick = step => {
    navigation.moveToStep(step);
  };

  return (
    <Container>
      <PslfWizardHeader
        headers={headers}
        currentStep={navigation.currentStep}
        progress={progress.overallProgress}
        handleHeaderClick={handleHeaderClick}
      />
      <GlobalErrorContainer>
        <GlobalError label="updateCurrentPslfForm" />
      </GlobalErrorContainer>

      {renderWizardSteps(steps)}
    </Container>
  );
};

const GlobalErrorContainer = styled.div`
  margin: 0 auto;
`;

const mapStateToProps = state => ({
  currentStep: getPslfCurrentStep(state),
  userPartner: getUserPartnerKey(state),
});

export default connect(mapStateToProps)(PslfWizard);
