import React from 'react';

import { TextInput } from '@summer/ui-components';

import { InputWrapper } from 'components/pages/idr/wizard/steps/Submit/shared/Inputs';

const HrName = ({
  formik: { values, errors, handleBlur, handleChange, touched, isSubmitting },
}) => (
  <InputWrapper>
    <TextInput
      label="Employer Contact Email Address"
      name="hrEmail"
      touched={touched.hrEmail}
      isSubmitting={isSubmitting}
      error={errors.hrEmail}
      value={values.hrEmail || ''}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder="Email Address"
    />
  </InputWrapper>
);

export default HrName;
