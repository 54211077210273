import React from 'react';
import styled from 'styled-components';

import { FlexColumn, Header, Text, COLORS } from '@summer/ui-components';

import { hexToRgba } from 'utils/common';

export const getParamData = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return {
    firstName: queryParams.get('firstName'),
    lastName: queryParams.get('lastName'),
    employer: queryParams.get('employer'),
    recordKeeper: queryParams.get('recordkeeper'),
  };
};

export const RecordKeeperDetails = ({ recordKeeperTitle }) => {
  const params = getParamData();

  const recordKeeper = params.recordKeeper || recordKeeperTitle;

  return (
    <Container>
      <SlcHeader>
        <Header h2>Welcome, {params.firstName}!</Header>
        <Text small color="default">
          {`These details came through from ${
            recordKeeper || `[Recordkeeper Name]`
          } and your employer:`}
        </Text>
      </SlcHeader>
      <FlexColumn>
        <Header h5 color="default">
          Name
        </Header>
        <UserDetail medium color="secondary">
          {`${params.firstName} ${params.lastName}`}
        </UserDetail>
        <Header h5 color="default">
          Employer
        </Header>
        <Text medium color="secondary">
          {params.employer}
        </Text>
      </FlexColumn>
    </Container>
  );
};

const Container = styled(FlexColumn)`
  width: 100%;
  padding: 24px;
  margin-bottom: 15px;
  background-color: ${hexToRgba(COLORS.azure, '0.05')};
`;

const UserDetail = styled(Text)`
  margin-bottom: 16px;
`;

const SlcHeader = styled.div`
  h2 {
    margin-bottom: 8px;
  }

  margin-bottom: 16px;
`;
