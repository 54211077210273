import { get, keys, pick } from 'lodash';

import {
  ADD_EMERGENCY_SAVINGS_PAYROLL_PROVIDER,
  SET_EMERGENCY_SAVINGS,
} from 'constants/actionTypes';

const initialState = {
  housingExpense: null,
  utilityExpense: null,
  insuranceExpense: null,
  transportationExpense: null,
  debtExpense: null,
  groceryExpense: null,
  savingsTarget: null,
  savings: null,
  salary: null,
  savingsPerMonth: null,
  payrollProviders: [],
};

const emergencySavingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_EMERGENCY_SAVINGS: {
      return { ...state, ...pick(payload, keys(initialState)) };
    }

    case ADD_EMERGENCY_SAVINGS_PAYROLL_PROVIDER: {
      return {
        ...state,
        payrollProviders: [
          ...get(state, 'payrollProviders', initialState.payrollProviders),
          payload,
        ],
      };
    }

    default:
      return state;
  }
};

export default emergencySavingsReducer;
