import React from 'react';
import styled from 'styled-components';

import {
  Text,
  Toggle,
  FlexRow,
  FlexColumn,
  COLORS,
} from '@summer/ui-components';

import TextTooltip from 'components/shared/TextTooltip';
import { MobileSize } from 'constants/styleguide';

const PslfToggle = ({ onPSLFToggle, isPslf }) => (
  <ToggleContainer>
    <Toggle onChange={onPSLFToggle} checked={isPslf} />
    <ToggleTextContainer>
      <Text small center bold>
        {isPslf ? 'ON' : 'OFF'}
      </Text>
    </ToggleTextContainer>
  </ToggleContainer>
);

const Pslf = ({ pslf: { onPSLFToggle, isPslf } }) => {
  const toolTipCopy =
    'Turning this on shows how much forgiveness you might receive if you qualify for PSLF. Under the new PSLF waiver, you may be eligible for forgiveness sooner than we estimate. Use Summer\u2019s PSLF certification tool to assess your eligibility.';

  return (
    <Container>
      <TextContainer>
        <Text small bold>
          Include{' '}
          <TextTooltip lightUnderline kind="include PSLF" text={toolTipCopy}>
            Public Service Loan Forgiveness
          </TextTooltip>
        </Text>
      </TextContainer>
      <PslfToggle onPSLFToggle={onPSLFToggle} isPslf={isPslf} />
    </Container>
  );
};

const ToggleContainer = styled(FlexRow)`
  min-height: min-content;
`;

const Container = styled(FlexColumn)`
  min-height: min-content;
  height: 80px;
  width: 100%;
  justify-content: center;
  padding: 16px;
  border: 1px solid ${COLORS.grey};

  @media (max-width: ${MobileSize}) {
    height: 84px;
  }
`;

const TextContainer = styled.div`
  margin-bottom: 8px;
`;

const ToggleTextContainer = styled.div`
  width: 32px;
  padding: 4px;
`;

export default Pslf;
