import React from 'react';
import styled from 'styled-components';

import { MobileSize } from 'constants/styleguide';

const servicerImageRoute = {
  ins_116319: '/images/servicers/aes.png',
  ins_132249: '/images/servicers/aidvantage.png',
  ins_116304: '/images/servicers/edfinancial.png',
  ins_116527: '/images/servicers/fedloan.png',
  ins_116308: '/images/servicers/granitestate.png',
  ins_116861: '/images/servicers/greatlakes.png',
  ins_116866: '/images/privateServicers/Mohela-logo.png',
  ins_116248: '/images/servicers/navient.png',
  ins_116528: '/images/servicers/nelnet.png',
  ins_116945: '/images/privateServicers/OSLA-logo.png',

  ins_116950: '/images/privateServicers/Commonbond-logo.png',
  ins_33: '/images/privateServicers/Discover-logo.png',
  ins_126289: '/images/privateServicers/EducationLoanFinance-logo.png',
  ins_116295: '/images/privateServicers/Firstmark-logo.png',
  ins_116947: '/images/privateServicers/LaurelRoad-logo.png',
  ins_116944: '/images/privateServicers/SallieMae-logo.png',
  ins_116464: '/images/privateServicers/SoFi-logo.png',
  ins_4: '/images/privateServicers/WellsFargo-logo.png',
};

export const doesServicerHaveLogo = institutionId => {
  return Object.keys(servicerImageRoute).includes(institutionId);
};

const ServicerLogo = ({ institutionId, servicerName }) => {
  return doesServicerHaveLogo ? (
    <Logo src={servicerImageRoute[institutionId]} alt={servicerName} />
  ) : null;
};

const Logo = styled.img`
  max-width: 170px;
  max-height: 60px;

  @media (max-width: ${MobileSize}) {
    max-width: 50%;
    margin-right: 16px;
  }
`;

export default ServicerLogo;
