import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { LoadingBars, COLORS } from '@summer/ui-components';

import { fetchAllLoans } from 'redux/actions/loans.actions';
import { isLoadingWithInit } from 'redux/selectors/ui.selectors';

import LoansView from './LoansView';
import PlaidSyncPage from './SyncView/PlaidSyncPage';

const Loans = ({
  hasFederalLoans,
  isLoansLoading,
  isPlaidLoading,
  setFieldValue,
  openPlaid,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllLoans());
  }, [dispatch]);

  useEffect(() => {
    const isUserLoansValid = hasFederalLoans;

    setFieldValue('yourLoans.isValid', isUserLoansValid);
  }, [setFieldValue, hasFederalLoans]);

  const isLoading = isLoansLoading || isPlaidLoading;

  if (isLoading) {
    return <LoadingBars color={COLORS.azure} />;
  }

  return (
    <>
      {hasFederalLoans ? (
        <LoansView openPlaid={openPlaid} />
      ) : (
        <PlaidSyncPage openPlaid={openPlaid} />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  isLoansLoading: isLoadingWithInit(state, 'fetchAllLoans'),
});

export default connect(mapStateToProps)(Loans);
