import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { hexToRgba } from '../../helpers/utils/common';
import { FlexRow } from '../Flex';
import { MobileSize } from '../../helpers/constants/styleguide';

const AlertContainer = ({ children, dismissed, color, className, testId }) => (
  <TransitionGroup>
    {dismissed ? null : (
      <CSSTransition classNames="alert" timeout={{ enter: 750, exit: 750 }}>
        <Container
          dismissed={dismissed}
          color={color}
          className={className}
          data-testid={`alert-${testId}`}
        >
          {children}
        </Container>
      </CSSTransition>
    )}
  </TransitionGroup>
);

const fadeUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(25px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeDown = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(25px);
  }
`;

const fadeUpAnimation = css`
  ${fadeUp} 750ms ease-in-out;
`;

const fadeDownAnimation = css`
  ${fadeDown} 750ms ease-in-out;
`;

const Container = styled(FlexRow)`
  position: relative;
  width: 100%;
  padding: 12px 12px 12px 16px;
  min-height: 48px;
  border-radius: 3px;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ color }) => hexToRgba(color, '0.06')};
  animation: ${fadeUpAnimation};

  &.alert-exit {
    animation: ${fadeDownAnimation};
  }

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
    padding: 12px 16px 16px 16px;
  }
`;

export default AlertContainer;
