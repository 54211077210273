import _, {
  camelCase,
  forEach,
  isPlainObject,
  mapKeys,
  mapValues,
} from 'lodash';
import fp from 'lodash/fp';

import { camelWithPrefix } from 'utils/text';

// This function takes in an object and prefix, and returns a new object
// with keys in the format camelCase(prefix + oldKey)
export const addPrefixToKeys = (object, prefix) =>
  mapKeys(object, (value, key) => camelWithPrefix(prefix, key));

// This function takes in two objects and a string prefix
// It returns an object with the keys of the first object,
// mapped to the values of the second object where the key
// matches the format camelCase(prefix + key)
export const mapValuesWithPrefix = (keySource, valueSource, prefix) =>
  mapValues(
    keySource,
    (value, key) => valueSource[camelWithPrefix(prefix, key)],
  );

// This function takes in an object and prefix, and returns an array of
// strings in the format camelCase(prefix + objectKey)
export const getKeysWithPrefix = (object, prefix) =>
  _.flow(
    fp.keys,
    fp.map(fieldName => camelWithPrefix(prefix, fieldName)),
  )(object);

export const getFields = (values, prefix = '') =>
  mapKeys(values, (value, key) => camelWithPrefix(prefix, key));

// This function takes in a nested object and returns a new object with
// its keys recursively converted to camelCase.
export const objectKeysToCamelCase = object => {
  const camelCaseObject = {};

  forEach(object, (value, key) => {
    const newValue = isPlainObject(value)
      ? objectKeysToCamelCase(value)
      : value;

    camelCaseObject[camelCase(key)] = newValue;
  });

  return camelCaseObject;
};
