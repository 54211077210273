import { push as redirectTo } from 'connected-react-router';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { USStateDisplayNameMapping } from '@simplifidev/shared/dist/constants/states';

import { Button, Header, Text, FancyCard } from '@summer/ui-components';

import { MobileSize } from 'constants/styleguide';
import { usePlatform } from 'hooks/platform';
import { getUserStateOfResidence } from 'redux/selectors/profile.selectors';

const FFRecCardContent = ({ stateOfResidence }) => {
  const mappedState = USStateDisplayNameMapping[stateOfResidence];

  const dispatch = useDispatch();
  const handleCTA = React.useCallback(() => {
    dispatch(redirectTo(`/forgiveness-finder`));
  }, [dispatch]);

  return (
    <CardContentContainer>
      <ContentContainer>
        <Header h2>Explore Summer&rsquo;s Forgiveness Finder</Header>
        <Text paragraph weight="medium" size="medium">
          We have a robust list of federal and private forgiveness programs for
          residents of {mappedState} that you may qualify for based on your
          occupation.
        </Text>
      </ContentContainer>
      <CTAContainer>
        <Button onClick={handleCTA} width={255}>
          View Forgiveness Programs
        </Button>
      </CTAContainer>
    </CardContentContainer>
  );
};

const ForgivenessFinderRec = ({ stateOfResidence }) => {
  const { isMobile } = usePlatform();
  const headerDirection = isMobile ? 'column' : 'row';

  return (
    <FancyCard
      title="FORGIVENESS FINDER"
      shortTitle="FF"
      color="sand"
      testId="rec-card-ff"
      headerDirection={headerDirection}
    >
      <FFRecCardContent stateOfResidence={stateOfResidence} />
    </FancyCard>
  );
};

const CardContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: inherit;
  align-items: center;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;

    > * {
      margin-bottom: 16px;
      :last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const ContentContainer = styled.div`
  margin-right: 8px;
  max-width: 80%;

  > * {
    margin-bottom: 8px;
  }

  :last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${MobileSize}) {
    max-width: 100%;
  }
`;

const CTAContainer = styled.div`
  text-align: right;
  align-self: flex-end;
  width: 255px;

  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

const mapStateToProps = state => ({
  stateOfResidence: getUserStateOfResidence(state),
});

export default connect(mapStateToProps)(ForgivenessFinderRec);
