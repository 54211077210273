import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Text, Strong, COLORS } from '@summer/ui-components';

import ConnectFsa from 'components/common/SyncLoans/ConnectFsa';
import WizardContent from 'components/layout/wizard/WizardContent';
import WizardFooter from 'components/layout/wizard/WizardFooter';
import { Link } from 'components/shared/typography';
import { hasSyncedLoans } from 'redux/selectors/sync.selectors';

import LoansPage from './LoansPage';

const HeaderContent = () => (
  <Text paragraph>
    Connecting your loans will help us accurately confirm your loan status and
    history so we can <Strong>identify your available options</Strong>.
  </Text>
);

const CustomCTA = navigation => {
  return (
    <SkipLink data-testid="default-skip-sync">
      <Link track inheritColor underline onClick={navigation.next}>
        Skip for now
      </Link>
    </SkipLink>
  );
};

const DefaultSyncPage = ({ hasSyncedLoans, navigation }) => {
  return (
    <WizardContent backgroundColor={COLORS.white}>
      {hasSyncedLoans ? (
        <LoansPage navigation={navigation} />
      ) : (
        <ConnectFsa
          page="pslf"
          headerSize="h2"
          headerContent={<HeaderContent />}
          CustomCTA={() => CustomCTA(navigation)}
          mobileHeaderAlign="left"
        />
      )}
      <WizardFooter handleBack={navigation.back} isNextVisible={false} />
    </WizardContent>
  );
};

const SkipLink = styled.div`
  margin: 24px 0;
  text-align: center;
  color: ${COLORS.darkerGrey};
`;

const mapStateToProps = state => ({
  hasSyncedLoans: hasSyncedLoans(state),
});

export default connect(mapStateToProps)(DefaultSyncPage);
