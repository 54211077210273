import axios from 'axios';
import { useEffect, useState } from 'react';

export const getLatestPosts = async () => {
  const allPosts = await getPostsFromXML(
    'https://proxy.meetsummer.org/corsproxy/?apiurl=https://www.meetsummer.com/post/rss.xml',
  );
  const indvidulsPosts = allPosts.filter(post => {
    return post.description.includes('Individuals');
  });
  return indvidulsPosts.slice(0, 6);
};

export const getPostsFromXML = async url => {
  const parser = new DOMParser();
  const response = await axios.get(url, {
    headers: {
      'Content-Type': 'text/xml',
    },
  });
  const xmlDoc = parser.parseFromString(response.data, 'text/xml');
  const items = Array.from(xmlDoc.getElementsByTagName('item'));
  const posts = items.map(item => {
    return {
      title: item.getElementsByTagName('title')[0].textContent,
      link: item.getElementsByTagName('link')[0].textContent,
      description: item.getElementsByTagName('description')[0].textContent,
      pubDate: item.getElementsByTagName('pubDate')[0].textContent,
    };
  });
  return posts;
};

export const useGetLastestPosts = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    const fetchPosts = async () => {
      const posts = await getLatestPosts();
      setPosts(posts);
    };
    fetchPosts();
  }, []);

  return posts;
};
