import { REHYDRATE } from 'redux-persist';

import { SET_USER } from 'constants/actionTypes';
import handleSideEffects from 'redux/handleSideEffects';
import { setDatadogContext, setDatadogUser } from 'services/datadog';

const datadogMiddleware = handleSideEffects({
  [SET_USER]: (dispatch, { payload }) => {
    setDatadogUser(payload.user);
    setDatadogContext('flags', payload.user.flags ?? {});
  },

  [REHYDRATE]: (dispatch, { payload }) => {
    if (payload?.currentUser) {
      setDatadogUser(payload.currentUser);
    }

    if (payload?.flags) {
      setDatadogContext('flags', payload.flags);
    }
  },
});

export default datadogMiddleware;
