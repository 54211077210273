import styled from 'styled-components';

import { Header, Text } from '@summer/ui-components';

import { TabletSize } from 'constants/styleguide';

export const FormContents = styled.div`
  width: 100%;
  margin: auto;

  @media (min-width: ${TabletSize}) {
    width: 50%;
  }
`;

export const Label = styled(Header)`
  margin-bottom: 28px;
`;

export const HelperText = styled(Text)`
  margin-bottom: 24px;
`;
