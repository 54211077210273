import { format, parseISO } from 'date-fns';
import { isNil } from 'lodash';
import { startCase, toLower } from 'lodash/string';
import React from 'react';

import { Text } from '@summer/ui-components';

import { dollars, percentage } from 'utils/numbers';

export const CELL_TYPES = {
  STRING: 'string',
  PERCENT: 'percent',
  MONEY: 'money',
  DATE: 'date',
  ELIGIBILITY: 'eligibility',
};

const generateCell = (type, value) => {
  switch (type) {
    case CELL_TYPES.STRING: {
      const formattedValue = startCase(toLower(value)).replace(/Ffel/, 'FFEL');
      return (
        <>
          <Text
            data-testid={`cell-${type}-${formattedValue}`}
            weight="medium"
            color="dark"
            truncate
            tooltip={formattedValue}
            paragraph
            smallTracking
          >
            {formattedValue}
          </Text>
        </>
      );
    }
    case CELL_TYPES.PERCENT:
      return (
        <>
          <Text
            data-testid={`cell-${type}-${value}`}
            weight="medium"
            color="dark"
            paragraph
            smallTracking
          >
            {percentage(value, { showSymbol: true })}
          </Text>
        </>
      );
    case CELL_TYPES.MONEY:
      return (
        <>
          <Text
            data-testid={`cell-${type}-${value}`}
            weight="medium"
            color="dark"
            paragraph
            smallTracking
          >
            <div>{dollars(value)}</div>
          </Text>
        </>
      );
    case CELL_TYPES.DATE:
      return (
        <>
          <Text
            data-testid={`cell-${type}-${value}`}
            weight="medium"
            color="dark"
            paragraph
            smallTracking
          >
            {format(parseISO(value), 'MM / yyyy')}
          </Text>
        </>
      );
    default:
      return null;
  }
};

export const Cell = ({ type, value, moreInfo, valid }) => (
  <>
    {isNil(value) ? (
      <Text
        smallTracking
        paragraph
        color={valid === false ? 'error' : 'secondary'}
        decoration={valid === false ? 'underline' : null}
      >
        {valid === false ? 'Required' : 'Unavailable'}
      </Text>
    ) : (
      <>
        {generateCell(type, value)}
        <Text
          color="secondary"
          truncate
          tooltip={moreInfo}
          smallTracking
          paragraph
          size="small"
        >
          {moreInfo}
        </Text>
      </>
    )}
  </>
);
