export const ONBOARDING_PAGE_VIEWED = 'eduAssist:onboarding_viewed';

export const EDU_ASSIST_LANDING_PAGE_VIEWED = 'eduAssist:landing_viewed';
export const EDU_ASSIST_GET_STARTED_CLICKED = 'eduAssist:start_click';
export const EDU_ASSIST_COURSE_REQUEST_FORM_VIEWED =
  'eduAssist:courseForm_viewed';
export const EDU_ASSIST_DOCUMENT_UPLOAD_CLICKED = 'eduAssist:docUpload_click';
export const EDU_ASSIST_SUBMIT_FORM_CLICKED = 'eduAssist:submitCourse_click';
export const EDU_ASSIST_COURSE_REQUEST_SUCCESS_MODAL_VIEWED =
  'eduAssist:successModal_viewed';
export const EDU_ASSIST_CANCEL_COURSE_REQUEST_FORM_CLICKED =
  'eduAssist:cancelForm_click';
export const EDU_ASSIST_DETAILS_MODAL_VIEWED = 'eduAssist:requestModal_viewed';
export const EDU_ASSIST_ADD_PROOF_OF_COMPLETION_BUTTON_CLICKED =
  'eduAssist:fileProof_click';
export const EDU_ASSIST_PROOF_OF_COMPLETION_FORM_VIEWED =
  'eduAssist:proofForm_viewed';
export const EDU_ASSIST_PROOF_OF_COMPLETION_SUBMITTED =
  'eduAssist:submitProof_click';
export const EDU_ASSIST_PROOF_OF_COMPLETION_SUCCESS_MODAL_VIEWED =
  'eduAssist:proofSubmitted_viewed';
