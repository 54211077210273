import { SET_SMS_FACTOR } from 'constants/actionTypes';

const initialState = {
  phoneNumber: null,
  status: null,
};

const smsFactorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SMS_FACTOR: {
      return payload;
    }
    default:
      return state;
  }
};

export default smsFactorReducer;
