import React from 'react';
import styled from 'styled-components';

import {
  Button,
  Cheers,
  COLORS,
  FlexCenter,
  FlexColumn,
  Header,
  IconMedallion,
  ModalPopup,
  Text,
} from '@summer/ui-components';

import { MobileSize } from 'constants/styleguide';

const SuccessModal = ({ togglePopup, isOpen }) => {
  return (
    <ModalPopup
      isOpen={isOpen}
      openByDefault
      width={700}
      togglePopup={togglePopup}
      component={() => {
        return (
          <Container>
            <FlexCenter>
              <IconContainer>
                <IconMedallion
                  SvgComponent={Cheers}
                  size={80}
                  color={COLORS.azure}
                />
              </IconContainer>
            </FlexCenter>
            <FlexColumn>
              <Header as="h2">
                Great! You&rsquo;re enrolled in your employer&rsquo;s Student
                Loan Retirement Match benefit.
              </Header>
              <HeaderSubtext paragraph bold large>
                Here&rsquo;s what happens next:
              </HeaderSubtext>
              <List>
                <li>
                  <Text paragraph>
                    Continue to make payments on your student loans as you
                    normally would
                  </Text>
                </li>
                <li>
                  <Text paragraph>
                    Summer will keep track of your payments and will let you
                    know if you need more information.
                  </Text>
                </li>
                <li>
                  <Text paragraph>
                    Your employer can now match your qualifying student loan
                    payments as contributions to your workplace retirement plan.
                    The amount of the match (for both your student loan payments
                    and your deferrals to your retirement plan) is determined by
                    your employer&rsquo;s plan rules and federal limits.
                  </Text>
                </li>
              </List>
            </FlexColumn>
            <ButtonContainer>
              <Button width={210} onClick={togglePopup}>
                View my dashboard
              </Button>
            </ButtonContainer>
          </Container>
        );
      }}
    />
  );
};

const Container = styled.div`
  @media (min-width: ${MobileSize}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const IconContainer = styled.div`
  margin-bottom: 30px;
`;

const List = styled.ul`
  padding: 20px;
  & li {
    margin-bottom: 20px;
  }

  @media (min-width: ${MobileSize}) {
    margin-bottom: 36px;
  }
`;

const ButtonContainer = styled.div`
  text-align: right;
`;

const HeaderSubtext = styled(Text)`
  margin-top: 24px;
  @media (min-width: ${MobileSize}) {
    margin-bottom: 24px;
  }
`;

export default SuccessModal;
