import React from 'react';
import styled from 'styled-components';

import {
  Header,
  ResponsiveColumn,
  Row,
  Container as GridContainer,
  COLORS,
} from '@summer/ui-components';

import RecommendedBadge from 'components/shared/RecommendedBadge';
import { MobileSize } from 'constants/styleguide';
import useDimensions from 'hooks/useDimensions';
import { hexToRgba } from 'utils/common';

const LandingPageHeader = ({
  headerTheme,
  isRecommended,
  headerName,
  subHeader,
  isMobile,
}) => {
  const [ref, { height }] = useDimensions();

  return (
    <div style={{ position: 'relative' }}>
      <Background headerTheme={headerTheme} height={height} />
      <GridContainer ref={ref}>
        <HeaderRow center>
          <ResponsiveColumn desktopCol={6} mobileCol={12} tabletCol={12}>
            <Container>
              {isRecommended && <RecommendedBadge />}
              {!isMobile && <Header h2>{headerName}</Header>}
              {subHeader}
            </Container>
          </ResponsiveColumn>
        </HeaderRow>
      </GridContainer>
    </div>
  );
};

const HeaderRow = styled(Row)`
  position: relative;
  z-index: 1;
`;

const getBackgroundParams = headerTheme => {
  const backgroundMap = {
    magenta: {
      backgroundColor: hexToRgba(COLORS.magenta, 0.08),
      backgroundImage: 'linear-gradient(189deg, #e74ec6 1%, #fc75dd 66%)',
    },
    violet: {
      backgroundColor: hexToRgba(COLORS.violet, 0.08),
      backgroundImage: 'linear-gradient(213deg, #9e36d1 64%, #be64ec 39%)',
    },
    gold: {
      backgroundColor: hexToRgba(COLORS.gold, 0.08),
      backgroundImage: 'linear-gradient(213deg, #ffc100 64%, #ffd800 39%)',
    },
    cobaltDark: {
      backgroundColor: hexToRgba(COLORS.cobaltDark, 0.08),
      backgroundImage: 'linear-gradient(214deg, #0053da 63%, #007aff 39%)',
    },
    sand: {
      backgroundColor: hexToRgba(COLORS.sand, 0.08),
      backgroundImage: 'linear-gradient(171deg, #ffaf87 88%, #fce799 87%)',
    },
    darkGreen: {
      backgroundColor: hexToRgba(COLORS.darkGreen, 0.08),
      backgroundImage: 'linear-gradient(171deg, #0e8273 88%, #5eab9b 87%)',
    },
    grey: {
      backgroundColor: hexToRgba(COLORS.darkGrey, 0.08),
      backgroundImage: 'linear-gradient(229deg, #fff 55%, #6c7f8b 39%)',
    },
    orange: {
      backgroundColor: hexToRgba(COLORS.orange, 0.08),
      backgroundImage: 'linear-gradient(229deg, #ff8a38 95%, #fcd6ba 39%)',
    },
    jade: {
      backgroundColor: hexToRgba(COLORS.jade, 0.12),
      backgroundImage:
        'linear-gradient(189.02deg, #00662F 6.85%, #05C30D 63.94%)',
    },
  };

  return backgroundMap[headerTheme];
};

const minBackgroundHeight = 246;

export const Background = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ height }) =>
    height > minBackgroundHeight ? height : minBackgroundHeight}px;
  background-color: ${({ headerTheme }) => {
    const { backgroundColor } = getBackgroundParams(headerTheme);
    return backgroundColor;
  }};

  :before {
    content: '';
    z-index: 1;
    position: absolute;
    top: calc(${window.innerWidth}px * 0.76 * -1);
    right: calc(${window.innerWidth}px * 0.2);
    width: ${window.innerWidth}px;
    height: ${window.innerWidth}px;
    border-radius: 50%;
    opacity: 0.12;
    background-image: ${({ headerTheme }) => {
      const { backgroundImage } = getBackgroundParams(headerTheme);
      return backgroundImage;
    }};

    @media (max-width: ${MobileSize}) {
      top: calc(${window.innerWidth}px * 0.76 * -0.75);
      right: calc(${window.innerWidth}px * 0.3);
    }
  }

  :after {
    content: '';
    position: absolute;
    z-index: 1;
    top: -160px;
    right: calc(-30px);
    width: 600px;
    height: 600px;
    background: #fff;
    border-radius: 50%;
    opacity: 0.32;

    @media (max-width: ${MobileSize}) {
      top: -112px;
      left: 136px;
    }
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 40px;
  & .text {
    margin-top: 8px;
  }
  @media (max-width: ${MobileSize}) {
    margin-top: 16px;
  }
`;

export default LandingPageHeader;
