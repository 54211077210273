import { LOCATION_CHANGE } from 'connected-react-router';
import { set } from 'lodash/fp';
import { REHYDRATE } from 'redux-persist/lib/constants';

import { CLEAR_SERVER_ERROR, SHOW_SERVER_ERROR } from 'constants/actionTypes';

const initialState = {};

const serverErrorsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_SERVER_ERROR:
      return set(payload.label, payload.error, state);

    case CLEAR_SERVER_ERROR:
    case LOCATION_CHANGE:
      return initialState;

    case REHYDRATE:
      return {};

    default:
      return state;
  }
};

export default serverErrorsReducer;
