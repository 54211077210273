import md5 from 'blueimp-md5';
import { get, startCase, toLower, some } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { string } from 'yup';

import {
  FlexRow,
  Button,
  Header,
  Text,
  SvgImage,
  BalloonQuestion,
  COLORS,
} from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { aftJoinLink, aftLoginLink } from 'constants/externalLinks/partner';
import { TabletSize } from 'constants/styleguide';
import { fixedEncodeURIComponent } from 'services/partner/utils';
import { hexToRgba } from 'utils/common';
import { isUpperCase } from 'utils/text';
import { track } from 'utils/track';

export const aftConventionValidation = {
  localNumber: string().required('Local Number is a required field'),
  memberId: string().required('Member ID is a required field'),
};

export const getAftConventionPartnerIdentifierData = values => {
  return {
    identifierMetadata: {
      local: values.localNumber,
      member: values.memberId,
    },
  };
};

export const getNewAftPartnerIdentifierData = values => {
  return {
    identifierValue: values.partnerGuid || '',
    identifierMetadata: {},
  };
};

export const getPartnerIdentifierData = aftData => {
  return {
    identifierValue: aftData.memberId,
    identifierMetadata: {
      local: aftData.localNumber,
      campaign: aftData.campaign,
    },
  };
};

export const getName = aftData => {
  return {
    firstName: aftData.firstName,
    lastName: aftData.lastName,
  };
};

export const getAftData = () => {
  const queryParams = new URLSearchParams(window.location.search);

  return {
    firstName: queryParams.get('fname'),
    lastName: queryParams.get('lname'),
    hash: queryParams.get('hash'),
    partnerIdentifierData: {
      identifierValue: queryParams.get('member'),
      identifierMetadata: {
        localNumber: queryParams.get('local'),
        campaign: queryParams.get('campaign'),
      },
    },
  };
};

export const getAftName = partnerData => {
  let { firstName, lastName } = partnerData;

  if (isUpperCase(firstName) && isUpperCase(lastName)) {
    firstName = startCase(toLower(firstName));
    lastName = startCase(toLower(lastName));
  }

  return { firstName, lastName };
};

export const buildAftDataUrl = aftData => {
  const firstName = fixedEncodeURIComponent(aftData.firstName);
  const lastName = fixedEncodeURIComponent(aftData.lastName);

  return `fname=${firstName}&lname=${lastName}&member=${aftData.memberId}&local=${aftData.localNumber}&campaign=${aftData.campaign}`;
};

export const isAftUrlValid = aftData => {
  const aftParams = [
    'firstName',
    'lastName',
    'memberId',
    'localNumber',
    'campaign',
    'hash',
  ];

  const aftDataFlattened = {
    firstName: get(aftData, 'firstName'),
    lastName: get(aftData, 'lastName'),
    hash: get(aftData, 'hash'),
    memberId: get(aftData, 'partnerIdentifierData.identifierValue'),
    localNumber: get(
      aftData,
      'partnerIdentifierData.identifierMetadata.localNumber',
    ),
    campaign: get(aftData, 'partnerIdentifierData.identifierMetadata.campaign'),
  };

  const isParamFieldNull = some(
    aftParams,
    param => aftDataFlattened[param] == null,
  );

  if (isParamFieldNull) {
    return false;
  }

  const aftDataUrl = buildAftDataUrl(aftDataFlattened);
  const urlHash = md5(aftDataUrl);

  return urlHash === aftData.hash;
};

export const getAftUrlPartnerData = () => {
  const urlData = getAftData();
  const standardizedName = getAftName(urlData); // names will be title cased if they come in all caps
  return {
    urlData,
    standardizedName,
    isValidUrl: isAftUrlValid(urlData),
  };
};

export const getAftInitialValues = urlPartnerData => {
  return {
    firstName: get(urlPartnerData, 'standardizedName.firstName'),
    lastName: get(urlPartnerData, 'standardizedName.lastName'),
    email: '',
    password: '',
    partnerCode: '',
    customCodeName: undefined,
    termsOfService: false,
  };
};

export const getAftPartnerIdentifierData = urlData => {
  return get(urlData, 'urlData.partnerIdentifierData');
};

export const AftError = () => {
  track(`[UI] User tried accessing AFT with invalid URL`);

  return (
    <>
      <AftErrorImage>
        <SvgImage SvgComponent={BalloonQuestion} width={182} height={177} />
      </AftErrorImage>
      <AftErrorHeader>
        <Header h2 color="secondary">
          Hmmm... Your Member ID has not transferred from AFT. Please sign into
          the AFT website to gain access.
        </Header>
      </AftErrorHeader>
      <AftButtonContainer>
        <Link href={aftLoginLink}>
          <Button type="submit">Login to AFT member benefits</Button>
        </Link>
        <Link to="/login">Already a member? Sign in</Link>
      </AftButtonContainer>
    </>
  );
};

export const AftErrorHelpText = () => {
  return (
    <Text paragraph small color="secondary" center>
      Not an AFT member?{' '}
      <Link href={aftJoinLink} inheritColor underline>
        Join today!
      </Link>{' '}
    </Text>
  );
};

export const AftDetails = props => {
  return (
    <AftSplitRow>
      <AftBox>
        <AftHeader>
          <Header h2>Hello, {props.firstName}!</Header>
          <Text small color="default">
            These details came through from your AFT account:
          </Text>
        </AftHeader>
        <FlexRow>
          <IdColumn>
            <Header h5 color="default">
              AFT Member ID
            </Header>
            <Text medium color="secondary">
              {props.memberId}
            </Text>
          </IdColumn>
          <IdColumn>
            <Header h5 color="default">
              Local ID
            </Header>
            <Text medium color="secondary">
              {props.localId}
            </Text>
          </IdColumn>
        </FlexRow>
      </AftBox>
    </AftSplitRow>
  );
};

const AftErrorHeader = styled.div`
  margin-bottom: 32px;
  line-height: 35px;
`;

const AftButtonContainer = styled.div`
  text-align: center;
  & > * {
    margin-bottom: 16px;

    width: 100%;
  }
  line-height: 30px;

  a {
    display: block;
  }
`;

const AftErrorImage = styled.div`
  margin-bottom: 16px;
`;

const AftSplitRow = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: ${TabletSize}) {
    flex-direction: column;
  }

  & > * {
    :first-child {
      margin-right: 16px;
    }

    @media (max-width: ${TabletSize}) {
      :first-child {
        margin-right: 0;
      }
    }
  }

  background-color: ${hexToRgba(COLORS.azure, '0.05')};
  padding: 24px;
  margin-bottom: 15px;
`;

const AftBox = styled.div`
  margin-bottom: 0px;
  width: 100%;
`;

const AftHeader = styled.div`
  h2 {
    margin-bottom: 8px;
  }

  margin-bottom: 16px;
`;

const IdColumn = styled.div`
  flex-grow: 1;

  h5 {
    margin-bottom: 4px;
  }
`;
