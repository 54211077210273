import React from 'react';
import styled from 'styled-components';

import { Text, FlexRow } from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { PRIVACY_POLICY_LINK } from 'constants/externalLinks/supportLinks';

const PrivacyPolicyLink = ({ className }) => (
  <Container className={className}>
    <Text extraSmall color="secondary">
      See our{' '}
      <Link inheritColor underline href={PRIVACY_POLICY_LINK}>
        Privacy Policy
      </Link>{' '}
      to learn how your information is used, stored, and protected.
    </Text>
  </Container>
);

const Container = styled(FlexRow)`
  align-items: center;
  justify-content: center;
`;

export default PrivacyPolicyLink;
