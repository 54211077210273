import React from 'react';
import styled from 'styled-components';

import { FlexColumn, Header, COLORS } from '@summer/ui-components';

import { Consumer } from 'components/layout/Guide/Guide';
import { SCROLL_OFFSET, MobileSize } from 'constants/styleguide';

class Section extends React.Component {
  componentDidMount() {
    const { addSection, title } = this.props;
    addSection({ title, element: this.section });
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  handleScroll = () => {
    const { setActiveSection, title } = this.props;
    const { top } = this.section.getBoundingClientRect();

    if (top <= SCROLL_OFFSET) {
      setActiveSection(title);
    }
  };

  render() {
    return (
      <Container
        ref={ref => {
          this.section = ref;
        }}
      >
        <TitleContainer>
          <Header h2 color="summer">
            {this.props.title}:
          </Header>
        </TitleContainer>
        {this.props.children}
      </Container>
    );
  }
}

const Container = styled(FlexColumn)`
  flex: 1 0 auto;
  padding: 40px;
  width: 620px;
  margin: 16px 8px;
  background-color: ${COLORS.lightestGrey};
  border: 1px solid ${COLORS.grey};
  border-radius: 3px;

  @media (max-width: ${MobileSize}) {
    width: 95%;
    padding: 32px 24px;

    &:first-child {
      margin-top: 20px;
    }
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 30px;
`;

const ConsumerSection = props => {
  return <Consumer>{value => <Section {...value} {...props} />}</Consumer>;
};

export default ConsumerSection;
