import { keys, pick } from 'lodash';

import { SET_CSP_CALCULATOR_RESULTS } from 'constants/actionTypes';

const initialState = {
  netCost: null,
  collegeCost: null,
  contributions: {
    periodic: null,
    oneTime: null,
  },
  surplus: null,
  futureSavings: {
    total: null,
    futureValueContribution: null,
    futureValueSavings: null,
  },
  monthsUntilStart: null,
};

const resultsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CSP_CALCULATOR_RESULTS: {
      return pick(payload, keys(initialState));
    }

    default:
      return state;
  }
};

export default resultsReducer;
