import React from 'react';

import DefaultBlocker from 'components/common/DefaultBlocker';
import CallOrChat from 'components/shared/ContactUs';
import { getOutOfDefaultBulletsIdr } from 'constants/getOutOfDefault';

const LoansInDefault = () => {
  return (
    <DefaultBlocker
      getOutOfDefaultBullets={getOutOfDefaultBulletsIdr}
      ineligibleProgram="income-driven repayment (IDR)"
      contactUsSection={<CallOrChat />}
    />
  );
};

export default LoansInDefault;
