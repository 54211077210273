import { includes } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Alert, Text, Strong } from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { MobileSize } from 'constants/styleguide';
import { setUiSetting } from 'redux/actions/user.actions';
import { getUiSetting } from 'redux/selectors/ui.selectors';
import { getUserPartnerKey } from 'redux/selectors/user.selectors';

const ScheduleACallAlert = ({
  partnerKey,
  scheduleCallBannerDismissed,
  dispatch,
}) => {
  const showScheduleACallAlert = includes(['asurion'], partnerKey);

  if (showScheduleACallAlert && scheduleCallBannerDismissed !== 'true') {
    const closeScheduleCallAlert = () =>
      dispatch(setUiSetting('scheduleCallBannerDismissed', 'true'));
    return (
      <Alert
        theme="success"
        showIcon={false}
        onClose={closeScheduleCallAlert}
        centerContent
      >
        <Container>
          <Text size="small" paragraph>
            <Strong>
              As a premium user, you can schedule a call to speak with one of
              our student loan experts.
            </Strong>{' '}
            Just go to our{' '}
            <Link
              inheritColor
              underline
              to="/support"
              onClick={closeScheduleCallAlert}
            >
              Support page
            </Link>
            !
          </Text>
        </Container>
      </Alert>
    );
  }

  return null;
};

const mapStatetoProps = state => ({
  partnerKey: getUserPartnerKey(state),
  scheduleCallBannerDismissed: getUiSetting(
    state,
    'scheduleCallBannerDismissed',
  ),
});

const Container = styled.div`
  @media (max-width: ${MobileSize}) {
    padding: 4px 6px;
  }
`;

export default connect(mapStatetoProps)(ScheduleACallAlert);
