import { DEFAULT_ACTION } from 'constants/actionTypes';

const handleSideEffects =
  sideEffects =>
  ({ getState, dispatch }) =>
  next =>
  action => {
    const sideEffect = sideEffects[action.type];
    const defaultAction = sideEffects[DEFAULT_ACTION];

    next(action);

    let state;
    if (sideEffect || defaultAction) {
      state = getState();
    }

    if (sideEffect) {
      sideEffect(dispatch, action, state);
    }

    if (defaultAction) {
      defaultAction(dispatch, action, state);
    }
  };

export default handleSideEffects;
