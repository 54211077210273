import { push as redirectTo } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  COLORS,
  Text,
  FlexColumn,
  FlexRow,
  Button,
  Header,
  InfoTooltip,
  IconMedallion,
  AutoSaveSafe,
} from '@summer/ui-components/src';

import { Link } from 'components/shared/typography';
import { dollars } from 'utils/numbers';

import { EmergencySavingsCard } from './shared';

const AutoDepositTable = ({ enabled, depositConfigs }) => {
  if (!depositConfigs?.length) {
    return (
      <EmptyTable>
        <Text paragraph>No auto-deposits created yet!</Text>
        <Text paragraph>
          {enabled
            ? 'Sync a savings account to get started.'
            : 'Calculate a savings target to get started.'}
        </Text>
      </EmptyTable>
    );
  }
  return (
    <Deposits role="list">
      {depositConfigs?.map(({ logo, name, frequency, amount }) => (
        <Deposit role="listitem" key={name}>
          <DepositImage>
            <img alt={`${name} logo`} src={logo} />
            <Text>{name}</Text>
          </DepositImage>
          <Text color="secondary" size="extraSmall">
            {frequency}
          </Text>
          <Text bold>{dollars(amount)}</Text>
        </Deposit>
      ))}
    </Deposits>
  );
};

const AutoDeposits = ({ enabled, payrollProviders: depositConfigs }) => {
  const dispatch = useDispatch();

  return (
    <EmergencySavingsCard>
      <Content>
        <Head>
          <Header as="h2">
            Auto-Deposits{' '}
            <InfoTooltip text="With just a few clicks, you can start directing the right amount of your paycheck into your Emergency Savings Account.  Even better, once you hit your Emergency Savings goal, Summer will automatically stop contributing and help you reallocate that portion toward your next financial goal like paying down high interest debt or savings for retirement." />
          </Header>
          <IconMedallion
            size={54}
            paintWith="stroke"
            SvgComponent={AutoSaveSafe}
          />
        </Head>
        <AutoDepositTable enabled={enabled} depositConfigs={depositConfigs} />
        <Buttons>
          <Button
            disabled={!enabled}
            width={190}
            onClick={() => dispatch(redirectTo('/emergency-savings/payroll'))}
          >
            Create auto-deposit
          </Button>
          <Link>Learn more</Link>
        </Buttons>
      </Content>
    </EmergencySavingsCard>
  );
};

const Head = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Buttons = styled(FlexRow)`
  margin-top: 32px;
  gap: 24px;
  align-items: center;
`;

const Content = styled(FlexColumn)``;

const EmptyTable = styled.div`
  text-align: center;
  padding-top: 24px;
  padding-bottom: 24px;
  background: ${COLORS.lighterGrey};
`;

const Deposits = styled(FlexColumn)`
  gap: 8px;
`;

const Deposit = styled(FlexRow)`
  border: 1px solid ${COLORS.medDarkGrey};
  border-radius: 4px;
  align-items: center;
  padding: 24px 20px;

  & > * {
    flex-grow: 1;
  }

  & > :nth-child(2) {
    flex-grow: 3;
  }

  & > :first-child {
    flex-basis: 15%;
  }

  & > :last-child {
    margin-left: auto;
    text-align: right;
  }
`;

const DepositImage = styled(FlexRow)`
  gap: 8px;
  align-items: center;
`;

export default AutoDeposits;
