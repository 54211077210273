import axios from 'axios';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { FlexCenter, LoadingBars, Text, COLORS } from '@summer/ui-components';

import GlobalError from 'components/shared/GlobalError';
import { MobileSize } from 'constants/styleguide';
import { usePlatform } from 'hooks/platform';
import { fetchLoans } from 'redux/actions/loans.actions';
import { fetchSyncStatus } from 'redux/actions/sync.actions';
import { closeConnectFsa, showServerError } from 'redux/actions/ui.actions';
import { logout } from 'redux/actions/user.actions';
import { getUserEmail } from 'redux/selectors/user.selectors';
import { hexToRgba } from 'utils/common';

import DropZoneLabel from './DropZoneLabel';

// [sc-31636] integrate this with redux instead of making requests here
const useFileUpload = (email, pathname) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onDrop = async acceptedFiles => {
    if (isEmpty(acceptedFiles)) {
      return;
    }

    setLoading(true);

    const file = acceptedFiles[0]; // we only allow one file
    const fd = new FormData();
    fd.append('file', file);

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/loans/nslds`,
      data: fd,
      withCredentials: true,
    })
      .then(() => {
        dispatch(fetchLoans());
        dispatch(fetchSyncStatus());
        dispatch(closeConnectFsa());
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          if (email !== null) {
            dispatch(logout({ pathOrigin: pathname }));
          }
        } else {
          dispatch(
            showServerError({
              label: 'uploadNslds',
              error: {
                message: error?.response.data?.message ?? error,
                status: error?.response.status,
              },
            }),
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { onDrop, loading };
};

const NsldsUpload = ({ email, location }) => {
  const { onDrop, loading } = useFileUpload(email, location.pathname);
  const { platformType } = usePlatform();

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      maxSize: 2e7, // 1 MB
      accept: {
        'text/csv': ['.csv'],
        'text/plain': ['.txt'],
      },
      multiple: false,
      style: { border: 0, width: '100%' },
    });

  return (
    <>
      <LoadingContainer isLoading={loading}>
        <Text bold align="center">
          Loading your loans...
        </Text>
        <br />
        <LoadingBars color={COLORS.azure} />
      </LoadingContainer>

      <Container isLoading={loading}>
        <Text bold large>
          Upload your FSA loan file
        </Text>

        <DropZoneContainer {...getRootProps()} isLoading={loading}>
          <input {...getInputProps()} />

          <DropZone
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
            data-testid="dropzone"
          >
            <DropZoneLabel
              isDragAccept={isDragAccept}
              isDragReject={isDragReject}
              platformType={platformType}
            />
          </DropZone>
        </DropZoneContainer>

        <GlobalError label="uploadNslds" />
      </Container>
    </>
  );
};

const Container = styled.div`
  display: ${({ isLoading }) => (isLoading ? 'none' : 'block')};
`;

const DropZoneContainer = styled.div`
  margin-top: 15px;
`;

const LoadingContainer = styled.div`
  margin-top: 15px;
  height: 145px;
  display: ${({ isLoading }) => (!isLoading ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${MobileSize}) {
    height: 88px;
  }
`;

const DropZone = styled(FlexCenter)`
  flex: 1 0 auto;
  height: 145px;
  cursor: pointer;
  border: dashed 2px ${hexToRgba(COLORS.skyBlue, '0.6')};
  border-radius: 3px;
  background-color: ${({ isDragAccept, isDragReject }) => {
    if (isDragAccept) {
      return '#e8faee';
    }
    if (isDragReject) {
      return hexToRgba(COLORS.orange, '0.6');
    }
    return hexToRgba(COLORS.skyBlue, '0.1');
  }};

  @media (max-width: ${MobileSize}) {
    height: 88px;
  }
`;

const mapStateToProps = state => ({
  email: getUserEmail(state),
});

export default withRouter(connect(mapStateToProps)(NsldsUpload));
