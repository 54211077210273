import {
  FETCH_TUITION_REIMBURSEMENT,
  POST_TUITION_REIMBURSEMENT_COURSE_REQUEST,
  SET_TUITION_REIMBURSEMENT,
  FETCH_TUITION_REIMBURSEMENT_PARTNER_CONFIG,
  SET_TUITION_REIMBURSEMENT_PARTNER_CONFIG,
  PATCH_TUITION_REIMBURSEMENT,
} from 'constants/actionTypes';

export const fetchTuitionReimbursement = () => ({
  type: FETCH_TUITION_REIMBURSEMENT,
  payload: {},
});

export const setTuitionReimbursement = payload => ({
  type: SET_TUITION_REIMBURSEMENT,
  payload,
});

export const postTuitionReimbursementCourseRequest = (
  payload,
  { onSuccess, onFailure },
) => ({
  type: POST_TUITION_REIMBURSEMENT_COURSE_REQUEST,
  payload,
  meta: { onSuccess, onFailure },
});

export const patchTuitionReimbursement = (
  uuid,
  payload,
  { onSuccess, onFailure },
) => ({
  type: PATCH_TUITION_REIMBURSEMENT,
  payload: {
    uid: uuid,
    ...payload,
  },
  meta: { onSuccess, onFailure },
});

export const fetchTuitionReimbursementPartnerConfig = () => ({
  type: FETCH_TUITION_REIMBURSEMENT_PARTNER_CONFIG,
  payload: {},
});

export const setTuitionReimbursementPartnerConfig = payload => ({
  type: SET_TUITION_REIMBURSEMENT_PARTNER_CONFIG,
  payload,
});
