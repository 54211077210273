import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {
  FlexRow,
  Header,
  Text,
  Testimonial,
  COLORS,
} from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import PartialDataCreateAccountForm from 'components/pages/onboarding/PartialDataCreateAccountForm';
import AdpMarketing from 'components/pages/onboarding/marketing/AdpMarketing';
import { Disclaimer } from 'components/pages/onboarding/shared';
import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';
import { TabletSize, MobileSize } from 'constants/styleguide';
import { createAccount } from 'redux/actions/user.actions';
import { getOnboardingBadEmail } from 'redux/selectors/onboarding.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { hexToRgba } from 'utils/common';

const CreateAccountPartner = ({ windowWidth, platformType }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const isSecureEligible = queryParams.get('isSecureEligible') === 'true';
  const firstName = queryParams.get('firstName') || '';
  const lastName = queryParams.get('lastName') || '';

  const headerRef = useRef(null);
  const [backgroundHeight, setBackgroundHeight] = useState();

  const isMobile = platformType === MOBILE;

  useEffect(
    function updateBackgroundHeight() {
      setBackgroundHeight(headerRef.current?.offsetTop);
    },
    [windowWidth],
  );

  const getHelpText = () => {
    return (
      <Text paragraph small color="secondary" center>
        Having trouble? Contact us at{' '}
        <Link href={`mailto:${supportAddress}`} inheritColor underline>
          {supportAddress}
        </Link>{' '}
        and we’ll get back to you.
      </Text>
    );
  };

  const InfoItem = ({ children }) => (
    <li>
      <Text large color="dark" paragraph>
        {children}
      </Text>
    </li>
  );

  const getInfoListTitle = () => {
    return isSecureEligible ? "Here's how it works:" : 'Account Benefits:';
  };

  const getInfoItemsList = () => {
    const itemList = isSecureEligible
      ? [
          'Sync your student loans with Summer.',
          'Summer will send your student loan payment data to ADP.',
          'Your employer will match your qualifying student loan payments into your retirement plan on an annual basis, subject to any plan rules and legal limits.',
        ]
      : [
          'Compare savings plans',
          'Track your loans',
          'Access to guidance and resources',
          'Check your eligibility for federal forgiveness plans',
        ];

    const listElems = itemList.map((item, idx) => (
      <InfoItem key={idx}>{item}</InfoItem>
    ));

    return isSecureEligible ? (
      <ListContainer>
        <ol>{listElems}</ol>
      </ListContainer>
    ) : (
      <ListContainer>
        <ul>{listElems}</ul>
      </ListContainer>
    );
  };

  const getHeaderText = () => {
    return isSecureEligible
      ? 'With ADP and Summer, you can pay off your student loans and save for retirement - at the same time.'
      : 'With ADP and Summer, you can save on your student loans.';
  };

  const getDescriptionText = () => {
    return isSecureEligible
      ? 'Great news! Your employer will now make a matching contribution into your retirement plan account based on your qualified student loan payments.'
      : "All ADP participants now have access to Summer's student debt tools and resources.";
  };

  const getFormHeaderText = () => {
    return isSecureEligible && firstName && lastName
      ? 'Verify your email'
      : 'Create an account';
  };

  return (
    <>
      <Container>
        <Background backgroundHeight={backgroundHeight} />
        <LogoContainer>
          <Logo width={130} src="/images/partners/adp-logo.png" />
        </LogoContainer>
        <Info isMobile={isMobile}>
          <InfoHeader>
            <Header>{getHeaderText()}</Header>
            <div ref={headerRef} />
          </InfoHeader>
          <Text large color="dark" bold paragraph>
            {getDescriptionText()}
          </Text>
          <Spacer />
          <Text large color="dark" bold paragraph>
            {getInfoListTitle()}
          </Text>
          {getInfoItemsList()}
          <Testimonial
            image="/images/testimonials/jennifer-cleaveland.png"
            quote="&ldquo;Summer was great at explaining everything in simple terms and helping me understand what I needed to do.&rdquo;"
            name="&mdash; Jennifer C., Ohio"
          />
        </Info>
        <FormAndHelp>
          <FormContainer>
            <FormHeader h2>{getFormHeaderText()}</FormHeader>
            {isSecureEligible && firstName && lastName && (
              <PrefillTextContainer>
                <Text large paragraph>
                  We received your first and last name from ADP. Please enter
                  your email address to continue.
                </Text>
              </PrefillTextContainer>
            )}
            <PartialDataCreateAccountForm />
            <Help>{getHelpText()}</Help>
            <DisclaimerContainer>
              <Disclaimer />
            </DisclaimerContainer>
          </FormContainer>
        </FormAndHelp>
      </Container>
      <AdpMarketing />
    </>
  );
};

const Container = styled(FlexRow)`
  margin-top: 76px;
  padding: 0 24px 24px;
  width: 100%;
  justify-content: center;
  flex: 1 0 auto;

  @media (max-width: ${TabletSize}) {
    margin-top: 54px;
    flex-direction: column;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(${props => props.backgroundHeight}px + 30px);
  background-image: linear-gradient(
    to bottom,
    rgba(252, 254, 255, 0.53),
    ${hexToRgba(COLORS.azure, '0.05')}
  );
  z-index: -1;

  transition: height 1s;

  @media (max-width: ${TabletSize}) {
    height: calc(${props => props.backgroundHeight}px + 24px);
  }
`;

const LogoContainer = styled(FlexRow)`
  position: absolute;
  top: 8px;
  right: 30px;
  align-items: center;
  height: 80px;

  @media (max-width: ${TabletSize}) {
    right: 24px;
  }

  @media (max-width: ${MobileSize}) {
    right: 24px;
  }
`;

const Logo = styled.img`
  margin-left: 20px;
  width: ${({ width }) => width}px;

  @media (max-width: ${TabletSize}) {
    width: ${({ width }) => width - 30}px;
  }

  @media (max-width: ${MobileSize}) {
    ${({ width }) => (width > 200 ? `width: ${width - 125}px;` : '')}
  }
`;

const Info = styled.div`
  max-width: 390px;
  width: 100%;
  margin-right: 88px;
  margin-left: auto;

  flex: 0 1 auto;

  @media (max-width: ${TabletSize}) {
    flex: 1 0 auto;
    max-width: 560px;
    margin: 0 auto;

    ${({ isMobile }) =>
      !isMobile &&
      `border-bottom: solid 1px ${COLORS.grey};
    `}

    margin-bottom: 32px;
  }
`;

const Spacer = styled.div`
  height: 24px;
`;

const FormAndHelp = styled.div`
  max-width: 560px;
  width: 100%;
  margin-right: auto;
  flex: 0 1 auto;

  @media (max-width: ${TabletSize}) {
    flex: 1 0 auto;
    margin-left: auto;
  }
`;

const FormHeader = styled(Header)`
  margin-bottom: 32px;
`;

const PrefillTextContainer = styled.div`
  margin-bottom: 32px;
`;

const Help = styled.div`
  margin-top: 32px;
`;

const DisclaimerContainer = styled.div`
  margin-top: 24px;
`;

const InfoHeader = styled.div`
  margin-bottom: 60px;

  @media (max-width: ${TabletSize}) {
    margin-bottom: 40px;
  }

  & > h1 {
    font-size: 40px;

    @media (max-width: ${TabletSize}) {
      font-size: 32px;
    }
  }
`;

const FormContainer = styled.div`
  max-width: 560px;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 ${COLORS.medDarkGrey};
  padding: 48px 64px;
  background-color: ${COLORS.white};

  @media (max-width: ${TabletSize}) {
    width: 100%;
    box-shadow: inherit;
    padding: 0;
  }
`;

const ListContainer = styled.div`
  margin-top: 10px;
  margin-left: 32px;

  & li {
    margin-bottom: 12px;
  }

  @media (max-width: ${TabletSize}) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const mapStateToProps = state => ({
  badEmail: getOnboardingBadEmail(state),
  isLoading: isLoading(state, 'createAccount'),
});

export default withRouter(
  connect(mapStateToProps, { createAccount })(Platform(CreateAccountPartner)),
);
