import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  Header,
  SyncInfinity,
  Modal,
  Text,
  IconMedallion,
  FolderWarning,
  FlexCenter,
  COLORS,
} from '@summer/ui-components';

import PaymentVerification from 'components/pages/retirementMatch/dashboard/verification';
import { Link } from 'components/shared/typography';
import { PLAID_EVENTS } from 'hooks/plaid';
import { isLoading } from 'redux/selectors/ui.selectors';

const cardStates = {
  syncMore: 'sync more services',
  syncFederal: 'try sync federal loans',
  noFederal: 'missing federal loans',
};

const PlaidExtra = ({
  openPlaid,
  plaidEvent,
  hasFederalLoans,
  showPlaidCards,
  setShowPlaidCards,
  isPlaidLoading,
  showDataSummary = false,
  showFederalCopy = true,
  isPlaidDone = false,
  setIsPlaidDone,
  institutionId,
}) => {
  const { push: redirectTo } = useHistory();
  const [isPlaidOpen, setIsPlaidOpen] = useState(isPlaidDone);
  const [isPlaidInTransition, setIsPlaidInTransition] = useState(false);
  const [cardState, setCardState] = useState(cardStates.syncMore);
  const [isDataSummaryOpen, setDataSummaryOpen] = useState(showDataSummary);

  useEffect(
    function handlePlaidEvent() {
      const { open, exit, handoff, transition, success } = PLAID_EVENTS;

      if (plaidEvent === open) {
        setIsPlaidOpen(true);
        setIsPlaidInTransition(false);
      }
      if (plaidEvent === exit) {
        setIsPlaidOpen(false);
        setIsPlaidInTransition(false);
      }

      if (plaidEvent === handoff) {
        setIsPlaidInTransition(false);
      }

      if (plaidEvent === transition) {
        setIsPlaidInTransition(true);
      }

      if (plaidEvent === success) {
        setShowPlaidCards(true);
        setDataSummaryOpen(showDataSummary);
      }
    },
    [
      plaidEvent,
      setIsPlaidOpen,
      setIsPlaidInTransition,
      setShowPlaidCards,
      showDataSummary,
    ],
  );

  const CardContent = () => {
    if (cardState === cardStates.syncMore) {
      if (isPlaidInTransition) {
        return null;
      }

      const textCopy = showFederalCopy
        ? `
      If you have other federal student loans with a different servicer,
      please connect those loans too.`
        : `If you have other student loans with a different servicer,
      please connect those loans too.`;

      const linkCopy = showFederalCopy
        ? `I don’t have any more federal loans`
        : `I don’t have any more loans`;

      return (
        <>
          <InfinitySymbolImage
            size={59}
            SvgComponent={SyncInfinity}
            color={COLORS.jade}
          />
          <Header h2 align="center">
            Do you have another account to connect?
          </Header>
          <Text>{textCopy}</Text>
          <Button
            fullWidth
            onClick={() => {
              openPlaid();
              setShowPlaidCards(false);
            }}
          >
            Sync another account
          </Button>
          <Link
            onClick={() => {
              if (hasFederalLoans || showDataSummary) {
                setIsPlaidOpen(false);
                setIsPlaidDone(true);
                setShowPlaidCards(false);
              } else {
                setCardState(cardStates.syncFederal);
              }
            }}
          >
            {linkCopy}
          </Link>
        </>
      );
    }

    if (cardState === cardStates.syncFederal) {
      if (isPlaidInTransition) {
        return null;
      }

      return (
        <>
          <InfinitySymbolImage
            size={59}
            SvgComponent={SyncInfinity}
            color={COLORS.jade}
          />
          <Header h2 align="center">
            We didn’t find any federal loans
          </Header>
          <Text>
            It looks like you just synced your private loans, but we need your
            federal loans to determine the best plan for you.
          </Text>
          <Button
            fullWidth
            onClick={() => {
              openPlaid();
              setShowPlaidCards(false);
            }}
          >
            Sync your federal loans
          </Button>
          <Link
            onClick={() => {
              setCardState(cardStates.noFederal);
            }}
          >
            I don’t have any federal loans
          </Link>
        </>
      );
    }

    if (cardState === cardStates.noFederal) {
      if (isPlaidInTransition || showDataSummary) {
        return null;
      }

      return (
        <>
          <NoFederalIcon
            size={59}
            SvgComponent={FolderWarning}
            color={COLORS.orange}
          />
          <Header h2 align="center">
            Income-driven repayment plans are only available for federal loans
          </Header>
          <Text>
            Let’s see what other options are recommended to you, like
            refinancing or paying your loans off quicker.
          </Text>
          <Button
            fullWidth
            onClick={() => {
              redirectTo('/plan');
            }}
          >
            Back to My Plan
          </Button>
        </>
      );
    }

    return null;
  };

  return (
    <Modal
      isOpen={isPlaidOpen || showPlaidCards}
      backgroundColor={COLORS.lighterGrey}
      backgroundOpacity={1}
    >
      {showPlaidCards && isDataSummaryOpen && (
        <PaymentVerification
          setDataSummaryOpen={setDataSummaryOpen}
          institutionId={institutionId}
        />
      )}
      {showPlaidCards && !isDataSummaryOpen && (
        <CardContainer>
          <Card>
            {isPlaidLoading ? (
              <Loading>
                <Header h2>Loading...</Header>
              </Loading>
            ) : (
              <CardContent isPlaidInTransition={isPlaidInTransition} />
            )}
          </Card>
        </CardContainer>
      )}
    </Modal>
  );
};

PlaidExtra.propTypes = {
  plaidOpen: PropTypes.bool,
};

const mapStateToProps = state => ({
  isPlaidLoading: isLoading(state, 'postPlaidIntegration'),
});

export default connect(mapStateToProps)(PlaidExtra);

const Loading = styled(FlexCenter)`
  height: 100%;
`;

const CardContainer = styled.div`
  // this css is so the card will be in the same position of plaid widget
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding-bottom: 4%;
  padding-top: 2%;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 608px;
  width: 360px;
  padding: 78px 24px 55px;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;

  h2 {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  button {
    margin-top: 54px;
    margin-bottom: 20px;
  }
`;

const InfinitySymbolImage = styled(IconMedallion)`
  margin-right: 16px;
`;

const NoFederalIcon = styled(IconMedallion)`
  svg {
    width: 28px !important;
    height: 28px !important;
  }
`;
