import React from 'react';
import styled from 'styled-components';

import { LoadingBars, COLORS } from '@summer/ui-components';

import { TabletSize } from 'constants/styleguide';

const WizardContent = ({ children, isLoading, backgroundColor }) => (
  <Container backgroundColor={backgroundColor}>
    {isLoading ? <LoadingBars color={COLORS.azure} /> : children}
  </Container>
);
// these values are used in the footer for positioning
export const xPaddingDesktop = '32px';
export const xPaddingMobile = '16px';
export const topPaddingDesktop = '32px';
export const topPaddingMobile = '16px';

const Container = styled.div`
  align-items: center;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding-top: ${topPaddingDesktop};
  padding-bottom: 96px;
  padding-left: ${xPaddingDesktop};
  padding-right: ${xPaddingDesktop};
  ${({ backgroundColor }) =>
    backgroundColor
      ? `background-color: ${backgroundColor};`
      : `background-image: linear-gradient(to bottom, ${COLORS.white}, #eceff1 155%);`}

  @media (max-width: ${TabletSize}) {
    padding-top: ${topPaddingMobile};
    padding-left: ${xPaddingMobile};
    padding-right: ${xPaddingMobile};
    overflow: visible;
    flex: 1;
    height: unset;
  }
`;

export default WizardContent;
