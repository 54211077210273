import { format } from 'date-fns';
import { RichText } from 'prismic-reactjs';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Button, Header, Text, COLORS } from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { fetchScholarshipByUid } from 'redux/actions/collegeSavingsPlanner.actions';
import { getScholarshipByUid } from 'redux/selectors/collegeSavingsPlanner.selectors';
import { htmlSerializer } from 'utils/cms';
import { dollars } from 'utils/numbers';

import { ScholarshipDatabaseLayout } from '../layout';

const ScholarshipPage = ({ scholarship, dispatch, match }) => {
  useEffect(
    function fetchScholarshipOnMount() {
      if (!scholarship && match?.params?.uid) {
        dispatch(fetchScholarshipByUid(match.params.uid));
      }
    },
    [dispatch, scholarship, match?.params?.uid],
  );

  const {
    name,
    description,
    upToAmount,
    requirements,
    deadline,
    disbursement,
    applicationWebsite,
    applicationEmail,
    applicationPhone,
  } = scholarship?.data || {};

  return (
    <ScholarshipDatabaseLayout>
      <Container>
        <Link to="/college-savings-planner/scholarship-database">
          <Button secondary width={180}>
            Back to Scholarships
          </Button>
        </Link>
        <Header>{name}</Header>
        {description && (
          <RichText render={description} htmlSerializer={htmlSerializer} />
        )}
        {upToAmount && (
          <Text color="summer" bold>
            Up to {dollars(upToAmount)}
          </Text>
        )}
        {!!requirements?.length && (
          <section>
            <Header h3>Requirements</Header>
            <RichText render={requirements} htmlSerializer={htmlSerializer} />
          </section>
        )}
        {deadline && (
          <section>
            <Header h3>Deadline</Header>
            <Text>{format(new Date(deadline), 'LLLL dd, yyyy')}</Text>
          </section>
        )}
        {!!disbursement?.length && (
          <section>
            <Header h3>Disbursement</Header>
            <RichText render={disbursement} htmlSerializer={htmlSerializer} />
          </section>
        )}
        {(applicationWebsite?.url || applicationEmail || applicationPhone) && (
          <HowToApply>
            <Header h3 color="summer">
              How do I apply?
            </Header>
            {applicationWebsite?.url && (
              <Text>
                <Link inheritColor underline href={applicationWebsite?.url}>
                  Program Website
                </Link>
              </Text>
            )}
            {applicationEmail && (
              <Text>
                Email:{' '}
                <Link
                  inheritColor
                  underline
                  href={`mailto:${applicationEmail}`}
                >
                  {applicationEmail}
                </Link>
              </Text>
            )}
            {applicationPhone && <Text>Phone: {applicationPhone}</Text>}
          </HowToApply>
        )}
      </Container>
    </ScholarshipDatabaseLayout>
  );
};

const Container = styled.div`
  max-width: 745px;

  & > h1 {
    margin-top: 32px;
    margin-bottom: 16px;
  }

  & > .text {
    margin-top: 16px;
  }

  & > section {
    margin-top: 24px;

    h3 {
      margin-bottom: 4px;
    }
  }
`;

const HowToApply = styled.section`
  border-left: 4px solid ${COLORS.azure};
  padding-left: 12px;

  & > h3,
  & > .text {
    margin-bottom: 8px;
  }
`;

const mapStateToProps = (state, props) => ({
  scholarship: getScholarshipByUid(state, props.match.params.uid),
});

export default connect(mapStateToProps)(ScholarshipPage);
