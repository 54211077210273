import { push as redirectTo } from 'connected-react-router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackServerSide } from 'redux/actions/analytics.actions';
import { updateStripeMembership } from 'redux/actions/paywall.actions';
import { isLoadingWithInit } from 'redux/selectors/ui.selectors';

export const usePaywallSuccess = ({ sessionId, redirectPath }) => {
  const dispatch = useDispatch();
  const isUpdateLoading = useSelector(state =>
    isLoadingWithInit(state, 'updateStripeMembership'),
  );

  useEffect(
    function savePaywallSuccess() {
      dispatch(updateStripeMembership(sessionId));
      dispatch(trackServerSide('Payment information accepted'));
    },
    [sessionId, dispatch],
  );

  useEffect(
    function redirectBackAfterUpdate() {
      if (!isUpdateLoading) {
        dispatch(redirectTo(redirectPath));
      }
    },
    [isUpdateLoading, redirectPath, dispatch],
  );
};
