import { createSelector } from 'reselect';

export const getFinancialProfile = state => state.financialProfile;

export const getMaritalStatus = createSelector(
  getFinancialProfile,
  financialProfile => financialProfile.maritalStatus,
);

export const getSpouseHasFsl = createSelector(
  getFinancialProfile,
  financialProfile => financialProfile.spouseHasFsl,
);

export const getHasLoansFromGradSchool = createSelector(
  getFinancialProfile,
  financialProfile => financialProfile.hasLoansFromGradSchool,
);

export const getIdrRecertDate = createSelector(
  getFinancialProfile,
  financialProfile => financialProfile.idrRecertDate,
);

export const getIdrRepaymentPlanTypeId = createSelector(
  getFinancialProfile,
  financialProfile => financialProfile.idrRepaymentPlanTypeId,
);

export const getConsolidationIncludedParentPlus = createSelector(
  getFinancialProfile,
  financialProfile => financialProfile.consolidationIncludedParentPlus,
);
