import { isEmpty, findIndex, pick, times, get } from 'lodash';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Drawer, COLORS } from '@summer/ui-components';

import { useCompare, usePrevious } from 'hooks/common';

// This Hack is to prevent mousedown to fire blur before we click the drawer
// This fix the double click problem
// (the double click bug is because wrong use of Formik)
const handleMouseDown = e => {
  e.preventDefault();
};

const handleBlur = () => {
  // because we cancel the blur in handleMouseDown we need to fire it after the click
  document.activeElement.blur();
};

// this is a custom hook that add focus the to first input in the open drawer
export const useFormDrawerFocus = (formRef, show) => {
  useEffect(() => {
    const formElement = get(formRef, 'current');

    if (formElement instanceof HTMLElement) {
      const firstInput = formRef.current.getElementsByTagName('input')[0];
      if (show) {
        if (firstInput) {
          firstInput.focus({
            preventScroll: true,
          });
        }
      }
    }
  }, [show, formRef]);
};

// This creates an array of false booleans the length of the number of drawers
const getAllClosedState = numDrawers => times(numDrawers, () => false);

// This creates an array of booleans the length of the number of drawers
// First element will be true and the rest false.
const getFirstOpenState = numDrawers => {
  const firstOpen = getAllClosedState(numDrawers);
  firstOpen[0] = true;
  return firstOpen;
};

const FormDrawers = ({ forms, submitCount, resetSubmitCount, errors }) => {
  const numDrawers = get(forms, 'length');

  // An array that contains a set of errors for each drawer
  const groupedErrors = forms.map(form => pick(errors, form.fields));

  const [show, setShow] = useState(getFirstOpenState(numDrawers));

  const handleClick = i => e => {
    const newShow = getAllClosedState(numDrawers);

    newShow[i] = !show[i];
    setShow(newShow);

    handleBlur(e);
  };

  // Finds the first drawer with errors
  const sectionWithErrors = findIndex(
    groupedErrors,
    sectionErrors => !isEmpty(sectionErrors),
  );
  const currentSection = findIndex(show);
  const errorsDidUpdate = useCompare(sectionWithErrors);
  const submitCountDidIncrease = usePrevious(submitCount) < submitCount;

  // This hook will open the next section with any errors when the submit button is clicked
  useEffect(() => {
    // Don't run this hook if the sectionWithErrors changed
    // This prevents the next drawer from opening immediately once
    // the last error in a section is resolved.
    if (errorsDidUpdate) {
      return;
    }

    // If there are any errors, open the first drawer that contains errors
    if (sectionWithErrors >= 0 && submitCountDidIncrease) {
      const newShow = getAllClosedState(numDrawers);
      newShow[sectionWithErrors] = true;

      if (sectionWithErrors !== currentSection) {
        setShow(newShow);
        resetSubmitCount();
      }
    }
  }, [
    errorsDidUpdate,
    sectionWithErrors,
    submitCountDidIncrease,
    numDrawers,
    resetSubmitCount,
    currentSection,
  ]);

  // This hook will eenable the submit button when a new section with errors is opened
  useEffect(() => {
    if (sectionWithErrors !== currentSection) {
      resetSubmitCount();
    }
  }, [groupedErrors, sectionWithErrors, currentSection, resetSubmitCount]);

  return (
    <Container>
      {forms.map(({ form, name, title }, index) => (
        <Drawer
          className="drawer"
          key={name}
          ejectControl
          show={show[index]}
          onClick={handleClick(index)}
          evenPadding
          title={title}
          onMouseDown={handleMouseDown}
        >
          {form(show[index])}
        </Drawer>
      ))}
    </Container>
  );
};

const Container = styled.div`
  box-shadow: 0 0 4px 0 #dfe3e8;

  & h5 {
    font-size: 14px;
    color: ${COLORS.darkerGrey};
    letter-spacing: 0.7px;
    text-transform: uppercase;
    line-height: 0.86;
  }

  & > div > [class^='trigger-'] > div {
    border: none;
    border-bottom: 1px solid ${COLORS.grey};
    border-left: 1px solid ${COLORS.grey};
    border-right: 1px solid ${COLORS.grey};
  }

  & > div:first-child > [class^='trigger-'] > div {
    border-top: 1px solid ${COLORS.grey};
  }

  & [class^='collapse-'] > div {
    border: none;
    border-bottom: 1px solid ${COLORS.grey};
    border-left: 1px solid ${COLORS.grey};
    border-right: 1px solid ${COLORS.grey};
  }
`;

export default FormDrawers;
