import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Text, Strong, COLORS } from '@summer/ui-components';

import NewLoansTable from 'components/common/ViewLoans/LoansTables/NewLoanTable';
import { ServedInMilitary } from 'components/pages/idr/wizard/steps/Submit/fields';
import Header from 'components/pages/idr/wizard/steps/Submit/shared/Header';
import {
  getSelectedPath,
  getLoansToConsolidate,
  getPlaidLoansToConsolidate,
} from 'redux/selectors/idr.selectors';
import { shouldUsePlaidFlow } from 'redux/selectors/plaid.selectors';
import { getNormalizedFsaLoans, getNormalizedPlaidLoans } from 'utils/loans';
import { oxfordComma } from 'utils/text';

const ConsolidateLoans = ({
  values,
  errors,
  touched,
  idrHandlers,
  shouldUsePlaidFlow,
  loansToConsolidate,
  plaidLoansToConsolidate,
  selectedPath,
}) => {
  const formik = { values, errors, touched, ...idrHandlers };

  const selectedPathPlanType = get(selectedPath, 'planType');
  const multiplePlanTypes = get(selectedPathPlanType, 'length') > 1;

  return (
    <>
      <Header>
        In order to enroll you in the {oxfordComma(selectedPathPlanType)} plan
        {multiplePlanTypes ? 's' : ''}, we first have to consolidate your loans.
      </Header>
      <Text paragraph>
        These loans <Strong>will be consolidated</Strong> into a Direct
        Consolidation Loan:
      </Text>
      <TableContainer>
        <NewLoansTable
          loans={
            shouldUsePlaidFlow ? plaidLoansToConsolidate : loansToConsolidate
          }
          columnsToShow={['loanType', 'interestRate', 'currentBalance']}
        />
      </TableContainer>
      <Text small paragraph color="secondary">
        When you consolidate your loans, your interest rates are averaged and
        rounded up to the nearest 0.125%. (If you have loans with variable
        interest rates, they will switch to a fixed interest rate.)
      </Text>
      <Divider />
      <ServedInMilitary formik={formik} />
    </>
  );
};

const TableContainer = styled.div`
  margin: 16px 0;
`;

const Divider = styled.div`
  width: 100%;
  margin: 32px 0;
  border-bottom: 1px solid ${COLORS.grey};
`;

const mapStateToProps = state => ({
  loansToConsolidate: getNormalizedFsaLoans(getLoansToConsolidate(state)),
  plaidLoansToConsolidate: getNormalizedPlaidLoans(
    getPlaidLoansToConsolidate(state),
  ),
  selectedPath: getSelectedPath(state),
  shouldUsePlaidFlow: shouldUsePlaidFlow(state),
});

export default connect(mapStateToProps)(ConsolidateLoans);
