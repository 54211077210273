import {
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_PARTIAL_DATA,
  FETCH_CURRENT_STEP,
  FILL_LOAN_INFO,
  FILL_SURVEY,
  FORGOT_PASSWORD,
  LOGIN,
  LOGOUT,
  RESET_PASSWORD,
  SEND_ACCOUNT_VERIFICATION_EMAIL,
  SET_LOGOUT,
  SET_USER_EMAIL,
  SET_LOAN_INFO,
  SET_USER_SURVEY_DATA,
  UPDATE_EMAIL,
  UPDATE_PASSWORD,
  VERIFY_USER_EMAIL_SUCCESS,
  SET_UI_SETTING,
  UPDATE_IS_CURRENTLY_ENROLLED_IN_IDR,
  SET_IS_CURRENTLY_ENROLLED_IN_IDR,
  HYDRATE_USER,
  SET_IN_SLC,
} from 'constants/actionTypes';

export const createAccount = ({
  firstName,
  lastName,
  email,
  password,
  currentStep,
  partner,
  partnerIdentifierData,
  partnerCode,
  usePartnerCode,
  partnerGuid,
  usePartnerGuid,
  partnerApiBorrowerId,
}) => ({
  type: CREATE_ACCOUNT,
  payload: {
    firstName,
    lastName,
    email,
    password,
    currentStep,
    partner,
    partnerIdentifierData,
    partnerCode,
    usePartnerCode: usePartnerCode || false,
    partnerGuid,
    usePartnerGuid: usePartnerGuid || false,
    referrer: document.referrer,
    partnerApiBorrowerId,
  },
});

export const createPartialDataAccount = ({ firstName, lastName, email }) => ({
  type: CREATE_ACCOUNT_PARTIAL_DATA,
  payload: {
    firstName,
    lastName,
    email,
  },
});

export const login = ({ email, password }, meta) => ({
  type: LOGIN,
  payload: {
    email,
    password,
  },
  meta,
});

export const verifyUserSuccess = () => ({
  type: VERIFY_USER_EMAIL_SUCCESS,
});

export const sendAccountVerificationEmail = ({ email }) => ({
  type: SEND_ACCOUNT_VERIFICATION_EMAIL,
  payload: {
    email,
  },
});

export const forgot = ({ email }) => ({
  type: FORGOT_PASSWORD,
  payload: {
    email,
  },
});

export const resetPassword = ({ email, token, password }) => ({
  type: RESET_PASSWORD,
  payload: {
    email,
    token,
    password,
  },
});

export const updateEmail = ({ email, password }) => ({
  type: UPDATE_EMAIL,
  payload: {
    email,
    password,
  },
});

export const updatePassword = ({ oldPassword, newPassword }) => ({
  type: UPDATE_PASSWORD,
  payload: {
    oldPassword,
    newPassword,
  },
});

export const fillSurvey = ({
  gradStatus,
  troubleWithPayments,
  salary,
  familyCount,
  state,
  isPublicSector,
  creditScore,
  currentEmployer,
  phone,
  hasOptedIntoPhoneContact,
  currentStep,
}) => ({
  type: FILL_SURVEY,
  payload: {
    gradStatus,
    troubleWithPayments,
    salary,
    familyCount,
    state,
    isPublicSector,
    creditScore,
    currentEmployer,
    phone,
    hasOptedIntoPhoneContact,
    currentStep,
  },
});

export const fillLoanInfo = ({
  federalLoansEstimate,
  loansInDefaultFederal,
  privateLoansEstimate,
  loansInDefaultPrivate,
  isCurrentlyEnrolledInIDR,
}) => ({
  type: FILL_LOAN_INFO,
  payload: {
    federalLoansEstimate,
    loansInDefaultFederal,
    privateLoansEstimate,
    loansInDefaultPrivate,
    isCurrentlyEnrolledInIDR,
  },
});

export const setSurveyData = ({
  gradStatus,
  troubleWithPayments,
  salary,
  familyCount,
  isPublicSector,
  creditScore,
  currentEmployer,
  phone,
  hasOptedIntoPhoneContact,
  state,
}) => ({
  type: SET_USER_SURVEY_DATA,
  payload: {
    gradStatus,
    troubleWithPayments,
    salary,
    familyCount,
    isPublicSector,
    creditScore,
    currentEmployer,
    phone,
    hasOptedIntoPhoneContact,
    state,
  },
});

export const setLoanData = ({
  federalLoansEstimate,
  isCurrentlyEnrolledInIDR,
  loansInDefaultFederal,
  loansInDefaultPrivate,
  privateLoansEstimate,
}) => ({
  type: SET_LOAN_INFO,
  payload: {
    federalLoansEstimate,
    isCurrentlyEnrolledInIDR,
    loansInDefaultFederal,
    loansInDefaultPrivate,
    privateLoansEstimate,
  },
});

export const setUserEmail = email => ({
  type: SET_USER_EMAIL,
  payload: {
    email,
  },
});

export const logout = ({ pathOrigin = null }) => ({
  type: LOGOUT,
  meta: {
    locationState: {
      origin: pathOrigin,
    },
  },
});

export const setLogout = () => ({
  type: SET_LOGOUT,
});

export const getCurrentStep = () => ({
  type: FETCH_CURRENT_STEP,
});

export const setInSLC = ({ inSLC }) => ({
  type: SET_IN_SLC,
  payload: { inSLC },
});

export const setUiSetting = (key, value) => ({
  type: SET_UI_SETTING,
  payload: {
    key,
    value,
  },
});

export const updateIsCurrentlyEnrolledInIdr = isCurrentlyEnrolledInIDR => ({
  type: UPDATE_IS_CURRENTLY_ENROLLED_IN_IDR,
  payload: {
    isCurrentlyEnrolledInIDR,
  },
});

export const setIsCurrentlyEnrolledInIdr = ({ isCurrentlyEnrolledInIDR }) => ({
  type: SET_IS_CURRENTLY_ENROLLED_IN_IDR,
  payload: {
    isCurrentlyEnrolledInIDR,
  },
});

export const hydrateUser = () => ({
  type: HYDRATE_USER,
});
