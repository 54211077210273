import React from 'react';
import styled from 'styled-components';

import { FlexColumn, Text, Header, COLORS } from '@summer/ui-components';

import ConsolidateAlert from 'components/pages/pslf/wizard/steps/yourLoans/alerts/ConsolidateAlert';
import { Link } from 'components/shared/typography';
import { MobileSize } from 'constants/styleguide';

const WhatsNextFooter = ({ shouldShowConsolidateAlert }) => {
  return (
    <Content>
      <Divider />
      <LightHeader>
        <Header color="secondary" as="h3">
          WHAT&rsquo;S NEXT?
        </Header>
      </LightHeader>
      {shouldShowConsolidateAlert ? (
        <ConsolidateAlert shouldShowCTA buttonWidth={120} />
      ) : (
        <>
          <Header as="h4">
            Maximize your potential forgiveness with an income-driven repayment
            plan
          </Header>
          <StyledText paragraph>
            To lower your payments and maximize potential forgiveness under
            PSLF, enroll in a free federal income-driven repayment plan.{' '}
            <Link to="/repayment">Get Started</Link>.
          </StyledText>
        </>
      )}
    </Content>
  );
};

const Content = styled(FlexColumn)`
  width: 100%;
  z-index: 1;
  position: relative;
`;

const LightHeader = styled.div`
  text-align: left;
  margin-bottom: 16px;

  @media (max-width: ${MobileSize}) {
    max-width: 100%;
    margin-bottom: 8px;
  }
`;

const Divider = styled.div`
    width: 100%;
    border-bottom: 1px solid ${COLORS.grey};
    margin: 16px 0;
  }
`;

const StyledText = styled(Text)`
  margin: 8px 0;
  @media (max-width: ${MobileSize}) {
    margin: 16px 0;
  }
`;

export default WhatsNextFooter;
