import LogRocket from 'logrocket';

export const getAnalyticsTraits = () => {
  const { analytics } = window;

  if (analytics && analytics.user) {
    return analytics.user().traits();
  }

  return {};
};

export const identify = (email, options) => {
  const { analytics } = window;

  // only sends to mixpanel to maintain old behavior
  if (analytics) {
    analytics.identify(email, options, { All: false, Mixpanel: true });
  }
};

/**
 * need to multiplex because Mailchimp (removed) has very specific field naming rules
 * (10 character max).
 * TODO: Consider refactor to remove multiplexing
 */
export const segmentIdentify = (id, options) => {
  const { analytics } = window;

  const all = options.All || {};
  const mixpanel = options.Mixpanel;
  const zendesk = options.Zendesk;

  // send data to all destinations
  if (!mixpanel && !zendesk) {
    if (analytics) {
      analytics.identify(id, all, { integrations: { All: true } });
    }
    LogRocket.identify(id, all);
  }

  if (mixpanel) {
    if (analytics) {
      analytics.identify(
        id,
        { ...all, ...mixpanel },
        { integrations: { All: false, Mixpanel: true } },
      );
    }
    LogRocket.identify(id, { ...all, ...mixpanel });
  }

  if (analytics) {
    analytics.identify(id, { ...all }, { integrations: { All: false } });
  }

  if (zendesk && analytics) {
    analytics.identify(
      id,
      { ...all, ...zendesk },
      { integrations: { All: false, Zendesk: true } },
    );
  }
};

export const track = (event, options = {}) => {
  const { analytics } = window;

  if (analytics) {
    const user = getAnalyticsTraits();

    analytics.track(event, {
      ...options,
      // TODO: Move this somewhere else
      // We shouldn't be sending currentPslfFormId on every track event
      ...(user.currentPslfFormId
        ? { currentPslfFormId: user.currentPslfFormId }
        : {}),
    });
  }
};

export const alias = alias => {
  const { analytics } = window;

  if (analytics) {
    analytics.alias(alias);
  }
};

export const trackLink = (element, message, options) => {
  const { analytics } = window;

  if (analytics) {
    analytics.trackLink(element, message, options);
  }
};
