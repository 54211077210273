import { map, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Header, Text, COLORS } from '@summer/ui-components';

import Track from 'components/hoc/Track';
import Page from 'components/shared/dashboard/Page';
import { MobileSize } from 'constants/styleguide';
import { useIsPageLoading } from 'hooks/common';
import { useMyPlanRecommendations } from 'hooks/recEngine';
import { fetchRepaymentPlanTypes } from 'redux/actions/constants.actions';
import { fetchRecommendations } from 'redux/actions/recEngine.actions';
import { getUserHasCompletedOnboarding } from 'redux/selectors/onboarding.selectors';
import { isLoadingWithInit } from 'redux/selectors/ui.selectors';

import MyPlanHeader from './MyPlanHeader';
import NoRecommendedView from './NoRecommendedView';
import OnboardingRecCard from './OnboardingRecCard';
import RecommendationsHeader from './RecommendationsHeader';
import { getRecCard } from './shared';

const MyPlan = ({ track, isLoading, location }) => {
  const dispatch = useDispatch();
  const fromOnboarding = location?.state?.origin === '/onboard/loan-info';
  const userHasCompletedOnboarding = useSelector(getUserHasCompletedOnboarding);

  useEffect(
    function loadInitialData() {
      dispatch(fetchRepaymentPlanTypes());
      if (!fromOnboarding && userHasCompletedOnboarding) {
        dispatch(fetchRecommendations());
      }
    },
    [dispatch, fromOnboarding, userHasCompletedOnboarding],
  );

  const { current, pending, recommended } = useMyPlanRecommendations();
  useEffect(() => {
    track('Recommendation received', { recommended });
  }, [track, recommended]);

  const hasPendingPlans = !isEmpty(pending);
  const hasCurrentPlans = !isEmpty(current);
  const isPageLoading = useIsPageLoading(isLoading);
  const showLoading = fromOnboarding ? false : isPageLoading;
  const hasCurrentOrPendingPlans = hasPendingPlans || hasCurrentPlans;
  const hasRecommendations = !isEmpty(recommended);
  return (
    <Page
      isLoading={showLoading}
      backgroundStyle="circle2"
      themeColor={COLORS.jade}
      gradientColor="#d5f0ec"
      renderPageHeader={() => (
        <MyPlanHeader
          hasCurrentOrPendingPlans={hasCurrentOrPendingPlans}
          hasRecommendations={hasRecommendations}
        />
      )}
    >
      <MyPlanContainer>
        {userHasCompletedOnboarding ? (
          <Recommendations
            current={current}
            pending={pending}
            recommended={recommended}
            hasPendingPlans={hasPendingPlans}
            hasCurrentPlans={hasCurrentPlans}
            hasCurrentOrPendingPlans={hasCurrentOrPendingPlans}
            hasRecommendations={hasRecommendations}
          />
        ) : (
          <OnboardingRecCard />
        )}
      </MyPlanContainer>
    </Page>
  );
};

const Recommendations = ({
  current,
  pending,
  recommended,
  hasPendingPlans,
  hasCurrentPlans,
  hasCurrentOrPendingPlans,
  hasRecommendations,
}) => {
  return (
    <>
      {hasCurrentPlans && (
        <>
          <CurrentOrPendingHeader
            weight="medium"
            color="secondary"
            size="small"
          >
            Your current plan:
          </CurrentOrPendingHeader>
          {map(current, cardType => {
            return getRecCard(cardType, { current: true }, { pending: false });
          })}
        </>
      )}
      {hasPendingPlans && (
        <>
          <CurrentOrPendingHeader
            weight="medium"
            color="secondary"
            size="small"
          >
            Pending approval:
          </CurrentOrPendingHeader>
          {map(pending, cardType =>
            getRecCard(cardType, { current: false }, { pending: true }),
          )}
        </>
      )}
      {hasCurrentOrPendingPlans && (
        <SummerRecommendsHeader>
          <Header h2 color="summer">
            Summer recommends:{' '}
          </Header>
        </SummerRecommendsHeader>
      )}
      <TextMargins>
        {hasRecommendations ? <RecommendationsHeader /> : <NoRecommendedView />}
      </TextMargins>
      {map(recommended, cardType =>
        getRecCard(cardType, { current: false }, { pending: false }),
      )}
    </>
  );
};

const CurrentOrPendingHeader = styled(Text)``;

const MyPlanContainer = styled.div`
  width: 100%;
  padding-bottom: 68px;
  margin-top: -108px;
  position: relative;
  z-index: 1;

  @media (max-width: ${MobileSize}) {
    padding: 0;
  }

  > * {
    margin-bottom: 24px;
  }

  ${CurrentOrPendingHeader} {
    margin-bottom: 16px;

    @media (max-width: ${MobileSize}) {
      margin-bottom: 12px;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${MobileSize}) {
    > * {
      margin-bottom: 16px;
    }
  }
`;

const textMargin = `
@media (max-width: ${MobileSize}) {
  margin-right: 8px;
  margin-left: 8px;
}
`;

const SummerRecommendsHeader = styled.div`
  margin: 20px 0 8px;
  ${textMargin}
`;

const TextMargins = styled.div`
  ${textMargin}
`;

const mapStateToProps = state => ({
  isLoading:
    (isLoadingWithInit(state, 'fetchRecommendations') &&
      getUserHasCompletedOnboarding(state)) ||
    isLoadingWithInit(state, 'fetchRepaymentPlanTypes'),
});

export default Track(connect(mapStateToProps)(MyPlan));
