import React from 'react';
import styled from 'styled-components';

import {
  RadioButtons,
  TabletSize,
  MobileSize,
  SmallDesktopSize,
  MoneyInput,
  PercentageInput,
  NumberInput,
} from '@summer/ui-components/src';

export const NumberInputCSP = ({ name, label, placeholder, limit, formik }) => (
  <NumberInput
    name={name}
    label={label}
    placeholder={placeholder}
    limit={limit}
    value={formik.values[name]}
    error={formik.errors[name]}
    touched={formik.touched[name]}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    isSubmitting={formik.isSubmitting}
  />
);

export const MoneyInputCSP = ({ name, label, placeholder, formik, error }) => (
  <MoneyInput
    name={name}
    label={label}
    placeholder={placeholder}
    value={formik.values[name]}
    error={error || formik.errors[name]}
    touched={formik.touched[name]}
    setFieldValue={formik.setFieldValue}
    setFieldTouched={formik.setFieldTouched}
    onBlur={formik.handleBlur}
    isSubmitting={formik.isSubmitting}
  />
);

export const PercentageInputCSP = ({ name, label, formik }) => (
  <PercentageInput
    name={name}
    label={label}
    value={formik.values[name]}
    error={formik.errors[name]}
    touched={formik.touched[name]}
    setFieldValue={formik.setFieldValue}
    setFieldTouched={formik.setFieldTouched}
    onBlur={formik.handleBlur}
    isSubmitting={formik.isSubmitting}
  />
);

export const RadioButtonsCSP = ({
  name,
  options,
  formik,
  error,
  defaultCol,
  smallDesktopCol,
  fontSize,
}) => (
  <StyledRadioButtons
    name={name}
    options={options}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    value={formik.values[name]}
    error={error || formik.errors[name]}
    touched={formik.touched[name]}
    styleProps={{
      defaultCol,
      smallDesktopCol,
      fontSize,
    }}
  />
);

export const StyledRadioButtons = styled(RadioButtons).attrs(
  ({ styleProps = {} }) => {
    const getGridTemplate = numCol => Array(numCol).fill('1fr').join(' ');

    return {
      defaultCol: getGridTemplate(styleProps.defaultCol),
      smallDesktopCol: getGridTemplate(
        styleProps.smallDesktopCol || styleProps.defaultCol,
      ),
      marginTop: styleProps.marginTop || '20px',
      fontSize: styleProps.fontSize || '16px',
    };
  },
)`
  margin-top: ${props => props.marginTop};

  // inner container;
  & > div:first-child {
    margin-top: 0;
    display: grid;
    column-gap: 34px;
    row-gap: 8px;
    grid-template-columns: ${props => props.defaultCol};

    @media (max-width: ${SmallDesktopSize}) {
      grid-template-columns: ${props => props.smallDesktopCol};
    }

    @media (max-width: ${TabletSize}) {
      grid-template-columns: 1fr;
    }

    // buttons
    & > div {
      margin-top: 0;
      margin-right: 0;

      & > div {
        padding: 12px 24px;
        height: 100%;
      }

      // button label
      & > div > div {
        font-weight: 500;
        font-size: ${props => props.fontSize};
      }
    }
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 44px;
  row-gap: 8px;

  & > * {
    align-self: end;
  }

  @media (max-width: ${MobileSize}) {
    grid-template-columns: 1fr;
  }
`;
