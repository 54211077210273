import React from 'react';
import styled from 'styled-components';

import { Text, Strong, Button } from '@summer/ui-components';

import DefaultDrawerContent from 'components/pages/default/wizard/layout/DefaultDrawerContent';
import TextTooltip from 'components/shared/TextTooltip';
import Link from 'components/shared/typography/Link';

export const checklistConfig = {
  header: 'This is your best option if:',
  items: [
    `You haven't completed loan rehabilitation before`,
    'You want to lower collection fees and remove the default from your credit score',
    <>
      You’ve already made payments towards income-driven repayment (IDR) and{' '}
      <TextTooltip text="PSLF is a federal program for borrowers who work in public service. It forgives any remaining federal student debt after 120 qualifying payments while working in public service.">
        Public Service Loan Forgiveness (PSLF)
      </TextTooltip>
    </>,
  ],
};

export const Middle = () => (
  <MiddleContainer>
    <Text>Here&rsquo;s how loan rehabilitation works:</Text>
    <List>
      <Text paragraph medium as="li">
        You&rsquo;ll make nine on-time monthly payments based on 15% of your
        income
      </Text>
      <Text paragraph medium as="li">
        The payments can be as low as $5 per month
      </Text>
      <Text paragraph medium as="li">
        At the end of nine months, the loan will be out of default, some
        collection fees may be waived, and the default (but not late payments)
        will be removed from your credit history
      </Text>
      <Text paragraph medium as="li">
        You can then enroll in an{' '}
        <TextTooltip text="An income-driven repayment plan sets your monthly payment at an amount that is intended to be affordable based on your income and family size.">
          IDR
        </TextTooltip>{' '}
        plan to keep an affordable monthly payment based on your income
      </Text>
    </List>
    <Text paragraph>
      Loan rehabilitation can only be completed once, so it&rsquo;s important to
      avoid defaulting again.
    </Text>
  </MiddleContainer>
);

export const BlueBoxContents = () => (
  <>
    <BlueBoxHeader bold>Before the call, make sure you have:</BlueBoxHeader>
    <List>
      <Text paragraph medium as="li">
        Documentation of your income (e.g., tax return or pay stub)
      </Text>
      <Text paragraph medium as="li">
        An estimate of your monthly expenses
      </Text>
    </List>
    <BlueBoxHeader paragraph>
      <Strong>During the call, use our script</Strong> to help you navigate your
      conversation with your servicer.
    </BlueBoxHeader>
    <Link href="/guides/Loan_Rehabilitation_Guide_vFeb2021.pdf">
      <Button width={265}>Download Conversation Guide</Button>
    </Link>
  </>
);

const LoanRehabilitation = () => (
  <DefaultDrawerContent
    optionName="rehabilitation"
    checklistConfig={checklistConfig}
    middle={<Middle />}
    blueBoxContents={<BlueBoxContents />}
  />
);

const List = styled.ul`
  margin-top: 8px;
  margin-left: 24px;

  li {
    margin-bottom: 12px;
  }
`;

const MiddleContainer = styled.div`
  & > * {
    margin-bottom: 12px;

    :last-child {
      margin-bottom: 24px;
    }
  }
`;

const BlueBoxHeader = styled(Text)`
  margin-bottom: 8px;
`;

export default LoanRehabilitation;
