import React from 'react';
import styled from 'styled-components';
import Cleave from 'cleave.js/react';
import { Field } from 'formik';
import { set } from 'lodash';

import InputContainer, { FormInput } from '../Input';
import { COLORS } from '../../../helpers/constants/styleguide';
import { FlexRow } from '../../Flex';

class NumberInput extends React.Component {
  state = {
    initValue:
      this.props.value || this.props.value === 0 ? this.props.value : '',
  };

  // this code allows formik.resetForm() to work correctly
  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value && value !== this.cleave.getRawValue()) {
      this.cleave.setRawValue(value || value === 0 ? value : '');
    }
  }

  renderInputText = handleFocusChange => {
    const {
      placeholder,
      name,
      onBlur,
      validate,
      onChange,
      limit,
      keepLeadingZeroes,
      decimalScale,
      autoFocus,
    } = this.props;
    return (
      <Container>
        <Field name={name} validate={validate}>
          {() => (
            <CleaveComponent
              placeholder={placeholder}
              inputMode="decimal"
              data-testid={`input-text-${name}`}
              id={`${name}-input`}
              onInit={cleave => {
                this.cleave = cleave;
              }}
              name={name}
              value={this.state.initValue}
              onChange={event => onChange(set(event, 'target.name', name))}
              options={{
                numeral: true,
                numeralThousandsGroupStyle: 'none',
                numeralIntegerScale: limit || null,
                numeralDecimalScale: decimalScale || 0,
                numeralPositiveOnly: true,
                stripLeadingZeroes: !keepLeadingZeroes,
              }}
              onFocus={handleFocusChange}
              onBlur={e => {
                handleFocusChange();
                onBlur(e);
              }}
              autoFocus={autoFocus}
            />
          )}
        </Field>
      </Container>
    );
  };

  render() {
    const { error, touched, height, isSubmitting, label, name } = this.props;
    return (
      <InputContainer
        id={name}
        error={error}
        touched={touched}
        height={height}
        isSubmitting={isSubmitting}
        label={label}
      >
        {this.renderInputText}
      </InputContainer>
    );
  }
}

const Container = styled(FlexRow)`
  height: 100%;
  align-items: center;
`;

const CleaveComponent = styled(FormInput.withComponent(Cleave))`
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  caret-color: ${COLORS.darkerGrey};
  -moz-appearance: textfield;

  &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export default NumberInput;
