import React from 'react';
import styled from 'styled-components';

import { FlexRow, Text } from '@summer/ui-components';

import { MobileSize } from 'constants/styleguide';

import LastUpdated from './LastUpdated';
import UpdateOnboardingModal from './UpdateOnboardingModal';

const NoRecommendedView = ({ lastUpdated }) => {
  return (
    <Container data-testid="rec-card-none">
      <div>
        <NoRecommendationText paragraph bold>
          You&rsquo;re doing great!{' '}
          <span role="img" aria-label="emoji">
            🌟
          </span>{' '}
          We don&rsquo;t have any new recommendations.
        </NoRecommendationText>
        <Text paragraph>
          When your situation changes,{' '}
          <UpdateOnboardingModal text="update your info" /> to receive new
          recommendations.
        </Text>
      </div>
      <LastUpdated lastUpdated={lastUpdated} />
    </Container>
  );
};

const Container = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;

  > * {
    margin-bottom: 8px;
  }
  :last-child {
    margin-bottom: 0;
  }

  > :last-child {
    align-self: flex-start;
  }

  @media (max-width: ${MobileSize}) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const NoRecommendationText = styled(Text)`
  // this fixes a spacing issue with emojis on non-retina screens
  & > span {
    @media not screen and (min-device-pixel-ratio: 2),
      not screen and (min-resolution: 192dpi) {
      display: inline-block;
      width: 2ch;
    }
  }
`;

export default NoRecommendedView;
