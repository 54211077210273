import { map } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import {
  Accordion,
  Header,
  Text,
  Strong,
  SvgImage,
  COLORS,
} from '@summer/ui-components';

import Layout from 'components/pages/default/wizard/layout/layout';
import LoanConsolidation from 'components/pages/default/wizard/steps/DefaultRecommendationsPage/consolidation/LoanConsolidationV1';
import LoanRehabilitation from 'components/pages/default/wizard/steps/DefaultRecommendationsPage/rehabilitation/LoanRehabilitationV1';
import PayOffYourLoans from 'components/pages/default/wizard/steps/DefaultRecommendationsPage/settlement/SettlementV1';
import { getOutOfDefaultBulletsDefault } from 'constants/getOutOfDefault';
import { MobileSize } from 'constants/styleguide';
import { hexToRgba } from 'utils/common';

const config = [
  {
    title: 'Loan Rehabilitation',
    subTitle: 'Make nine monthly payments based on your income',
    content: <LoanRehabilitation />,
  },
  {
    title: 'Loan Consolidation',
    subTitle:
      'Combine into one new loan if you’ve already completed rehabilitation',
    content: <LoanConsolidation />,
  },
  {
    title: 'Pay Off Your Loans',
    subTitle: 'Pay off all or most of your loans in one lump sum',
    content: <PayOffYourLoans />,
  },
];

const LeftSection = () => {
  return (
    <>
      <OptionsHeader h2>Your Options</OptionsHeader>
      <OptionsText>
        For federal student loans, there are three main options available.
      </OptionsText>
      <Accordion config={config} />
    </>
  );
};

export const BoxContent = () => {
  return (
    <>
      <BoxHeader paragraph>
        <Strong>Getting out of default will help you:</Strong>
      </BoxHeader>
      <List>
        {map(getOutOfDefaultBulletsDefault, ({ image, text }) => (
          <ListItem key={text}>
            <ListIcon width={24} SvgComponent={image} />
            <Text paragraph>{text}</Text>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export const DefaultOptionsContent = ({ boxContent }) => {
  return (
    <Layout
      leftSection={<LeftSection />}
      boxContent={boxContent}
      boxColor={hexToRgba(COLORS.jade, 0.1)}
    />
  );
};

const OptionsHeader = styled(Header)`
  margin-bottom: 14px;

  @media (max-width: ${MobileSize}) {
    text-align: center;
  }
`;

const OptionsText = styled(Text)`
  margin-bottom: 16px;
  @media (max-width: ${MobileSize}) {
    text-align: center;
  }
`;

const BoxHeader = styled(Text)`
  margin-bottom: 13px;
`;

const List = styled.ul`
  list-style-type: none;
`;

const ListIcon = styled(SvgImage)`
  margin-right: 10px;
  margin-bottom: auto;
`;

const ListItem = styled.li`
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;

  ${ListIcon} {
    margin-top: 4px;
  }
`;
