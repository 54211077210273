import { createSelector } from 'reselect';

export const getRetirementMatch = state => state.retirementMatch;

export const hasUserConsentedRetirementMatch = createSelector(
  getRetirementMatch,
  retirementMatch => {
    return !!retirementMatch.consentedAt;
  },
);
