import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import {
  FlexColumn,
  FlexRow,
  Text,
  Statistic,
} from '@summer/ui-components/src';

import { percentage } from 'utils/numbers';

import { EmergencySavingsCard } from '../shared';

import SavingsTargetSide from './SavingsTargetSide';

const Progress = ({ value }) => (
  <ProgressContainer>
    <ProgressBar
      value={value}
      role="progressbar"
      aria-valuenow={value}
      aria-valuemin={0}
      aria-valuemax={1}
    />
  </ProgressContainer>
);

const SavingsTargetStatus = ({
  savingsTarget,
  account: { institutionName, logo, name, accountNumber, balance, syncDate },
}) => {
  return (
    <EmergencySavingsCard>
      <Container>
        <Content>
          <Affirmation>
            <span aria-labelledby="affirmation-text" role="img">
              👍
            </span>
            <Text as="span" id="affirmation-text">
              You are almost there
            </Text>
          </Affirmation>
          <Text paragraph>Estimated emergency savings account balance</Text>
          <Statistic format="dollars" value={balance} />
          <Text size="small">
            {percentage(balance / savingsTarget)} completion
          </Text>
          <Progress value={balance / savingsTarget} />
          <AccountInfo role="note">
            <img src={logo} width={40} alt={`${institutionName} logo`} />
            <Text paragraph>
              {institutionName} &bull; {name} &bull; {accountNumber}
            </Text>
            <SyncDate>
              <Text>As of {format(syncDate, 'do MMM yyyy')}</Text>
            </SyncDate>
          </AccountInfo>
        </Content>
        <SavingsTargetSide savingsTarget={savingsTarget} />
      </Container>
    </EmergencySavingsCard>
  );
};

const Affirmation = styled(FlexRow)`
  gap: 4px;
  width: fit-content;
  background-color: #f4f8fa;
  padding: 2px 4px;
`;

const Content = styled(FlexColumn)`
  justify-content: center;
  gap: 12px;
  width: 66%;
`;

const Container = styled(FlexRow)`
  justify-content: space-between;
  gap: 24px;
`;

const AccountInfo = styled(FlexRow)`
  gap: 8px;
  margin-top: 40px;
  align-items: center;
`;

const SyncDate = styled.div`
  margin-left: auto;
`;

const ProgressContainer = styled.div`
  width: 100%;
  height: 6px;
  border-radius: 6px;
  background-color: #f1f2f3;
`;

const ProgressBar = styled.div`
  height: 6px;
  width: ${({ value }) => `${value * 100}%`};
  border-radius: 6px;
  background-color: #38a1ff;
`;

export default SavingsTargetStatus;
