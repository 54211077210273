import { email } from '@hapi/address';
import { reduce, set, some } from 'lodash';
import { number, string, addMethod } from 'yup';

import { requireLoanPayoffDate } from '@simplifidev/shared/dist/utils/idr/requireLoanPayoffDate';

import { plaidFederalLoanSchema } from 'schemas/plaid';

addMethod(string, 'validEmail', function validateEmail(message) {
  return this.test('validEmail', message, function checkEmailValid(value) {
    const { path, createError } = this;

    if (value === undefined || value === null) {
      return true;
    }
    return email.isValid(value) || createError({ path, message });
  });
});

export const validateYupSchema = (values, schema) => {
  const validateData = reduce(
    values,
    (result, value, key) => {
      if (value === '') {
        return set(result, key, undefined);
      }

      return set(result, key, value);
    },
    {},
  );
  return schema.validate(validateData, {
    abortEarly: false,
  });
};

const validationField = schema => value => {
  let error;
  const valueToCheck = value !== '' ? value : undefined;
  try {
    schema.validateSync(valueToCheck, {
      abortEarly: false,
    });
  } catch (err) {
    error = err.message;
  }
  return error;
};

export const validateNumber = (message = null) =>
  validationField(
    number()
      .integer('Please enter a number (without decimals)')
      .max(Number.MAX_SAFE_INTEGER, 'Please enter a lower number')
      .required(message || 'Please enter a number'),
  );
export const validateString = (message = null) =>
  validationField(
    string()
      .nullable()
      .required(message || 'Please pick an answer'),
  );

export const validatePlaidLoanMissingLoanPayoffDate = (
  plaidLoan,
  consolidationIncludedParentPlus,
) => {
  const loanPayoffDate =
    plaidLoan.currentBalance === 0 && plaidLoan.lastPaymentDate
      ? plaidLoan.lastPaymentDate
      : null;

  const includesParentPlus = plaidLoan.federalLoanType.isConsolidation
    ? consolidationIncludedParentPlus
    : false;

  return (
    !loanPayoffDate &&
    requireLoanPayoffDate(
      plaidLoan.dateBorrowed,
      plaidLoan.federalLoanType.payoffEligible,
      includesParentPlus,
      plaidLoan.principalBalance,
    )
  );
};
export const validatePlaidLoansForMissingInfo = (
  plaidFedLoans,
  consolidationIncludedParentPlus,
) => {
  return some(
    plaidFedLoans,
    loan =>
      plaidFederalLoanSchema.isValidSync(loan) === false ||
      validatePlaidLoanMissingLoanPayoffDate(
        loan,
        consolidationIncludedParentPlus,
      ),
  );
};
