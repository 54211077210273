import { push as redirectTo } from 'connected-react-router';
import { get, toString, size, filter, isFunction } from 'lodash';

import { FormStatus } from '@simplifidev/shared/dist/constants/forms';

import {
  FETCH_IDR_PATH_OPTIONS,
  FETCH_IDR_FORMS,
  MOVE_TO_IDR_CURRENT_STEP,
  NETWORK_FAILURE,
  NETWORK_SUCCESS,
  UPDATE_CURRENT_IDR_FORM,
  SAVE_IDR_CURRENT_STEP,
  CREATE_IDR_FORM,
} from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import { setFileCollections } from 'redux/actions/fileCollections.actions';
import { setFinancialProfile } from 'redux/actions/financialProfile.actions';
import {
  moveToIdrCurrentStep,
  updateCurrentIdrForm,
  setCurrentIdrForm,
  setIdrPathOptions,
  clearIdrPathOptions,
  setIdrForms,
} from 'redux/actions/idr.actions';
import { setProfile } from 'redux/actions/profile.actions';
import handleSideEffects from 'redux/handleSideEffects';
import {
  getIdrWizardSteps,
  getCurrentIdrForm,
} from 'redux/selectors/idr.selectors';
import { getUserIsVerified } from 'redux/selectors/user.selectors';
import {
  countPastPayments as countPastPaymentsFlag,
  enablePlaidConsolidation as enablePlaidConsolidationFlag,
} from 'services/featureFlags/flags';
import { ObjectEmptyStringToNull, ObjectNullToEmptyString } from 'utils/common';
import { safeStringToNumber } from 'utils/numbers';

const idrMiddleware = handleSideEffects({
  [FETCH_IDR_PATH_OPTIONS]: (dispatch, { payload: { formId, loanSource } }) => {
    const countPastPayments = countPastPaymentsFlag() === false ? false : null;
    const enablePlaidConsolidation =
      enablePlaidConsolidationFlag() === true ?? false;
    dispatch(
      apiRequest({
        url: `/idr/paths`,
        params: { loanSource, countPastPayments, enablePlaidConsolidation },
        method: 'POST',
        body: { formId },
        fromAction: FETCH_IDR_PATH_OPTIONS,
        label: 'fetchIdrPathOptions',
      }),
    );
  },

  [UPDATE_CURRENT_IDR_FORM]: (
    dispatch,
    {
      payload: { currentStep, form, profile, financialProfile, status },
      meta: { close, finish, onSuccess },
    },
  ) => {
    const requestBody = {
      id: form.formId,
      currentStep: currentStep === null ? currentStep : toString(currentStep),
      form: ObjectEmptyStringToNull(form),
      profile,
      financialProfile: ObjectEmptyStringToNull(financialProfile),
      status,
    };

    dispatch(
      apiRequest({
        url: '/form/idr',
        method: 'PUT',
        fromAction: UPDATE_CURRENT_IDR_FORM,
        body: requestBody,
        label: 'updateCurrentIdrForm',
        meta: {
          nextStep: currentStep,
          close,
          finish,
          onSuccess,
        },
      }),
    );
  },

  [SAVE_IDR_CURRENT_STEP]: (dispatch, { payload: { step } }, state) => {
    const { formId } = getCurrentIdrForm(state);
    dispatch(
      updateCurrentIdrForm({
        currentStep: step,
        form: { formId },
      }),
    );
  },

  [MOVE_TO_IDR_CURRENT_STEP]: (
    dispatch,
    { payload: { step, origin } },
    state,
  ) => {
    dispatch(redirectTo(getIdrWizardSteps(state)[step], { origin }));
  },

  [CREATE_IDR_FORM]: (dispatch, { payload: { currentStep, form } }, state) => {
    const userIsVerified = getUserIsVerified(state);
    if (userIsVerified) {
      dispatch(
        apiRequest({
          url: '/form/idr',
          method: 'POST',
          body: { currentStep, form },
          fromAction: CREATE_IDR_FORM,
          label: 'createIdrForm',
        }),
      );
    }
  },

  [FETCH_IDR_FORMS]: dispatch => {
    dispatch(
      apiRequest({
        url: '/form/idr',
        method: 'GET',
        fromAction: FETCH_IDR_FORMS,
        label: 'fetchIdrForms',
      }),
    );
  },

  [NETWORK_SUCCESS]: (dispatch, { payload, meta }, state) => {
    if (meta.fromAction === FETCH_IDR_PATH_OPTIONS) {
      dispatch(setIdrPathOptions(payload.response));
      return;
    }

    if (meta.fromAction === FETCH_IDR_FORMS) {
      // Throw an error if a user has more than 1 IDR form in progress.
      const numStartedForms = size(
        filter(payload.response.forms, { status: FormStatus.STARTED }),
      );
      if (numStartedForms > 1) {
        throw new Error(
          `User should only have 1 IDR form in progress, but received ${numStartedForms}`,
        );
      }

      // Save subset of data from all forms
      dispatch(setIdrForms(payload?.response?.forms));

      // Set the first form (most recent) onto the current IDR form state
      const genericForm = get(payload, 'response.forms[0]', {});
      const idrForm = ObjectNullToEmptyString(get(genericForm, 'form', {}));
      const fileCollections = get(idrForm, 'supplementalFiles', {});
      const currentStep = safeStringToNumber(genericForm.currentStep);
      const formId = get(genericForm, 'id', null);
      const status = get(genericForm, 'status', null);
      const signedAt = get(genericForm, 'signedAt', null);

      dispatch(
        setCurrentIdrForm({
          ...idrForm,
          currentStep,
          formId,
          status,
          signedAt,
        }),
      );
      dispatch(setFileCollections({ fileCollections }));
    }

    if (
      meta.fromAction === CREATE_IDR_FORM ||
      meta.fromAction === UPDATE_CURRENT_IDR_FORM
    ) {
      const {
        form: idrForm,
        id: formId,
        status,
        profile,
        financialProfile,
      } = payload.response;
      const fileCollections = get(idrForm, 'supplementalFiles', {});

      const currentStep = safeStringToNumber(payload.response.currentStep);

      const { close, finish, onSuccess } = meta;
      dispatch(
        setCurrentIdrForm({
          ...ObjectNullToEmptyString(idrForm),
          currentStep,
          formId,
          status,
        }),
      );
      dispatch(setFileCollections({ fileCollections }));

      if (profile) {
        dispatch(setProfile(ObjectNullToEmptyString(profile)));
      }
      if (financialProfile) {
        dispatch(
          setFinancialProfile(ObjectNullToEmptyString(financialProfile)),
        );
      }

      if (close) {
        if (state?.router?.location?.state?.origin === 'myPlan') {
          dispatch(redirectTo('/plan'));
          return;
        }
        dispatch(redirectTo('/repayment'));
        return;
      }
      if (finish) {
        dispatch(
          redirectTo('/repayment', {
            origin: state?.router?.location?.pathname,
          }),
        );
        return;
      }

      if (isFunction(onSuccess)) {
        onSuccess();
      }

      dispatch(moveToIdrCurrentStep(currentStep));
    }
  },

  [NETWORK_FAILURE]: (dispatch, { meta }) => {
    if (meta.fromAction === FETCH_IDR_PATH_OPTIONS) {
      dispatch(clearIdrPathOptions());
    }
  },
});

export default idrMiddleware;
