import React from 'react';
import styled from 'styled-components';

import { Text, Header } from '@summer/ui-components';

import DefaultDrawerContent from 'components/pages/default/wizard/layout/DefaultDrawerContent';
import TextTooltip from 'components/shared/TextTooltip';

export const checklistConfig = {
  header: 'This is your best option if:',
  items: [
    'You’ve already completed loan rehabilitation once before',
    'You have an older type of loan (like a FFEL or Perkins loan)',
    'Your wages, tax returns, and federal benefits are not being garnished yet',
  ],
};

export const Middle = () => (
  <MiddleContainer>
    <Text>Here&rsquo;s how loan consolidation works:</Text>
    <List>
      <Text paragraph medium as="li">
        You&rsquo;ll submit an application to combine your defaulted loans into
        one new Direct Consolidation loan
      </Text>
      <Text paragraph medium as="li">
        You can also enroll in an{' '}
        <TextTooltip text="An income-driven repayment plan sets your monthly payment at an amount that is intended to be affordable based on your income and family size.">
          income-driven repayment (IDR)
        </TextTooltip>{' '}
        plan to lower your monthly payments
      </Text>
      <Text paragraph medium as="li">
        This option is faster than making the nine months of payments required
        for loan rehabilitation, but it does not reduce collection fees or
        remove the default from your credit history
      </Text>
      <Text paragraph medium as="li">
        Any qualifying payments you&rsquo;ve made toward IDR and{' '}
        <TextTooltip text="PSLF is a federal program for borrowers who work in public service. It forgives any remaining federal student debt after 120 qualifying payments while working in public service.">
          Public Service Loan Forgiveness (PSLF)
        </TextTooltip>{' '}
        will no longer apply to your new Direct Consolidation loan
      </Text>
    </List>
    <Text paragraph>
      Then you should enroll it into an income-driven repayment plan for more
      affordable monthly payments.
    </Text>
  </MiddleContainer>
);

export const BlueBoxContents = () => (
  <BlueBoxContainer>
    <Text bold paragraph>
      Are your paychecks or tax refunds being garnished or withheld?
    </Text>
    <Text paragraph>
      To stop the garnishment, you need first make five consecutive monthly
      payments on a payment plan based on your income before you can consolidate
      your defaulted loans.
    </Text>
    <Text paragraph>
      If you are in this situation, ask your servicer or the Default Resolution
      Group about how to get started.
    </Text>
  </BlueBoxContainer>
);

export const Bottom = () => (
  <BottomContainer>
    <Header h4>Consolidation considerations:</Header>
    <List>
      <Text paragraph medium as="li">
        All unpaid interest will be added to the principal of your new loan
      </Text>
      <Text paragraph medium as="li">
        Unlike loan rehabilitation, loan consolidation will not remove the
        default from your credit history or reduce collection fees
      </Text>
      <Text paragraph medium as="li">
        Any qualifying payments you&rsquo;ve made toward IDR and Public Service
        Loan Forgiveness will no longer apply to your new Direct Consolidation
        loan
      </Text>
    </List>
  </BottomContainer>
);

const LoanConsolidation = () => (
  <DefaultDrawerContent
    optionName="consolidation"
    checklistConfig={checklistConfig}
    middle={<Middle />}
    blueBoxContents={<BlueBoxContents />}
    bottom={<Bottom />}
  />
);

const List = styled.ul`
  margin-left: 24px;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const MiddleContainer = styled.div`
  & > * {
    margin-bottom: 12px;

    :last-child {
      margin-bottom: 24px;
    }
  }
`;

const BlueBoxContainer = styled.div`
  & > * {
    margin-bottom: 8px;
  }
`;

const BottomContainer = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export default LoanConsolidation;
