import React, { Component } from 'react';

import { MoneyInput, Text } from '@summer/ui-components';

import {
  Label,
  LabelContainer,
  Question,
  SmallTextInputContainer,
} from 'components/pages/idr/wizard/shared/styles';
import InfoTooltip from 'components/shared/InfoTooltip';
import { validateNumber } from 'utils/validate';

class JointAdjustedGrossIncome extends Component {
  componentWillUnmount() {
    this.props.formik.setFieldValue(
      `tellUsAboutYou.jointAdjustedGrossIncome`,
      '',
    );
  }

  render() {
    const {
      formik: {
        values,
        errors,
        handleBlur,
        touched,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
      },
    } = this.props;
    return (
      <Question id="jointAdjustedGrossIncome">
        <LabelContainer>
          <Label>
            What was you and your spouse&rsquo;s joint adjusted gross income
            (AGI) as reported on your federal taxes last year?
            <InfoTooltip
              width={300}
              size={14}
              top={3}
              leftOffset={6}
              text={
                <>
                  <Text small>
                    If you have income, then you are going to need to provide
                    proof of this income later by submitting your paystubs or a
                    written statement explaining your income.
                  </Text>
                  <br />
                  <Text small>
                    If you don&apos;t have any income, enter $0. You would be
                    eligible for a $0 monthly payment.
                  </Text>
                </>
              }
            />
          </Label>
        </LabelContainer>
        <SmallTextInputContainer>
          <MoneyInput
            name="tellUsAboutYou.jointAdjustedGrossIncome"
            value={values.jointAdjustedGrossIncome}
            error={errors.jointAdjustedGrossIncome}
            touched={touched.jointAdjustedGrossIncome}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            isSubmitting={isSubmitting}
            onBlur={handleBlur}
            validate={validateNumber()}
          />
        </SmallTextInputContainer>
      </Question>
    );
  }
}

export default JointAdjustedGrossIncome;
