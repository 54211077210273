import React from 'react';
import styled from 'styled-components';

import {
  Text,
  Button,
  COLORS,
  FlexRow,
  MobileSize,
  SmallDesktopSize,
} from '@summer/ui-components';

import { Link } from 'components/shared/typography';

const PlanCard = ({
  name,
  stateTaxBenefitType,
  stateDisplayName,
  taxBenefitAmount,
  moreInfoLink,
  logoSrc,
}) => (
  <PlanContainer>
    <PlanName>
      {logoSrc && <img alt="Plan logo" src={logoSrc} />}
      <Text weight="medium">{name}</Text>
    </PlanName>
    {(stateDisplayName || stateTaxBenefitType || taxBenefitAmount) && (
      <PlanData>
        {stateDisplayName && (
          <PlanAttribute>
            <Text small bold>
              State
            </Text>
            <Text small>{stateDisplayName}</Text>
          </PlanAttribute>
        )}
        {stateTaxBenefitType && (
          <PlanAttribute>
            <Text small bold>
              Tax Benefit Type
            </Text>
            <Text small>{stateTaxBenefitType}</Text>
          </PlanAttribute>
        )}
        {taxBenefitAmount && (
          <PlanAttribute>
            <Text small bold>
              Amount
            </Text>
            <Text small>{taxBenefitAmount}</Text>
          </PlanAttribute>
        )}
      </PlanData>
    )}
    <Link href={moreInfoLink}>
      <Button width={138} secondary>
        More Info
      </Button>
    </Link>
  </PlanContainer>
);

const PlanContainer = styled(FlexRow)`
  border: 1px solid ${COLORS.grey};
  border-radius: 3px;
  background-color: ${COLORS.white};
  padding: 34px 32px;
  margin: 8px 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  @media (max-width: ${SmallDesktopSize}) {
    flex-direction: column;
    align-items: flex-start;
  }

  a {
    align-self: flex-end;
  }
`;

const PlanName = styled(FlexRow)`
  flex: 1;
  align-items: center;
  min-width: 312px;

  img {
    max-height: 40px;
    padding-right: 8px;
    border-right: 1px solid ${COLORS.grey};
  }

  .text:last-child {
    padding-left: 8px;
  }

  @media (max-width: ${MobileSize}) {
    min-width: unset;
    flex-direction: column;
    align-items: flex-start;

    img {
      max-height: 30px;
      padding-right: unset;
      padding-bottom: 8px;
      border-right: unset;
    }
  }
`;

const PlanData = styled(FlexRow)`
  flex: 2;
  justify-content: space-around;
  align-items: center;

  @media (max-width: ${SmallDesktopSize}) {
    width: 100%;
  }
`;

const PlanAttribute = styled.div`
  max-width: 180px;
  margin: 0 8px;

  & > .text:first-child {
    margin-bottom: 4px;
  }
`;

export default PlanCard;
