import { isEmpty, filter } from 'lodash';
import { createSelector } from 'reselect';

import { getPlaidIntegrations } from './plaid.selectors';

export const getPlaidTransactionsData = state => state.plaidTransactions;

const getPlaidPayments = createSelector(
  getPlaidTransactionsData,
  plaidTransactions => {
    const plaidPayments = filter(
      plaidTransactions,
      transaction => transaction.amount < 0,
    );

    return plaidPayments.map(transaction => {
      const { amount } = transaction;

      return {
        ...transaction,
        amount: -amount,
      };
    });
  },
);

export const getMatchedPlaidPayments = createSelector(
  getPlaidPayments,
  getPlaidIntegrations,
  (plaidPayments, plaidIntegrations) => {
    if (isEmpty(plaidPayments) || isEmpty(plaidIntegrations)) {
      return [];
    }

    const matchedPlaidPayments = plaidIntegrations
      .map(integration => {
        const { uuid, institutionId, servicerName } = integration;
        const relatedTransactions = plaidPayments.filter(
          transaction => transaction.plaidIntegrationUuid === uuid,
        );

        return {
          institutionId,
          servicerName,
          transactions: relatedTransactions,
        };
      })
      .filter(({ transactions }) => !isEmpty(transactions));

    return matchedPlaidPayments;
  },
);

export const getPlaidPaymentsByInstitutionId = (state, institutionId) => {
  const integrationsMatchedToTransactions = getMatchedPlaidPayments(state);
  const selectedIntegration = integrationsMatchedToTransactions.find(
    integration => integration.institutionId === institutionId,
  );
  return selectedIntegration?.transactions;
};
