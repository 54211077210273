import {
  SET_SLC_USER,
  FETCH_SLC_USER,
  CREATE_SLC_USER,
} from 'constants/actionTypes';

export const setSlcUser = payload => ({
  type: SET_SLC_USER,
  payload,
});

export const fetchSlcUser = onSuccess => ({
  type: FETCH_SLC_USER,
  onSuccess,
});

export const createSlcUser = payload => onSuccess => ({
  type: CREATE_SLC_USER,
  onSuccess,
  payload,
});
