/* eslint-disable no-console */
import axios from 'axios';

import { S3_REQUEST, UPLOAD_FILE_S3 } from 'constants/actionTypes';
import {
  networkFailure,
  networkPending,
  networkSuccess,
} from 'redux/actions/api.actions';
import {
  clearServerError,
  finishLoading,
  startLoading,
  setS3UploadProgress,
} from 'redux/actions/ui.actions';
import handleSideEffects from 'redux/handleSideEffects';
import { isTestEnv } from 'utils/common';

const s3Middleware = handleSideEffects({
  [S3_REQUEST]: (dispatch, { payload, meta }) => {
    const { url, method = 'PUT', body } = payload;
    dispatch(
      networkPending({
        meta: {
          ...meta,
          request: payload,
        },
      }),
    );

    const onSuccess = response => {
      dispatch(
        networkSuccess({
          response: response.data,
          meta: {
            ...meta,
            request: payload,
          },
        }),
      );

      if (meta.label) {
        dispatch(finishLoading({ label: meta.label }));
      }
    };

    const onFailure = error => {
      const { fromAction } = meta;
      console.log(error);
      console.log(`Error from Action:${fromAction}`);
      dispatch(
        networkFailure({
          error,
          meta: {
            ...meta,
            request: payload,
          },
        }),
      );
      if (meta.label) {
        dispatch(finishLoading({ label: meta.label }));
      }
    };

    if (meta.label) {
      dispatch(startLoading({ label: meta.label }));
      dispatch(clearServerError({ label: meta.label }));
    }

    const onUploadProgress = progressEvent => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      dispatch(
        setS3UploadProgress({
          [meta.uploadPayload.fileName]: percentCompleted,
        }),
      );
    };

    axios
      .request({
        url,
        method,
        data: body,
        ...(meta.fromAction === UPLOAD_FILE_S3 &&
          // TODO: We should be able to remove this once the upload patch is in:
          // https://github.com/mswjs/interceptors/issues/187
          !isTestEnv() && {
            onUploadProgress,
          }),
      })
      .then(onSuccess)
      .catch(onFailure);
  },
});

export default s3Middleware;
