import React from 'react';
import styled from 'styled-components';

import {
  FlexColumn,
  TabletSize,
  MobileSize,
  SmallDesktopSize,
} from '@summer/ui-components';

import { CSPToolLayout } from '../layout';

export const ScholarshipDatabaseLayout = ({ children }) => {
  return (
    <CSPToolLayout title="Scholarship Database">
      <Content>{children}</Content>
    </CSPToolLayout>
  );
};

const Content = styled(FlexColumn)`
  padding: 32px 110px;

  @media (max-width: ${SmallDesktopSize}) {
    padding: 32px 60px;
  }

  @media (max-width: ${TabletSize}) {
    padding: 32px 48px;
  }

  @media (max-width: ${MobileSize}) {
    padding: 32px 24px;
  }
`;
