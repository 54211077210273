import React from 'react';
import styled from 'styled-components';

import {
  FlexCenter,
  Header,
  Text,
  MoreDetails,
  SvgImage,
} from '@summer/ui-components';

import CallOrChat from 'components/shared/ContactUs';
import { Link } from 'components/shared/typography';
import { fsaIdrToolLink } from 'constants/externalLinks/fsa';

const DefaultBlocker = () => (
  <Container data-testid="default-blocker">
    <TopContainer>
      <MoreDetailsIcon SvgComponent={MoreDetails} width={176} />
      <HeaderContainer>
        <Header center>Next steps for your loans</Header>
      </HeaderContainer>
      <TextContainer>
        <Text paragraph>
          It looks like your loan situation is a bit complex — that’s ok! Our
          online tool can’t fully automate this process for you yet, but you can{' '}
          <Link href={fsaIdrToolLink} underline inheritColor>
            use Federal Student Aid’s IDR tool{' '}
          </Link>
          to recertify your current plan, choose a new IDR plan, or enroll in
          IDR.
        </Text>
      </TextContainer>
    </TopContainer>
    <CallOrChat />
  </Container>
);

const HeaderContainer = styled.div`
  margin-bottom: 24px;
`;

const TextContainer = styled.div`
  margin: 0 auto;
`;

const MoreDetailsIcon = styled(SvgImage)`
  display: flex;
  margin-bottom: 40px;
  justify-content: center;
`;

const Container = styled(FlexCenter)`
  margin: 24px auto 0;
  flex-direction: column;
`;

const TopContainer = styled.div`
  max-width: 472px;
  margin-bottom: 40px;
`;

export default DefaultBlocker;
