import { SET_S3_UPLOAD_PROGRESS } from 'constants/actionTypes';

const initialState = {};

const s3UploadProgress = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_S3_UPLOAD_PROGRESS:
      return { ...state, ...payload };

    default:
      return state;
  }
};

export default s3UploadProgress;
