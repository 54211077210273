import styled from 'styled-components';

import { COLORS } from '@summer/ui-components/src';

export const InfoContainer = styled.div`
  box-shadow: 0 0 4px 0 #dfe3e8;

  background-color: ${COLORS.lightestGrey};
  border: 1px solid ${COLORS.lighterGrey};
  padding: 24px;
`;

export const HeaderContainer = styled.div`
  box-shadow: 0 0 4px 0 #dfe3e8;

  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.lighterGrey};
  padding: 24px;
`;

export const InstructionsContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`;
