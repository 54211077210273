import React, { useEffect } from 'react';

import { Text, MoneyInput } from '@summer/ui-components';

import {
  Question,
  Label,
  SmallTextInputContainer,
} from 'components/pages/idr/wizard/shared/styles';
import { validateNumber } from 'utils/validate';

const ExpectedNonUnemploymentIncome = ({
  formik: {
    values,
    errors,
    handleBlur,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
  },
}) => {
  useEffect(
    function clearFieldOnUnmount() {
      return () =>
        setFieldValue(`tellUsAboutYou.expectedNonUnemploymentIncome`, '');
    },
    [setFieldValue],
  );

  return (
    <div>
      <Question id="expectedNonUnemploymentIncome">
        <Label>
          Enter any other taxable income, excluding unemployment income, that
          you&rsquo;re expecting in the next 12 months.
        </Label>
        <Label>
          <Text paragraph as="span">
            Enter $0 if none.
          </Text>
        </Label>
        <SmallTextInputContainer>
          <MoneyInput
            name="tellUsAboutYou.expectedNonUnemploymentIncome"
            value={values.expectedNonUnemploymentIncome}
            error={errors.expectedNonUnemploymentIncome}
            touched={touched.expectedNonUnemploymentIncome}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            isSubmitting={isSubmitting}
            onBlur={handleBlur}
            validate={validateNumber()}
          />
        </SmallTextInputContainer>
      </Question>
    </div>
  );
};

export default ExpectedNonUnemploymentIncome;
