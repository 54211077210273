import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { COLORS } from '@summer/ui-components';

import { getErrorMessage } from 'redux/selectors/ui.selectors';

class GlobalError extends React.Component {
  render() {
    const { errorMessage } = this.props;
    return errorMessage ? (
      <Message dangerouslySetInnerHTML={{ __html: errorMessage }} />
    ) : null;
  }
}

const mapStateToProps = (state, props) => ({
  errorMessage: getErrorMessage(state, props.label),
});

const Message = styled.div`
  font-size: 14px;
  color: ${COLORS.orange};
  width: 100%;
  margin: 8px;
  text-align: left;
  & a {
    font-size: 14px;
    cursor: pointer;
    &:visited {
      color: ${COLORS.orange};
    }
  }
`;

export default connect(mapStateToProps)(GlobalError);
