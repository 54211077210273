import React, { useEffect } from 'react';

import { RadioButtons } from '@summer/ui-components';

import { Question, Label } from 'components/pages/idr/wizard/shared/styles';
import { validateString } from 'utils/validate';

const SpouseReceivingUnemploymentIncome = ({
  formik: {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    isSubmitting,
    setFieldValue,
  },
}) => {
  useEffect(
    function clearFieldOnUnmount() {
      return () =>
        setFieldValue(`tellUsAboutYou.spouseReceivingUnemploymentIncome`, '');
    },
    [setFieldValue],
  );

  return (
    <div>
      <Question id="spouseReceivingUnemploymentIncome">
        <Label>Is your spouse receiving Unemployment Income?</Label>
        <RadioButtons
          row
          design="smallRec"
          name="tellUsAboutYou.spouseReceivingUnemploymentIncome"
          touched={touched.spouseReceivingUnemploymentIncome}
          isSubmitting={isSubmitting}
          error={errors.spouseReceivingUnemploymentIncome}
          value={values.spouseReceivingUnemploymentIncome}
          onChange={handleChange}
          onBlur={handleBlur}
          options={[
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ]}
          validate={validateString()}
        />
      </Question>
    </div>
  );
};

export default SpouseReceivingUnemploymentIncome;
