import { compact } from 'lodash';

import { gpaMapping } from './constants';

export const sortScholarships = scholarships =>
  scholarships.sort(
    (a, b) => new Date(b.lastPublicationDate) - new Date(a.lastPublicationDate),
  );

export const filterScholarships = (filters, scholarships) => {
  const userMaxGpa = gpaMapping[filters.gpa] || null;
  const userStateOfResidence = filters.stateOfResidence;
  const userEthnicity = filters.ethnicity;
  const userInterestAffiliation = filters.interestsAffiliations;

  return scholarships.filter(
    ({
      data: {
        minimumGpa: scholarshipMinGpa,
        state: scholarshipState,
        ethnicities,
        interestsAffiliations,
      },
    }) => {
      const scholarshipInterestsAffiliations = compact(
        interestsAffiliations?.map(
          ({ interest_affiliation: interestAffiliation }) =>
            interestAffiliation,
        ),
      );

      const scholarshipEthnicities = compact(
        ethnicities?.map(({ ethnicity }) => ethnicity),
      );

      if (
        userStateOfResidence &&
        scholarshipState &&
        scholarshipState !== 'national'
      ) {
        return userStateOfResidence === scholarshipState;
      }

      if (userInterestAffiliation && scholarshipInterestsAffiliations?.length) {
        return scholarshipInterestsAffiliations.includes(
          userInterestAffiliation,
        );
      }

      if (userEthnicity && scholarshipEthnicities?.length) {
        return scholarshipEthnicities.includes(userEthnicity);
      }

      if (userMaxGpa && scholarshipMinGpa && userMaxGpa < scholarshipMinGpa) {
        return false;
      }

      return true;
    },
  );
};
