import { SET_IDR_FORMS } from 'constants/actionTypes';

const initialState = [];

const idrFormsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IDR_FORMS: {
      return payload?.formsResponse?.map(formResponse => ({
        formId: formResponse.id,
        status: formResponse.status,
        signedAt: formResponse.signedAt,
        selectedPathPlanType: formResponse.form?.selectedPath?.planType,
        selectedPathFirstPayment: formResponse.form?.selectedPath?.firstPayment,
      }));
    }
    default:
      return state;
  }
};

export default idrFormsReducer;
