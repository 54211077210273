import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { FlexRow, CheckSmall, Text, Icon, COLORS } from '@summer/ui-components';

import {
  getUserIsVerified,
  getUserNewEmail,
} from 'redux/selectors/user.selectors';
import { hexToRgba } from 'utils/common';

const EmailBox = ({ currentEmail, newEmail, isVerified }) => {
  const email = newEmail || currentEmail;

  return (
    <Box>
      <Top>
        <Text bold>Current Email:</Text>
        <VerificationBadge isVerified={isVerified}>
          {isVerified ? (
            <>
              <Check
                height={8}
                SvgComponent={CheckSmall}
                stroke={COLORS.darkerGrey}
              />{' '}
              Verified
            </>
          ) : (
            'Unverified'
          )}
        </VerificationBadge>
      </Top>
      <Text>{email}</Text>
    </Box>
  );
};

const Box = styled.div`
  text-align: left;
  max-width: 360px;
  background-color: ${COLORS.lighterGrey};
  padding: 22px 24px;
  margin: 20px 2px;
  text-decoration: none;
  transition: all 0.3s ease;
  overflow-wrap: break-word;
`;

const Top = styled(FlexRow)`
  justify-content: space-between;
  margin-bottom: 4px;
`;

const VerificationBadge = styled.div`
  border-radius: 3px;
  background-color: ${({ isVerified }) =>
    hexToRgba(isVerified ? COLORS.jade : COLORS.medDarkGrey, '0.06')};
  padding: 0 8px 0;
  font-size: 10px;
  height: 20px;
  font-weight: 600;
  color: ${COLORS.darkerGrey};
  display: flex;
  align-items: center;
`;

const Check = styled(Icon)`
  margin-right: 4px;
`;

const mapStateToProps = state => ({
  newEmail: getUserNewEmail(state),
  isVerified: getUserIsVerified(state),
});

export default connect(mapStateToProps)(EmailBox);
