import { USStatesPostal } from '@simplifidev/shared/dist/constants/states';

import { collegeCountsAlabama529Link } from 'constants/externalLinks/collegeSavingsPlanner';

const StateTaxBenefitTypes = {
  taxDeduction: 'Tax Deduction',
  taxCredit: 'Tax Credit',
  taxParity: 'Tax Parity',
  noStateTax: 'No State Tax',
  noTaxBenefits: 'No Tax Benefits',
};

export const state529Plans = {
  [USStatesPostal.Alabama]: [
    {
      name: 'College Counts - Alabama 529 Fund',
      logoSrc:
        '/images/collegeSavingsPlanner/529marketplace/statePlans/alabama-college-counts.png',
      stateTaxBenefitType: StateTaxBenefitTypes.taxDeduction,
      taxBenefitAmount: '$5,000 filing individually / $10,000 filing jointly',
      moreInfoLink: collegeCountsAlabama529Link,
    },
  ],
};

export const national529Plans = [
  {
    name: 'The Vanguard 529 College Savings Plan',
    logoSrc:
      '/images/collegeSavingsPlanner/529marketplace/nationalPlans/vanguard.png',
    moreInfoLink: '', // TODO [sc-41006] Add link
  },
  {
    name: 'T. Rowe Price College Savings Plan',
    logoSrc:
      '/images/collegeSavingsPlanner/529marketplace/nationalPlans/trowe.png',
    moreInfoLink: '', // TODO [sc-41006] Add link
  },
  {
    name: 'The Schwab 529 Education Savings Plan',
    logoSrc: null,
    moreInfoLink: '', // TODO [sc-41006] Add link
  },
];
