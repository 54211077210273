import React, { Component } from 'react';

import { RadioButtons } from '@summer/ui-components';

import { Question, Label } from 'components/pages/idr/wizard/shared/styles';
import { validateString } from 'utils/validate';

class MaritalStatus extends Component {
  componentWillUnmount() {
    this.props.formik.setFieldValue(`tellUsAboutYou.maritalStatus`, '');
  }

  render() {
    const {
      formik: {
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        isSubmitting,
      },
    } = this.props;
    return (
      <div>
        <Question id="maritalStatus">
          <Label>What is your current marital status?</Label>
          <RadioButtons
            row
            design="smallRec"
            name="tellUsAboutYou.maritalStatus"
            touched={touched.maritalStatus}
            isSubmitting={isSubmitting}
            error={errors.maritalStatus}
            value={values.maritalStatus}
            onChange={handleChange}
            onBlur={handleBlur}
            options={[
              { value: 'single', label: 'Single' },
              { value: 'married', label: 'Married' },
              { value: 'separated', label: 'Married, but separated' },
              {
                value: 'cantAccess',
                label:
                  'Married, but cannot reasonably access my spouse\u2019s income information',
              },
            ]}
            validate={validateString()}
          />
        </Question>
      </div>
    );
  }
}

export default MaritalStatus;
