import React from 'react';
import styled from 'styled-components';

import { FlexRow, Button, COLORS } from '@summer/ui-components';

import { TabletSize } from 'constants/styleguide';
import { helpWidgetSize } from 'services/chat';

const LoanDetailsFooter = ({ handlePrevious, handleNext }) => (
  <Container>
    <Buttons>
      <Button width={112} secondary onClick={handlePrevious}>
        Previous
      </Button>

      <Button width={112} onClick={handleNext}>
        Next
      </Button>
    </Buttons>
  </Container>
);

const Container = styled(FlexRow)`
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 16px 16px 16px 24px;
  align-items: center;
  justify-content: space-between;
  z-index: 1;

  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${COLORS.white};
    opacity: 0.8;
  }

  &:after {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
  }

  @media (max-width: ${TabletSize}) {
    position: static;
    flex-direction: column;
    padding: 24px 16px 16px 16px;
  }
`;

const Buttons = styled(FlexRow)`
  // keeps help widget from overlapping right button
  padding-right: calc(${helpWidgetSize.desktop} + 8px);

  & > button:first-child {
    margin-right: 8px;
  }

  @media (max-width: ${TabletSize}) {
    width: 100%;
    // keeps help widget from overlapping right button
    padding-right: calc(${helpWidgetSize.mobile} + 8px);

    & > button {
      width: 100%;
    }
  }
`;

export default LoanDetailsFooter;
