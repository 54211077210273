import React from 'react';
import styled from 'styled-components';

import { Header, Text } from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import ModalPopup from 'components/shared/ModalPopup';
import { Link } from 'components/shared/typography';
import { fsaPslfEmployerQuestionsLink } from 'constants/externalLinks/fsa';
import { irsEosLink } from 'constants/externalLinks/irs';
import { MobileSize } from 'constants/styleguide';

const ClassificationModal = ({ platformType, ...props }) => {
  const isMobile = platformType === MOBILE;

  return (
    <ModalPopup
      {...props}
      displayStyle="inline-block"
      width={724}
      component={() => {
        return (
          <Container>
            <Header as={isMobile ? 'h2' : 'h1'}>
              Make sure your employer qualifies for Public Service Loan
              Forgiveness
            </Header>

            <Content>
              <Header as="h4"> What is a 501(c)(3) non-profit? </Header>
              <Text as="p" paragraph>
                {' '}
                A 501(c)(3) is a specific type of organization that is exempt
                from federal income tax under section 501(c)(3) of the United
                States Code. 501(c)(3) tax-exemptions apply to entities that are
                operated exclusively for charitable, religious, scientific,
                literary, or educational purposes.
              </Text>
              <Header as="h4">
                {' '}
                How can I be sure my employer is a 501(c)(3)?{' '}
              </Header>
              <Text as="p" paragraph>
                It&rsquo;s best to ask your employer directly &mdash; preferably
                someone in HR. You can also check the IRS website to&nbsp;
                <Link inheritColor underline href={irsEosLink}>
                  confirm that your employer is listed
                </Link>{' '}
                under tax-exempt status.
              </Text>
              <Header as="h4">
                {' '}
                Do contractors count as government organizations?{' '}
              </Header>
              <Text as="p" paragraph>
                {' '}
                This is a critical requirement for PSLF, and arguably one of the
                most confusing. Even if you are working at a government or
                501(c)(3) non-profit employer, you will not qualify for PSLF if
                you&rsquo;re being paid by a private entity, such as a private
                contractor or a private company.
              </Text>
              <Header as="h4">
                {' '}
                Do AmeriCorps and the Peace Corps qualify?{' '}
              </Header>
              <Text as="p" paragraph>
                They both qualify for PSLF! You can select &quot;Government
                organization&quot; in our tool to continue.{' '}
              </Text>
              <Header as="h4">What if my non-profit is not a 501(c)(3)?</Header>
              <Text as="p" paragraph>
                {' '}
                The Dept. of Education allows a small number of private
                non-profits that provide certain public services to qualify for
                PSLF.{' '}
                <Link
                  inheritColor
                  underline
                  href={fsaPslfEmployerQuestionsLink}
                >
                  Learn more.
                </Link>{' '}
              </Text>
            </Content>
          </Container>
        );
      }}
    >
      <Link inheritColor underline>
        Learn more
      </Link>
    </ModalPopup>
  );
};

const Container = styled.div`
  padding: 0px 16px 24px 16px;
  @media (max-width: ${MobileSize}) {
    overflow: scroll;
    flex-direction: column;
    padding: 0px;
  }
`;

const Content = styled.div`
  margin-top: 16px;

  p {
    margin-bottom: 16px;
  }
`;

export default Platform(ClassificationModal);
