import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { FlexRow, Button, COLORS } from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import { xPaddingDesktop, xPaddingMobile } from 'constants/manageLoans';
import { MobileSize, TabletSize } from 'constants/styleguide';
import { prevLoan } from 'redux/actions/ui.actions';
import {
  getLoanFormNumberOfLoans,
  isLoanEditable,
  getCurrentLoanIdx,
} from 'redux/selectors/loans.selectors';
import { helpWidgetSize } from 'services/chat';
import { hexToRgba } from 'utils/common';
import { scrollToError } from 'utils/formik';

const LoansModalFooter = ({
  formik,
  currentLoanIdx,
  numberOfLoans,
  isLoading,
  loanIsEditable,
  dispatch,
  innerRef,
  platformType,
  reanimateAlert,
}) => {
  const { isValid } = formik;
  const isFinalLoan = currentLoanIdx === numberOfLoans - 1;
  const isNotFirstLoan = currentLoanIdx > 0;

  const isMobile = platformType === MOBILE;

  const areButtonsDisabled = !isValid;

  return (
    <Footer ref={innerRef}>
      {!isMobile && isNotFirstLoan && (
        <Wrapper className="back">
          <Button
            secondary
            type="button"
            isLoading={isLoading}
            width={160}
            onClick={() => dispatch(prevLoan())}
          >
            <div>Back</div>
          </Button>
        </Wrapper>
      )}

      <Wrapper className="save-close">
        {!isFinalLoan && (
          <Button
            type="button"
            secondary
            fakeDisabled={areButtonsDisabled}
            isLoading={isLoading}
            onClick={e => {
              reanimateAlert();
              scrollToError(formik.errors);
              formik.setFieldValue('onSuccess', 'closeManageLoans', false);
              formik.handleSubmit(e);
            }}
          >
            <div>{loanIsEditable ? 'Save & ' : ''}Close</div>
          </Button>
        )}
      </Wrapper>

      <Wrapper className="save-next">
        <Button
          type="button"
          fakeDisabled={areButtonsDisabled}
          isLoading={isLoading}
          onClick={e => {
            reanimateAlert();
            scrollToError(formik.errors);
            formik.setFieldValue('onSuccess', 'nextLoan', false);
            formik.handleSubmit(e);
          }}
        >
          <div>
            {isFinalLoan
              ? `${loanIsEditable ? 'Save & ' : ''}Done`
              : `${loanIsEditable ? 'Save & ' : ''}Next`}
          </div>
        </Button>
      </Wrapper>
    </Footer>
  );
};

const Footer = styled(FlexRow)`
  flex: 0;

  justify-content: flex-end;
  align-items: stretch;
  position: absolute;
  bottom: 0;
  width: 100%;

  padding-left: ${xPaddingDesktop};
  padding-right: ${xPaddingDesktop};
  padding-bottom: 48px;
  padding-top: 8px;

  background-color: ${hexToRgba(COLORS.white, 0.8)};
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);

  @media (max-width: ${TabletSize}) {
    padding-right: calc(8px + ${helpWidgetSize.desktop});
    padding-bottom: 10px;
  }

  @media (max-width: ${MobileSize}) {
    padding-left: ${xPaddingMobile};
    padding-right: calc(${xPaddingMobile} + ${helpWidgetSize.mobile});
  }
`;

const Wrapper = styled.div`
  max-width: 50%;

  & > button {
    height: 100%;
    width: 100%;
  }

  &.back {
    padding-right: 50%;
  }

  &.save-close,
  &.save-next {
    width: 25%;
    padding: 0 6px;

    @media (max-width: ${MobileSize}) {
      width: 50%;
    }
  }
`;

const mapStateToProps = state => ({
  currentLoanIdx: getCurrentLoanIdx(state),
  numberOfLoans: getLoanFormNumberOfLoans(state),
  loanIsEditable: isLoanEditable(state),
});

export default Platform(connect(mapStateToProps)(LoansModalFooter));
