import React, { useEffect } from 'react';

import { DateInput } from '@summer/ui-components';

import {
  Question,
  Label,
  SmallTextInputContainer,
} from 'components/pages/idr/wizard/shared/styles';
import { validateString } from 'utils/validate';

const UnemploymentEndDate = ({
  formik: { values, errors, handleBlur, touched, isSubmitting, setFieldValue },
}) => {
  useEffect(
    function clearFieldOnUnmount() {
      return () => setFieldValue(`tellUsAboutYou.unemploymentEndDate`, '');
    },
    [setFieldValue],
  );

  return (
    <div>
      <Question id="unemploymentEndDate">
        <Label>When do you expect your unemployment income to end?</Label>
        <SmallTextInputContainer>
          <DateInput
            name="tellUsAboutYou.unemploymentEndDate"
            touched={touched.unemploymentEndDate}
            isSubmitting={isSubmitting}
            error={errors.unemploymentEndDate}
            value={values.unemploymentEndDate}
            onChange={setFieldValue}
            onBlur={handleBlur}
            validate={validateString('Please enter a valid date')}
          />
        </SmallTextInputContainer>
      </Question>
    </div>
  );
};

export default UnemploymentEndDate;
