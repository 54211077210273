import { Formik } from 'formik';
import { filter, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { RadioButtons } from '@summer/ui-components';

import { usePlatform } from 'hooks/platform';
import { fetchRepaymentPlanTypes } from 'redux/actions/constants.actions';
import { updateCurrentIdrForm } from 'redux/actions/idr.actions';
import { getIdrRepaymentPlanTypeId } from 'redux/selectors/financialProfile.selectors';
import {
  getCurrentIdrFormId,
  getCurrentIdrFormStep,
} from 'redux/selectors/idr.selectors';
import { getRepaymentPlanTypes } from 'redux/selectors/loans.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { idrRepaymentPlanTypeIdSchema } from 'schemas/idr';

import Footer from '../Footer';
import { FormContents, Label } from '../shared';

const PlanType = ({
  currentIdrFormStep,
  fetchRepaymentPlanTypes,
  formId,
  idrRepaymentPlanTypeId,
  idrPlanTypes,
  isLoading,
  isLoadingRepaymentPlanTypes,
  navigation,
  updateCurrentIdrForm,
}) => {
  const { isMobile } = usePlatform();

  useEffect(
    function getRepaymentPlanTypes() {
      if (isEmpty(idrPlanTypes) && !isLoadingRepaymentPlanTypes) {
        fetchRepaymentPlanTypes();
      }
    },
    [fetchRepaymentPlanTypes, idrPlanTypes, isLoadingRepaymentPlanTypes],
  );

  const handleSave = ({ idrRepaymentPlanTypeId }) => {
    // Update financial profile
    updateCurrentIdrForm(
      {
        currentStep: currentIdrFormStep,
        form: { formId },
        financialProfile: { idrRepaymentPlanTypeId },
      },
      { close: false, finish: false, onSuccess: navigation.next },
    );
  };

  return (
    <Formik
      initialValues={{ idrRepaymentPlanTypeId }}
      onSubmit={handleSave}
      validationSchema={idrRepaymentPlanTypeIdSchema}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        isValid,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormContents>
              <Label
                h2
                align={isMobile ? 'center' : 'left'}
                id="idrRepaymentPlanTypeId"
              >
                Which income-driven repayment (IDR) plan are you enrolled in?
              </Label>
              <PlanTypeRadioButtons
                name="idrRepaymentPlanTypeId"
                value={values.idrRepaymentPlanTypeId}
                error={errors?.idrRepaymentPlanTypeId}
                touched={touched.idrRepaymentPlanTypeId}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                numberOfCol={1}
                isSubmitting={isSubmitting}
                onBlur={handleBlur}
                options={idrPlanTypes.map(plan => {
                  return {
                    value: plan.id.toString(),
                    label: `${plan.name.toLowerCase()} (${plan.shortName})`,
                  };
                })}
              />
            </FormContents>
            <Footer
              handleBack={navigation.back}
              isBackLoading={isLoading}
              isNextDisabled={!isValid}
              isNextLoading={isLoading}
              isValid={isValid}
            />
          </form>
        );
      }}
    </Formik>
  );
};

const PlanTypeRadioButtons = styled(RadioButtons)`
  text-transform: capitalize;
`;

const idrPlanTypesKeys = [
  'REVISED PAY AS YOU EARN PLAN',
  'PAY AS YOU EARN REPAYMENT',
  'INCOME-BASED REPAYMENT',
  'INCOME-CONTINGENT REPAYMENT',
];

const mapStateToProps = state => ({
  currentIdrFormStep: getCurrentIdrFormStep(state),
  formId: getCurrentIdrFormId(state),
  idrPlanTypes: filter(getRepaymentPlanTypes(state), plan =>
    idrPlanTypesKeys.includes(plan.key),
  ),
  idrRepaymentPlanTypeId: getIdrRepaymentPlanTypeId(state).toString(),
  isLoading: isLoading(state, 'updateCurrentIdrForm'),
  isLoadingRepaymentPlanTypes: isLoading(state, 'fetchRepaymentPlanTypes'),
});

export default connect(mapStateToProps, {
  fetchRepaymentPlanTypes,
  updateCurrentIdrForm,
})(PlanType);
