import React from 'react';
import styled from 'styled-components';

import { Strong, Header, Text, COLORS } from '@summer/ui-components';

import {
  List,
  ListItem,
  SmallTitle,
  GuideText,
} from 'components/pages/resourceCenter/guides/shared';
import Section from 'components/pages/resourceCenter/guides/shared/Section';
import { Link } from 'components/shared/typography';
import { idrTaxCopy } from 'constants/idr';
import { MobileSize } from 'constants/styleguide';

class IDRGuide extends React.Component {
  render() {
    return (
      <>
        <Section title="Overview">
          <GuideText>
            An income-driven repayment (IDR) plan could help you cut your
            monthly payments, tying the amount you have to pay to the amount you
            earn, which can help you handle your debt your way. Let&rsquo;s go
            over some of the details to see if income-based repayment could work
            for you.
          </GuideText>

          <SmallTitle>What is an income-driven repayment plan?</SmallTitle>
          <GuideText>
            An income-driven repayment (IDR) plan is a repayment plan for people
            with federal loans designed to make your monthly loan payments more
            affordable. income-driven repayment plans don&rsquo;t cover private
            loans.
          </GuideText>

          <GuideText>
            Income-driven plans base your monthly payments on how much money you
            make. But if you&rsquo;re still figuring out the whole
            &quot;career&quot; thing, don&rsquo;t worry; you can still qualify
            for income-driven repayment. If you don&rsquo;t have a job, or if
            your income is low enough, you can bring your payment down to $0.
            Really.
          </GuideText>

          <GuideText>There are 4 types of income-driven plans:</GuideText>
          <List>
            <ListItem>Save on a Valuable Education (SAVE),</ListItem>
            <ListItem>Pay as you Earn (PAYE),</ListItem>
            <ListItem>Income-Based Repayment (IBR),</ListItem>
            <ListItem>and Income-Contingent Repayment (ICR).</ListItem>
          </List>

          <SmallTitle>Is it right for me?</SmallTitle>
          <GuideText>
            If you have federal loans and you want to lower your monthly
            payments (or if you&rsquo;re having trouble making your monthly
            payments), income-driven plans are usually a good option. These
            plans are a great way to make sure that you don&rsquo;t a miss a
            payment on your federal loans. If you do not have a job right now,
            income-driven plans are a good option for you because your monthly
            payment would be $0. You can also always change plans as your life
            changes so you&rsquo;re not locked into a plan that doesn&rsquo;t
            work for you.
          </GuideText>

          <SmallTitle>Okay, what’s the catch?</SmallTitle>
          <GuideText>
            If you enroll in a income-driven repayment plan, you could end up
            paying more over the life of your loan than you would normally.
            That&rsquo;s a small price to pay, though, if it means that you can
            make all your payments on time. Missing a loan payment can really
            hurt your credit score, keeping you from reaching goals like renting
            an apartment or getting a new car. Enrolling in an IDR plan will let
            you stay up to date with making your payments and help you build
            your credit score.
          </GuideText>
        </Section>

        <Section title="Advantages">
          <GuideText>
            Now that you know what income-driven repayment plans are,
            we&rsquo;ll cover some of the ways these plans can help you out when
            it comes to paying down your debt.
          </GuideText>

          <SmallTitle>Your monthly payment could be lower</SmallTitle>
          <GuideText>
            Income-driven plans could help you bring down your monthly payments.
            Don&rsquo;t make a lot of money? Out of work? Your payment could
            even be as low as $0 if you can&rsquo;t afford to make payments.
          </GuideText>

          <SmallTitle>Your monthly payments change with your income</SmallTitle>
          <GuideText>
            With an income-driven repayment plan, your monthly payment changes
            along with how much money you earn, so you never pay more than you
            can afford. Your payment is based on 5-20% of your discretionary
            income, depending on the plan that you&rsquo;re enrolled in and your
            loan type. If you don&rsquo;t earn any income, your monthly payment
            would be $0.
          </GuideText>

          <GuideText>
            What is your discretionary income? The U.S. Department of Education
            considers your discretionary income to be your gross, after tax
            income minus the poverty guidelines for your family size.
            Summer&rsquo;s free tool can help you We&rsquo;ll help estimate how
            much you would be paying under an income-driven repayment plan based
            on your discretionary income.
          </GuideText>

          <SmallTitle>Your loans can be forgiven</SmallTitle>
          <GuideText>
            If you&rsquo;ve still got a loan balance after 20 or 25 years of
            qualifying repayment (depending on the plan), the remaining balance
            could be forgiven as part of an income-driven repayment plan. That
            means you won&rsquo;t have to pay it back.
          </GuideText>

          <GuideText>
            With the SAVE plan, it&rsquo;s possible to get forgiveness sooner
            depending on your loan balance.
          </GuideText>

          <SmallTitle>You can get interest benefits</SmallTitle>
          <GuideText>
            If your monthly payment doesn’t cover the full amount of interest
            added to your loans that month, most income-driven repayment plans
            also offer what&rsquo;s called an &quot;interest benefit.&quot; That
            means that, depending on which plan you’re enrolled in, you could be
            off the hook for paying off all of the interest on your loans for a
            certain period.
          </GuideText>
        </Section>

        <Section title="Disadvantages">
          <GuideText>
            You know the basics and the benefits of going with an income-driven
            repayment plan, but what are the drawbacks of these plans? Time to
            look at the downsides so you can make the best decision for you
            (with our help, of course).
          </GuideText>

          <SmallTitle>You&rsquo;ll pay more overall in interest</SmallTitle>
          <GuideText>
            If you enroll in an income-driven plan and cut your monthly
            payments, you may pay more interest over a longer period of time
            than you would with a standard 10-year repayment plan. But paying
            more overall may be worth it to you if it means lowering your
            monthly payment now so you can make payments on time.
          </GuideText>

          <SmallTitle>You&rsquo;ll pay for a longer period of time</SmallTitle>
          <GuideText>
            Income-driven plans extend the term of your loan to between 20 and
            25 years, which is twice as long as the standard 10-year plan. But
            during that time, you&rsquo;ll pay less each month, helping you
            manage your loans in a way that works better for you.
          </GuideText>

          <SmallTitle>The amount forgiven may be taxed</SmallTitle>
          <GuideText>
            At the end of the 20-25 years of income-driven repayment, if
            you&rsquo;ve still got a loan balance and it&rsquo;s forgiven (which
            would be great, right?), you may still need to pay income tax on the
            amount that&rsquo;s forgiven (less great).
          </GuideText>
          <GuideText>{idrTaxCopy}</GuideText>
        </Section>

        <Section title="Eligibility">
          <GuideText>
            With the pros and cons of income-driven repayment covered,
            let&rsquo;s go over what it takes to be eligible for these programs.
          </GuideText>
          <SmallTitle>Different plans have different eligibility</SmallTitle>
          <GuideText>
            Which income-driven plans you&rsquo;re eligible for depends on two
            things: your income levels compared to your debt and the type of
            federal loans you have.
          </GuideText>
          <SmallTitle>Income compared to debt</SmallTitle>
          <GuideText>
            You don&rsquo;t have to earn a certain amount of money each year to
            be eligible for any of the four income-driven repayment plans. Your
            servicer will determine whether you’re eligible: for PAYE and IBR,
            they&rsquo;ll compare the amount you earn to the amount of your
            loan. You&rsquo;ll likely be eligible if your monthly payments on a
            PAYE or IBR plan would be less than what they&rsquo;d be under a
            standard 10-year plan. If you’d like to estimate your repayment
            amount, you can use Summer&rsquo;s free payment estimator tool.
          </GuideText>
          <SmallTitle>Type of loans</SmallTitle>
          <GuideText>
            Only certain types of loans are eligible for each of the four
            income-driven repayment plans. Check out the chart below to find out
            which loans are eligible for each of the four different plan types.
            One other thing: keep in mind that to be eligible, your loans
            can&rsquo;t be in default.
          </GuideText>

          <GuideText>
            <Strong>Which of my loans are eligible?</Strong>
          </GuideText>

          <Table>
            <TableColumn>
              <Header h4>SAVE</Header>
              <Text small>Save on a Valuable Education</Text>
              <ColumnContent>
                <Text small paragraph color="secondary">
                  All direct loans except Parent PLUS and consolidation loans
                  that repaid Parent PLUS.
                </Text>
              </ColumnContent>
            </TableColumn>
            <TableColumn>
              <Header h4>PAYE</Header>
              <Text small>Pay As You Earn</Text>
              <ColumnContent>
                <Text small paragraph color="secondary">
                  All direct loans that have been disbursed after October 1,
                  2011 except Parent PLUS and consolidation loans that repaid
                  Parent PLUS.
                </Text>
              </ColumnContent>
              <ColumnContent>
                <Text small paragraph color="secondary">
                  Also, to be eligible, you can&rsquo;t have a federal loan
                  outstanding that you took out before October 1, 2007.
                </Text>
              </ColumnContent>
            </TableColumn>
            <TableColumn>
              <Header h4>IBR</Header>
              <Text small>Income-Based Repayment</Text>
              <ColumnContent>
                <Text small paragraph color="secondary">
                  All direct loans and FFEL loans except Parent PLUS and
                  consolidation loans that repaid Parent PLUS.
                </Text>
              </ColumnContent>
            </TableColumn>
            <TableColumn>
              <Header h4>ICR</Header>
              <Text small>Income-Contingent Repayment</Text>
              <ColumnContent>
                <Text small paragraph color="secondary">
                  All direct loans except Parent PLUS loans. Consolidation loans
                  made after July 1, 2006 that repaid Parent PLUS loans are
                  eligible.
                </Text>
              </ColumnContent>
            </TableColumn>
          </Table>

          <GuideText>
            <Strong>Who is eligible as a borrower?</Strong>
          </GuideText>

          <Table>
            <TableColumn>
              <Header h4>SAVE</Header>
              <Text small>Save on a Valuable Education</Text>
              <ColumnContent>
                <Text small paragraph color="secondary">
                  All borrowers.
                </Text>
              </ColumnContent>
            </TableColumn>
            <TableColumn>
              <Header h4>PAYE</Header>
              <Text small>Pay As You Earn</Text>
              <ColumnContent>
                <Text small paragraph color="secondary">
                  Borrowers with low income compared to eligible student loans.
                </Text>
              </ColumnContent>
            </TableColumn>
            <TableColumn>
              <Header h4>IBR</Header>
              <Text small>Income-Based Repayment</Text>
              <ColumnContent>
                <Text small paragraph color="secondary">
                  Borrowers with low income compared to eligible student loans.
                </Text>
              </ColumnContent>
            </TableColumn>
            <TableColumn>
              <Header h4>ICR</Header>
              <Text small>Income-Contingent Repayment</Text>
              <ColumnContent>
                <Text small paragraph color="secondary">
                  All borrowers.
                </Text>
              </ColumnContent>
            </TableColumn>
          </Table>
        </Section>

        <Section title="Payment Amount">
          <GuideText>
            With income-driven repayment plans, your monthly payments are tied
            to how much money you earn, but how much will you actually be paying
            and how is that number calculated? Let&rsquo;s go through it.
          </GuideText>
          <SmallTitle>Your monthly payment changes with your income</SmallTitle>
          <GuideText>
            Your monthly payment will be based on 5-20% of your discretionary
            income, depending on which plan that you&rsquo;re enrolled in and
            your loan type. If you&rsquo;re not making any money, your monthly
            payment would be $0. Seriously.
          </GuideText>
          <SmallTitle>What is discretionary income?</SmallTitle>
          <GuideText>
            Your discretionary income, as calculated by the U.S. Department of
            Education, is your gross, after tax income minus the poverty
            guidelines for your family size. No worries about remembering this
            now; we&rsquo;ll help you figure this out when it&rsquo;s time for
            you to enroll.
          </GuideText>
          <SmallTitle>How can I calculate my monthly payment?</SmallTitle>
          <GuideText>
            Check out the chart below to figure out how much you&rsquo;d be
            paying under each income-driven repayment plan. If you’d like to
            estimate how much you&rsquo;d be paying right now, you can use
            Summer&rsquo;s free tools for borrowers.
          </GuideText>

          <Table>
            <TableColumn>
              <Header h4>SAVE</Header>
              <Text small>Save on a Valuable Education</Text>
              <ColumnContent>
                <Text small paragraph color="secondary">
                  10% of your discretionary income.
                </Text>
                <Text small paragraph color="secondary">
                  5% of discretionary income for undergraduate loans starting
                  July 2024.
                </Text>
              </ColumnContent>
            </TableColumn>
            <TableColumn>
              <Header h4>PAYE</Header>
              <Text small>Pay As You Earn</Text>
              <ColumnContent>
                <Text small paragraph color="secondary">
                  10% of your discretionary income.
                </Text>
              </ColumnContent>
            </TableColumn>
            <TableColumn>
              <Header h4>IBR</Header>
              <Text small>Income-Based Repayment</Text>
              <ColumnContent>
                <Text small paragraph color="secondary">
                  10% of your discretionary income if you obtained your first
                  loan after July 1, 2014. Otherwise, it&rsquo;s 15% of your
                  discretionary income.
                </Text>
              </ColumnContent>
            </TableColumn>
            <TableColumn>
              <Header h4>ICR</Header>
              <Text small>Income-Contingent Repayment</Text>
              <ColumnContent>
                <Text small paragraph color="secondary">
                  20% of your discretionary income.
                </Text>
              </ColumnContent>
            </TableColumn>
          </Table>

          <GuideText>
            <Strong>What&rsquo;s the most I could be paying?</Strong>
          </GuideText>

          <Table>
            <TableColumn>
              <Header h4>SAVE</Header>
              <Text small>Save on a Valuable Education</Text>
              <ColumnContent>
                <Text small paragraph color="secondary">
                  If your income increases over a certain level, it&rsquo;s
                  possible that your monthly payments exceed your 10-year
                  standard payment amount.
                </Text>
              </ColumnContent>
            </TableColumn>
            <TableColumn>
              <Header h4>PAYE</Header>
              <Text small>Pay As You Earn</Text>
              <ColumnContent>
                <Text small paragraph color="secondary">
                  Never more than what you would have paid with a standard
                  10-year repayment plan.
                </Text>
              </ColumnContent>
            </TableColumn>
            <TableColumn>
              <Header h4>IBR</Header>
              <Text small>Income-Based Repayment</Text>
              <ColumnContent>
                <Text small paragraph color="secondary">
                  Never more than what you would have paid with a standard
                  10-year repayment plan.
                </Text>
              </ColumnContent>
            </TableColumn>
            <TableColumn>
              <Header h4>ICR</Header>
              <Text small>Income-Contingent Repayment</Text>
              <ColumnContent>
                <Text small paragraph color="secondary">
                  Never more than what you would have paid on a repayment plan
                  with fixed payment over 12 years, adjusted to income. If your
                  income increases over a certain level, it&rsquo;s possible
                  your monthly payments exceed your 10-year standard repayment
                  plan amount.
                </Text>
              </ColumnContent>
            </TableColumn>
          </Table>
        </Section>

        <Section title="Apply">
          <SmallTitle>When to apply</SmallTitle>
          <GuideText>
            If you left school recently, you can&rsquo;t apply until 4 months
            after that. Otherwise, you can apply any time. One thing to
            remember, though: it can take your servicer up to 2 months to
            process your application.
          </GuideText>

          <SmallTitle>How to apply</SmallTitle>
          <GuideText>
            You can submit an application by{' '}
            <Link to="/repayment">using our online tool.</Link> We&rsquo;ll walk
            you through the steps, help you determine the cost of the plans
            you&rsquo;re eligible for, and get your enrolled.
          </GuideText>

          <SmallTitle>Proving how much you earn</SmallTitle>
          <GuideText>
            You&rsquo;ll need to submit proof of how much you make, typically in
            the form of your most recent federal income tax return. If how much
            you earn has changed recently, you may need to submit alternative
            forms of documentation like your most recent pay stubs.
          </GuideText>

          <SmallTitle>Free to apply</SmallTitle>
          <GuideText>
            It&rsquo;s free for you to enroll in federal income-driven repayment
            plans. Pretty great, huh? Since it&rsquo;s free, make sure you
            don&rsquo;t get fooled by a third party asking for money to process
            your application for you. You&rsquo;ve got this on your own.
          </GuideText>

          <SmallTitle>Reapplying every year</SmallTitle>
          <GuideText>
            Once you are enrolled, you&rsquo;re going to need to submit your
            application again every year to confirm how much you&rsquo;re making
            at the time. It&rsquo;s important to reapply every year so that you
            can keep your lower monthly payments and keep interest from adding
            up on your loan balance.
          </GuideText>
        </Section>
      </>
    );
  }
}

const Table = styled.div`
  display: flex;
  border: solid 1px ${COLORS.grey};
  background-color: #fff;
  margin-bottom: 20px;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
  }
`;

const TableColumn = styled.div`
  border-right: solid 1px ${COLORS.grey};
  flex: 1 1 auto;
  width: 25%;
  padding: 15px 12px;

  @media (min-width: ${MobileSize}) {
    &:last-child {
      border-right: none;
    }
  }

  @media (max-width: ${MobileSize}) {
    width: unset;
  }
`;

const ColumnContent = styled.div`
  margin-top: 8px;
`;

export default IDRGuide;
