import React, { Component } from 'react';

import { NumberInput } from '@summer/ui-components';

import {
  Label,
  Question,
  SmallTextInputContainer,
} from 'components/pages/idr/wizard/shared/styles';
import { validateNumber } from 'utils/validate';

class NumberOfKids extends Component {
  componentWillUnmount() {
    this.props.formik.setFieldValue(`tellUsAboutYou.numberOfKids`, '');
  }

  render() {
    const {
      formik: {
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        isSubmitting,
      },
    } = this.props;

    return (
      <Question id="numberOfKids">
        <Label>
          How many kids receive more than half of their support from you?
          (Include any kids you’re currently expecting.) Enter 0 if you do not
          have any.
        </Label>
        <SmallTextInputContainer>
          <NumberInput
            name="tellUsAboutYou.numberOfKids"
            value={values.numberOfKids}
            touched={touched.numberOfKids}
            error={errors.numberOfKids}
            isSubmitting={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Number of children"
            validate={validateNumber()}
          />
        </SmallTextInputContainer>
      </Question>
    );
  }
}

export default NumberOfKids;
