import React from 'react';

import { useClearFieldOnUnmount } from 'hooks/formik';

import { MoneyInputCSP } from '../shared';

const EnterCollegeCost = ({ formik }) => {
  useClearFieldOnUnmount('yearlyTuition', null, formik.setFieldValue);

  return (
    <MoneyInputCSP
      name="yearlyTuition"
      label="College Cost"
      placeholder=" Enter your tuition cost here"
      formik={formik}
    />
  );
};

export default EnterCollegeCost;
