import React from 'react';
import styled from 'styled-components';

import { FlexColumn } from '@summer/ui-components';

import ConnectFsaBody from 'components/common/SyncLoans/ConnectFsa/ConnectFsaBody';
import ConnectFsaHeader from 'components/common/SyncLoans/ConnectFsa/ConnectFsaHeader';
import SecurityInfo from 'components/common/SyncLoans/ConnectFsa/SecurityInfo';
import { syncMarginMap } from 'components/common/SyncLoans/ConnectFsa/utils';
import { TabletSize } from 'constants/styleguide';
import { usePlatform } from 'hooks/platform';

const ConnectFsa = ({
  page,
  headerImgPath,
  headerSize,
  headerContent,
  bodyText,
  hideSecurityInfo,
  hideTermsOfUse,
  pageHasFooter,
  CustomCTA,
  mobileHeaderAlign,
  showMissingInfoHeader,
}) => {
  const { platformType } = usePlatform();
  const margin = syncMarginMap[page][platformType];

  return (
    <Content margin={margin} page={page}>
      <Top page={page}>
        <ConnectFsaHeader
          page={page}
          headerSize={headerSize}
          headerContent={headerContent}
          headerImgPath={headerImgPath}
          mobileHeaderAlign={mobileHeaderAlign}
          showMissingInfoHeader={showMissingInfoHeader}
        />
        <ConnectFsaBody bodyText={bodyText} CustomCTA={CustomCTA} />
      </Top>
      {!hideSecurityInfo && (
        <SecurityInfo
          hideTermsOfUse={hideTermsOfUse}
          pageHasFooter={pageHasFooter}
        />
      )}
    </Content>
  );
};

const Content = styled(FlexColumn)`
  margin: ${({ margin }) => margin};
  ${({ page }) => (page === 'pslf' ? '' : 'height: 100%;')};
  justify-content: space-between;
`;

const Top = styled.div`
  min-height: calc(100vh - 306px);

  @media (max-width: ${TabletSize}) {
    min-height: initial;
  }
`;

export default ConnectFsa;
