import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { find, keys, omit } from 'lodash';

import { COLORS } from '../../../helpers/constants/styleguide';

const colors = {
  default: COLORS.darkerGrey,
  secondary: COLORS.medGrey,
  summer: COLORS.azure,
  light: COLORS.white,
  covid: COLORS.covidGreen,
};

const sizes = {
  h1: '30px',
  h2: '24px',
  h3: '20px',
  h4: '18px',
  h5: '16px',
};

const sizeOptions = Object.keys(sizes);
const alignOptions = ['left', 'center', 'right', 'justify'];

const getAlign = props => {
  // this function provides us the shorthand syntax
  const propsAlign = find(alignOptions, alignOption => props[alignOption]);

  if (propsAlign) {
    return propsAlign;
  }

  return props.align;
};

const getLineHeight = props => {
  if (props.as === 'h1' || props.as === 'h2') {
    return '1.3';
  }
  return '1.5';
};

const Header = ({ children, ...props }) => {
  const as = find(sizeOptions, sizeOption => props[sizeOption]) || props.as;

  const size = sizes[as];

  /**
   * Omit 'as' to prevent overwritting the as selected by the shorthand size
   * option.
   */
  return (
    <StyledHeader as={as} size={size} {...omit(props, 'as')}>
      {children}
    </StyledHeader>
  );
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
  light: PropTypes.bool,
  align: PropTypes.oneOf(alignOptions),
  color: PropTypes.oneOf(keys(colors)),
  /** HTML header tag */
  as: PropTypes.oneOf(sizeOptions),
};

Header.defaultProps = {
  children: '',
  light: false,
  align: null,
  color: 'default',
  as: 'h1',
};

const StyledHeader = styled.h1`
  font-size: ${props => props.size};
  font-weight: ${props => (props.light ? 'normal' : '600')};
  text-align: ${props => getAlign(props) || 'inherit'};
  color: ${props => colors[props.color]};
  line-height: ${getLineHeight};
`;

export const StorybookHeader = Header;
export default memo(Header);
