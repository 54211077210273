import React, { memo, useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  FlexCenter,
  LoadingBars,
  Text,
  Strong,
  COLORS,
} from '@summer/ui-components';

import OnboardingRequiredModal from 'components/pages/refinancing/dashboard/OnboardingRequiredModal';
import RefiPage from 'components/pages/refinancing/dashboard/RefiPage';
import { getRefiKindObject } from 'components/pages/refinancing/utils';
import LandingPage from 'components/shared/dashboard/LandingPage';
import { fetchRecommendations } from 'redux/actions/recEngine.actions';
import {
  hasPlaidLoans,
  userHasFederalLoans,
} from 'redux/selectors/loans.selectors';
import { getUserHasCompletedOnboarding } from 'redux/selectors/onboarding.selectors';
import {
  getRefiFederalLoansRecommendation,
  getRefiPrivateLoansRecommendation,
} from 'redux/selectors/recEngine.selectors';
import { isLoadingWithInit } from 'redux/selectors/ui.selectors';
import { getUserPartnerKey } from 'redux/selectors/user.selectors';

const RefinancingDashboard = ({
  isLoading,
  refiFederalRecommended,
  refiPrivateRecommended,
  hasFederalLoans,
  hasPrivateLoans,
  userHasCompletedOnboarding,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (userHasCompletedOnboarding) {
      dispatch(fetchRecommendations());
    }
  }, [dispatch, userHasCompletedOnboarding]);

  const [isModalOpen, setIsModalOpen] = useState(!userHasCompletedOnboarding);

  const toggleModal = useCallback(
    () => setIsModalOpen(!isModalOpen),
    [setIsModalOpen, isModalOpen],
  );

  const headerCopy = `Refinancing ${
    getRefiKindObject({
      refiFederalRecommended,
      refiPrivateRecommended,
      hasFederalLoans,
      hasPrivateLoans,
    }).copy
  }`;

  if (isLoading) {
    return (
      <FlexCenter style={{ height: '100%' }}>
        <LoadingBars color={COLORS.azure} />
      </FlexCenter>
    );
  }

  return (
    <>
      <LandingPage
        data-testid="test-refi"
        headerTheme="cobaltDark"
        headerName={headerCopy}
        subHeader={
          <Text paragraph weight="medium">
            You may be able to <Strong>lower your interest rate</Strong> by
            refinancing{' '}
            {
              getRefiKindObject({
                refiFederalRecommended,
                refiPrivateRecommended,
                hasFederalLoans,
                hasPrivateLoans,
              }).copy
            }
            .
          </Text>
        }
      >
        <RefiPage />
      </LandingPage>
      <OnboardingRequiredModal isOpen={isModalOpen} togglePopup={toggleModal} />
    </>
  );
};

const mapStateToProps = state => {
  const userHasCompletedOnboarding = getUserHasCompletedOnboarding(state);

  return {
    refiFederalRecommended: getRefiFederalLoansRecommendation(state),
    refiPrivateRecommended: getRefiPrivateLoansRecommendation(state),
    userPartner: getUserPartnerKey(state),
    hasFederalLoans: userHasFederalLoans(state),
    hasPrivateLoans: hasPlaidLoans(state),
    isLoading: userHasCompletedOnboarding
      ? isLoadingWithInit(state, 'fetchRecommendations')
      : false,
    userHasCompletedOnboarding,
  };
};

export default memo(withRouter(connect(mapStateToProps)(RefinancingDashboard)));
