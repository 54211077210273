import { push as redirectTo } from 'connected-react-router';
import React, { useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Header, Text, Button, FancyCard } from '@summer/ui-components';

import { MobileSize } from 'constants/styleguide';
import {
  getRefiFederalLoansRecommendation,
  getRefiPrivateLoansRecommendation,
  getRefiLowestMonthlyPayment,
} from 'redux/selectors/recEngine.selectors';
import { getUserPartnerKey } from 'redux/selectors/user.selectors';

import { refiTooltipText } from '../utils';

const RecCardContent = ({ loansCopy }) => {
  const dispatch = useDispatch();
  const handleCTA = React.useCallback(() => {
    dispatch(redirectTo('/refinancing'));
  }, [dispatch]);

  return (
    <CardContentContainer>
      <ContentContainer>
        <Header h2>Explore refinancing {loansCopy}</Header>
        <Text paragraph weight="medium" size="medium">
          Refinancing is recommended based on your credit score and salary. Get
          instant estimates from several private lenders to compare lower
          interest rates and save money over time.
        </Text>
      </ContentContainer>
      <CTAContainer>
        <Button onClick={handleCTA} width={144}>
          Get Started
        </Button>
      </CTAContainer>
    </CardContentContainer>
  );
};

const RefiRec = ({
  refiFederalRecommended,
  refiPrivateRecommended,
  refiLowestMonthlyPayment,
}) => {
  const { loansCopy, testId, tooltipText } = useMemo(() => {
    if (refiFederalRecommended && refiPrivateRecommended) {
      return {
        loansCopy: 'your student loans',
        testId: 'rec-card-refi-all',
        tooltipText: refiTooltipText.federalAndPrivate,
      };
    }
    if (refiFederalRecommended) {
      return {
        loansCopy: 'your federal loans',
        testId: 'rec-card-refi-federal',
        tooltipText: refiTooltipText.federalOnly,
      };
    }
    if (refiPrivateRecommended) {
      return {
        loansCopy: 'your private loans',
        testId: 'rec-card-refi-private',
        tooltipText: refiTooltipText.privateOnly,
      };
    }
    return {};
  }, [refiFederalRecommended, refiPrivateRecommended]);

  return (
    <FancyCard
      title="LOWER INTEREST RATE"
      color="cobalt"
      testId={testId}
      headerInfo={[
        {
          title: `Est. monthly payment:`,
          value: refiLowestMonthlyPayment,
          tooltipText,
          animate: true,
        },
      ]}
    >
      <RecCardContent
        loansCopy={loansCopy}
        refiFederalRecommended={refiFederalRecommended}
      />
    </FancyCard>
  );
};

const CardContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: inherit;
  align-items: center;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;

    > * {
      margin-bottom: 16px;
      :last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const ContentContainer = styled.div`
  margin-right: 8px;
  max-width: 80%;

  > * {
    margin-bottom: 8px;
  }

  :last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${MobileSize}) {
    max-width: 100%;
  }
`;

const CTAContainer = styled.div`
  text-align: right;
  align-self: flex-end;
  width: 144px;

  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

const mapStateToProps = state => ({
  refiFederalRecommended: getRefiFederalLoansRecommendation(state),
  refiPrivateRecommended: getRefiPrivateLoansRecommendation(state),
  refiLowestMonthlyPayment: getRefiLowestMonthlyPayment(state),
  partnerKey: getUserPartnerKey(state),
});

export default connect(mapStateToProps)(RefiRec);
