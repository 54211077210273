import React from 'react';

import { TextInput } from '@summer/ui-components';

import { InputWrapper } from 'components/pages/idr/wizard/steps/Submit/shared/Inputs';
import { camelWithPrefix } from 'utils/text';

const ReferenceEmail = ({
  prefix,
  formik: { values, errors, handleChange, handleBlur, touched, isSubmitting },
}) => {
  const namePrefix = 'submit.';

  const getFormikName = name => namePrefix + camelWithPrefix(prefix, name);
  const getFormikPropertyName = name => camelWithPrefix(prefix, name);

  return (
    <InputWrapper>
      <TextInput
        label="Email Address"
        placeholder="Email Address"
        name={getFormikName('email')}
        touched={touched[getFormikPropertyName('email')]}
        isSubmitting={isSubmitting}
        error={errors[getFormikPropertyName('email')]}
        value={values[getFormikPropertyName('email')]}
        onChange={handleChange}
        onBlur={handleBlur}
        helpMessage="Optional"
        inputMode="email"
      />
    </InputWrapper>
  );
};

export default ReferenceEmail;
