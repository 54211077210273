import {
  CREATE_STRIPE_CHECKOUT,
  UPDATE_STRIPE_STATUS,
  NETWORK_SUCCESS,
} from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import { setHasPaymentSaved } from 'redux/actions/paywall.actions';
import handleSideEffects from 'redux/handleSideEffects';

const paywallMiddleware = handleSideEffects({
  [CREATE_STRIPE_CHECKOUT]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        method: 'POST',
        url: '/v1/paywall/checkout',
        body: payload,
        fromAction: CREATE_STRIPE_CHECKOUT,
        label: 'createStripeCheckoutSession',
      }),
    );
  },

  [UPDATE_STRIPE_STATUS]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        method: 'POST',
        url: '/v1/paywall/member/savePayment',
        body: payload,
        fromAction: UPDATE_STRIPE_STATUS,
        label: 'updateStripeMembership',
      }),
    );
  },

  [NETWORK_SUCCESS]: (dispatch, { payload, meta }) => {
    if (meta.fromAction === CREATE_STRIPE_CHECKOUT) {
      const { url } = payload.response;
      if (url) {
        window.location.href = url;
      }
    }

    if (meta.fromAction === UPDATE_STRIPE_STATUS) {
      const { status } = payload.response;
      if (status === 'payment_saved') {
        dispatch(setHasPaymentSaved(true));
      }
    }
  },
});

export default paywallMiddleware;
