import React from 'react';
import styled from 'styled-components';

import { Header, NumberInput, Text } from '@summer/ui-components';

import InfoTooltip from 'components/shared/InfoTooltip';

const HoursPerWeekQuestion = ({
  formik: { values, errors, handleChange, handleBlur, touched, isSubmitting },
}) => (
  <>
    <HeaderContainer>
      <Header as="h2">
        How many hours per week did you work for this employer?
        <InfoTooltip text="If you work a typical 9-5 job, it is fine to put 40 hours. Otherwise, put the average amount of hours that you work per week." />
      </Header>
    </HeaderContainer>
    <PslfQuestionText>
      <Text paragraph>
        We&rsquo;ll verify these hours with your employer, and they will add
        this information into the form.
      </Text>
    </PslfQuestionText>
    <NumberInput
      name="employerHoursPerWeek"
      placeholder="Hours per week"
      value={values.employerHoursPerWeek}
      error={errors.employerHoursPerWeek}
      touched={touched.employerHoursPerWeek}
      onChange={handleChange}
      isSubmitting={isSubmitting}
      onBlur={handleBlur}
      decimalScale={2}
    />
  </>
);

const HeaderContainer = styled.div`
  margin-bottom: 16px;
`;

const PslfQuestionText = styled.div`
  margin-bottom: 24px;
`;

export default HoursPerWeekQuestion;
