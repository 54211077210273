import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTable, useFlexLayout } from 'react-table';
import { isString, get } from 'lodash';
import { FixedSizeList } from 'react-window';
import { hexToRgba } from '../../helpers/utils/common';

import { Text } from '../typography';
import { COLORS } from '../../helpers/constants/styleguide';

const GUTTER_SIZE = 10;
const ITEM_SIZE = 74;

// get props the hide header cell if necessary
const getHiddenHeaderProps = (props, options) => {
  const hideHeader = options.column.hideHeader === true ? 'none' : null;
  const style = { ...props.style, display: hideHeader };
  return {
    ...props,
    style,
  };
};

const DefaultRowCellComponent = ({ cell }) => {
  return (
    <Text weight="medium" color="dark" truncate paragraph smallTracking>
      {cell.value}
    </Text>
  );
};

const VirtualizeCardTable = ({
  columns,
  data,
  getCustomRowProps,
  tableIndex,
  tableName,
  colDefaultWidth = 100,
  align = 'center',
  fitToContent = false,
  virtualizedOptions,
}) => {
  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      // minWidth: 30, // minWidth is only used as a limit for resizing
      // maxWidth: 200, // maxWidth is only used as a limit for resizing
      width: colDefaultWidth, // width is used for both the flex-basis and flex-grow
      Cell: DefaultRowCellComponent,
    }),
    [colDefaultWidth],
  );
  const {
    columns: instantColumns,
    headerGroups,
    rows,
    prepareRow,
    getTableProps,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFlexLayout,
  );

  // handle column visibility
  useEffect(
    function HandleColumnVisibility() {
      instantColumns.forEach(col => {
        if (col.show === true) {
          col.toggleHidden(false);
        } else if (col.show === false) {
          col.toggleHidden(true);
        }
      });
    },
    [instantColumns],
  );

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      const rowId = `${tableName || 'table'}${tableIndex}-row${index}`;
      prepareRow(row);
      return (
        <Row
          {...row.getRowProps(getCustomRowProps)}
          style={{
            ...style,
            top: style.top + GUTTER_SIZE,
            height: style.height - GUTTER_SIZE,
            left: style.left + GUTTER_SIZE,
            width: `calc(${style.width} - ${GUTTER_SIZE * 2}px)`,
          }}
          tabIndex="0"
          className="table-row"
          id={rowId}
        >
          {row.cells.map(cell => {
            return (
              <RowCell key={cell.value} {...cell.getCellProps()} align={align}>
                {cell.render('Cell')}
              </RowCell>
            );
          })}
        </Row>
      );
    },
    [prepareRow, rows, align, getCustomRowProps, tableIndex, tableName],
  );

  return (
    <Container>
      <Table {...getTableProps()} data-private fitToContent={fitToContent}>
        {headerGroups.map((headerGroup, index) => (
          <HeaderContainer key={index} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => {
              return (
                <HeaderCell
                  key={column.id}
                  {...column.getHeaderProps(getHiddenHeaderProps)}
                  align={align}
                >
                  {isString(column.Header) ? (
                    <Text weight="medium" size="extraSmall">
                      {column.render('Header')}
                    </Text>
                  ) : (
                    column.render('Header')
                  )}
                </HeaderCell>
              );
            })}
          </HeaderContainer>
        ))}

        <BodyContainer>
          <FixedSizeList
            height={get(virtualizedOptions, 'height', 0)}
            ref={get(virtualizedOptions, 'ref')}
            onItemsRendered={get(virtualizedOptions, 'onItemsRendered')}
            itemCount={rows.length}
            itemSize={ITEM_SIZE + GUTTER_SIZE}
          >
            {RenderRow}
          </FixedSizeList>
        </BodyContainer>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  overflow: scroll;
`;

const Table = styled.div`
  width: ${({ fitToContent }) => (fitToContent ? 'fit-content' : '100%')};
  min-width: unset !important; //useFlexLayout set min-width to totalColumnsWidth this not good for us in small screens
`;

const HeaderContainer = styled.div`
  display: flex;
  width: ${`calc(100% - ${GUTTER_SIZE * 2}px)`};
  height: 48px;
  padding: 16px 16px;
  margin-left: ${GUTTER_SIZE}px;
  align-items: center;
  border-radius: 3px;
  background-blend-mode: multiply;
  background-color: ${hexToRgba(COLORS.azure, 0.09)};
`;

const BodyContainer = styled.div`
  & > div {
    margin-bottom: 8px;
  }
`;

const rowValidStyle = css`
  border-left: solid 6px ${COLORS.orange} !important;
  &.error-focus {
    box-shadow: 0 2px 10px 2px rgba(214, 221, 231, 0.81);
    border: solid 1px #f2f4f7;
    background-color: #ffffff;
  }
`;

const Row = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: center;
  padding: 16px 16px;
  border-radius: 6px;
  border: solid 1px #ebeff4;
  background-color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;

  :first-child {
    text-align: left;
  }

  :hover {
    box-shadow: 0 2px 10px 2px rgba(214, 221, 231, 0.81);
    border: solid 1px #f2f4f7;
    background-color: #ffffff;
  }

  :focus {
    box-shadow: 0 0 5px 0 rgba(0, 166, 255, 0.5);
    border: solid 1px #41beff;
    outline: none;
  }

  :active {
    box-shadow: 0 2px 10px 2px rgba(214, 221, 231, 0.81);
    border: solid 1px #f2f4f7;
    background-color: #f9fafc;
  }

  ${({ valid }) => valid === false && rowValidStyle}
`;

/*
 * The use of justify-content in this component it to support our current web-app HeaderCell component
 * when using HeaderCell component align will not work and without it tooltip will break
 *
 * Todo: update tooltip design and HeaderCell to support normal align
 * */
const HeaderCell = styled.div`
  flex-shrink: 1 !important;
  flex-flow: nowrap;
  overflow: hidden;
  padding: 8px 8px;
  text-align: ${({ align }) => align};

  > * {
    justify-content: center;
  }

  :first-child {
    text-align: left;

    > * {
      justify-content: flex-start;
    }
  }

  :last-child {
    text-align: right;

    > * {
      justify-content: flex-end;
    }
  }
`;

const RowCell = styled.div`
  flex-flow: row nowrap;
  flex-shrink: 1 !important;
  flex-basis: auto;
  overflow: hidden;
  padding: 8px 8px;
  text-align: ${({ align }) => align};

  :first-child {
    text-align: left;
  }
  :last-child {
    text-align: right;
  }
`;

VirtualizeCardTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        .isRequired,
      id: PropTypes.string,
      columns: PropTypes.array,
      Header: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.element,
      ]),
      Cell: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]),
      width: PropTypes.number,
      minWidth: PropTypes.number,
      maxWidth: PropTypes.number,
    }),
  ).isRequired,
  getRowProps: PropTypes.func,
  tableIndex: PropTypes.number,
  colDefaultWidth: PropTypes.number,
  fitToContent: PropTypes.bool,
  align: PropTypes.PropTypes.oneOf(['center', 'left', 'right']),
};

VirtualizeCardTable.defaultProps = {
  tableIndex: 0,
  colDefaultWidth: 100,
  align: 'center',
};

export default VirtualizeCardTable;
