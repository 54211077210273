import React from 'react';
import styled from 'styled-components';

import { Accordion, Text } from '@summer/ui-components';

import { refiGuideLink } from 'constants/externalLinks/marketingSite';
import { MobileSize, TabletSize } from 'constants/styleguide';

import { Link } from '../../../shared/typography';
import earnestLogoSrc from '../assets/earnest-logo.png';
import lendkeyLogoSrc from '../assets/lendkey-logo.svg';
import penfedLogoSrc from '../assets/penfed-logo.svg';
import sofiLogoSrc from '../assets/sofi-logo.png';

const config = [
  {
    title: 'How does refinancing work?',
    content: (
      <Text paragraph>
        Refinancing involves changing a loan’s terms, typically by lowering the
        interest rate&mdash;which can reduce the cost of the loan&mdash;and
        sometimes giving the option to change the repayment timeline (i.e.,
        shortening to 5 years or increasing to 20 years, for example).
        <br />
        <br />
        When you refinance, you often take on a new loan with a new lender.
        Then, your new lender pays off the loan(s) that you owed to your former
        lender. When you’re done, you only have to pay your new lender. You’ll
        then have a new lender and potentially a lower interest rate, saving you
        money. Learn more{' '}
        <Link underline inheritColor href={refiGuideLink}>
          here
        </Link>
        .
      </Text>
    ),
  },
  {
    title: 'Why should I refinance through Summer?',
    content: (
      <Text paragraph>
        Summer works with partners to make it easy to compare rates across
        multiple lenders through a single streamlined application. We recommend
        comparing rates because different lenders use different calculation
        criteria, meaning that your chances of successfully refinancing at a
        lower interest rate increase when you comparison shop!{' '}
      </Text>
    ),
  },
];

export const RefiLogos = () => (
  <LogosContainer>
    <Text paragraph bold size="small">
      Rates from 1.99% – 9.14% APR
    </Text>
    <List>
      <img src={earnestLogoSrc} alt="Earnest" />
      <img src={sofiLogoSrc} alt="Sofi" />
      <img src={penfedLogoSrc} alt="Penfed" />
      <img src={lendkeyLogoSrc} alt="LendKey" />
    </List>
  </LogosContainer>
);

export const RefiFaq = () => (
  <AccordionContainer>
    <Accordion config={config} align="left" />
  </AccordionContainer>
);

// Todo:[ch25794] Update copy or remove
export const RefiFooterMessage = () => (
  <Message>
    <Text size="small" paragraph align="left">
      Through our partnership, Summer may receive compensation if you
      successfully refinance with one of the associated lenders.
    </Text>
  </Message>
);

const Message = styled.div`
  padding-top: 8px;
  @media (max-width: ${TabletSize}) {
    margin-bottom: 24px;
  }
`;

const AccordionContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const List = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    margin-right: 40px;
    :last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 1250px) {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 40px;

    img {
      margin: auto;
      :last-child {
        margin-right: auto;
      }
    }
  }

  @media (max-width: ${MobileSize}) {
    display: flex;
    flex-direction: column;
    gap: 0;

    img {
      margin: 0 0 40px 0;
      :last-child {
        margin-bottom: 0;
        margin-right: 0;
      }
    }
  }
`;

const LogosContainer = styled.div`
  padding: 24px 0 48px;
  .text {
    margin-bottom: 24px;
  }
`;
