import React from 'react';

import { Alert, Text } from '@summer/ui-components';

import ContactSupport from 'components/pages/pslf/wizard/steps/yourEmployment/shared/ContactSupport';
import {
  GOVERNMENT_ORG,
  NON_PROFIT_501C3,
  NON_PROFIT_OTHER,
  NONE_OF_THE_ABOVE,
} from 'constants/pslf';

const EmployerTypeAlert = ({ employerType }) => {
  let theme;
  let text;

  if (employerType === GOVERNMENT_ORG || employerType === NON_PROFIT_501C3) {
    theme = 'success';
    text = (
      <>
        <span role="img" aria-label="emoji">
          🎉
        </span>{' '}
        Hooray! Your employer qualifies.
      </>
    );
  }

  if (employerType === NON_PROFIT_OTHER) {
    theme = 'info';
    text = (
      <>
        👍 Ok, let’s continue. We have a couple more questions about your
        employer.
      </>
    );
  }

  if (employerType === NONE_OF_THE_ABOVE) {
    theme = 'warning';
    text = (
      <>
        🤔 If none of these categories apply to this employer, you may not be
        eligible for PSLF during this period. However, you may qualify while
        working for a different employer. <ContactSupport />
      </>
    );
  }

  if (theme && text) {
    return (
      <Alert dismissable={false} showIcon={false} theme={theme}>
        <Text paragraph>{text}</Text>
      </Alert>
    );
  }

  return null;
};

export default React.memo(EmployerTypeAlert);
