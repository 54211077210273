import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Text,
  FlexRow,
  Icon,
  Close,
  FileIcon,
  COLORS,
} from '@summer/ui-components';

import { getS3UploadProgress } from 'redux/selectors/ui.selectors';

const FileInfo = ({
  uploaded,
  handleDeleteFile,
  handleRemoveFileWithError,
  error,
  errorMessage,
  fileName,
  uploadProgress,
}) => {
  const deleteFileWithError = error && handleRemoveFileWithError;
  const deleteUploadedFile = !error && handleDeleteFile;
  const showRemoveFileIcon = deleteUploadedFile || deleteFileWithError;

  const removeFileHandler = () => {
    if (deleteFileWithError) {
      return handleRemoveFileWithError();
    }

    return uploaded ? handleDeleteFile() : null;
  };

  return (
    <FileInfoContainer>
      <LeftSideContainer>
        <Icon SvgComponent={FileIcon} fill={COLORS.medGrey} />
        <Info>
          <FileName className={uploaded ? 'uploaded' : ''}>
            <Text large>{fileName}</Text>
          </FileName>
          {error ? (
            <Text small color="error">
              {errorMessage
                ? `${errorMessage}`
                : 'We had trouble uploading your file. Try uploading again.'}
            </Text>
          ) : (
            !uploaded && (
              <Progress>
                <Meter
                  progress={uploadProgress[fileName]}
                  role="progressbar"
                  aria-valuenow={uploadProgress[fileName]}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </Progress>
            )
          )}
        </Info>
      </LeftSideContainer>

      {showRemoveFileIcon && (
        <Icon
          SvgComponent={Close}
          fill={COLORS.medGrey}
          width={16}
          height={16}
          onClick={() => removeFileHandler()}
        />
      )}
    </FileInfoContainer>
  );
};

const FileInfoContainer = styled(FlexRow)`
  border-radius: 3px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  min-height: 61px;
  width: 100%;
  background-color: ${COLORS.white};
  border: solid 1px ${COLORS.grey};
  padding: 16px 16px;

  &.fileInfo-enter {
    opacity: 0.01;
  }

  &.fileInfo-enter.fileInfo-enter-active {
    opacity: 1;
    transition: all 500ms ease-out;
  }

  &.fileInfo-exit {
    opacity: 1;
  }

  &.fileInfo-exit.fileInfo-exit-active {
    opacity: 0.01;
    transition: all 300ms ease-out;
  }
`;

const LeftSideContainer = styled(FlexRow)`
  flex: 1;
`;

const Info = styled.div`
  flex: 1;
  margin-left: 15px;
`;
const Progress = styled.div`
  flex: 1;
  height: 7px;
  margin-top: 3px;
  background-color: ${COLORS.grey};
`;

const Meter = styled.div`
  height: 100%;
  width: ${({ progress }) => progress}%;
  background-image: linear-gradient(
    to left,
    #58de7e,
    #7cffa1 34%,
    #58de7e 68%,
    #7cffa1
  );
  transition: width 2s;
`;

const FileName = styled.div`
  top: 0;
  transition: all 1s;

  &.uploaded {
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    & > div {
      font-size: 16px;
      overflow-wrap: anywhere;
    }
  }

  & > div {
    transition: all 1s;
  }
`;

const mapStateToProps = state => ({
  uploadProgress: getS3UploadProgress(state),
});

export default connect(mapStateToProps)(FileInfo);
