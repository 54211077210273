import {
  FETCH_RECOMMENDATIONS,
  SET_RECOMMENDATIONS,
} from 'constants/actionTypes';

export const fetchRecommendations = () => ({ type: FETCH_RECOMMENDATIONS });

export const setRecommendations = ({ current, pending, recommended }) => ({
  type: SET_RECOMMENDATIONS,
  payload: {
    current,
    pending,
    recommended,
  },
});
