import { find } from 'lodash';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';

import { Text, FlexCenter, COLORS, FlexColumn } from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import FileInfo from 'components/shared/FilesUpload/FileInfo';
import {
  setFileInfo,
  uploadError,
} from 'redux/actions/fileCollections.actions';
import { hexToRgba } from 'utils/common';

const DropZoneLabel = ({ isDragAccept, isDragReject, platformType }) => {
  if (isDragAccept) {
    return (
      <Text large weight="medium">
        Drop file
      </Text>
    );
  }
  if (isDragReject) {
    return (
      <Text large weight="medium">
        Wrong file type
      </Text>
    );
  }
  if (platformType === MOBILE) {
    return (
      <Text large color="summer" weight="medium">
        Upload photos or files
      </Text>
    );
  }
  return (
    <Text large weight="medium">
      Drag and drop files, or{' '}
      <Text as="span" large color="summer" weight="medium">
        browse files.
      </Text>
    </Text>
  );
};

const FilesStatus = ({
  fileList,
  handleDeleteFile,
  handleRemoveFileWithError,
}) => {
  return fileList.map(item => {
    return (
      <CSSTransition
        key={item.name + item.timestamp}
        classNames="fileInfo"
        timeout={{ enter: 500, exit: 300 }}
      >
        <FileInfo
          handleDeleteFile={
            handleDeleteFile && (() => handleDeleteFile(item.id))
          }
          fileName={item.name}
          uploaded={item.uploaded}
          error={item.error}
          errorMessage={item.errorMessage}
          handleRemoveFileWithError={
            handleRemoveFileWithError &&
            (() => handleRemoveFileWithError(item.name))
          }
        />
      </CSSTransition>
    );
  });
};

const fileUpload = (file, handleFileUpload, fileList) => {
  if (handleFileUpload) {
    const originalFile = find(fileList, { name: file.name });
    if (originalFile && originalFile.uploaded) {
      return;
    }
    handleFileUpload(file);
  }
};

const FileUpload = ({
  clearFilesErrors,
  fileList,
  handleFileUpload,
  handleDeleteFile,
  handleRemoveFileWithError,
  onClick,
  platformType,
  collectionName,
  dropZoneOnTop,
  dropZoneHeight,
}) => {
  const dispatch = useDispatch();

  const onDrop = React.useCallback(
    async (acceptedFiles, fileRejections) => {
      fileRejections.forEach(rejection => {
        const {
          file,
          errors: [error],
        } = rejection;

        if (error?.code === 'file-too-large') {
          dispatch(setFileInfo({ fileInfo: file, collection: collectionName }));
          dispatch(
            uploadError({
              fileInfo: file,
              collection: collectionName,
              errorMessage:
                'This file is larger than 20mb. Please upload a file smaller than 20mb.',
            }),
          );
        }
      });

      const acceptedFilesPromises = acceptedFiles.map(async file => {
        if (file.type === 'application/pdf') {
          const { checkPdfError } = await import('services/pdf');
          const hasPdfError = await checkPdfError(file);

          if (hasPdfError) {
            dispatch(
              setFileInfo({ fileInfo: file, collection: collectionName }),
            );
            dispatch(
              uploadError({
                fileInfo: file,
                collection: collectionName,
                errorMessage:
                  'Failed to upload file. Please ensure the file is not password protected. If it is not protected please contact our support or try again.',
              }),
            );
            return;
          }
        }
        fileUpload(file, handleFileUpload, fileList);
      });

      await Promise.all(acceptedFilesPromises);
    },
    [collectionName, dispatch, fileList, handleFileUpload],
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject, open } =
    useDropzone({
      onDrop,
      maxSize: 2e7, // 20 MB
      minSize: 10, // 1 bytes
      accept: {
        'application/pdf': ['.pdf'],
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/png': ['.png'],
      },
      style: { border: 0, width: '100%' },
      noClick: true,
    });

  const handleClick = () => {
    onClick?.();
    open();
  };

  useEffect(() => {
    clearFilesErrors();
    return () => clearFilesErrors();
  }, [clearFilesErrors]);

  return (
    <FlexColumn reverse={dropZoneOnTop}>
      <ScrollContainer scroll={dropZoneOnTop}>
        <CssGroup>
          <FilesStatus
            fileList={fileList}
            handleDeleteFile={handleDeleteFile}
            handleRemoveFileWithError={handleRemoveFileWithError}
          />
        </CssGroup>
      </ScrollContainer>
      <div {...getRootProps()} onClick={handleClick}>
        <input {...getInputProps()} data-testid="input-file-upload" />
        <DropZone
          height={dropZoneHeight}
          isDragAccept={isDragAccept}
          isDragReject={isDragReject}
        >
          <DropZoneLabel
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
            platformType={platformType}
          />
        </DropZone>
      </div>
    </FlexColumn>
  );
};

const DropZone = styled(FlexCenter)`
  flex: 1 0 auto;
  height: ${({ height }) => height ?? '61px'};
  cursor: pointer;
  margin-bottom: 10px;
  border: dashed 2px ${hexToRgba(COLORS.skyBlue, '0.6')};
  border-radius: 3px;
  background-color: ${({ isDragAccept, isDragReject }) => {
    if (isDragAccept) {
      return '#e8faee';
    }
    if (isDragReject) {
      return hexToRgba(COLORS.orange, '0.6');
    }
    return hexToRgba(COLORS.skyBlue, '0.1');
  }};
`;

const ScrollContainer = styled.div`
  display: inline-block;
  width: 100%;

  max-height: ${props => (props.scroll ? '220px' : 'none')};
  overflow: ${props => (props.scroll ? 'scroll' : 'visible')};
`;

const CssGroup = styled(TransitionGroup)`
  width: 100%;
`;

export default Platform(FileUpload);
