import { isNil, startCase, isEmpty } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  ColorBadge,
  Text,
  Header,
  FlexRow,
  FlexColumn,
  FlexCenter,
  Button,
  ResponsiveColumn,
  Row,
  COLORS,
} from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import { IDR_APPLICATION_TYPES } from 'components/pages/idr/wizard/steps/ReviewYourPlan/constants';
import ConsolidationInfoPopup from 'components/pages/idr/wizard/steps/ReviewYourPlan/popups/ConsolidationInfoPopup';
import ZeroPaymentPopup from 'components/pages/idr/wizard/steps/ReviewYourPlan/popups/ZeroPaymentPopup';
import TextTooltip from 'components/shared/TextTooltip';
import { Link } from 'components/shared/typography';
import Statistic from 'components/shared/typography/Statistic';
import { SmallDesktopSize, MobileSize } from 'constants/styleguide';
import { isLoading } from 'redux/selectors/ui.selectors';
import { hexToRgba } from 'utils/common';
import { calcRepaymentPeriod, renderPlanTypeNames } from 'utils/idr';

const { RECALCULATE, SWITCH, NEW, RECERTIFY } = IDR_APPLICATION_TYPES;

const recommendedReasonMap = {
  'Lowest monthly payment': 'Pay less now',
  'Lowest total amount paid': 'Pay less overall',
};

const showPopupLinks = (requiresConsolidation, isZeroPaymentInfoShown) =>
  (requiresConsolidation || isZeroPaymentInfoShown) && (
    <PopupLinksContainer>
      {requiresConsolidation && (
        <ConsolidationInfoPopup>
          <Text color="secondary" small>
            <Link inheritColor underline>
              Why do I need to consolidate?
            </Link>
          </Text>
        </ConsolidationInfoPopup>
      )}
      {isZeroPaymentInfoShown && (
        <ZeroPaymentPopup>
          <Text color="secondary" small>
            <Link inheritColor underline>
              Could I really pay $0?
            </Link>
          </Text>
        </ZeroPaymentPopup>
      )}
    </PopupLinksContainer>
  );

const PlanCard = ({
  isPslfOn,
  path,
  showRecBadge,
  isCurrentPath,
  isSelectedPath,
  platformType,
  selectPath,
  idrIsLoading,
  loansSource,
}) => {
  const {
    amountForgiven,
    firstPayment,
    numPayments,
    totalPayment,
    appType,
    requiresConsolidation,
    planType,
    recommendedReasons,
  } = path;

  const headerPrefix = {
    [NEW]: requiresConsolidation
      ? 'Consolidate and enroll in the '
      : 'Enroll in the ',
    [SWITCH]: requiresConsolidation
      ? 'Consolidate and switch to the '
      : 'Switch to the ',
    [RECALCULATE]: 'Recalculate my ',
    [RECERTIFY]: 'Recertify my ',
  };

  const showActivePath = isCurrentPath || isSelectedPath;
  const isZeroPaymentInfoShown = !showActivePath && firstPayment === 0;

  const repaymentPeriod = isNil(numPayments)
    ? null
    : calcRepaymentPeriod(numPayments);

  const planOrPlans = `plan${planType.length > 1 ? 's' : ''}`;

  const isLoanSourcePlaid = loansSource === 'plaid';
  const isCurrentPlan = isEmpty(planType);
  const isPlaidLoansAndCurrentPlan = isLoanSourcePlaid && isCurrentPlan;

  const pslfWaiverCopy =
    'if you received credit for additional payments under the PSLF Limited Waiver';

  const pslfWaiverCopyCapitalized =
    pslfWaiverCopy.charAt(0).toUpperCase() + pslfWaiverCopy.slice(1);

  const amountForgivenTooltipText = `This is the amount that could be forgiven after the ${
    isPslfOn
      ? `10 year repayment period for Public Service Loan Forgiveness. ${pslfWaiverCopyCapitalized}, you may be eligible for forgiveness sooner than we estimate.`
      : '20 or 25 year repayment period with qualifying payments.'
  }`;

  const repaymentPeriodTooltipText = `This is the potential length of your repayment period until you pay off your loans or receive forgiveness. If you\u2019ve already been making payments, your repayment period will be shorter${
    isPslfOn
      ? `, and ${pslfWaiverCopy}, you may be eligible for forgiveness sooner than this estimate.`
      : '.'
  }`;

  const recBadgeText = () => {
    if (isSelectedPath) {
      return startCase(`Selected Plan`);
    }

    if (isCurrentPath) {
      return startCase(`Your current ${planOrPlans}`);
    }

    return recommendedReasonMap[recommendedReasons[0]];
  };

  return (
    <Background
      showActivePath={showActivePath}
      data-testid={`idr-plan-card-${planType?.join()}-${appType}`}
      flexRowMobile={isPlaidLoansAndCurrentPlan}
    >
      <HeaderContent>
        <Badges>
          {!showActivePath && <ColorBadge colorName="magenta">IDR</ColorBadge>}
          {(showRecBadge || showActivePath) && (
            <RecBadge showActivePath={showActivePath}>
              <Text paragraph size="small" bold>
                {recBadgeText()}
              </Text>
            </RecBadge>
          )}
        </Badges>
        <Header h2>
          {headerPrefix[appType]}
          {renderPlanTypeNames(planType)}
        </Header>
        {platformType !== MOBILE &&
          showPopupLinks(requiresConsolidation, isZeroPaymentInfoShown)}
      </HeaderContent>
      <DataAndCTA>
        <Row desktopEnd tabletEnd fullWidth>
          <ResponsiveDataColumn
            hide={isPlaidLoansAndCurrentPlan}
            mobileCol={6}
            tabletCol={3}
            desktopCol={3}
          >
            <ColContent>
              <Text size="small">
                <TextTooltip lightUnderline text={amountForgivenTooltipText}>
                  Potential forgiveness
                </TextTooltip>
              </Text>
              <IdrStatistic
                bold
                size="small"
                animate
                value={amountForgiven}
                format="dollars"
              />
            </ColContent>
          </ResponsiveDataColumn>

          <ResponsiveDataColumn
            hide={isPlaidLoansAndCurrentPlan}
            mobileCol={6}
            tabletCol={3}
            desktopCol={3}
            mobileEnd
          >
            <ColContent>
              <Text size="small">
                <TextTooltip lightUnderline text={repaymentPeriodTooltipText}>
                  Maximum term
                </TextTooltip>
              </Text>
              <IdrStatistic
                bold
                size="small"
                animate
                value={repaymentPeriod}
                format="number"
              />
            </ColContent>
          </ResponsiveDataColumn>

          <ResponsiveDataColumn
            hide={isPlaidLoansAndCurrentPlan}
            mobileCol={6}
            tabletCol={3}
            desktopCol={3}
          >
            <ColContent>
              <Text size="small">
                <TextTooltip
                  lightUnderline
                  text="This is the total projected amount that you could pay over the life of your loans while enrolled in this plan. If your monthly payment is more than zero dollars a month, this is based on the assumption that your income will grow 3% each year."
                >
                  Total to repay
                </TextTooltip>
              </Text>
              <IdrStatistic
                bold
                size="small"
                animate
                value={totalPayment}
                format="dollars"
              />
            </ColContent>
          </ResponsiveDataColumn>

          <ResponsiveDataColumn
            mobileCol={isPlaidLoansAndCurrentPlan ? 12 : 6}
            tabletCol={3}
            desktopCol={3}
            mobileEnd
          >
            <ColContent>
              <Text size="small">
                <TextTooltip
                  lightUnderline
                  text={
                    isCurrentPath
                      ? 'This is the estimated amount that you are paying under these plan(s).'
                      : 'If you enroll in this plan, this is the amount you would pay per month for the next year, until you recertify your income. Summer will remind you when it’s time to recertify.'
                  }
                >
                  {isCurrentPath ? 'Monthly payment' : 'Next 12 payments'}
                </TextTooltip>
              </Text>
              <IdrStatistic
                bold
                size="small"
                animate
                value={firstPayment}
                format="dollars"
                testId="monthly-payment"
              />
            </ColContent>
          </ResponsiveDataColumn>
        </Row>
        {platformType === MOBILE &&
          showPopupLinks(requiresConsolidation, isZeroPaymentInfoShown)}
        {!isCurrentPath && (
          <CTAContainer>
            <Button
              width={160}
              onClick={selectPath}
              type="submit"
              isLoading={idrIsLoading}
            >
              Select this plan
            </Button>
          </CTAContainer>
        )}
      </DataAndCTA>
    </Background>
  );
};

const Background = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
  background-color: ${({ showActivePath }) =>
    showActivePath ? COLORS.lighterGrey : COLORS.white};
  border-radius: 3px;
  box-shadow: ${({ showActivePath }) =>
    showActivePath ? 'none' : '0 2px 10px 0 #d7dbe1'};
  border: ${({ showActivePath }) =>
    showActivePath ? 'none' : 'solid 1px rgba(223, 227, 232, 0.29)'};

  &:not(last-child) {
    margin-bottom: 16px;
  }

  @media (max-width: ${SmallDesktopSize}) {
    flex-direction: ${({ flexRowMobile }) =>
      flexRowMobile ? 'row' : 'column'};
  }

  @media (max-width: ${MobileSize}) {
    padding: 20px 24px 24px;
  }
`;

const HeaderContent = styled(FlexColumn)`
  min-width: 360px;
  max-width: 360px;

  margin-right: 16px;

  @media (max-width: ${SmallDesktopSize}) {
    min-width: unset;
    max-width: unset;
    width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
  }

  @media (max-width: ${MobileSize}) {
    margin-bottom: 8px;
  }
`;

const IdrStatistic = styled(Statistic)`
  margin-bottom: 4px;
`;

const Badges = styled(FlexRow)`
  align-items: center;
  margin-bottom: 8px;

  & > div:first-child {
    margin-right: 10px;
  }
`;

const RecBadge = styled(FlexCenter)`
  padding: 5px 8px;
  border-radius: 3px;

  background-color: ${({ showActivePath }) =>
    showActivePath ? COLORS.white : hexToRgba(COLORS.jade, 0.15)};
`;

const PopupLinksContainer = styled(FlexColumn)`
  margin-top: 16px;

  & > div:not(:last-child) {
    margin-bottom: 10px;
  }

  @media (max-width: ${SmallDesktopSize}) {
    margin-top: 8px;
  }

  @media (max-width: ${MobileSize}) {
    margin-top: 16px;
  }
`;

const DataAndCTA = styled(FlexColumn)`
  width: 100%;
  justify-content: space-between;
`;

const ResponsiveDataColumn = styled(ResponsiveColumn)`
  display: ${({ hide }) => (hide ? 'none' : 'auto')};
`;

const ColContent = styled.div`
  & > div:first-child {
    margin-bottom: 8px;
  }

  @media (max-width: ${MobileSize}) {
    margin-top: 8px;
  }
`;

const CTAContainer = styled.div`
  text-align: right;
  align-self: flex-end;
  width: 160px;
  margin-top: 20px;

  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

const mapStateToProps = state => ({
  idrIsLoading:
    isLoading(state, 'updateCurrentIdrForm') ||
    isLoading(state, 'fetchIdrPathOptions') ||
    isLoading(state, 'fetchLoans'),
});

export default Platform(connect(mapStateToProps)(PlanCard));
