import React, { useEffect } from 'react';

import { MoneyInput } from '@summer/ui-components';

import {
  Question,
  Label,
  SmallTextInputContainer,
} from 'components/pages/idr/wizard/shared/styles';
import { validateNumber } from 'utils/validate';

const SpouseUnemploymentIncome = ({
  formik: {
    values,
    errors,
    handleBlur,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
  },
}) => {
  useEffect(
    function clearFieldOnUnmount() {
      return () => setFieldValue(`tellUsAboutYou.spouseUnemploymentIncome`, '');
    },
    [setFieldValue],
  );

  return (
    <div>
      <Question id="spouseUnemploymentIncome">
        <Label>
          How much in unemployment income is your spouse receiving each month?
        </Label>
        <SmallTextInputContainer>
          <MoneyInput
            name="tellUsAboutYou.spouseUnemploymentIncome"
            value={values.spouseUnemploymentIncome}
            error={errors.spouseUnemploymentIncome}
            touched={touched.spouseUnemploymentIncome}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            isSubmitting={isSubmitting}
            onBlur={handleBlur}
            validate={validateNumber()}
          />
        </SmallTextInputContainer>
      </Question>
    </div>
  );
};

export default SpouseUnemploymentIncome;
