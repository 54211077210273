/* Calculate total payments and remaining terms based on current balance, monthly payment and interest rate.

  Args:
      { balance(int): current balance,
        monthly(int): monthly payment,
        rate(float): annual weighted average interest rate }

  Returns:
      { "totalPayment": total payments,
        "remainingTerms": remaining terms until paid off } */
export const calcPayments = ({ balance, monthly, rate }) => {
  let currentBalance = balance;
  const monthlyRate = rate / 12;
  let remainingTerms = 0;
  let totalPayments = 0;

  while (currentBalance > 0) {
    if (currentBalance * (1 + monthlyRate) <= monthly) {
      totalPayments += currentBalance * (1 + monthlyRate);
      remainingTerms += 1;
      break;
    }

    // Ensuring that the calculations will not go in an infinite loop, i.e. the monthly payment should always be greater than the interest
    if (monthly < currentBalance * monthlyRate) {
      throw new Error(
        `Error in calcPayments, monthly = ${monthly}, currentBalance = ${currentBalance}, monthlyRate = ${monthlyRate}`,
      );
    }

    currentBalance = currentBalance + currentBalance * monthlyRate - monthly;
    totalPayments += monthly;
    remainingTerms += 1;
  }

  return {
    totalPayments: Math.round(totalPayments),
    remainingTerms,
  };
};

/* Calculate total savings and terms difference for overpayment

Args:
    { balance(int): current balance,
     currentMonthly(int): monthly payment,
     rate(float): annual weighted average interest rate,
     overpayAmount(int): the extra amount you would pay every month }

Returns:
    { "totalSavings": total savings if do overpayment,
      "termsDiff": terms difference if do overpayment } */
export const calcOverpayment = ({
  balance,
  currentMonthly,
  rate,
  overpayAmount,
}) => {
  const newMonthly = currentMonthly + overpayAmount;

  const currentStats = calcPayments({ balance, monthly: currentMonthly, rate });
  const newStats = calcPayments({ balance, monthly: newMonthly, rate });

  const currentRemainingTerms = currentStats.remainingTerms;
  const newRemainingTerms = newStats.remainingTerms;

  const currentTotalPayments = currentStats.totalPayments;
  const newTotalPayments = newStats.totalPayments;

  const totalSavings = currentTotalPayments - newTotalPayments;
  const termsDiff = currentRemainingTerms - newRemainingTerms; // in months

  return {
    totalSavings,
    termsDiff,
  };
};

export const setCurrentPaymentValues = (values, setFieldValue) => {
  const { currentBalance, currentMonthlyPayment, interestRate } = values;

  const { totalPayments, remainingTerms } = calcPayments({
    balance: currentBalance,
    monthly: currentMonthlyPayment,
    rate: interestRate,
  });

  setFieldValue('currentTotalPayment', totalPayments);
  setFieldValue('currentRemainingTerms', remainingTerms);
};

export const setOverpaymentValues = (
  overpaymentAmount,
  values,
  setFieldValue,
) => {
  const { currentBalance, currentMonthlyPayment, interestRate } = values;

  const { totalPayments, remainingTerms } = calcPayments({
    balance: currentBalance,
    monthly: currentMonthlyPayment + overpaymentAmount,
    rate: interestRate,
  });

  setFieldValue('overpaymentTotalPayment', totalPayments);
  setFieldValue('overpaymentTotalTime', remainingTerms);

  const { totalSavings, termsDiff } = calcOverpayment({
    balance: currentBalance,
    currentMonthly: currentMonthlyPayment,
    rate: interestRate,
    overpayAmount: overpaymentAmount,
  });

  setFieldValue('totalSavings', totalSavings);
  setFieldValue('termsDiff', termsDiff);
};
