export const loanPayoffDateLabelLink =
  'https://studentaid.ed.gov/npas/index.htm';

export const fsaSignInLink = 'https://studentaid.gov/fsa-id/sign-in/landing';
export const fsaCreateAccountLink =
  'https://studentaid.gov/fsa-id/create-account/account-info';

export const fsaConsolidationToolLink =
  'https://studentaid.gov/loan-consolidation';

export const fsaIdrToolLink = 'https://studentaid.gov/idr/';
export const fsaIdrFormLink =
  'https://studentaid.gov/sites/default/files/IncomeDrivenRepayment-en-us.pdf';
export const fsaIdrPlansLink =
  'https://studentaid.gov/manage-loans/repayment/plans/income-driven';

export const fsaPslfToolLink = 'https://studentaid.gov/pslf/';
export const fsaPslfLink =
  'https://studentaid.gov/manage-loans/forgiveness-cancellation/public-service';
export const fsaPslfConsolidationLink =
  'https://studentaid.gov/manage-loans/forgiveness-cancellation/public-service#eligible-loans';
export const fsaPslfDefermentForbearanceLink =
  'https://studentaid.gov/manage-loans/forgiveness-cancellation/public-service#deferments-forbearances';
export const fsaPslfForgivenessFormLink =
  'https://studentaid.gov/sites/default/files/public-service-application-for-forgiveness.pdf';
export const fsaRefiHelpLink =
  'https://studentaid.gov/help-center/answers/article/should-i-refinance-my-federal-student-loans-into-a-private-loan';
export const fsaPslfReconLink =
  'https://studentaid.gov/manage-loans/pslf-reconsideration';
export const fsaPslfEmployerQuestionsLink =
  'https://studentaid.gov/manage-loans/forgiveness-cancellation/public-service/questions';

export const fsaAnnouncementSaveLink =
  'https://studentaid.gov/announcements-events/save-plan';
export const fsaAnnouncementIdrAdjustmentLink =
  'https://studentaid.gov/announcements-events/idr-account-adjustment';
export const fsaAnnouncementPslfWaiverLink =
  'https://studentaid.gov/announcements-events/pslf-limited-waiver';

export const fsaRepaymentPlansLink =
  'https://studentaid.gov/manage-loans/repayment/plans';
export const fsaServicersLink =
  'https://studentaid.gov/manage-loans/repayment/servicers';
export const fsaPerkinsCancellationLink =
  'https://studentaid.gov/manage-loans/forgiveness-cancellation/perkins';

export const defaultResolutionGroupWebsiteLink =
  'https://myeddebt.ed.gov/borrower';

export const servedInMilitaryLearnMoreLink =
  'https://studentaid.ed.gov/sa/types/grants-scholarships/military#interest-and-deferment'; // FIXME: This link is broken
export const nsldsLink = 'https://nslds.ed.gov/nslds/nslds_SA/'; // FIXME: This link is broken
export const nsldsFindServicerLink = 'https://nsldsfap.ed.gov/login'; // TODO: is this the right link? Says 'professional access'
