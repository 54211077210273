import React from 'react';
import styled from 'styled-components';

import { Button, FlexCenter, Header, Text } from '@summer/ui-components/src';
import Link from '@summer/ui-components/src/core/typography/Link';

import { collegeAidProReferralLink } from 'constants/externalLinks/collegeSavingsPlanner';
import { MobileSize } from 'constants/styleguide';

const FurtherHelp = () => (
  <div>
    <Header as="h2">Need further help?</Header>
    <FurtherHelpContainer>
      <img
        src="/images/collegeSavingsPlanner/collegeAidProLogo.png"
        alt="College Aid Pro"
        height="44px"
      />
      <FurtherHelpText small paragraph>
        Our partners, College Aid Pro, have a range of resources to help with
        your college planning needs, including customized financial aid
        projections for every school in the country, a database of scholarships,
        and access to a dedicated college funding expert.
      </FurtherHelpText>
      <Link href={collegeAidProReferralLink}>
        <FurtherHelpCTAButton>Go to College Aid Pro</FurtherHelpCTAButton>
      </Link>
    </FurtherHelpContainer>
  </div>
);

const FurtherHelpContainer = styled(FlexCenter)`
  flex-direction: row;
  margin: 12px 0 0 0;
  padding: 20px 28px 20px 16px;
  background-color: #edf3f7;
  align-items: center;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
    align-items: start;
    padding: 30px;
  }
`;

const FurtherHelpText = styled(Text)`
  margin: 0 44px 0 16px;

  @media (max-width: ${MobileSize}) {
    margin: 24px 0 24px 0;
  }
`;

const FurtherHelpCTAButton = styled(Button)`
  white-space: nowrap;
  flex-shrink: 0;
  padding-left: 18px;
  padding-right: 18px;
  width: fit-content;
  height: fit-content;
`;

export default FurtherHelp;
