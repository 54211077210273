import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Header, Text } from '@summer/ui-components';

import ManageLoansAlert from 'components/common/ManageLoans/alerts/';
import { xPaddingDesktop, xPaddingMobile } from 'constants/manageLoans';
import { MobileSize } from 'constants/styleguide';
import {
  getLoanFormProgramType,
  isLoanEditable,
  getLoanFormNumberOfLoans,
  getCurrentLoanIdx,
} from 'redux/selectors/loans.selectors';
import {
  getEmployerStartDate,
  getEmployerEndDate,
} from 'redux/selectors/pslf.selectors';

import { getHeaderCopy } from './shared/utils';

const LoansModalHeader = ({
  formik,
  formType,
  programType,
  pslfEmployerStartDate,
  pslfEmployerEndDate,
  loanIsEditable,
  loanIsPaidOff,
  numberOfLoans,
  currentLoanIdx,
  innerRef,
  alertDismissed,
}) => {
  const { title, subtitle } = getHeaderCopy(
    formType,
    programType,
    loanIsEditable,
    loanIsPaidOff,
    pslfEmployerStartDate,
    pslfEmployerEndDate,
  );

  const { isValid, errors, validateForm } = formik;

  useEffect(
    /* This is a hack fix for what seems to be a bug where the Yup validation seems to be run
     * with the wrong values in some cases. Triggering the validation manually here ensures that it
     * is being run against the current loan.

     * It is being done here and not in index.js because hooks
     * cannot be used inside a render function, and we need access to the formik props.
     */
    function ensureCorrectValidation() {
      if (!isEmpty(errors)) {
        validateForm();
      }
    },
    [errors, validateForm],
  );

  return (
    <Container ref={innerRef}>
      <Counter>
        <Text bold color="secondary" center>
          Loan {currentLoanIdx + 1} of {numberOfLoans}
        </Text>
      </Counter>
      <SubtitleContainer>
        <Text bold color="secondary" center>
          {subtitle}
        </Text>
      </SubtitleContainer>
      <Title>
        <Header center>{title}</Header>
      </Title>
      <ManageLoansAlert
        isMissingInfo={!isValid}
        alertDismissed={alertDismissed}
      />
    </Container>
  );
};

const Container = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: ${xPaddingDesktop};
  padding-right: ${xPaddingDesktop};
  flex: 0;

  @media (max-width: ${MobileSize}) {
    padding-left: ${xPaddingMobile};
    padding-right: ${xPaddingMobile};
  }
`;

const Counter = styled.div``;

const SubtitleContainer = styled.div`
  margin-bottom: 8px;
  letter-spacing: 1px;
  padding-top: 16px;
`;

const Title = styled.div`
  margin-bottom: 16px;

  @media (max-width: ${MobileSize}) {
    & > h1 {
      font-size: 24px;
    }
  }
`;

const mapStateToProps = state => ({
  programType: getLoanFormProgramType(state),
  loanIsEditable: isLoanEditable(state),
  pslfEmployerStartDate: getEmployerStartDate(state),
  pslfEmployerEndDate: getEmployerEndDate(state),
  numberOfLoans: getLoanFormNumberOfLoans(state),
  currentLoanIdx: getCurrentLoanIdx(state),
});

export default connect(mapStateToProps)(LoansModalHeader);
