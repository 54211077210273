import { Formik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Button, NumberInput, Text, Alert } from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { mfaHelpLink } from 'constants/externalLinks/support';
import {
  sendSmsFactorOTP,
  verifySmsFactor,
} from 'redux/actions/smsFactor.actions';
import { getSmsFactorPhoneNumber } from 'redux/selectors/smsFactor.selectors';
import { isLoading, getErrorMessage } from 'redux/selectors/ui.selectors';
import { getUserIsVerified } from 'redux/selectors/user.selectors';
import { verifySmsFactorSchema } from 'schemas/onboard';

import { MfaPageContainer } from './shared';

const VerifySmsFactor = ({
  phoneNumber,
  setIsEditing,
  setIsVerified,
  isLoading,
  isSending,
  dispatch,
  smsVerifyError,
  smsOTPSendingError,
}) => {
  const [isSent, setIsSent] = useState(false);
  const handleSave = values => {
    dispatch(
      verifySmsFactor(
        {
          phoneNumber,
          code: values.code,
        },
        {
          onSuccess: () => setIsVerified(true),
        },
      ),
    );
  };

  const handleSend = () => {
    setIsSent(false);
    dispatch(
      sendSmsFactorOTP(
        {
          phoneNumber,
        },
        {
          onSuccess: () => setIsSent(true),
        },
      ),
    );
  };

  const phoneLastFour = phoneNumber.slice(-4);

  return (
    <MfaPageContainer header="Enter the 6-digit code">
      <TextContainer>
        <Text paragraph center data-testid="phone-confirmation-text">
          We sent it to XXX-XXX-{phoneLastFour}. The code expires in 10 minutes.
          Can&apos;t find it?&nbsp;
          <Link
            underline
            inheritColor
            onClick={handleSend}
            disabled={isSending}
          >
            Resend
          </Link>
        </Text>
      </TextContainer>
      <Formik
        onSubmit={handleSave}
        initialValues={{
          code: '',
        }}
        validationSchema={verifySmsFactorSchema}
      >
        {({
          values,
          errors,
          handleBlur,
          handleSubmit,
          touched,
          isSubmitting,
          handleChange,
        }) => (
          <Form onSubmit={handleSubmit}>
            <NumberInput
              limit={6}
              name="code"
              touched={touched.code}
              error={errors.code}
              value={values.code}
              isSubmitting={isSubmitting}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isLoading}
              keepLeadingZeroes
              placeholder="Enter the verification code"
              autoComplete="one-time-code"
              autoFocus
            />
            <ButtonContainer>
              <Button type="submit" isLoading={isLoading}>
                Submit
              </Button>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
      {setIsEditing ? (
        <StyledLink testId="edit-sms-phone" onClick={() => setIsEditing(true)}>
          Send to a different number
        </StyledLink>
      ) : (
        <StyledLink testId="cannot-access-phone" href={mfaHelpLink}>
          I can’t access this phone right now
        </StyledLink>
      )}
      {/* TODO: Add more granular error handling as part of MFA v1 */}
      {isSent && !smsOTPSendingError && (
        <AlertContainer>
          <Alert theme="success" showIcon={true} dismissable={false}>
            <Text small paragraph data-testid="sent-confirmation-text">
              New code sent, you should receive a text
            </Text>
          </Alert>
        </AlertContainer>
      )}
      {smsVerifyError && (
        <AlertContainer>
          <Alert className="server-error" theme="warning" dismissable={false}>
            Sorry, that code is incorrect.
          </Alert>
        </AlertContainer>
      )}
      {!isSent && smsOTPSendingError && (
        <AlertContainer>
          <Alert className="sending-error" theme="warning" dismissable={false}>
            Sorry, a new code could not be sent.
          </Alert>
        </AlertContainer>
      )}
    </MfaPageContainer>
  );
};

const TextContainer = styled.div`
  margin-bottom: 16px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
`;

const Form = styled.form`
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
`;

const ButtonContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
`;

const StyledLink = styled(Link)`
  margin-bottom: 32px;
`;

const AlertContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 16px;
  max-width: 360px;
`;

const mapStateToProps = state => ({
  isLoading: isLoading(state, 'verifySmsFactor'),
  isSending: isLoading(state, 'sendSmsFactorOTP'),
  smsVerifyError: getErrorMessage(state, 'verifySmsFactor'),
  smsOTPSendingError: getErrorMessage(state, 'sendSmsFactorOTP'),
  phoneNumber: getSmsFactorPhoneNumber(state),
  emailIsVerified: getUserIsVerified(state),
});

export default connect(mapStateToProps)(VerifySmsFactor);
