import styled from 'styled-components';

import { FlexRow } from '@summer/ui-components';

import { MobileSize } from 'constants/styleguide';

export const ContentBox = styled.div`
  width: 100%;
  padding: 24px;
`;

export const IconContainer = styled(FlexRow)`
  text-align: left;
  position: relative;
  left: 0px;
  padding-left: 32px;
  align-items: center;

  @media (max-width: ${MobileSize}) {
    align-items: flex-start;
    padding-left: 8px;
  }
`;
