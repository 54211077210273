import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { FormStatus } from '@simplifidev/shared/dist/constants/forms';

import { Header, Text, Button, Checkbox, COLORS } from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import Error from 'components/shared/GlobalError';
import ModalPopup from 'components/shared/ModalPopup';
import { MobileSize } from 'constants/styleguide';
import { getCurrentStepFromNavigation } from 'hooks/Wizard/utils';
import { updateCurrentPslfForm } from 'redux/actions/pslf.actions';
import { getUserCurrentPslfForm } from 'redux/selectors/pslf.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { employerEmailConfirmationSchema } from 'schemas/pslf';
import { markPslfCompleteLocalStorage } from 'utils/pslf';

const EmployerEmailConfirmationModal = ({
  isOpen,
  togglePopup,
  currentPslfForm,
  updateCurrentPslfForm,
  formik: { values: employerFormValues },
  navigation,
  platformType,
  isLoading,
}) => {
  const isMobile = platformType === MOBILE;

  const handleSave = ({ hrEmailCorrect }) => {
    const { formId } = currentPslfForm;
    const currentStep = getCurrentStepFromNavigation(navigation);

    const formValuesToSave = {
      ...employerFormValues,
      hrEmailCorrect,
      formId,
    };

    markPslfCompleteLocalStorage();

    updateCurrentPslfForm({
      status: FormStatus.COMPLETED,
      currentStep,
      form: formValuesToSave,
    });
  };

  return (
    <Formik
      initialValues={{
        hrEmailCorrect: false,
      }}
      onSubmit={handleSave}
      validationSchema={employerEmailConfirmationSchema}
    >
      {formik => {
        const {
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          submitCount,
          touched,
        } = formik;

        return (
          <ModalPopup
            isOpen={isOpen}
            togglePopup={togglePopup}
            displayStyle="inline-block"
            width={770}
            kind="Employer email confirmation"
            component={() => (
              <>
                <form onSubmit={handleSubmit}>
                  <Container>
                    <HeaderText>
                      <Header as={isMobile ? 'h2' : 'h1'}>
                        Just double checking &mdash; is your employer
                        contact&rsquo;s email address correct?
                      </Header>
                    </HeaderText>

                    <Text as="p" paragraph>
                      Your PSLF application contains personal information,
                      including your social security number, that this person
                      will see when they sign. Please confirm their email
                      address is correct:{' '}
                    </Text>

                    <EmailContainer>
                      <Text paragraph color="secondary">
                        {`${employerFormValues.hrFirstName} ${employerFormValues.hrLastName}`}
                      </Text>
                      <Text bold>{employerFormValues.hrEmail}</Text>
                    </EmailContainer>

                    <CheckboxContainer>
                      <Checkbox
                        name="hrEmailCorrect"
                        label="I certify that this information is correct and understand that Summer will email the address provided so that my employer may review and co-sign my application. "
                        onChange={e => {
                          handleChange(e);
                        }}
                        handleBlur={handleBlur}
                        errors={errors.hrEmailCorrect}
                        labelSize="small"
                      />
                    </CheckboxContainer>

                    {errors.hrEmailCorrect && touched.hrEmailCorrect ? (
                      <ErrorText>
                        <Text small color="error">
                          {errors.hrEmailCorrect}
                        </Text>
                      </ErrorText>
                    ) : null}

                    <Error label="updateCurrentPslfForm" />

                    <ButtonContainer>
                      <Button
                        type="submit"
                        width={160}
                        disabled={isLoading || (!isValid && submitCount > 0)}
                        isLoading={isLoading}
                      >
                        Yes, it&rsquo;s correct
                      </Button>
                    </ButtonContainer>
                  </Container>
                </form>
              </>
            )}
          />
        );
      }}
    </Formik>
  );
};

const Container = styled.div`
  & > h1 {
    margin-bottom: 16px;
  }
  & > p:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const HeaderText = styled.div`
  margin-bottom: 16px;

  @media (max-width: ${MobileSize}) {
    margin-bottom: 8px;
  }
`;

const EmailContainer = styled.div`
  background-color: ${COLORS.lightestGrey};
  margin: 16px 0;
  padding: 16px 16px;
  width: 320px;

  @media (max-width: ${MobileSize}) {
    width: 80%;
    overflow: scroll;
  }
`;

const CheckboxContainer = styled.div`
  & div {
    position: relative;
    top: -3px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ErrorText = styled.div`
  @media (max-width: ${MobileSize}) {
    margin-bottom: 8px;
  }
`;

const mapStateToProps = state => ({
  currentPslfForm: getUserCurrentPslfForm(state),
  isLoading: isLoading(state, 'updateCurrentPslfForm'),
});

export default Platform(
  connect(mapStateToProps, {
    updateCurrentPslfForm,
  })(EmployerEmailConfirmationModal),
);
