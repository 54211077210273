import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  FlexColumn,
  FlexCenter,
  Button,
  RadioButtons,
  MoneyInput,
  NumberInput,
  SelectInput,
  TextInput,
  PhoneInput,
  Checkbox,
  Text,
  Family,
  Briefcase,
  CreditCard,
  Heart,
  HandCoins,
  DollarSign,
  LocationPin,
  Pencil,
  COLORS,
} from '@summer/ui-components';

import StepHeader from 'components/pages/onboarding/StepHeader';
import {
  OnboardingContainer,
  OnboardingPageTitle,
  Form,
  StyledIcon as Icon,
  Label,
  Options,
  Question,
  Background,
} from 'components/pages/onboarding/shared';
import GlobalError from 'components/shared/GlobalError';
import { loanInfo } from 'constants/onboardingSteps';
import { States } from 'constants/states';
import { goBackOnboarding, setPageInfo } from 'redux/actions/onboard.actions';
import { fillSurvey, setSurveyData } from 'redux/actions/user.actions';
import {
  getUserGradStatus,
  getUserStateOfResidence,
  getUserCurrentEmployer,
  getUserPhone,
} from 'redux/selectors/profile.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import {
  getUserPaymentDifficulty,
  getUserSalary,
  getUserFamilyCount,
  getUserIsPublicSector,
  getUserCreditScore,
  getUserHasOptedIntoPhoneContact,
} from 'redux/selectors/user.selectors';
import { surveyValidationSchema } from 'schemas/onboard';
import { scrollToError } from 'utils/formik';

class Questions extends React.Component {
  buttonMobileCSS = `
    @media (max-width: 600px) {
      width: auto;
      white-space: nowrap;
    }
  `;

  componentDidMount() {
    this.props.setPageInfo(1, 'Your situation');
  }

  handleSubmit = values => {
    const {
      troubleWithPayments,
      salary,
      familyCount,
      state,
      isPublicSector,
      creditScore,
      gradStatus,
      currentEmployer,
      phone,
      hasOptedIntoPhoneContact,
    } = {
      ...values,
      currentEmployer: values.currentEmployer || null,
      phone: values.phone || null,
      hasOptedIntoPhoneContact: values.hasOptedIntoPhoneContact || false,
    };

    this.props.setSurveyData({
      gradStatus,
      troubleWithPayments,
      salary,
      familyCount,
      isPublicSector,
      creditScore,
      currentEmployer,
      phone,
      hasOptedIntoPhoneContact,
      state,
    });

    this.props.fillSurvey({
      gradStatus,
      troubleWithPayments,
      salary,
      familyCount,
      state,
      isPublicSector,
      creditScore,
      currentEmployer,
      phone,
      hasOptedIntoPhoneContact,
      currentStep: loanInfo,
    });
  };

  renderForm = formik => {
    const {
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      touched,
      isSubmitting,
      isValid,
      setFieldValue,
      setFieldTouched,
      submitCount,
    } = formik;
    const submitDisabled = !isValid && submitCount > 0;

    return (
      <Form onSubmit={handleSubmit}>
        <Question id="gradStatus">
          <FlexColumn>
            <Icon width={25.6} SvgComponent={Pencil} stroke={COLORS.azure} />
          </FlexColumn>
          <FlexColumn>
            <Label>Where are you in your education?</Label>
            <Options>
              <RadioButtons
                name="gradStatus"
                touched={touched.gradStatus}
                isSubmitting={isSubmitting}
                error={errors.gradStatus}
                value={values.gradStatus}
                numberOfCol={2}
                options={[
                  { value: 'attending', label: 'I’m currently attending' },
                  { value: 'graduated', label: 'I graduated' },
                  { value: 'unfinished', label: 'I did not finish' },
                ]}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Options>
          </FlexColumn>
        </Question>

        <Question id="troubleWithPayments">
          <FlexColumn>
            <Icon
              width={25.6}
              SvgComponent={DollarSign}
              stroke={COLORS.azure}
            />
          </FlexColumn>
          <FlexColumn>
            <Label>
              Which one of these describes how you feel about making your
              monthly payments?
            </Label>
            <Options>
              <RadioButtons
                design="smallRec"
                name="troubleWithPayments"
                touched={touched.troubleWithPayments}
                isSubmitting={isSubmitting}
                error={errors.troubleWithPayments}
                value={values.troubleWithPayments}
                onChange={handleChange}
                onBlur={handleBlur}
                numberOfCol={1}
                options={[
                  { value: 'yes', label: "I can't make payments" },
                  { value: 'maybe', label: 'I find it hard to make payments' },
                  { value: 'no', label: 'I have no problem paying each month' },
                ]}
              />
            </Options>
          </FlexColumn>
        </Question>

        <Question id="salary">
          <FlexColumn>
            <Icon width={25.6} SvgComponent={HandCoins} stroke={COLORS.azure} />
          </FlexColumn>
          <FlexColumn>
            <Label>
              What is your expected household annual income, before taxes?
            </Label>
            <SubTextContainer>
              <Text paragraph>
                Out of work right now? Enter &lsquo;$0&rsquo;.
              </Text>
              <br />
            </SubTextContainer>
            <Options>
              <MoneyInput
                name="salary"
                value={values.salary}
                error={errors.salary}
                touched={touched.salary}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                isSubmitting={isSubmitting}
                onBlur={handleBlur}
              />
            </Options>
          </FlexColumn>
        </Question>

        <Question id="familyCount">
          <FlexColumn>
            <Icon width={25.6} SvgComponent={Family} stroke={COLORS.azure} />
          </FlexColumn>
          <FlexColumn>
            <Label>How many people are in your family?</Label>
            <SubTextContainer>
              <Text paragraph>
                Include yourself, your spouse, and any children or family
                members that you support financially in this number.
              </Text>
            </SubTextContainer>
            <Options>
              <NumberInput
                name="familyCount"
                value={values.familyCount}
                error={errors.familyCount}
                touched={touched.familyCount}
                onChange={handleChange}
                isSubmitting={isSubmitting}
                onBlur={handleBlur}
                placeholder="Number of people"
              />
            </Options>
          </FlexColumn>
        </Question>

        <Question id="state">
          <FlexColumn>
            <Icon width={25.6} SvgComponent={LocationPin} fill={COLORS.azure} />
          </FlexColumn>
          <FlexColumn>
            <Label>What state do you live in?</Label>
            <Options>
              <SelectInput
                name="state"
                touched={touched.state}
                isSubmitting={isSubmitting}
                error={errors.state}
                options={States}
                value={values.state}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                placeholder="Type or scroll to select"
              />
            </Options>
          </FlexColumn>
        </Question>

        <Question id="isPublicSector">
          <FlexColumn>
            <Icon width={25.6} SvgComponent={Heart} stroke={COLORS.azure} />
          </FlexColumn>
          <FlexColumn>
            <Label>
              Do you currently work or plan to work at a non-profit, federal, or
              state organization?
            </Label>
            <SubTextContainer>
              <Text paragraph>
                Federal and state organizations include organizations such as
                public schools and public hospitals.
              </Text>
            </SubTextContainer>
            <Options>
              <RadioButtons
                row
                design="smallRec"
                name="isPublicSector"
                touched={touched.isPublicSector}
                isSubmitting={isSubmitting}
                error={errors.isPublicSector}
                value={values.isPublicSector}
                onChange={handleChange}
                onBlur={handleBlur}
                buttonCSS={this.buttonMobileCSS}
                options={[
                  { value: 'yes', label: 'Yes' },
                  { value: 'no', label: 'No' },
                  { value: 'maybe', label: 'I’m not sure' },
                ]}
              />
            </Options>
          </FlexColumn>
        </Question>

        <Question id="currentEmployer">
          <FlexColumn>
            <Icon width={25.6} SvgComponent={Briefcase} fill={COLORS.azure} />
          </FlexColumn>
          <FlexColumn>
            <Label isFieldOptional>
              What is the name of your current employer?
            </Label>
            <SubTextContainer>
              <Text paragraph>
                If you&rsquo;re currently unemployed, leave this blank.
              </Text>
            </SubTextContainer>
            <Options>
              <TextInput
                name="currentEmployer"
                touched={touched.currentEmployer}
                isSubmitting={isSubmitting}
                error={errors.currentEmployer}
                value={values.currentEmployer}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Your employer"
              />
            </Options>
          </FlexColumn>
        </Question>

        <Question id="creditScore">
          <FlexColumn>
            <Icon
              width={25.6}
              SvgComponent={CreditCard}
              className="credit-card-icon"
            />
          </FlexColumn>
          <FlexColumn>
            <Label>What is your credit score range?</Label>
            <Options>
              <RadioButtons
                design="smallRec"
                name="creditScore"
                touched={touched.creditScore}
                isSubmitting={isSubmitting}
                error={errors.creditScore}
                value={values.creditScore}
                onChange={handleChange}
                onBlur={handleBlur}
                buttonCSS={this.buttonMobileCSS}
                numberOfCol={2}
                options={[
                  { value: 'below 600', label: 'Below 600' },
                  { value: '600-679', label: '600 - 679' },
                  { value: '680-699', label: '680 - 699' },
                  { value: '700-749', label: '700 - 749' },
                  { value: '750+', label: '750 or higher' },
                  { value: 'Not sure', label: 'I’m not sure' },
                ]}
              />
            </Options>
          </FlexColumn>
        </Question>

        <Background>
          <PhoneQuestion>
            <Question id="phone">
              <FlexColumn>
                <SubTextContainer>
                  <Text color="secondary" size="small">
                    Optional
                  </Text>
                </SubTextContainer>
                <Label>What is your phone number?</Label>
                <SubTextContainer>
                  <Text paragraph>
                    Summer may reach out to provide additional support or update
                    you on your student loan situation.
                  </Text>
                </SubTextContainer>
                <Options>
                  <PhoneInput
                    name="phone"
                    touched={touched.phone}
                    isSubmitting={isSubmitting}
                    error={errors.phone}
                    value={values.phone}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                  />
                </Options>
                <Checkbox
                  name="hasOptedIntoPhoneContact"
                  label="By checking this box, I acknowledge that Summer can contact me via text message or phone with updates about my student loans or my application."
                  onChange={() => {
                    setFieldValue(
                      'hasOptedIntoPhoneContact',
                      !values.hasOptedIntoPhoneContact,
                    );
                  }}
                  handleBlur={handleBlur}
                  labelSize="medium"
                  numberOfCol={1}
                  checked={values.hasOptedIntoPhoneContact}
                />
              </FlexColumn>
            </Question>
          </PhoneQuestion>
        </Background>

        <GlobalError label="fillSurveyPage" />
        <ButtonContainer>
          <Button
            fakeDisabled={submitDisabled}
            isLoading={this.props.isLoading}
            type="submit"
            onClick={() => scrollToError(errors)}
          >
            Continue
          </Button>
        </ButtonContainer>
      </Form>
    );
  };

  render() {
    return (
      <OnboardingContainer>
        <StepHeader />
        <OnboardingPageTitle>Tell us your situation:</OnboardingPageTitle>

        <Formik
          initialValues={{
            gradStatus: this.props.gradStatus,
            troubleWithPayments: this.props.troubleWithPayments,
            salary: this.props.salary,
            familyCount: this.props.familyCount,
            state: this.props.state,
            isPublicSector: this.props.isPublicSector,
            creditScore: this.props.creditScore,
            currentEmployer: this.props.currentEmployer,
            phone: this.props.phone,
            hasOptedIntoPhoneContact: this.props.hasOptedIntoPhoneContact,
          }}
          validationSchema={surveyValidationSchema}
          onSubmit={this.handleSubmit}
        >
          {this.renderForm}
        </Formik>
      </OnboardingContainer>
    );
  }
}

const SubTextContainer = styled.div`
  margin-bottom: 16px;
`;

const ButtonContainer = styled(FlexCenter)`
  width: 100%;
  margin-bottom: 32px;
`;

const PhoneQuestion = styled.div`
  margin-left: 2.6rem;
  width: 100%;
  max-width: calc(550px - 2.6rem);
`;

const mapStateToProps = state => ({
  gradStatus: getUserGradStatus(state),
  troubleWithPayments: getUserPaymentDifficulty(state),
  salary: getUserSalary(state),
  familyCount: getUserFamilyCount(state),
  state: getUserStateOfResidence(state),
  isPublicSector: getUserIsPublicSector(state),
  creditScore: getUserCreditScore(state),
  currentEmployer: getUserCurrentEmployer(state),
  phone: getUserPhone(state),
  hasOptedIntoPhoneContact: getUserHasOptedIntoPhoneContact(state),
  isLoading: isLoading(state, 'survey'),
});

export default connect(mapStateToProps, {
  fillSurvey,
  setSurveyData,
  setPageInfo,
  goBackOnboarding,
})(Questions);
