import { findIndex, mapValues, reject, get, clone } from 'lodash';
import { getOr, set } from 'lodash/fp';

import {
  DELETE_FILE,
  NETWORK_SUCCESS,
  REMOVE_FILES_WITH_ERROR,
  SET_FILE,
  SET_FILE_COLLECTIONS,
  CLEAR_FILE_COLLECTIONS,
  UPLOAD_ERROR,
  UPLOAD_SUCCESS,
  REMOVE_FILES_WITH_COLLECTION,
  REMOVE_SINGLE_FILE_WITH_ERROR,
} from 'constants/actionTypes';

const initialState = {};

const fileCollections = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case SET_FILE_COLLECTIONS: {
      return payload.fileCollections;
    }

    case CLEAR_FILE_COLLECTIONS: {
      return initialState;
    }

    case SET_FILE: {
      const { collection, fileInfo } = payload || {};
      const fileList = getOr([], collection, state);
      const newFileList = reject(fileList, { name: fileInfo.name });
      newFileList.push({ name: fileInfo.name, timestamp: Date.now() });
      return set(`${collection}`, newFileList, state);
    }

    case UPLOAD_SUCCESS: {
      const { collection, fileInfo } = payload || {};
      const fileIndex = findIndex(state[collection], { name: fileInfo.name });
      // Check if file is already in state - it may have been removed due to initial network error
      if (fileIndex > -1) {
        const { timestamp } = state[collection][fileIndex];
        return set(
          `${collection}[${fileIndex}]`,
          { id: fileInfo.id, name: fileInfo.name, uploaded: true, timestamp },
          state,
        );
      }
      // if file is not in state, reset it
      const fileList = getOr([], collection, state);
      const newFileList = clone(fileList);
      newFileList.push({
        id: fileInfo.id,
        name: fileInfo.name,
        uploaded: true,
        timestamp: Date.now(),
      });
      return set(`${collection}`, newFileList, state);
    }

    case UPLOAD_ERROR: {
      const { collection, fileInfo, errorMessage } = payload || {};
      const fileIndex = findIndex(state[collection], { name: fileInfo.name });
      const timestamp = get(state, `[${collection}][${fileIndex}].timestamp`);
      return set(
        `${collection}[${fileIndex}]`,
        { name: fileInfo.name, error: true, errorMessage, timestamp },
        state,
      );
    }

    case REMOVE_FILES_WITH_ERROR: {
      return mapValues(state, fileList => {
        return reject(fileList, { error: true });
      });
    }

    case REMOVE_SINGLE_FILE_WITH_ERROR: {
      const { collection, fileName } = payload || {};
      const newFileList = reject(state[collection], { name: fileName });
      return set(collection, newFileList, state);
    }

    case REMOVE_FILES_WITH_COLLECTION: {
      const { collection } = payload || {};
      return set(`${collection}`, [], state);
    }

    case NETWORK_SUCCESS: {
      if (meta.fromAction === DELETE_FILE) {
        const { collection } = meta;
        const { fileInfo } = payload.response;

        const newFileList = reject(state[collection], { id: fileInfo.id });

        return set(collection, newFileList, state);
      }
      return state;
    }

    default:
      return state;
  }
};

export default fileCollections;
