import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import {
  Header,
  Text,
  FlexColumn,
  FlexRow,
  Icon,
  SummerLogoSmall,
  COLORS,
} from '@summer/ui-components';

import WizardClose from 'components/layout/wizard/WizardClose';
import { TabletSize } from 'constants/styleguide';
import { toggleIdrQuestionnaire } from 'redux/actions/ui.actions';
import { showConfirmIdrPlan } from 'redux/selectors/idr.selectors';
import { userNeedsToRecertifyIdr } from 'redux/selectors/loans.selectors';
import { shouldUsePlaidFlow } from 'redux/selectors/plaid.selectors';
import { getShouldShowQuestionnaire } from 'redux/selectors/ui.selectors';

class IDRHeader extends Component {
  isStepDisabled(path) {
    const { wizardSteps, currentStep } = this.props;
    return path === '/idr/submit' || wizardSteps.indexOf(path) >= currentStep;
  }

  renderHeaderText() {
    const { location, userNeedsToRecertifyIdr, showConfirmIdrPlan } =
      this.props;
    // feature flag
    // idr recertification
    if (location.pathname === '/idr/review-your-plan') {
      return 'Review IDR Plans ';
    }
    if (userNeedsToRecertifyIdr) {
      return 'Recertify your IDR Plan ';
    }
    if (showConfirmIdrPlan) {
      return 'Confirm your IDR Plan ';
    }
    return 'Application for Income-Driven Repayment ';
  }

  render() {
    const {
      dispatch,
      handleClose,
      handleStepBack,
      wizardSteps,
      moveQuestionnaireToStep,
      shouldShowQuestionnaire,
      shouldUsePlaidFlow,
    } = this.props;
    const handleProgressBarClick = (e, path) => {
      if (this.isStepDisabled(path)) {
        e.preventDefault();
      } else {
        if (shouldUsePlaidFlow) {
          // If user navigates to Your Loans or earlier,
          // set questionnaire wizard to first step
          if (wizardSteps.indexOf(path) < 3) {
            moveQuestionnaireToStep(1);
          }
          if (shouldShowQuestionnaire) {
            dispatch(toggleIdrQuestionnaire(false));
          }
        }
        handleStepBack(wizardSteps.indexOf(path));
      }
    };

    return (
      <div>
        <HeaderContainer>
          <Title>
            <Logo SvgComponent={SummerLogoSmall} fill={COLORS.lightBlue} />
            <Header h4>{this.renderHeaderText()}</Header>
          </Title>
          <WizardClose handleClose={handleClose} />
        </HeaderContainer>

        <MobileProgressBar>
          <MobileProgressFill progress={this.props.currentStep / 4} />
        </MobileProgressBar>

        <ProgressBar>
          <Stage
            onClick={e => handleProgressBarClick(e, '/idr/tell-us-about-you')}
            exact
            to="/idr/tell-us-about-you"
            disabled={this.isStepDisabled('/idr/tell-us-about-you')}
          >
            <StageLabel>Tell us about you</StageLabel>
          </Stage>
          <Stage
            onClick={e => handleProgressBarClick(e, '/idr/your-loans')}
            exact
            to="/idr/your-loans"
            disabled={this.isStepDisabled('/idr/your-loans')}
          >
            <StageLabel>About your loans</StageLabel>
          </Stage>
          <Stage
            onClick={e => handleProgressBarClick(e, '/idr/review-your-plan')}
            exact
            to="/idr/review-your-plan"
            disabled={this.isStepDisabled('/idr/review-your-plan')}
          >
            <StageLabel>Review your plan</StageLabel>
          </Stage>
          <Stage
            onClick={e => handleProgressBarClick(e, '/idr/submit')}
            to="/idr/submit"
            disabled={this.isStepDisabled('/idr/submit')}
          >
            <StageLabel>Submit your application</StageLabel>
          </Stage>
        </ProgressBar>
      </div>
    );
  }
}

const HeaderContainer = styled(FlexRow)`
  height: 68px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px ${COLORS.grey};
  padding: 0 16px;
  background: ${COLORS.lightestGrey};
  z-index: 1;
`;

const Logo = styled(Icon)`
  margin-right: 24px;
  height: 32px;
`;

const Title = styled(FlexRow)`
  align-items: center;
`;

const ProgressBar = styled(FlexRow)`
  width: 100%;
  justify-content: space-around;
  align-items: center;
  height: 48px;
  overflow: hidden;
  border-bottom: 1px solid ${COLORS.grey};
`;

const StageLabel = ({ children }) => (
  <Text className="stage-label" bold center color="secondary">
    {children}
  </Text>
);

const Stage = styled(FlexColumn.withComponent(NavLink))`
  height: 100%;
  position: relative;
  justify-content: center;
  text-decoration: none;
  cursor: ${props => (props.disabled ? 'default' : 'cursor')};
  width: 25%;

  @media (max-width: ${TabletSize}) {
    display: none;
  }

  & .stage-label {
    color: ${({ disabled }) => !disabled && COLORS.azure};
  }
  :before {
    content: '';
    position: absolute;
    align-self: center;
    left: 0;
    bottom: 0;
    width: ${({ disabled }) => (disabled ? '0' : '100%')};
    height: 3px;
    background-color: ${COLORS.azure};
    transition: width 0.6s ease-out;
  }

  &.active {
    @media (max-width: ${TabletSize}) {
      display: block;
      height: 56px;
      width: 100%;
      padding: 16px;

      :before {
        display: none;
      }
    }

    & .stage-label {
      color: ${COLORS.azure};
      font-weight: 600;
    }

    :before {
      width: 100%;
    }
  }
`;

const MobileProgressBar = styled(FlexRow)`
  display: none;

  @media (max-width: ${TabletSize}) {
    height: 3px;
    display: flex;
    background-color: ${COLORS.grey};
  }
`;

const MobileProgressFill = styled.div`
  width: ${({ progress }) => progress * 100}%;
  background-color: ${COLORS.azure}};
  transition: width 0.5s;
`;

const mapStateToProps = state => ({
  userNeedsToRecertifyIdr: userNeedsToRecertifyIdr(state),
  showConfirmIdrPlan: showConfirmIdrPlan(state),
  shouldShowQuestionnaire: getShouldShowQuestionnaire(state),
  shouldUsePlaidFlow: shouldUsePlaidFlow(state),
});

export default connect(mapStateToProps)(IDRHeader);
