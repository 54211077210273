import React from 'react';
import styled from 'styled-components';

import {
  FlexColumn,
  FlexRow,
  SvgImage,
  SavingsTargetDartThrow,
} from '@summer/ui-components/src';

import { MobileSize } from 'constants/styleguide';

import { EmergencySavingsCard } from '../shared';

import SavingsTargetSide from './SavingsTargetSide';

const SavingsTargetCompleteV1 = ({ savingsTarget }) => {
  return (
    <EmergencySavingsCard>
      <Container>
        <Content>
          <Image>
            <SvgImage SvgComponent={SavingsTargetDartThrow}></SvgImage>
          </Image>
        </Content>
        <SavingsTargetSide savingsTarget={savingsTarget} />
      </Container>
    </EmergencySavingsCard>
  );
};

const Image = styled.div`
  position: absolute;
  left: 12.5%;

  @media (max-width: ${MobileSize}) {
    position: relative;
    left: 25%;
    margin: 10px;

    & > * {
      width: 150%;
    }
  }
`;

const Content = styled(FlexColumn)`
  justify-content: center;
  gap: 32px;
  width: 50%;
`;

const Container = styled(FlexRow)`
  justify-content: space-between;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
    & > * {
      margin-right: 0;
    }
  }
`;

export default SavingsTargetCompleteV1;
