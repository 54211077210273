import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { FlexRow, Collapse, SelectInput } from '@summer/ui-components';

import {
  loanType,
  occupations,
} from 'constants/forgivenessProgramOptionsLists';
import { StateFilterOptions } from 'constants/prismic';
import { TabletSize } from 'constants/styleguide';
import { arrayToOptions } from 'utils/toOptions';

class ForgivenessProgramFilters extends React.Component {
  render() {
    const { filtersValues, show } = this.props;
    return (
      <Collapse
        ejectControl
        show={show}
        watchResize={true}
        openByDefault={true}
        trigger={() => null}
      >
        <CollapseContent>
          <SelectInput
            notFormik
            label="State you live in:"
            name="state"
            value={filtersValues.state}
            options={StateFilterOptions}
            placeholder="Type or scroll to select"
            onBlur={() => {}}
            onChange={this.props.onChange}
            isClearable
          />
          <SelectInput
            notFormik
            label="Occupation:"
            name="occupation"
            value={filtersValues.occupation}
            options={arrayToOptions(occupations)}
            placeholder="Type or scroll to select"
            onBlur={() => {}}
            onChange={this.props.onChange}
            isClearable
          />
          <SelectInput
            notFormik
            label="Loan type eligibility:"
            name="loanType"
            value={filtersValues.loanType}
            options={arrayToOptions(loanType)}
            placeholder="Type or scroll to select"
            onBlur={() => {}}
            onChange={this.props.onChange}
            isClearable
          />
        </CollapseContent>
      </Collapse>
    );
  }
}

const CollapseContent = styled(FlexRow)`
  justify-content: space-between;
  padding-bottom: 16px;
  padding-top: 40px;
  align-items: flex-start;

  & > * {
    :first-child {
      margin-left: 0;
    }
    margin-left: 16px;
  }

  @media (max-width: ${TabletSize}) {
    padding-top: 32px;
    flex-wrap: wrap;

    & > * {
      :first-child {
        margin-left: 16px;
      }
      margin: 0 16px;
    }
  }
`;

ForgivenessProgramFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
  filtersValues: PropTypes.object.isRequired,
};

export default ForgivenessProgramFilters;
