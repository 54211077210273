import React from 'react';
import styled from 'styled-components';

import { Header, GuideCard } from '@summer/ui-components/src';

import { MobileSize, TabletSize } from 'constants/styleguide';

import { collegePlanningGuides } from './cardsContent';

const CollegeSavingsPlannerResourceCenter = () => {
  return (
    <div>
      <Header h2>College Planning Resource Center</Header>

      <GuideCardList>
        {collegePlanningGuides.map((guideData, idx) => (
          <GuideCard key={idx} {...guideData} />
        ))}
      </GuideCardList>
    </div>
  );
};

const GuideCardList = styled.div`
  padding-top: 16px;
  display: grid;
  align-items: stretch;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;

  @media (max-width: ${TabletSize}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${MobileSize}) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`;

export default CollegeSavingsPlannerResourceCenter;
