import { push as redirectTo } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  Header,
  SummerLogoMedallion,
  FlexRow,
  COLORS,
} from '@summer/ui-components';

import WizardClose from 'components/layout/wizard/WizardClose';

export const CSPToolLayout = ({ title, children }) => {
  const dispatch = useDispatch();

  const handleClose = () => dispatch(redirectTo('/college-savings-planner'));

  return (
    <>
      <HeaderContainer handleClose={handleClose}>
        <TitleContainer>
          <SummerLogoMedallion size={40} />
          <Header h5>{title}</Header>
        </TitleContainer>
        <WizardClose handleClose={handleClose} />
      </HeaderContainer>
      {children}
    </>
  );
};

const HeaderContainer = styled(FlexRow)`
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 12px 14px;
  background-color: ${COLORS.lightestGrey};
  box-shadow: 0 1px 2px 0 ${COLORS.grey};
`;

const TitleContainer = styled(FlexRow)`
  align-items: center;
  & > h5 {
    margin-left: 8px;
  }
`;
