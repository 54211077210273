import { flow, set } from 'lodash/fp';

import { SET_RECOMMENDATIONS } from 'constants/actionTypes';

const initialState = {
  pending: {
    idr: {
      isPending: null,
      dateSubmitted: null,
      monthlyPayment: null,
      totalPayment: null,
      repaymentPlanTypeIds: null,
    },
  },
  recommended: {
    idr: {
      isRecommended: null,
      estIdrMonthlyPayment: null,
    },
    pslf: {
      isRecommended: null,
    },
    refi: {
      federalRecommended: null,
      privateRecommended: null,
    },
    overpayments: {
      isRecommended: null,
    },
    other: {
      getOutOfDefaultRecommended: null,
    },
  },
};

const recEngineReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_RECOMMENDATIONS: {
      return flow([
        set('current', payload.current),
        set('pending', payload.pending),
        set('recommended', payload.recommended),
      ])(state);
    }

    default:
      return state;
  }
};

export default recEngineReducer;
