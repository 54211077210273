import styled from 'styled-components';

export { default as Text } from './Text';
// export { default as Link } from './Link';
export { default as Header } from './Header';
export { default as Statistic } from './Statistic';
export { default as Highlight } from './Highlight';

export const Strong = styled.strong`
  font-weight: 600;
  font-size: inherit;
`;

Strong.displayName = 'Strong';
