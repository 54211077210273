import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Text,
  FancyCard,
  FlexRow,
  FlexColumn,
  Header,
  Button,
  Icon,
  CheckNew,
  Container,
  Row,
  ResponsiveColumn,
  COLORS,
} from '@summer/ui-components';

import {
  mapPropsForFiona,
  refiTooltipText,
} from 'components/pages/refinancing/utils';
import PrivacyPolicyLink from 'components/shared/PrivacyPolicyLink';
import { Link } from 'components/shared/typography';
import { MobileSize } from 'constants/styleguide';
import { usePlatform } from 'hooks/platform';
import { trackServerSide } from 'redux/actions/analytics.actions';
import {
  hasPlaidLoans,
  userHasFederalLoans,
} from 'redux/selectors/loans.selectors';
import {
  getUserFirstName,
  getUserLastName,
  getUserPhone,
} from 'redux/selectors/profile.selectors';
import {
  getRefiFederalLoansRecommendation,
  getRefiLowestMonthlyPaymentValues,
  getRefiLowestTotalPaidValues,
  getRefiPrivateLoansRecommendation,
} from 'redux/selectors/recEngine.selectors';
import {
  getUserCreditScore,
  getUserEmail,
  getUserId,
  getUserPrivateLoansEstimate,
  getUserSalary,
  userIndicatedHasFederalLoans,
  userIndicatedHasPrivateLoans,
  getUserFederalLoansEstimate,
  getUserPartnerKey,
} from 'redux/selectors/user.selectors';
import { hexToRgba } from 'utils/common';

import FederalRefiAlert from './FederalRefiAlert';
import { RefiFaq, RefiFooterMessage, RefiLogos } from './RefiFooter';
import RefiToggle from './RefiToggle';

const CheckmarkIcon = () => (
  <Icon
    width={13}
    SvgComponent={CheckNew}
    fill={hexToRgba(COLORS.darkGreen, 0.6)}
  />
);

const Disclaimer = () => (
  <DisclaimerText size="extraSmall" paragraph>
    *These are estimates for your combined loans based on the information you
    shared.{' '}
  </DisclaimerText>
);

const RefiPage = ({
  annualIncome,
  creditScore,
  email,
  federalLoansEstimate,
  firstName,
  lastName,
  primaryPhone,
  privateLoansEstimate,
  userId,
  refiLowestMonthlyPaymentValues,
  refiLowestTotalPaidValues,
  userIndicatedHasFederalLoans,
  userIndicatedHasPrivateLoans,
  refiFederalRecommended,
  refiPrivateRecommended,
  hasFederalLoans,
  hasPrivateLoans,
  trackServerSide,
}) => {
  const getQueryParams = () => {
    const { app, tag } = mapPropsForFiona({
      annualIncome,
      creditScore,
      email,
      federalLoansEstimate,
      firstName,
      lastName,
      primaryPhone,
      privateLoansEstimate,
      userId,
      refiFederalRecommended,
      refiPrivateRecommended,
      hasFederalLoans,
      hasPrivateLoans,
    });

    const params = new URLSearchParams();

    Object.keys(app).forEach(appKey =>
      params.set(`filter.${appKey}`, app[appKey]),
    );

    Object.keys(tag).forEach(tagKey =>
      params.set(`tag.${tagKey}`, tag[tagKey]),
    );

    return `?loan_flow=application&step=application&filter.purpose=student_loan_refi&${params.toString()}`;
  };

  const [isLowestMonthlyPaymentSelected, setIsLowestMonthlyPaymentSelected] =
    useState(true);

  const data = isLowestMonthlyPaymentSelected
    ? refiLowestMonthlyPaymentValues
    : refiLowestTotalPaidValues;

  const getTooltipText = () => {
    if (userIndicatedHasFederalLoans && userIndicatedHasPrivateLoans) {
      return refiTooltipText.federalAndPrivate;
    }
    if (userIndicatedHasFederalLoans) {
      return refiTooltipText.federalOnly;
    }
    if (userIndicatedHasPrivateLoans) {
      return refiTooltipText.privateOnly;
    }
    return null;
  };

  const { isMobile, windowWidth } = usePlatform();

  return (
    <>
      <Container fullWidth>
        <Row center>
          {/* TODO: [ch25377] Add properties for smallDesktopCol and check widths 1025px-1110px */}
          <ResponsiveColumn
            desktopCol={windowWidth > 1200 ? 8 : 12}
            tabletCol={12}
            mobileCol={12}
          >
            <CardContainer>
              <FederalRefiAlert />
              <FancyCard
                headerInfo={[
                  {
                    title: 'Est. monthly payment:',
                    value: data.monthlyPayment,
                    tooltipText: getTooltipText(),
                    animate: true,
                  },
                ]}
              >
                <CardContent>
                  <RefiDescription>
                    <Header h2>
                      With interest rates on the move, now may be the perfect
                      time to lock in a lower rate.
                    </Header>
                    <List>
                      <ListItem>
                        Fill out a single form to get prequalified, personalized
                        rates
                      </ListItem>
                      <ListItem>Compare offers from several lenders</ListItem>
                      <ListItem>
                        Select the lender that best fits your needs at no extra
                        cost
                      </ListItem>
                    </List>
                    {!isMobile && <Disclaimer />}
                  </RefiDescription>
                  <RefiData>
                    <RefiToggle
                      isLowestMonthlyPaymentSelected={
                        isLowestMonthlyPaymentSelected
                      }
                      setIsLowestMonthlyPaymentSelected={
                        setIsLowestMonthlyPaymentSelected
                      }
                    />
                    <Link
                      href={`https://hifiona.com/meet-summer-embed/loans${getQueryParams()}`}
                      onClick={() =>
                        trackServerSide('User clicked through to Even')
                      }
                    >
                      <Button fullWidth={true}>Check your rate</Button>
                    </Link>
                    {isMobile && <Disclaimer />}
                    <FionaDisclaimerText size="extraSmall" paragraph>
                      By checking your rates, you will be taken to our 3rd party
                      partner Fiona&rsquo;s student loan refinancing
                      marketplace.
                    </FionaDisclaimerText>
                    <HorizontalLine />
                  </RefiData>
                </CardContent>
              </FancyCard>
            </CardContainer>
          </ResponsiveColumn>
        </Row>
      </Container>
      <Background>
        <Container fullWidth>
          <Row center>
            <ResponsiveColumn desktopCol={10} tabletCol={12} mobileCol={12}>
              <RefiLogos />
            </ResponsiveColumn>
          </Row>
        </Container>
      </Background>
      <Container fullWidth>
        <FooterRow center>
          <ResponsiveColumn desktopCol={3} tabletCol={12} mobileCol={12}>
            <RefiFooterMessage />
          </ResponsiveColumn>
          <ResponsiveColumn desktopCol={5} tabletCol={12} mobileCol={12}>
            <RefiFaq />
            <PrivacyPolicyLinkRefiPage />
          </ResponsiveColumn>
        </FooterRow>
      </Container>
    </>
  );
};

const CardContainer = styled.div`
  position: relative;
  margin-top: 32px;
  z-index: 1;
  overflow: auto;

  .FancyCardHeader {
    padding-right: 32px;
  }
`;

const CardContent = styled(FlexRow)`
  text-align: left;
  padding: 0 8px;
  @media (max-width: ${MobileSize}) {
    flex-direction: column-reverse;
  }
`;

const List = styled.ul`
  list-style-type: none;
  margin-top: 16px;
  margin-bottom: 16px;

  & li {
    display: flex;
    margin-bottom: 16px;
    align-items: baseline;

    :last-child {
      margin-bottom: 0;
    }

    .text {
      display: inline;
    }

    .icon {
      display: inline;
      margin-right: 8px;
    }
  }
`;

const ListItem = ({ children }) => (
  <li>
    <CheckmarkIcon />
    <Text paragraph>{children}</Text>
  </li>
);

const RefiDescription = styled(FlexColumn)`
  justify-content: flex-start;

  @media (min-width: ${MobileSize}) {
    margin-right: 62px;
  }
`;

const RefiData = styled.div`
  flex-shrink: 0;

  @media (min-width: ${MobileSize}) {
    max-width: 280px;
  }
`;

const DisclaimerText = styled(Text)`
  margin: 16px 0;

  @media (min-width: ${MobileSize}) {
    margin: auto 0 0 0;
  }
`;

const FionaDisclaimerText = styled(Text)`
  margin-top: 16px;
`;

const HorizontalLine = styled.div`
  @media (max-width: ${MobileSize}) {
    margin: 24px 0;
    border: solid 1px ${COLORS.grey};
  }
`;

const Background = styled.div`
  margin-top: 24px;
  width: 100%;
  background-color: ${COLORS.white};
`;

const FooterRow = styled(Row)`
  margin-top: 16px;
  margin-bottom: 42px;
`;

const PrivacyPolicyLinkRefiPage = styled(PrivacyPolicyLink)`
  justify-content: flex-start;
  text-align: left;
  padding: 30px 0px 35px;
`;

const mapStateToProps = state => ({
  annualIncome: getUserSalary(state),
  creditScore: getUserCreditScore(state),
  email: getUserEmail(state),
  federalLoansEstimate: getUserFederalLoansEstimate(state),
  firstName: getUserFirstName(state),
  lastName: getUserLastName(state),
  primaryPhone: getUserPhone(state),
  privateLoansEstimate: getUserPrivateLoansEstimate(state),
  userId: getUserId(state),
  userIndicatedHasFederalLoans: userIndicatedHasFederalLoans(state),
  userIndicatedHasPrivateLoans: userIndicatedHasPrivateLoans(state),
  refiLowestMonthlyPaymentValues: getRefiLowestMonthlyPaymentValues(state),
  refiLowestTotalPaidValues: getRefiLowestTotalPaidValues(state),
  refiFederalRecommended: getRefiFederalLoansRecommendation(state),
  refiPrivateRecommended: getRefiPrivateLoansRecommendation(state),
  hasFederalLoans: userHasFederalLoans(state),
  hasPrivateLoans: hasPlaidLoans(state),
  partnerKey: getUserPartnerKey(state),
});

export default connect(mapStateToProps, { trackServerSide })(memo(RefiPage));
