import { isEmpty } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Header } from '@summer/ui-components';

import { ManageAllPlaidLoansButton } from 'components/common/SyncLoans/Buttons';
import ServicersLoansTables from 'components/common/ViewLoans/ServicersLoansTables';
import PayOff from 'components/pages/loans/components/PayOff';
import { MobileSize } from 'constants/styleguide';
import { usePlatform } from 'hooks/platform';
import { getPlaidFederalLoans } from 'redux/selectors/plaid.selectors';
import {
  getNormalizedPlaidLoans,
  findActiveServicers,
  findInactiveServicers,
} from 'utils/loans';

const LoansView = ({ plaidFederalLoans, openPlaid }) => {
  const { isMobile } = usePlatform();

  const headerText = 'Your Federal Student Loans';

  const columnsToShow = ['loanType', 'interestRate', 'currentBalance'];

  const allActiveServicers = findActiveServicers(plaidFederalLoans);

  const allInactiveServicers = findInactiveServicers(plaidFederalLoans);

  return (
    <Container>
      <HeaderContainer>
        {!isMobile && (
          <Left>
            <Header h2>{headerText}</Header>
          </Left>
        )}
        <ManagePlaidLoans
          openPlaid={openPlaid}
          plaidLoans={plaidFederalLoans}
        />
      </HeaderContainer>
      <ServicersLoansTables
        normalizedLoans={plaidFederalLoans}
        allServicers={allActiveServicers}
        active={true}
        columnsToShow={columnsToShow}
        onClickTableRow={null}
      />
      <PayOff openByDefault={true} hide={isEmpty(allInactiveServicers)}>
        <ServicersLoansTables
          normalizedLoans={plaidFederalLoans}
          allServicers={allInactiveServicers}
          active={false}
          columnsToShow={columnsToShow}
          onClickTableRow={null}
          tableName="paid-off"
        />
      </PayOff>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
    margin-bottom: 24px;
  }
`;

const Left = styled.div`
  && > * {
    margin-bottom: 16px;
  }

  @media (max-width: ${MobileSize}) {
    margin-top: 16px;
    text-align: center;
  }
`;

const ManagePlaidLoans = styled(ManageAllPlaidLoansButton)`
  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

const mapStateToProps = state => ({
  plaidFederalLoans: getNormalizedPlaidLoans(getPlaidFederalLoans(state)),
});

export default connect(mapStateToProps)(LoansView);
