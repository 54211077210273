import * as Sentry from '@sentry/browser';
import React, { Component } from 'react';

import Error from 'components/pages/Error';
import { track } from 'utils/track';

const SENTRY_ISSUE_URL =
  'https://sentry.io/organizations/summer/issues/?project=1392804&query=';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({
      hasError: true,
    });

    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);

      const eventId = Sentry.captureException(error);

      this.setState({ eventId });

      track('react catch an error', {
        sentryEvent: `${SENTRY_ISSUE_URL}${eventId}`,
      });
    });
  }

  render() {
    if (this.state.hasError) {
      return <Error eventId={this.state.eventId} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
