import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Header, Text, Strong, COLORS } from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import ModalPopup from 'components/shared/ModalPopup';
import { Link } from 'components/shared/typography';
import { fsaServicersLink } from 'constants/externalLinks/fsa';
import { MobileSize } from 'constants/styleguide';
import { fetchServicers } from 'redux/actions/constants.actions';
import { getServicers } from 'redux/selectors/servicers.selectors';

const ServicersModal = ({ children, platformType, servicers }) => {
  const dispatch = useDispatch();
  const isMobile = platformType === MOBILE;

  const servicersToExclude = [
    'Debt Management and Collections System',
    'FSA',
    'MyEdDebt',
    'University / Other',
    'Test Fax Servicer',
    'AES',
    'Navient',
    'CornerStone',
    'FedLoan',
    'Great Lakes',
    'Granite State - GSMR',
    'OSLA Servicing',
    'PHEAA',
  ];
  const servicersToInclude = servicers.filter(servicer => {
    return !servicersToExclude.includes(servicer.companyName);
  });
  const servicersHalfLength = Math.ceil(servicersToInclude.length / 2);
  const servicersFirstHalf = [...servicersToInclude].splice(
    0,
    servicersHalfLength,
  );
  const servicersSecondHalf = [...servicersToInclude].splice(
    servicersHalfLength,
    servicers.length - 1,
  );

  const fsaicPhoneNumber = '1-800-433-3243';

  useEffect(() => {
    dispatch(fetchServicers());
  }, [dispatch]);

  const renderServicer = (servicer, key) => {
    return (
      <Servicer key={key}>
        <ServicerLink>
          <Link track inheritColor underline href={servicer.website}>
            <Strong>{servicer.companyName}</Strong>
          </Link>
        </ServicerLink>
        <Link inheritColor inheritWeight href={`tel:${servicer.contactPhone}`}>
          {servicer.contactPhone}
        </Link>
      </Servicer>
    );
  };

  return (
    <ModalPopup
      width={720}
      kind="loan-types"
      component={() => (
        <Container>
          <HeaderContainer>
            <Header as={isMobile ? 'h3' : 'h1'}>Contact your servicer</Header>
          </HeaderContainer>
          <ServicersContainer>
            <div>
              {servicersFirstHalf.map((servicer, i) =>
                renderServicer(servicer, i),
              )}
            </div>
            <SecondServicerSection>
              {servicersSecondHalf.map((servicer, i) =>
                renderServicer(servicer, i),
              )}
            </SecondServicerSection>
          </ServicersContainer>
          <ServicerBox>
            <Text as="p" paragraph>
              <Strong>Not sure who your servicer is?</Strong>
            </Text>
            <Text as="p" paragraph>
              If you&rsquo;ve synced your loans with Summer, you can find this
              information under &lsquo;My Federal Loans.&rsquo; You can also
              check your servicer through{' '}
              <Link underline inheritColor href={fsaServicersLink}>
                Federal Student Aid{' '}
              </Link>
              online or by calling{' '}
              <Link underline inheritColor href={`tel:${fsaicPhoneNumber}`}>
                {fsaicPhoneNumber}
              </Link>
              .
            </Text>
          </ServicerBox>
        </Container>
      )}
    >
      {children}
    </ModalPopup>
  );
};

const Container = styled.div`
  p {
    margin-bottom: 8px;
  }

  h5 {
    margin-top: 24px;
    margin-bottom: 8px;
  }
`;

const HeaderContainer = styled.div`
  padding-bottom: 17px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${COLORS.grey};

  @media (max-width: ${MobileSize}) {
    padding-bottom: 24px;
  }
`;

const ServicersContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  color: ${COLORS.darkerGrey};

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
  }
`;

const SecondServicerSection = styled.div`
  margin-left: 100px;

  @media (max-width: ${MobileSize}) {
    margin-left: 0;
  }
`;

const Servicer = styled.div`
  margin-bottom: 20px;
`;

const ServicerLink = styled.div`
  padding-bottom: 5px;
`;

const ServicerBox = styled.div`
  background-color: ${COLORS.lighterGrey};
  padding: 24px;
`;

const mapStateToProps = state => ({
  servicers: getServicers(state),
});

export default Platform(connect(mapStateToProps)(ServicersModal));
