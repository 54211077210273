import React from 'react';
import styled from 'styled-components';

import {
  FlexColumn,
  FlexRow,
  Header,
  Close,
  Icon,
  SummerLogoMedallion,
  COLORS,
} from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import { MobileSize, SmallDesktopSize } from 'constants/styleguide';

const ModalView = ({
  children,
  handleClose,
  headerText,
  platformType,
  showLogo,
}) => (
  <Container>
    <HeaderContainer>
      <Title>
        {showLogo && (
          <SummerLogoHeader size={platformType === MOBILE ? 32 : 43} />
        )}
        <Header h4>{headerText}</Header>
      </Title>
      <CloseIcon
        onClick={handleClose}
        SvgComponent={Close}
        fill={COLORS.medGrey}
      />
    </HeaderContainer>
    {children}
  </Container>
);

const Container = styled(FlexColumn)`
  height: 100%;

  @media (max-width: ${SmallDesktopSize}) {
    height: auto;
    min-height: 100%;
  }
`;

const HeaderContainer = styled(FlexRow)`
  min-height: 68px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px ${COLORS.grey};
  padding: 0 16px;
  background: ${COLORS.lightestGrey};
  z-index: 1;

  @media (max-width: ${MobileSize}) {
    padding: 16px 24px;
  }
`;

const SummerLogoHeader = styled(SummerLogoMedallion)`
  margin-right: 16px;
`;

const CloseIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  right: 16px;
  width: 13px;

  @media (max-width: ${MobileSize}) {
    width: 17px;
  }
`;

const Title = styled(FlexRow)`
  align-items: center;
`;

export default Platform(ModalView);
