/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import styled from 'styled-components';

import { Header, Text } from '@summer/ui-components';
import { COLORS } from '@summer/ui-components/src/helpers/constants/styleguide';

import Link from 'components/shared/typography/Link';
import WizardGif from 'hooks/Wizard/Demo/shared/WizardGif';
import { useWizard } from 'hooks/Wizard/useWizard';

// the order is importent

const renderWizardHeaders = headers => {
  return headers.map(({ title }, index) => (
    <HeaderContainer key={index}>
      <Header as="h5">{title}</Header>
    </HeaderContainer>
  ));
};

const renderWizardSteps = steps => {
  return steps.map(step => step.render());
};

const WizardDemo = () => {
  const [state, setState] = React.useState({ selected: true });
  const handleOnChange = () => {
    setState({ selected: !state.selected });
  };

  const config = React.useMemo(
    () => [
      {
        title: 'title -> Step1',
        render: props => (
          <StepComponent {...props} color={COLORS.azure}>
            <Text>step1</Text>
          </StepComponent>
        ),
      },
      {
        title: 'title -> Step2',
        render: props => (
          <StepComponent {...props} color={COLORS.orange}>
            <Text>step2</Text>
            <input
              checked={state.selected}
              onChange={handleOnChange}
              type="checkbox"
            />
            <Text>with step 4</Text>
          </StepComponent>
        ),
      },
      {
        title: 'title -> Step3',
        render: ({ navigation, key }) => (
          <StepComponent key={key} color={COLORS.yellow}>
            {navigation.isLastStep ? (
              <Text>This is the Last Step!!</Text>
            ) : (
              <Text>step3</Text>
            )}
          </StepComponent>
        ),
      },
      {
        title: 'title -> Step4',
        render: ({ navigation, key }) => (
          <StepComponent key={key} color={COLORS.orange}>
            {navigation.isLastStep ? (
              <Text>This is the Last Step!!</Text>
            ) : (
              <Text>step4</Text>
            )}
          </StepComponent>
        ),
        condition: state.selected,
      },
    ],
    [state],
  );

  const { steps, headers, navigation } = useWizard({
    config,
  });

  return (
    <Container>
      <WizardGif />
      <Header as="h1">Here Will Be Demo Of The Wizard</Header>
      <Header as="h2">
        <span role="img" aria-label="emoji">
          🎢🔮
        </span>
      </Header>
      <Link onClick={navigation.back}>Back</Link>
      <Link onClick={navigation.next}>Next</Link>
      <Wizard>
        <Headers>{renderWizardHeaders(headers)}</Headers>
        <Steps>
          <StepContainer>{renderWizardSteps(steps)}</StepContainer>
        </Steps>
      </Wizard>
      <div style={{ margin: '10px' }}>{`Visitor Number: ${Math.round(
        Math.random() * 10000,
      )}`}</div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Headers = styled.div`
  display: flex;
`;
const HeaderContainer = styled.div`
  border: 1px solid;
  flex: 1 1 auto;
  padding: 10px;
  text-align: center;
`;
const StepContainer = styled.div`
  flex: 1 1 auto;
  height: 100%;
  padding: 10px;
  text-align: center;
`;
const StepComponent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ color }) => color};
`;
const Steps = styled.div`
  flex: 1 0 auto;
`;

const Wizard = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
`;

export default WizardDemo;
