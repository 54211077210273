import React from 'react';
import styled from 'styled-components';

import { Header, Text, Button, FancyCard } from '@summer/ui-components';

import Link from 'components/shared/typography/Link';
import { FRESH_START_BLOG_POST_URL } from 'constants/getOutOfDefault';
import { MobileSize } from 'constants/styleguide';

const DefaultFeatureContent = () => (
  <CardContentContainer>
    <ContentContainer>
      <Header h2>Let&rsquo;s get your federal loans out of default</Header>
      <Text paragraph weight="medium" size="medium">
        Getting your student loans out of default has never been easier - the
        Default Fresh Start program is currently underway! You&rsquo;ll be able
        to get your loans back in good standing, lower your monthly payments,
        and improve your credit score when you take action before the end of
        2023.
      </Text>
    </ContentContainer>
    <CTAContainer>
      <Link href={FRESH_START_BLOG_POST_URL}>
        <Button width={144}>Learn More</Button>
      </Link>
    </CTAContainer>
  </CardContentContainer>
);

const GetOutOfDefault = ({ platformType }) => (
  <FancyCard
    title="GET BACK ON TRACK"
    color="raven"
    testId="rec-card-get-out-of-default"
  >
    <DefaultFeatureContent platformType={platformType} />
  </FancyCard>
);

const CardContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: inherit;
  align-items: center;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;

    > * {
      margin-bottom: 16px;
      :last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const ContentContainer = styled.div`
  margin-right: 8px;
  max-width: 80%;

  > * {
    margin-bottom: 12px;
    :last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: ${MobileSize}) {
    max-width: 100%;
  }
`;

const CTAContainer = styled.div`
  text-align: right;
  align-self: flex-end;
  width: 144px;

  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;
export default GetOutOfDefault;
