import { push as redirectTo } from 'connected-react-router';
import { includes } from 'lodash';

import {
  FETCH_CURRENT_STEP,
  FILL_LOAN_INFO,
  FILL_SURVEY,
  FINISH_WELCOME,
  GO_BACK_ONBOARDING,
  NETWORK_SUCCESS,
  SET_NEXT_STEP,
} from 'constants/actionTypes';
import {
  loadingRecommendations,
  loanInfo,
  questions,
} from 'constants/onboardingSteps';
import {
  persistOnboardingStep,
  setOnboardingStep,
} from 'redux/actions/onboard.actions';
import { fetchRecommendations } from 'redux/actions/recEngine.actions';
import { setLoanData } from 'redux/actions/user.actions';
import handleSideEffects from 'redux/handleSideEffects';

const onboardingMiddleware = handleSideEffects({
  [GO_BACK_ONBOARDING]: (dispatch, { payload: { page } }) => {
    let module;
    switch (page) {
      case 'questions':
        module = questions;
        break;
      case 'loanInfo':
        module = loanInfo;
        break;
      default:
        return;
    }
    dispatch(setOnboardingStep(module));
    dispatch(persistOnboardingStep(module));
    dispatch(redirectTo(module));
  },

  [FINISH_WELCOME]: dispatch => {
    dispatch(setOnboardingStep(questions));
    dispatch(redirectTo(questions));
  },

  [SET_NEXT_STEP]: (
    dispatch,
    { payload: { currentStep }, meta: { persistStep } },
  ) => {
    if (persistStep === true) {
      dispatch(persistOnboardingStep(currentStep));
    }
  },

  [NETWORK_SUCCESS]: (dispatch, { payload, meta }, state) => {
    const { state: locationState, pathname } = state?.router?.location;

    if (meta.fromAction === FILL_SURVEY) {
      dispatch(setOnboardingStep(loanInfo));
      dispatch(redirectTo(loanInfo, locationState));
    }

    if (meta.fromAction === FILL_LOAN_INFO) {
      dispatch(setLoanData(meta.request.body));

      if (!includes(pathname, 'onboard')) {
        dispatch(fetchRecommendations());
        return;
      }

      dispatch(setOnboardingStep(loadingRecommendations));
      dispatch(redirectTo(loadingRecommendations));
    }

    if (meta.fromAction === FETCH_CURRENT_STEP) {
      dispatch(setOnboardingStep(payload.response.currentStep));
    }
  },
});

export default onboardingMiddleware;
