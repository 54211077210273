import React from 'react';

import { LoadingFullPage } from '@summer/ui-components';

import { usePaywallSuccess } from 'hooks/paywall';

const PaywallSuccessIdr = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const sessionId = params.get('sessionId');

  usePaywallSuccess({ sessionId, redirectPath: '/idr/submit/contact-info' });

  return <LoadingFullPage />;
};

export default PaywallSuccessIdr;
