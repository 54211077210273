import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { MoneyInput } from '@summer/ui-components';

import StaticField from 'components/common/ManageLoans/fields/shared/StaticField';
import InfoTooltip from 'components/shared/InfoTooltip';
import { isLoanFieldEditable } from 'redux/selectors/loans.selectors';
import { dollars } from 'utils/numbers';

const AmountBorrowedLabel = () => (
  <>
    <span>Amount Borrowed</span>
    <InfoTooltip
      text="This is the original amount when you first received your loan."
      top={2}
    />
  </>
);

class AmountBorrowed extends React.Component {
  componentWillUnmount() {
    if (this.props.canEdit) {
      this.props.formik.setFieldValue('amountBorrowed', null);
    }
  }

  render() {
    const {
      formik: {
        values,
        errors,
        setFieldValue,
        setFieldTouched,
        handleBlur,
        touched,
        isSubmitting,
      },
      canEdit,
    } = this.props;
    return (
      <Container id="amountBorrowed">
        <InputContainer>
          {canEdit ? (
            <MoneyInput
              name="amountBorrowed"
              value={values.amountBorrowed}
              error={errors.amountBorrowed}
              touched={touched.amountBorrowed}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              onBlur={handleBlur}
              isSubmitting={isSubmitting}
              height={40}
              label={<AmountBorrowedLabel />}
            />
          ) : (
            <StaticField
              label={<AmountBorrowedLabel />}
              value={dollars(values.amountBorrowed)}
            />
          )}
        </InputContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  flex: 1;
`;

const InputContainer = styled.div`
  width: 100%;
`;

const mapStateToProps = (state, props) => {
  const loanId = props.formik.values.id;
  return { canEdit: isLoanFieldEditable(state, loanId, 'amountBorrowed') };
};

export default connect(mapStateToProps)(AmountBorrowed);
