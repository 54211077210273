import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  FlexColumn,
  Text,
  IconMedallion,
  TargetPhone,
  ReceiptViewPricing,
  ServerCash,
  COLORS,
  TabletSize,
  Header,
} from '@summer/ui-components/src';

import LandingPage from 'components/shared/dashboard/LandingPage';
import { Link } from 'components/shared/typography';
import { MobileSize } from 'constants/styleguide';
import { usePlatform } from 'hooks/platform';
import {
  getIsCSP529MarketplaceEnabled,
  getIsCSPScholarshipDatabaseEnabled,
} from 'redux/selectors/flags.selectors';
import { hexToRgba } from 'utils/common';

import CollegeSavingsPlannerResourceCenter from '../calculator/resourceCenter';

const CollegeSavingsPlannerCalculator = ({
  showCSP529Marketplace,
  showCSPScholarshipDatabase,
}) => {
  const { isMobile, isTablet } = usePlatform();
  const tools = [
    {
      header: 'Calculate College Cost',
      body: 'Discover your college savings needs, federal aid estimates, and gap-bridging strategies with our tool.',
      linkTo: '/college-savings-planner/calculator',
      linkCta: 'Start Calculator',
      icon: TargetPhone,
      enabled: true,
    },
    {
      header: '529 Plan',
      body: 'Find the best plans to help with tax advantaged options for saving for future education expenses.',
      linkTo: '/college-savings-planner/529-marketplace',
      linkCta: 'Explore Plans',
      icon: ReceiptViewPricing,
      enabled: showCSP529Marketplace,
    },
    {
      header: 'Scholarship Database',
      body: 'Gain access to various scholarships and grants for your educational funding.',
      linkTo: '/college-savings-planner/scholarship-database',
      linkCta: 'Explore Database',
      icon: ServerCash,
      enabled: showCSPScholarshipDatabase,
    },
  ];

  return (
    <LandingPage
      headerTheme="orange"
      headerName="College Savings Planner"
      subHeader={
        <Subheader paragraph weight="medium">
          Use our tool to find out how much you need to save for college, how
          much you can expect in federal aid, and strategies for bridging the
          gap.
        </Subheader>
      }
    >
      <Content>
        <ToolsContainer>
          <Tools>
            {tools.map(
              ({ header, body, linkTo, linkCta, icon, enabled }) =>
                enabled && (
                  <Tool key={header}>
                    <IconMedallion
                      size={isMobile || isTablet ? 100 : 190}
                      paintWith="fill"
                      SvgComponent={icon}
                      iconColor={COLORS.raven}
                      backgroundColor={hexToRgba(COLORS.orange, 0.08)}
                    />
                    <Header h3 center>
                      {header}
                    </Header>
                    <ToolBody paragraph center>
                      {body}
                    </ToolBody>
                    <Link to={linkTo}>{linkCta}</Link>
                  </Tool>
                ),
            )}
          </Tools>
        </ToolsContainer>

        <CollegeSavingsPlannerResourceCenter />
      </Content>
    </LandingPage>
  );
};

const Subheader = styled(Text)`
  margin: 16px 8px 0 8px;
`;

const ToolsContainer = styled.div`
  background-color: white;
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 #ebeef4;
`;

const Tools = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 18px;
  padding: 0;

  @media (max-width: ${TabletSize}) {
    flex-direction: column;
  }
`;

const Tool = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 24px 16px;
  border-radius: 6px;
  border: solid 1px #dfe3e8;
  background-color: #fff;
`;

const ToolBody = styled(Text)`
  padding: 0 16px;

  @media (min-width: ${TabletSize}) {
    flex-basis: 100px;
  }
`;

const Content = styled(FlexColumn)`
  position: relative;
  padding: 48px 32px 144px;
  margin: 0 auto;
  max-width: 1080px;
  gap: 48px;
  z-index: 1;

  @media (max-width: ${MobileSize}) {
    padding: 28px 24px 0;
  }
`;

const mapStateToProps = state => ({
  showCSP529Marketplace: getIsCSP529MarketplaceEnabled(state),
  showCSPScholarshipDatabase: getIsCSPScholarshipDatabaseEnabled(state),
});

export default connect(mapStateToProps)(CollegeSavingsPlannerCalculator);
