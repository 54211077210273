import React from 'react';
import styled from 'styled-components';

import {
  CardTable,
  Cell,
  CELL_TYPES,
  FlexColumn,
  Header,
} from '@summer/ui-components/src';

import { generateDayMonthYearCopy } from 'utils/date';
import { dollars } from 'utils/numbers';

const SavingsHistory = ({ deposits }) => {
  if (!Array.isArray(deposits) || deposits.length === 0) {
    return null;
  }

  const data = deposits.map(({ type, amount, datePaid, fundsSource }) => ({
    type,
    amount: dollars(amount),
    datePaid: generateDayMonthYearCopy(datePaid),
    fundsSource,
  }));

  const columns = [
    {
      Header: 'Type',
      accessor: 'type',
      show: true,
    },
    {
      Cell: AmountPaidCell,
      Header: 'Amount',
      accessor: 'amount',
      show: true,
    },
    {
      Cell: DatePaidCell,
      Header: 'Date Paid',
      accessor: 'datePaid',
      show: true,
    },
    {
      Header: 'Funds Source',
      accessor: 'fundsSource',
      show: true,
    },
  ];

  return (
    <Container>
      <Header as="h3">Savings History</Header>
      <Table>
        <CardTable align="right" columns={columns} data={data} />
      </Table>
    </Container>
  );
};

const DatePaidCell = ({ cell }) => (
  <Cell type={CELL_TYPES.STRING} value={cell.value} />
);

const AmountPaidCell = ({ cell }) => (
  <Cell type={CELL_TYPES.MONEY} value={cell.value} />
);

const Container = styled(FlexColumn)`
  gap: 16px;
  width: 100%;
`;

const Table = styled.div`
  margin: 0 -10px -15px;
`;

export default SavingsHistory;
