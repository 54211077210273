import { isEmpty } from 'lodash';
import { useState } from 'react';

export const useClientSidePagination = ({ data = [], limit = 10 }) => {
  const [offset, setOffset] = useState(0);

  const numEntries = data.length;

  const currentLower = !isEmpty(data) ? offset + 1 : 0;
  const currentUpper = Math.min(offset + limit, numEntries);
  const numPages = Math.ceil(numEntries / limit);
  const currentPageIndex = Math.floor(offset / limit);

  const overPageLimit = numPages > 1;

  const dataToDisplay = data.slice(offset, currentUpper);

  const setActiveIndex = activeIndex => {
    setOffset(activeIndex * limit);
  };

  return {
    dataToDisplay,
    overPageLimit,
    numPages,
    currentLower,
    currentUpper,
    currentPageIndex,
    setActiveIndex,
  };
};
