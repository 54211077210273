import React from 'react';
import styled from 'styled-components';

import {
  Button,
  FlexRow,
  Icon,
  InfinitySymbol,
  COLORS,
} from '@summer/ui-components';

const SyncButton = ({ onClick }) => (
  <Button onClick={onClick} width={222} small>
    <ButtonContentContainer>
      <FlexRow>
        <Icon
          SvgComponent={InfinitySymbol}
          width={27}
          style={{ marginRight: '16px', cursor: 'pointer' }}
          stroke={COLORS.white}
        />
        Sync your loans
      </FlexRow>
    </ButtonContentContainer>
  </Button>
);

const ButtonContentContainer = styled(FlexRow)`
  justify-content: space-around;
  align-items: center;
`;

export default SyncButton;
