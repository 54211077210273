import React from 'react';

import { Strong } from '@summer/ui-components';

import {
  List,
  ListItem,
  SmallTitle,
  GuideText,
} from 'components/pages/resourceCenter/guides/shared';
import Section from 'components/pages/resourceCenter/guides/shared/Section';
import { Link } from 'components/shared/typography';
import { freCreditReportLink } from 'constants/externalLinks/resources';

class RefinancingGuide extends React.Component {
  render() {
    return (
      <>
        <Section title="Overview">
          <SmallTitle>What is refinancing?</SmallTitle>
          <GuideText>
            Refinancing lets you get a new loan with better terms than your
            current one. Better terms can mean a lower interest rate (so you pay
            less for your loan) and/or a more convenient repayment schedule.
          </GuideText>

          <SmallTitle>How does refinancing work?</SmallTitle>
          <GuideText>
            When you refinance, you take on a new loan with a new lender. Then,
            your new lender pays off the loan (or loans) from your old lender.
            When you&rsquo;re done, you&rsquo;ll only have to pay your new
            lender. You&rsquo;ll have a new lender and a lower interest rate,
            saving you money.
          </GuideText>

          <SmallTitle>Anything else I should know?</SmallTitle>
          <GuideText>
            If you have <Strong>private loans</Strong>, it&rsquo;s pretty
            simple: you should refinance if you can get a lower rate.
          </GuideText>

          <GuideText>
            If you have <Strong>federal loans</Strong>, however, you
            shouldn&rsquo;t refinance your loans if you think you may have
            trouble making your monthly payments in the future. Federal loans
            have repayment plans that help borrowers who are in trouble. Also,
            if you plan on working in the non-profit or the public sector, your
            federal loans can be forgiven after 10 years. Refinancing costs you
            those benefits because it turns your federal loans into a private
            loan.
          </GuideText>
        </Section>

        <Section title="Eligibility">
          <GuideText>
            Here&rsquo;s what lenders typically look for to decide whether you
            can refinance your loans:
          </GuideText>

          <SmallTitle>Good credit</SmallTitle>
          <GuideText>
            You should have a credit score of 680 or higher to look your best
            when trying to refinance. To find out where you stand, you can get
            your free <Link href={freCreditReportLink}>credit score</Link> here.
          </GuideText>

          <SmallTitle>Low debt relative to how much you earn</SmallTitle>
          <GuideText>
            Your debt-to-income ratio should be less than 45%. To calculate your
            debt-to-income ratio, add up all of the monthly payments you owe on
            any outstanding debts (like your student debt, car loans, etc.) and
            divide that by your how much money you make each month before taxes
            (called your gross monthly income).
          </GuideText>

          <SmallTitle>A stable income</SmallTitle>
          <GuideText>
            You&rsquo;ll need to have a job and at least a few months worth of
            paystubs to qualify for refinancing, although different lenders may
            have different requirements in terms of how long you’ll need to have
            been working.
          </GuideText>

          <GuideText>
            Not all lenders are the same and not all of these requirements are
            written in stone, so make sure to check with lenders directly to see
            if you&rsquo;re eligible for refinancing your loans.
          </GuideText>
        </Section>

        <Section title="Advantages">
          <GuideText>
            So, what are the upsides of refinancing your loans? Let&rsquo;s go
            over how refinancing helps you.
          </GuideText>

          <SmallTitle>You save money.</SmallTitle>
          <GuideText>
            You can get a lower interest rate which will mean you pay less on
            your loans.
          </GuideText>

          <SmallTitle>It&rsquo;s easier.</SmallTitle>
          <GuideText>
            Refinancing many loans into one makes it simpler to manage your
            payments. Refinancing means you&rsquo;ll only need to pay one
            company each month instead of many.
          </GuideText>

          <SmallTitle>You get a flexible repayment schedule.</SmallTitle>
          <GuideText>
            When you refinance, you can also change your monthly payments and
            the length of your loan term to make sure it works for you. If you
            extend your repayment timeline when you refinance, your payments
            will go down now, but you&rsquo;ll make more in total payments
            overall. If you shorten your repayment timeline, however, you’ll pay
            more now but less overall.
          </GuideText>
        </Section>

        <Section title="Disadvantages">
          <GuideText>
            Now that you know about how refinancing can help you, let&rsquo;s
            cover the drawbacks to refinancing your loans.
          </GuideText>

          <SmallTitle>You&rsquo;ll lose your federal loan benefits.</SmallTitle>
          <GuideText>
            The government offers more support to borrowers who are struggling
            to make payments than private lenders that refinance your loans. If
            you refinance your federal loan for a private loan, you won&rsquo;t
            be eligible for federal repayment plans that can help save you money
            like income-driven repayment plans and Public Service Loan
            Forgiveness. Once you’re out, you’re out.
          </GuideText>

          <SmallTitle>The bottom line.</SmallTitle>
          <GuideText>
            If you think that your job situation may change in the near future
            and/or you may need some financial assistance in the future, then
            you should think twice before refinancing your federal loans.
            Refinancing costs you the chance to get into federal loan
            forgiveness programs that could really help you out.
          </GuideText>
        </Section>

        <Section title="Apply">
          <SmallTitle>
            Step 1: Know what you’re looking for when getting a new loan.
          </SmallTitle>
          <List>
            <ListItem>
              Calculate your current average interest rate. You should only
              refinance if a lender gives you an interest rate below your
              current weighted average interest rate.
            </ListItem>
            <ListItem>
              Pick the ideal repayment timeline and monthly payment amount for
              you. Want a lower monthly payment? If you do, you’ll want a longer
              timeline—but that also means you&rsquo;ll pay more for your loan
              in interest in the long run.
            </ListItem>
            <ListItem>
              Want to lower the total amount you’ll pay over the life of your
              loan? You’ll want a shorter timeline—but that also means
              you&rsquo;ll be paying more each month.
            </ListItem>
          </List>

          <SmallTitle>Step 2: Apply and compare.</SmallTitle>

          <List>
            <ListItem>
              <GuideText>
                You should shop around to different lenders to see which ones
                offer you the best rate. Each application is pretty
                straightforward and shouldn&rsquo;t take any more than 5
                minutes. Here are some of the student loan refinancers with the
                lowest rates:
              </GuideText>
              <List>
                <ListItem>SoFi</ListItem>
                <ListItem>CommonBond</ListItem>
                <ListItem>Laurel Road</ListItem>
                <ListItem>Lend Key</ListItem>
                <ListItem>Citizens Bank</ListItem>
                <ListItem>Earnest</ListItem>
              </List>
            </ListItem>

            <ListItem>
              Look for the lowest interest rate and the repayment schedule that
              works best for you.
            </ListItem>

            <ListItem>
              If you don&rsquo;t qualify for refinancing, it may be because your
              credit score is too low. Adding a co-signer—someone who&rsquo;d be
              responsible for paying back your loan if you couldn&rsquo;t (maybe
              a family member, for example)—could make the difference.
            </ListItem>
          </List>

          <SmallTitle>Step 3: Get enrolled.</SmallTitle>

          <List>
            <ListItem>
              Enrolling is the easy part—follow the instructions with your new
              lender to transfer your debt.
            </ListItem>
            <ListItem>
              Make sure you keep copies of all documentation and set up auto-pay
              or calendar reminders for your new monthly payments.
            </ListItem>
          </List>
        </Section>
      </>
    );
  }
}

export default RefinancingGuide;
