import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Text } from '@summer/ui-components';

class StepHeader extends Component {
  render() {
    return this.props.stepCount || this.props.stepMessage ? (
      <Box>
        <Text center bold color="secondary">
          {this.props.stepCount && `Step ${this.props.stepCount} of 2 `}
        </Text>
      </Box>
    ) : (
      <></>
    );
  }
}

const Box = styled.div`
  margin-bottom: 8px;
`;

const mapStateToProps = state => ({
  stepCount: state.onboarding.stepCount,
});

export default connect(mapStateToProps)(StepHeader);
