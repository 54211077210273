import { startCase } from 'lodash';
import React from 'react';

import { Header, Text } from '@summer/ui-components';

import SyncButton from 'components/common/SyncLoans/Buttons/SyncButton';
import {
  SyncBox,
  ContentContainer,
  SectionHeader,
} from 'components/pages/loans/components/LoansView';

const SyncCard = ({ loanType, onClick }) => (
  <>
    <SectionHeader>
      <Header h2>{startCase(loanType)} Loans</Header>
    </SectionHeader>
    <SyncBox>
      <ContentContainer>
        <Text>
          Have {loanType} loans? Connect them to see all your loans in one
          place.
        </Text>
      </ContentContainer>
      <SyncButton onClick={onClick} />
    </SyncBox>
  </>
);

export default SyncCard;
