import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { find, keys } from 'lodash';

import { textColors as colors } from '../../../helpers/constants/styleguide';
import htmlTagNames from '../../../helpers/constants/htmlTagNames';

const sizes = {
  inherit: 'inherit',
  extraSmall: '12px',
  small: '14px',
  medium: '16px',
  large: '18px',
  extraLarge: '20px',
};

const weight = {
  normal: 'normal',
  heavy: 900,
  bold: 600,
  medium: 500,
};

const sizeOptions = Object.keys(sizes);
const alignOptions = ['left', 'center', 'right', 'justify'];
const weightOptions = Object.keys(weight);

const getSize = props => {
  // this function provides us the shorthand syntax
  const propSize = find(sizeOptions, sizeOption => props[sizeOption]);

  if (propSize) {
    return sizes[propSize];
  }

  return sizes[props.size];
};

const getAlign = props => {
  // this function provides us the shorthand syntax
  const propAlign = find(alignOptions, alignOption => props[alignOption]);

  if (propAlign) {
    return propAlign;
  }

  return props.align;
};

const getWeight = props => {
  if (props.bold) {
    return weight.bold;
  }
  if (!props.weight) {
    return weight.normal;
  }

  return weight[props.weight];
};

const getLineHeight = props => {
  if (props.paragraph) {
    return '1.5';
  }
  return 'normal';
};

const truncateCss = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Text = ({ children, ...props }) => {
  const title = props.truncate ? props.tooltip : null;
  return (
    <StyledText title={!props.useCustomTooltip ? title : null} {...props}>
      {children}
    </StyledText>
  );
};

Text.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(sizeOptions),
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  /** Adds line-height of 1.5 for large chunks of copy */
  paragraph: PropTypes.bool,
  smallTracking: PropTypes.bool,
  truncate: PropTypes.bool,
  tooltip: PropTypes.string,
  align: PropTypes.oneOf(alignOptions),
  color: PropTypes.oneOf([...keys(colors), 'inherit']),
  decoration: PropTypes.string,
  weight: PropTypes.oneOf(weightOptions),
  useCustomTooltip: PropTypes.bool,
  /** Must be valid HTML tag */
  /* eslint-disable-next-line consistent-return */
  as: (props, propName) => {
    if (!htmlTagNames.includes(props[propName])) {
      // has to be a custom validator here because oneOf() was breaking with too long of an array
      return new Error(
        `Invalid prop \`${propName}\` supplied to Text. \`as\` must be a valid HTML tag name.`,
      );
    }
  },
};

Text.defaultProps = {
  children: '',
  size: 'medium',
  bold: false,
  italic: false,
  paragraph: false,
  truncate: false,
  smallTracking: false,
  align: null,
  color: 'default',
  as: 'div',
  weight: null,
  useCustomTooltip: false,
};

const StyledText = styled.div.attrs({
  className: 'text',
})`
  font-size: ${getSize};
  font-weight: ${getWeight};
  text-align: ${props => getAlign(props) || 'inherit'};
  color: ${props => colors[props.color]};
  line-height: ${getLineHeight};
  letter-spacing: ${props => (props.smallTracking ? 'normal' : 'inherit')};
  text-decoration: ${props => (props.decoration ? props.decoration : 'none')};
  font-style: ${props => (props.italic ? 'italic' : 'normal')};
  ${({ truncate }) => truncate && truncateCss};
`;

export const StorybookText = Text;
export default memo(Text);
