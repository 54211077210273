import React from 'react';
import styled from 'styled-components';

import { Icon, Text, CheckNew, COLORS } from '@summer/ui-components';

import { hexToRgba } from 'utils/common';

const RecommendedBadge = () => (
  <Recommended>
    <Icon
      width={10}
      height={10}
      SvgComponent={CheckNew}
      fill={hexToRgba(COLORS.darkGreen, 0.6)}
    />
    <Text bold size="small">
      Recommended
    </Text>
  </Recommended>
);

const Recommended = styled.div`
  display: inline-block;
  border-radius: 4px;
  padding: 4px 8px;
  margin-bottom: 16px;
  background-color: ${COLORS.lighterGrey};

  & .icon {
    display: inline;
    margin-right: 4px;
  }
  & .text {
    display: inline;
  }
`;
export default RecommendedBadge;
