import React, { Component } from 'react';

import { MoneyInput } from '@summer/ui-components';

import {
  Question,
  Label,
  SmallTextInputContainer,
} from 'components/pages/idr/wizard/shared/styles';
import { validateNumber } from 'utils/validate';

class SpouseCurrentLoanBalance extends Component {
  componentWillUnmount() {
    this.props.formik.setFieldValue(
      `tellUsAboutYou.spouseCurrentLoanBalance`,
      '',
    );
  }

  render() {
    const {
      formik: {
        values,
        errors,
        setFieldValue,
        setFieldTouched,
        handleBlur,
        touched,
        isSubmitting,
      },
    } = this.props;
    return (
      <div>
        <Question id="spouseCurrentLoanBalance">
          <Label>
            What is the total current balance of your spouse&rsquo;s federal
            student loans?
          </Label>
          <SmallTextInputContainer>
            <MoneyInput
              name="tellUsAboutYou.spouseCurrentLoanBalance"
              value={values.spouseCurrentLoanBalance}
              error={errors.spouseCurrentLoanBalance}
              touched={touched.spouseCurrentLoanBalance}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              isSubmitting={isSubmitting}
              onBlur={handleBlur}
              validate={validateNumber()}
            />
          </SmallTextInputContainer>
        </Question>
      </div>
    );
  }
}

export default SpouseCurrentLoanBalance;
