import React from 'react';
import { connect } from 'react-redux';

import { Alert } from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';
import { pslfIneligibleLoansCopy } from 'constants/pslf';
import { getHasSubmittedIdrForm } from 'redux/selectors/idr.selectors';
import { getHideSupportChat } from 'redux/selectors/user.selectors';
import { openChat } from 'services/chat';

const PslfIneligibleAlert = ({ hasSubmittedIdrForm, hideSupportChat }) => {
  const theme = 'neutral';
  const text = pslfIneligibleLoansCopy;

  const hasSubmittedIdrText = (
    <>
      It looks like you submitted an IDR form with Summer. Once your application
      is processed, you&rsquo;ll be eligible for PSLF and can continue with the
      PSLF application. It takes about 4 weeks for your servicer to process your
      application. Come back to Summer then to submit your PSLF application.
      Questions?{' '}
      {hideSupportChat ? (
        <>
          Email us at{' '}
          <Link underline inheritColor href={`mailto:${supportAddress}`}>
            {supportAddress}
          </Link>
        </>
      ) : (
        <Link underline inheritColor onClick={openChat}>
          Send us a message
        </Link>
      )}
      .
    </>
  );

  return hasSubmittedIdrForm ? (
    <Alert dismissable={false} theme={theme}>
      {hasSubmittedIdrText}
    </Alert>
  ) : (
    <Alert
      dismissable={false}
      theme={theme}
      callToAction={{
        label: 'Explore Consolidation/IDR',
        buttonWidth: 240,
        link: '/repayment',
      }}
    >
      {text}
    </Alert>
  );
};

const mapStateToProps = state => ({
  hasSubmittedIdrForm: getHasSubmittedIdrForm(state),
  hideSupportChat: getHideSupportChat(state),
});

export default connect(mapStateToProps)(PslfIneligibleAlert);
