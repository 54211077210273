import React from 'react';

import InfoPopup from 'components/pages/idr/wizard/shared/InfoPopup';

const ConsolidationInfoPopup = ({ children }) => (
  <InfoPopup
    kind="Consolidation pros cons"
    title="Why do we recommend consolidating your loans?"
    pros={[
      {
        description:
          'Consolidating your loans will make them eligible for a lower monthly payment under income-driven repayment plans, as well as other federal benefits',
      },
      {
        description:
          'If you work in public service, consolidating your loans will make them eligible for Public Service Loan Forgiveness (PSLF)',
      },
      {
        description:
          'We’ll only consolidate older loan types that aren’t eligible for some federal benefits - we won’t consolidate any of your Direct loans',
      },
    ]}
    consTitle="What else should I know before consolidating?"
    cons={[
      {
        description:
          'Consolidating can mean both combining multiple loans into one new loan, and changing the type of loan to a Direct Consolidation loan',
      },
      {
        description:
          'Your loans will stay federal student loans, and will not be refinanced with a private lender (your interest rate will stay the same or will be rounded up slightly)',
      },
      {
        description:
          'If your loans are already on an income-driven repayment plan, consolidation will restart the repayment timeline for that loan',
      },
    ]}
    however="However, we check these things as you go to ensure you don’t miss out on any benefits you’re eligible for."
  >
    {children}
  </InfoPopup>
);

export default ConsolidationInfoPopup;
