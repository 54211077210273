import { format, parse } from 'date-fns';
import React from 'react';

import { Text, Drawer } from '@summer/ui-components';

import {
  DrawerContent,
  DrawerContentColumn,
  DisplayData,
} from 'components/pages/loans/components/LoanDetails/shared';
import { dollars } from 'utils/numbers';

const PaymentInfo = ({
  dueDate,
  monthlyPayment,
  pslfMatchedMonths,
  totalPayments,
}) => {
  const formattedDueDate = dueDate
    ? format(parse(dueDate, 'yyyy-MM-dd', new Date()), 'MMM d, yyyy')
    : null;
  return (
    <Drawer openByDefault title="Payment Information">
      <DrawerContent>
        <DrawerContentColumn>
          <div>
            <Text color="secondary">Monthly Payment</Text>
            <Text large bold>
              <DisplayData valueToCheck={monthlyPayment}>
                {dollars(monthlyPayment)}
              </DisplayData>
            </Text>
          </div>
          <div>
            <Text color="secondary">Total Payments</Text>
            <Text large bold>
              <DisplayData valueToCheck={totalPayments}>
                {dollars(totalPayments)}
              </DisplayData>
            </Text>
          </div>
        </DrawerContentColumn>
        <DrawerContentColumn>
          <div>
            <Text color="secondary">Due Date</Text>
            <Text large bold>
              <DisplayData valueToCheck={dueDate}>
                {formattedDueDate}
              </DisplayData>
            </Text>
          </div>
          <div>
            <Text color="secondary">PSLF Qualifying Payments</Text>
            <Text large bold>
              <DisplayData valueToCheck={pslfMatchedMonths}>
                {pslfMatchedMonths} Months
              </DisplayData>
            </Text>
          </div>
        </DrawerContentColumn>
      </DrawerContent>
    </Drawer>
  );
};

export default PaymentInfo;
