import React from 'react';
import styled from 'styled-components';

import { Text, FlexRow, RadioButtons } from '@summer/ui-components';

import InfoTooltip from 'components/shared/InfoTooltip';

class InDefermentOrForbearance extends React.Component {
  buttonOptions = [
    { value: 'no', label: 'No, I am not.' },
    {
      value: 'yes-pay-now',
      label:
        'Yes, but I want to start making payments under my plan immediately.',
    },
  ];

  buttonCSS = `
    width: 100%;
    height: 40px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);

    input {
      margin-left: -50%;
    }
  `;

  render() {
    const {
      formik: {
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        isSubmitting,
      },
    } = this.props;

    return (
      <Container id="deferment">
        <LabelContainer>
          <Text large bold>
            Are you currently in deferment or forbearance?
          </Text>
          <InfoTooltip
            top={4}
            text="Your loans are in deferment or forbearance if you have temporarily paused your monthly payments. You would have needed to contact your loan servicer to do so."
          />
        </LabelContainer>
        <RadioButtons
          row
          design="smallRec"
          name="submit.deferment"
          touched={touched.deferment}
          isSubmitting={isSubmitting}
          error={errors.deferment}
          value={values.deferment}
          onChange={handleChange}
          onBlur={handleBlur}
          options={this.buttonOptions}
          buttonCSS={this.buttonCSS}
          numberOfCol={1}
        />
      </Container>
    );
  }
}

const Container = styled.div`
  & > .text {
    margin-bottom: 16px;
  }
`;

const LabelContainer = styled(FlexRow)`
  margin-bottom: 16px;
`;

export default InDefermentOrForbearance;
