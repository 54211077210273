import React from 'react';
import styled from 'styled-components';

import {
  Header,
  Text,
  IconMedallion,
  OverpaymentsIcon,
  COLORS,
} from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import ModalPopup from 'components/shared/ModalPopup';
import { MobileSize } from 'constants/styleguide';

const OverpaymentsModal = ({ children, platformType, displayStyle }) => {
  const isMobile = platformType === MOBILE;
  return (
    <ModalPopup
      displayStyle={displayStyle}
      width={770}
      kind="overpayments"
      component={() => (
        <Container>
          <IconMedallion SvgComponent={OverpaymentsIcon} />
          <HeaderContainer>
            <Header as={isMobile ? 'h3' : 'h1'} center>
              Learn more about overpayments
            </Header>
          </HeaderContainer>
          <Header h5>What are overpayments?</Header>
          <Text as="p" paragraph weight="medium">
            Making an overpayment means paying more than the monthly minimum
            that’s due on your student loans.
          </Text>
          <Header h5>How can overpaying save me money?</Header>
          <Text as="p" paragraph weight="medium">
            By paying more than the minimum on your loans, you’ll pay them off
            more quickly and therefore will pay less in interest over time.
            Here’s an example.
          </Text>

          <img
            src={`/images/overpayments/overpayments-table-${
              isMobile ? 'mobile' : 'desktop'
            }.png`}
            alt="Scenario"
            width="100%"
          />

          <Header h5>Should I overpay?</Header>
          <Text as="p" paragraph weight="medium">
            If you are able to afford making extra payments on your student
            loans and want to pay your loans off more quickly while paying less
            in interest, you may want to make overpayments.
          </Text>
          <Text as="p" paragraph weight="medium">
            Before you decide to overpay on your student loans, take a look at
            your other financial priorities as well. Do you have high interest
            credit card debt? Do you have savings for retirement or a rainy day?
            The importance of these factors will be different for everyone, but
            overpaying on student loans may not be the highest priority until
            you’re financially comfortable in other areas.
          </Text>
          <Text as="p" paragraph weight="medium">
            If you are working toward Public Service Loan Forgiveness, you may
            not want to make overpayments. Paying more than is due could cause
            your payment to be ineligible for the 120 months needed for loan
            forgiveness. (Other loan forgiveness programs may be compatible with
            overpayments.)
          </Text>
          <Header h5>How much should I overpay?</Header>
          <Text as="p" paragraph weight="medium">
            This will depend on what you’re comfortable with given your other
            financial goals and responsibilities. Even small amounts can make a
            big difference over time, and you’ll always be able to adjust the
            amount of your payments if your financial situation changes.
          </Text>
          <Header h5>Where should I target my overpayments?</Header>
          <Text as="p" paragraph weight="medium">
            In general, we recommend prioritizing your loans with the highest
            interest rate first. Interest can grow rapidly, and paying toward
            your highest interest rate loans can save you the most money in the
            long run.
          </Text>
          <Header h5>How do I adjust my monthly payment?</Header>
          <Text as="p" paragraph weight="medium">
            If you decide making overpayments is right for you, you can adjust
            your monthly payment through your servicer’s website.
          </Text>
          <Text as="p" paragraph weight="medium">
            Unfortunately, you won’t be able to do this through Summer—yet!
            We’re working on it.
          </Text>
        </Container>
      )}
    >
      {children}
    </ModalPopup>
  );
};

const Container = styled.div`
  .icon-medallion {
    margin: 0 auto;
  }

  p {
    margin-bottom: 10px;
  }

  img {
    margin-top: 16px;
    max-width: 540px;

    @media (max-width: ${MobileSize}) {
      margin-left: -8px;
      margin-right: -8px;
      width: calc(100% + 16px);
    }
  }

  h5 {
    margin-top: 24px;
    margin-bottom: 8px;
  }
`;

const HeaderContainer = styled.div`
  border-bottom: solid 1px ${COLORS.grey};
  padding: 30px 0;
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: 24px;
`;

export default Platform(OverpaymentsModal);
