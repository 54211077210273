import { isNil } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import { getIsEmergencySavingsV1Enabled } from 'redux/selectors/flags.selectors';

import SavingsTargetCompleteV1 from './SavingsTargetCompleteV1';
import SavingsTargetStart from './SavingsTargetStart';
import SavingsTargetStatus from './SavingsTargetStatus';
import SavingsTargetSync from './SavingsTargetSync';

const SavingsTarget = ({ savingsTarget, account, setAccount }) => {
  const showEmergencySavingsV1 = useSelector(getIsEmergencySavingsV1Enabled);

  if (isNil(savingsTarget)) {
    return <SavingsTargetStart />;
  }

  if (account) {
    return (
      <SavingsTargetStatus savingsTarget={savingsTarget} account={account} />
    );
  }

  if (showEmergencySavingsV1) {
    return <SavingsTargetCompleteV1 savingsTarget={savingsTarget} />;
  }

  return (
    <SavingsTargetSync savingsTarget={savingsTarget} setAccount={setAccount} />
  );
};

export default SavingsTarget;
