import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { COLORS } from '@summer/ui-components';

import {
  ServicersToSendIDR,
  ServicersToSendConsolidation,
  InDefermentOrForbearance,
  InGracePeriod,
} from 'components/pages/idr/wizard/steps/Submit/fields';
import Header from 'components/pages/idr/wizard/steps/Submit/shared/Header';
import { MobileSize } from 'constants/styleguide';
import { selectedPathIncludesConsolidation } from 'redux/selectors/idr.selectors';

class ConfirmServicers extends React.Component {
  renderForm = () => {
    const { values, errors, touched, idrHandlers, includesConsolidation } =
      this.props;
    const formik = { values, errors, touched, ...idrHandlers };

    const ServicersToSend = includesConsolidation
      ? ServicersToSendConsolidation
      : ServicersToSendIDR;

    return (
      <>
        <ServicersToSend formik={formik} />
        <Divider />
        <InDefermentOrForbearance formik={formik} />
        {includesConsolidation && (
          <>
            <Divider />
            <InGracePeriod formik={formik} />
          </>
        )}
      </>
    );
  };

  render() {
    return (
      <>
        <Header>Confirm your servicers</Header>
        <FormContainer>{this.renderForm()}</FormContainer>
      </>
    );
  }
}

const Divider = styled.div`
  width: 100%;

  border solid 1px ${COLORS.grey};

  margin-top: 24px;
  margin-bottom: 24px;
`;

const FormContainer = styled.div`
  @media (max-width: ${MobileSize}) {
    padding: 24px;
    margin: 0;
    border-radius: 3px;
    border: solid 1px ${COLORS.grey};
    background-color: ${COLORS.lightestGrey};
  }
`;

const mapStateToProps = state => ({
  includesConsolidation: selectedPathIncludesConsolidation(state),
});

export default connect(mapStateToProps)(ConfirmServicers);
