import {
  POST_RETIREMENT_MATCH_CONSENT,
  CREATE_RETIREMENT_MATCH_MANUAL_MATCH,
  FETCH_RETIREMENT_MATCH,
  SET_RETIREMENT_MATCH,
} from 'constants/actionTypes';

export const postRetirementMatchConsent = options => ({
  type: POST_RETIREMENT_MATCH_CONSENT,
  payload: {
    hasOptedIntoRetirementMatch: true,
  },
  meta: {
    onSuccess: options?.onSuccess,
  },
});

export const createRetirementMatchManualMatch = ({ fileId }) => ({
  type: CREATE_RETIREMENT_MATCH_MANUAL_MATCH,
  payload: { fileId },
});

export const fetchRetirementMatch = () => ({
  type: FETCH_RETIREMENT_MATCH,
});

export const setRetirementMatch = payload => ({
  type: SET_RETIREMENT_MATCH,
  payload,
});
