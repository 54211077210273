import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Text } from '@summer/ui-components/src';
import Link from '@summer/ui-components/src/core/typography/Link';

import { collegeAidProReferralLink } from 'constants/externalLinks/collegeSavingsPlanner';
import { MobileSize } from 'constants/styleguide';
import { getCollegeSavingsPlannerInputs } from 'redux/selectors/collegeSavingsPlanner.selectors';
import { dollars } from 'utils/numbers';

const NeedMore = ({ contributions, monthsUntilStart }) => {
  const { plannedContribution } = useSelector(getCollegeSavingsPlannerInputs);
  const { periodic: monthlyContribution, oneTime: oneTimeContribution } =
    contributions;

  return (
    <RedBox>
      <Header weight="bold">
        <Emoji>
          <span role="img" aria-label="lightbulb">
            💡
          </span>
        </Emoji>
        We get it. College is expensive!
      </Header>
      <Text paragraph>
        Based on what you entered, it looks like there&rsquo;s a gap between
        what you&rsquo;ll have and what you&rsquo;ll need to fully fund college
        attendance by the time you or your student enroll. But there are options
        available!
      </Text>
      <Tips>
        <Tip>
          <Text size="medium" paragraph>
            To reach your goal, you could start saving a total of{' '}
            <Text weight="bold" as="span" size="large">
              {dollars(monthlyContribution)}
            </Text>{' '}
            per month for the next{' '}
            <Text weight="bold" as="span" size="large">
              {monthsUntilStart}
            </Text>{' '}
            months (about{' '}
            <Text weight="bold" as="span" size="large">
              {(monthsUntilStart / 12).toFixed(2)}
            </Text>{' '}
            years).
          </Text>
        </Tip>
        <Tip>
          <Text paragraph>
            You could also make a one time contribution of{' '}
            <Text weight="bold" as="span" size="large">
              {dollars(oneTimeContribution)}
            </Text>{' '}
            now and keep saving your intended{' '}
            <Text weight="bold" as="span" size="large">
              {dollars(plannedContribution)}
            </Text>{' '}
            per month.
          </Text>
        </Tip>
        <Tip>
          <Text paragraph>
            Friends and family can help reach this goal by contributing to a
            college savings account like a 529 &mdash; you can read more about
            those plans below!
          </Text>
        </Tip>
        <Tip>
          <Text paragraph>
            There are also additional scholarship and aid resources that can
            help &mdash; our partner{' '}
            <Link href={collegeAidProReferralLink} inheritColor underline>
              College Aid Pro
            </Link>{' '}
            has an extensive searchable database and offers access to expert
            help.
          </Text>
        </Tip>
      </Tips>
    </RedBox>
  );
};

const Header = styled(Text)`
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: solid 1px #e9a2a2;
`;

const RedBox = styled.div`
  background: #faf0f0;
  padding: 40px 16px 40px;

  @media (min-width: ${MobileSize}) {
    padding: 21px 24px 38px;
  }
`;

const Emoji = styled.span`
  font-size: 24px;
  padding: 0 8px;
`;

const Tips = styled.ul`
  padding-left: 24px;
`;

const Tip = styled.li`
  padding: 8px 0;
`;

export default NeedMore;
