import React, { useEffect } from 'react';
import styled from 'styled-components';

import { PLAID_LINK_CUSTOMIZATION_NAMES } from '@simplifidev/shared/dist/constants/plaidLink';

import {
  FlexColumn,
  FlexRow,
  Text,
  Button,
  IconMedallion,
  PiggyBankCoins,
} from '@summer/ui-components/src';

import { Link } from 'components/shared/typography';
import { usePlaid } from 'hooks/plaid';

import { EmergencySavingsCard } from '../shared';

import SavingsTargetSide from './SavingsTargetSide';

const SavingsTargetSync = ({ savingsTarget, setAccount }) => {
  const { open: openPlaid, event } = usePlaid({
    linkCustomizationName: PLAID_LINK_CUSTOMIZATION_NAMES.emergency_savings,
  });

  useEffect(() => {
    if (event === 'SUCCESS') {
      setAccount({
        institutionName: 'Wells Fargo',
        logo: '/images/emergencySavingsIcons/wellsFargoIcon.png',
        name: 'Savings',
        accountNumber: '********1234',
        balance: 20000,
        syncDate: new Date(),
      });
    }
  }, [setAccount, event]);

  return (
    <EmergencySavingsCard>
      <Container>
        <Content>
          <IconMedallion paintWith="stroke" SvgComponent={PiggyBankCoins} />
          <Text paragraph>
            No balance to display here yet.  Sync your emergency savings account
            to track your progress. 
          </Text>
          <ActionRow>
            <Button width={200} onClick={() => openPlaid()}>
              Sync savings account
            </Button>
            <Link to="/emergency-savings/marketplace">
              Don&lsquo;t have an account?
            </Link>
          </ActionRow>
        </Content>
        <SavingsTargetSide savingsTarget={savingsTarget} />
      </Container>
    </EmergencySavingsCard>
  );
};

const Content = styled(FlexColumn)`
  justify-content: center;
  gap: 32px;
  width: 50%;
`;

const Container = styled(FlexRow)`
  justify-content: space-between;
`;

const ActionRow = styled(FlexRow)`
  align-items: center;
  gap: 24px;
`;

export default SavingsTargetSync;
