import React from 'react';

import { TextInput } from '@summer/ui-components';

import { Row } from 'components/pages/idr/wizard/shared/styles';
import { InputWrapper } from 'components/pages/idr/wizard/steps/Submit/shared/Inputs';

const EmployerNameAndWebsite = ({
  formik: { values, errors, handleChange, handleBlur, touched, isSubmitting },
}) => {
  return (
    <Row>
      <InputWrapper>
        <TextInput
          label="Employer Name"
          name="employerName"
          touched={touched.employerName}
          isSubmitting={isSubmitting}
          error={errors.employerName}
          value={values.employerName || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Employer Name"
        />
      </InputWrapper>
      <InputWrapper>
        <TextInput
          label="Employer Website (if any)"
          name="employerWebsite"
          touched={touched.employerWebsite}
          isSubmitting={isSubmitting}
          error={errors.employerWebsite}
          value={values.employerWebsite || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Employer Website"
        />
      </InputWrapper>
    </Row>
  );
};

export default EmployerNameAndWebsite;
