import { replace, push as redirectTo } from 'connected-react-router';
import { get, isNil } from 'lodash';

import {
  POST_OAUTH_CODE,
  NETWORK_SUCCESS,
  NETWORK_FAILURE,
} from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import { setUser, checkUser } from 'redux/actions/auth.actions';
import { setProfile } from 'redux/actions/profile.actions';
import { showServerError } from 'redux/actions/ui.actions';
import handleSideEffects from 'redux/handleSideEffects';

const authMiddleware = handleSideEffects({
  [POST_OAUTH_CODE]: (dispatch, { payload }) => {
    const url = `/${payload.provider}${
      payload.search
    }&clientRedirect=true&redirectTo=${encodeURIComponent(
      window.location.href,
    )}&referrer=${encodeURIComponent(document.referrer)}`;

    dispatch(
      apiRequest({
        url,
        method: 'POST',
        fromAction: POST_OAUTH_CODE,
        label: 'oauth',
        maxRedirects: 0,
        meta: {
          provider: payload.provider,
        },
      }),
    );
  },

  [NETWORK_SUCCESS]: (dispatch, { payload, meta }, state) => {
    if (meta.fromAction === POST_OAUTH_CODE) {
      const { redirect, error, user } = payload.response;
      const postAuthRedirect = get(state, 'router.location.state.origin');
      let url;

      if (user && !error) {
        dispatch(setUser(user, meta));
        dispatch(
          setProfile({
            firstName: user.user.firstName,
            lastName: user.user.lastName,
          }),
        );

        if (user.user.isVerified) {
          dispatch(checkUser());
        }
      }

      if (redirect) {
        /**
         * dont redirect, the SET_USER middleware uses this to redirect the
         * originally requested route. If there is an error then redirect
         * always
         */
        if (error || (!error && isNil(postAuthRedirect))) {
          url = new URL(redirect);

          dispatch(replace({ pathname: url.pathname, search: url.search }));
          dispatch(redirectTo({ pathname: url.pathname, search: url.search }));
        }
      } else {
        const redirectLocation = getFailureRedirectLocation(meta.provider);
        dispatch(replace(redirectLocation));
        dispatch(redirectTo(redirectLocation));
      }

      if (error) {
        const message = error;

        dispatch(showServerError({ label: 'oauth', error: { message } }));
      } else if (!user) {
        /* This is a refresh response. The bell cookie is not set so we cannot
         * retry the request.
         */
        const redirectLocation = getFailureRedirectLocation(meta.provider);
        dispatch(replace(redirectLocation));
        dispatch(redirectTo(redirectLocation));
      }
    }
  },

  [NETWORK_FAILURE]: (dispatch, { meta }) => {
    if (meta.fromAction === POST_OAUTH_CODE) {
      dispatch(redirectTo(getFailureRedirectLocation(meta.provider)));
    }
  },
});

const getFailureRedirectLocation = () => {
  return '/login';
};

export default authMiddleware;
