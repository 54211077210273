import React from 'react';
import styled from 'styled-components';

import { FlexColumn } from '@summer/ui-components';

import { Row } from 'components/pages/idr/wizard/shared/styles';
import DateOfBirth from 'components/pages/pslf/wizard/steps/submit/YourContactInfo/fields/DateOfBirth';
import Name from 'components/pages/pslf/wizard/steps/submit/YourContactInfo/fields/Name';
import { useFormDrawerFocus } from 'components/shared/FormDrawers';

const PersonalInfo = ({ formik, show }) => {
  const formRef = React.useRef(null);

  useFormDrawerFocus(formRef, show);

  return (
    <FlexColumn ref={formRef}>
      <Name formik={formik} />
      <Row>
        <DateOfBirth formik={formik} />
        <Spacer />
      </Row>
    </FlexColumn>
  );
};

const Spacer = styled.div`
  width: 100%;
`;

export default PersonalInfo;
