import {
  differenceInCalendarDays,
  isAfter,
  isBefore,
  isPast,
  parseISO,
} from 'date-fns';
import _, {
  filter,
  find,
  forEach,
  get,
  includes,
  isEmpty,
  isNil,
  keys,
  map,
  pick,
  some,
} from 'lodash';
import fp from 'lodash/fp';
import { createSelector } from 'reselect';

import {
  fieldsOnManageLoansIdr,
  fieldsOnManageLoansPslf,
  fieldsOnPaidOffLoans,
} from 'constants/loans';
import { programTypes } from 'constants/manageLoans';
import {
  getEmployerEndDate,
  getEmployerStartDate,
  getPslfLoanEligibility,
} from 'redux/selectors/pslf.selectors';
import { hasSyncedLoans } from 'redux/selectors/sync.selectors';
import { getUserFederalLoansEstimate } from 'redux/selectors/user.selectors';
import {
  getNormalizedFsaLoans,
  loanIsActive,
  showIncludesParentPlus,
  showLoanPayoffDate,
} from 'utils/loans';
import { dataToOptions } from 'utils/toOptions';

const getLoans = state => state.loans;
export const getPlaidLoans = state => state.plaidLoans;
export const getLoanFormProgramType = state => state.ui.loanForm.programType;
export const isLoanFormOpen = state => state.ui.loanForm.isOpen;
export const getCurrentLoanIdx = state => state.ui.loanForm.currentLoanIdx;

const getTotalPrincipalBalance = loans => {
  return loans.reduce((sum, loan) => {
    return sum + loan.loansRevision[0].principalBalance;
  }, 0);
};

export const getUserFederalLoans = createSelector(getLoans, loans => {
  return (loans || []).filter(loan => loan.type === 'federal');
});

export const getUserFederalLoansWithPrincipalBalance = createSelector(
  getUserFederalLoans,
  loans =>
    filter(loans, loan =>
      loanIsActive(get(loan, 'loansRevision[0].principalBalance')),
    ),
);

const getUserFederalLoansTotalPrincipalBalance = createSelector(
  getUserFederalLoans,
  getTotalPrincipalBalance,
);

/* This selector will return a user's total federal principal balance if
 * they have synced loans, and will fall back to the onboarding question
 * if they have not. */
export const getFederalLoansAmount = createSelector(
  hasSyncedLoans,
  getUserFederalLoansEstimate,
  getUserFederalLoansTotalPrincipalBalance,
  (hasSyncedLoans, federalLoansEstimate, federalLoansPrincipalBalance) =>
    hasSyncedLoans ? federalLoansPrincipalBalance : federalLoansEstimate,
);

export const userHasFederalLoans = createSelector(
  getFederalLoansAmount,
  federalLoansAmount => federalLoansAmount > 0,
);

export const getFederalLoanTypes = state => state.constants.loanTypes;

export const getRepaymentPlanTypes = state =>
  state.constants.repaymentPlanTypes;

export const getLoansForPslf = createSelector(
  getUserFederalLoansWithPrincipalBalance,
  getPslfLoanEligibility,
  (userFederalLoans, pslfLoanEligibility) => {
    const loansForPslf = map(userFederalLoans, loan => {
      const { pslfEligible, shouldConsolidate } = pick(
        find(pslfLoanEligibility, { loanId: loan.id }),
        ['pslfEligible', 'shouldConsolidate'],
      );
      const pslfEligibilityAndConsolidationRec = {
        pslfEligible,
        shouldConsolidate,
      };
      return { ...loan, pslfEligibilityAndConsolidationRec };
    });

    return loansForPslf;
  },
);

const getLoansForLoanForm = createSelector(
  getLoanFormProgramType,
  getUserFederalLoans,
  getLoansForPslf,
  (programType, userFederalLoans, loansForPslf) =>
    programType === programTypes.pslf ? loansForPslf : userFederalLoans,
);

export const getLoanFormNumberOfLoans = createSelector(
  getLoansForLoanForm,
  loans => get(loans, 'length'),
);

export const getCurrentLoan = createSelector(
  getCurrentLoanIdx,
  getLoansForLoanForm,
  (currentLoanIdx, loans) => {
    const normalizedLoans = getNormalizedFsaLoans(loans) || [];
    return normalizedLoans[currentLoanIdx];
  },
);

const getFsaLoanServicerNames = (loans, active = true) =>
  _.flow(
    getNormalizedFsaLoans,
    fp.filter({ active }),
    fp.map('servicerName'),
    fp.uniq,
  )(loans);

export const getActiveFsaLoanServicerNames = createSelector(
  getUserFederalLoans,
  getFsaLoanServicerNames,
);

export const getInactiveFsaLoanServicerNames = createSelector(
  getUserFederalLoans,
  loans => getFsaLoanServicerNames(loans, false),
);

export const getLoanTypeById = (state, id) =>
  find(getFederalLoanTypes(state), { id });

export const getLoanTypeNameById = (state, id) =>
  get(getLoanTypeById(state, id), 'description');

export const getRepaymentPlanTypeNameById = (state, id) =>
  _.flow(getRepaymentPlanTypes, fp.find({ id }), fp.get('name'))(state);

export const getFederalLoanTypesOptions = createSelector(
  getFederalLoanTypes,
  loanTypes => {
    const first = 'DIRECT_STAFFORD_SUBSIDIZED';
    const second = 'DIRECT_STAFFORD_UNSUBSIDIZED';

    return loanTypes
      .map(({ id, description, type }) => ({
        label: description,
        type,
        value: id,
      }))
      .sort((a, b) => {
        const nameA = a.type;
        const nameB = b.type;

        // the first and second elements must always be direct stafford loans
        if (nameA === first) {
          return -1;
        }
        if (nameB === first) {
          return 1;
        }

        if (nameA === second) {
          return -1;
        }
        if (nameB === second) {
          return 1;
        }

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
      .filter(({ type }) => type !== 'PRIVATE_NON_FEDERAL');
  },
);

export const getRepaymentPlanTypeOptions = createSelector(
  getRepaymentPlanTypes,
  repaymentPlanTypes => dataToOptions(repaymentPlanTypes, 'id', 'name'),
);

const getLoanById = (state, loanId) => find(state.loans, { id: loanId });

export const isLoanFieldEditable = (state, loanId, field) => {
  const loan = getLoanById(state, loanId);
  const loanRevision = get(loan, 'loansRevision[0]');
  const editableFields = get(loanRevision, 'editableFields');
  const fieldIsOnLoansRevision = includes(keys(loanRevision), field);
  const fieldIsOnLoan = includes(keys(loan), field);

  if (field === 'disbursementDate') {
    return isNil(get(loan, `${field}[0].disbursementDate`));
  }
  if (fieldIsOnLoansRevision) {
    return includes(editableFields, field);
  }
  if (fieldIsOnLoan) {
    // for now, if fields on loan (not loansRevision) are empty, can only edit once
    // but always allow user to edit education level b/c not confident in our matching logic
    return isNil(loan[field]) || field === 'educationLevel';
  }
  return false;
};

// Returns a function that will tell you if a given field on a loan is editable
export const getCanEditField = state => (loanId, field) =>
  isLoanFieldEditable(state, loanId, field);

const loanIsInIDR = loan =>
  get(loan, 'loansRevision[0].repaymentPlanType.isIdrPlan');

const areLoansInIdr = loans => {
  if (isEmpty(loans)) {
    return null;
  }

  const loanInIdr = some(loans, loan => loanIsInIDR(loan));

  return loanInIdr;
};

export const isLoanEditable = state => {
  const loan = getCurrentLoan(state);
  const federalLoanTypes = getFederalLoanTypes(state);
  const programType = getLoanFormProgramType(state);
  if (loan) {
    const {
      principalBalance,
      id: loanId,
      disbursementDate,
      federalLoanTypeId,
      includesParentPlus,
    } = loan;
    const loanIsPaidOff =
      principalBalance === 0 &&
      !isLoanFieldEditable(state, loanId, 'principalBalance');

    let fieldsToCheck = [];
    if (loanIsPaidOff) {
      fieldsToCheck = fieldsOnPaidOffLoans;
    } else if (programType === programTypes.pslf) {
      fieldsToCheck = fieldsOnManageLoansPslf;
    } else {
      fieldsToCheck = fieldsOnManageLoansIdr;
    }

    let loanIsEditable = false;

    forEach(fieldsToCheck, field => {
      const loanFieldIsEditable = isLoanFieldEditable(state, loanId, field);
      const canEditParentPlus =
        showIncludesParentPlus(federalLoanTypeId, federalLoanTypes) &&
        loanFieldIsEditable;
      const canEditLoanPayoffDate =
        showLoanPayoffDate(
          disbursementDate,
          federalLoanTypeId,
          includesParentPlus,
          principalBalance,
          federalLoanTypes,
        ) && loanFieldIsEditable;

      if (field === 'includesParentPlus') {
        if (canEditParentPlus) {
          loanIsEditable = true;
        }
      } else if (field === 'loanPayoffDate') {
        if (canEditLoanPayoffDate) {
          loanIsEditable = true;
        }
      } else if (loanFieldIsEditable) {
        loanIsEditable = true;
      }
    });

    return loanIsEditable;
  }

  return null;
};

export const userHasLoansInIdr = createSelector(
  getUserFederalLoansWithPrincipalBalance,
  areLoansInIdr,
);

const getLoansInIdr = loans => filter(loans, loan => loanIsInIDR(loan));

const getUserLoansInIdr = createSelector(
  getUserFederalLoansWithPrincipalBalance,
  getLoansInIdr,
);

const getEarliestRecertificationDate = loans => {
  let earliestRecertificatonDate = null;

  loans.forEach(loan => {
    if (loanIsInIDR(loan)) {
      const recertificationDate = get(
        loan,
        'loansRevision[0].idrRecertificationDate',
      );

      if (
        !earliestRecertificatonDate ||
        isBefore(
          parseISO(recertificationDate),
          parseISO(earliestRecertificatonDate),
        )
      ) {
        earliestRecertificatonDate = recertificationDate;
      }
    }
  });

  return earliestRecertificatonDate;
};

export const getUserRecertificationDate = createSelector(
  getUserLoansInIdr,
  getEarliestRecertificationDate,
);

const idrLoansNeedToBeRecertified = (hasLoansInIDR, loans) => {
  if (isEmpty(loans) || !hasLoansInIDR) {
    return null;
  }

  const maxNumberOfDaysBeforeRecertification = 49;
  const recertificationDate = getEarliestRecertificationDate(loans);
  if (
    !isNil(recertificationDate) &&
    differenceInCalendarDays(parseISO(recertificationDate), new Date()) <
      maxNumberOfDaysBeforeRecertification
  ) {
    return true;
  }

  return false;
};

export const userNeedsToRecertifyIdr = createSelector(
  userHasLoansInIdr,
  getUserFederalLoansWithPrincipalBalance,
  idrLoansNeedToBeRecertified,
);

export const userRecertificationDateHasPassed = createSelector(
  getUserRecertificationDate,
  date => (isNil(date) ? false : isPast(parseISO(date))),
);

const getPlanTypeNamesFromLoans = _.flow(
  fp.map('loansRevision[0].repaymentPlanType.shortName'),
  fp.uniq,
);

export const getUserIdrPlanTypeNames = createSelector(
  getUserLoansInIdr,
  getPlanTypeNamesFromLoans,
);

export const userHasPerkinsLoans = createSelector(
  getUserFederalLoansWithPrincipalBalance,
  loans => {
    if (isEmpty(loans)) {
      return null;
    }
    return some(loans, 'loansRevision[0].federalLoanType.isPerkins');
  },
);

export const userHasFFELLoans = createSelector(
  getUserFederalLoansWithPrincipalBalance,
  loans => {
    if (isEmpty(loans)) {
      return null;
    }
    return some(loans, 'loansRevision[0].federalLoanType.isFFEL');
  },
);

export const getUserPslfIneligibleLoanTypes = createSelector(
  getUserFederalLoansWithPrincipalBalance,
  _.flow(
    fp.map('loansRevision[0].federalLoanType'),
    fp.reject('pslfEligible'),
    fp.uniqBy('id'),
  ),
);

/* Get loan status changes that overlap with employment period for
 * the current PSLF form.
 */
export const getLoanStatusHistoryForPslf = createSelector(
  getCurrentLoan,
  getEmployerStartDate,
  getEmployerEndDate,
  (loan, employerStartDate, employerEndDate) => {
    const loanStatusHistory = get(loan, 'loanStatusHistory');

    return filter(
      loanStatusHistory,
      ({ loanStatusEffectiveDate }, index, loanStatusHistory) => {
        if (
          isAfter(parseISO(loanStatusEffectiveDate), parseISO(employerEndDate))
        ) {
          return false;
        }

        const nextLoanStatusEffectiveDate = get(
          loanStatusHistory[index - 1],
          'loanStatusEffectiveDate',
        );

        if (
          isBefore(
            parseISO(nextLoanStatusEffectiveDate),
            parseISO(employerStartDate),
          )
        ) {
          return false;
        }

        return true;
      },
    );
  },
);

export const hasPlaidLoans = createSelector(
  getPlaidLoans,
  plaidLoans => !isEmpty(plaidLoans),
);
