import { push as redirectTo } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  IconMedallion,
  LoanSync,
  Button,
  Text,
  FlexColumn,
  COLORS,
} from '@summer/ui-components';

import { TabletSize } from 'constants/styleguide';
import { clearFileCollections } from 'redux/actions/fileCollections.actions';

const EmptyDocumentsDisplay = () => {
  const dispatch = useDispatch();

  const handleUploadDocuments = () => {
    dispatch(clearFileCollections());
    dispatch(
      redirectTo('/retirement-match/upload-documents', {
        hasPreviouslyUploadedDocuments: true,
      }),
    );
  };

  return (
    <Container>
      <IconMedallion
        SvgComponent={LoanSync}
        size={64}
        color={COLORS.lightBlue}
      />
      <TextContainer>
        <Text bold large paragraph>
          Document Upload
        </Text>
        <Text paragraph>
          If you&rsquo;re having trouble syncing your student loans, please
          upload your statements which show your proof of student loan payments.
        </Text>
      </TextContainer>
      <Button
        type="button"
        kind="upload-documents"
        primary
        width={184}
        onClick={handleUploadDocuments}
      >
        Upload a document
      </Button>
    </Container>
  );
};

const Container = styled(FlexColumn)`
  padding: 56px 94px 32px;
  align-items: center;
  text-align: center;

  @media (max-width: ${TabletSize}) {
    padding: 48px 24px;
  }
`;

const TextContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;

  & > * {
    margin-bottom: 4px;
  }
`;

export default EmptyDocumentsDisplay;
