import { push as redirectTo } from 'connected-react-router';
import { isEqual, isNil, keys, pick, unset, get } from 'lodash';

import {
  CHECK_USER,
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_PARTIAL_DATA,
  LOGIN,
  LOGOUT,
  NETWORK_SUCCESS,
  SET_USER,
  POST_OAUTH_CODE,
  KEEP_SESSION_ALIVE,
} from 'constants/actionTypes';
import { finish, welcome } from 'constants/onboardingSteps';
import { setUserForErrorTracking } from 'errorTracking';
import { apiRequest } from 'redux/actions/api.actions';
import { setUser, verifyUser, checkUser } from 'redux/actions/auth.actions';
import { setFinancialProfile } from 'redux/actions/financialProfile.actions';
import { setOnboardingStep } from 'redux/actions/onboard.actions';
import { setProfile } from 'redux/actions/profile.actions';
import { resetState } from 'redux/actions/resetAction.actions';
import { setSmsFactor } from 'redux/actions/smsFactor.actions';
import handleSideEffects from 'redux/handleSideEffects';
import {
  getUserFirstName,
  getUserLastName,
} from 'redux/selectors/profile.selectors';
import {
  getUserEmail,
  getUserPartnerKey,
  getUserId,
} from 'redux/selectors/user.selectors';
import { zendeskWidgetIdentify, zendeskWidgetLogout } from 'services/zendesk';

const authMiddleware = handleSideEffects({
  [CHECK_USER]: (dispatch, { meta }) => {
    dispatch(
      apiRequest({
        url: '/user',
        method: 'GET',
        meta,
        fromAction: CHECK_USER,
        label: 'checkUser',
      }),
    );
  },

  [LOGIN]: (dispatch, { payload, meta }) => {
    // Send account verification token of a locked out account
    // to authenticate before verification
    const token = meta && meta.token;

    dispatch(
      apiRequest({
        url: '/login',
        method: 'POST',
        body: { ...payload, token },
        meta,
        fromAction: LOGIN,
        label: 'login',
      }),
    );
  },

  [CREATE_ACCOUNT]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: '/signup',
        method: 'POST',
        body: { ...payload },
        fromAction: CREATE_ACCOUNT,
        label: 'createAccount',
      }),
    );
  },

  [CREATE_ACCOUNT_PARTIAL_DATA]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: '/v1/adp/user',
        method: 'POST',
        body: { ...payload },
        fromAction: CREATE_ACCOUNT_PARTIAL_DATA,
        label: 'createPartialDataAccount',
      }),
    );
  },

  [LOGOUT]: (dispatch, { meta }) => {
    dispatch(
      apiRequest({
        url: '/logout',
        method: 'POST',
        fromAction: LOGOUT,
        meta,
      }),
    );
  },

  [KEEP_SESSION_ALIVE]: dispatch => {
    dispatch(
      apiRequest({
        url: '/keepalive',
        method: 'GET',
        fromAction: KEEP_SESSION_ALIVE,
      }),
    );
  },

  [SET_USER]: (dispatch, { payload, meta }, state) => {
    setUserForErrorTracking(pick(payload.user, ['id', 'email']));

    const profile = get(payload, 'user.profile');
    const financialProfile = get(payload, 'user.financialProfile');

    if (profile) {
      dispatch(setProfile(profile));
    }

    if (financialProfile) {
      dispatch(setFinancialProfile(financialProfile));
    }

    if (payload.mfa?.sms) {
      dispatch(setSmsFactor(payload.mfa.sms));
    }

    if (meta.fromAction === CREATE_ACCOUNT) {
      dispatch(setOnboardingStep(welcome));
      dispatch(redirectTo(welcome));
    }

    if (meta.fromAction === CREATE_ACCOUNT_PARTIAL_DATA) {
      const { currentStep } = state.onboarding;
      dispatch(redirectTo(currentStep));
    }

    if (
      meta.fromAction === LOGIN ||
      meta.fromAction === CHECK_USER ||
      meta.fromAction === POST_OAUTH_CODE
    ) {
      const locationState = state.router.location.state;
      const { currentStep } = state.onboarding;

      if (!isNil(currentStep) && currentStep !== finish) {
        dispatch(redirectTo(currentStep));
        return;
      }

      let origin = '/';
      if (locationState && locationState.origin) {
        origin = locationState.origin;
      }
      dispatch(redirectTo(origin, { origin }));
    }
  },

  [NETWORK_SUCCESS]: (dispatch, { payload, meta }, state) => {
    if (meta.fromAction === LOGIN) {
      // Async verify user account
      const { token } = meta;

      if (token) {
        dispatch(verifyUser({ token }));
      }

      dispatch(setUser(payload.response, meta));
      dispatch(
        setProfile({
          firstName: payload.response.user.firstName,
          lastName: payload.response.user.lastName,
        }),
      );

      if (payload.response.user.isVerified) {
        dispatch(checkUser());
      }
      return;
    }

    if (meta.fromAction === CHECK_USER) {
      const { verificationToken } = state.currentUser;
      zendeskWidgetIdentify({
        firstName: getUserFirstName(state),
        lastName: getUserLastName(state),
        email: get(state, 'currentUser.email'),
      });

      if (verificationToken) {
        dispatch(verifyUser({ token: verificationToken }));
      }

      /**
       * Set user if anything has changed. Payload includes some things not
       * part of currentUser
       */
      if (
        !isEqual(
          state.currentUser,
          pick(payload.response.user, keys(state.currentUser)),
        )
      ) {
        if (getUserEmail(state) !== payload.response.user.email) {
          dispatch(setUser(payload.response, meta));
        } else {
          dispatch(setUser(payload.response, unset(meta, 'fromAction')));
        }
      }
      return;
    }

    if (
      meta.fromAction === CREATE_ACCOUNT ||
      meta.fromAction === CREATE_ACCOUNT_PARTIAL_DATA
    ) {
      dispatch(setUser(payload.response, meta));
      dispatch(
        setProfile({
          firstName: payload.response.user.firstName,
          lastName: payload.response.user.lastName,
        }),
      );

      if (payload.response.user.isVerified) {
        dispatch(checkUser());
      }
      return;
    }

    if (meta.fromAction === LOGOUT) {
      // if we're creating a new account, we need to clear local storage
      // otherwise last user's data will be there
      const origin = meta?.locationState?.origin;

      // Preserve checkUser loading state from App to prevent infinite loading animation
      dispatch(resetState({ preserve: 'ui.loading.checkUser' }));
      // delete zendesk cookies
      zendeskWidgetLogout();

      const isLoggedIn = !!getUserId(state);
      if (isLoggedIn) {
        const partnerKey = getUserPartnerKey(state);
        if (partnerKey === 'adp') {
          dispatch(redirectTo(`/logout/${partnerKey}`, { origin }));
        } else {
          dispatch(redirectTo('/login', { origin }));
        }
      }
    }
  },
});

export default authMiddleware;
