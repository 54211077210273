import React from 'react';
import styled from 'styled-components';

import {
  ModalPopup,
  FlexRow,
  Button,
  Header,
  Text,
} from '@summer/ui-components';

import Link from 'components/shared/typography/Link';

const UpdateOnboardingModal = ({ text }) => {
  return (
    <ModalPopup
      displayStyle="inline"
      component={togglePopup => (
        <Modal>
          <Text large bold center color="summer">
            Are you sure?
          </Text>
          <Header h2 center>
            Updating your situation will reset your recommendation
          </Header>
          <Text paragraph>
            However, you won&rsquo;t lose any progress you may have already
            made. You should update your situation when major life changes
            occur. For example, if you recently had a child or a significant
            change in income.{' '}
          </Text>
          <FlexRow>
            <Link onClick={togglePopup}>Cancel</Link>
            <Link
              to={{
                pathname: '/onboard/questions',
                state: { fromDashboard: true },
              }}
            >
              <Button width={144}>Proceed</Button>
            </Link>
          </FlexRow>
        </Modal>
      )}
    >
      <Link inheritColor underline>
        {text}
      </Link>
    </ModalPopup>
  );
};

const Modal = styled.div`
  h2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .link {
    text-align: center;
    width: 144px;
  }

  ${FlexRow} {
    margin-top: 24px;
    justify-content: flex-end;
    align-items: center;
  }
`;

export default UpdateOnboardingModal;
