import React from 'react';

import { Link } from 'components/shared/typography';
import { fsaServicersLink } from 'constants/externalLinks/fsa';

const SubmitToLoanServicer = () => (
  <>
    You can either submit through the online tool, or{' '}
    <Link inheritColor inheritWeight underline href={fsaServicersLink}>
      fax a PDF or mail a hard copy to your loan servicer.
    </Link>
  </>
);

export default SubmitToLoanServicer;
