import { initZendeskSettings, openContact } from 'services/zendesk';

export const helpWidgetSize = {
  // zendesk widget size with default design
  desktop: '130px',
  mobile: '70px',
};

export const openChat = () => {
  if (window.helpWidget) {
    window.helpWidget.open();
  }
};

export const openHelpCenter = () => {
  if (window.helpWidget) {
    window.helpWidget.openHelpCenter();
  }
};

export const hideChat = () => {
  if (window.helpWidget) {
    window.helpWidget.hide();
  }
};

export const showChat = () => {
  if (window.helpWidget) {
    window.helpWidget.show();
  }
};

export class HelpWidget {
  constructor() {
    initZendeskSettings();
  }

  open = () => {
    openContact();
  };

  openHelpCenter = () => {
    if (window.zE) {
      window.zE('webWidget', 'open');
    }
  };

  close = () => {
    if (window.zE) {
      window.zE('webWidget', 'close');
    }
  };

  show = () => {
    if (window.zE) {
      window.zE('webWidget', 'show');
    }
  };

  hide = () => {
    if (window.zE) {
      window.zE('webWidget', 'hide');
    }
  };
}
