import { trackServerSide } from 'redux/actions/analytics.actions';
import { track } from 'utils/track';

const useCurrentStep = (currentStep, { instance, dispatch }) => {
  const { title, track: trackConfig } = instance.config[currentStep - 1];

  // If track prop is not provided in the config, step will be tracked with its title field
  // If track prop is provided, step will be tracked with string provided
  // If track prop is set to false, step will not be tracked
  const trackEnabled = trackConfig !== false;
  const trackString = `Loaded Step: ${trackConfig || title}`;

  if (trackEnabled) {
    track(trackString);
    dispatch(trackServerSide(trackString));
  }
};

const useTrackStepChanges = hooks => {
  hooks.useCurrentStep.push(useCurrentStep);
};

export default useTrackStepChanges;
