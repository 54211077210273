import { format } from 'date-fns';
import { camelCase, capitalize, isNil, startCase } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import {
  Statistic,
  Strong,
  Text,
  FlexRow,
  COLORS,
} from '@summer/ui-components';

import { DisplayData } from 'components/pages/loans/components/LoanDetails/shared';
import { MobileSize } from 'constants/styleguide';
import { dollars, percentage } from 'utils/numbers';

const titleCase = text => {
  if (isNil(text)) {
    return text;
  }
  return startCase(camelCase(text));
};

const BasicInfo = ({
  amountBorrowed,
  currentBalance,
  dateBorrowed,
  repaymentPlan,
  interestRate,
  interestType,
  outstandingInterestBalance,
  principalBalance,
  school,
  status,
}) => (
  <>
    <InfoContainer>
      <Info>
        <Text>
          School:{' '}
          <Strong>
            <DisplayData valueToCheck={school}>{titleCase(school)}</DisplayData>
          </Strong>
        </Text>
        <Text>
          Date Borrowed:{' '}
          <Strong>
            <DisplayData valueToCheck={dateBorrowed}>
              {format(new Date(dateBorrowed), 'MMM d, yyyy')}
            </DisplayData>
          </Strong>
        </Text>
        <Text>
          Repayment Plan:{' '}
          <Strong>
            <DisplayData valueToCheck={repaymentPlan}>
              {titleCase(repaymentPlan)}
            </DisplayData>
          </Strong>
        </Text>
      </Info>
      <Info>
        <Text>
          Amount Borrowed:{' '}
          <Strong>
            <DisplayData valueToCheck={amountBorrowed}>
              {dollars(amountBorrowed)}
            </DisplayData>
          </Strong>
        </Text>
        <Text>
          Interest Rate:{' '}
          <Strong>
            <DisplayData valueToCheck={interestRate}>
              {`${percentage(interestRate)} ${capitalize(interestType)}`}
            </DisplayData>
          </Strong>
        </Text>
        <Text>
          Status:{' '}
          <Strong>
            <DisplayData valueToCheck={status}>{status}</DisplayData>
          </Strong>
        </Text>
      </Info>
    </InfoContainer>

    <Balance>
      <Figure>
        <Text center color="secondary">
          Principal Balance
        </Text>
        <StatContainer>
          <Statistic value={principalBalance} bold format="dollars" />
        </StatContainer>
      </Figure>
      <Operation>
        <Text color="secondary">+</Text>
      </Operation>
      <Figure>
        <Text center color="secondary">
          Outstanding Interest
        </Text>
        <StatContainer>
          <Statistic value={outstandingInterestBalance} bold format="dollars" />
        </StatContainer>
      </Figure>
      <Operation>
        <Text color="secondary">=</Text>
      </Operation>
      <Figure>
        <Text center color="secondary">
          Current Balance
        </Text>
        <StatContainer>
          <Statistic value={currentBalance} bold format="dollars" />
        </StatContainer>
      </Figure>
    </Balance>
  </>
);

const InfoContainer = styled(FlexRow)`
  padding: 32px 0;
  @media (max-width: ${MobileSize}) {
    flex-direction: column;
  }
`;

const Info = styled.div`
  width: 50%;
  padding: 0 32px;
  & > div:not(:first-child) {
    margin-top: 8px;
  }

  &:nth-child(2) {
    text-align: right;
  }

  @media (max-width: ${MobileSize}) {
    width: 100%;

    &:nth-child(2) {
      text-align: left;
      margin-top: 8px;
    }
  }
`;

const Balance = styled(FlexRow)`
  padding: 32px;
  border-top: 2px solid ${COLORS.lighterGrey};

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
  }
`;

const Figure = styled.div`
  flex: 1;
  text-align: center;
`;

const StatContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 4px;
`;

const Operation = styled.div`
  padding-top: 40px;
  & > div {
    font-size: 24px;
  }

  @media (max-width: ${MobileSize}) {
    padding-top: 24px;
    padding-bottom: 24px;
    text-align: center;
  }
`;

export default BasicInfo;
