import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import {
  Icon,
  Button,
  Text,
  ArrowUp,
  Header,
  FormDownloadIcon,
  COLORS,
} from '@summer/ui-components';

import { MobileSize } from 'constants/styleguide';
import { usePlatform } from 'hooks/platform';

export const LearnMoreContainer = ({ children, close }) => {
  const { isMobile } = usePlatform;

  return (
    <Overlay>
      <Background>
        <LearnMorePage data-testid="learn-more-page">
          <StickyHeader>
            <CloseButton onClick={close} data-testid="learn-more-close">
              <BackArrow SvgComponent={ArrowUp} fill={COLORS.medGrey} />
            </CloseButton>
          </StickyHeader>
          <Content>
            <HeaderContainer>
              <DownloadIcon SvgComponent={FormDownloadIcon} />
              <Header as={isMobile ? 'h3' : 'h2'} color="default">
                Submit it yourself
              </Header>
              <PriceHeader bold>FREE</PriceHeader>
            </HeaderContainer>
            {children}
          </Content>
          <Footer>
            <BackButton secondary onClick={close} kind="learn-more-back">
              Back
            </BackButton>
          </Footer>
        </LearnMorePage>
      </Background>
    </Overlay>
  );
};

const fadeInUp = keyframes`
 from {
   opacity: 0;
 }

 to {
   opacity: 1;
 }
`;

const fadeInUpAnimation = css`
  ${fadeInUp} 0.2s;
`;

const Overlay = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  animation: ${fadeInUpAnimation};
  z-index: 100;
`;

const LearnMorePage = styled.div`
  padding: 0px 16px 0px;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  & > * {
    z-index: 100;
  }
  justify-content: space-between;
`;

const Content = styled.div`
  flex: 1 0 auto;
  max-width: 724px;
  margin-left: auto;
  margin-right: auto;
`;

const StickyHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 120;
  background: white;
`;

const Footer = styled.div`
  padding: 16px;

  flex: 0 0 auto;

  margin-top: auto;
  margin-left: -16px;
  margin-right: -16px;
  flex-shrink: 0;

  background: ${COLORS.white};
`;

const BackButton = styled(Button)`
  width: 111px;
`;

const HeaderContainer = styled.div`
  text-align: center;
  margin: 56px auto 44px auto;
  max-width: 424px;
  @media (max-width: ${MobileSize}) {
    margin-bottom: 24px;
  }
`;

const DownloadIcon = styled(Icon)`
  display: inline-flex;
  align-self: center;
  margin: 16px;
  width: 80px;
  height: 61px;
`;

const PriceHeader = styled(Text)`
  margin: 12px;
  color: ${COLORS.azure};
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1px;
`;

const CloseButton = styled.div`
  margin-left: 23px;
  margin-top: 23px;
  margin-bottom: 23px;
  width: 22px;
  height: 22px;
  z-index: 101;
`;

const BackArrow = styled(Icon)`
  cursor: pointer;
  transform: rotate(270deg);
  transition: transform 0.3s ease-out;
  width: 22px;
  height: 22px;
  @media (max-width: ${MobileSize}) {
    width: 20px;
    height: 20px;
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-image: linear-gradient(to bottom, #fff, #eceff1 155%);
  @media (max-width: ${MobileSize}) {
    background-image: unset;
    background: white;
  }
`;
