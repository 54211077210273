import React from 'react';
import styled from 'styled-components';

import {
  COLORS,
  Statistic,
  Header,
  Text,
  Target,
  Icon,
  FlexColumn,
  FlexRow,
} from '@summer/ui-components/src';

import Link from 'components/shared/typography/Link';
import { MobileSize } from 'constants/styleguide';

const SavingsTargetSide = ({ savingsTarget }) => {
  return (
    <Container>
      <IconRow>
        <FlexColumn>
          <Header as="h5">Savings Target</Header>
          <TargetStatistic>
            <Statistic
              bold
              size="large"
              format="dollars"
              value={savingsTarget}
            />
          </TargetStatistic>
        </FlexColumn>
        <TargetIcon color={COLORS.azure} SvgComponent={Target} />
      </IconRow>
      <Text paragraph size="small">
        This is the amount you need to set aside in your emergency savings
        account
      </Text>
      <CTA>
        <Link to="/emergency-savings/wizard">Manage</Link>
      </CTA>
    </Container>
  );
};

const IconRow = styled(FlexRow)`
  justify-content: space-between;
`;

const TargetIcon = styled(Icon)`
  align-self: start;
`;

const TargetStatistic = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`;

const CTA = styled.div`
  margin-top: 24px;
`;

const Container = styled.aside`
  padding: 32px 24px;
  background: ${COLORS.grey};
  border-radius: 3px;
  max-width: 340px;
  margin-left: 16px;

  @media (max-width: ${MobileSize}) {
    margin-left: 0;
    max-width: 100%;
  }
`;

export default SavingsTargetSide;
