import React, { useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Modal,
  Text,
  Strong,
  Icon,
  Close,
  COLORS,
} from '@summer/ui-components';

import ConnectFsa from 'components/common/SyncLoans/ConnectFsa';
import Platform, { MOBILE } from 'components/hoc/Platform';
import { MobileSize } from 'constants/styleguide';
import { closeConnectFsa } from 'redux/actions/ui.actions';
import { isConnectFsaModalOpen } from 'redux/selectors/ui.selectors';

const connectFsaModalText = () => (
  <ModalTextContainer>
    <Text paragraph>
      We need accurate information about your loans to determine which{' '}
      <Strong>repayment plans</Strong> and{' '}
      <Strong>loan forgiveness options</Strong> you are eligible for.
    </Text>
  </ModalTextContainer>
);

const ConnectFsaModal = ({ isModalOpen, platformType, closeConnectFsa }) => {
  const isMobile = platformType === MOBILE;
  const scrollElementRef = useRef();

  return (
    <Modal
      scrollElementRef={scrollElementRef}
      isOpen={isModalOpen}
      togglePopup={() => closeConnectFsa()}
    >
      <Container ref={scrollElementRef}>
        <ConnectFsa
          page="modal"
          headerSize="h1"
          headerContent={isMobile && connectFsaModalText()}
          bodyText={!isMobile && connectFsaModalText()}
          hideTermsOfUse
        />
        <CloseContainer>
          <Icon
            width={16}
            height={16}
            SvgComponent={Close}
            fill={COLORS.medGrey}
            onClick={closeConnectFsa}
          />
        </CloseContainer>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  position: relative;
  height: inherit;
  background-color: ${COLORS.white};
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  border-radius: 6px;
`;

const CloseContainer = styled.div`
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
`;

const ModalTextContainer = styled.div`
  margin-top: 16px;

  @media (max-width: ${MobileSize}) {
    margin-top: 0;
  }
`;

const mapStateToProps = state => ({
  isModalOpen: isConnectFsaModalOpen(state),
});

export default Platform(
  connect(mapStateToProps, { closeConnectFsa })(ConnectFsaModal),
);
