import React from 'react';
import ReactDOM from 'react-dom';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { Manager, Reference, Popper } from 'react-popper';
import { isFunction, startsWith, isEmpty } from 'lodash';

import { COLORS, MobileSize } from '../../helpers/constants/styleguide';

import Icon, { Close } from '../Icon';

const createPortalContainer = () => {
  const container = document.createElement('div');
  container.style.pointerEvents = 'none';
  container.style.position = 'absolute';
  container.style.height = '100%';
  container.style.width = '100%';
  container.style.top = 0;
  container.style.zIndex = '100';
  container.style.pointerEvents = 'none';

  document.body.appendChild(container);
  return container;
};

const removePortalContainer = container => {
  if (document.body.contains(container)) {
    document.body.removeChild(container);
  }
};

export const offsetFromReferenceEdge = (
  offsetDistance = 0, // px
) => {
  return ({ placement, reference }) => {
    const skidding = 0;
    let distance = offsetDistance;
    if (startsWith(placement, 'top') || startsWith(placement, 'bottom')) {
      distance = reference.height / 2 + offsetDistance;
    }
    if (startsWith(placement, 'right') || startsWith(placement, 'left')) {
      distance = reference.width / 2 + offsetDistance;
    }
    return [skidding, distance];
  };
};

class TooltipFloater extends React.Component {
  state = {
    isOpen: false,
    queue: [],
    /* FIXME
     * This component was written before we had the Platform HOC
     * We can now use that instead of tracking window width state here
     * Need to fix issue with storybook first, see [ch24060]
     */
    windowWidth: null,
  };

  static propTypes = {
    children: PropTypes.element,
    content: PropTypes.func,
    placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right', 'auto']),
    width: PropTypes.number,
    /** See popper.js docs: https://popper.js.org/docs/v2/modifiers/offset/ */
    offset: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
    backgroundColor: PropTypes.string,
    padding: PropTypes.string,
    /** Used for mixpanel tracking */
    track: PropTypes.func,
    kind: PropTypes.string,
  };

  static defaultProps = {
    placement: 'bottom',
    width: 260,
    offset: offsetFromReferenceEdge(),
    backgroundColor: COLORS.white,
    padding: '16px 16px',
  };

  updateWindowWidth = () => this.setState({ windowWidth: window.innerWidth });

  onResize = () => {
    /* Closes tooltip when window size changes. Helps prevent weird bugs when moving to / from mobile
     * view in web inspector
     */
    if (this.state.isOpen) {
      this.closeTooltip();
    }
    this.updateWindowWidth();
  };

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    removePortalContainer(this.container);
  }

  openTooltip = () => {
    if (isFunction(this.props.track)) {
      this.props.track('[UI] opened tooltip', {
        tooltip: this.props.kind,
      });
    }
    if (!(this.container instanceof HTMLElement)) {
      this.container = createPortalContainer();
    }
    this.setState({ isOpen: true });
    document.body.style.overflow = 'hidden';
  };

  handleOpenTooltip = e => {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.isOpen) {
      // tooltip is already open, track state of open events
      this.setState(state => ({ queue: [...state.queue, true] }));
    } else {
      this.openTooltip();
    }
  };

  closeTooltip = () => {
    if (this.container) {
      removePortalContainer(this.container);
      this.container = null;
    }
    this.setState({ isOpen: false, queue: [] });
    document.body.style.overflow = 'visible';
  };

  handleCloseTooltip = e => {
    if (e.type === 'click') {
      // bypass queue for click events
      this.closeTooltip();
    } else {
      const closeDelay = 200;
      setTimeout(() => {
        if (isEmpty(this.state.queue)) {
          this.closeTooltip();
        } else {
          // remove an element from the queue
          this.setState(state => ({ queue: state.queue.slice(1) }));
        }
      }, closeDelay);
    }
  };

  renderTooltip = ({ ref, style, placement, arrowProps }) => {
    const { width, content, backgroundColor, padding, customZIndex } =
      this.props;

    const isMobile = this.state.windowWidth < parseInt(MobileSize, 10);

    return isMobile ? (
      <MobileContainer onClick={this.handleCloseTooltip}>
        <TooltipContainer
          customZIndex={customZIndex}
          backgroundColor={backgroundColor}
          padding={padding}
          onClick={e => e.stopPropagation()}
        >
          <div onClick={this.handleCloseTooltip}>
            <CloseIcon SvgComponent={Close} fill={COLORS.medGrey} />
          </div>
          <TooltipMobileContentContainer>
            {content({ closeTooltip: this.handleCloseTooltip })}
          </TooltipMobileContentContainer>
        </TooltipContainer>
      </MobileContainer>
    ) : (
      <TooltipContainer
        customZIndex={customZIndex}
        width={width}
        ref={ref}
        style={style}
        backgroundColor={backgroundColor}
        padding={padding}
        data-placement={placement}
        onMouseLeave={this.handleCloseTooltip}
        onMouseEnter={this.handleOpenTooltip}
        onClick={this.handleCloseTooltip}
      >
        {content({ closeTooltip: this.handleCloseTooltip })}
        <Arrow
          ref={arrowProps.ref}
          style={{
            ...arrowProps.style,
            // Workaround for issues caused by upgrade to react-popper v2
            // See if we can remove when refactoring to use usePopper hook
            transform: `${arrowProps.style?.transform} rotate(-45deg)`,
          }}
          backgroundColor={backgroundColor}
          data-placement={placement}
        />
      </TooltipContainer>
    );
  };

  renderPopper = () => {
    const { placement, offset } = this.props;

    return (
      <Popper
        placement={placement}
        eventsEnabled={false}
        positionFixed={true}
        modifiers={[
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'viewport',
            },
          },
          { name: 'offset', options: { offset } },
        ]}
      >
        {this.renderTooltip}
      </Popper>
    );
  };

  render() {
    const { children } = this.props;

    const isMobile = this.state.windowWidth < parseInt(MobileSize, 10);
    const doNothing = () => {};

    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <TriggerWrapper
              onClick={isMobile ? this.handleOpenTooltip : doNothing}
              onMouseEnter={isMobile ? doNothing : this.handleOpenTooltip}
              onMouseLeave={isMobile ? doNothing : this.handleCloseTooltip}
              ref={ref}
              className={this.state.isOpen ? 'isOpen' : ''}
              style={this.props.style}
            >
              {children}
            </TriggerWrapper>
          )}
        </Reference>
        {this.state.isOpen &&
          ReactDOM.createPortal(this.renderPopper(), this.container)}
      </Manager>
    );
  }
}

const TriggerWrapper = styled.div`
  font-size: inherit;
  display: inline-block;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeInAnimation = css`
  ${fadeIn} 0.5s;
`;

const TooltipContainer = styled.div`
  padding: ${props => props.padding};
  width: ${props => props.width}px;
  background-color: ${props => props.backgroundColor};
  box-shadow: 0 1px 8px 0 rgba(143, 152, 165, 0.47);
  border-radius: 5px;
  z-index: ${props => props.customZIndex || '999'};
  text-align: initial;
  pointer-events: auto;

  @media (max-width: ${MobileSize}) {
    width: calc(100vw - 32px);
    transform: translateY(calc(30vh - 30%));
    max-height: calc(100vh - 32px);
    top: 0;
    left: 0;
    position: fixed;
    margin: 16px;
  }

  animation: ${fadeInAnimation};
`;

const MobileContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  pointer-events: auto;
`;

const Arrow = styled.div`
  position: absolute;

  &[data-placement*='bottom'] {
    content: '';
    position: absolute;
    box-sizing: border-box;
    border: 6px solid ${props => props.backgroundColor};
    transform-origin: 80% 60%;
    top: -8px;
    box-shadow: 2px -2px 2px 0 rgba(178, 178, 178, 0.2);
  }
  &[data-placement*='top'] {
    content: '';
    position: absolute;
    box-sizing: border-box;
    border: 6px solid ${props => props.backgroundColor};
    transform-origin: 80% 60%;
    bottom: -2px;
    box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4);
  }
  &[data-placement*='right'] {
    content: '';
    position: absolute;
    box-sizing: border-box;
    border: 10px solid ${props => props.backgroundColor};
    transform-origin: 50% 50%;
    left: -8px;
    box-shadow: -2px -2px 2px 0 rgba(178, 178, 178, 0.3);
  }
  &[data-placement*='left'] {
    content: '';
    position: absolute;
    box-sizing: border-box;
    border: 10px solid ${props => props.backgroundColor};
    transform-origin: 50% 50%;
    right: -8px;
    box-shadow: 2px 2px 2px 0 rgba(178, 178, 178, 0.3);
  }
`;

const CloseIcon = styled(Icon)`
  cursor: pointer;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 10px;
  top: 10px;

  @media (max-width: ${MobileSize}) {
    position: fixed;
    top: auto;
  }
`;

const TooltipMobileContentContainer = styled.div`
  padding: 8px;
`;

export default TooltipFloater;
