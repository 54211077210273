/* eslint-disable no-console */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Track from 'components/hoc/Track';
import {
  blockUserOnPlansStep,
  blockUserOnSubmitStep,
} from 'redux/selectors/idr.selectors';

import DefaultBlocker from './DefaultBlocker';
import { LOANS_IN_DEFAULT, REQUIRES_CONSOLIDATION } from './constants';
import ConsolidationBlocker from './scenarios/ConsolidationBlocker';
import LoansInDefault from './scenarios/LoansInDefault';

const BlockingPages = {
  [LOANS_IN_DEFAULT]: <LoansInDefault />,
  [REQUIRES_CONSOLIDATION]: <ConsolidationBlocker />,
};

class BlockingPage extends React.Component {
  static propTypes = {
    step: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { track } = this.props;
    const reasonUserIsBlocked = this.getBlockedReason();
    this.logBlockingReasonToConsole(reasonUserIsBlocked);
    track(`[UI] Situation is unique. Reason: ${reasonUserIsBlocked}`);
  }

  getBlockedReason() {
    const { step, plansStepBlockedReason, submitStepBlockedReason } =
      this.props;

    if (step === 'Review Your Plan') {
      return plansStepBlockedReason;
    }
    if (step === 'Submit') {
      return submitStepBlockedReason;
    }

    return null;
  }

  logBlockingReasonToConsole(reasonUserIsBlocked) {
    const { step } = this.props;

    if (reasonUserIsBlocked) {
      console.info(
        `User blocked on ${step} step. Reason: ${reasonUserIsBlocked}`,
      );
    } else {
      console.info(
        `User blocked on ${step} step by client. Reason not provided.`,
      );
    }
  }

  render() {
    const reasonUserIsBlocked = this.getBlockedReason();

    return BlockingPages[reasonUserIsBlocked] || <DefaultBlocker />;
  }
}

const mapStateToProps = state => ({
  plansStepBlockedReason: blockUserOnPlansStep(state),
  submitStepBlockedReason: blockUserOnSubmitStep(state),
});

export default Track(connect(mapStateToProps)(BlockingPage));
