import { subMonths, isBefore } from 'date-fns';
import _, { isNil, get } from 'lodash';
import fp from 'lodash/fp';
import { createSelector } from 'reselect';

export const hasSyncedLoans = state => {
  return !!state.loans.find(loan => !isNil(loan.accountId));
};

export const getMostRecentSync = _.flow(
  fp.get(`sync.self.syncs`),
  fp.sortBy('id'),
  fp.last,
);

export const getMostRecentFsaUploadDate = state =>
  get(state, 'sync.self.updatedAt');

export const STALE_FSA_FILE_MONTHS = 3;

export const getIsFsaLoanFileStale = createSelector(
  getMostRecentFsaUploadDate,
  mostRecentFsaUploadDate => {
    const uploadDate = new Date(mostRecentFsaUploadDate);
    const staleDate = subMonths(new Date(), STALE_FSA_FILE_MONTHS);
    return isBefore(uploadDate, staleDate);
  },
);
