import { LOCATION_CHANGE } from 'connected-react-router';
import { get } from 'lodash/fp';
import { REHYDRATE } from 'redux-persist';

import { LOG_TOOLTIP, SET_USER } from 'constants/actionTypes';
import handleSideEffects from 'redux/handleSideEffects';

const pageView = pathname => {
  window.ga('set', 'page', pathname); // we need this to know in which page we fire event
  window.ga('send', 'pageview');
};

const event = (eventCategory, eventAction, eventLabel, eventValue) => {
  window.ga(
    'send',
    'event',
    eventCategory,
    eventAction,
    eventLabel,
    eventValue,
  );
};

const analyticsMiddleware = handleSideEffects({
  [SET_USER]: (dispatch, { payload }) => {
    if (window.ga) {
      window.ga('set', 'userId', payload.user.id);
    }
  },

  [REHYDRATE]: (dispatch, { payload }) => {
    const userId = get('currentUser.id', payload);
    if (window.ga && userId) {
      window.ga('set', 'userId', userId);
    }
  },

  [LOCATION_CHANGE]: (dispatch, { payload }) => {
    if (window.ga) {
      if (payload.pathname !== '/') {
        pageView(payload.pathname);
      }
    }
  },

  [LOG_TOOLTIP]: (dispatch, action, state) => {
    if (window.ga) {
      event('tooltip', 'open', state.router.location.pathname);
    }
  },
});

export default analyticsMiddleware;
