import { push as redirectTo } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  COLORS,
  FlexRow,
  FlexColumn,
  Header,
  Text,
} from '@summer/ui-components';

import { addEmergencySavingsPayrollProvider } from 'redux/actions/emergencySavings.actions';

const logoBase = '/images/emergencySavingsPayrollLogos';

const options = [
  {
    name: 'Justworks',
    logo: `${logoBase}/justworks.png`,
  },
  {
    name: 'Amazon',
    logo: `${logoBase}/amazon.png`,
  },
  {
    name: 'ADP',
    logo: `${logoBase}/adp.png`,
  },
  {
    name: 'AT&T',
    logo: `${logoBase}/att.png`,
  },
  {
    name: 'Best Buy',
    logo: `${logoBase}/bestbuy.png`,
  },
  {
    name: 'Gusto',
    logo: `${logoBase}/gusto.png`,
  },
];

const EmergencySavingsPayroll = () => {
  const dispatch = useDispatch();

  const handleOptionClick = ({ name, logo }) => {
    dispatch(
      addEmergencySavingsPayrollProvider({
        name,
        logo,
        frequency: 'Weekly',
        amount: 4300,
      }),
    );
    dispatch(redirectTo('/emergency-savings'));
  };

  return (
    <Page>
      <Container>
        <Header as="h1">Who is your Payroll provider?</Header>
        <Text paragraph>Select provider</Text>
        <Options>
          {options.map(({ name, logo }) => (
            <Option
              key={name}
              onClick={() => handleOptionClick({ name, logo })}
            >
              <img src={logo} alt={`${name} Logo`} />
              <Text bold>{name}</Text>
            </Option>
          ))}
        </Options>
      </Container>
    </Page>
  );
};

const Page = styled.div`
  width 100%;
  margin-bottom: 32px;
  margin-top: 120px;
`;

const Container = styled(FlexColumn)`
  max-width: 580px;
  margin-left: auto;
  margin-right: auto;
  gap: 16px;
`;

const Options = styled(FlexRow)`
  margin-top: 24px;
  flex-wrap: wrap;
  gap: 24px;
`;

const Option = styled(FlexRow)`
  flex-grow: 1;
  max-width: calc(50% - 12px);
  width: calc(50% - 12px);
  height: 60px;
  border: 1px solid black;
  padding: 10px 22px;
  align-items: center;
  gap: 12px;
  border: 1px solid ${COLORS.medDarkGrey};
  border-radius: 4px;
  cursor: pointer;

  :hover {
    box-shadow: 0 2px 10px 2px ${COLORS.grey};
  }
`;

export default EmergencySavingsPayroll;
