import React from 'react';
import styled from 'styled-components';

import { Header, Text } from '@summer/ui-components';

import { hideSubmitSidebarSize } from 'constants/pslf';
import { MobileSize } from 'constants/styleguide';

const SubmitHeader = ({ children, navigation }) => {
  const { currentStep, numberOfSteps } = navigation;

  return (
    <Container>
      <StepCounter>
        <Text center color="secondary">
          Step {currentStep} of {numberOfSteps}
        </Text>
      </StepCounter>
      <Header as="h2" center>
        {children}
      </Header>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 32px;

  @media (max-width: ${MobileSize}) {
    margin-bottom: 24px;
  }
`;

const StepCounter = styled.div`
  margin-bottom: 8px;

  @media (min-width: ${hideSubmitSidebarSize}) {
    display: none;
  }
`;

export default SubmitHeader;
