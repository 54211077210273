import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  FlexRow,
  Text,
  AlertContainer,
  RadioButtons,
  COLORS,
} from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { fsaPerkinsCancellationLink } from 'constants/externalLinks/fsa';
import { MobileSize } from 'constants/styleguide';
import { userHasPerkinsLoans } from 'redux/selectors/loans.selectors';

class PerkinsAlert extends React.Component {
  componentDidUpdate() {
    // clear formik state if user does not have Perkins loans
    const { userHasPerkinsLoans, formik } = this.props;
    if (
      userHasPerkinsLoans !== true &&
      formik.values.userWillPursuePlc !== ''
    ) {
      formik.setFieldValue(`yourLoans.userWillPursuePlc`, '');
    }
  }

  render() {
    const { values, errors, handleChange, handleBlur, touched, isSubmitting } =
      this.props.formik;

    const errorMessage = errors.userWillPursuePlc;

    return (
      this.props.userHasPerkinsLoans && (
        <Container>
          <AlertContainer color={COLORS.azure}>
            <AlertContent>
              <Message>
                <Text bold paragraph>
                  It looks like you have Perkins Loans. Are you going to pursue
                  Perkins Loan Cancellation?
                </Text>
                <Text small paragraph>
                  You may qualify to have your Perkins Loans partially or
                  completely forgiven if you meet certain criteria.{' '}
                  <Link
                    underline
                    inheritColor
                    href={fsaPerkinsCancellationLink}
                  >
                    Learn&nbsp;more
                  </Link>
                </Text>
              </Message>
              <RadioContainer>
                <RadioButtons
                  hideError
                  name="yourLoans.userWillPursuePlc"
                  touched={touched.userWillPursuePlc}
                  isSubmitting={isSubmitting}
                  value={values.userWillPursuePlc}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  numberOfCol={2}
                  options={[
                    { value: 'true', label: 'Yes' },
                    { value: 'false', label: 'No' },
                  ]}
                />
              </RadioContainer>
            </AlertContent>
          </AlertContainer>
          <Error>
            {errorMessage && (
              <Text small right color="error">
                {errorMessage}
              </Text>
            )}
          </Error>
        </Container>
      )
    );
  }
}

const Container = styled.div``;

const Error = styled.div`
  height: 16px;
  margin-top: 8px;
`;

const Message = styled.div`
  flex: 1;
  & > div:first-child {
    margin-bottom: 8px;
  }
`;

const RadioContainer = styled.div`
  flex: 0;
  text-align: center;
  min-width: 220px;
  margin-left: 16px;
  margin-top: -7px;

  @media (max-width: ${MobileSize}) {
    margin-top: 16px;
    margin-left: 0;
  }
`;

const AlertContent = styled(FlexRow)`
  width: 100%;
  padding-left: 2px;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
  }
`;

const mapStateToProps = state => ({
  userHasPerkinsLoans: userHasPerkinsLoans(state),
});

export default connect(mapStateToProps)(PerkinsAlert);
