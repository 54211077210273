import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { PLAID_LINK_CUSTOMIZATION_NAMES } from '@simplifidev/shared/dist/constants/plaidLink';

import Loans from 'components/pages/idr/wizard/steps/YourLoansPlaid/Loans';
import { renderWizardSteps } from 'hooks/Wizard/utils';
import { usePlaid } from 'hooks/plaid';
import { fetchAllLoans } from 'redux/actions/loans.actions';
import { hasFederalPlaidLoans } from 'redux/selectors/plaid.selectors';
import { getShouldShowQuestionnaire } from 'redux/selectors/ui.selectors';

import PlaidExtra from './Loans/SyncView/PlaidExtra';

const YourLoansPlaid = ({
  shouldShowQuestionnaire,
  plaidLoanQuestionnaireSteps,
  idrHandlers: { setFieldValue },
  hasFederalLoans,
}) => {
  const [showPlaidCards, setShowPlaidCards] = useState(false);
  const [isPlaidDone, setIsPlaidDone] = useState(false);

  const {
    open: openPlaid,
    event: plaidEvent,
    isLoading: isPlaidLoading,
  } = usePlaid({
    linkCustomizationName: PLAID_LINK_CUSTOMIZATION_NAMES.idr,
    successAction: fetchAllLoans,
  });

  return (
    <Container>
      {shouldShowQuestionnaire ? (
        renderWizardSteps(plaidLoanQuestionnaireSteps)
      ) : (
        <>
          <Loans
            openPlaid={openPlaid}
            isPlaidLoading={isPlaidLoading}
            hasFederalLoans={hasFederalLoans}
            setFieldValue={setFieldValue}
          />
          <PlaidExtra
            openPlaid={openPlaid}
            isPlaidDone={isPlaidDone}
            setIsPlaidDone={setIsPlaidDone}
            plaidEvent={plaidEvent}
            hasFederalLoans={hasFederalLoans}
            showPlaidCards={showPlaidCards}
            setShowPlaidCards={setShowPlaidCards}
          />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
`;

const mapStateToProps = state => ({
  shouldShowQuestionnaire: getShouldShowQuestionnaire(state),
  hasFederalLoans: hasFederalPlaidLoans(state),
});

export default connect(mapStateToProps)(YourLoansPlaid);
