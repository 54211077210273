import { isEmpty } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import {
  ArrowUp,
  Button,
  COLORS,
  Collapse,
  Cross,
  FlexRow,
  Icon,
  Text,
} from '@summer/ui-components/src';
import Link from '@summer/ui-components/src/core/typography/Link';

import { StyledIcon } from 'components/pages/onboarding/shared';
import TransactionsTable from 'components/pages/retirementMatch/shared/TransactionsTable';
import ServicerLogoOrName from 'components/shared/Servicer/ServicerLogoOrName';
import { MobileSize, TabletSize } from 'constants/styleguide';
import { MOBILE, usePlatform } from 'hooks/platform';
import { hexToRgba } from 'utils/common';

const ServicersDisplay = ({
  PlaidTransactionsData = [],
  openPlaid,
  setIsPlaidDone,
  isPlaidReady,
}) => {
  const { platformType } = usePlatform();
  const isMobile = platformType === MOBILE;
  const expandInfoText = isMobile ? 'More' : 'More Information';
  const closeInfoText = isMobile ? 'Less' : 'Less Information';

  const hasPlaidTransactionData = !isEmpty(PlaidTransactionsData);

  const handleSync = () => {
    openPlaid();
    setIsPlaidDone(false);
  };

  return (
    <Container>
      <ButtonContainer>
        <Button
          type="button"
          kind="open-plaid"
          secondary
          width={280}
          onClick={handleSync}
          isLoading={isPlaidReady}
        >
          <StyledIcon
            SvgComponent={Cross}
            width={isMobile ? 22 : 24}
            fill={COLORS.azure}
          />
          {hasPlaidTransactionData
            ? 'Sync another loan servicer'
            : 'Sync your loan servicer'}
        </Button>
      </ButtonContainer>
      <SyncsContainer>
        {PlaidTransactionsData.map(entry => {
          return (
            <Collapse
              key={entry.institutionId}
              trigger={({ openState }) => (
                <CollapseTrigger open={openState}>
                  <ServicerLogoContainer>
                    <ServicerLogoOrName
                      institutionId={entry.institutionId}
                      servicerName={entry.servicerName}
                    />
                  </ServicerLogoContainer>
                  <InfoCard open={openState}>
                    <StyledText paragraph>
                      {openState ? closeInfoText : expandInfoText}
                    </StyledText>
                    <Arrow
                      open={openState}
                      SvgComponent={ArrowUp}
                      fill={COLORS.medGrey}
                    />
                  </InfoCard>
                </CollapseTrigger>
              )}
            >
              <CollapseContent>
                <TransactionsTable transactions={entry.transactions} />
              </CollapseContent>
            </Collapse>
          );
        })}
      </SyncsContainer>
      <ExploreContainer>
        <Text bold paragraph>
          Explore Summer
        </Text>
        <Text small paragraph>
          You&rsquo;re all set! Find all the ways to reduce your student debt by{' '}
          <Link underline to="/plan">
            exploring Summer&rsquo;s other tools
          </Link>{' '}
          or return to your retirement provider.
        </Text>
      </ExploreContainer>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 12px;
  padding-bottom: 28px;
  padding-left: 55px;
  padding-right: 55px;

  .card-table > div {
    padding: 0;
  }

  @media (max-width: ${TabletSize}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const ButtonContainer = styled(FlexRow)`
  justify-content: flex-end;
  margin-bottom: 20px;

  button {
    margin-left: auto;
    margin-right: 0;
    padding: 8px;

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: ${MobileSize}) {
    margin-top: 12px;
    margin-bottom: 10px;
    padding: 0 20px 0 20px;
  }
`;

const SyncsContainer = styled.div`
  max-width: 83vw;
`;

const menuItemStyles = `
  text-decoration: none;
  display: flex;
  position: relative;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 8px;
  height: auto;

  &:hover {
    background-color: ${hexToRgba(COLORS.white, 0.15)};
  }

  &.active {
    .text {
      color: ${COLORS.azure};
    }

    svg path, g {
      fill: ${COLORS.azure};
    }

    background-color: ${COLORS.white};
    border-bottom: none;
    border-radius: 3px 3px 0px 0px;
  }
`;

const CollapseTrigger = styled(FlexRow)`
  ${menuItemStyles}
  cursor: pointer;
  border: solid 1px ${COLORS.grey};
  border-bottom: ${props => (props.open ? 'none' : `solid 1px ${COLORS.grey}`)};
  border-radius: ${props => (props.open ? '3px 3px 0px 0px' : '3px')};
  margin-top: 16px;

  justify-content: space-between;
`;

const ServicerLogoContainer = styled(FlexRow)`
  align-items: center;
`;

const Arrow = styled(Icon)`
  cursor: pointer;
  transform: ${props => (props.open ? 'rotate(0deg)' : 'rotate(180deg)')};

  transition: transform 0.3s ease-out;
  margin-left: 10px;
  width: 18px;
`;

const CollapseContent = styled.div`
  padding: 24px 85px 24px 85px;
  border: solid 1px ${COLORS.grey};
  border-top: none;

  @media (max-width: ${MobileSize}) {
    padding: 10px;
  }
`;

const InfoCard = styled(FlexRow)`
  border: solid 1px ${COLORS.grey};
  margin-right: 10px;
  padding: 16px;

  @media (max-width: ${MobileSize}) {
    padding: 8px;
  }
`;

const StyledText = styled(Text)`
  margin-top: 1%;

  @media (max-width: ${MobileSize}) {
    margin: 0;
    padding-left: 5px;
    padding-right: 5px;
  }
`;

const ExploreContainer = styled.div`
  background-color: ${hexToRgba(COLORS.azure, 0.06)};
  margin-top: 28px;
  padding: 16px;
`;

export default ServicersDisplay;
