import { push as redirectTo } from 'connected-react-router';
import { Formik } from 'formik';
import { isNil, has } from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { FormStatus } from '@simplifidev/shared/dist/constants/forms';

import IDRForm from 'components/pages/idr/wizard/layout/IDRForm';
import PaywallIdr from 'components/pages/paywall/idr/PaywallIdr';
import {
  updateCurrentIdrForm,
  saveIdrCurrentStep,
} from 'redux/actions/idr.actions';
import { getIsSummerBasicEnabled } from 'redux/selectors/flags.selectors';
import {
  checkIfThisIsLastStep,
  doWeNeedSpouseInfo,
  getRequiredSupportingDocuments,
  getStepFromLocation,
  selectedPathIncludesConsolidation,
  getCurrentIdrForm,
  getSelectedPathId,
} from 'redux/selectors/idr.selectors';
import { userHasPerkinsLoans } from 'redux/selectors/loans.selectors';
import { showPaywallIdr } from 'redux/selectors/paywall.selectors';
import { getUserEmail } from 'redux/selectors/user.selectors';
import {
  confirmServicersSchema,
  confirmServicersSchemaConsolidation,
  consolidateLoansSchema,
  getYourLoansSchema,
  referencesSchema,
  signAndSendSchema,
  supportingDocsPayFrequencyIrsDisclosureSchema,
  yourContactInfoSchema,
} from 'schemas/idr';
import { getSubmitFieldsToSave } from 'utils/idr';
import { pickProfile, pickFinancialProfile } from 'utils/profile';
import { validateYupSchema } from 'utils/validate';

class IDRWizard extends PureComponent {
  state = {
    currentStepSubmitCount: 0,
  };

  resetSubmitCount = () => this.setState({ currentStepSubmitCount: 0 });

  incrementSubmitCount = () =>
    this.setState(({ currentStepSubmitCount }) => ({
      currentStepSubmitCount: currentStepSubmitCount + 1,
    }));

  componentDidUpdate(prevProps) {
    if (prevProps.saveIdrError !== this.props.saveIdrError) {
      if (!isNil(this.props.saveIdrError)) {
        this.props.redirectTo('/error');
      }
    }
  }

  validate = values => {
    let valuesToCheck = null;
    let schema = null;
    const route = this.props.location.pathname;
    switch (route) {
      case '/idr/your-loans':
        valuesToCheck = values.yourLoans;
        schema = getYourLoansSchema(this.props.userHasPerkinsLoans);
        break;
      case '/idr/submit/consolidate-loans':
        valuesToCheck = values.submit;
        schema = consolidateLoansSchema;
        break;
      case '/idr/submit/contact-info':
        valuesToCheck = values.submit;
        schema = yourContactInfoSchema(
          this.props.userEmail,
          this.props.requiresSpouseInfo,
          this.props.includesConsolidation,
        );
        break;
      case '/idr/submit/references':
        valuesToCheck = values.submit;
        schema = referencesSchema;
        break;
      case '/idr/submit/confirm-servicers':
        valuesToCheck = values.submit;
        schema = this.props.includesConsolidation
          ? confirmServicersSchemaConsolidation
          : confirmServicersSchema;
        break;
      case '/idr/submit/upload-documents':
        valuesToCheck = {
          ...values.supportingDocuments,
          ...values.submit,
        };
        schema = supportingDocsPayFrequencyIrsDisclosureSchema(
          has(this.props.requiredSupportingDocuments, 'userProofOfIncome'),
          has(this.props.requiredSupportingDocuments, 'spouseProofOfIncome'),
        );
        break;
      case '/idr/submit/sign-and-send':
        valuesToCheck = values.signAndSend;
        schema = signAndSendSchema;
        break;
      default:
        return {};
    }
    return new Promise(resolve => {
      validateYupSchema(valuesToCheck, schema)
        .then(() => {
          resolve({});
        })
        .catch(validateError => {
          if (validateError.inner.length > 0) {
            const errors = validateError.inner.reduce((object, item) => {
              return { ...object, [item.path]: item.message };
            }, {});
            resolve(errors);
          }
        });
    });
  };

  handleSave = (values, formikBag) => {
    const {
      updateCurrentIdrForm,
      idrCurrentStep,
      isThisLastStep,
      showSummerBasic,
    } = this.props;
    const { formId } = this.props.currentIdrForm;
    const nextStep = idrCurrentStep + 1;

    if (showSummerBasic && isThisLastStep) {
      this.props.redirectTo('/plan');
      return;
    }

    if (isThisLastStep) {
      const finish = true;
      updateCurrentIdrForm(
        {
          form: { formId },
          currentStep: 0,
          status: FormStatus.SIGNED,
        },
        { finish },
      );
      return;
    }

    switch (this.props.idrCurrentStep) {
      case 1: {
        // Tell Us About You (TUAY)
        const financialProfile = pickFinancialProfile(values.tellUsAboutYou);
        const profile = pickProfile(values.tellUsAboutYou);

        updateCurrentIdrForm({
          currentStep: nextStep,
          form: { formId },
          profile,
          financialProfile,
        });
        break;
      }
      case 2: {
        // Your Loans
        const { userWillPursuePlc } = values.yourLoans;
        updateCurrentIdrForm({
          currentStep: nextStep,
          form: { formId, userWillPursuePlc },
        });
        break;
      }
      case 3: {
        // Review Your Plan
        const { selectedPathId } = this.props;
        updateCurrentIdrForm({
          currentStep: nextStep,
          form: { formId, selectedPathId },
        });
        break;
      }
      case 4: // Submit
      case 5:
      case 6:
      case 7:
      case 8: {
        const { form, profile, financialProfile } = getSubmitFieldsToSave(
          values,
          this.props.location,
        );
        updateCurrentIdrForm({
          currentStep: nextStep,
          form: { formId, ...form },
          profile,
          financialProfile,
        });
        break;
      }
      default:
        updateCurrentIdrForm({
          currentStep: nextStep,
          form: { formId },
        });
        return;
    }
    formikBag.setTouched({});
    formikBag.setSubmitting(false);
    this.resetSubmitCount();
  };

  render() {
    const {
      currentIdrForm,
      idrCurrentStep,
      updateCurrentIdrForm,
      saveIdrCurrentStep,
      showPaywallIdr,
    } = this.props;
    const isStepBackValid = idrCurrentStep > 1;

    return (
      <>
        {showPaywallIdr ? (
          <PaywallIdr />
        ) : (
          <Formik
            initialValues={{
              tellUsAboutYou: currentIdrForm.tellUsAboutYou,
              yourLoans: currentIdrForm.yourLoans,
              submit: currentIdrForm.submit,
              calculations: currentIdrForm.calculations,
            }}
            validate={this.validate}
            onSubmit={this.handleSave}
          >
            {props => (
              <IDRForm
                {...props}
                idrCurrentStep={idrCurrentStep}
                isStepBackValid={isStepBackValid}
                updateCurrentIdrForm={updateCurrentIdrForm}
                saveIdrCurrentStep={saveIdrCurrentStep}
                resetSubmitCount={this.resetSubmitCount}
                incrementSubmitCount={this.incrementSubmitCount}
                currentStepSubmitCount={this.state.currentStepSubmitCount}
              />
            )}
          </Formik>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  idrCurrentStep: getStepFromLocation(state),
  isThisLastStep: checkIfThisIsLastStep(state),
  userEmail: getUserEmail(state),
  saveIdrError: state.ui.serverErrors.updateCurrentIdrForm,
  userHasPerkinsLoans: userHasPerkinsLoans(state),
  requiresSpouseInfo: doWeNeedSpouseInfo(state),
  includesConsolidation: selectedPathIncludesConsolidation(state),
  requiredSupportingDocuments: getRequiredSupportingDocuments(state),
  currentIdrForm: getCurrentIdrForm(state),
  selectedPathId: getSelectedPathId(state),
  showPaywallIdr: showPaywallIdr(state),
  showSummerBasic: getIsSummerBasicEnabled(state),
});

export default connect(mapStateToProps, {
  updateCurrentIdrForm,
  saveIdrCurrentStep,
  redirectTo,
})(IDRWizard);
