import {
  FETCH_SYNC_STATUS,
  DELETE_SYNC,
  SET_SYNCS,
} from 'constants/actionTypes';

export const deleteSync = successAction => ({
  type: DELETE_SYNC,
  successAction,
});

export const fetchSyncStatus = (payload = { poll: false }, successAction) => ({
  type: FETCH_SYNC_STATUS,
  payload,
  successAction,
});

export const setSyncs = syncs => ({
  type: SET_SYNCS,
  payload: syncs,
});
