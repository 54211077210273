import { push as redirectTo } from 'connected-react-router';
import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  FlexRow,
  FlexCenter,
  Button,
  Header,
  Text,
  Strong,
  COLORS,
} from '@summer/ui-components';

import { MobileSize } from 'constants/styleguide';
import {
  fetchCurrentPslfForm,
  setCurrentPslfForm,
} from 'redux/actions/pslf.actions';
import { getUserPslfForms } from 'redux/selectors/pslf.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { hexToRgba } from 'utils/common';

const StartPage = ({ pslfForms, isCurrentFormLoading, dispatch }) => {
  const numberOfForms = get(pslfForms, 'length');

  const showContinue = numberOfForms === 1;
  const formId = get(pslfForms, '[0].formId');
  const currentStep = get(pslfForms, '[0].currentStep');

  return (
    <>
      <Box>
        <Container>
          <ImageContainer>
            <img
              src="/images/pslf/pslf-start.png"
              alt="Pslf Start"
              width="100%"
            />
          </ImageContainer>
          <Right>
            <Header h2>Let’s check to see if you’re eligible!</Header>
            <Text paragraph>
              You could have your federal debt forgiven if you work in public
              service and you make a and you make a total of 120 qualifying
              monthly payment.
            </Text>
            <OrderedList>
              <Text paragraph as="li">
                <TextContent>
                  <Oval>
                    <Strong>1</Strong>
                  </Oval>
                  First, we’ll take a look at your employers
                </TextContent>
              </Text>
              <Text paragraph as="li">
                <TextContent>
                  <Oval>
                    <Strong>2</Strong>
                  </Oval>
                  Then, we’ll check over your loans
                </TextContent>
              </Text>
              <Text paragraph as="li">
                <TextContent>
                  <Oval>
                    <Strong>3</Strong>
                  </Oval>
                  If everything looks good, you can complete your PSLF
                  employment certification form and track your progress
                </TextContent>
              </Text>
            </OrderedList>
          </Right>
        </Container>
        <ButtonContainer>
          <Button
            width={163}
            small
            isLoading={isCurrentFormLoading}
            disabled={isCurrentFormLoading}
            onClick={
              showContinue
                ? () => {
                    dispatch(setCurrentPslfForm({ currentStep, formId }));
                    dispatch(fetchCurrentPslfForm(formId));
                  }
                : () => {
                    dispatch(redirectTo('/pslf/form'));
                  }
            }
          >
            {showContinue ? 'Continue' : 'Get Started'}
          </Button>
        </ButtonContainer>
      </Box>
    </>
  );
};

const Box = styled.div`
  padding: 32px 32px 0;

  @media (max-width: ${MobileSize}) {
    width: 100%;
    padding: 8px;
  }
`;

const ImageContainer = styled.div`
  max-width: 360px;

  @media (max-width: ${MobileSize}) {
    max-width: 288px;
  }
`;

const Container = styled(FlexRow)`
  justify-content: center;
  min-height: 130px;
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: ${MobileSize}) {
    flex-wrap: wrap;
  }
`;

const ButtonContainer = styled(FlexCenter)`
  margin-top: 16px;
  justify-content: flex-end;

  @media (max-width: ${MobileSize}) {
    margin-right: 8px;
    margin-left: 8px;
    flex-wrap: wrap;
  }
`;

const Right = styled.div`
  padding-top: 16px;
  padding-left: 16px;

  & > h3 {
    margin-bottom: 8px;
  }

  @media (max-width: ${MobileSize}) {
    margin-right: 8px;
    margin-left: 8px;
    flex-wrap: wrap;
    padding-top: 0;
  }
`;

const OrderedList = styled.ol`
  margin-bottom: 24px;
  margin-top: 24px;
  list-style-type: none;

  & > * {
    margin-bottom: 24px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
  & .icon {
    margin-right: 16px;
  }
`;

const Oval = styled(FlexCenter)`
  border-radius: 50%;
  width: 32px;
  min-width: 32px;
  height: 32px;
  background-color: ${hexToRgba(COLORS.azure, '0.08')};
  margin-right: 12px;

  text-align: center;
`;

const TextContent = styled(FlexRow)`
  align-items: baseline;
`;

const mapStateToProps = state => ({
  pslfForms: getUserPslfForms(state),
  isCurrentFormLoading: isLoading(state, 'fetchCurrentPslfForm'),
});

export default connect(mapStateToProps)(StartPage);
