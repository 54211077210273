import React from 'react';
import styled from 'styled-components';

import { TextInput } from '@summer/ui-components';

import { Row } from 'components/pages/idr/wizard/shared/styles';
import { camelWithPrefix } from 'utils/text';

const RelationshipToYou = ({
  prefix,
  formik: { values, errors, handleChange, handleBlur, touched, isSubmitting },
}) => {
  const namePrefix = 'submit.';

  const getFormikName = name => namePrefix + camelWithPrefix(prefix, name);
  const getFormikPropertyName = name => camelWithPrefix(prefix, name);

  return (
    <Row>
      <TextInput
        label="Relationship to you"
        placeholder="Relationship to you"
        name={getFormikName('relationshipToUser')}
        touched={touched[getFormikPropertyName('relationshipToUser')]}
        isSubmitting={isSubmitting}
        error={errors[getFormikPropertyName('relationshipToUser')]}
        value={values[getFormikPropertyName('relationshipToUser')]}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Placeholder />
    </Row>
  );
};

const Placeholder = styled.div`
  width: 100%;
`;

export default RelationshipToYou;
