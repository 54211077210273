export const savingsMarketplaceCapitalOneLink =
  'https://www.capitalone.com/bank/savings-accounts/online-performance-savings-account/';
export const savingsMarketplaceDiscoverLink =
  'https://www.discover.com/online-banking/savings-lng-01/';
export const savingsMarketplaceAllyLink =
  'https://www.ally.com/bank/online-savings-account/';
export const savingsMarketplaceWealthfrontLink =
  'https://www.wealthfront.com/cash';
export const savingsMarketplaceCreditKarmaLink =
  'https://www.creditkarma.com/savings';
export const savingsMarketplaceSynchronyLink =
  'https://www.synchronybank.com/banking/high-yield-savings';
export const savingsMarketplaceVaroLink =
  'https://www.varomoney.com/savings-account/';
export const savingsMarketplaceBettermentLink =
  'https://www.betterment.com/cash-reserve';
export const savingsMarketplaceMarcusLink =
  'https://www.marcus.com/us/en/savings/high-yield-savings';
