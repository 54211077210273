import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// SVG Icons - Keep in alphabetical order
export { ReactComponent as AirBalloon } from '../assets/svgIcons/airBalloon.svg';
export { ReactComponent as ArrowUp } from '../assets/svgIcons/arrowUp.svg';
export { ReactComponent as AutoSaveSafe } from '../assets/svgIcons/autoSaveSafe.svg';
export { ReactComponent as Bank } from '../assets/svgIcons/bank.svg';
export { ReactComponent as BeggingHandCoin } from '../assets/svgIcons/beggingHandCoin.svg';
export { ReactComponent as BrokenLink } from '../assets/svgIcons/brokenLink.svg';
export { ReactComponent as Briefcase } from '../assets/svgIcons/briefcase.svg';
export { ReactComponent as BubbleCustom } from '../assets/svgIcons/bubble-custom.svg';
export { ReactComponent as Caret } from '../assets/svgIcons/caret.svg';
export { ReactComponent as Calculator } from '../assets/svgIcons/calculator.svg';
// TODO: Fix Callout question SVG to only use fill
export { ReactComponent as CalloutQuestion } from '../assets/svgIcons/callout-question.svg';
export { ReactComponent as Caution } from '../assets/svgIcons/caution.svg';
export { ReactComponent as Certificate } from '../assets/svgIcons/certificate.svg';
export { ReactComponent as ChartLineUp } from '../assets/svgIcons/chartLineUp.svg';
export { ReactComponent as Checklist } from '../assets/svgIcons/checklist.svg';
export { ReactComponent as Checkmark } from '../assets/svgIcons/checkmark.svg';
export { ReactComponent as CheckSmall } from '../assets/svgIcons/checkSmall.svg';
export { ReactComponent as CheckNew } from '../assets/svgIcons/checkNew.svg';
export { ReactComponent as Cheers } from '../assets/svgIcons/cheers.svg';
export { ReactComponent as Close } from '../assets/svgIcons/close.svg';
export { ReactComponent as CloseModal } from '../assets/svgIcons/closeModal.svg';
export { ReactComponent as CreditCard } from '../assets/svgIcons/creditCard.svg';
export { ReactComponent as Cross } from '../assets/svgIcons/cross.svg';
export { ReactComponent as Coins } from '../assets/svgIcons/coins.svg';
export { ReactComponent as CommonFileTextSearchCustom } from '../assets/svgIcons/common-file-text-search-custom.svg';
// TODO: Fix Consultation question SVG to only use fill
export { ReactComponent as Consultation } from '../assets/svgIcons/consultation.svg';
export { ReactComponent as CurrencyDollar } from '../assets/svgIcons/currency-dollar.svg';
export { ReactComponent as Document } from '../assets/svgIcons/document.svg';
export { ReactComponent as DollarSign } from '../assets/svgIcons/dollarSign.svg';
export { ReactComponent as Email } from '../assets/svgIcons/email.svg';
export { ReactComponent as EmergencyAlarm } from '../assets/svgIcons/emergencyAlarm.svg';
export { ReactComponent as Family } from '../assets/svgIcons/family.svg';
export { ReactComponent as FileIcon } from '../assets/svgIcons/fileIcon.svg';
export { ReactComponent as FileTextUpload } from '../assets/svgIcons/fileTextUpload.svg';
export { ReactComponent as FolderCustom } from '../assets/svgIcons/folder-custom.svg';
export { ReactComponent as FolderWarning } from '../assets/svgIcons/icon-folder-warning.svg';
export { ReactComponent as GearLarge } from '../assets/svgIcons/gearLarge.svg';
export { ReactComponent as GraduationCap } from '../assets/svgIcons/graduationCap.svg';
export { ReactComponent as GraduationCapMinimal } from '../assets/svgIcons/graduationCapMinimal.svg';
export { ReactComponent as Graph } from '../assets/svgIcons/graph.svg';
export { ReactComponent as Heart } from '../assets/svgIcons/heart.svg';
export { ReactComponent as HelpQuestionNetwork } from '../assets/svgIcons/helpQuestionNetwork.svg';
export { ReactComponent as HelpWheelCustom } from '../assets/svgIcons/help-wheel-custom.svg';
export { ReactComponent as HandCoins } from '../assets/svgIcons/handCoins.svg';
export { ReactComponent as HandsHoldingDollar } from '../assets/svgIcons/handsHoldingDollar.svg';
export { ReactComponent as HeartHand } from '../assets/svgIcons/heartHand.svg';
export { ReactComponent as InfinitySymbol } from '../assets/svgIcons/infinitySymbol.svg';
export { ReactComponent as InfoIcon } from '../assets/svgIcons/infoIcon.svg';
export { ReactComponent as LoanSync } from '../assets/svgIcons/loanSync.svg';
export { ReactComponent as LocationPin } from '../assets/svgIcons/location-pin.svg';
export { ReactComponent as Lock } from '../assets/svgIcons/lock.svg';
export { ReactComponent as LockWithKeyHole } from '../assets/svgIcons/lockWithKeyHole.svg';
export { ReactComponent as Mask } from '../assets/svgIcons/mask.svg';
export { ReactComponent as MoneySignInCircle } from '../assets/svgIcons/moneySignInCircle.svg';
export { ReactComponent as OpenBook } from '../assets/svgIcons/openBook.svg';
export { ReactComponent as OpenBookOutline } from '../assets/svgIcons/openBookOutline.svg';
export { ReactComponent as OpenExternalPageIcon } from '../assets/svgIcons/openExternalPage.svg';
export { ReactComponent as OverpaymentsIcon } from '../assets/svgIcons/overpayments-icon.svg';
export { ReactComponent as pathArrow } from '../assets/svgIcons/pathArrow.svg';
export { ReactComponent as PasswordResetKey } from '../assets/svgIcons/passwordResetKey.svg';
export { ReactComponent as Pencil } from '../assets/svgIcons/pencil.svg';
export { ReactComponent as PercentBubble } from '../assets/svgIcons/percentBubble.svg';
export { ReactComponent as Phone } from '../assets/svgIcons/phone.svg';
export { ReactComponent as PiggyBank } from '../assets/svgIcons/piggyBank.svg';
export { ReactComponent as PiggyBankCoins } from '../assets/svgIcons/piggyBankCoins.svg';
export { ReactComponent as PiggyBankNew } from '../assets/svgIcons/piggy-bank-new.svg';
export { ReactComponent as PiggyBank3 } from '../assets/svgIcons/piggyBank3.svg';
export { ReactComponent as PresentationBoard } from '../assets/svgIcons/presentation-board.svg';
export { ReactComponent as ProtectionShieldStar } from '../assets/svgIcons/protection-shield-star.svg';
export { ReactComponent as QuestionCircle } from '../assets/svgIcons/question-circle.svg';
export { ReactComponent as ReceiptViewPricing } from '../assets/svgIcons/receiptViewPricing.svg';
export { ReactComponent as RecruitingEmployeeGraduateMoney } from '../assets/svgIcons/recruitingEmployeeGraduateMoney.svg';
export { ReactComponent as SavingBankCash } from '../assets/svgIcons/savingBankCash.svg';
export { ReactComponent as SavingsPiggyBank } from '../assets/svgIcons/savingsPiggyBank.svg';
export { ReactComponent as SavingSafe } from '../assets/svgIcons/savingSafe.svg';
export { ReactComponent as SendEmail } from '../assets/svgIcons/SendEmailEnvelope.svg';
export { ReactComponent as ServerCash } from '../assets/svgIcons/serverCash.svg';
export { ReactComponent as ShieldExclamationMark } from '../assets/svgIcons/shield-exclamation-mark.svg';
export { ReactComponent as SlcIcon } from '../assets/svgIcons/slc-icon.svg';
export { ReactComponent as SortAz } from '../assets/svgIcons/sortAz.svg';
export { ReactComponent as SortZa } from '../assets/svgIcons/sortZa.svg';
export { ReactComponent as SplitPath } from '../assets/svgIcons/splitPath.svg';
export { ReactComponent as StopWatch } from '../assets/svgIcons/stopWatch.svg';
export { ReactComponent as SummerLogo } from '../assets/svgIcons/summerLogo.svg';
export { ReactComponent as SummerLogoSmall } from '../assets/svgIcons/summerLogoSmall.svg';
export { ReactComponent as SwimmingPool } from '../assets/svgIcons/swimmingPool.svg';
export { ReactComponent as SyncInfinity } from '../assets/svgIcons/syncInfinity.svg';
export { ReactComponent as Target } from '../assets/svgIcons/target.svg';
export { ReactComponent as TargetPhone } from '../assets/svgIcons/targetPhone.svg';
export { ReactComponent as TimeMonthly } from '../assets/svgIcons/timeMonthly.svg';
export { ReactComponent as Tools } from '../assets/svgIcons/tools.svg';
export { ReactComponent as Trash } from '../assets/svgIcons/trash.svg';
export { ReactComponent as TrashNew } from '../assets/svgIcons/trashNew.svg';
export { ReactComponent as WarningIcon } from '../assets/svgIcons/warningIcon.svg';
export { ReactComponent as Website } from '../assets/svgIcons/website.svg';

const Icon = ({
  SvgComponent,
  width,
  height,
  fill,
  stroke,
  hoverFill,
  hoverStroke,
  className,
  onClick,
  style,
  testId,
}) => {
  return (
    <StyledIcon
      testId={testId}
      svgWidth={width}
      svgHeight={height}
      fill={fill}
      stroke={stroke}
      hoverFill={hoverFill}
      className={className}
      hoverStroke={hoverStroke}
      onClick={onClick}
      style={style}
    >
      <SvgComponent />
    </StyledIcon>
  );
};

const StyledIcon = styled.div.attrs(props => ({
  'className': 'icon',
  'data-testid': props.testId || 'icon',
}))`
  display: flex;
  justify-content: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};

  & svg {
    width: ${({ svgWidth }) =>
      svgWidth ? `${svgWidth}px !important` : 'auto'};
    height: ${({ svgHeight }) =>
      svgHeight ? `${svgHeight}px !important` : 'auto'};
  }

  & path,
  g {
    fill: ${({ fill }) => (fill ? `${fill} !important` : '')};
    stroke: ${({ stroke }) => (stroke ? `${stroke} !important` : '')};
  }

  &:hover path,
  g {
    fill: ${({ hoverFill }) => (hoverFill ? `${hoverFill} !important` : '')};
    stroke: ${({ hoverStroke }) =>
      hoverStroke ? `${hoverStroke} !important` : ''};
  }
`;

Icon.propTypes = {
  SvgComponent: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  hoverFill: PropTypes.string,
  hoverStroke: PropTypes.string,
};

export default Icon;
