import { get } from 'lodash';

export const getFidelityUrlPartnerData = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return {
    urlData: {
      partnerIdentifierData: {
        identifierValue: queryParams.get('tid'),
        identifierMetadata: {
          clientId: queryParams.get('clientId'),
          fidelityContext: queryParams.get('fidelityContext'),
        },
      },
    },
    isValidUrl: true,
  };
};

export const getFidelityIdentifierData = urlData => {
  return get(urlData, 'urlData.partnerIdentifierData');
};
