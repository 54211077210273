import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import ReviewYourPlan from 'components/pages/idr/wizard/steps/ReviewYourPlan';
import Submit from 'components/pages/idr/wizard/steps/Submit';
import TellUsAboutYou from 'components/pages/idr/wizard/steps/TellUsAboutYou/TellUsAboutYou';
import YourLoans from 'components/pages/idr/wizard/steps/YourLoans';
import YourLoansPlaid from 'components/pages/idr/wizard/steps/YourLoansPlaid';
import {
  shouldUsePlaidFlow,
  arePlaidFederalLoansMissingInfo,
} from 'redux/selectors/plaid.selectors';
import { showPlaidInIdr } from 'services/featureFlags/flags';
import { track } from 'utils/track';

const IDRPages = ({
  idrErrors,
  idrValues,
  idrHandlers,
  idrTouched,
  isValid,
  resetSubmitCount,
  currentStepSubmitCount,
  plaidLoanQuestionnaireSteps,
  shouldUsePlaidFlow,
  arePlaidFederalLoansMissingInfo,
}) => {
  useEffect(
    function trackTextFileUploadTriggered() {
      if (showPlaidInIdr() && arePlaidFederalLoansMissingInfo) {
        track(
          '[IDR] User was sent to Text File Upload flow after syncing Plaid federal loans with missing info',
        );
      }
    },
    [arePlaidFederalLoansMissingInfo],
  );

  return (
    <Switch>
      {/*
          So here you need to add the Route and Your page Component
          like this: <Route exact path="/idr/{your_route}" component={your import component} />

          when you add new component remember to add the new route to naviagtion middleware
          so you can local test it, and remove it when you push to pr
           */}

      <Route
        exact
        path="/idr/tell-us-about-you"
        render={props => (
          <TellUsAboutYou
            values={idrValues.tellUsAboutYou}
            errors={idrErrors.tellUsAboutYou || {}}
            touched={idrTouched.tellUsAboutYou || {}}
            idrHandlers={idrHandlers}
            {...props}
          />
        )}
      />

      <Route
        exact
        path="/idr/your-loans"
        render={() =>
          shouldUsePlaidFlow ? (
            <YourLoansPlaid
              plaidLoanQuestionnaireSteps={plaidLoanQuestionnaireSteps}
              idrHandlers={idrHandlers}
            />
          ) : (
            <YourLoans
              values={idrValues.yourLoans}
              errors={idrErrors || {}}
              touched={idrTouched.yourLoans || {}}
              idrHandlers={idrHandlers}
              isValid={isValid}
              showMissingInfoHeader={
                showPlaidInIdr() && arePlaidFederalLoansMissingInfo
              }
            />
          )
        }
      />

      <Route
        exact
        path="/idr/review-your-plan"
        render={props => {
          return (
            <ReviewYourPlan
              values={idrValues.calculations}
              errors={idrErrors || {}}
              touched={idrTouched.calculations || {}}
              idrHandlers={idrHandlers}
              currentStepSubmitCount={currentStepSubmitCount}
              {...props}
            />
          );
        }}
      />

      <Route
        path="/idr/submit"
        render={({ location, match, history }) => (
          <Submit
            values={idrValues.submit}
            errors={idrErrors || {}}
            touched={idrTouched.submit || {}}
            idrHandlers={idrHandlers}
            location={location}
            match={match}
            history={history}
            resetSubmitCount={resetSubmitCount}
            currentStepSubmitCount={currentStepSubmitCount}
          />
        )}
      />

      <Redirect to="/idr/tell-us-about-you" />
    </Switch>
  );
};

const mapStateToProps = state => ({
  shouldUsePlaidFlow: shouldUsePlaidFlow(state),
  arePlaidFederalLoansMissingInfo: arePlaidFederalLoansMissingInfo(state),
});

export default withRouter(connect(mapStateToProps)(IDRPages));
