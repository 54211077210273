import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Text } from '../../typography';
import { COLORS } from '../../../helpers/constants/styleguide';
import TooltipFloater, { offsetFromReferenceEdge } from '../../TooltipFloater';
import Icon, { InfoIcon } from '../../Icon';

const getWidth = (text, width) => {
  if (width) {
    return width;
  }
  return text.length > 100 ? 300 : 240;
};

const InfoTooltip = ({ top, size, leftOffset, text, width, kind, track }) => (
  <IconContainer leftOffset={leftOffset} top={top}>
    <TooltipFloater
      track={track}
      kind={kind}
      placement="bottom"
      content={() => <Text small>{text}</Text>}
      width={getWidth(text, width)}
      offset={offsetFromReferenceEdge(4)}
    >
      <StyledIcon
        fill={COLORS.darkGreen}
        SvgComponent={InfoIcon}
        width={size}
        height={size}
      />
    </TooltipFloater>
  </IconContainer>
);

InfoTooltip.propTypes = {
  top: PropTypes.number,
  size: PropTypes.number,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  leftOffset: PropTypes.number,
  width: PropTypes.number,
  /** Used for mixpanel tracking */
  kind: PropTypes.string,
};

InfoTooltip.defaultProps = {
  text: '',
  size: 14,
  leftOffset: 6,
};

const StyledIcon = styled(Icon)`
  cursor: pointer;

  &:hover,
  .isOpen > & {
    opacity: 0.6;
  }
`;

const IconContainer = styled.div`
  display: inline-block;
  position: relative;
  top: ${({ top }) => top || 0}px;
  margin-left: ${({ leftOffset }) => leftOffset}px;
`;

export default InfoTooltip;
