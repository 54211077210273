import _, {
  filter,
  find,
  forEach,
  get,
  isEmpty,
  isNil,
  memoize,
  set,
  size,
} from 'lodash';
import fp from 'lodash/fp';

import { requireLoanPayoffDate } from '@simplifidev/shared/dist/utils/idr/requireLoanPayoffDate';

import {
  doesNotQualifyCopy,
  doesQualifyCopy,
  qualifiesOnlyWithConsolidationCopy,
} from 'constants/pslf';
import { oxfordComma } from 'utils/text';
import { stringToBool } from 'utils/toOptions';

export const isConsolidationLoan = (federalLoanTypeId, federalLoanTypes) => {
  const loanType = find(
    federalLoanTypes,
    loanType => loanType.id === federalLoanTypeId,
  );
  return get(loanType, 'isConsolidation');
};

export const showIncludesParentPlus = isConsolidationLoan;

export const loanIsActive = principalBalance => principalBalance !== 0;

export const findInactiveServicers = _.flow(
  fp.filter({ active: false }),
  fp.map('servicerName'),
  fp.uniq,
);

export const findActiveServicers = _.flow(
  fp.filter({ active: true }),
  fp.map('servicerName'),
  fp.uniq,
);

export const showRecertificationDate = (
  repaymentPlanTypeId,
  principalBalance,
  repaymentPlanTypes,
) => {
  const repaymentPlanType = find(
    repaymentPlanTypes,
    repaymentPlanType => repaymentPlanType.id === Number(repaymentPlanTypeId),
  );
  return get(repaymentPlanType, 'isIdrPlan') && loanIsActive(principalBalance);
};

export const showLoanPayoffDate = (
  disbursementDate,
  federalLoanTypeId,
  includesParentPlus,
  principalBalance,
  federalLoanTypes,
) => {
  const loanType = find(
    federalLoanTypes,
    loanType => loanType.id === federalLoanTypeId,
  );
  const loanTypeRequiresPayoffDate = get(loanType, 'payoffEligible');

  return requireLoanPayoffDate(
    disbursementDate,
    loanTypeRequiresPayoffDate,
    stringToBool(includesParentPlus),
    principalBalance,
  );
};

export const normalizedFsaLoan = ({ loansRevision = {}, ...loan }) => {
  const revision = loansRevision[0];

  const school =
    loan.attendingSchoolName === 'SCHOOL CODE FOR CONSOLIDATION LOANS'
      ? null
      : loan.attendingSchoolName;

  const { principalBalance, outstandingInterestBalance } = revision;
  const currentBalance =
    !isNil(principalBalance) && !isNil(outstandingInterestBalance)
      ? principalBalance + outstandingInterestBalance
      : null;
  const interestRate =
    !revision.interestRate && !isNil(revision.inferredInterestRate)
      ? revision.inferredInterestRate
      : revision.interestRate;

  return {
    id: loan.id,
    amountBorrowed: loan.amountBorrowed,
    educationLevel: loan.educationLevel,
    school,
    repaymentPlan:
      get(revision, 'repaymentPlanType.name') ||
      get(revision, 'repaymentPlanTypeRaw'),
    repaymentPlanShortName: get(revision, 'repaymentPlanType.shortName'),
    repaymentBeginDate: get(revision, 'repaymentBeginDate'),
    repaymentPlanStartDate: get(revision, 'repaymentPlanStartDate'),
    loanType:
      get(revision, 'federalLoanType.description') ||
      get(revision, 'federalLoanTypeRaw'),
    pslfEligibleLoanType: get(revision, 'federalLoanType.pslfEligible'),
    pslfEligibleRepaymentPlan: get(revision, 'repaymentPlanType.pslfEligible'),
    servicerName: get(revision, 'servicer.companyName'),
    servicerPhone: get(revision, 'servicer.contactPhone'),
    servicerUrl: get(revision, 'servicer.website'),
    servicerEmail: get(revision, 'servicer.contactEmail'),
    dateBorrowed: get(loan, 'disbursementDate[0].disbursementDate', null),
    status: get(revision, 'loanStatus.description', null),
    currentBalance,
    // Default to the interest rate we chose above
    interestRate,
    // Include the original and inferred rates to let components handle context
    // specific cases
    originalInterestRate: revision.interestRate,
    inferredInterestRate: revision.inferredInterestRate,
    interestType: revision.interestType || '',
    monthlyPayment: revision.monthlyPayment,
    principalBalance,
    loanStatusHistory: revision.loanStatusHistory,
    dueDate: revision.monthlyPaymentDate,
    pslfMatchedMonths: revision.pslfMatchedMonths,
    totalPayments: revision.cumulativeAmount,
    active: loanIsActive(principalBalance),

    // new field for manage loans
    servicerId: get(revision, 'servicer.id'),
    servicerRaw: get(revision, 'servicerRaw'),
    repaymentPlanTypeId: get(revision, 'repaymentPlanType.id'),
    federalLoanTypeId: get(revision, 'federalLoanType.id'),
    federalLoanTypeRaw: get(revision, 'federalLoanTypeRaw'),
    outstandingInterestBalance,
    idrRecertificationDate: revision.idrRecertificationDate,
    includesParentPlus: loan.includesParentPlus,
    loanPayoffDate: loan.loanPayoffDate,

    disbursementDateId: get(loan, 'disbursementDate[0].id', 0),
    // add duplicate data with different name to support old normalizer of manage loans
    disbursementDate: get(loan, 'disbursementDate[0].disbursementDate', null),

    pslfEligible: get(loan, 'pslfEligibilityAndConsolidationRec.pslfEligible'),
    pslfEligibilityAndConsolidationRec: get(
      loan,
      'pslfEligibilityAndConsolidationRec',
    ),
  };
};

export const normalizedPlaidLoan = loan => {
  const { principalBalance } = loan;
  const normalizedPlaidLoan = { ...loan };
  set(normalizedPlaidLoan, 'active', loanIsActive(principalBalance));
  return normalizedPlaidLoan;
};

const validateLoan = (schema = null, loan) => {
  if (isNil(schema)) {
    return loan;
  }

  try {
    schema.validateSync(loan, {
      abortEarly: false,
    });
    return loan;
  } catch ({ inner }) {
    const errors = {};
    forEach(inner, error => {
      errors[error.path] = error.message;
    });
    return {
      ...loan,
      valid: false,
      errors,
    };
  }
};

export const getNormalizedFsaLoans = (loans, yupSchemaToValidateLoan) => {
  return _.flow(
    fp.map(normalizedFsaLoan),
    fp.map(loan => validateLoan(yupSchemaToValidateLoan, loan)),
    fp.orderBy(['active', 'servicerName', 'id'], ['desc', 'asc', 'asc']),
  )(loans);
};

export const getNormalizedPlaidLoans = memoize(loans => {
  return _.flow(
    fp.map(normalizedPlaidLoan),
    fp.orderBy(['active', 'servicerName', 'id'], ['desc', 'asc', 'asc']),
  )(loans);
});

export const areLoansValid = loans => {
  if (isEmpty(loans)) {
    return false;
  }
  return isEmpty(filter(loans, { valid: false }));
};

/* This function takes an array of repayment plan type objects and returns
 * a string listing out the plan type names in the following format:
 * "Plan Type One (ABRV) and Plan Type 2 plan(s)".
 * ex. "Pay As You Earn (PAYE) plan"
 *     "Standard and Income Based Repayment (IBR) plans"
 *     "Standard, Extended, and Revised Pay As You Earn (REPAYE) plans"
 */
export const listPlanTypeNames = repaymentPlanTypes => {
  const multiplePlans = size(repaymentPlanTypes) > 1;
  return `${_.flow(
    fp.map(
      ({ name, shortName }) =>
        `${_.flow(fp.lowerCase, fp.startCase)(name)}${
          shortName ? ` (${shortName})` : ''
        }`,
    ),
    oxfordComma,
  )(repaymentPlanTypes)} plan${multiplePlans ? 's' : ''}`;
};

export const getPslfLoanTypeHelperText = federalLoanType => {
  const { isFFEL, isPerkins, isParentPlus, isDirect } = federalLoanType ?? {};

  const qualifiesOnlyWithConsolidation = (isFFEL || isPerkins) && !isParentPlus;
  const alwaysQualifies = isDirect && !isParentPlus;

  if (qualifiesOnlyWithConsolidation) {
    return qualifiesOnlyWithConsolidationCopy;
  }
  if (alwaysQualifies) {
    return doesQualifyCopy;
  }
  return doesNotQualifyCopy;
};
