import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Button,
  LoadingBars,
  Row,
  Strong,
  Text,
  FlexCenter,
  COLORS,
} from '@summer/ui-components';

import Track from 'components/hoc/Track';
import WizardContent from 'components/layout/wizard/WizardContent';
import WizardFooter from 'components/layout/wizard/WizardFooter';
import DefaultWizardContent from 'components/pages/default/wizard/layout/DefaultWizardContent';
import Layout from 'components/pages/default/wizard/layout/layout';
import { checklistConfig as consolidationChecklistConfig } from 'components/pages/default/wizard/steps/DefaultRecommendationsPage/consolidation/LoanConsolidationV1';
import { LoanConsolidationRecommendation } from 'components/pages/default/wizard/steps/DefaultRecommendationsPage/consolidation/LoanConsolidationV2';
import { checklistConfig as rehabilitationChecklistConfig } from 'components/pages/default/wizard/steps/DefaultRecommendationsPage/rehabilitation/LoanRehabilitationV1';
import {
  HowToStartLoanRehabilitation,
  LoanRehabilitationRecommendation,
} from 'components/pages/default/wizard/steps/DefaultRecommendationsPage/rehabilitation/LoanRehabilitationV2';
import { checklistConfig as settlementChecklistConfig } from 'components/pages/default/wizard/steps/DefaultRecommendationsPage/settlement/SettlementV1';
import { LoanSettlementRecommendation } from 'components/pages/default/wizard/steps/DefaultRecommendationsPage/settlement/SettlementV2';
import Link from 'components/shared/typography/Link';
import {
  getBestDefaultOption,
  getEstIdrMonthlyPayment,
  getEstRehabMonthlyPayment,
} from 'redux/selectors/recEngine.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';

const BoxContent = ({ checklistConfig, navigation }) => {
  const { header, items } = checklistConfig;

  return (
    <>
      <BoxHeader>
        <Strong>{header}</Strong>
      </BoxHeader>
      <List>
        {items.map((item, idx) => (
          <Text as="li" paragraph key={idx}>
            {item}
          </Text>
        ))}
      </List>
      <AllOptionsLink>
        <Link underline inheritColor onClick={navigation.next}>
          See all my options
        </Link>
      </AllOptionsLink>
    </>
  );
};

const LeftSection = ({
  bestDefaultOption,
  estIdrMonthlyPayment,
  estRehabMonthlyPayment,
  showHowToStartRehab,
}) => {
  switch (bestDefaultOption) {
    case 'rehabilitation':
      return showHowToStartRehab ? (
        <HowToStartLoanRehabilitation />
      ) : (
        <LoanRehabilitationRecommendation
          estIdrMonthlyPayment={estIdrMonthlyPayment}
          estRehabMonthlyPayment={estRehabMonthlyPayment}
        />
      );
    case 'consolidation':
      return (
        <LoanConsolidationRecommendation
          estIdrMonthlyPayment={estIdrMonthlyPayment}
        />
      );
    case 'settlement':
      return <LoanSettlementRecommendation />;
    default:
      return null;
  }
};

const checklistConfig = bestDefaultOption => {
  let checklistConfig;

  switch (bestDefaultOption) {
    case 'rehabilitation':
      checklistConfig = { ...rehabilitationChecklistConfig };
      break;
    case 'consolidation':
      checklistConfig = { ...consolidationChecklistConfig };
      break;
    case 'settlement':
      checklistConfig = { ...settlementChecklistConfig };
      break;
    default:
      return {};
  }

  checklistConfig.header = 'We recommend this option if:';
  return checklistConfig;
};

const DefaultRecommendationsPage = ({
  bestDefaultOption,
  estIdrMonthlyPayment,
  estRehabMonthlyPayment,
  isLoading,
  navigation,
  skippedStartPage,
  track,
}) => {
  const [showHowToStartRehab, setShowHowToStartRehab] = useState(false);

  return isLoading ? (
    <LoadingBarsContainer>
      <LoadingBars color={COLORS.azure} />
    </LoadingBarsContainer>
  ) : (
    <WizardContent>
      <DefaultWizardContent>
        <Layout
          leftSection={
            <>
              <LeftSection
                bestDefaultOption={bestDefaultOption}
                estIdrMonthlyPayment={estIdrMonthlyPayment}
                estRehabMonthlyPayment={estRehabMonthlyPayment}
                showHowToStartRehab={showHowToStartRehab}
              />
              {bestDefaultOption === 'rehabilitation' &&
                !showHowToStartRehab && (
                  <ContentRow desktopEnd>
                    <Button
                      width={218}
                      onClick={() => setShowHowToStartRehab(true)}
                      track={track}
                    >
                      Start Loan Rehabilitation
                    </Button>
                  </ContentRow>
                )}
            </>
          }
          boxContent={
            bestDefaultOption && (
              <BoxContent
                checklistConfig={checklistConfig(bestDefaultOption)}
                navigation={navigation}
              />
            )
          }
          boxColor={COLORS.lighterGrey}
        />
      </DefaultWizardContent>
      {(!skippedStartPage || showHowToStartRehab) && (
        <WizardFooter
          handleBack={
            showHowToStartRehab
              ? () => setShowHowToStartRehab(false)
              : navigation.back
          }
          isNextVisible={false}
        />
      )}
    </WizardContent>
  );
};

const LoadingBarsContainer = styled(FlexCenter)`
  height: 100vh;
`;

const ContentRow = styled(Row)`
  margin: 0;
`;

const List = styled.ul`
  margin-left: 24px;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const BoxHeader = styled(Text)`
  margin-bottom: 13px;
`;

const AllOptionsLink = styled.div`
  margin-top: 16px;
  color: ${COLORS.darkerGrey};
`;

const mapStateToProps = state => ({
  bestDefaultOption: getBestDefaultOption(state),
  estIdrMonthlyPayment: getEstIdrMonthlyPayment(state),
  estRehabMonthlyPayment: getEstRehabMonthlyPayment(state),
  isLoading:
    isLoading(state, 'fetchLoans') || isLoading(state, 'fetchRecommendations'),
});

export default Track(connect(mapStateToProps)(DefaultRecommendationsPage));
