/* eslint-disable max-classes-per-file */
import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Header,
  Text,
  Strong,
  Icon,
  Bank,
  Mask,
  FlexCenter,
  Button,
  RadioButtons,
  MoneyInput,
  COLORS,
} from '@summer/ui-components';

import StepHeader from 'components/pages/onboarding/StepHeader';
import {
  Form,
  OnboardingContainer,
  BackLink,
} from 'components/pages/onboarding/shared';
import ModalPopup from 'components/shared/ModalPopup';
import { Link } from 'components/shared/typography';
import { goBackOnboarding, setPageInfo } from 'redux/actions/onboard.actions';
import { fillLoanInfo } from 'redux/actions/user.actions';
import { isLoading } from 'redux/selectors/ui.selectors';
import {
  getUserFederalLoansEstimate,
  getLoansInDefaultFederalOnboardingQuestion,
  getUserPrivateLoansEstimate,
  getLoansInDefaultPrivateOnboardingQuestion,
  getIdrOnboardingQuestion,
} from 'redux/selectors/user.selectors';
import { loansInfoValidationSchema } from 'schemas/onboard';
import { scrollToError } from 'utils/formik';

class LoansInDefaultFederal extends React.Component {
  componentWillUnmount() {
    this.props.formik.setFieldValue('loansInDefaultFederal', '');
  }

  render() {
    const {
      formik: {
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        isSubmitting,
      },
    } = this.props;

    return (
      <RadioButtons
        row
        design="smallRec"
        name="loansInDefaultFederal"
        touched={touched.loansInDefaultFederal}
        isSubmitting={isSubmitting}
        error={errors.loansInDefaultFederal}
        value={values.loansInDefaultFederal}
        onChange={handleChange}
        onBlur={handleBlur}
        buttonCSS={this.buttonMobileCSS}
        options={[
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
          { value: 'maybe', label: "I'm not sure" },
        ]}
      />
    );
  }
}

class IsCurrentlyEnrolledInIDR extends React.Component {
  componentWillUnmount() {
    this.props.formik.setFieldValue('isCurrentlyEnrolledInIDR', '');
  }

  render() {
    const {
      formik: {
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        isSubmitting,
      },
    } = this.props;

    return (
      <RadioButtons
        row
        design="smallRec"
        name="isCurrentlyEnrolledInIDR"
        touched={touched.isCurrentlyEnrolledInIDR}
        isSubmitting={isSubmitting}
        error={errors.isCurrentlyEnrolledInIDR}
        value={values.isCurrentlyEnrolledInIDR}
        onChange={handleChange}
        onBlur={handleBlur}
        buttonCSS={this.buttonMobileCSS}
        options={[
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
          { value: 'maybe', label: "I'm not sure" },
        ]}
      />
    );
  }
}

class LoansInDefaultPrivate extends React.Component {
  componentWillUnmount() {
    this.props.formik.setFieldValue('loansInDefaultPrivate', '');
  }

  render() {
    const {
      formik: {
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        isSubmitting,
      },
    } = this.props;

    return (
      <RadioButtons
        row
        design="smallRec"
        name="loansInDefaultPrivate"
        touched={touched.loansInDefaultPrivate}
        isSubmitting={isSubmitting}
        error={errors.loansInDefaultPrivate}
        value={values.loansInDefaultPrivate}
        onChange={handleChange}
        onBlur={handleBlur}
        buttonCSS={this.buttonMobileCSS}
        options={[
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
          { value: 'maybe', label: "I'm not sure" },
        ]}
      />
    );
  }
}

class LoanInfo extends React.Component {
  state = { isModalOpen: false };

  componentDidMount() {
    this.props.setPageInfo(2, 'Loan info');
  }

  handleSubmit = values => {
    const data = {
      federalLoansEstimate: values.federalLoansEstimate || 0,
      loansInDefaultFederal: values.loansInDefaultFederal || 'no',
      loansInDefaultPrivate: values.loansInDefaultPrivate || 'no',
      privateLoansEstimate: values.privateLoansEstimate || 0,
      isCurrentlyEnrolledInIDR: values.isCurrentlyEnrolledInIDR || 'no',
    };

    this.props.fillLoanInfo({ ...data, currentStep: '/' });
  };

  renderForm = formik => {
    const {
      values,
      errors,
      handleBlur,
      handleSubmit,
      touched,
      setFieldValue,
      setFieldTouched,
      isSubmitting,
      isValid,
      submitCount,
    } = formik;

    const submitDisabled = !isValid && submitCount > 0;

    return (
      <>
        <Form onSubmit={handleSubmit}>
          <Box
            isErrorDisplayed={errors.federalLoansEstimate}
            id="federalLoansEstimate"
          >
            <LoanType>
              <Icon
                SvgComponent={Bank}
                fill={COLORS.azure}
                width={38}
                height={38}
                style={{ marginRight: '16px' }}
              />
              <Header h2 center>
                Federal Loans
              </Header>
            </LoanType>

            <Label>
              <Header h4>
                Approximately how much do you currently owe in federal loans?
              </Header>
            </Label>
            <SubTextContainer>
              <Text>
                Enter $0 if you don&rsquo;t have any federal student loans.
              </Text>
            </SubTextContainer>
            <MoneyInput
              name="federalLoansEstimate"
              value={values.federalLoansEstimate}
              error={errors.federalLoansEstimate}
              touched={touched.federalLoansEstimate}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              isSubmitting={isSubmitting}
              onBlur={handleBlur}
              defaultValue={0}
            />

            {values.federalLoansEstimate > 0 && (
              <>
                <Label id="loansInDefaultFederal">
                  <Header h4>Are any of your federal loans in default?</Header>
                </Label>
                <SubTextContainer>
                  <Text>
                    Your loan may be in default if your payments have been
                    overdue for nine months or more.
                  </Text>
                </SubTextContainer>
                <OptionsRow>
                  <LoansInDefaultFederal formik={formik} />
                </OptionsRow>
              </>
            )}
            {values.federalLoansEstimate > 0 && (
              <>
                <Label id="isCurrentlyEnrolledInIDR">
                  <Header h4>
                    Are you currently enrolled in an income-driven repayment
                    (IDR) plan?
                  </Header>
                </Label>
                <SubTextContainer>
                  <Text>
                    You would have needed to submit an application to your loan
                    servicer to enroll in an IDR plan.
                  </Text>
                </SubTextContainer>
                <OptionsRow>
                  <IsCurrentlyEnrolledInIDR formik={formik} />
                </OptionsRow>
              </>
            )}
          </Box>
          <Box
            isErrorDisplayed={errors.privateLoansEstimate}
            id="privateLoansEstimate"
          >
            <LoanType>
              <Icon
                SvgComponent={Mask}
                fill={COLORS.azure}
                width={40}
                height={40}
                style={{ marginRight: '16px' }}
              />
              <Header h2 center>
                Private Loans
              </Header>
            </LoanType>

            <Label>
              <Header h4>
                Approximately how much do you currently owe in private loans?
              </Header>
            </Label>
            <SubTextContainer>
              <Text>
                Enter $0 if you don&rsquo;t have any private student loans.
              </Text>
            </SubTextContainer>
            <MoneyInput
              name="privateLoansEstimate"
              value={values.privateLoansEstimate}
              error={errors.privateLoansEstimate}
              touched={touched.privateLoansEstimate}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              isSubmitting={isSubmitting}
              onBlur={handleBlur}
              defaultValue={0}
            />

            {values.privateLoansEstimate > 0 && (
              <>
                <Label id="loansInDefaultPrivate">
                  <Header h4>Are any of your private loans in default?</Header>
                </Label>
                <OptionsRow>
                  <LoansInDefaultPrivate formik={formik} />
                </OptionsRow>
              </>
            )}
          </Box>
          <Button
            type="submit"
            isLoading={this.props.isLoading}
            fakeDisabled={submitDisabled}
            onClick={() => scrollToError(errors)}
          >
            Continue
          </Button>
        </Form>
      </>
    );
  };

  render() {
    return (
      <OnboardingContainer>
        <StepHeader />
        <Header center>Tell us about your loans:</Header>
        <InfoLinkContainer>
          <InfoModal>
            <PopupLink>
              <Link>
                What’s the difference between federal and private loans?
              </Link>
            </PopupLink>
          </InfoModal>
        </InfoLinkContainer>
        <Formik
          initialValues={{
            federalLoansEstimate: this.props.federalLoansEstimate,
            loansInDefaultFederal: this.props.loansInDefaultFederal,
            privateLoansEstimate: this.props.privateLoansEstimate,
            loansInDefaultPrivate: this.props.loansInDefaultPrivate,
            isCurrentlyEnrolledInIDR: this.props.isCurrentlyEnrolledInIDR,
          }}
          validationSchema={loansInfoValidationSchema}
          onSubmit={this.handleSubmit}
        >
          {this.renderForm}
        </Formik>
        <BackLink onClick={() => this.props.goBackOnboarding('questions')}>
          Back to Step 1: Your Situation
        </BackLink>
      </OnboardingContainer>
    );
  }
}

const InfoModal = props => {
  return (
    <ModalPopup
      {...props}
      kind="Loan Types"
      component={
        <>
          <PopupTitle>
            <Header>
              What’s the difference between federal and private loans?
            </Header>
          </PopupTitle>

          <LoanDefinition>
            <Text>
              <Strong>Federal loans</Strong> are given out by the US government.
              These are loans given to you when you fill out the FAFSA and apply
              for financial aid. They have names like Direct, FFEL, PLUS,
              Perkins, and Stafford.
            </Text>
          </LoanDefinition>

          <LoanDefinition>
            <Text>
              <Strong>Private loans</Strong> are given out by private companies
              like banks. Some common private lenders include Citizens Bank,
              College Ave, CommonBond, Sallie Mae, and SoFi.
            </Text>
          </LoanDefinition>
        </>
      }
    />
  );
};

const PopupTitle = styled.div`
  margin-bottom: 16px;
`;

const PopupLink = styled.div`
  margin-top: 16px;
`;

const Label = styled.div`
  width: 400px;
  margin-bottom: 16px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const SubTextContainer = styled.div`
  margin-bottom: 16px;
`;

const LoanDefinition = styled.div`
  margin-top: 32px;
`;

const LoanType = styled(FlexCenter)`
  margin-bottom: 32px;
`;

const OptionsRow = styled.div`
  & > div > div {
    width: auto;
  }
`;

const Box = styled.div`
  border-radius: 3px;
  border: 1px solid ${COLORS.grey};
  background-color: ${COLORS.lightestGrey};
  max-width: 480px;
  width: 100%;
  margin-bottom: 32px;
  padding: 32px 32px
    ${({ isErrorDisplayed }) => (isErrorDisplayed ? `16px` : `0`)};
`;

const InfoLinkContainer = styled.div`
  margin-bottom: 48px;
`;

const mapStateToProps = state => ({
  isLoading: isLoading(state, 'survey'),
  calcsLoading: isLoading(state, 'calculating-recommendations'),
  federalLoansEstimate: getUserFederalLoansEstimate(state),
  loansInDefaultFederal: getLoansInDefaultFederalOnboardingQuestion(state),
  privateLoansEstimate: getUserPrivateLoansEstimate(state),
  loansInDefaultPrivate: getLoansInDefaultPrivateOnboardingQuestion(state),
  isCurrentlyEnrolledInIDR: getIdrOnboardingQuestion(state),
});

export default connect(mapStateToProps, {
  setPageInfo,
  goBackOnboarding,
  fillLoanInfo,
})(LoanInfo);
