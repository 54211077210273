import React from 'react';

import { TextInput } from '@summer/ui-components';

import { InputWrapper } from 'components/pages/idr/wizard/steps/Submit/shared/Inputs';

const FormerName = ({
  formik: { values, errors, handleChange, handleBlur, touched, isSubmitting },
}) => (
  <InputWrapper>
    <TextInput
      label="Former Name/s if any"
      name="submit.formerName"
      touched={touched.formerName}
      isSubmitting={isSubmitting}
      error={errors.formerName}
      value={values.formerName}
      onChange={handleChange}
      onBlur={handleBlur}
      helpMessage="Optional"
      placeholder="Former Name/s"
    />
  </InputWrapper>
);

export default FormerName;
