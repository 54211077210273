import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { FlexCenter, Header, Text } from '@summer/ui-components';

import SecurityFooter from 'components/pages/onboarding/shared/SecurityFooter';
import SecurityHeader from 'components/pages/onboarding/shared/SecurityHeader';
import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';
import { useIsPageLoading } from 'hooks/common';
import { isLoading } from 'redux/selectors/ui.selectors';
import { getUserIsVerified } from 'redux/selectors/user.selectors';

const BasePage = ({ children }) => (
  <>
    <Container>
      <SecurityHeader />
      {children}
    </Container>
    <SecurityFooter />
  </>
);

const ADPEmailVerificationLandingPage = ({
  isLoadingEmailVerification,
  isVerified,
}) => {
  const { isLoading } = useIsPageLoading(isLoadingEmailVerification);

  if (isLoading) {
    return <BasePage>Loading</BasePage>;
  }

  if (isVerified) {
    return (
      <BasePage>
        <HeaderBox>
          <Header center>Email verification successful</Header>
        </HeaderBox>
        <Box>
          <Text large paragraph>
            We were able to verify you email!
          </Text>
          <Text large paragraph>
            Please return to the browser where you are logged into myADP to
            continue.
          </Text>
          <Text large paragraph>
            If you&rsquo;re having trouble, contact{' '}
            <Link href={`mailto:${supportAddress}`} inheritColor underline>
              {supportAddress}
            </Link>
            .
          </Text>
        </Box>
      </BasePage>
    );
  }

  return <BasePage>Failed to verify your email</BasePage>;
};

const Box = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Container = styled(FlexCenter)`
  max-width: 408px;
  width: 100%;

  padding: 0 24px 72px;

  flex-shrink: 0;
  flex-direction: column;
`;

const HeaderBox = styled.div`
  margin-bottom: 24px;
`;

const mapStateToProps = state => ({
  isLoadingEmailVerification: isLoading(state, 'verify-user'),
  isVerified: getUserIsVerified(state),
});

export default connect(mapStateToProps)(ADPEmailVerificationLandingPage);
