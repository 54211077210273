import { includes, keys, pick, pickBy } from 'lodash';

import { initialState as financialProfileInitialState } from 'redux/reducers/financialProfile.reducer';
import { initialState as profileInitialState } from 'redux/reducers/profile.reducer';

export const pickProfile = values => {
  const profileFields = keys(profileInitialState);
  return pickBy(values, (value, key) => includes(profileFields, key) && value);
};

export const pickFinancialProfile = values => {
  const financialProfileFields = keys(financialProfileInitialState);
  return pick(values, financialProfileFields);
};
