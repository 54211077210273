import { push as redirectTo } from 'connected-react-router';
import { isNil } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { Text } from '@summer/ui-components';

import { postOAuthCode } from 'redux/actions/oauth.actions';

class OAuthCallback extends React.Component {
  componentDidMount() {
    const { location, match, postOAuthCode, redirectTo } = this.props;
    const { search } = location;
    const { provider } = match.params;

    const searchParams = new URLSearchParams(search);

    if (provider === 'creditkarma' && isNil(searchParams.get('state'))) {
      redirectTo('/onboard/creditkarma/oauth');
    } else {
      postOAuthCode(provider, search);
    }
  }

  render() {
    return <Text>Authenticating</Text>;
  }
}

export default connect(null, {
  postOAuthCode,
  redirectTo,
})(OAuthCallback);
