import React, { Element } from 'react';
import PropTypes from 'prop-types';
import * as ReactDOM from 'react-dom';
import styled, { css, keyframes } from 'styled-components';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { MobileSize } from '../../helpers/constants/styleguide';
import { hexToRgba } from '../../helpers/utils/common';

const createPortalContainer = () => {
  const container = document.createElement('div');
  document.body.appendChild(container);
  return container;
};

const removePortalContainer = container => {
  if (document.body.contains(container)) {
    document.body.removeChild(container);
  }
};

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.contentRef = React.createRef();
    this.state = {
      realHeight: window.innerHeight,
    };
  }

  componentWillUnmount() {
    removePortalContainer(this.container);
    this.toggleOffIosHeightFix();
    clearAllBodyScrollLocks();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      if (this.props.track) {
        this.props.track('[UI] opened modal', {
          modal: this.props.kind,
        });
      }

      disableBodyScroll(this.props.scrollElementRef?.current);

      // Make main modal to focus so we can close it with esc key
      if (this.contentRef.current) {
        this.contentRef.current.focus();
      }
    }
    if (prevProps.isOpen && !this.props.isOpen) {
      removePortalContainer(this.container);
      clearAllBodyScrollLocks();
      this.toggleOffIosHeightFix();
      this.container = null;
    }
  }

  closeOnEscape = event => {
    if (event.keyCode === 27) {
      this.toggleModal();
    }
  };

  updateRealHeight = () => {
    this.setState(() => ({
      realHeight: window.innerHeight,
    }));
  };

  toggleOnIosHeightFix = () => {
    window.addEventListener('resize', this.updateRealHeight);
  };

  toggleOffIosHeightFix = () => {
    window.removeEventListener('resize', this.updateRealHeight);
  };

  toggleModal = () => {
    if (typeof this.props.toggleModal === 'function') {
      this.props.toggleModal();
    }
  };

  renderModal = () => {
    if (!(this.container instanceof HTMLElement)) {
      this.container = createPortalContainer();
      this.toggleOnIosHeightFix();
      this.container.style.top = `${window.scrollY}px`;
    }
    return ReactDOM.createPortal(
      <Overlay
        onClick={() => {
          this.toggleModal();
        }}
        realHeight={this.state.realHeight}
        backgroundColor={this.props.backgroundColor}
        backgroundOpacity={this.props.backgroundOpacity}
      >
        <ContentContainer
          ref={this.contentRef}
          tabIndex="-1"
          onKeyDown={this.closeOnEscape}
          onClick={e => {
            e.stopPropagation();
          }}
          contentHeight={this.props.contentHeight}
        >
          {this.props.children}
        </ContentContainer>
      </Overlay>,
      this.container,
    );
  };

  render() {
    return this.props.isOpen && this.renderModal();
  }
}

const fadeInUp = keyframes`
 from {
   opacity: 0;
 }

 to {
   opacity: 1;
 }
`;

const fadeInUpAnimation = css`
  ${fadeInUp} 0.2s;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  height: ${({ contentHeight }) => contentHeight || 'auto'};

  @media (max-width: ${MobileSize}) {
    height: ${({ contentHeight }) => contentHeight || 'inherit'};
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: ${({ realHeight }) => realHeight}px;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor, backgroundOpacity }) =>
    hexToRgba(backgroundColor, backgroundOpacity)};
  animation: ${fadeInUpAnimation};
  z-index: 100;
`;

Modal.defaultProps = {
  isOpen: false,
  backgroundColor: '000000',
  backgroundOpacity: 0.5,
};

Modal.propTypes = {
  kind: PropTypes.string,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  contentHeight: PropTypes.string,
  children: PropTypes.node,
  scrollElementRef: PropTypes.oneOfType([
    PropTypes.shape({
      current: PropTypes.instanceOf(HTMLElement),
    }),
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]),
  track: PropTypes.func,
  backgroundColor: PropTypes.string,
  backgroundOpacity: PropTypes.number,
};

export default Modal;
