import React from 'react';
import styled from 'styled-components';

import { DateInput } from '@summer/ui-components';

import { Row, Spacer } from 'components/pages/idr/wizard/shared/styles';

const SpouseDateOfBirth = ({
  formik: { values, errors, setFieldValue, handleBlur, touched, isSubmitting },
}) => (
  <Row>
    <DOB>
      <DateInput
        label="Date of Birth"
        name="submit.spouseDob"
        touched={touched.spouseDob}
        isSubmitting={isSubmitting}
        error={errors.spouseDob}
        value={values.spouseDob}
        onChange={setFieldValue}
        onBlur={handleBlur}
      />
    </DOB>
    <Spacer />
  </Row>
);

const DOB = styled.div`
  flex: 1;
`;

export default SpouseDateOfBirth;
