import { debounce } from 'lodash';
import { useEffect, useState, useCallback } from 'react';

import { MobileSize, SmallDesktopSize, TabletSize } from 'constants/styleguide';

export const MOBILE = 'mobile';
export const TABLET = 'tablet';
export const SMALL_DESKTOP = 'small desktop';
export const DESKTOP = 'desktop';

export const usePlatform = () => {
  const [platformType, setPlatformType] = useState(DESKTOP);
  const [windowWidth, setWindowWidth] = useState(null);

  const updatePlatformType = () => {
    const windowWidth = window.innerWidth;
    let platformType;

    switch (true) {
      case parseInt(MobileSize, 10) >= windowWidth:
        platformType = MOBILE;
        break;
      case parseInt(TabletSize, 10) >= windowWidth:
        platformType = TABLET;
        break;
      case parseInt(SmallDesktopSize, 10) >= windowWidth:
        platformType = SMALL_DESKTOP;
        break;
      default:
        platformType = DESKTOP;
        break;
    }
    setPlatformType(platformType);
  };

  const updateWindowWidth = useCallback(() => {
    updatePlatformType();
    setWindowWidth(window.innerWidth);
  }, []);

  const debounceUpdateWindowWidth = debounce(updateWindowWidth, 50);

  useEffect(
    function addResizeEventListenerOnMount() {
      updateWindowWidth();
      window.addEventListener('resize', debounceUpdateWindowWidth);

      return () => {
        debounceUpdateWindowWidth.cancel();
        window.removeEventListener('resize', debounceUpdateWindowWidth);
      };
    },
    [debounceUpdateWindowWidth, updateWindowWidth],
  );

  const isMobile = platformType === MOBILE;
  const isTablet = platformType === TABLET;
  const isSmallDesktop = platformType === SMALL_DESKTOP;
  const isDesktop = platformType === DESKTOP;

  return {
    platformType,
    windowWidth,
    isMobile,
    isTablet,
    isSmallDesktop,
    isDesktop,
  };
};
