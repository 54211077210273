import { SET_SLC_USER } from 'constants/actionTypes';

const initialState = {};

const slcReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SLC_USER: {
      return { ...state, ...payload };
    }

    default:
      return state;
  }
};

export default slcReducer;
