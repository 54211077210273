import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { hexToRgba } from '../../../helpers/utils/common';

import { COLORS } from '../../../helpers/constants/styleguide';

const Highlight = ({ children }) => {
  return (
    <Container>
      <Inner>{children}</Inner>
    </Container>
  );
};

Highlight.propTypes = { children: PropTypes.node };

const highlightColor = hexToRgba(COLORS.yellow, '0.7');

const Container = styled.span`
  background-image: linear-gradient(0deg, ${highlightColor}, ${highlightColor});
  background-repeat: no-repeat;
  background-size: 100% 40%;
  background-position-y: 75%;

  transform: skew(-5deg);

  font-size: inherit;
`;

const Inner = styled.span`
  transform: skew(5deg);
  font-size: inherit;
`;

export default memo(Highlight);
export const StorybookHighlight = Highlight;
