import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  FlexCenter,
  FlexRow,
  Header,
  Icon,
  PasswordResetKey,
  TextInput,
  Button,
  COLORS,
  Text,
} from '@summer/ui-components';

import GlobalError from 'components/shared/GlobalError';
import { Link } from 'components/shared/typography';
import { clearServerError } from 'redux/actions/ui.actions';
import { forgot } from 'redux/actions/user.actions';
import { getErrorMessage, isLoading } from 'redux/selectors/ui.selectors';
import { forgotPasswordValidationSchema } from 'schemas/onboard';

class ForgotPassword extends React.Component {
  constructor() {
    super();

    this.formInitialValues = {
      email: '',
    };

    this.state = {
      submitted: false,
    };
  }

  handleSubmit = values => {
    const { email } = values;
    this.props.clearServerError();

    this.props.forgot({
      email,
    });

    this.setState({
      submitted: true,
    });
  };

  renderForm = formik => {
    const {
      values,
      errors,
      handleChange,
      handleSubmit,
      touched,
      isSubmitting,
      isValid,
    } = formik;

    const { error } = this.props;
    const { submitted } = this.state;

    return (
      <Form onSubmit={handleSubmit}>
        <TextInput
          label="Email"
          name="email"
          small
          touched={touched.email}
          isSubmitting={isSubmitting}
          error={errors.email}
          value={values.email}
          onChange={handleChange}
          disabled={error && submitted}
          inputMode="email"
        />
        <GlobalError label="forgot" />
        {!error && submitted && (
          <SubmitTextContainer>
            <Text small paragraph color="secondary">
              An email has been sent. Please check your inbox.
            </Text>
          </SubmitTextContainer>
        )}
        <Box>
          <Button
            disabled={!isValid || (error && submitted)}
            isLoading={this.props.isLoading}
            width={360}
            type="submit"
          >
            Send Password Reset Email
          </Button>
        </Box>
      </Form>
    );
  };

  render() {
    return (
      <Container>
        <StyledIcon SvgComponent={PasswordResetKey} stroke={COLORS.azure} />
        <Title>
          <Header center h4>
            Reset Your Password
          </Header>
        </Title>

        <Formik
          initialValues={this.formInitialValues}
          validationSchema={forgotPasswordValidationSchema}
          onSubmit={this.handleSubmit}
        >
          {formik => this.renderForm(formik)}
        </Formik>

        <LinkContainer>
          <Link to="/login"> Return to login</Link>
        </LinkContainer>
      </Container>
    );
  }
}

const Container = styled(FlexCenter)`
  width: 360px;
  flex-shrink: 0;
  flex-direction: column;
`;

const StyledIcon = styled(Icon)`
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
`;

const Title = styled.div`
  margin-bottom: 20px;
`;

const Form = styled(FlexRow.withComponent('form'))`
  flex-wrap: wrap;
`;

const Box = styled.div`
  width: 100%;
`;

const LinkContainer = styled.div`
  margin: 16px;
`;

const SubmitTextContainer = styled.div`
  margin: 0 auto;
`;

const mapStateToProps = state => ({
  isLoading: isLoading(state, 'forgot'),
  error: getErrorMessage(state, 'forgot'),
});

export default connect(mapStateToProps, { forgot, clearServerError })(
  ForgotPassword,
);
