import { push as redirect } from 'connected-react-router';
import { isNil } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { FlexCenter, Header, Text, Button } from '@summer/ui-components';

import SecurityFooter from 'components/pages/onboarding/shared/SecurityFooter';
import SecurityHeader from 'components/pages/onboarding/shared/SecurityHeader';
import { Link } from 'components/shared/typography';
import { logout } from 'redux/actions/user.actions';
import { isLoading } from 'redux/selectors/ui.selectors';

const partnerLogoutConfig = {
  adp: {
    signoutName: 'ADP',
    returnLink: '',
  },
};

export const PartnerLogout = props => {
  const { partner } = props.match.params;
  const logoutConfig = partnerLogoutConfig[partner];

  if (isNil(logoutConfig)) {
    window.location.href = '/';
    return null;
  }

  const { signoutName, returnLink } = logoutConfig;

  return (
    <>
      <Container>
        <SecurityHeader />
        <HeaderBox>
          <Header center>You have been securely signed out</Header>
        </HeaderBox>
        <Box>
          <Text large paragraph align="center" style={{ paddingBottom: 10 }}>
            This may have ocurred if your session expired. Please sign into
            Summer again through {signoutName}.
          </Text>
        </Box>
        {returnLink && (
          <StyledLink
            href={returnLink}
            inheritColor={true}
            inheritWeight={true}
            target="_self"
          >
            <Button isLoading={props.isLoading} width={360} type="button">
              Back to {signoutName}
            </Button>
          </StyledLink>
        )}
      </Container>
      <SecurityFooter />
    </>
  );
};

const StyledLink = styled(Link)`
  width: 100%;
`;

const Box = styled.div`
  margin-bottom: 16px;
`;

const Container = styled(FlexCenter)`
  max-width: 408px;
  width: 100%;

  padding: 0 24px 72px;

  flex-shrink: 0;
  flex-direction: column;
`;

const HeaderBox = styled.div`
  margin-bottom: 24px;
`;

const mapStateToProps = state => ({
  isLoading: isLoading(state, 'logout'),
});

export default connect(mapStateToProps, { logout, redirect })(PartnerLogout);
