import { useFormikContext } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Text } from '@summer/ui-components/src';

import FormDrawersContainer from 'components/common/FormDrawersContainer';
import Content from 'components/layout/wizard/WizardContent';
import Footer from 'components/layout/wizard/WizardFooter';
import { Row } from 'components/pages/idr/wizard/shared/styles';
import { InputWrapper } from 'components/pages/idr/wizard/steps/Submit/shared/Inputs';
import { TabletSize } from 'constants/styleguide';
import {
  getTuitionReimbursementCompletionProof,
  getTuitionReimbursementPaymentProof,
} from 'redux/selectors/fileCollections.selectors';

import { InfoContainer, InstructionsContainer } from '../../shared';
import TuitionFileUpload from '../../shared/TuitionFileUpload';

const ProofOfCompletionForm = ({
  paymentProof,
  completionProof,
  formik: { values, setFieldValue, setFieldTouched },
}) => {
  return (
    <div>
      <Row>
        <InputWrapper>
          <Label bold>Proof of Payment</Label>
          <InstructionsContainer>
            <Text paragraph>
              Upload your itemized receipt of payment. Ensure the date of
              payment and vendor name are included.
              <br />
              File formats accepted: PDF, PNG, JPG, or JPEG.
            </Text>
          </InstructionsContainer>
          <UploadContainer>
            <TuitionFileUpload
              fileCollection={paymentProof}
              collection="tuitionReimbursementPaymentProof"
              name="paymentProofFiles"
              value={values.paymentProofFiles}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </UploadContainer>
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <Label bold>Proof of Completion</Label>
          <InstructionsContainer>
            <Text paragraph>
              Upload your proof of completion for this course. For example, your
              transcript showing your grade for the course or a certificate of
              completion.
              <br />
              File formats accepted: PDF, PNG, JPG, or JPEG.
            </Text>
          </InstructionsContainer>
          <UploadContainer>
            <TuitionFileUpload
              fileCollection={completionProof}
              collection="tuitionReimbursementCompletionProof"
              name="completionProofFiles"
              value={values.completionProofFiles}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </UploadContainer>
        </InputWrapper>
      </Row>
    </div>
  );
};

const ProofOfCompletion = ({
  tuitionReimbursementPaymentProof,
  tuitionReimbursementCompletionProof,
}) => {
  const formik = useFormikContext();
  const { handleSubmit, isValid, isSubmitting } = formik;

  return (
    <Content>
      <form>
        <FormDrawersContainer>
          <FormContainer>
            <ProofOfCompletionForm
              paymentProof={tuitionReimbursementPaymentProof}
              completionProof={tuitionReimbursementCompletionProof}
              formik={formik}
            />
          </FormContainer>
        </FormDrawersContainer>
      </form>
      <Footer
        useSubmitLabel
        isThisLastStep
        isNextFakeDisabled={!isValid}
        isNextLoading={isSubmitting}
        isBackDisabled={isSubmitting}
        isValid={isValid}
        handleSaveContinue={handleSubmit}
      />
    </Content>
  );
};

const Label = styled(Text)`
  font-size: 18px;
`;

const UploadContainer = styled.div`
  margin-top: 16px;
`;

const FormContainer = styled(InfoContainer)`
  @media (min-width: ${TabletSize}) {
    padding-left: 136px;
    padding-right: 136px;
  }
`;

const mapStateToProps = state => ({
  tuitionReimbursementPaymentProof: getTuitionReimbursementPaymentProof(state),
  tuitionReimbursementCompletionProof:
    getTuitionReimbursementCompletionProof(state),
});

export default connect(mapStateToProps)(ProofOfCompletion);
