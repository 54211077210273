import React from 'react';

import { Text } from '@summer/ui-components';

import { Link } from 'components/shared/typography';

export const idrFaqConfig = {
  recommended: {
    title: 'Why should I consider IDR?',
    content: (
      <Text paragraph>
        <Link
          underline
          inheritColor
          to={{ pathname: '/guides/idr', state: { goBack: true } }}
        >
          IDR
        </Link>{' '}
        could help you pay less each month based on your income this year.
      </Text>
    ),
  },
  forgiveness: {
    title: 'Can I get forgiveness with an IDR plan?',
    content: (
      <>
        <Text paragraph>
          You may be eligible for forgiveness, depending on your income, program
          requirements and annual recertification. After 20 or 25 years on an
          IDR plan (the timeline varies by plan), the remaining balance on your
          loans will be forgiven if you’ve made qualifying payments and are not
          in default. The amount that&rsquo;s forgiven may be taxed as income.
        </Text>
      </>
    ),
  },
  whatElse: {
    title: 'What else should I know about IDR?',
    content: (
      <Text paragraph>
        Your payments could adjust each year as your income changes (up or down)
        and you&rsquo;ll need to submit new proof of your income once a year to
        stay in the program. Your remaining loan balance can be forgiven in
        20-25 years or less, and you can switch out of an IDR plan whenever you
        want to.
      </Text>
    ),
  },
  alreadyEnrolled: {
    title: 'What if I’m already enrolled in an IDR plan?',
    content: (
      <>
        <Text paragraph>
          Summer can still help! Use Summer to see if there’s a better IDR plan
          for you. If you decide to submit an updated application, Summer will
          submit it to your loan servicer indicating that you’d like to change
          plans.
        </Text>
      </>
    ),
  },
  inDefault: {
    title: 'My loans are in default. Can I still enroll?',
    content: (
      <Text paragraph>
        You are not eligible for income-driven repayment plans while your loans
        are in default. This may be the case if you have not made a payment on
        your loans in 9 months.
      </Text>
    ),
  },
  paymentUpdate: {
    title: 'When does my monthly payment update?',
    content: (
      <Text paragraph>
        Your monthly payment will update once your loan servicer processes and
        accepts your application. This can take up to four to eight weeks.
      </Text>
    ),
  },
  afterFormSubmit: {
    title: 'What can I expect after I submit a form?',
    content: (
      <Text paragraph>
        Our team of experts will review your application and submit it to your
        loan servicer for you. We’ll let you know when we do! Servicers can take
        up to four to eight weeks to process an application once they receive
        it. You’ll hear from them directly with an update. If you don’t, check
        in to make sure they received it!
      </Text>
    ),
  },
};
