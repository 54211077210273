import React from 'react';

import { Header, Text } from '@summer/ui-components';

import { Link } from 'components/shared/typography';

// temp component for development
export const Placeholder = ({ navigation, title }) => (
  <div>
    <Header center h3>
      {title} placeholder
    </Header>
    <Text center>
      <Link onClick={navigation.back}>Back</Link> |{' '}
      <Link onClick={navigation.next}>Next</Link>
    </Text>
  </div>
);
