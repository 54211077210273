import { createSelector } from 'reselect';

import { getCurrentIdrFormStep, blockUserOnSubmitStep } from './idr.selectors';
import { getIsPaywallEnabled } from './user.selectors';

const getHasPaymentSaved = state => state.currentUser.hasPaymentSaved;

export const showPaywall = createSelector(
  getIsPaywallEnabled,
  getHasPaymentSaved,
  (paywallIsEnabled, hasPaymentSaved) => {
    return paywallIsEnabled && !hasPaymentSaved;
  },
);

export const showPaywallIdr = createSelector(
  blockUserOnSubmitStep,
  showPaywall,
  getCurrentIdrFormStep,
  (blockUserOnSubmitStep, showPaywallFlag, currentStep) => {
    return showPaywallFlag && currentStep > 3 && !blockUserOnSubmitStep;
  },
);
