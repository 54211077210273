import {
  CMS_FAILURE,
  CMS_FETCH_DOC_BY_UID,
  CMS_FETCH_DOCS_BY_TYPE,
  CMS_SUCCESS,
} from 'constants/actionTypes';

export const fetchDocsByType = ({
  type,
  page,
  pageSize,
  fromAction,
  label,
  meta,
}) => ({
  type: CMS_FETCH_DOCS_BY_TYPE,
  payload: { type, page, pageSize },
  meta: {
    fromAction,
    label,
    ...meta,
  },
});

export const fetchDocByUid = ({ type, uid, fromAction, label, meta }) => ({
  type: CMS_FETCH_DOC_BY_UID,
  payload: { uid, type },
  meta: {
    fromAction,
    label,
    ...meta,
  },
});

export const cmsSuccess = ({ response, meta }) => ({
  type: CMS_SUCCESS,
  payload: {
    response,
  },
  meta: {
    ...meta,
  },
});

export const cmsFailure = ({ error, meta }) => ({
  type: CMS_FAILURE,
  payload: {
    error,
  },
  meta: {
    ...meta,
  },
});
