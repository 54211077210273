import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Header,
  Text,
  Button,
  AirBalloon,
  Icon,
  FlexRow,
  FlexCenter,
  TextInput,
  COLORS,
} from '@summer/ui-components';

import GlobalError from 'components/shared/GlobalError';
import { clearServerError } from 'redux/actions/ui.actions';
import { sendAccountVerificationEmail } from 'redux/actions/user.actions';
import { getErrorMessage, isLoading } from 'redux/selectors/ui.selectors';
import { verifyAccountValidationSchema } from 'schemas/onboard';

class VerifyAccount extends React.Component {
  constructor() {
    super();

    this.formInitialValues = {
      email: '',
    };

    this.state = {
      submitted: false,
    };
  }

  handleSubmit = ({ email }) => {
    this.props.clearServerError();
    this.props.sendAccountVerificationEmail({ email });

    this.setState({
      submitted: true,
    });
  };

  renderForm = formik => {
    const {
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      touched,
      isSubmitting,
      isValid,
    } = formik;

    const { error } = this.props;
    const { submitted } = this.state;

    return (
      <Form onSubmit={handleSubmit}>
        <TextInput
          label="Email"
          name="email"
          touched={touched.email}
          isSubmitting={isSubmitting}
          error={errors.email}
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={error && submitted}
          inputMode="email"
        />
        <GlobalError label="sendAccountVerificationEmail" />
        {!error && submitted && (
          <SubmitTextContainer>
            <Text small paragraph color="secondary">
              An email has been sent. Please check your inbox.
            </Text>
          </SubmitTextContainer>
        )}
        <Button
          disabled={!isValid || (error && submitted)}
          isLoading={this.props.isLoading}
          type="submit"
        >
          Send Account Verification Email
        </Button>
      </Form>
    );
  };

  render() {
    return (
      <Container>
        <StyledIcon
          SvgComponent={AirBalloon}
          stroke={COLORS.azure}
          width={34}
          height={49}
        />
        <Title>
          <Header h4 center>
            Please Verify Your Email
          </Header>
        </Title>
        <TextContainer>
          <Text justify>
            It looks like you never verified your email address and now your
            account has been locked out. Can you make sure you verify your email
            now?
          </Text>
        </TextContainer>
        <Formik
          initialValues={this.formInitialValues}
          onSubmit={this.handleSubmit}
          validationSchema={verifyAccountValidationSchema}
        >
          {formik => this.renderForm(formik)}
        </Formik>
      </Container>
    );
  }
}

const TextContainer = styled.div`
  position: relative;
  margin: 20px 0;
  width: 360px;
  flex-shrink: 0;
`;

const Container = styled(FlexCenter)`
  width: 360px;
  flex-shrink: 0;
  flex-direction: column;
`;

const StyledIcon = styled(Icon)`
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
`;

const SubmitTextContainer = styled.div`
  margin: 0 auto;
`;

const Title = styled.div`
  margin-bottom: 20px;
`;

const Form = styled(FlexRow.withComponent('form'))`
  flex-wrap: wrap;
`;

const mapStateToProps = state => ({
  isLoading: isLoading(state, 'sendAccountVerificationEmail'),
  error: getErrorMessage(state, 'sendAccountVerificationEmail'),
});

export default connect(mapStateToProps, {
  sendAccountVerificationEmail,
  clearServerError,
})(VerifyAccount);
