import { camelCase, toUpper, map, isEmpty } from 'lodash';

export const preventWidow = string => {
  if (!string) {
    return '';
  }
  const stringArray = string.split('');
  const indexOfLastSpace = stringArray.lastIndexOf(' ');
  if (indexOfLastSpace >= 0) {
    stringArray[indexOfLastSpace] = '\xa0';
  }
  return stringArray.join('');
};

export const disallowBreaks = inputString => {
  /*
   * This function takes a string and replaces all
   * regular spaces with non-breaking spaces.
   *
   * @param {string}
   * @returns {string}
   * @example
   * disallowBreaks('hello how are you')
   * // returns 'hello\xa0how\xa0are\xa0you'
   */
  if (!inputString) {
    return '';
  }
  const stringArray = map(inputString.split(''), char =>
    char === ' ' ? '\xa0' : char,
  );
  return stringArray.join('');
};

export const oxfordComma = array => {
  /*
   * @param {string[]}
   * @returns {string}
   * @example
   * // returns 'string1, string2, and string3'
   * oxfordComma ['string1', 'string2', 'string3']
   */
  if (isEmpty(array)) {
    return '';
  }
  if (array.length === 1) {
    return array[0];
  }
  if (array.length === 2) {
    return array.join(' and ');
  }

  return `${array.slice(0, array.length - 1).join(', ')}, and ${
    array[array.length - 1]
  }`;
};

export const camelWithPrefix = (prefix, string) =>
  camelCase(`${prefix} ${string}`);

export const isUpperCase = inputString => inputString === toUpper(inputString);
