import { push as redirectTo } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Header, Button, Text } from '@summer/ui-components/src';

import { MobileSize } from 'constants/styleguide';

const NoTransactionsScreen = () => {
  const dispatch = useDispatch();

  return (
    <LoadingPageWrapper>
      <LoadingPageHeader h2>
        This is taking longer than expected. Don&rsquo;t worry, we have a plan!
      </LoadingPageHeader>
      <List>
        <li>
          <Text>
            We will keep trying to pull the data but instead of making you wait,
            we will email you once we have it so you can confirm.
          </Text>
        </li>
        <li>
          <Text>
            If we are unable to find the data, we will email you with an
            alternative way to share your transactions and get your deserved
            retirement match.
          </Text>
        </li>
      </List>
      <ButtonContainer>
        <Button
          type="button"
          secondary
          onClick={() => {
            dispatch(redirectTo('/plan'));
          }}
        >
          Explore other benefits on Summer
        </Button>
      </ButtonContainer>
    </LoadingPageWrapper>
  );
};

const LoadingPageHeader = styled(Header)`
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (max-width: ${MobileSize}) {
    margin-top: 32px;
  }
`;

const LoadingPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 650px;
  width: 100%;
  font-weight: 600;
  padding-top: 72px;

  @media (max-width: ${MobileSize}) {
    padding-top: 15%;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const List = styled.ul`
  max-width: 570px;
  margin-bottom: 16px;
  & li {
    margin-bottom: 12px;
  }

  @media (max-width: ${MobileSize}) {
    margin-left: 32px;
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  text-align: right;
  margin-right: 10px;
  padding-bottom: 50px;

  @media (max-width: ${MobileSize}) {
    padding-top: 30px;
    margin: 0 30px 0 30px;
  }
`;

export default NoTransactionsScreen;
