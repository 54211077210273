import React from 'react';

import { Header } from '@summer/ui-components/src';

import { arrayToOptions } from 'utils/toOptions';

import { RadioButtonsCSP } from '../shared';

import EnterExpectedAmount from './EnterExpectedAmount';
import EstimateByIncome from './EstimateByIncome';

export const expectedAidMethods = {
  'Enter based on my household income': EstimateByIncome,
  'Enter expected amount manually': EnterExpectedAmount,
};

const ExpectedAid = ({ formik }) => {
  const selectedMethod = formik.values.expectedAidMethod;

  const ExpectedAidComponent = expectedAidMethods[selectedMethod];

  return (
    <>
      <Header h3>Expected Financial Aid and Scholarships</Header>
      <RadioButtonsCSP
        name="expectedAidMethod"
        options={arrayToOptions(Object.keys(expectedAidMethods))}
        formik={formik}
        defaultCol={2}
      />
      {selectedMethod && <ExpectedAidComponent formik={formik} />}
    </>
  );
};

export default ExpectedAid;
