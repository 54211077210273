import React from 'react';
import styled from 'styled-components';

import { Header, Text, FlexColumn } from '@summer/ui-components';

import { Row } from 'components/pages/idr/wizard/shared/styles';
import EmployerNameAndWebsite from 'components/pages/pslf/wizard/steps/submit/EmployerInformation/fields/EmployerNameAndWebsite';
import HrEmail from 'components/pages/pslf/wizard/steps/submit/EmployerInformation/fields/HrEmail';
import HrName from 'components/pages/pslf/wizard/steps/submit/EmployerInformation/fields/HrName';
import { useFormDrawerFocus } from 'components/shared/FormDrawers';

const EmployerInfo = ({ formik, show }) => {
  const formRef = React.useRef(null);

  useFormDrawerFocus(formRef, show);

  return (
    <FlexColumn ref={formRef}>
      <EmployerNameAndWebsite formik={formik} />
      <InfoText>
        <Header h3>
          Please provide a contact at your employer who will sign your PSLF
          Certification form
        </Header>
        <Text paragraph>
          This may be someone in Operations or Human Resources, or your manager.
          It’s worth confirming the right person as we’ll be emailing the
          certification for them to verify and sign.
        </Text>
      </InfoText>
      <HrName formik={formik} />
      <Row>
        <HrEmail formik={formik} />
        <Spacer />
      </Row>
    </FlexColumn>
  );
};

const Spacer = styled.div`
  width: 100%;
`;

const InfoText = styled.div`
  margin: 10px 0 30px 0;

  h3 {
    margin-bottom: 20px;
  }
`;

export default EmployerInfo;
