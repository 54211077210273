import React from 'react';
import { debounce } from 'lodash';
import {
  MobileSize,
  SmallDesktopSize,
  TabletSize,
} from '../constants/styleguide';

export const MOBILE = 'mobile';
export const TABLET = 'tablet';
export const SMALL_DESKTOP = 'small desktop';
export const DESKTOP = 'desktop';

const Platform = WrappedComponent => {
  class Base extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        platformType: DESKTOP,
        screenWidth: null,
      };
      this.debounceUpdateWindowWidth = debounce(this.updateWindowWidth, 50);
    }

    componentDidMount() {
      this.updateWindowWidth();
      window.addEventListener('resize', this.debounceUpdateWindowWidth);
    }

    componentWillUnmount() {
      this.debounceUpdateWindowWidth.cancel();
      window.removeEventListener('resize', this.debounceUpdateWindowWidth);
    }

    updateWindowWidth = () => {
      this.updatePlatformType();
      this.setState({ windowWidth: window.innerWidth });
    };

    updatePlatformType = () => {
      const windowWidth = window.innerWidth;
      let platformType;

      switch (true) {
        case parseInt(MobileSize, 10) >= windowWidth:
          platformType = MOBILE;
          break;
        case parseInt(TabletSize, 10) >= windowWidth:
          platformType = TABLET;
          break;
        case parseInt(SmallDesktopSize, 10) >= windowWidth:
          platformType = SMALL_DESKTOP;
          break;
        default:
          platformType = DESKTOP;
          break;
      }
      this.setState({ platformType });
    };

    render() {
      const { platformType, screenWidth, windowWidth } = this.state;
      return (
        <WrappedComponent
          screenWidth={screenWidth}
          windowWidth={windowWidth}
          platformType={platformType}
          {...this.props}
        />
      );
    }
  }

  Base.displayName = WrappedComponent.displayName;
  Base.propTypes = WrappedComponent.propTypes;
  Base.defaultProps = WrappedComponent.defaultProps;

  return Base;
};

export default Platform;
