import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Container as GridContainer,
  ResponsiveColumn,
  Row,
} from '@summer/ui-components';

import CreateNewFormCTA from 'components/pages/idr/dashboard/CompletedView/CreateNewFormCTA';
import MostRecentApplication from 'components/pages/idr/dashboard/CompletedView/MostRecentApplication';
import PslfBanner from 'components/pages/idr/dashboard/CompletedView/PslfBanner';
import FAQ from 'components/pages/idr/dashboard/CompletedView/faq';
import PrivacyPolicyLink from 'components/shared/PrivacyPolicyLink';
import { MobileSize } from 'constants/styleguide';
import {
  getPslfCurrentState,
  getPslfRecommendation,
} from 'redux/selectors/recEngine.selectors';
import { helpWidgetSize } from 'services/chat';

const CompletedView = ({ hasCurrentPslf, isRecommendedPslf }) => (
  <Container>
    <Row center>
      <ResponsiveColumn desktopCol={8} mobileCol={12} tabletCol={12}>
        <CreateNewFormCTA />
        <MostRecentApplication />
        {isRecommendedPslf && !hasCurrentPslf && (
          <PslfBannerContainer>
            <PslfBanner buttonStyle="link" />
          </PslfBannerContainer>
        )}
        <FAQ />
        <PrivacyPolicyLinkIDRPageCompletedView />
      </ResponsiveColumn>
    </Row>
  </Container>
);

const Container = styled(GridContainer)`
  position: relative;
  margin-top: -48px;
  padding-bottom: ${helpWidgetSize.mobile};
  z-index: 1;
`;

const PslfBannerContainer = styled.div`
  margin-top: 24px;

  & > * {
    margin-left: 0;
    margin-right: 0;
  }

  @media (max-width: ${MobileSize}) {
    margin: 16px -24px 0;
  }
`;

const PrivacyPolicyLinkIDRPageCompletedView = styled(PrivacyPolicyLink)`
  justify-content: flex-start;
  text-align: left;
  padding-top: 30px;
`;

const mapStateToProps = state => ({
  hasCurrentPslf: getPslfCurrentState(state)?.forgivenessDate,
  isRecommendedPslf: getPslfRecommendation(state),
});

export default connect(mapStateToProps)(CompletedView);
