import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Header, FlexColumn } from '@summer/ui-components/src';

import { fetchAllScholarships } from 'redux/actions/collegeSavingsPlanner.actions';
import { getCollegeSavingsPlannerScholarships } from 'redux/selectors/collegeSavingsPlanner.selectors';

import FilterControl, { filtersInitialState } from './filterControl';
import { ScholarshipDatabaseLayout } from './layout';
import ScholarshipCard from './scholarshipCard';
import { filterScholarships, sortScholarships } from './utils';

const ScholarshipDatabase = ({ scholarships, dispatch }) => {
  useEffect(
    function fetchScholarshipsOnMount() {
      dispatch(fetchAllScholarships());
    },
    [dispatch],
  );

  const [filters, setFilters] = useState(filtersInitialState);

  const sortedScholarships = useMemo(
    () => sortScholarships(scholarships),
    [scholarships],
  );

  const filteredScholarships = useMemo(
    () => filterScholarships(filters, sortedScholarships),
    [filters, sortedScholarships],
  );

  return (
    <ScholarshipDatabaseLayout>
      <Container>
        <Header h2>
          We have a robust list of scholarship programs that you may qualify
          for.
        </Header>
        <FilterControl filters={filters} setFilters={setFilters} />
        {filteredScholarships.map(ScholarshipCard)}
      </Container>
    </ScholarshipDatabaseLayout>
  );
};

const Container = styled(FlexColumn)`
  gap: 16px;
`;

const mapStateToProps = state => ({
  scholarships: getCollegeSavingsPlannerScholarships(state),
});

export default connect(mapStateToProps)(ScholarshipDatabase);
