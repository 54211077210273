import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Paywall from 'components/pages/paywall';
import { getCurrentStepFromNavigation } from 'hooks/Wizard/utils';
import { savePslfCurrentStep } from 'redux/actions/pslf.actions';
import { getCurrentPslfFormId } from 'redux/selectors/pslf.selectors';

const PaywallPslf = ({ wizardNavigation: navigation }) => {
  const dispatch = useDispatch();
  const currentPslfFormId = useSelector(getCurrentPslfFormId);
  const currentStep = getCurrentStepFromNavigation(navigation);

  useEffect(() => {
    dispatch(
      savePslfCurrentStep({
        currentStep,
        form: { formId: currentPslfFormId },
      }),
    );
  }, [currentPslfFormId, dispatch, currentStep]);

  const baseUrl = window.location.origin;

  const paywallSuccessBaseUrl = `${baseUrl}/paywall/success/pslf`;
  const paywallCancelBaseUrl = `${baseUrl}/pslf/form`;

  const handlePaywallClose = () => {
    navigation.back(nextStep => {
      dispatch(
        savePslfCurrentStep({
          currentStep: nextStep.toString(),
          form: { formId: currentPslfFormId },
        }),
      );
    });
  };

  return (
    <Paywall
      variant="pslf"
      handleClose={handlePaywallClose}
      successUrl={{
        baseUrl: paywallSuccessBaseUrl,
      }}
      cancelUrl={{
        baseUrl: paywallCancelBaseUrl,
      }}
    />
  );
};

export default PaywallPslf;
