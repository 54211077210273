import React from 'react';
import styled from 'styled-components';

import {
  FlexCenter,
  Header,
  Text,
  Strong,
  MoreDetails,
  SvgImage,
  Button,
  COLORS,
} from '@summer/ui-components';
import { hexToRgba } from '@summer/ui-components/src/helpers/utils/common';

import CallOrChat from 'components/shared/ContactUs';
import { Link } from 'components/shared/typography';
import { fsaConsolidationToolLink } from 'constants/externalLinks/fsa';
import { MobileSize } from 'constants/styleguide';

const ConsolidationBlocker = () => (
  <Container data-testid="default-blocker">
    <TopContainer>
      <MoreDetailsIcon SvgComponent={MoreDetails} width={176} />
      <HeaderContainer>
        <Header h2 center>
          Consolidating your loans can help you access lower
          monthly&nbsp;payments
        </Header>
      </HeaderContainer>
      <TextContainer>
        <Text paragraph>
          Some types of federal student loans (like FFEL, Parent PLUS, and
          Perkins loans) aren&rsquo;t eligible for all of the IDR plans that
          could lower your monthly payments. By consolidating those loans,
          you&rsquo;d be combining them into one new federal loan called a
          Direct Consolidation Loan that&rsquo;s eligible for IDR as well as
          federal forgiveness programs.{' '}
        </Text>
        <br />
        <Text paragraph>
          Summer is working on building a new consolidation tool, but in the
          meantime, you can complete the process of consolidating your loans and
          enrolling in IDR through{' '}
          <Link href={fsaConsolidationToolLink} inheritColor underline>
            Federal Student Aid&rsquo;s online tool
          </Link>
          .
        </Text>
        <br />
        <WarningContainer>
          <Text paragraph>
            <Strong>
              Here’s the most important thing to remember: Don&rsquo;t
              consolidate any of your Direct loans.
            </Strong>{' '}
            (That includes any loan type with the word &ldquo;Direct&rdquo; in
            the title.){' '}
            <Strong>
              Consolidating Direct loans could reset your timeline for potential
              loan forgiveness.
            </Strong>{' '}
            You&rsquo;ll have the option to only select FFEL, Parent PLUS, or
            Perkins loans to consolidate.
          </Text>
        </WarningContainer>
      </TextContainer>
      <ButtonContainer>
        <Link href={fsaConsolidationToolLink}>
          <Button>Consolidate with Federal Student Aid</Button>
        </Link>
      </ButtonContainer>
    </TopContainer>
    <CallOrChat />
  </Container>
);

const HeaderContainer = styled.div`
  margin-bottom: 24px;
`;

const TextContainer = styled.div`
  margin: 0 auto;
`;

const WarningContainer = styled.div`
  background-color: ${hexToRgba(COLORS.orange, 0.06)};
  padding: 12px 16px;
  margin-bottom: 24px;
  border-radius: 6px;
`;

const ButtonContainer = styled(FlexCenter)`
  width: 100%;

  @media (max-width: ${MobileSize}) {
    a {
      width: 100%;
    }
  }
`;

const MoreDetailsIcon = styled(SvgImage)`
  display: flex;
  margin-bottom: 40px;
  justify-content: center;
`;

const Container = styled(FlexCenter)`
  margin: 24px auto 0;
  flex-direction: column;
`;

const TopContainer = styled.div`
  max-width: 472px;
  margin-bottom: 30px;

  @media (max-width: ${MobileSize}) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export default ConsolidationBlocker;
