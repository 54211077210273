import React from 'react';
import styled from 'styled-components';

import { Accordion, Header, Text } from '@summer/ui-components';

import { MOBILE } from 'components/hoc/Platform';

const faqs = [
  {
    title: 'How does forgiveness work under IDR?',
    content: (
      <>
        <Text paragraph>
          You may be eligible for forgiveness depending on your income, program
          requirements, and annual recertification. After 20 or 25 years on an
          IDR plan (the timeline varies by plan), the remaining balance on your
          loans will be forgiven if you&rsquo;ve made qualifying payments and
          are not in default. The amount that&rsquo;s forgiven may be taxed as
          income.
        </Text>
      </>
    ),
  },
  {
    title: 'What happens after I select a plan?',
    content: (
      <Text paragraph>
        Once you select a plan, Summer will help you complete and sign the form
        online. We&rsquo;ll then review it before submitting it to your loan
        servicer and contacting you to confirm.
      </Text>
    ),
  },
  {
    title: 'Does enrolling affect my credit score?',
    content: (
      <Text paragraph>
        A payment under an IDR plan is still considered an on-time and in-full
        monthly payment for your credit score. If lowering your monthly payment
        also means you’re not missing payments, it can actually help your credit
        score.
      </Text>
    ),
  },
  {
    title:
      'Does enrolling put all of my loans into the new plan or only some of them?',
    content: (
      <Text paragraph>
        Enrolling will place all of your loans into the new plan. (If you have
        Perkins loans and are pursuing Perkins Loan Cancellation, your Perkins
        loans will not be included).
      </Text>
    ),
  },
  {
    title: 'What is consolidation?',
    content: (
      <Text paragraph>
        Consolidation is combining multiple loans into one loan, and changing
        the type to a Direct Consolidation loan.
      </Text>
    ),
  },

  {
    title: 'Are these all the plans that are available?',
    content: (
      <Text paragraph>
        These are the plans that you&rsquo;re eligible for based on your
        individual situation and loan details.
      </Text>
    ),
  },

  {
    title: 'What if my income increases or my situation changes?',
    content: (
      <Text paragraph>
        If your income decreases, you can also submit a new form to recalculate
        your monthly payment based on your new income. If your income increases,
        your monthly payment will go up the next time you complete your annual
        certification.
      </Text>
    ),
  },
];

const FAQ = ({ platformType }) => (
  <Container>
    <Header h2>Frequently asked questions</Header>
    <Questions>
      <Accordion config={faqs} numColumns={platformType === MOBILE ? 1 : 2} />
    </Questions>
  </Container>
);

const Container = styled.div`
  max-width: 1024px;
  margin: auto;
`;

const Questions = styled.div`
  padding-top: 9px;
`;

export default FAQ;
