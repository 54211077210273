import {
  OPEN_CONNECT_FSA_MODAL,
  CLOSE_CONNECT_FSA_MODAL,
} from 'constants/actionTypes';

const initialState = {
  isOpen: false,
};

const connectFsaModal = (state = initialState, { type }) => {
  switch (type) {
    case OPEN_CONNECT_FSA_MODAL:
      return {
        isOpen: true,
      };

    case CLOSE_CONNECT_FSA_MODAL:
      return initialState;

    default:
      return state;
  }
};

export default connectFsaModal;
