import { format, parseISO } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { DateInput } from '@summer/ui-components';

import StaticField from 'components/common/ManageLoans/fields/shared/StaticField';
import InfoTooltip from 'components/shared/InfoTooltip';
import { isLoanFieldEditable } from 'redux/selectors/loans.selectors';

const DisbursementDateLabel = () => (
  <>
    <span>Date Borrowed</span>
    <InfoTooltip
      text="Date borrowed is the date that you received your loan. It is sometime referred to as the &ldquo;Origination Date&rdquo; or &ldquo;Disbursement Date.&rdquo;"
      top={2}
    />
  </>
);

class DisbursementDate extends React.Component {
  render() {
    const {
      formik: {
        values,
        errors,
        setFieldValue,
        handleBlur,
        touched,
        isSubmitting,
      },
      canEdit,
    } = this.props;

    return (
      <Container id="disbursementDate">
        <InputContainer>
          {canEdit ? (
            <DateInput
              name="disbursementDate"
              value={values.disbursementDate}
              error={errors.disbursementDate}
              touched={touched.disbursementDate}
              onChange={setFieldValue}
              onBlur={handleBlur}
              isSubmitting={isSubmitting}
              label={<DisbursementDateLabel />}
            />
          ) : (
            <StaticField
              label={<DisbursementDateLabel />}
              value={format(parseISO(values.disbursementDate), 'MM/dd/yyyy')}
            />
          )}
        </InputContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  flex: 1;
`;

const InputContainer = styled.div`
  width: 100%;
`;

const mapStateToProps = (state, props) => {
  const loanId = props.formik.values.id;
  return { canEdit: isLoanFieldEditable(state, loanId, 'disbursementDate') };
};

export default connect(mapStateToProps)(DisbursementDate);
