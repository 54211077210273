import { filter, isEmpty } from 'lodash';

export const getForgivenessFinderPrograms = state =>
  state.forgivenessFinder.programs;

export const getForgivenessFinderFilters = state =>
  state.forgivenessFinder.filters;

export const getProgramDataByUid = (state, uid) => {
  const { data } =
    filter(getForgivenessFinderPrograms(state), { uid })[0] || {};
  return data;
};

export const getFilteredPrograms = (programs, filters) => {
  if (isEmpty(filters)) {
    return programs;
  }

  const { loanType, state, occupation } = filters;
  const filterPredicate = {
    data: {}, // we using data because all filed in Prismic documents are in data key
  };

  let filtered = [...programs];

  if (loanType) {
    filtered = filterByLoanType(filtered, loanType);
  }

  if (occupation) {
    filterPredicate.data.occupation = [{ occupation_item: occupation }];
  }

  filtered = filter(filtered, filterPredicate);

  if (state) {
    filtered = filterByState(filtered, state);
  }

  return filtered;
};

export const filterByState = (filtered, state) => {
  const filteredStates = filter(filtered, value => value.data.state === state);

  const filteredNational = filter(
    filtered,
    value => value.data.state === 'national',
  );

  return [...filteredStates, ...filteredNational];
};

const filterByLoanType = (filtered, loanType) => {
  return filter(
    filtered,
    value => value.data.loanType === loanType || value.data.loanType === 'Both',
  );
};
