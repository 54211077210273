import React from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Alert, Text } from '@summer/ui-components';

import { openConnectFsa } from 'redux/actions/ui.actions';
import {
  getIsFsaLoanFileStale,
  STALE_FSA_FILE_MONTHS,
} from 'redux/selectors/sync.selectors';

const StaleFsaAlert = ({ isFsaLoanFileStale }) => {
  const dispatch = useDispatch();
  const openFsa = () => dispatch(openConnectFsa());

  return (
    isFsaLoanFileStale && (
      <DefaultAlertContainer>
        <Alert
          theme="info"
          dismissable={false}
          showIcon={true}
          callToAction={{
            label: 'Upload Now',
            onClick: openFsa,
            fullWidthMobileButton: true,
          }}
        >
          <Text small paragraph>
            It looks like your FSA loan file is more than{' '}
            {STALE_FSA_FILE_MONTHS} months old. Upload a new file so that Summer
            can provide the most up-to-date recommendations.
          </Text>
        </Alert>
      </DefaultAlertContainer>
    )
  );
};

const DefaultAlertContainer = styled.div`
  margin-top: 16px;
`;

const mapStateToProps = state => ({
  isFsaLoanFileStale: getIsFsaLoanFileStale(state),
});

export default connect(mapStateToProps)(StaleFsaAlert);
