import React from 'react';
import styled from 'styled-components';

import WizardFooter from 'components/layout/wizard/WizardFooter';
import { TabletSize } from 'constants/styleguide';

const Footer = ({
  handleBack,
  handleNext,
  isBackLoading,
  isNextDisabled,
  isNextLoading,
}) => (
  <QuestionnaireFooterContainer>
    <WizardFooter
      handleBack={handleBack}
      isBackLoading={isBackLoading}
      isNextVisible
      isNextDisabled={isNextDisabled}
      isNextLoading={isNextLoading}
      handleSaveContinue={handleNext}
    />
  </QuestionnaireFooterContainer>
);

const QuestionnaireFooterContainer = styled.div`
  > div {
    z-index: 1;
    justify-content: space-between;

    :before {
      opacity: 1;
    }

    @media (max-width: ${TabletSize}) {
      position: fixed;
      bottom: 0;
    }
  }
`;

export default Footer;
