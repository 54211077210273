import { filter, find, get } from 'lodash';
import { createSelector } from 'reselect';

const baseImgPath = '/images/servicers/';

const appendBaseImgPath = path => baseImgPath + path;

export const getServicers = state => state.constants.servicers;

export const getFsaServicer = createSelector(getServicers, servicers => {
  return servicers
    .filter(({ companyName }) => companyName.toLowerCase() === 'fsa')
    .map(servicer => {
      const updateServicer = { ...servicer };
      updateServicer.logo = appendBaseImgPath(servicer.logo);
      return updateServicer;
    });
});

export const getServicersDropdownOptions = createSelector(
  getServicers,
  servicers => {
    return servicers
      .filter(
        ({ companyName }) =>
          companyName.toLowerCase() !== 'fsa' &&
          companyName !== 'Test Fax Servicer',
      )
      .map(({ id, companyName }) => ({
        label: companyName,
        value: id,
      }))
      .sort((a, b) => {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
  },
);

export const getServicerNameById = (state, id) =>
  get(find(getServicers(state), { id }), 'companyName');

export const getConsolidationServicers = createSelector(
  getServicers,
  servicers =>
    filter(servicers, servicer => servicer.canSwitchWhenConsolidating),
);
