import React from 'react';

import Switch from 'react-switch';
import PropTypes from 'prop-types';
import { COLORS } from '../../helpers/constants/styleguide';

export default class Toggle extends React.Component {
  static defaultProps = {
    disabled: false,
    checked: false,
  };

  static propTypes = {
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Switch
        onChange={this.props.onChange}
        uncheckedIcon={false}
        checkedIcon={false}
        checked={this.props.checked}
        disabled={this.props.disabled}
        height={12}
        onHandleColor={COLORS.azure}
        onColor={COLORS.grey}
        offColor={COLORS.grey}
        offHandleColor={COLORS.white}
        handleDiameter={25}
        boxShadow="0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12)"
        width={30}
      />
    );
  }
}
