import React from 'react';
import styled from 'styled-components';

import { Header, Text, Strong } from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import ModalPopup from 'components/shared/ModalPopup';
import { Link } from 'components/shared/typography';
import { bcorpLink } from 'constants/externalLinks/marketingSite';
import { trustLink } from 'constants/externalLinks/support';
import { MobileSize } from 'constants/styleguide';

const ZeroPaymentModal = ({ platformType, children, ...props }) => {
  const isMobile = platformType === MOBILE;

  return (
    <ModalPopup
      {...props}
      displayStyle="inline-block"
      width={724}
      component={() => {
        return (
          <Container>
            <Header as={isMobile ? 'h2' : 'h1'}>
              It&rsquo;s true, your payments can be as low as $0 in an
              income-driven repayment plan
            </Header>

            <Content>
              <Section>
                <Text paragraph>
                  <Strong> Could I really pay $0 a month?</Strong>
                </Text>
                <Text paragraph>
                  Yes, really! If you apply for an income-driven repayment (IDR)
                  plan and you don&rsquo;t currently have income, your monthly
                  payment will indeed be $0.
                </Text>
              </Section>
              <Section>
                <Text paragraph>
                  <Strong>What happens if my income increases?</Strong>
                </Text>
                <Text paragraph>
                  When you&rsquo;re enrolled in an income-driven repayment plan,
                  you&rsquo;re required to ‘recertify’ your annual income once
                  every 12 months. If your annual income increases, your monthly
                  payment will increase as well.
                </Text>
              </Section>
              <Section>
                <Text paragraph>
                  <Strong>
                    Why do you estimate $0 as the total amount paid?
                  </Strong>
                </Text>
                <Text paragraph>
                  Summer estimates the total amount you will pay over the life
                  of your federal student loan by assuming that your income will
                  increase by 3% every year. Since your current income is $0,
                  right now we predict $0 as the total amount paid through the
                  life of your loan. As your income changes or increases in the
                  future, this estimate will update to reflect your updated
                  situation.
                </Text>
              </Section>
              <Section>
                <Text>
                  <Strong>Can I trust Summer?</Strong>
                </Text>
                <Text paragraph>
                  Summer is a public benefit corporation and a{' '}
                  <Link inheritColor underline href={bcorpLink}>
                    certified B Corporation®
                  </Link>{' '}
                  on a mission to help student loan borrowers achieve their
                  financial goals. Our team draws on years of experience in
                  public policy, law, consumer finance, and engineering to
                  tackle one of the greatest challenges of our generation, and
                  we&rsquo;re fed up with the bad actors and unexpected fees in
                  the market that target cash-strapped borrowers. As student
                  loan borrowers ourselves, we&rsquo;re here to{' '}
                  <Link inheritColor underline href={trustLink}>
                    stand on the side of borrowers
                  </Link>
                  .
                </Text>
              </Section>
            </Content>
          </Container>
        );
      }}
    >
      {children}
    </ModalPopup>
  );
};

const Container = styled.div`
  padding: 0px 16px;
  @media (max-width: ${MobileSize}) {
    overflow: scroll;
    flex-direction: column;
    padding: 0px;
  }
`;

const Content = styled.div`
  margin-top: 24px;
`;

const Section = styled.div`
  margin-bottom: 16px;
`;

export default Platform(ZeroPaymentModal);
