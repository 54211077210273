import React from 'react';
import styled from 'styled-components';

import { FlexColumn } from '../Flex';
import LoadingBars from '../LoadingBars';

import { COLORS } from '../../helpers/constants/styleguide';

const LoadingFullPage = () => (
  <Container>
    <LoadingBars color={COLORS.azure} />
  </Container>
);

const Container = styled(FlexColumn)`
  height: 100vh;
  width: 100vw;
  background-color: ${COLORS.white};
  justify-content: center;
`;

export default LoadingFullPage;
