import React from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import { Header, Text } from '../typography';
import Link from '../typography/Link';

import { COLORS } from '../../helpers/constants/styleguide';

import Icon from '../Icon';

const GuideCard = ({
  href,
  to,
  callToAction,
  headerText,
  contentText,
  footerText,
  type,
  icon,
  track,
  testId,
}) => {
  const textAlign = type === 'blogpost' ? 'left' : 'center';
  const cardProps = !callToAction ? { href, to } : {};
  const callToActionProps = callToAction ? { href, to, track } : {};

  return (
    <Container testId={testId} clickable={!callToAction} {...cardProps}>
      {icon && <StyledIcon width={48} height={48} SvgComponent={icon} />}
      <Header h4 align={textAlign}>
        {headerText}
      </Header>
      {contentText && <Text align={textAlign}>{contentText}</Text>}
      {callToAction && (
        <CallToActionContainer {...callToActionProps}>
          {callToAction}
        </CallToActionContainer>
      )}
      <FooterText>
        <Text small>{footerText}</Text>
      </FooterText>
    </Container>
  );
};

const StyledIcon = styled(Icon)`
  margin: 8px auto 16px;
  & g,
  & path {
    stroke: ${COLORS.medGrey};
  }
`;

const FooterText = styled.div`
  position: absolute;
  bottom: 24px;
`;

const Container = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.white};
  padding: 24px 24px 40px;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px 0 #e7ebf1;
  border-radius: 6px;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};

  :hover {
    opacity: 1;
  }

  h4 {
    margin-bottom: 12px;
  }
`;

const CallToActionContainer = styled(Link)`
  align-self: flex-end;
`;

GuideCard.propTypes = {
  headerText: PropTypes.string.isRequired,
  href: PropTypes.string,
  to: PropTypes.string,
  callToAction: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  contentText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  footerText: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  clickable: PropTypes.bool,
};

export default GuideCard;
