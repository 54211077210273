import React from 'react';

import { Form, Row } from 'components/pages/idr/wizard/shared/styles';
import {
  DateOfBirth,
  Name,
  FormerName,
} from 'components/pages/idr/wizard/steps/Submit/fields';
import { useFormDrawerFocus } from 'components/shared/FormDrawers';

const PersonalInfo = ({ formik, show }) => {
  const formRef = React.useRef(null);

  useFormDrawerFocus(formRef, show);

  return (
    <Form ref={formRef}>
      <Name formik={formik} />
      <Row>
        <FormerName formik={formik} />
        <DateOfBirth formik={formik} />
      </Row>
    </Form>
  );
};

export default PersonalInfo;
