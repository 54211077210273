import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { RadioButtons, Header } from '@summer/ui-components';

import Content from 'components/pages/pslf/wizard/layout/PslfWizardContent';
import Footer from 'components/pages/pslf/wizard/layout/PslfWizardFooter';
import { employerNonProfitTypeOptions } from 'constants/pslf';
import { MobileSize } from 'constants/styleguide';
import { useOnSuccess } from 'hooks/pslf';
import { updateCurrentPslfForm } from 'redux/actions/pslf.actions';
import { getUserCurrentPslfForm } from 'redux/selectors/pslf.selectors';
import { employerNonProfitTypeSchema } from 'schemas/pslf';

import EmployerNonProfitTypeAlert from './EmployerNonProfitTypeAlert';

const EmployerNonProfitType = ({
  currentPslfForm,
  updateCurrentPslfForm,
  navigation,
}) => {
  useOnSuccess('updateCurrentPslfForm', navigation.next);

  const handleSave = ({ employerNonProfitType }) => {
    const { formId } = currentPslfForm;
    const valuesToSave = {
      formId,
      employerNonProfitType,
    };

    updateCurrentPslfForm({ form: valuesToSave });
  };

  const initialValues = {
    employerNonProfitType: currentPslfForm.employerNonProfitType,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={employerNonProfitTypeSchema}
      validateOnMount
      onSubmit={handleSave}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        isValid,
        submitCount,
      }) => (
        <Content navigation={navigation}>
          <form onSubmit={handleSubmit}>
            <Container>
              <PslfQuestionHeader>
                <Header as="h2">
                  Is the non-profit you work for one of the following?
                </Header>
              </PslfQuestionHeader>
              <RadioButtons
                name="employerNonProfitType"
                value={values.employerNonProfitType}
                error={errors.employerNonProfitType}
                touched={touched.employerNonProfitType}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                isSubmitting={isSubmitting}
                onBlur={handleBlur}
                numberOfCol={1}
                options={employerNonProfitTypeOptions}
              />
              <EmployerNonProfitTypeAlertContainer>
                <EmployerNonProfitTypeAlert
                  employerNonProfitType={values.employerNonProfitType}
                />
              </EmployerNonProfitTypeAlertContainer>
            </Container>
            <Footer
              handleBack={navigation.back}
              isValid={isValid}
              submitCount={submitCount}
            />
          </form>
        </Content>
      )}
    </Formik>
  );
};

const PslfQuestionHeader = styled.div`
  margin-bottom: 16px;
`;

const EmployerNonProfitTypeAlertContainer = styled.div`
  margin-right: 16px;

  @media (max-width: ${MobileSize}) {
    width: 100%;
    margin-right: 0;
  }
`;

const Container = styled.div`
  max-width: 464px;
  margin: auto;
`;

const mapStateToProps = state => ({
  currentPslfForm: getUserCurrentPslfForm(state),
});

export default connect(mapStateToProps, { updateCurrentPslfForm })(
  EmployerNonProfitType,
);
