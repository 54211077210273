import React from 'react';
import styled from 'styled-components';

import {
  Text,
  SelectInput,
  COLORS,
  SmallDesktopSize,
  TabletSize,
} from '@summer/ui-components/src';

import { StateFilterOptions } from 'constants/prismic';
import { arrayToOptions } from 'utils/toOptions';

import { ethnicity, gpaRanges, interestsAffiliations } from './constants';

export const filtersInitialState = {
  stateOfResidence: null,
  interestsAffiliations: null,
  ethnicity: null,
  gpa: null,
};

const FilterControl = ({ filters, setFilters }) => {
  const onChange = (name, value) =>
    setFilters(prevState => ({
      ...prevState,
      [name]: value,
    }));

  const resetFilters = () => setFilters(filtersInitialState);

  const interestAffiliationsOptions = arrayToOptions(interestsAffiliations);
  const ethnicityOptions = arrayToOptions(ethnicity);
  const gpaOptions = arrayToOptions(gpaRanges);

  return (
    <Filters>
      <GridContainer>
        <SelectInput
          notFormik
          label="State of Residence"
          name="stateOfResidence"
          value={filters.stateOfResidence}
          options={StateFilterOptions}
          placeholder="Select State"
          onBlur={() => {}}
          onChange={onChange}
          isClearable
        />
        <SelectInput
          notFormik
          label="Interest/Affiliations"
          name="interestsAffiliations"
          value={filters.interestsAffiliations}
          options={interestAffiliationsOptions}
          placeholder="Select"
          onBlur={() => {}}
          onChange={onChange}
          isClearable
        />
        <SelectInput
          notFormik
          label="Ethnicity"
          name="ethnicity"
          value={filters.ethnicity}
          options={ethnicityOptions}
          placeholder="Select ethnicity"
          onBlur={() => {}}
          onChange={onChange}
          isClearable
        />
        <SelectInput
          notFormik
          label="GPA"
          name="gpa"
          value={filters.gpa}
          options={gpaOptions}
          placeholder="Select GPA range"
          onBlur={() => {}}
          onChange={onChange}
          isClearable
        />
      </GridContainer>
      <Text
        small
        right
        color="error"
        decoration="underLine"
        onClick={resetFilters}
      >
        Clear all filters
      </Text>
    </Filters>
  );
};

const Filters = styled.div`
  padding: 40px 48px;
  background-color: ${COLORS.lighterGrey};

  & > .text {
    cursor: pointer;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 32px;

  @media (max-width: ${SmallDesktopSize}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${TabletSize}) {
    grid-template-columns: 1fr;
  }
`;

export default FilterControl;
