import { mapValues, compact } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import {
  InfoTooltip,
  Text,
  LoadingBars,
  COLORS,
} from '@summer/ui-components/src';

import { useClearFieldOnUnmount } from 'hooks/formik';
import { getAverageTuitions } from 'redux/selectors/collegeSavingsPlanner.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { dollars } from 'utils/numbers';

import { RadioButtonsCSP } from '../shared';

const institutionLabels = {
  twoYearPublic: '2-year public',
  twoYearPrivate: '2-year private',
  fourYearPublic: '4-year public',
  fourYearPrivate: '4-year private',
};

const buttonKeys = Object.keys(institutionLabels);

const ButtonLabel = ({ institutionLabel, formattedTuition }) => (
  <>
    {institutionLabel}{' '}
    <Text as="span" size="inherit" color="inherit" weight="normal">
      ({formattedTuition})
    </Text>
  </>
);

const SelectCollegeType = ({ formik, averageTuitions, isCalcDataLoading }) => {
  useClearFieldOnUnmount('yearlyTuition', null, formik.setFieldValue);

  const formattedTuitions = mapValues(averageTuitions, yearlyTuition =>
    dollars(yearlyTuition),
  );

  const tuitionOptions = compact(
    buttonKeys.map(
      buttonKey =>
        averageTuitions[buttonKey] && {
          label: (
            <ButtonLabel
              institutionLabel={institutionLabels[buttonKey]}
              formattedTuition={formattedTuitions[buttonKey]}
            />
          ),
          value: String(averageTuitions[buttonKey]),
        },
    ),
  );

  return isCalcDataLoading ? (
    <LoadingBars color={COLORS.azure} />
  ) : (
    <>
      <Text bold>
        Select a type of institution{' '}
        <InfoTooltip text="We&rsquo;ll estimate the current cost of attendance based on the averages for each type of school." />
      </Text>
      <RadioButtonsCSP
        name="yearlyTuition"
        options={tuitionOptions}
        formik={formik}
        error={formik.errors.yearlyTuition && 'Please make a selection'}
        defaultCol={4}
        smallDesktopCol={2}
        fontSize="14px"
      />
    </>
  );
};

const mapStateToProps = state => ({
  isCalcDataLoading: isLoading(state, 'fetchCSPCalculatorData'),
  averageTuitions: getAverageTuitions(state),
});

export default connect(mapStateToProps)(SelectCollegeType);
