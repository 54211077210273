import React from 'react';
import styled from 'styled-components';

import {
  COLORS,
  Header,
  ResponsiveColumn,
  Row,
  Text,
} from '@summer/ui-components';
import Link from '@summer/ui-components/src/core/typography/Link';

import { supportAddress } from 'constants/emailLinks';
import { SmallDesktopSize } from 'constants/styleguide';
import { usePlatform } from 'hooks/platform';

const HelpFooter = () => {
  const { isMobile } = usePlatform();

  const slcProvider = 'Spinwheel';

  return (
    <HelpFooterRow>
      <ResponsiveColumn mobileCol="12" tabletCol="12" desktopCol="12">
        <HelpFooterContainer>
          {!isMobile && (
            <HelpFooterTitle>
              <Header as="h3" color="secondary">
                CAN&rsquo;T CONNECT YOUR LOAN?
              </Header>
            </HelpFooterTitle>
          )}
          <Text paragraph align="left">
            If you are having trouble syncing your student loan with{' '}
            {slcProvider} or need help enrolling in your student loan
            contribution benefit, please email{' '}
            <Link href={`mailto:${supportAddress}`}>{supportAddress}</Link> and
            someone on the Summer team will make sure to help you.
          </Text>
        </HelpFooterContainer>
      </ResponsiveColumn>
    </HelpFooterRow>
  );
};

const HelpFooterRow = styled(Row)`
  @media (max-width: ${SmallDesktopSize}) {
    margin-left: 0px;
  }
  margin-left: -172px;

  margin-bottom: 48px;
`;

const HelpFooterContainer = styled.div`
  display: flex;
  gap: 32px;
`;

const HelpFooterTitle = styled(Header)`
  height: 108px;
  border-right: solid 2px ${COLORS.grey};
  text-align: right;
  padding-right: 25px;
`;

export default HelpFooter;
