import { array, string, object } from 'yup';

const employerContributionStatementFields = {
  statementFiles: array().length(1, 'Please upload one statement'),
  accountNumber: string()
    .nullable()
    .trim()
    .max('24', 'Please enter a shorter account number')
    .required('Please enter your account number'),
};

export const employerContributionStatementSchema = object().shape(
  employerContributionStatementFields,
);
