import { Formik } from 'formik';
import { debounce } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { RadioButtons, Header, Text } from '@summer/ui-components';

import Content from 'components/pages/pslf/wizard/layout/PslfWizardContent';
import Footer from 'components/pages/pslf/wizard/layout/PslfWizardFooter';
import InfoTooltip from 'components/shared/InfoTooltip';
import { Link } from 'components/shared/typography';
import { employerTypeOptions, NON_PROFIT_OTHER } from 'constants/pslf';
import { MobileSize } from 'constants/styleguide';
import { useOnSuccess } from 'hooks/pslf';
import { updateCurrentPslfForm } from 'redux/actions/pslf.actions';
import { getUserCurrentPslfForm } from 'redux/selectors/pslf.selectors';
import { employerTypeSchema } from 'schemas/pslf';
import { track } from 'utils/track';

import ClassificationModal from './ClassificationModal';
import EmployerTypeAlert from './EmployerTypeAlert';

const EmployerType = ({
  currentPslfForm,
  updateCurrentPslfForm,
  navigation,
}) => {
  useOnSuccess('updateCurrentPslfForm', navigation.next);

  const handleSave = ({ employerType }) => {
    const { formId } = currentPslfForm;
    const valuesToSave = {
      formId,
      employerType,
    };

    // Clear values for conditional questions if their condition is not met.
    if (employerType !== NON_PROFIT_OTHER) {
      valuesToSave.employerNonProfitType = null;
      valuesToSave.employerPrimaryPurpose = [];
    }

    updateCurrentPslfForm({ form: valuesToSave });
  };

  const initialValues = { employerType: currentPslfForm.employerType };

  const debouncedTrack = debounce(
    currentPslfEmployerType =>
      track('Set pslf employer type', {
        currentPslfEmployerType,
      }),
    500,
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSave}
      validationSchema={employerTypeSchema}
      validateOnMount
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        isValid,
        submitCount,
      }) => (
        <Content navigation={navigation}>
          <form onSubmit={handleSubmit}>
            <Container>
              <PslfQuestionHeader>
                <Header as="h2">
                  What type of employer is it?
                  <InfoTooltip
                    text={
                      <>
                        If you work for a public university or public school,
                        then select &ldquo;Government organization.&rdquo;
                        According to the Dept. of Education, for-profit
                        employers (including for-profit government contractors,
                        partisan political organizations, and labor unions) do
                        not qualify for PSLF.
                      </>
                    }
                  />
                </Header>
              </PslfQuestionHeader>
              <PslfQuestionText>
                <Text>
                  Not sure how your employer is classified?&nbsp;
                  <ClassificationModal>
                    <Link inheritColor underline>
                      Learn more
                    </Link>
                  </ClassificationModal>
                </Text>
              </PslfQuestionText>
              <>
                <RadioButtons
                  name="employerType"
                  value={values.employerType}
                  error={errors.employerType}
                  touched={touched.employerType}
                  onChange={change => {
                    debouncedTrack(change.target.value);

                    return handleChange(change);
                  }}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  isSubmitting={isSubmitting}
                  onBlur={handleBlur}
                  numberOfCol={1}
                  options={employerTypeOptions}
                />
                <EmployerTypeAlertContainer>
                  <EmployerTypeAlert employerType={values.employerType} />
                </EmployerTypeAlertContainer>
              </>
            </Container>
            <Footer
              handleBack={navigation.back}
              isValid={isValid}
              submitCount={submitCount}
            />
          </form>
        </Content>
      )}
    </Formik>
  );
};

const PslfQuestionHeader = styled.div`
  margin-bottom: 16px;
`;

const PslfQuestionText = styled.div`
  margin-bottom: 16px;
`;

const EmployerTypeAlertContainer = styled.div`
  margin-right: 16px;

  @media (max-width: ${MobileSize}) {
    width: 100%;
    margin-right: 0;
  }
`;

const Container = styled.div`
  max-width: 464px;
  margin: auto;
`;

const mapStateToProps = state => ({
  currentPslfForm: getUserCurrentPslfForm(state),
});

export default connect(mapStateToProps, { updateCurrentPslfForm })(
  EmployerType,
);
