import React from 'react';

import {
  Container as GridContainer,
  ResponsiveColumn,
  Row,
  Text,
} from '@summer/ui-components';

import LandingPage from 'components/shared/dashboard/LandingPage';

const SlcLandingPage = ({ headerCopy, subheaderCopy, logoSrc, children }) => {
  return (
    <LandingPage
      headerTheme="darkGreen"
      headerName={headerCopy || ''}
      subHeader={subheaderCopy && <Text paragraph>{subheaderCopy}</Text>}
      logoSrc={logoSrc}
    >
      <GridContainer fullWidth>
        <Row center>
          <ResponsiveColumn desktopCol={10} mobileCol={12} tabletCol={12}>
            {children}
          </ResponsiveColumn>
        </Row>
      </GridContainer>
    </LandingPage>
  );
};

export default SlcLandingPage;
