import _ from 'lodash';
import fp, { set } from 'lodash/fp';

import { SET_CSP_SCHOLARSHIPS, SET_LOGOUT } from 'constants/actionTypes';
import { objectKeysToCamelCase } from 'utils/object';

const initialState = { scholarships: [] };

const scholarshipDatabaseReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case SET_CSP_SCHOLARSHIPS: {
      const { docs } = payload;
      const scholarships = _.flow(
        fp.unionBy('id', state.scholarships),
        fp.map(objectKeysToCamelCase),
      )(docs);
      return set('scholarships', scholarships, state);
    }

    case SET_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default scholarshipDatabaseReducer;
