import React from 'react';
import styled from 'styled-components';

import { Icon, Close, Text, COLORS } from '@summer/ui-components';

import Platform, { MOBILE, TABLET } from 'components/hoc/Platform';
import { Link } from 'components/shared/typography';

const WizardClose = ({ handleClose, platformType }) => {
  const isMobileOrTablet = platformType === TABLET || platformType === MOBILE;

  return (
    <>
      {isMobileOrTablet ? (
        <CloseIcon
          onClick={handleClose}
          SvgComponent={Close}
          fill={COLORS.medGrey}
        />
      ) : (
        <Text center data-testid="close-wizard">
          <Link onClick={handleClose}>Close</Link>
        </Text>
      )}
    </>
  );
};

const CloseIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
`;

export default Platform(WizardClose);
