import dotenv from 'dotenv';
import { find, toLower } from 'lodash';
import React from 'react';

import { Text } from '@summer/ui-components';

import { initFeatureFlagsFromObject } from 'services/featureFlags';
import {
  AftError,
  getAftInitialValues,
  getAftPartnerIdentifierData,
  getAftUrlPartnerData,
  getNewAftPartnerIdentifierData,
  getAftConventionPartnerIdentifierData,
  aftConventionValidation,
} from 'services/partner/aftOnboard';
import {
  getFidelityUrlPartnerData,
  getFidelityIdentifierData,
} from 'services/partner/fidelityOnboard';
import {
  getMattressFirmPartnerIdentifierData,
  mattressFirmValidation,
} from 'services/partner/mattressFirm';
import {
  getNerdWalletUrlPartnerData,
  getNerdWalletIdentifierData,
} from 'services/partner/nerdWalletOnboard';

dotenv.config();
const ENV = process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV;

const getQuery = () => {
  const search = window?.location?.search;
  return URLSearchParams ? new URLSearchParams(search) : undefined;
};

const getLogoFromUrl = () => {
  const queryParams = getQuery();

  return queryParams.get('logoSrc');
};

const defaultInitialValuesWithAutoFill = () => {
  const queryParams = getQuery();

  return {
    firstName: queryParams?.get('firstName') ?? '',
    lastName: queryParams?.get('lastName') ?? '',
    email: queryParams?.get('email') ?? '',
    password: '',
    partnerCode: '',
    termsOfService: false,
  };
};

const defaultInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  partnerCode: '',
  partnerGuid: '',
  termsOfService: false,
};

const HeaderText = {
  defaultHeaderText: `We've partnered to help you save.`,
  findYourPath: 'Find your path out of student debt.',
  freedomFromDebt: 'Making freedom from student debt a reality.',
  retirementMatch: 'Match student loan payments toward your retirement',
  yourWay: 'Your student loan savings benefit, your way.', // TODO: only used by mattress firm
};

const BenefitsHeaderText = {
  defaultBenefitsHeaderText: 'Account Benefits:',
  withSummer: 'With Summer you can:',
  withYourAccount: 'With your account, you’ll be able to:',
  premiumBenefits: 'Premium Partner Account benefits:',
};

const Testimonial = {
  jennifer: 'jennifer',
  shante: 'shante',
  corry: 'corry',
  jackalyne: 'jackalyne',
};

const OutroParagraph = {
  aft: 'AFT members have saved millions through Summer!',
  fidelity:
    'Create your account for free with the option to upgrade for the full digital PSLF experience, unlimited support, and a money-back guarantee!',
  createYourFreeAccount: 'Create your free account today.',
  paywallPartner:
    'Create your free account today and start taking action, with the option to upgrade for the full digital experience, unlimited support, and a money-back guarantee!',
};

const sharedPartnerConfigs = {
  aft: {
    title: 'AFT',
    hasLogo: true,
    logoSrc: '/images/partners/aft-logo.png',
    logoWidth: 160,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: true,
    useOauth: true,
    urlPartnerData() {
      return getAftUrlPartnerData();
    },
    getPartnerIdentifierData() {
      return getAftPartnerIdentifierData(this.urlPartnerData());
    },
    getInitialValues() {
      return getAftInitialValues(this.urlPartnerData());
    },
    get introParagraph() {
      return `AFT members now have access to Summer Premium, the student loan assistance platform to lower your payments and apply for forgiveness.`;
    },
    emailHelpMessage: '',
    waitlistLink:
      'https://docs.google.com/forms/d/e/1FAIpQLScbfa6KXDfmKGHXd-BSHD2ldPykB1S6cDpZaS4q9ycQVucDLQ/viewform?usp=sf_link',
    hideLogoCopy: false,
    testimonial: Testimonial.jennifer,
    shouldShowTestimonial: true,
    headerText: HeaderText.defaultHeaderText,
    benefitsHeaderText: BenefitsHeaderText.premiumBenefits,
    errorPage: <AftError />,
    signupValidation: null,
  },
  aftbenefit: {
    title: 'AFT Benefit',
    hasLogo: true,
    logoSrc: '/images/partners/aft-logo.png',
    logoWidth: 160,
    isPremium: true,
    usePartnerCode: false,
    usePartnerGuid: true,
    hasPartnerIdentifierData: true,
    useOauth: true,
    urlPartnerData() {
      return {
        isValidUrl: true,
      };
    },
    getPartnerIdentifierData(values) {
      return getNewAftPartnerIdentifierData(values);
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `All AFT members have access to Summer’s Premium student debt tool and resources.`;
    },
    outroParagraph: OutroParagraph.aft,
    emailHelpMessage: '',
    hideLogoCopy: false,
    testimonial: Testimonial.shante,
    shouldShowTestimonial: true,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.premiumBenefits,
    errorPage: null,
    signupValidation: null,
    partnerGuidHelpText:
      'Use the unique code shared with you via email from AFT.',
    infoItems: [
      'Access expert support',
      'Compare savings plans',
      'Apply for forgiveness',
      'Enroll in IDR',
      'Lower your payments',
      'Track your loans',
    ],
  },
  aftconvention: {
    title: 'AFT Convention',
    hasLogo: true,
    logoSrc: '/images/partners/aft-logo.png',
    logoWidth: 160,
    isPremium: true,
    usePartnerCode: true,
    usePartnerGuid: false,
    hasPartnerIdentifierData: true,
    useOauth: true,
    urlPartnerData() {
      return {
        isValidUrl: true,
      };
    },
    usePartnerIdentifierValue: false,
    getPartnerIdentifierData(values) {
      return getAftConventionPartnerIdentifierData(values);
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `All AFT members have access to Summer’s Premium student debt tool and resources.`;
    },
    outroParagraph: OutroParagraph.aft,
    emailHelpMessage: '',
    hideLogoCopy: false,
    testimonial: Testimonial.shante,
    shouldShowTestimonial: true,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.premiumBenefits,
    errorPage: null,
    signupValidation: aftConventionValidation,
    infoItems: [
      'Access expert support',
      'Compare savings plans',
      'Apply for forgiveness',
      'Enroll in IDR',
      'Lower your payments',
      'Track your loans',
    ],
  },
  ada: {
    title: 'American Diabetes Association',
    hasLogo: true,
    logoSrc: '/images/partners/ada-logo.png',
    logoWidth: 160,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `All American Diabetes Association employees and their family members now have access to Summer’s student debt resources.`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: false,
    testimonial: Testimonial.jennifer,
    shouldShowTestimonial: true,
    headerText: HeaderText.defaultHeaderText,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    signupValidation: null,
  },
  cff: {
    title: 'Cystic Fibrosis Foundation',
    hasLogo: true,
    logoSrc: '/images/partners/cff-logo.png',
    logoWidth: 160,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `All Cystic Fibrosis Foundation employees and their family members now have access to Summer’s student debt resources.`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: false,
    testimonial: Testimonial.jennifer,
    shouldShowTestimonial: true,
    headerText: HeaderText.defaultHeaderText,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    signupValidation: null,
  },
  alleninstitute: {
    title: `Allen Institute`,
    hasLogo: true,
    logoSrc: '/images/partners/allen-institute-logo.png',
    logoWidth: 200,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `Allen Institute employees now have access to Summer’s student debt resources.`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: false,
    testimonial: Testimonial.shante,
    shouldShowTestimonial: true,
    headerText: HeaderText.defaultHeaderText,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    signupValidation: null,
    infoItems: [
      'Enroll in forgiveness programs',
      'Get advice from student loan experts',
      'Compare repayment plans',
    ],
  },
  cityofalexandria: {
    title: `City of Alexandria`,
    hasLogo: true,
    logoSrc: '/images/partners/city-of-alexandria-logo.png',
    logoWidth: 80,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    oauthProviders: ['cityOfAlexandria'],
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `All City of Alexandria employees now have access to Summer's Premium student debt tool and resources.`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: false,
    testimonial: Testimonial.shante,
    shouldShowTestimonial: true,
    headerText: HeaderText.defaultHeaderText,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    signupValidation: null,
    infoItems: [
      'Access expert support',
      'Compare savings plans',
      'Lower your payments',
      'Apply for a forgiveness plan',
      'Track your loans',
    ],
  },
  asurion: {
    title: `Asurion`,
    hasLogo: true,
    logoSrc: '/images/partners/asurion-logo.png',
    logoWidth: 250,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `All Asurion employees now have access to Summer’s student debt resources.`;
    },
    emailHelpMessage: `Use your Asurion, NewCorp or UBreakIFix email address to receive premium access`,
    waitlistLink: null,
    hideLogoCopy: false,
    testimonial: Testimonial.shante,
    shouldShowTestimonial: true,
    headerText: HeaderText.defaultHeaderText,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    signupValidation: null,
  },
  mfrm: {
    title: 'Mattress Firm and Summer',
    hasLogo: true,
    logoSrc: '/images/partners/mattress-firm-logo.png',
    logoWidth: 200,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: true,
    useOauth: false,
    urlPartnerData() {
      // TODO: [sc-41709] Look into this further
      return {
        isValidUrl: true, // hack!
      };
    },
    getPartnerIdentifierData(values) {
      return getMattressFirmPartnerIdentifierData(values);
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `All Mattress Firm employees and your families now have access to Summer's Premium student debt tool and resources.`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: false,
    testimonial: Testimonial.shante,
    shouldShowTestimonial: true,
    headerText: HeaderText.yourWay,
    benefitsHeaderText: BenefitsHeaderText.premiumBenefits,
    errorPage: null,
    signupValidation: mattressFirmValidation,
    useCustomCode: true,
    customCodeName: 'mattressFirmId',
    customCodeLabel: 'Active Directory ID',
    customCodeHelpText: 'Please enter your Active Directory ID',
    infoItems: [
      'Access expert support',
      'Compare savings plans',
      'Lower your payments',
      'Apply for a forgiveness plan',
      'Track your loans',
    ],
  },
  apiDemo: {
    title: `API Demo Partner`,
    hasLogo: true,
    logoSrc: getLogoFromUrl,
    logoWidth: 144,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: true,
    urlPartnerData() {
      return null;
    },
    getPartnerIdentifierData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValuesWithAutoFill();
    },
    get introParagraph() {
      return `API users now have access to Summer’s student loan tools and resources.`;
    },
    emailHelpMessage: ``,
    waitlistLink: null,
    hideLogoCopy: false,
    testimonial: Testimonial.jackalyne,
    shouldShowTestimonial: true,
    headerText: HeaderText.defaultHeaderText,
    useShortInfoList: true,
    benefitsHeaderText: BenefitsHeaderText.withSummer,
    errorPage: null,
    signupValidation: null,
    featureFlags: {
      showPlaidInIdr: 'true',
    },
  },
  general: {
    title: 'General Paywall',
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: true,
    headerText: HeaderText.findYourPath,
    introParagraph:
      'Get started with Summer, the digital solution designed to help you tackle your student debt once and for all.',
    benefitsHeaderText: BenefitsHeaderText.withSummer,
    outroParagraph: OutroParagraph.paywallPartner,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    useCustomCode: false,
    infoItems: [
      'Compare repayment and forgiveness options',
      'Track the status of your loans',
      'Check your eligibility for repayment plans in minutes',
      'Navigate the requirements for forgiveness',
      'Easily sign and submit necessary documents online',
      'Access guides and resources',
      'Connect with student loan experts',
    ],
    testimonial: Testimonial.corry,
    shouldShowTestimonial: true,
    signupValidation: null,
  },
  fallschurchva: {
    title: 'City of Falls Church',
    hasLogo: true,
    logoSrc: '/images/partners/fallschurchva-logo.png',
    logoWidth: 80,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: false,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `All Falls Church employees now have access to Summer\u2019s Premium student debt tool and resources.`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: false,
    testimonial: Testimonial.shante,
    shouldShowTestimonial: true,
    headerText: HeaderText.defaultHeaderText,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Access expert support',
      'Compare savings plans',
      'Lower your payments',
      'Apply for a forgiveness plan',
      'Track your loans',
    ],
  },
  fidelity: {
    title: 'Fidelity ',
    hasLogo: true,
    logoSrc: '/images/partners/fidelity-logo.png',
    logoWidth: 140,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: true,
    useOauth: true,
    urlPartnerData() {
      return getFidelityUrlPartnerData();
    },
    getPartnerIdentifierData() {
      return getFidelityIdentifierData(this.urlPartnerData());
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    introParagraph() {
      return (
        <>
          Welcome Fidelity participants!{' '}
          <Text large paragraph as="span">
            Get started with Summer, the digital PSLF solution designed to help
            you tackle your student debt and get forgiveness.
          </Text>
        </>
      );
    },
    outroParagraph: OutroParagraph.fidelity,
    customQuote:
      '\u201CSummer saved me nearly $20,000 this year! I couldn\u0027t have done this alone.\u201D',
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    testimonial: Testimonial.corry,
    shouldShowTestimonial: true,
    headerText: `Find your path out of student debt.`,
    benefitsHeaderText: BenefitsHeaderText.withSummer,
    errorPage: null,
    signupValidation: null,
    infoItems: [
      'Check your eligibility in minutes',
      'Navigate the requirements for forgiveness',
      'Compare your repayment and forgiveness options',
      'Easily sign and submit necessary documents online',
    ],
    // hiddenToolsList correlates to tool pathnames.
    hiddenToolsList: ['/overpayments'],
  },
  cityofakron: {
    title: 'City of Akron',
    hasLogo: true,
    logoSrc: '/images/partners/cityofakron-logo.jpg',
    logoWidth: 94,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `All City of Akron employees now have access to Summer\u2019s Premium student debt tool and resources.`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    testimonial: Testimonial.jennifer,
    shouldShowTestimonial: true,
    headerText: HeaderText.defaultHeaderText,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Access expert support',
      'Compare savings plans',
      'Lower your payments',
      'Apply for a forgiveness plan',
      'Track your loans',
    ],
  },
  uhpa: {
    title: 'University of Hawaii Professional Assembly',
    hasLogo: true,
    logoSrc: '/images/partners/uhpa-logo.png',
    logoWidth: 360,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `AFT and UHPA have partnered with Summer to give you access to premium student debt assistance.`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    testimonial: Testimonial.shante,
    shouldShowTestimonial: true,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Apply for Public Service Loan Forgiveness',
      'Access expert support',
      'Lower your payments',
      'Track your loans',
      'Compare savings plans',
    ],
  },
  winter: {
    title: 'Winter',
    hasLogo: true,
    logoSrc: '/images/partners/winter-logo.png',
    logoWidth: 94,
    isPremium: false,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return 'All Winter employees now have access to Student Loan Contribution';
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    testimonial: Testimonial.corry,
    shouldShowTestimonial: true,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Access expert support',
      'Apply for a forgiveness plan',
      'Track your loans',
    ],
  },
  techsmith: {
    title: 'TechSmith',
    hasLogo: true,
    logoSrc: '/images/partners/techsmith-logo.png',
    logoWidth: 195,
    isPremium: false,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return 'TechSmith is helping you pay down your student loan debt faster!';
    },
    emailHelpMessage: 'Use your TechSmith email address to get verified access',
    waitlistLink: null,
    hideLogoCopy: true,
    shouldShowTestimonial: false,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Sync up your student loans',
      'Enroll in your employer\u2019s student loan contribution benefit',
      'Access free guides and resources',
    ],
  },
  cityofauburn: {
    title: 'City of Auburn, Washington',
    hasLogo: true,
    logoSrc: '/images/partners/cityofauburn-logo.png',
    logoWidth: 141,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return 'All City of Auburn employees and family now have access to Summer’s Premium student debt tools and resources.';
    },
    waitlistLink: null,
    hideLogoCopy: true,
    testimonial: Testimonial.corry,
    shouldShowTestimonial: true,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Apply for forgiveness',
      'Enroll in a new repayment plan',
      'Access expert support',
      'Compare savings plans',
      'Lower your payments',
      'Track your loans',
    ],
  },
  nerdwallet: {
    title: 'NerdWallet',
    hasLogo: true,
    logoSrc: '/images/partners/nerdwallet-logo.png',
    logoWidth: 300,
    isPremium: false,
    usePartnerCode: false,
    hasPartnerIdentifierData: true,
    useOauth: true,
    headerText: HeaderText.findYourPath,
    introParagraph:
      'Get started with Summer, the digital solution designed to help you tackle your student debt once and for all.',
    benefitsHeaderText: BenefitsHeaderText.withSummer,
    outroParagraph: OutroParagraph.paywallPartner,
    urlPartnerData() {
      return getNerdWalletUrlPartnerData();
    },
    getPartnerIdentifierData() {
      return getNerdWalletIdentifierData(this.urlPartnerData());
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    useCustomCode: false,
    infoItems: [
      'Compare repayment and forgiveness options',
      'Track the status of your loans',
      'Check your eligibility for repayment plans in minutes',
      'Navigate the requirements for forgiveness',
      'Easily sign and submit necessary documents online',
      'Access guides and resources',
      'Connect with student loan experts',
    ],
    testimonial: Testimonial.corry,
    shouldShowTestimonial: true,
    signupValidation: null,
  },
  thehome: {
    title: 'The Home for Little Wanderers',
    hasLogo: true,
    logoSrc: '/images/partners/little-wanderers.png',
    logoWidth: 200,
    isPremium: false,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    introParagraph:
      'The Home for Little Wanderers is helping you pay down your student loan debt faster!',
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    shouldShowTestimonial: false,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Sync up your student loans',
      'Enroll in your employer\u2019s student loan contribution benefit',
      'Access free guides and resources',
    ],
  },
  strongsville: {
    title: 'Strongsville',
    hasLogo: true,
    logoSrc: '/images/partners/strongsville-logo.png',
    logoWidth: 100,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: false,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    introParagraph:
      'All Strongsville employees and family now have access to Summer’s Premium student debt tool and resources.',
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    testimonial: Testimonial.jennifer,
    shouldShowTestimonial: true,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Access expert support',
      'Compare savings plans',
      'Lower your payments',
      'Apply for a forgiveness plan',
      'Track your loans',
    ],
  },
  vaxart: {
    title: 'Vaxart',
    hasLogo: true,
    logoSrc: '/images/partners/vaxart-logo.png',
    logoWidth: 130,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `All Vaxart employees and their family members have access to Summer\u2019s premium student loan support.`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: false,
    testimonial: Testimonial.corry,
    shouldShowTestimonial: true,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Access expert support',
      'Compare savings plans',
      'Lower your payments',
      'Plan for the future',
      'Track your loans',
    ],
  },
  current: {
    title: 'Current',
    hasLogo: true,
    logoSrc: '/images/partners/current-logo.png',
    logoWidth: 140,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `All Current employees and family now have access to Summer\u2019s Premium student debt tool and resources.`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: false,
    testimonial: Testimonial.corry,
    shouldShowTestimonial: true,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Access expert support',
      'Compare savings plans',
      'Lower your payments',
      'Apply for a forgiveness plan',
      'Track your loans',
    ],
  },
  vita: {
    title: 'Vita',
    hasLogo: true,
    logoSrc: '/images/partners/vita-logo.png',
    logoWidth: 140,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `Vita and Summer have partnered to give you access to Summer\u2019s Premium student debt tool and resources.`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    testimonial: Testimonial.corry,
    shouldShowTestimonial: true,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Access expert support',
      'Compare savings plans',
      'Lower your payments',
      'Apply for a forgiveness plan',
      'Track your loans',
    ],
  },
  hurleymc: {
    title: 'Hurley Medical Center',
    hasLogo: true,
    logoSrc: '/images/partners/hurleymc-logo.png',
    logoWidth: 140,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `Hurley Medical Center employees have access to convenient and easy-to-use student loan support from Summer.`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    outroParagraph: OutroParagraph.createYourFreeAccount,
    shouldShowTestimonial: true,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Speak to a student loan expert',
      'Compare personalized savings plans',
      'Digitally enroll in PSLF and IDR',
      'Plan for future college costs',
      'Sync and track loans in one place',
    ],
  },
  lsswis: {
    title: 'Lutheran Social Services of Wisconsin & Upper Michigan',
    hasLogo: true,
    logoSrc: '/images/partners/lsswis-logo.jpg',
    logoWidth: 100,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `LSS WIS employees have access to convenient and easy-to-use student loan support from Summer.`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    outroParagraph: OutroParagraph.createYourFreeAccount,
    shouldShowTestimonial: true,
    headerText: HeaderText.freedomFromDebt,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Speak to a student loan expert',
      'Compare personalized savings plans',
      'Digitally enroll in PSLF and IDR',
      'Plan for future college costs',
      'Sync and track loans in one place',
    ],
  },
  housingworks: {
    title: 'Housing Works',
    hasLogo: true,
    logoSrc: '/images/partners/housingworks-logo.png',
    logoWidth: 200,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `Housing Works employees and family have access to convenient and easy-to-use student loan support from Summer.`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    outroParagraph: OutroParagraph.createYourFreeAccount,
    shouldShowTestimonial: true,
    headerText: HeaderText.freedomFromDebt,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Speak to a student loan expert',
      'Compare personalized savings plans',
      'Digitally enroll in PSLF and IDR',
      'Plan for future college costs',
      'Sync and track loans in one place',
    ],
  },
  petvet: {
    title: 'PetVet',
    hasLogo: true,
    logoSrc: '/images/partners/petvet-logo.png',
    logoWidth: 250,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `PetVet employees and family have access to convenient and easy-to-use student loan support from Summer.`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    testimonial: Testimonial.corry,
    outroParagraph: OutroParagraph.createYourFreeAccount,
    shouldShowTestimonial: true,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Speak to a student loan expert',
      'Compare personalized savings plans',
      'Digitally enroll in federal programs',
      'Plan for future college costs',
      'Sync and track loans in one place',
    ],
  },
  learnbehavioral: {
    title: 'LEARN Behavioral',
    hasLogo: true,
    logoSrc: '/images/partners/learn-behavioral-logo.png',
    logoWidth: 200,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `LEARN employees and family members have access to convenient and easy-to-use student loan support from Summer.`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    outroParagraph: OutroParagraph.createYourFreeAccount,
    shouldShowTestimonial: true,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Speak to a student loan expert',
      'Compare personalized savings plans',
      'Digitally enroll in federal programs',
      'Plan for future college costs',
      'Sync and track loans in one place',
    ],
  },
  transformationsnetwork: {
    title: 'Transformations Care Network',
    hasLogo: true,
    logoSrc: '/images/partners/transformations-care-network.png',
    logoWidth: 300,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `Convenient and easy-to use student loan benefits at your fingertips`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    shouldShowTestimonial: true,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Enroll in your student loan contribution benefit ',
      'Receive custom repayment recommendations',
      'Digitally apply for Income-Driven Repayment',
      'Save for future college costs',
      'Get in touch with student loan experts',
    ],
  },
  napaanesthesia: {
    title: 'NAPA Anesthesia',
    hasLogo: true,
    logoSrc: '/images/partners/napaanesthesia.png',
    logoWidth: 200,
    isPremium: false,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `NAPA Anesthesia is helping you pay down your student loan debt faster!`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    shouldShowTestimonial: true,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Sync up your student loans',
      'Enroll in your employer’s student loan contribution benefit',
      'Access free guides and resources',
    ],
  },
  fairlife: {
    title: 'Fairlife',
    hasLogo: true,
    logoSrc: '/images/partners/fairlife.png',
    logoWidth: 250,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `Convenient and easy-to use student loan benefits at your fingertips`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    outroParagraph: OutroParagraph.createYourFreeAccount,
    shouldShowTestimonial: true,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Enroll in your student loan contribution benefit',
      'Receive custom repayment recommendations',
      'Digitally apply for Income-Driven Repayment',
      'Save for future college costs',
      'Get in touch with student loan experts',
    ],
  },
  elementcare: {
    title: 'Element Care',
    hasLogo: true,
    logoSrc: '/images/partners/element-care.png',
    logoWidth: 250,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `Convenient and easy-to use student loan benefits at your fingertips`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    shouldShowTestimonial: true,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Enroll in your student loan contribution benefit',
      'Receive custom repayment recommendations',
      'Digitally apply for Public Service Loan Forgiveness',
      'Save for future college costs',
      'Get in touch with student loan experts',
    ],
  },
  nortonhealthcare: {
    title: 'Norton Healthcare',
    hasLogo: true,
    logoSrc: '/images/partners/nortonhealthcare-logo.png',
    logoWidth: 250,
    isPremium: false,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `Convenient and easy-to use student loan benefits at your fingertips.`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    needsLogoSpacing: false,
    testimonial: Testimonial.jackalyne,
    shouldShowTestimonial: true,
    hasTermsOfService: false,
    headerText: 'Find your path out of student debt.',
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Enroll in your student loan contribution benefit',
      'Receive custom repayment recommendations',
      'Save for future college costs',
      'Access free guides and resources',
    ],
  },
  quorumhealth: {
    title: 'Quorum Health',
    hasLogo: true,
    logoSrc: '/images/partners/quorumhealth-logo.png',
    logoWidth: 250,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `Convenient and easy-to use student loan benefits at your fingertips`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    needsLogoSpacing: false,
    testimonial: Testimonial.jackalyne,
    outroParagraph: null,
    shouldShowTestimonial: true,
    hasTermsOfService: false,
    headerText: 'Find your path out of student debt.',
    benefitsHeaderText: HeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Enroll in your student loan contribution benefit',
      'Receive custom repayment recommendations',
      'Save for future college costs',
      'Get in touch with student loan experts',
    ],
  },
  cipe: {
    title: 'Center for International Private Enterprise (CIPE)',
    hasLogo: true,
    logoSrc: '/images/partners/cipe.png',
    logoWidth: 120,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `Convenient and easy-to use student loan benefits at your fingertips`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    needsLogoSpacing: false,
    outroParagraph: null,
    shouldShowTestimonial: false,
    hasTermsOfService: false,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Enroll in your student loan contribution benefit',
      'Save for future college costs',
      'Get in touch with student loan experts',
      'Digitally apply for Public Service Loan Forgiveness',
    ],
  },
  enel: {
    title: 'Enel North America, Inc.',
    hasLogo: true,
    logoSrc: '/images/partners/enel.png',
    logoWidth: 200,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `Convenient and easy-to use student loan benefits at your fingertips`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    needsLogoSpacing: false,
    testimonial: Testimonial.jackalyne,
    outroParagraph: null,
    shouldShowTestimonial: true,
    hasTermsOfService: false,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Enroll in your student loan contribution benefit',
      'Receive custom repayment recommendations',
      'Save for future college costs',
      'Get in touch with student loan experts',
    ],
  },
};

const getRecordKeeper = () => {
  const queryParams = getQuery();

  return queryParams.get('recordkeeper');
};

const demoPartnerConfigs = {
  // Partner for internal use
  'internal-premium': {
    title: 'Internal Premium',
    hasLogo: false,
  },
  'demo-record-keeper': {
    isRecordKeeper: true,
    title: 'Demo Record Keeper',
    hasLogo: false,
    logoWidth: 40,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    urlPartnerData() {
      return null;
    },
    get introParagraph() {
      return `${
        getRecordKeeper() || `[Recordkeeper name]`
      } and your employer are helping you save for retirement by matching the amount of your student loan payments into your retirement plan.`;
    },
    useCustomCode: false,
    shouldShowTestimonial: false,
    headerText: HeaderText.retirementMatch,
    signupValidation: null,
    outroFinePrintParagraph:
      'The amount you receive can\u2019t exceed the maximum match percentage offered by your employer.',
    useOauth: false,
    getInitialValues() {
      return defaultInitialValuesWithAutoFill();
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: false,
    benefitsHeaderText: BenefitsHeaderText.withYourAccount,
    errorPage: null,
    infoItems: [
      'Sync your student loans and confirm that they qualify',
      'Track your payments so they can be matched to your retirement plan',
      'Access free guides and resources',
    ],
  },
  'adp-demo': {
    isRecordKeeper: false,
    title: 'ADP',
    hasLogo: true,
    logoSrc: '/images/partners/adp-logo.png',
    logoWidth: 130,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    urlPartnerData() {
      return null;
    },
    get introParagraph() {
      return `ADP and your employer are helping you save for retirement by matching the amount of your student loan payments into your retirement plan.`;
    },
    useCustomCode: false,
    shouldShowTestimonial: false,
    headerText: HeaderText.retirementMatch,
    signupValidation: null,
    outroFinePrintParagraph:
      'The amount you receive can\u2019t exceed the maximum match percentage offered by your employer.',
    useOauth: false,
    getInitialValues() {
      return defaultInitialValuesWithAutoFill();
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: false,
    benefitsHeaderText: BenefitsHeaderText.withYourAccount,
    errorPage: null,
    infoItems: [
      'Sync your student loans and confirm that they qualify',
      'Track your payments so they can be matched to your retirement plan',
      'Access free guides and resources',
    ],
  },
  'assistance-demo': {
    title: 'Assistance Demo',
    hasLogo: false,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: true,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `All members now have access to Summer\u2019s Premium tuition assistance tool and resources.`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: true,
    testimonial: Testimonial.jennifer,
    shouldShowTestimonial: true,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    infoItems: [
      'Access expert support',
      'Apply for a forgiveness plan',
      'Track your loans',
    ],
  },
  'referral': {
    title: 'Referral',
    hasLogo: false,
    isPremium: false,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: true,
    urlPartnerData() {
      return null;
    },
    getInitialValues() {
      return defaultInitialValues;
    },
    get introParagraph() {
      return `Create an account to access Summer’s student debt resources.`;
    },
    emailHelpMessage: '',
    waitlistLink: null,
    hideLogoCopy: false,
    testimonial: Testimonial.jennifer,
    shouldShowTestimonial: true,
    headerText: HeaderText.defaultHeaderText,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    errorPage: null,
    signupValidation: null,
  },
};

export const partnerConfig =
  ENV === 'development' ||
  ENV === 'sandbox' ||
  ENV === 'staging' ||
  ENV === 'test'
    ? { ...sharedPartnerConfigs, ...demoPartnerConfigs }
    : { ...sharedPartnerConfigs };

export const initFeatureFlagsForPartners = partner => {
  const currentPartnerConfig = find(partnerConfig, (_, partnerKey) => {
    return toLower(partnerKey) === toLower(partner);
  });
  initFeatureFlagsFromObject(currentPartnerConfig?.featureFlags);
};
