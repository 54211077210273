/* eslint-disable no-console */
import _, {
  difference,
  every,
  get,
  includes,
  isNil,
  keys,
  map,
  omit,
  pick,
} from 'lodash';
import fp from 'lodash/fp';
import React from 'react';

import {
  IDR_APPLICATION_TYPES,
  rypDisplayPlanTypes,
} from 'components/pages/idr/wizard/steps/ReviewYourPlan/constants';
import TextTooltip from 'components/shared/TextTooltip';
import {
  employerInitialValues,
  initialEmployerState,
  initialIDRState as initialIDRSubmitState,
  initialReferenceState,
  referenceInitialValues,
} from 'redux/reducers/IDR/submit.reducer';
import { mapValuesWithPrefix } from 'utils/object';
import { pickFinancialProfile, pickProfile } from 'utils/profile';
import { stringToBool } from 'utils/toOptions';

const { NEW, SWITCH, RECALCULATE, RECERTIFY } = IDR_APPLICATION_TYPES;

export const isPathSelected = (pathId, selectedPathId) => {
  if (isNil(selectedPathId) && isNil(pathId)) {
    return false;
  }
  return pathId === selectedPathId;
};

export const checkIdrPathsPayload = ({ currentPath, paths, pathsWithPSLF }) => {
  const pathsToCheck = [...paths, ...pathsWithPSLF];

  if (currentPath && !isNil(currentPath.appType)) {
    console.error(
      `currentPath.appType should not have an 'appType' but instead it was '${currentPath.appType}'`,
    );
  }

  pathsToCheck.forEach(path => {
    if (!includes([NEW, SWITCH, RECALCULATE, RECERTIFY], path.appType)) {
      console.error(
        `path.appType should be '${NEW}', '${SWITCH}', '${RECALCULATE}', or '${RECERTIFY} but instead is ${path.appType}`,
      );
    }
    if (includes([NEW, SWITCH], path.appType) && path.planType.length !== 1) {
      console.error(
        `Path option ${path.appType} requires a single planType, instead got ${path.planType.length}`,
      );
    }
  });
};

const getEmployer = values => ({
  ...omit(
    mapValuesWithPrefix(employerInitialValues, values.submit, 'employer'),
    ['name'],
  ),
  firstName: get(values, 'submit.employerName'),
  relation: 'employer',
});

const getConsolidation = (values, location) => {
  const consolidationValues = _.flow(
    fp.pick([
      'inGracePeriod',
      'remainInGracePeriod',
      'gracePeriodEndDate',
      'targetServicerId',
      'servedInMilitary',
      'formerName',
    ]),
    fp.update('servedInMilitary', stringToBool),
    fp.update('inGracePeriod', stringToBool),
    fp.update('remainInGracePeriod', stringToBool),
  )(values.submit);

  /* Don't send grace period fields if we are not on the confirm servicers step.
   * This is necessary because these fields are conditional so we clear them in
   * the formik state when their component unmounts.
   * This can be refactored once we move IDR to wizard and each step is
   * self-contained.
   */
  if (location.pathname !== '/idr/submit/confirm-servicers') {
    return omit(consolidationValues, [
      'inGracePeriod',
      'remainInGracePeriod',
      'gracePeriodEndDate',
    ]);
  }

  if (!consolidationValues.inGracePeriod) {
    return {
      ...consolidationValues,
      remainInGracePeriod: null,
      gracePeriodEndDate: null,
    };
  }

  if (!consolidationValues.remainInGracePeriod) {
    return {
      ...consolidationValues,
      gracePeriodEndDate: null,
    };
  }

  return consolidationValues;
};

const getReference = (values, referenceNumber) => ({
  ...mapValuesWithPrefix(
    referenceInitialValues,
    values.submit,
    `reference${referenceNumber}`,
  ),
  relation: 'reference',
});

export const getSubmitFieldsToSave = (values, location) => {
  const formFields = keys(initialIDRSubmitState);
  const referenceFields = keys(initialReferenceState);
  const employerFields = keys(initialEmployerState);

  const optionalEmployeeFields = ['employerId'];

  const optionalReferenceFields = [
    'reference1Id',
    'reference1Email',
    'reference2Id',
    'reference2Email',
  ];

  const requiredEmployerFields = difference(
    employerFields,
    optionalEmployeeFields,
  );

  const requiredReferenceFields = difference(
    referenceFields,
    optionalReferenceFields,
  );

  // check that required fields are present for employer / references
  // this way we only submit them on / after the appropriate step
  const hasEmployer = every(
    requiredEmployerFields,
    field => !!values.submit[field],
  );
  const hasReferences = every(
    requiredReferenceFields,
    field => !!values.submit[field],
  );

  const form = pick(values.submit, formFields);

  form.consolidation = getConsolidation(values, location);

  if (hasEmployer) {
    form.employer = getEmployer(values);
  }

  if (hasReferences) {
    form.reference1 = getReference(values, '1');
    form.reference2 = getReference(values, '2');
  }

  if (form.consentToIrsDisclosure) {
    _.update(form, 'consentToIrsDisclosure', stringToBool);
  }

  let profile;
  const financialProfile = pickFinancialProfile(values.submit);

  // Only send profile on "Your contact information" page
  // Need to check pathname here because currentStep changes for each step.
  if (location.pathname === '/idr/submit/contact-info') {
    profile = pickProfile(values.submit);
  }

  return { form, profile, financialProfile };
};

export const calcRepaymentPeriod = numPayments => {
  const yearsOfPayments = numPayments / 12;
  const repaymentPeriod = parseFloat(
    yearsOfPayments % 1 === 0 ? yearsOfPayments : yearsOfPayments.toFixed(1),
  );
  return repaymentPeriod;
};

export const getIdrProgress = idrCurrentStep => {
  return (idrCurrentStep / 4) * 100;
};

export const filterSignatureRequestsForIdr = (
  signatureRequestsList,
  formsToGetSignatureRequest,
  formId,
) => {
  const formTypesToGetSignatureRequest = map(
    formsToGetSignatureRequest,
    'type',
  );

  return _.flow(
    fp.filter(signatureRequest => {
      return (
        includes(formTypesToGetSignatureRequest, signatureRequest.formType) &&
        formId === signatureRequest.formId
      );
    }),
    fp.orderBy(['formType'], ['desc']),
  )(signatureRequestsList);
};

export const renderPlanTypeNames = planType => {
  return planType.map((type, idx) => {
    const tooltipText = get(rypDisplayPlanTypes[type], `tooltipText`, null);
    const planTypeTitle = get(rypDisplayPlanTypes[type], `title`, type);

    const planTypeName = tooltipText ? (
      <TextTooltip kind={`path header - ${type}`} text={tooltipText}>
        {planTypeTitle}
      </TextTooltip>
    ) : (
      planTypeTitle
    );

    return (
      <React.Fragment key={idx}>
        {idx > 0 && ' and '}
        {planTypeName}
      </React.Fragment>
    );
  });
};
