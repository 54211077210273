import {
  OPEN_EDIT_A_LOAN,
  NEXT_LOAN,
  PREV_LOAN,
  CLOSE_MANAGE_LOANS,
} from 'constants/actionTypes';

const initialState = {
  programType: '', // 'idr', 'pslf'
  isOpen: false,
  currentLoanIdx: 0,
};

const loansModal = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_EDIT_A_LOAN:
      return {
        programType: payload.programType,
        isOpen: true,
        currentLoanIdx: payload.loanIdx,
      };

    case NEXT_LOAN:
      return {
        ...state,
        currentLoanIdx: state.currentLoanIdx + 1,
      };

    case PREV_LOAN:
      return {
        ...state,
        currentLoanIdx: state.currentLoanIdx - 1,
      };

    case CLOSE_MANAGE_LOANS:
      return initialState;

    default:
      return state;
  }
};

export default loansModal;
