import {
  filter,
  find,
  forEach,
  get,
  includes,
  isEmpty,
  isNil,
  reject,
} from 'lodash';
import { createSelector } from 'reselect';

import { FormStatus } from '@simplifidev/shared/dist/constants/forms';

import {
  IDR_APPLICATION_TYPES,
  REPAYMENT_PLAN_TYPES,
} from 'components/pages/idr/wizard/steps/ReviewYourPlan/constants';
import { getFileCollectionsState } from 'redux/selectors/fileCollections.selectors';
import {
  getFinancialProfile,
  getMaritalStatus,
  getSpouseHasFsl,
} from 'redux/selectors/financialProfile.selectors';
import { getIsSummerBasicEnabled } from 'redux/selectors/flags.selectors';
import {
  getUserFederalLoans,
  userHasLoansInIdr,
  getActiveFsaLoanServicerNames,
} from 'redux/selectors/loans.selectors';
import {
  getActiveFederalPlaidLoanServicerNames,
  arePlaidFederalLoansMissingInfo,
  getPlaidFederalLoans,
} from 'redux/selectors/plaid.selectors';
import {
  getUserCity,
  getUserDateOfBirth,
  getUserFirstName,
  getUserLastName,
  getUserPhone,
  getUserStateOfResidence,
  getUserStreetAddress1,
  getUserZipcode,
} from 'redux/selectors/profile.selectors';
import { getIdrOnboardingQuestion } from 'redux/selectors/user.selectors';
import { showPlaidInIdr } from 'services/featureFlags/flags';
import { boolToString } from 'utils/toOptions';

export const getSupportingDocumentsState = state =>
  state.IDR.supportingDocuments;

export const getIdrCalculations = state => state.IDR.calculations;

export const getSelectedPathId = state => {
  return state.IDR.calculations.selectedPathId;
};

export const doWeNeedSpouseSignature = createSelector(
  getMaritalStatus,
  maritalStatus => maritalStatus === 'married',
);

export const doWeNeedSpouseInfo = createSelector(
  getMaritalStatus,
  getSpouseHasFsl,
  (maritalStatus, spouseHasFsl) =>
    maritalStatus === 'married' && spouseHasFsl.toString() === 'true',
);

export const getRequiredSupportingDocuments = createSelector(
  getSupportingDocumentsState,
  getFileCollectionsState,
  (supportingDocuments, fileCollections) => {
    const requiredSupportingDocuments = {};

    forEach(supportingDocuments, (isRequire, collectionName) => {
      if (isRequire) {
        requiredSupportingDocuments[collectionName] =
          fileCollections[collectionName] || [];
      }
    });

    return requiredSupportingDocuments;
  },
);

export const showConfirmIdrPlan = state => {
  /* This function will let you know if a user is enrolled in IDR, based on their loan data, with
   * a fallback to the onboarding question if loans are not available.
   * The same logic is used to determine the field `recommended.idr.alreadyInIdr` in the rec engine.
   * We duplicate it here so that we don't need to rely on the rec engine state in IDR.
   */
  const loansAreInIDR = userHasLoansInIdr(state);
  const idrOnboardingQuestion = getIdrOnboardingQuestion(state);

  if (loansAreInIDR === true) {
    return true;
  }
  if (loansAreInIDR === false) {
    return false;
  }
  if (loansAreInIDR === null) {
    if (idrOnboardingQuestion === 'yes') {
      return true;
    }
    if (idrOnboardingQuestion === 'no') {
      return false;
    }
  }

  return null;
};

export const getSelectedPath = createSelector(
  getIdrCalculations,
  pathsState => {
    if (isNil(pathsState)) {
      return null;
    }

    const { selectedPathId, currentPath, paths, pathsWithPSLF } = pathsState;
    const allPaths = [currentPath, ...paths, ...pathsWithPSLF];
    return find(allPaths, { id: selectedPathId });
  },
);

export const showConfirmServicers = createSelector(
  getSelectedPath,
  selectedPath => {
    const { RECERTIFY, RECALCULATE } = IDR_APPLICATION_TYPES;
    const selectedAppType = get(selectedPath, 'appType');
    return !(selectedAppType === RECERTIFY || selectedAppType === RECALCULATE);
  },
);

export const selectedPathIncludesConsolidation = createSelector(
  getSelectedPath,
  selectedPath => {
    if (isNil(selectedPath)) {
      return null;
    }

    return selectedPath.requiresConsolidation;
  },
);

export const blockUserOnPlansStep = createSelector(
  getIdrCalculations,
  idrCalculations => idrCalculations.blockedReason,
);

export const blockUserOnSubmitStep = createSelector(
  getSelectedPath,
  selectedPath => get(selectedPath, 'blockedReason'),
);

/**
 * This function returns ONE set of loans to consolidate
 * Only loansToConsolidate or loansToConsolidateParentPlus should be populated.
 * This will change in the future when we handle consolidating
 * multiple sets of loans.
 */
export const getLoansToConsolidate = createSelector(
  getUserFederalLoans,
  getSelectedPath,
  selectedPathIncludesConsolidation,
  (userFederalLoans, selectedPath, includesConsolidation) => {
    const { loansToConsolidate, loansToConsolidateParentPlus } = selectedPath;

    if (
      !includesConsolidation ||
      (isEmpty(loansToConsolidate) && isEmpty(loansToConsolidateParentPlus))
    ) {
      return null;
    }

    const loanIdsToConsolidate = isEmpty(loansToConsolidate)
      ? loansToConsolidateParentPlus
      : loansToConsolidate;

    const loans = filter(userFederalLoans, loan =>
      includes(loanIdsToConsolidate, loan.id),
    );

    return loans;
  },
);

export const getPlaidLoansToConsolidate = createSelector(
  getPlaidFederalLoans,
  getSelectedPath,
  selectedPathIncludesConsolidation,
  (plaidLoans, selectedPath, includesConsolidation) => {
    const { plaidLoansToConsolidate, plaidLoansToConsolidateParentPlus } =
      selectedPath;

    if (
      !includesConsolidation ||
      (isEmpty(plaidLoansToConsolidate) &&
        isEmpty(plaidLoansToConsolidateParentPlus))
    ) {
      return null;
    }

    const plaidLoanIdsToConsolidate = isEmpty(plaidLoansToConsolidate)
      ? plaidLoansToConsolidateParentPlus
      : plaidLoansToConsolidate;

    const loans = filter(plaidLoans, loan =>
      includes(plaidLoanIdsToConsolidate, loan.plaidLoanUuid),
    );
    return loans;
  },
);

const getUserCurrentPlanTypes = createSelector(
  getIdrCalculations,
  idrPathOptions => get(idrPathOptions, 'currentPath.planType'),
);

export const userIsInIbr = createSelector(
  getUserCurrentPlanTypes,
  currentPlanTypes =>
    includes(currentPlanTypes, REPAYMENT_PLAN_TYPES.IBR) ||
    includes(currentPlanTypes, REPAYMENT_PLAN_TYPES.IBR_NEW_BORROWER),
);

export const userIsInPaye = createSelector(
  getUserCurrentPlanTypes,
  currentPlanTypes => includes(currentPlanTypes, REPAYMENT_PLAN_TYPES.PAYE),
);

const getReceivingUnemploymentIncomeInitialValue = (
  currentlyEmployed,
  unemploymentIncome,
  expectedAnnualGrossIncome,
) => {
  if (boolToString(currentlyEmployed) === 'false') {
    if (unemploymentIncome !== '' && unemploymentIncome >= 0) {
      return 'true';
    }
    if (expectedAnnualGrossIncome !== '' && expectedAnnualGrossIncome >= 0) {
      return 'false';
    }
  }
  return null;
};

// This selector handles loading initial values to IDR's Formik component
// We can break this up when we move IDR to the new Wizard hook and
// each page has its own separate Form.
export const getCurrentIdrForm = state => {
  const { formId, currentStep, status, signedAt } = state.IDR.currentIdrForm;

  const {
    calculations,
    signatureInfo,
    supportingDocuments,
    yourLoans,
    wizardSteps,
  } = state.IDR;

  const {
    numberOfKids,
    numberOfDependents,
    maritalStatus,
    changeInIncome,
    currentlyEmployed,
    unemploymentIncome,
    unemploymentEndDate,
    expectedNonUnemploymentIncome,
    expectedNextAnnualIncome,
    adjustedGrossIncome,
    expectedAnnualGrossIncome,
    spouseHasFsl,
    jointTaxReturn,
    spouseExpectedAnnualGrossIncome,
    spouseIncomeChange,
    spouseCurrentlyEmployed,
    spouseUnemploymentIncome,
    spouseUnemploymentEndDate,
    spouseExpectedNonUnemploymentIncome,
    spouseExpectedNextAnnualIncome,
    jointAdjustedGrossIncome,
    spouseAdjustedGrossIncome,
    spouseCurrentLoanBalance,
    spousePayFrequency,
  } = getFinancialProfile(state);

  // receivingUnemploymentIncome is not saved in the database, so we need to infer its value from other fields
  const receivingUnemploymentIncome =
    getReceivingUnemploymentIncomeInitialValue(
      currentlyEmployed,
      unemploymentIncome,
      expectedAnnualGrossIncome,
    );
  const spouseReceivingUnemploymentIncome =
    getReceivingUnemploymentIncomeInitialValue(
      spouseCurrentlyEmployed,
      spouseUnemploymentIncome,
      spouseExpectedAnnualGrossIncome,
    );

  return {
    formId,
    calculations,
    currentStep,
    signedAt,
    signatureInfo,
    status,
    submit: {
      ...state.IDR.submit,
      firstName: getUserFirstName(state),
      lastName: getUserLastName(state),
      streetAddress1: getUserStreetAddress1(state),
      city: getUserCity(state),
      state: getUserStateOfResidence(state),
      zipcode: getUserZipcode(state),
      dateOfBirth: getUserDateOfBirth(state),
      phone: getUserPhone(state),
      spousePayFrequency,
    },
    supportingDocuments,
    tellUsAboutYou: {
      state: getUserStateOfResidence(state),
      numberOfKids,
      numberOfDependents,
      maritalStatus,
      changeInIncome,
      currentlyEmployed: boolToString(currentlyEmployed),
      receivingUnemploymentIncome,
      unemploymentIncome,
      unemploymentEndDate,
      expectedNonUnemploymentIncome,
      expectedNextAnnualIncome,
      adjustedGrossIncome,
      expectedAnnualGrossIncome,
      spouseHasFsl: boolToString(spouseHasFsl),
      jointTaxReturn,
      spouseExpectedAnnualGrossIncome,
      spouseIncomeChange,
      spouseCurrentlyEmployed: boolToString(spouseCurrentlyEmployed),
      spouseReceivingUnemploymentIncome,
      spouseUnemploymentIncome,
      spouseUnemploymentEndDate,
      spouseExpectedNonUnemploymentIncome,
      spouseExpectedNextAnnualIncome,
      jointAdjustedGrossIncome,
      spouseAdjustedGrossIncome,
      spouseCurrentLoanBalance,
    },
    wizardSteps,
    yourLoans,
  };
};

export const getCurrentIdrFormStep = createSelector(
  getCurrentIdrForm,
  ({ currentStep }) => currentStep,
);

export const getCurrentIdrFormStatus = createSelector(
  getCurrentIdrForm,
  ({ status }) => status,
);

export const getHasSubmittedIdrForm = createSelector(
  getCurrentIdrFormStatus,
  status => status === FormStatus.SIGNED,
);

export const getCurrentIdrFormId = createSelector(
  getCurrentIdrForm,
  ({ formId }) => formId,
);

export const getIdrWizardSteps = createSelector(
  getIsSummerBasicEnabled,
  selectedPathIncludesConsolidation,
  showConfirmServicers,
  (summerBasicEnabled, pathIncludesConsolidation, showConfirmServicers) => {
    const idrSetupSteps = [
      '/repayment',
      '/idr/tell-us-about-you',
      '/idr/your-loans',
      '/idr/review-your-plan',
    ];

    const idrSummerBasicSteps = ['/idr/submit/fsa-instructions'];

    const idrSubmitSteps = [
      ...(pathIncludesConsolidation ? ['/idr/submit/consolidate-loans'] : []),
      '/idr/submit/contact-info',
      ...(pathIncludesConsolidation ? ['/idr/submit/references'] : []),
      ...(showConfirmServicers ? ['/idr/submit/confirm-servicers'] : []),
      '/idr/submit/upload-documents',
      '/idr/submit/sign-and-send',
    ];

    return [
      ...idrSetupSteps,
      ...(summerBasicEnabled ? idrSummerBasicSteps : idrSubmitSteps),
    ];
  },
);

export const getStepFromLocation = state =>
  getIdrWizardSteps(state).indexOf(state.router.location.pathname);

export const checkIfThisIsLastStep = createSelector(
  getStepFromLocation,
  getIdrWizardSteps,
  (currentStep, wizardSteps) => {
    return currentStep === wizardSteps.length - 1;
  },
);

const getIdrForms = state => state.IDR.idrForms;

const getCompletedIdrForms = createSelector(getIdrForms, idrForms =>
  reject(idrForms, { status: FormStatus.STARTED }),
);

export const getNumCompletedIdrForms = createSelector(
  getCompletedIdrForms,
  completedIdrForms => completedIdrForms.length,
);

const getLastSubmittedIdrForm = createSelector(
  getCompletedIdrForms,
  completedIdrForms => completedIdrForms[0],
);

export const getLastSubmittedIdrFormPlanType = createSelector(
  getLastSubmittedIdrForm,
  ({ selectedPathPlanType }) => selectedPathPlanType,
);

export const getLastSubmittedIdrFormFirstPayment = createSelector(
  getLastSubmittedIdrForm,
  ({ selectedPathFirstPayment }) => selectedPathFirstPayment,
);

export const getLastSubmittedIdrFormSignedAt = createSelector(
  getLastSubmittedIdrForm,
  ({ signedAt }) => signedAt,
);

export const checkIdrNetworkSuccess = (state, key) => {
  return get(state, `IDR.networkSuccess.${key}`);
};

export const getValueForServicersToSendIDR = createSelector(
  getActiveFsaLoanServicerNames,
  getActiveFederalPlaidLoanServicerNames,
  arePlaidFederalLoansMissingInfo,
  (servicerNamesFromFsa, servicerNamesFromPlaid, plaidLoansAreMissingInfo) => {
    const usePlaidLoans = showPlaidInIdr() && !plaidLoansAreMissingInfo;
    return usePlaidLoans ? servicerNamesFromPlaid : servicerNamesFromFsa;
  },
);
