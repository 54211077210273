import { push as redirectTo } from 'connected-react-router';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  FlexRow,
  Header,
  Text,
  Strong,
  Button,
  CheckSmall,
  Close,
  SummerLogo,
  SyncInfinity,
  Icon,
  IconMedallion,
  COLORS,
} from '@summer/ui-components';

import Platform, { MOBILE, DESKTOP } from 'components/hoc/Platform';
import ModalView from 'components/layout/ModalView';
import LoanTypesModal from 'components/pages/support/ScheduleConsultationPage/LoanTypesModal';
import SyncView from 'components/pages/support/ScheduleConsultationPage/SyncView';
import { Link } from 'components/shared/typography';
import { loanConsultationCalendlyLink } from 'constants/externalLinks/support';
import { partnerConfig } from 'constants/partnerConfig';
import { MobileSize, TabletSize, SmallDesktopSize } from 'constants/styleguide';
import { fetchLoans } from 'redux/actions/loans.actions';
import {
  getUserPartnerKey,
  getUserPartnerName,
} from 'redux/selectors/user.selectors';

const ScheduleConsultationPage = ({
  platformType,
  userPartnerKey,
  userPartnerName,
}) => {
  const dispatch = useDispatch();
  const [showSyncView, setShowSyncView] = useState(false);
  const isCollapseWidth = platformType !== DESKTOP;
  const isMobile = platformType === MOBILE;

  const headerText = `Thanks to our partnership with ${userPartnerName}, you are
        eligible to speak with one of Summer’s student loan experts!`;

  const handleClose = () => {
    dispatch(redirectTo('/support'));
  };

  useEffect(() => {
    dispatch(fetchLoans());
  }, [dispatch]);

  return (
    <ModalView
      handleClose={handleClose}
      showLogo={!isMobile}
      headerText="Schedule a consultation"
    >
      {showSyncView ? (
        <SyncViewContainer>
          <SyncView onClickBack={() => setShowSyncView(false)} />
        </SyncViewContainer>
      ) : (
        <Content>
          <PartnershipDescription>
            {partnerConfig[userPartnerKey]?.hasLogo && (
              <Logos>
                <Icon
                  fill={COLORS.lightBlue}
                  SvgComponent={SummerLogo}
                  width={isMobile ? 114 : 143}
                />
                <XIcon fill={COLORS.medGrey} SvgComponent={Close} />
                <LogoImageContainer partner={userPartnerKey}>
                  <LogoImage
                    partner={userPartnerKey}
                    src={partnerConfig[userPartnerKey].logoSrc}
                  />
                </LogoImageContainer>
              </Logos>
            )}
            <Header as={isCollapseWidth ? 'h4' : 'h2'}>
              <Strong>{headerText}</Strong>
            </Header>
            <PartnershipText paragraph>
              Here&rsquo;s what you can expect with a consultation:
            </PartnershipText>
            <List>
              <ListText paragraph as="li">
                <Checkmark
                  width={11}
                  SvgComponent={CheckSmall}
                  stroke={COLORS.jade}
                />
                Review your student loan situation and identify potential
                savings options
              </ListText>
              <ListText paragraph as="li">
                <Checkmark
                  width={11}
                  SvgComponent={CheckSmall}
                  stroke={COLORS.jade}
                />
                Walk through the recommendations you received online from Summer
              </ListText>
              <ListText paragraph as="li">
                <Checkmark
                  width={11}
                  SvgComponent={CheckSmall}
                  stroke={COLORS.jade}
                />
                Answer any questions about your loans or next steps
              </ListText>
            </List>
          </PartnershipDescription>
          <SyncLoansContent>
            <IconMedallion SvgComponent={SyncInfinity} color={COLORS.jade} />
            <SyncLoansHeader h4>
              <Strong>
                Before scheduling a call, you&rsquo;ll need to sync your federal
                loan information to Summer.
              </Strong>
            </SyncLoansHeader>
            <SyncLoansText paragraph>
              This will ensure we have a more complete view of your student loan
              situation during our consultation.
            </SyncLoansText>
            <Button width={240} onClick={() => setShowSyncView(true)}>
              Sync Federal Loans
            </Button>
            <PrivateLoansLink href={loanConsultationCalendlyLink}>
              I only have private loans
            </PrivateLoansLink>
            <LoanTypesModal>
              <Text decoration="underline">I&rsquo;m not sure</Text>
            </LoanTypesModal>
          </SyncLoansContent>
        </Content>
      )}
    </ModalView>
  );
};

const PartnershipDescription = styled.div`
  max-width: 431px;
  margin-right: 104px;

  @media (max-width: ${SmallDesktopSize}) {
    max-width: 500px;
    padding: 36px 24px 81px;
    margin-right: 0;
  }

  @media (max-width: ${MobileSize}) {
    max-width: 100%;
  }
`;

const Logos = styled(FlexRow)`
  padding-bottom: 24px;

  @media (max-width: ${SmallDesktopSize}) {
    justify-content: center;
  }
`;

const XIcon = styled(Icon)`
  width: 14px;
  margin: auto 24px;

  @media (max-width: ${MobileSize}) {
    width: 12px;
    margin: auto 18px;
  }
`;

const LogoImageContainer = styled.div`
  @media (max-width: ${TabletSize}) {
    max-width: ${({ partner }) => partnerConfig[partner].logoWidth - 13}px;
  }
`;

const LogoImage = styled.img`
  vertical-align: bottom;
  width: ${({ partner }) => partnerConfig[partner].logoWidth}px;

  @media (max-width: ${TabletSize}) {
    width: 100%;
    height: auto;
  }
`;

const PartnershipText = styled(Text)`
  padding: 24px 0 16px;
`;

const List = styled.ul`
  list-style-type: none;
`;

const Checkmark = styled(Icon)`
  margin-right: 10px;
`;

const ListText = styled(Text)`
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const SyncLoansContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${COLORS.lighterGrey};
  padding: 39px 60px 52px;
  max-width: 626px;

  @media (max-width: ${SmallDesktopSize}) {
    max-width: none;
    width: 100%;
    padding: 46px 28px 50px;
  }
`;

const SyncLoansHeader = styled(Header)`
  padding-top: 24px;
  text-align: center;
`;

const SyncLoansText = styled(Text)`
  padding: 10px 0 40px;
  text-align: center;
`;

const PrivateLoansLink = styled(Link)`
  padding: 24px 0 15px;
`;

const SyncViewContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const Content = styled.div`
  align-items: center;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 30px;

  @media (max-width: ${SmallDesktopSize}) {
    height: auto;
    padding: 0;
    flex-direction: column-reverse;
  }
`;

const mapStateToProps = state => ({
  userPartnerKey: getUserPartnerKey(state),
  userPartnerName: getUserPartnerName(state),
});

export default Platform(connect(mapStateToProps)(ScheduleConsultationPage));
