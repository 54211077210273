import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  TextInput,
  Button,
  FlexColumn,
  Header,
  COLORS,
} from '@summer/ui-components';

import GlobalError from 'components/shared/GlobalError';
import { TabletSize } from 'constants/styleguide';
import { updatePasswordValidationSchema } from 'schemas/user';

export default class UpdatePassword extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    error: PropTypes.string,
  };

  constructor() {
    super();

    this.state = {
      submitted: false,
    };

    this.formInitialValues = {
      oldPassword: '',
      newPassword: '',
    };
  }

  handleSubmit = values => {
    this.props.handleSubmit(values);

    this.setState({
      submitted: true,
    });
  };

  renderForm = formik => {
    const {
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      touched,
      isSubmitting,
      isValid,
    } = formik;

    const { error } = this.props;
    const { submitted } = this.state;

    return (
      <Form onSubmit={handleSubmit}>
        <HeaderContainer>
          <Header h4 color="secondary">
            Change your password{' '}
          </Header>
        </HeaderContainer>

        <TextInput
          label="Old Password"
          name="oldPassword"
          type="password"
          touched={touched.oldPassword}
          isSubmitting={isSubmitting}
          error={errors.oldPassword}
          value={values.oldPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!error && submitted}
          autoComplete="current-password"
        />

        {/* Dummy input to disable new-password autocomplete */}
        <input
          type="password"
          name="dummy-password"
          style={{ display: 'none' }}
          autoComplete="new-password"
        />

        <TextInput
          label="New Password"
          name="newPassword"
          type="password"
          touched={touched.newPassword}
          isSubmitting={isSubmitting}
          error={errors.newPassword}
          value={values.newPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!error && submitted}
          autoComplete="new-password"
        />

        <GlobalError label="updatePassword" />

        <Button
          disabled={!isValid || (!error && submitted)}
          isLoading={this.props.isLoading}
          type="submit"
        >
          {!error && submitted ? 'Password updated!' : 'Update'}
        </Button>
      </Form>
    );
  };

  render() {
    return (
      <Formik
        initialValues={this.formInitialValues}
        onSubmit={this.handleSubmit}
        validationSchema={updatePasswordValidationSchema}
      >
        {formik => this.renderForm(formik)}
      </Formik>
    );
  }
}

const Form = styled(FlexColumn.withComponent('form'))`
  margin-top: 24px;
  margin-bottom: 24px;
  max-width: 355px;

  @media (max-width: ${TabletSize}) {
    width: 100%;
  }
`;

const HeaderContainer = styled.div`
  border-bottom: 1px solid ${COLORS.grey};
  margin-bottom: 16px;
  padding-bottom: 8px;
`;
