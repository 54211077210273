/* eslint-disable react/display-name */
import { difference } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { Text } from '@summer/ui-components';

import Header from 'components/pages/idr/wizard/steps/Submit/shared/Header';
import FormDrawers from 'components/shared/FormDrawers';
import { MobileSize } from 'constants/styleguide';
import { referenceInitialValues } from 'redux/reducers/IDR/submit.reducer';
import { getKeysWithPrefix } from 'utils/object';

import ReferenceForm from './ReferenceForm';

const References = ({
  values,
  errors,
  touched,
  idrHandlers,
  resetSubmitCount,
  currentStepSubmitCount,
}) => {
  const formik = { values, errors, touched, ...idrHandlers };

  const getReferenceFieldNames = referenceNumber => {
    // Generates an array of field names for each reference
    const referencePrefix = `reference${referenceNumber}`;
    return difference(
      getKeysWithPrefix(referenceInitialValues, referencePrefix),
      [`${referencePrefix}Id`],
    );
  };

  return (
    <>
      <Header>Provide two references</Header>
      <Text paragraph small>
        Strange, we know! Unfortunately the Department of Education requires two
        personal or professional references, that:
      </Text>
      <Description>
        <ul>
          <Text paragraph small as="li">
            You have known for at least three years
          </Text>
          <Text paragraph small as="li">
            Have different U.S. addresses from you and from each other
          </Text>
        </ul>
      </Description>
      <DrawersContainer>
        <FormDrawers
          errors={errors}
          submitCount={currentStepSubmitCount}
          resetSubmitCount={resetSubmitCount}
          forms={[
            {
              name: 'reference1',
              title: 'REFERENCE #1',
              form: show => (
                <ReferenceForm
                  formik={formik}
                  referenceNumber="1"
                  show={show}
                />
              ),
              fields: getReferenceFieldNames('1'),
            },
            {
              name: 'reference2',
              title: 'REFERENCE #2',
              form: show => (
                <ReferenceForm
                  formik={formik}
                  referenceNumber="2"
                  show={show}
                />
              ),
              fields: getReferenceFieldNames('2'),
            },
          ]}
        />
      </DrawersContainer>
    </>
  );
};

const DrawersContainer = styled.div`
  @media (max-width: ${MobileSize}) {
    margin: 0 -20px;
  }
`;

const Description = styled.div`
  margin-bottom: 24px;

  & > ul {
    margin-top: 8px;
    margin-left: 16px;
  }
`;

export default References;
