import React from 'react';

import { Statistic } from '@summer/ui-components';

import { isTestEnv } from 'utils/common';

const StatisticWebApp = props => (
  <Statistic {...props} animate={props.animate && !isTestEnv()} />
);
export default StatisticWebApp;
