import { set } from 'lodash/fp';

import { SET_UI_SETTING, SET_USER } from 'constants/actionTypes';

const initialState = {};

const settings = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER:
      return { ...payload.user.uiSettings };
    case SET_UI_SETTING:
      return set(payload.key, payload.value, state);
    default:
      return state;
  }
};

export default settings;
