import { map } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  FlexRow,
  FlexColumn,
  Header,
  Text,
  Icon,
  Caution,
  Checkmark,
  COLORS,
} from '@summer/ui-components';

import ModalPopup from 'components/shared/ModalPopup';
import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';
import { MobileSize } from 'constants/styleguide';
import { getHideSupportChat } from 'redux/selectors/user.selectors';
import { openChat } from 'services/chat';

const InfoPopup = ({
  kind,
  children,
  title,
  pros,
  consTitle,
  cons,
  however,
  hideSupportChat,
}) => (
  <ModalPopup
    kind={kind}
    displayStyle="inline-block"
    width={800}
    component={() => (
      <div>
        <HeaderContainer>
          <Header h2 center>
            {title}
          </Header>
        </HeaderContainer>

        <Pros>
          {map(pros, ({ title, description }, i) => (
            <Tip key={i}>
              <Check SvgComponent={Checkmark} stroke={COLORS.jade} />
              <div>
                <Text bold paragraph>
                  {title}
                </Text>
                <Text paragraph>{description}</Text>
              </div>
            </Tip>
          ))}
        </Pros>

        <Subheader>
          <Header h4>{consTitle}</Header>
        </Subheader>

        {map(cons, ({ description }, i) => (
          <Warning key={i}>
            <CautionIcon
              width={24}
              height={24}
              SvgComponent={Caution}
              fill={COLORS.orange}
            />
            <Text paragraph>{description}</Text>
          </Warning>
        ))}

        {however && (
          <However>
            <Text paragraph bold>
              {however}
            </Text>
          </However>
        )}

        <Contact>
          <Text paragraph center>
            Questions?{' '}
            {hideSupportChat ? (
              <>
                Email us at{' '}
                <Link href={`mailto:${supportAddress}`}>{supportAddress}</Link>
              </>
            ) : (
              <>
                Just{' '}
                <Link underline onClick={openChat}>
                  send us a message
                </Link>
                !
              </>
            )}
          </Text>
        </Contact>
      </div>
    )}
  >
    {children}
  </ModalPopup>
);

const HeaderContainer = styled.div`
  width: 472px;
  padding-bottom: 32px;
  margin: 0 auto;

  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

const Subheader = styled(FlexRow)`
  margin-bottom: 8px;
`;

const Tip = styled(FlexRow)`
  &:not(:first-child) {
    margin-top: 16px;
  }
`;

const Check = styled(Icon)`
  margin: 2px 12px 8px 0;
  width: 24px;
  height: 24px;
`;

const CautionIcon = styled(Icon)`
  margin: 2px 12px 8px 0;
`;

const Warning = styled(FlexRow)`
  margin: 8px 0;
`;

const Contact = styled.div`
  margin: 16px;
`;

const Pros = styled(FlexColumn)`
  margin-top: 8px;
  margin-bottom: 40px;
`;

const However = styled.div`
  margin-top: 8px;
`;

const mapStateToProps = state => ({
  hideSupportChat: getHideSupportChat(state),
});

export default connect(mapStateToProps)(InfoPopup);
