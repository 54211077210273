import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { FlexColumn, Icon, Close, COLORS } from '@summer/ui-components';

import { ToastComponents } from './ToastComponents';

export const Toast = ({ canClose, onDismissClick, id, bgColor }) => (
  <Notification bgColor={bgColor}>
    {canClose && (
      <div onClick={onDismissClick}>
        <CloseIcon
          width={10}
          height={10}
          SvgComponent={Close}
          fill={COLORS.medGrey}
        />
      </div>
    )}
    <NotificationContent>{ToastComponents[id]}</NotificationContent>
  </Notification>
);

Toast.propTypes = {
  id: PropTypes.string.isRequired,
  onDismissClick: PropTypes.func,
  canClose: PropTypes.bool.isRequired,
  bgColor: PropTypes.string,
};

Toast.defaultProps = {
  bgColor: COLORS.lightestGrey,
};

const Notification = styled(FlexColumn)`
  position: relative;
  max-width: 350px;
  min-height: 91px;
  border-radius: 6px;
  background-color: ${props => props.bgColor};
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.18);
  padding: 20px 16px;
`;

const CloseIcon = styled(Icon)`
  cursor: pointer;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
`;

const NotificationContent = styled(FlexColumn)`
  flex: 1;
  align-items: center;
`;
