import React from 'react';
import styled from 'styled-components';

import { Text, Accordion, COLORS } from '@summer/ui-components';

import Link from 'components/shared/typography/Link';
import { fsaPslfReconLink } from 'constants/externalLinks/fsa';

import { LearnMoreContainer } from './shared';

const learnMorePslfAccordian = [
  {
    title: 'What happens next?',
    content: (
      <>
        <Text paragraph>
          If your loans aren’t there already, they’ll be transferred to MOHELA
          Servicing. You should hear back within about six to eight weeks, but
          it may take longer.
        </Text>
        <br />
        <Text paragraph>
          You’ll receive a count of qualifying payments you’ve made for the
          period covered by the form for each of your loans. For example, if you
          turned in a form covering two years of employment and you met all the
          other qualifications during that time period, you’d be credited with
          24 months out of the required 120 total to receive forgiveness. Every
          time you turn in a new form (we recommend doing it once a year),
          you’ll receive an updated count.
        </Text>
      </>
    ),
  },
  {
    title: 'What if my count doesn’t look right?',
    content: (
      <>
        <Text paragraph>
          What if something doesn’t look right? Unfortunately, it’s common for
          mistakes to be made in the count of your qualifying payments, and the
          Department of Education is still working with servicers to implement
          the recent changes to PSLF and income-driven repayment (IDR). If the
          information you receive doesn’t sound correct, or if it’s taking too
          long to receive a response, you can contact MOHELA Servicing to
          request a re-count of your qualifying payments. If you believe you’re
          ready for forgiveness, you can also{' '}
          <Link inheritColor inheritWeight underline href={fsaPslfReconLink}>
            submit a reconsideration request.
          </Link>
        </Text>
      </>
    ),
  },
];

const LearnMorePslf = props => {
  return (
    <LearnMoreContainer close={props.close}>
      <CopyText>
        <BodyText paragraph>
          <Text color="dark" paragraph>
            You can submit the PSLF form yourself! Here’s how:
          </Text>
          <br />
          <OrderedList>
            <li>
              {' '}
              <Link
                inheritColor
                inheritWeight
                underline
                href="/guides/PSLF_Form_Temporary_Expanded_TEPSLF_vAug2023.pdf"
              >
                Download the form
              </Link>{' '}
              from Federal Student Aid and complete and sign the first page.
            </li>
            <li>
              Send the form to your employer to complete and sign the second
              page. (Usually this is someone in the human resources department.
              It could also be your supervisor.)
            </li>
            <li>
              When the form is completed, send it to MOHELA Servicing - they’re
              the loan servicer that handles Public Service Loan Forgiveness
              (PSLF).
              <br /> <br />
              <OrderedList type="a">
                <li>
                  Mail: MOHELA, <br /> MOHELA Servicing, 633 Spirit Drive <br />{' '}
                  Chesterfield, MO 63005-1243
                </li>
                <li>Fax: 717-720-1628</li>
                <li>
                  Upload: If MOHELA Servicing is already your loan servicer, you
                  can upload it through your online account.
                </li>
              </OrderedList>
            </li>
          </OrderedList>
        </BodyText>
      </CopyText>
      <Accordion config={learnMorePslfAccordian}></Accordion>
    </LearnMoreContainer>
  );
};

const CopyText = styled.div`
  max-width: 724px;
  margin-left: auto;
  margin-right: auto;
`;

const BodyText = styled(Text)`
  font-family: AvenirNext-DemiBold, Avenir, sans-serif;
  color: ${COLORS.blueBlack};
`;

const OrderedList = styled.ol`
  margin: 0 1em;
  li {
    margin-bottom: 10px;
  }
`;

export default LearnMorePslf;
