import React from 'react';

import { TextInput } from '@summer/ui-components';

import { Row } from 'components/pages/idr/wizard/shared/styles';
import { InputWrapper } from 'components/pages/idr/wizard/steps/Submit/shared/Inputs';

const SpouseName = ({
  formik: { values, errors, handleChange, handleBlur, touched, isSubmitting },
}) => (
  <Row>
    <InputWrapper>
      <TextInput
        label="First Name"
        name="submit.spouseFirstName"
        touched={touched.spouseFirstName}
        isSubmitting={isSubmitting}
        error={errors.spouseFirstName}
        value={values.spouseFirstName}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </InputWrapper>
    <InputWrapper>
      <TextInput
        label="Last Name"
        name="submit.spouseLastName"
        touched={touched.spouseLastName}
        isSubmitting={isSubmitting}
        error={errors.spouseLastName}
        value={values.spouseLastName}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </InputWrapper>
  </Row>
);

export default SpouseName;
