import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  Icon,
  SvgImage,
  Accordion,
  Button,
  FancyCard,
  Header,
  CheckList,
  Text,
  BriefcasePlus,
  CheckWatermark,
  ArrowUp,
  COLORS,
} from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { PAYMENT_TERMS_LINK } from 'constants/externalLinks/supportLinks';
import { MobileSize } from 'constants/styleguide';
import { usePlatform } from 'hooks/platform';
import { trackServerSide } from 'redux/actions/analytics.actions';
import { createStripeCheckoutSession } from 'redux/actions/paywall.actions';
import { getPaywallPrice } from 'redux/selectors/flags.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';

import Error from '../Error';

import LearnMoreIdr from './LearnMoreIdr';
import LearnMorePslf from './LearnMorePslf';

const paywallAccordionConfig = paywallPrice => [
  {
    title: 'How will I be charged?',
    content: (
      <Text paragraph>
        Simply enter your payment information on the next page and, once your
        form is submitted, you will be charged ${paywallPrice} for unlimited
        form submissions and support for one year. You will not be automatically
        charged the following year, but you could choose to renew for continued
        recertification assistance.
      </Text>
    ),
  },
  {
    title: 'Can I decide later?',
    content: (
      <Text paragraph>
        Of course! Feel free to come back later if you want some time to make up
        your mind. If you&rsquo;d like to proceed, you can enter your payment
        information and we won&rsquo;t charge you until we&rsquo;ve submitted
        your form.
      </Text>
    ),
  },
  {
    title: 'What if I decide not to submit?',
    content: (
      <Text paragraph>
        You&rsquo;ll only be charged once we submit your form(s) to your loan
        servicer, so if you don&rsquo;t proceed for any reason, we won&rsquo;t
        process the payment.
      </Text>
    ),
  },
  {
    title: 'Why does Summer charge borrowers if I can submit on my own?',
    content: (
      <Text paragraph>
        With Summer&rsquo;s Expert Assistance, you&rsquo;ll receive access to
        our proprietary tools for determining your eligibility and next steps
        for loan repayment and forgiveness programs, as well as the ability to
        submit your forms online with expert review. We&rsquo;ll work with you
        to make sure your forms are ready for acceptance, and also to help you
        push back if your servicer makes mistakes or your application is
        rejected. You&rsquo;ll also receive unlimited access to our team of
        student loan experts, who are ready to help with any questions or
        concerns along the way.
      </Text>
    ),
  },
];

const Paywall = ({
  variant,
  renderConsolidation,
  successUrl,
  cancelUrl,
  isLoading,
  handleClose,
  paywallPrice,
}) => {
  const { isMobile } = usePlatform();
  const [showLearnMore, setShowLearnMore] = React.useState(false);

  const LearnMore = variant === 'pslf' ? LearnMorePslf : LearnMoreIdr;
  const openLearnMore = () => {
    setShowLearnMore(true);
    dispatch(trackServerSide(`User selected DIY from ${variant} paywall`));
  };
  const closeLearnMore = () => setShowLearnMore(false);

  const createStripeCheckoutPayload = {
    success: successUrl,
    cancel: cancelUrl,
  };

  const dispatch = useDispatch();
  const openStripe = () => {
    dispatch(createStripeCheckoutSession(createStripeCheckoutPayload));
    dispatch(
      trackServerSide(
        `User selected premium membership from ${variant} paywall`,
      ),
    );
  };

  useEffect(() => {
    dispatch(trackServerSide(`User opened ${variant} paywall`));
  }, [variant, dispatch]);

  return (
    <>
      {paywallPrice ? (
        <Background>
          {showLearnMore && (
            <LearnMore
              renderConsolidation={renderConsolidation}
              close={closeLearnMore}
            />
          )}
          <PaywallPage>
            <StickyHeader>
              <CloseButton onClick={handleClose} data-testid="paywall-close">
                <BackArrow
                  SvgComponent={ArrowUp}
                  fill={COLORS.medGrey}
                  width={isMobile ? 20 : 22}
                  height={isMobile ? 20 : 22}
                />
              </CloseButton>
            </StickyHeader>
            <Content>
              <PageHeader>
                <Header as={isMobile ? 'h2' : 'h1'} align="center">
                  It looks like you&rsquo;re eligible! Let&rsquo;s submit your
                  forms.
                </Header>
              </PageHeader>
              <SubmitOnlineCard
                CustomLeftHeader={() => (
                  <SubmitOnlineHeader price={paywallPrice} />
                )}
                color="azure"
                backgroundStyle="light"
              >
                <>
                  <CheckList
                    items={[
                      'Our experts check for errors and submit on your behalf – all online!',
                      'Summer helps you get accepted, and sends recertification reminders',
                      'Rest easy with unlimited email and chat support',
                    ]}
                  />
                  <MoneyBackGuarantee />
                  <SubmitSummerContainer>
                    <PaymentTermsCompliance>
                      By submitting your forms with Summer and providing payment
                      information, you agree to our{' '}
                      <Link href={PAYMENT_TERMS_LINK} inheritColor underline>
                        Payment Terms
                      </Link>
                      .
                    </PaymentTermsCompliance>
                    <PaywallButton isLoading={isLoading} onClick={openStripe}>
                      Submit your forms now
                    </PaywallButton>
                  </SubmitSummerContainer>
                </>
              </SubmitOnlineCard>
              <SubmitYourselfCard hideHeader>
                <div>
                  <SubmitYourselfHeader>
                    <Header as={isMobile ? 'h3' : 'h2'}>
                      Submit it yourself
                    </Header>
                  </SubmitYourselfHeader>
                  <SubmitYourself>
                    <Text paragraph>
                      You can choose to submit the form yourself directly to
                      your servicer for free.
                    </Text>
                  </SubmitYourself>
                  <SubmitSelfContainer>
                    <PaywallButton
                      secondary
                      onClick={() => {
                        openLearnMore(variant);
                      }}
                    >
                      Learn more
                    </PaywallButton>
                  </SubmitSelfContainer>
                </div>
              </SubmitYourselfCard>
              <Accordion
                config={paywallAccordionConfig(paywallPrice)}
              ></Accordion>
            </Content>
            <Footer>
              <BackButton onClick={handleClose} secondary>
                Back
              </BackButton>
            </Footer>
          </PaywallPage>
        </Background>
      ) : (
        <PaywallError data-testid="paywall-error">
          <Error />{' '}
        </PaywallError>
      )}
    </>
  );
};

const SubmitOnlineHeader = ({ price }) => {
  const { isMobile } = usePlatform();

  return (
    <>
      <SvgImage height={52} SvgComponent={BriefcasePlus} />
      <HeaderContainer>
        <SubmitOnlineHeaderContainer>
          <Header as={isMobile ? 'h3' : 'h2'}> Submit online in minutes</Header>
        </SubmitOnlineHeaderContainer>
        <Text bold color="summer">
          ${price}
        </Text>
      </HeaderContainer>
    </>
  );
};

const MoneyBackGuarantee = () => (
  <MoneyBackGuranteeContainer>
    <MoneyBackGuranteeHeaderContainer>
      <MoneyBackGuranteeHeader bold size="large" align="center">
        <MoneyBackGuranteeIcon height={72} SvgComponent={CheckWatermark} />
        100% Money Back Guarantee
      </MoneyBackGuranteeHeader>
    </MoneyBackGuranteeHeaderContainer>
    <MoneyBackGuranteeCopy align="center" paragraph small>
      Not completely satisfied with your Summer experience? No worries{' '}
      – we&rsquo;ll refund you!
    </MoneyBackGuranteeCopy>
  </MoneyBackGuranteeContainer>
);

const PaywallError = styled.div``;

const PaywallPage = styled.div`
  padding: 104px 16px 0;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: ${MobileSize}) {
    padding-top: 72px;
  }

  & > * {
    z-index: 10;
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-image: linear-gradient(to bottom, #fff, #eceff1 155%);

  @media (max-width: ${MobileSize}) {
    background-image: unset;
    background: white;
  }
`;

const Content = styled.div`
  flex: 1 0 auto;
  max-width: 724px;
  margin-left: auto;
  margin-right: auto;
`;

const StickyHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  background: white;
`;

const Footer = styled.div`
  padding: 16px;
  margin-left: -16px;
  margin-right: -16px;
  flex-shrink: 0;

  background: ${COLORS.white};

  margin-top: 40px;
`;

const CloseButton = styled.div`
  margin-left: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
  width: 22px;
  height: 22px;
`;

const PageHeader = styled.div`
  margin-bottom: 40px;
  max-width: 424px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${MobileSize}) {
    margin-bottom: 24px;
  }
`;

const SubmitOnlineCard = styled(FancyCard)`
  margin-bottom: 24px;

  @media (max-width: ${MobileSize}) {
    margin-bottom: 16px;
  }
`;

const SubmitYourselfCard = styled(FancyCard)`
  margin-bottom: 32px;
`;

const HeaderContainer = styled.div`
  margin-left: 16px;
`;

const MoneyBackGuranteeContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
`;

const MoneyBackGuranteeHeaderContainer = styled.div`
  margin-bottom: 4px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
`;

const MoneyBackGuranteeHeader = styled(Text)`
  color: ${COLORS.darkGreen};
  position: relative;
  font-family: AvenirNext-DemiBold, Avenir, sans-serif;
`;

const MoneyBackGuranteeIcon = styled(SvgImage)`
  position: absolute;
  left: -40px;
  bottom: -24px;
`;

const MoneyBackGuranteeCopy = styled(Text)`
  font-family: Avenir-Roman, Avenir, sans-serif;
`;

const SubmitYourself = styled.div`
  margin-bottom: 16px;
  letter-spacing: -0.1px;
`;

const BackButton = styled(Button)`
  width: 111px;
`;

const SubmitSummerContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
  }
`;

const SubmitSelfContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const PaywallButton = styled(Button)`
  @media (min-width: ${MobileSize}) {
    width: 220px;
  }
`;

const PaymentTermsCompliance = styled.div`
  text-align: left;
  width: 380px;
  font-size: 14px;
  color: ${COLORS.darkerGrey};

  margin-top: 8px;

  @media (max-width: ${MobileSize}) {
    width: 100%;
    margin-bottom: 16px;
  }
`;

const SubmitYourselfHeader = styled.div`
  padding-bottom: 4px;
`;

const SubmitOnlineHeaderContainer = styled.div`
  padding-bottom: 4px;
`;

const BackArrow = styled(Icon)`
  cursor: pointer;
  transform: rotate(270deg);
  transition: transform 0.3s ease-out;
`;

const mapStateToProps = state => ({
  isLoading: isLoading(state, 'createStripeCheckoutSession'),
  paywallPrice: getPaywallPrice(state),
});

const urlType = PropTypes.shape({
  baseUrl: PropTypes.string.isRequired,
  queryParams: PropTypes.string,
}).isRequired;

Paywall.propTypes = {
  variant: PropTypes.oneOf(['idr', 'pslf']).isRequired,
  renderConsolidation: PropTypes.bool,
  isLoading: PropTypes.bool,
  successUrl: urlType,
  cancelUrl: urlType,
};

export default connect(mapStateToProps)(Paywall);
