import { map } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import {
  Button,
  Header,
  Text,
  SvgImage,
  FlexCenter,
} from '@summer/ui-components';

import ContactUs from 'components/shared/ContactUs';
import Link from 'components/shared/typography/Link';
import { FRESH_START_BLOG_POST_URL } from 'constants/getOutOfDefault';

const LoansInDefault = ({
  getOutOfDefaultBullets,
  ineligibleProgram,
  contactUsSection,
}) => (
  <Container direction="column">
    <img alt="Loans in default" src="/images/idr/loansInDefault.png" />
    <TextContainer>
      <Header center>Let’s get your loans out of default.</Header>
      <Text as="p" paragraph>
        Loans in default are not eligible for {ineligibleProgram}. Don’t
        worry—we’ll help you get them back on track.
      </Text>
      <ul>
        {map(getOutOfDefaultBullets, ({ image, text }) => (
          <ListItem key={text}>
            <ListIcon width={24} SvgComponent={image} />
            <Text paragraph>{text}</Text>
          </ListItem>
        ))}
      </ul>
      <Text as="p" paragraph>
        The Default Fresh Start program is currently underway, and will make it
        easier to get your loans out of default when you take action before the
        end of 2023.
      </Text>
    </TextContainer>
    <Link href={FRESH_START_BLOG_POST_URL}>
      <Button width={224}>Learn More</Button>
    </Link>
    {contactUsSection || <ContactUs />}
  </Container>
);

const Container = styled(FlexCenter)`
  width: 100%;
  max-width: 520px;
  margin: auto;

  & > div {
    margin-bottom: 16px;
  }

  & > img {
    max-width: 100%;
  }

  & > button {
    margin-top: 16px;
  }
`;

const TextContainer = styled.div`
  padding: 0 8px;
  margin-top: 40px;

  & > h1 {
    margin-bottom: 32px;
  }

  & > p {
    max-width: 550px;
    margin: 0 auto 16px auto;
  }

  & > ul {
    list-style-type: none;
  }
`;

const ListIcon = styled(SvgImage)`
  margin-right: 10px;
  margin-bottom: auto;
`;

const ListItem = styled.li`
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;

  ${ListIcon} {
    margin-top: 4px;
  }
`;

export default LoansInDefault;
