import React from 'react';
import styled, { css } from 'styled-components';

import Cleave from 'cleave.js/react';
import { Field } from 'formik';

import InputContainer, { FormInput, FormInputStyle } from '../Input';
import { COLORS } from '../../../helpers/constants/styleguide';
import { FlexRow } from '../../Flex';

class MoneyInput extends React.Component {
  state = {
    initValue:
      this.props.value || this.props.value === 0 ? this.props.value / 100 : '',
    inputWidth: '',
  };

  cleave = null;

  componentDidMount() {
    this.setInputWidth(this.props.value);
  }

  // this code allows formik.resetForm() to work correctly
  componentDidUpdate(prevProps) {
    const { value, setFieldTouched, name } = this.props;
    if (
      prevProps.value !== value &&
      value !== this.cleave.getRawValue() * 100
    ) {
      this.cleave.setRawValue(value || value === 0 ? value / 100 : '');
      setFieldTouched(name, false);
    }
  }

  setInputWidth(value) {
    let newWidth;
    switch (true) {
      case value < 1000:
        newWidth = 25;
        break;
      case value < 10000:
        newWidth = 35;
        break;
      case value < 100000:
        newWidth = 45;
        break;
      case value < 1000000:
        newWidth = 60;
        break;
      default:
        newWidth = 70;
    }

    this.setState({
      inputWidth: newWidth,
    });
  }

  setFormikValue = event => {
    const { setFieldValue, name, setFieldTouched } = this.props;
    if (event.target.rawValue === '') {
      setFieldValue(name, '');
    } else {
      const valueInDollars = event.target.rawValue;
      const valueInCents = Math.round(valueInDollars * 100);
      setFieldValue(name, valueInCents);

      if (this.props.theme === 'noBorder') {
        this.setInputWidth(valueInCents);
      }
    }

    setTimeout(() => setFieldTouched(name));
  };

  renderInputText = (handleFocusChange, focus) => {
    const { error, placeholder, name, onBlur, validate, theme } = this.props;
    return (
      <Container theme={theme}>
        <Symbol focus={focus} error={error} $theme={theme}>
          $
        </Symbol>
        <Field name={name} validate={validate}>
          {() => (
            <CleaveComponent
              id={`${name}-input`}
              inputMode="decimal"
              name={name}
              placeholder={placeholder}
              value={this.state.initValue}
              onInit={cleave => {
                this.cleave = cleave;
              }}
              onChange={this.setFormikValue}
              options={{
                numeral: true,
                numeralPositiveOnly: true,
              }}
              onFocus={handleFocusChange}
              onBlur={e => {
                handleFocusChange();
                onBlur(e);
              }}
              data-testid={`input-text-${name}`}
              error={error}
              $theme={theme}
            />
          )}
        </Field>
      </Container>
    );
  };

  render() {
    const { error, touched, height, isSubmitting, label, theme, name } =
      this.props;
    return (
      <InputContainer
        id={name}
        error={error}
        touched={touched}
        height={height}
        isSubmitting={isSubmitting}
        label={label}
        theme={theme}
        width={theme === 'noBorder' ? this.state.inputWidth : undefined}
      >
        {this.renderInputText}
      </InputContainer>
    );
  }
}

const containerOverride = css`
  ${({ theme }) =>
    theme === 'noBorder' &&
    `
  margin-bottom: 5px;
  justify-content: flex-end;
  `}
`;

const Container = styled(FlexRow)`
  height: 100%;
  align-items: center;
  ${containerOverride}
`;

const CleaveComponent = styled(FormInput.withComponent(Cleave))`
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  caret-color: ${COLORS.darkerGrey};
  -moz-appearance: textfield;
  padding-left: 0;

  &:focus {
    outline: none;
  }
`;

const symbolOverride = css`
  ${({ $theme, error }) =>
    $theme === 'noBorder' &&
    `
  margin-right: 0;
  margin-left: 0;
  color: ${error ? COLORS.orange : COLORS.azure};
  `}
`;

const Symbol = styled(FormInputStyle)`
  margin-left: 16px;
  margin-right: 2px;
  color: ${({ focus }) => (!focus ? COLORS.medGrey : '')};
  ${symbolOverride}
`;

MoneyInput.defaultProps = {
  theme: 'default',
};

export default MoneyInput;
