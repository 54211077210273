import { object, string } from 'yup';

export const newPasswordFieldValidation = error => {
  const defaultError = 'Enter at least 8 characters, both letters and numbers';
  return string()
    .required(error || defaultError)
    .min(8, error || defaultError)
    .matches(/[a-zA-Z]/, error || defaultError)
    .matches(/[0-9]/, error || defaultError);
};

export const passwordFieldValidation = error => {
  return string().required(error);
};

const passwordFieldMatchValidation = () => {
  return string().test(
    'match',
    'Your new password cannot match your old one',
    function isMatch(newPassword) {
      return newPassword !== this.parent.oldPassword;
    },
  );
};

/* eslint-disable */
export const updateEmailValidationSchema = object().shape({
  email: string()
    .validEmail('Please enter a valid email')
    .required('Please enter email'),
  password: passwordFieldValidation('Please enter password.'),
});

export const updatePasswordValidationSchema = object().shape({
  oldPassword: passwordFieldValidation('Please enter old password.'),
  newPassword: string()
    .concat(passwordFieldMatchValidation())
    .concat(newPasswordFieldValidation()),
});

export const updateNameSchema = object().shape({
  firstName: string().required('Please enter your first name'),
  lastName: string().required('Please enter your last name'),
});
