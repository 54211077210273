import React from 'react';
import Select from 'react-select';
import { Field } from 'formik';
import InputContainer from '../Input';
import { COLORS } from '../../../helpers/constants/styleguide';

class SelectInput extends React.Component {
  handleChange = item => {
    const { name } = this.props;

    if (item) {
      this.props.onChange(name, item.value);
    } else {
      this.props.onChange(name, null);
    }
  };

  handleBlur = () => {
    const { name } = this.props;
    this.props.onBlur(name, true);
  };

  renderSelectStyled = () => {
    const {
      placeholder,
      value,
      name,
      options,
      customOnChange,
      isClearable = false,
      menuPlacement = 'auto',
      label,
    } = this.props;

    /* eslint-disable no-use-before-define */
    return (
      <Select
        aria-label={label}
        name={name}
        className="SelectContainer"
        classNamePrefix="Select"
        noOptionsMessage={() => 'Apologies. No results found.'}
        styles={customStyles}
        isClearable={isClearable}
        placeholder={placeholder}
        options={options}
        onChange={customOnChange || this.handleChange}
        onBlur={this.handleBlur}
        value={options.filter(option => option.value === value)}
        menuPlacement={menuPlacement}
      />
    );
    /* eslint-enable no-use-before-define */
  };

  renderSelect = () => {
    const { name, validate, notFormik } = this.props;

    return notFormik ? (
      this.renderSelectStyled()
    ) : (
      <Field name={name} validate={validate}>
        {() => this.renderSelectStyled()}
      </Field>
    );
  };

  render() {
    return (
      <InputContainer noClipping {...this.props}>
        {this.renderSelect}
      </InputContainer>
    );
  }
}

const customStyles = {
  container: provided => ({
    ...provided,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  }),
  menu: provided => ({
    ...provided,
    maxHeight: '200px',
    fontFamily: 'Avenir',
    fontSize: '18px',
    fontWeight: '500',
    color: COLORS.darkGrey,
    border: `solid 1px ${COLORS.grey}`,
    zIndex: 9999,
    borderRadius: '0px 0px 3px 3px',
    backgroundColor: `${COLORS.white}`,
    marginLeft: '-1px',
    marginTop: '-1px',
    width: 'calc(100% + 2px)',
    boxShadow: 'none',
  }),
  menuList: provided => ({
    ...provided,
    maxHeight: '200px',
    padding: '0px',
  }),
  control: (provided, state) => ({
    ...provided,
    'width': '100%',
    'height': '100%',
    'backgroundColor': 'transparent',
    'fontFamily': 'Avenir',
    'fontSize': '20px',
    'fontWeight': '500',
    'color': COLORS.darkerGrey,
    'outline': 'none',

    'borderRadius': '3px',
    'border': 'none',
    'boxShadow': state.isFocused
      ? `0 0 2px 2px ${COLORS.grey}, 
      0 0 0 3px ${COLORS.white}, 
      0px 0px 0px 5px ${COLORS.azure} !important`
      : 'none',
    '&:hover': {
      boxShadow: `0 2px 10px 0 ${COLORS.grey}`,
    },
  }),
  dropdownIndicator: provided => ({
    ...provided,
    caretColor: COLORS.darkerGrey,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    'backgroundColor':
      state.isSelected || state.isFocused ? `${COLORS.darkerGrey}` : 'inherit',
    'color':
      state.isSelected || state.isFocused ? `${COLORS.white}` : 'inherit',
    '&:hover': {
      backgroundColor: `${COLORS.darkerGrey}`,
      color: `${COLORS.white}`,
    },
  }),
};

export default SelectInput;
