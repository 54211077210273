import React from 'react';
import styled from 'styled-components';

import { FlexRow } from '../Flex';
import Icon, { Cross } from '../Icon';
import { Header } from '../typography';

import { COLORS, MobileSize } from '../../helpers/constants/styleguide';

import { hexToRgba } from '../../helpers/utils/common';

const CovidAlert = ({ children, header }) => (
  <Container header={header}>
    {' '}
    <HeaderContainer header={header}>
      <CovidIcon
        SvgComponent={Cross}
        fill={COLORS.covidGreen}
        width={18}
        height={18}
      />
      {!header ? (
        children
      ) : (
        <Header h5 color="covid">
          {header}
        </Header>
      )}
    </HeaderContainer>
    {header && children}
  </Container>
);

const Container = styled.div`
  padding: 16px;
  ${({ header }) => header && `padding-bottom: 16px;`}
  border-radius: 3px;
  border: solid 1px ${hexToRgba(COLORS.covidGreen, 0.2)};
`;

const HeaderContainer = styled(FlexRow)`
  flex: 1 1 auto;
  justify-content: center;
  align-items: flex-start;

  h5 {
    margin-bottom: 8px;
  }

  @media (min-width: ${MobileSize}) {
    justify-content: flex-start;
  }

  svg {
    ${({ header }) => !header && `align-self: flex-start;`}
    margin-right: 8px;
  }
`;

const CovidIcon = styled(Icon)`
  margin-top: 2px;
`;

export default CovidAlert;
