import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { FlexCenter, LoadingBars, COLORS } from '@summer/ui-components';

import CompletedView from 'components/pages/idr/dashboard/CompletedView';
import SuccessModal from 'components/pages/idr/dashboard/CompletedView/SuccessModal';
import HeaderSubTitle from 'components/pages/idr/dashboard/HeaderSubTitle';
import UserStillInSchool from 'components/pages/idr/dashboard/UserStillInSchool';
import IdrStartView from 'components/pages/idr/dashboard/startView/IdrStartView';
import LandingPage from 'components/shared/dashboard/LandingPage';
import Page from 'components/shared/dashboard/Page';
import { fetchIdrForms } from 'redux/actions/idr.actions';
import { fetchLoans } from 'redux/actions/loans.actions';
import { fetchRecommendations } from 'redux/actions/recEngine.actions';
import {
  getCurrentIdrFormStep,
  getNumCompletedIdrForms,
} from 'redux/selectors/idr.selectors';
import { getUserHasCompletedOnboarding } from 'redux/selectors/onboarding.selectors';
import { isLoadingWithInit, getUiSetting } from 'redux/selectors/ui.selectors';
import {
  userIsStillInSchool,
  userWorksInPublicSector,
} from 'redux/selectors/user.selectors';

const IdrRepayment = ({
  userIsStillInSchool,
  userHasPreviousLoans,
  location,
  isLoading,
  numCompletedIdrForms,
  userHasCompletedOnboarding,
}) => {
  const dispatch = useDispatch();

  useEffect(
    function loadRequiredData() {
      if (userHasCompletedOnboarding) {
        dispatch(fetchRecommendations());
      }
      dispatch(fetchLoans());
      dispatch(fetchIdrForms());
    },
    [dispatch, userHasCompletedOnboarding],
  );

  const isIdrCompleted = numCompletedIdrForms >= 1;
  const fromLastStep = location?.state?.origin === '/idr/submit/sign-and-send';

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingBars color={COLORS.azure} />
      </LoadingContainer>
    );
  }

  if (userIsStillInSchool && !userHasPreviousLoans) {
    return (
      <Page>
        <UserStillInSchool />
      </Page>
    );
  }

  return (
    <LandingPage
      headerTheme="magenta"
      headerName="Income-Driven Repayment (IDR)"
      subHeader={<HeaderSubTitle />}
    >
      {isIdrCompleted ? (
        <>
          {fromLastStep && <SuccessModal />}
          <CompletedView />
        </>
      ) : (
        <IdrStartView />
      )}
    </LandingPage>
  );
};

const LoadingContainer = styled(FlexCenter)`
  height: 100%;
`;

const mapStateToProps = state => {
  const userHasCompletedOnboarding = getUserHasCompletedOnboarding(state);
  const isRecEngineLoading = userHasCompletedOnboarding
    ? isLoadingWithInit(state, 'fetchRecommendations')
    : false;

  return {
    currentStep: getCurrentIdrFormStep(state),
    userIsStillInSchool: userIsStillInSchool(state),
    userHasPreviousLoans: getUiSetting(state, 'userHasPreviousLoans'),
    isLoading: isLoadingWithInit(state, 'fetchLoans') || isRecEngineLoading,
    numCompletedIdrForms: getNumCompletedIdrForms(state),
    userWorksInPublicSector: userWorksInPublicSector(state),
    userHasCompletedOnboarding,
  };
};

export default connect(mapStateToProps)(IdrRepayment);
