export const loanConsultationCalendlyLink =
  'https://calendly.com/meetsummer/loan-consultation';

export const helpCenterLink = 'https://summer.zendesk.com/hc/en-us';
export const interestRateHelpArticleUrl =
  'https://summer.zendesk.com/hc/en-us/articles/360058693993';
export const mfaHelpLink =
  'https://summer.zendesk.com/hc/en-us/articles/4715123469843'; // FIXME: this link doesn't work
export const trustLink =
  'https://summer.zendesk.com/hc/en-us/articles/360043036954-Why-should-you-trust-us-';
export const rmDocumentHelpLink =
  'https://summer.zendesk.com/hc/en-us/articles/28078744659475-What-document-s-do-I-need-[…]d-to-verify-my-student-loan-payments-for-a-retirement-match';
