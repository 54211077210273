import { size } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getValueForServicersToSendIDR } from 'redux/selectors/idr.selectors';

export const useSetServicerFields = setFieldValue => {
  const activeLoanServicerNames = useSelector(getValueForServicersToSendIDR);

  useEffect(
    function setServicerFields() {
      const multipleServicers = size(activeLoanServicerNames) > 1;
      setFieldValue('submit.servicersToSendIDR', activeLoanServicerNames);
      setFieldValue('submit.multipleServicers', multipleServicers);
    },
    [setFieldValue, activeLoanServicerNames],
  );
};
