import React from 'react';
import styled from 'styled-components';

import {
  FlexCenter,
  FlexRow,
  Header,
  Text,
  Icon,
  COLORS,
} from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import {
  CONSUMER_PRIVACY_NOTICE_LINK,
  PRIVACY_POLICY_LINK,
  TERMS_OF_USE_LINK,
} from 'constants/externalLinks/supportLinks';
import { MobileSize } from 'constants/styleguide';

export const FillRow = styled.div``;

export const CenterButton = styled.div`
  margin-left: 1.6rem;
`;

export const StyledIcon = styled(Icon)`
  width: 1.6rem;
  height: auto;
  margin-right: 1rem;

  /* need to change color here because it can't be done with 
  * Icon component props due to how this SVG is constructed.
  */
  &.credit-card-icon > svg > g {
    path:first-child {
      stroke: ${COLORS.azure};
    }
    path:last-child {
      fill: ${COLORS.azure};
    }
  }
`;

export const Question = styled(FlexRow)`
  margin-bottom: 1.6rem;
  width: 100%;

  > * {
    &:last-child {
      flex-grow: 1;
    }
  }
`;

export const Background = styled(FlexCenter)`
  padding-top: 24px;
  background-color: ${COLORS.lightestGrey};
  width: 100vw;
  margin-bottom: 1.6rem;

  @media (max-width: ${MobileSize}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const Options = styled(FlexRow)`
  align-items: center;

  div {
    text-align: left;
  }
`;

export const OptionsRow = styled.div`
  & > div > div {
    width: auto;
  }
`;

export const Label = ({ children, isFieldOptional }) => (
  <LabelContainer>
    <Text as="span" size="large" bold>
      {children}
    </Text>
    {isFieldOptional && (
      <Text as="span" size="small" color="secondary">
        Optional
      </Text>
    )}
  </LabelContainer>
);

const LabelContainer = styled.div`
  margin-bottom: 16px;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  @media (max-width: ${MobileSize}) {
    width: 100%;

    & > *:not(:last-child) {
      margin-right: 6px;
    }
  }
`;

export const Form = styled(FlexCenter.withComponent('form'))`
  flex-direction: column;
  width: 100%;
  max-width: 550px;
`;

export const OnboardingPageTitle = ({ children }) => (
  <OnboardingPageTitleContainer>
    <Header h1 center>
      {children}
    </Header>
  </OnboardingPageTitleContainer>
);

const OnboardingPageTitleContainer = styled.div`
  margin-bottom: 48px;
`;

export const OnboardingContainer = styled(FlexCenter)`
  flex-shrink: 0;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 600px;
  padding: 0 16px;
`;

export const BackLink = ({ onClick, children }) => (
  <BackLinkContainer>
    <Text color="secondary">
      <Link inheritColor underline onClick={onClick}>
        {children}
      </Link>
    </Text>
  </BackLinkContainer>
);

const BackLinkContainer = styled.div`
  margin: 20px 0;
`;

export const Disclaimer = ({ left }) => (
  <Text center={!left} small color="secondary" paragraph>
    By signing up, you agree to our{' '}
    <Link inheritColor underline href={TERMS_OF_USE_LINK}>
      Terms of Use
    </Link>
    ,{' '}
    <Link inheritColor underline href={PRIVACY_POLICY_LINK}>
      Privacy Policy
    </Link>
    , and{' '}
    <Link inheritColor underline href={CONSUMER_PRIVACY_NOTICE_LINK}>
      GLBA Privacy Notice
    </Link>
    .
  </Text>
);
