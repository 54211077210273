import { push as redirectTo } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  Header,
  Text,
  Button,
  FlexColumn,
  COLORS,
} from '@summer/ui-components';

import { TabletSize } from 'constants/styleguide';
import { usePlatform } from 'hooks/platform';

const OnboardingRec = () => {
  const dispatch = useDispatch();
  const { isMobile } = usePlatform();

  return (
    <ContentContainer>
      <SummerRecommendsImageContainer>
        <SummerRecommendsImage
          src="/images/summer-recommends.png"
          alt="Example of recommendations you will receive"
        />
      </SummerRecommendsImageContainer>
      <TextContainer>
        <Header h2>
          Summer specializes in finding all the ways to reduce your student debt
        </Header>
        <Text paragraph size={isMobile ? 'small' : 'medium'}>
          Answer some basic questions about your existing student loans along
          with some basic household information and Summer will provide you with
          personalized recommendations.
        </Text>
        <ButtonContainer>
          <Button
            fullWidth
            onClick={() => {
              dispatch(redirectTo('/onboard/questions'));
            }}
          >
            Continue
          </Button>
        </ButtonContainer>
      </TextContainer>
    </ContentContainer>
  );
};

const OnboardingRecCard = () => {
  return (
    <Card>
      <OnboardingRec />
    </Card>
  );
};

const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: inherit;
  align-items: center;
  box-shadow: 0 2px 4px 0 #e7ebf1;
  background-color: ${COLORS.white};
  padding: 24px 24px 40px;
  border-radius: 6px;
  max-width: 1080px;
  margin: 0 auto;
`;

const ContentContainer = styled(FlexColumn)`
  gap: 16px;

  @media (min-width: ${TabletSize}) {
    flex-direction: row;
    gap: 56px;
    padding: 32px;
  }
`;

const SummerRecommendsImageContainer = styled.div`
  align-self: center;
`;

const SummerRecommendsImage = styled.img`
  align-self: center;
  max-width: 100%;
`;

const TextContainer = styled(FlexColumn)`
  margin-right: 8px;
  align-items: left;
  gap: 24px;
  flex: 1;
  min-width: 225px;
`;

const ButtonContainer = styled.div`
  text-align: right;
  align-self: flex-end;
  width: 100%;
  margin-top: auto;

  @media (min-width: ${TabletSize}) {
    width: 144px;
  }
`;

export default OnboardingRecCard;
