export const PaymentFreezeExpirationCopy = 'in October 2023';

export const fieldsOnManageLoansIdr = [
  'id',
  'amountBorrowed',
  'disbursementDate',
  'disbursementDateId',
  'educationLevel',
  'federalLoanTypeId',
  'idrRecertificationDate',
  'includesParentPlus',
  'interestRate',
  'loanPayoffDate',
  'outstandingInterestBalance',
  'principalBalance',
  'repaymentPlanTypeId',
  'servicerId',
];

export const fieldsOnManageLoansPslf = [
  'id',
  'amountBorrowed',
  'disbursementDate',
  'disbursementDateId',
  'federalLoanTypeId',
  'principalBalance',
  'servicerId',
];

export const fieldsOnPaidOffLoans = [
  'federalLoanTypeId',
  'disbursementDate',
  'principalBalance',
  'includesParentPlus',
  'loanPayoffDate',
];
