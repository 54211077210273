import { datadogRum } from '@datadog/browser-rum';

import { isDevEnv } from 'utils/common';

export const setupDatadog = () => {
  const applicationId = process.env.REACT_APP_DD_BROWSER_RUM_APPLICATION_ID;
  const clientToken = process.env.REACT_APP_DD_BROWSER_RUM_CLIENT_TOKEN;
  const env = process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV;
  const service = `rum-web-app-${env}`;

  if (!isDevEnv()) {
    datadogRum.init({
      applicationId,
      clientToken,
      site: 'datadoghq.com',
      service,
      env,
      sampleRate: 100,
      // The trackInteractions parameter enables the automatic collection of
      // user clicks in your application. Sensitive and private data contained
      // on your pages may be included to identify the elements interacted with
      trackInteractions: false,
      allowedTracingOrigins: [
        'https://api-staging.meetsummer.org',
        'https://api.meetsummer.org',
        'https://api-sandbox.meetsummer.org',
      ],
    });
  }
};

export const setDatadogUser = user => {
  datadogRum.setUser({
    id: user.id,
    email: user.email,
  });
};

export const setDatadogContext = (key, value) => {
  datadogRum.addRumGlobalContext(key, value);
};
