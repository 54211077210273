import styled from 'styled-components';

import {
  xPaddingMobile,
  xPaddingDesktop,
  topPaddingDesktop,
  topPaddingMobile,
} from 'components/layout/wizard/WizardContent';
import { TabletSize } from 'constants/styleguide';

const DefaultWizardContent = styled.div`
  margin-top: -${topPaddingDesktop};
  margin-left: -${xPaddingDesktop};
  margin-right: -${xPaddingDesktop};

  @media (max-width: ${TabletSize}) {
    margin-top: -${topPaddingMobile};
    margin-left: -${xPaddingMobile};
    margin-right: -${xPaddingMobile};
    margin-bottom: -38px;
  }
`;

export default DefaultWizardContent;
