import { SET_SERVICERS } from 'constants/actionTypes';

const initialState = [];

const servicersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SERVICERS:
      return payload;

    default:
      return state;
  }
};

export default servicersReducer;
