import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  BackgroundSvg,
  Container,
  Icon,
  ResponsiveColumn,
  Row,
  COLORS,
} from '@summer/ui-components';

import SecurityInfo from 'components/common/SyncLoans/ConnectFsa/SecurityInfo';
import { PlaidOutageAlert } from 'components/common/SyncLoans/PlaidOutageAlert';
import { MobileSize, TabletSize } from 'constants/styleguide';
import { hasFederalPlaidLoans } from 'redux/selectors/plaid.selectors';
import { getIsPlaidOutage } from 'redux/selectors/user.selectors';
import { hexToRgba } from 'utils/common';

import PlaidSyncBody from './PlaidSyncBody';
import PlaidSyncHeader from './PlaidSyncHeader';

const PlaidSyncPage = ({ openPlaid, isPlaidOutage }) => {
  return (
    <>
      <View>
        <ViewTopSection>
          <BackgroundRowContainer>
            <BackgroundShape
              fill={hexToRgba(COLORS.jade, 0.16)}
              rotate={-52}
              SvgComponent={BackgroundSvg}
            />

            <Container fullWidth={true}>
              <Row center>
                <ResponsiveColumn desktopCol={10} mobileCol={12} tabletCol={12}>
                  <PlaidSyncHeader handleCta={openPlaid} />
                </ResponsiveColumn>
              </Row>
            </Container>
          </BackgroundRowContainer>

          <Container>
            <Row center>
              <ResponsiveColumn desktopCol={10} mobileCol={12} tabletCol={12}>
                {isPlaidOutage && <StyledPlaidOutageAlert />}
                <PlaidSyncBody />
              </ResponsiveColumn>
            </Row>
          </Container>
        </ViewTopSection>
        <SecurityInfo pageHasFooter={true} />
      </View>
    </>
  );
};

const StyledPlaidOutageAlert = styled(PlaidOutageAlert)`
  margin-top: 24px;
`;

const View = styled.div`
  margin: -32px;

  @media (max-width: ${MobileSize}) {
    margin: -16px;
  }
`;

const ViewTopSection = styled.div`
  min-height: calc(100vh - 306px);

  @media (max-height: ${TabletSize}) {
    min-height: initial;
  }
`;

const BackgroundRowContainer = styled.div`
  position: relative;
  background-color: ${hexToRgba(COLORS.jade, 0.12)};
  width: 100%;
  top: 0;
  left: 0;
  padding: 24px 0;
  overflow: hidden;

  @media (max-width: ${MobileSize}) {
    padding: 24px;
  }
`;

const BackgroundShape = styled(Icon)`
  position: absolute;
  top: 0;
  left: 34%;
  transform: ${({ rotate }) => `rotate(${rotate}deg)`} scale(48);
  z-index: 1;
  opacity: 50%;

  @media (max-width: ${MobileSize}) {
    transform: ${({ rotate }) => `rotate(${rotate}deg)`} scale(30);
  }
`;

const mapStateToProps = state => ({
  hasFederalLoans: hasFederalPlaidLoans(state),
  isPlaidOutage: getIsPlaidOutage(state),
});

export default connect(mapStateToProps)(PlaidSyncPage);
