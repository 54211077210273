import { replace } from 'lodash';

const pathUuidMatcher = new RegExp(
  `/+[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}`,
  'g',
);

const signatureIdMatcher = new RegExp(`/+[0-9a-fA-F]{32}\\b`, 'g');

const pathIdMatcher = new RegExp(`/+\\d+`, 'g');

export const parsePathForIdentifiers = path => {
  const pathParsedForUuids = replace(path, pathUuidMatcher, '/{uuid}');
  const pathParsedForSignatures = replace(
    pathParsedForUuids,
    signatureIdMatcher,
    '/{signatureId}',
  );
  const pathParsedForIds = replace(
    pathParsedForSignatures,
    pathIdMatcher,
    '/{id}',
  );

  return pathParsedForIds;
};
