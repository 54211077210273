import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import {
  AmountBorrowed,
  DisbursementDate,
  FederalLoanType,
  LoanStatusHistory,
  PrincipalBalance,
  RepaymentPlanTypePslf,
  Servicer,
} from 'components/common/ManageLoans/fields';
import Section from 'components/common/ManageLoans/shared/Section';
import { getLoanTypeById } from 'redux/selectors/loans.selectors';
import { loanIsActive, getPslfLoanTypeHelperText } from 'utils/loans';

const LoansFormFieldsPslf = ({
  formik,
  formik: {
    values: { principalBalance },
  },
  servicerName,
  selectedLoanType,
}) => {
  const loanIsNotPaidOff = loanIsActive(principalBalance);

  const helperText = useMemo(
    () => getPslfLoanTypeHelperText(selectedLoanType),
    [selectedLoanType],
  );

  return (
    <>
      <Section>
        {loanIsNotPaidOff ? null : <div />}
        {loanIsNotPaidOff && (
          <Servicer formik={formik} servicerName={servicerName} />
        )}
        <DisbursementDate formik={formik} />
      </Section>

      <Section>
        <FederalLoanType formik={formik} helperText={helperText} />
        <PrincipalBalance formik={formik} />
      </Section>
      {loanIsNotPaidOff && (
        <Section>
          <AmountBorrowed formik={formik} />
          <div />
        </Section>
      )}
      <Section>
        <LoanStatusHistory />
        <RepaymentPlanTypePslf />
      </Section>
    </>
  );
};

const mapStateToProps = (state, props) => {
  const loanTypeId = props.formik.values.federalLoanTypeId;

  return {
    selectedLoanType: getLoanTypeById(state, loanTypeId),
  };
};

export default connect(mapStateToProps)(LoansFormFieldsPslf);
