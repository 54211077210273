import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Paywall from 'components/pages/paywall';
import { saveIdrCurrentStep } from 'redux/actions/idr.actions';
import { selectedPathIncludesConsolidation } from 'redux/selectors/idr.selectors';

const PaywallIdr = () => {
  const dispatch = useDispatch();
  const includesConsolidation = useSelector(selectedPathIncludesConsolidation);

  const baseUrl = window.location.origin;

  const paywallSuccessBaseUrl = `${baseUrl}/paywall/success/idr`;
  const paywallCancelBaseUrl = `${baseUrl}/idr/submit/contact-info`;

  return (
    <Paywall
      variant="idr"
      renderConsolidation={includesConsolidation}
      handleClose={() => dispatch(saveIdrCurrentStep(3))}
      successUrl={{
        baseUrl: paywallSuccessBaseUrl,
      }}
      cancelUrl={{
        baseUrl: paywallCancelBaseUrl,
      }}
    />
  );
};

export default PaywallIdr;
