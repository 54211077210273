export const occupations = [
  'Air Force',
  'Army',
  'Attorney',
  'Dentist',
  'Farmer',
  'Firefighter',
  'Health Professional',
  'Law enforcement',
  'Lawyer',
  'Librarian',
  'Military',
  'Nurse',
  'Nurse Educator',
  'Peace Corps',
  'Physician/Doctor',
  'STEM Professional',
  'Student',
  'Teacher',
  'Veterinarian',
].sort();

export const loanType = ['Federal', 'Private', 'Both'];
