/**
 * This is a wrapper function recommended by MDN to encode URIs, including
 * characters that encodeURIComponent does not include such as apostrophes.
 * encodeURIComponent is a function that browsers support.
 *
 * Ex. O'caml => O%27caml
 *
 * @param {inputString} - A string param
 * @return {string} URI encoded string with certain safe characters encoded in addition
 * to what encodeURIComponent supports
 *
 */

export const fixedEncodeURIComponent = inputString => {
  return encodeURIComponent(inputString).replace(/[!'()*]/g, reservedChar => {
    return `%${reservedChar.charCodeAt(0).toString(16)}`;
  });
};
