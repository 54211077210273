import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Header, Text } from '@summer/ui-components';

import Content from 'components/pages/pslf/wizard/layout/PslfWizardContent';
import Footer from 'components/pages/pslf/wizard/layout/PslfWizardFooter';
import InfoTooltip from 'components/shared/InfoTooltip';
import { MobileSize } from 'constants/styleguide';
import { useOnSuccess } from 'hooks/pslf';
import { updateCurrentPslfForm } from 'redux/actions/pslf.actions';
import { getUserCurrentPslfForm } from 'redux/selectors/pslf.selectors';
import { employerDatesSchema } from 'schemas/pslf';

import EmployerEndDateQuestion from './EmployerEndDateQuestion';
import EmployerStartDateQuestion from './EmployerStartDateQuestion';

const EmployerDates = ({
  currentPslfForm,
  updateCurrentPslfForm,
  navigation,
}) => {
  useOnSuccess('updateCurrentPslfForm', navigation.next);

  const handleSave = ({ employerStartDate, employerEndDate }) => {
    const { formId } = currentPslfForm;
    const valuesToSave = {
      formId,
      employerStartDate,
      employerEndDate,
    };

    if (!valuesToSave.employerEndDate) {
      valuesToSave.employerEndDate = null;
    }

    updateCurrentPslfForm({ form: valuesToSave });
  };

  const { employerStartDate, employerEndDate } = currentPslfForm;

  return (
    <Formik
      enableReinitialize
      initialValues={{ employerStartDate, employerEndDate }}
      onSubmit={handleSave}
      validationSchema={employerDatesSchema}
    >
      {formik => {
        const { handleSubmit, isValid, submitCount } = formik;
        return (
          <Content navigation={navigation}>
            <form onSubmit={handleSubmit}>
              <Container>
                <PslfQuestionHeader>
                  <Header as="h2">
                    When did you work for this employer?
                    <InfoTooltip
                      text={
                        <>
                          This is required for the PSLF form and will be
                          verified by your employer. Try your best to be as
                          accurate as possible.
                        </>
                      }
                    />
                  </Header>
                </PslfQuestionHeader>
                <PslfQuestionText>
                  <Text paragraph>
                    We&rsquo;ll verify these dates with your employer, and they
                    will add this information into the form.
                  </Text>
                </PslfQuestionText>
                <PslfQuestionText>
                  <Text paragraph>
                    If you&rsquo;ve worked for this employer during different
                    time periods, fill out new certification forms for each
                    period.
                  </Text>
                </PslfQuestionText>
                <EmployerDatesContainer>
                  <StartDateContainer>
                    <EmployerStartDateQuestion formik={formik} />
                  </StartDateContainer>
                  <EndDateContainer>
                    <EmployerEndDateQuestion
                      formik={formik}
                      currentPslfForm={currentPslfForm}
                    />
                  </EndDateContainer>
                </EmployerDatesContainer>
              </Container>
              <Footer
                handleBack={navigation.back}
                isValid={isValid}
                submitCount={submitCount}
              />
            </form>
          </Content>
        );
      }}
    </Formik>
  );
};

const PslfQuestionHeader = styled.div`
  margin-bottom: 16px;
`;

const PslfQuestionText = styled.div`
  margin-bottom: 24px;
`;

const Container = styled.div`
  max-width: 464px;
  margin: auto;
`;

const EmployerDatesContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${MobileSize}) {
    flex-direction: column;
  }
`;

const StartDateContainer = styled.div`
  width: 100%;
  margin-right: 24px;
`;

const EndDateContainer = styled.div`
  width: 100%;
  align-items: left;
`;

const mapStateToProps = state => ({
  currentPslfForm: getUserCurrentPslfForm(state),
});

export default connect(mapStateToProps, { updateCurrentPslfForm })(
  EmployerDates,
);
