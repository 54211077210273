import styled from 'styled-components';

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: ${props => (props.reverse ? 'column-reverse' : 'column')};
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${props => props.direction || 'row'};
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-Between;
  align-items: center;
  flex-direction: ${props => props.direction || 'row'};
`;

export const FlexAround = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: ${props => props.direction || 'row'};
`;
