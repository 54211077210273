import { goBack, push as redirectTo } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Header,
  FlexColumn,
  GraduationCap,
  CloseModal,
  Close,
  Icon,
  Button,
  COLORS,
} from '@summer/ui-components';

import Sidebar from 'components/layout/Guide/Sidebar';
import { SCROLL_OFFSET, TabletSize } from 'constants/styleguide';

const context = React.createContext();
const { Provider } = context;

class Guide extends React.Component {
  state = {
    sections: [],
    activeSection: null,
    progress: 0,
  };

  addSection = section => {
    this.setState(state => ({
      sections: [...state.sections, section],
      activeSection: state.activeSection || section.title,
    }));
  };

  handleSideBarClick = element => {
    this.content.scroll({
      top: element.offsetTop - SCROLL_OFFSET,
      left: 0,
      behavior: 'smooth',
    });
  };

  setActiveSection = activeSection => {
    const { sections } = this.state;

    const progress =
      ((sections.findIndex(section => section.title === activeSection) + 1) /
        sections.length) *
      100;

    this.setState(() => ({ activeSection, progress }));
  };

  closeGuide = () => {
    const { location } = this.props;
    const { goBack, redirectTo } = this.props;
    if (location.state && location.state.goBack) {
      goBack();
    } else {
      redirectTo('/guides');
    }
  };

  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        sections: [],
        activeSection: null,
        progress: 0,
      });
    }
  }

  render() {
    return (
      <Provider
        value={{
          ...this.state,
          addSection: this.addSection,
          setActiveSection: this.setActiveSection,
        }}
      >
        <Container>
          <CloseIcon onClick={this.closeGuide}>
            <DesktopClose>
              <Icon
                SvgComponent={CloseModal}
                stroke={COLORS.medGrey}
                style={{ cursor: 'pointer' }}
              />
            </DesktopClose>
            <MobileClose>
              <Icon SvgComponent={Close} />
            </MobileClose>
          </CloseIcon>
          <Sidebar
            {...this.state}
            title={this.props.title}
            handleClick={this.handleSideBarClick}
          />
          <Content
            ref={ref => {
              this.content = ref;
            }}
          >
            <HeaderContainer>
              <Icon SvgComponent={GraduationCap} stroke={COLORS.azure} />
              <Title>
                <Header h2>{this.props.title}</Header>
              </Title>
            </HeaderContainer>
            {this.props.children}

            <div>
              <Button onClick={this.closeGuide}>Complete</Button>
            </div>
          </Content>
        </Container>
      </Provider>
    );
  }
}

const HeaderContainer = styled.div`
  margin-bottom: 24px;

  @media (max-width: ${TabletSize}) {
    display: none;
  }
`;

const MobileClose = styled.div`
  display: none;
  z-index: 2;
  @media (max-width: ${TabletSize}) {
    display: block;
  }
`;

const DesktopClose = styled.div`
  @media (max-width: ${TabletSize}) {
    display: none;
  }
`;

const CloseIcon = styled.div`
  position: fixed;
  right: 40px;
  top: 40px;
  cursor: pointer;
  z-index: 2;

  @media (max-width: ${TabletSize}) {
    height: 16px;
    width: 16px;
    top: 16px;
    right: 16px;
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1 0 auto;
  height: 100%;

  @media (max-width: ${TabletSize}) {
    width: 100%;
  }
`;

const Content = styled(FlexColumn)`
  padding-top: ${SCROLL_OFFSET}px;
  margin-top: 24px;
  flex: 1 0 auto;
  overflow: scroll;
  padding-bottom: ${SCROLL_OFFSET}px;
  align-items: center;

  @media (max-width: ${TabletSize}) {
    width: 100%;
  }
`;

const Title = styled.div`
  margin-top: 16px;
`;

export const { Consumer } = context;

export default connect(null, { goBack, redirectTo })(Guide);
