import { get } from 'lodash';
import { createSelector } from 'reselect';

const getPendingState = state => state.recEngine.pending;
const getCurrentState = state => state.recEngine.current;

export const getIdrCurrentState = createSelector(
  getCurrentState,
  current => current?.repaymentPlan,
);

export const getIdrIsCurrent = createSelector(
  getIdrCurrentState,
  repaymentPlan => repaymentPlan?.showCurrent,
);

export const getIdrPendingState = createSelector(
  getPendingState,
  pending => pending?.idr,
);

export const getIdrIsPending = createSelector(
  getIdrPendingState,
  idr => idr?.isPending,
);

export const getPslfPendingState = createSelector(
  getPendingState,
  pending => pending?.pslf,
);

export const getPslfIsPending = createSelector(getPslfPendingState, pslf => {
  return pslf?.isPending;
});

export const getPslfCurrentState = createSelector(
  getCurrentState,
  current => current?.pslf,
);

export const getPslfForgivenessDate = createSelector(
  getPslfCurrentState,
  pslf => pslf?.forgivenessDate,
);

export const getIdrRecommendation = state =>
  state.recEngine.recommended.idr.isRecommended;

export const getPslfRecommendation = state =>
  state.recEngine.recommended.pslf.isRecommended;

export const getRefiFederalLoansRecommendation = state =>
  state.recEngine.recommended.refi.federalRecommended;

export const getRefiPrivateLoansRecommendation = state =>
  state.recEngine.recommended.refi.privateRecommended;

export const getRefiRecommendation = createSelector(
  getRefiFederalLoansRecommendation,
  getRefiPrivateLoansRecommendation,
  (federalRecommended, privateRecommended) =>
    federalRecommended || privateRecommended,
);

export const getRefiLowestMonthlyPaymentValues = state =>
  get(state, 'recEngine.recommended.refi.lowestMonthlyPayment', {});

export const getRefiLowestTotalPaidValues = state =>
  get(state, 'recEngine.recommended.refi.lowestTotalPaid', {});

export const getRefiLowestMonthlyPayment = createSelector(
  getRefiLowestMonthlyPaymentValues,
  refiLowestMonthlyPaymentValues =>
    get(refiLowestMonthlyPaymentValues, 'monthlyPayment', null),
);
export const getOverpaymentsRecommendation = state =>
  state.recEngine.recommended.overpayments.isRecommended;

export const getEstIdrMonthlyPayment = state =>
  state.recEngine.recommended.idr.estIdrMonthlyPayment ||
  state.recEngine.recommended?.getOutOfDefault?.estIdrMonthlyPayment;

export const getGetOutOfDefaultRecommendation = state =>
  state.recEngine.recommended?.getOutOfDefault?.isRecommended;

export const getForgivenessFinderRecommendation = state =>
  state.currentUser.federalLoansEstimate > 0 ||
  state.currentUser.privateLoansEstimate > 0;

export const getAmountForgiven = state =>
  get(state, 'recEngine.pending.pslf.amountForgiven', 0);

export const getEstimatedForgivenessDate = state =>
  get(state, 'recEngine.current.pslf.forgivenessDate', null);

export const getBestDefaultOption = state =>
  state.recEngine.recommended?.getOutOfDefault?.bestOption;

export const getEstRehabMonthlyPayment = state =>
  state.recEngine.recommended?.getOutOfDefault?.estRehabMonthlyPayment;

export const checkUserEnrolledInIDR = state =>
  state.recEngine.recommended?.idr?.alreadyInIdr;

const getAllRecommendations = createSelector(
  getOverpaymentsRecommendation,
  getIdrRecommendation,
  getRefiRecommendation,
  getPslfRecommendation,
  getGetOutOfDefaultRecommendation,
  (
    overpaymentsRecommendation,
    idrRecommendation,
    refiRecommendation,
    pslfRecommendation,
    defaultRecommendation,
  ) => ({
    idrRecommendation,
    refiRecommendation,
    pslfRecommendation,
    defaultRecommendation,
  }),
);

export const onlyIdrRecommendation = createSelector(
  getAllRecommendations,
  ({
    overpaymentsRecommendation,
    idrRecommendation,
    refiRecommendation,
    pslfRecommendation,
    defaultRecommendation,
  }) => {
    return (
      idrRecommendation &&
      !overpaymentsRecommendation &&
      !refiRecommendation &&
      !pslfRecommendation &&
      !defaultRecommendation
    );
  },
);

export const onlyRefiRecommendation = createSelector(
  getAllRecommendations,
  ({
    overpaymentsRecommendation,
    idrRecommendation,
    refiRecommendation,
    pslfRecommendation,
    defaultRecommendation,
  }) => {
    return (
      refiRecommendation &&
      !idrRecommendation &&
      !overpaymentsRecommendation &&
      !pslfRecommendation &&
      !defaultRecommendation
    );
  },
);

export const onlyDefaultRecommendation = createSelector(
  getAllRecommendations,
  ({
    overpaymentsRecommendation,
    idrRecommendation,
    refiRecommendation,
    pslfRecommendation,
    defaultRecommendation,
  }) =>
    !refiRecommendation &&
    !idrRecommendation &&
    !overpaymentsRecommendation &&
    !pslfRecommendation &&
    defaultRecommendation,
);

export const onlyPslfAndIdrRecommendation = createSelector(
  getAllRecommendations,
  checkUserEnrolledInIDR,
  (
    {
      overpaymentsRecommendation,
      idrRecommendation,
      refiRecommendation,
      pslfRecommendation,
      defaultRecommendation,
    },
    userEnrolledInIDR,
  ) => {
    return (
      !userEnrolledInIDR &&
      idrRecommendation &&
      pslfRecommendation &&
      !overpaymentsRecommendation &&
      !refiRecommendation &&
      !defaultRecommendation
    );
  },
);
