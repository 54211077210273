/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import EmployerDates from 'components/pages/pslf/wizard/steps/yourEmployment/EmployerDates';
import EmployerHours from 'components/pages/pslf/wizard/steps/yourEmployment/EmployerHours';
import EmployerName from 'components/pages/pslf/wizard/steps/yourEmployment/EmployerName';
import EmployerNonProfitType from 'components/pages/pslf/wizard/steps/yourEmployment/EmployerNonProfitType';
import EmployerPrimaryPurpose from 'components/pages/pslf/wizard/steps/yourEmployment/EmployerPrimaryPurpose';
import EmployerType from 'components/pages/pslf/wizard/steps/yourEmployment/EmployerType';
import PslfReadiness from 'components/pages/pslf/wizard/steps/yourEmployment/PslfReadiness';
import { NON_PROFIT_OTHER } from 'constants/pslf';
import useTrackStepChanges from 'hooks/Wizard/useTrackStepChanges';
import { useWizard } from 'hooks/Wizard/useWizard';
import { renderWizardSteps } from 'hooks/Wizard/utils';
import { getEmployerType } from 'redux/selectors/pslf.selectors';

const YourEmploymentWizard = ({
  navigation: parentNavigation,
  employerType,
  parentProgress,
}) => {
  const config = useMemo(
    () => [
      {
        title: 'Employer Name',
        render: props => <EmployerName {...props} />,
      },
      {
        title: 'Employer Type',
        render: props => <EmployerType {...props} />,
      },
      {
        title: 'Employer Non-Profit Type',
        render: props => <EmployerNonProfitType {...props} />,
        condition: employerType === NON_PROFIT_OTHER,
      },
      {
        title: 'Employer Primary Purpose',
        render: props => <EmployerPrimaryPurpose {...props} />,
        condition: employerType === NON_PROFIT_OTHER,
      },
      {
        title: 'Employer Dates',
        render: props => <EmployerDates {...props} />,
      },
      {
        title: 'Employer Hours',
        render: props => <EmployerHours {...props} />,
      },
      {
        title: 'Pslf Readiness',
        render: props => <PslfReadiness {...props} />,
      },
    ],
    [employerType],
  );

  const { steps } = useWizard(
    {
      config,
      parentNavigation,
      parentProgress,
    },
    useTrackStepChanges,
  );

  return renderWizardSteps(steps);
};

const mapStateToProps = state => ({
  employerType: getEmployerType(state),
});

export default connect(mapStateToProps)(YourEmploymentWizard);
