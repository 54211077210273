import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Button,
  Header,
  IconMedallion,
  SyncInfinity,
  Text,
  COLORS,
} from '@summer/ui-components';

import { TabletSize } from 'constants/styleguide';
import { getIsPlaidOutage } from 'redux/selectors/user.selectors';

const PlaidSyncHeader = ({ handleCta, isPlaidOutage }) => {
  return (
    <HeaderContainer>
      <HeaderBody>
        <Title>
          <InfinitySymbolImage
            size={43}
            SvgComponent={SyncInfinity}
            color={COLORS.jade}
          />
          <Header h2>Let’s sync your federal loans</Header>
        </Title>

        <Text size="large" paragraph>
          Syncing your loans with your servicers makes it easier for us to lower
          your monthly payments.
        </Text>
      </HeaderBody>
      <Button width={192} onClick={handleCta} disabled={isPlaidOutage}>
        Connect now
      </Button>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: 32px 0;
  z-index: 2;

  button {
    align-self: flex-end;
  }

  @media (max-width: ${TabletSize}) {
    flex-direction: column;
    text-align: center;
    align-items: center;

    button {
      width: 100%;
      margin-top: 16px;
    }
  }
`;

const HeaderBody = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 426px;
`;

const InfinitySymbolImage = styled(IconMedallion)`
  margin-right: 16px;
  @media (max-width: ${TabletSize}) {
    margin-right: 0;
    margin-bottom: 16px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @media (max-width: ${TabletSize}) {
    flex-direction: column;
  }
`;

const mapStateToProps = state => ({
  isPlaidOutage: getIsPlaidOutage(state),
});

export default connect(mapStateToProps)(PlaidSyncHeader);
