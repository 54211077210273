import React from 'react';
import styled from 'styled-components';

import HelpFooter from 'components/pages/retirementMatch/shared/helpFooter';
import LandingPage from 'components/shared/dashboard/LandingPage';
import { MobileSize } from 'constants/styleguide';

const RetirementMatchLandingPage = ({ children }) => {
  return (
    <LandingPage headerTheme="jade" headerName="Student Loan Retirement Match">
      <ContentContainer>
        <ContentWrapper>
          <CardWrapper>{children}</CardWrapper>
          <HelpFooter />
        </ContentWrapper>
      </ContentContainer>
    </LandingPage>
  );
};

const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  margin: 16px 16px 36px;
  @media (min-width: ${MobileSize}) {
    margin-top: 48px;
  }

  max-width: 896px;
  //necessary to make card background opaque
  position: relative;
  z-index: 1;
`;

const CardWrapper = styled.div`
  margin-bottom: 36px;
  @media (min-width: ${MobileSize}) {
    margin-bottom: 72px;
  }
`;

export default RetirementMatchLandingPage;
