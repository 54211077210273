import { format, parseISO } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';

import { Text } from '@summer/ui-components';

import { getProfileUpdatedAt } from 'redux/selectors/profile.selectors';
import { disallowBreaks } from 'utils/text';

const LastUpdated = ({ lastUpdated }) => {
  const lastUpdatedDate =
    lastUpdated && disallowBreaks(format(parseISO(lastUpdated), 'MMM d, yyyy'));

  return (
    <Text align="right" color="secondary" size="extraSmall" weight="medium">
      {lastUpdated && `Last updated ${lastUpdatedDate}`}{' '}
    </Text>
  );
};

const mapStateToProps = state => ({
  lastUpdated: getProfileUpdatedAt(state),
});

export default connect(mapStateToProps)(LastUpdated);
