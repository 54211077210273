import { flow, set, get, mapValues, isNil } from 'lodash/fp';

import { SET_FINANCIAL_PROFILE } from 'constants/actionTypes';

export const initialState = {
  adjustedGrossIncome: '',
  changeInIncome: '',
  expectedAnnualGrossIncome: '',
  currentlyEmployed: '',
  unemploymentIncome: '',
  unemploymentEndDate: '',
  expectedNonUnemploymentIncome: '',
  expectedNextAnnualIncome: '',
  jointAdjustedGrossIncome: '',
  jointTaxReturn: '',
  maritalStatus: '',
  numberOfDependents: '',
  numberOfKids: '',
  spouseAdjustedGrossIncome: '',
  spouseCurrentLoanBalance: '',
  spouseExpectedAnnualGrossIncome: '',
  spouseHasFsl: '',
  spouseIncomeChange: '',
  spouseCurrentlyEmployed: '',
  spouseUnemploymentIncome: '',
  spouseUnemploymentEndDate: '',
  spouseExpectedNonUnemploymentIncome: '',
  spouseExpectedNextAnnualIncome: '',
  spousePayFrequency: '',
  idrRepaymentPlanTypeId: '',
  idrRecertDate: '',
  hasLoansFromGradSchool: '',
  consolidationIncludedParentPlus: '',
  hadPellGrants: '',
};

const profileReducer = (state = initialState, { type, payload }) => {
  const profileData = get('financialProfile', payload);

  switch (type) {
    case SET_FINANCIAL_PROFILE: {
      return flow([
        set('adjustedGrossIncome', profileData.adjustedGrossIncome),
        set('changeInIncome', profileData.changeInIncome),
        set('expectedAnnualGrossIncome', profileData.expectedAnnualGrossIncome),
        set('currentlyEmployed', profileData.currentlyEmployed),
        set('unemploymentIncome', profileData.unemploymentIncome),
        set('unemploymentEndDate', profileData.unemploymentEndDate),
        set(
          'expectedNonUnemploymentIncome',
          profileData.expectedNonUnemploymentIncome,
        ),
        set('expectedNextAnnualIncome', profileData.expectedNextAnnualIncome),
        set('jointAdjustedGrossIncome', profileData.jointAdjustedGrossIncome),
        set('jointTaxReturn', profileData.jointTaxReturn),
        set('maritalStatus', profileData.maritalStatus),
        set('numberOfDependents', profileData.numberOfDependents),
        set('numberOfKids', profileData.numberOfKids),
        set('spouseAdjustedGrossIncome', profileData.spouseAdjustedGrossIncome),
        set('spouseCurrentLoanBalance', profileData.spouseCurrentLoanBalance),
        set(
          'spouseExpectedAnnualGrossIncome',
          profileData.spouseExpectedAnnualGrossIncome,
        ),
        set('spouseHasFsl', profileData.spouseHasFsl),
        set('spouseIncomeChange', profileData.spouseIncomeChange),
        set('spouseCurrentlyEmployed', profileData.spouseCurrentlyEmployed),
        set('spouseUnemploymentIncome', profileData.spouseUnemploymentIncome),
        set('spouseUnemploymentEndDate', profileData.spouseUnemploymentEndDate),
        set(
          'spouseExpectedNonUnemploymentIncome',
          profileData.spouseExpectedNonUnemploymentIncome,
        ),
        set(
          'spouseExpectedNextAnnualIncome',
          profileData.spouseExpectedNextAnnualIncome,
        ),
        set('spousePayFrequency', profileData.spousePayFrequency),
        set('idrRepaymentPlanTypeId', profileData.idrRepaymentPlanTypeId),
        set('idrRecertDate', profileData.idrRecertDate),
        set('hasLoansFromGradSchool', profileData.hasLoansFromGradSchool),
        set(
          'consolidationIncludedParentPlus',
          profileData.consolidationIncludedParentPlus,
        ),
        set('hadPellGrants', profileData.hadPellGrants),

        mapValues(value => (isNil(value) ? '' : value)),
      ])(state);
    }

    default:
      return state;
  }
};

export default profileReducer;
