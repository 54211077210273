import React from 'react';

import { TextInput } from '@summer/ui-components';

import { Row } from 'components/pages/idr/wizard/shared/styles';
import { InputWrapper } from 'components/pages/idr/wizard/steps/Submit/shared/Inputs';

const HrName = ({
  formik: { values, errors, handleChange, handleBlur, touched, isSubmitting },
}) => (
  <Row>
    <InputWrapper>
      <TextInput
        label="Employer Contact First Name"
        name="hrFirstName"
        touched={touched.hrFirstName}
        isSubmitting={isSubmitting}
        error={errors.hrFirstName}
        value={values.hrFirstName || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="First Name"
      />
    </InputWrapper>
    <InputWrapper>
      <TextInput
        label="Employer Contact Last Name"
        name="hrLastName"
        touched={touched.hrLastName}
        isSubmitting={isSubmitting}
        error={errors.hrLastName}
        value={values.hrLastName || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Last Name"
      />
    </InputWrapper>
  </Row>
);

export default HrName;
