import React from 'react';

import { Text, Alert } from '@summer/ui-components';

export const QualifiesFullTimeAlert = () => (
  <Alert theme="success" showIcon={false} dismissable={false}>
    <Text>
      <span role="img" aria-label="emoji">
        🎉
      </span>{' '}
      Hooray! Your employment should qualify for PSLF.
    </Text>
  </Alert>
);

export const QualifiesPartTimeAlert = () => (
  <Alert theme="success" showIcon={false} dismissable={false}>
    <Text>
      <span role="img" aria-label="emoji">
        🎉
      </span>{' '}
      Hooray! Your employment will qualify with both employers. If you
      haven&rsquo;t already created an additional application for that employer,
      we&rsquo;ll have you create one after this one.
    </Text>
  </Alert>
);

export const DoesNotQualifyAlert = () => (
  <Alert theme="warning" showIcon={false} dismissable={false}>
    <Text>
      <span role="img" aria-label="emoji">
        😳
      </span>{' '}
      Unfortunately, federal guidelines state that you must have worked 30+
      hours per week for one or more public service employers in order for your
      employment to be eligible for PSLF. However you may qualify for other
      forgiveness programs.
    </Text>
  </Alert>
);
