import React, { Component } from 'react';

import { NumberInput } from '@summer/ui-components';

import {
  Label,
  Question,
  SmallTextInputContainer,
} from 'components/pages/idr/wizard/shared/styles';
import { validateNumber } from 'utils/validate';

class NumberOfDependents extends Component {
  componentWillUnmount() {
    this.props.formik.setFieldValue(`tellUsAboutYou.numberOfDependents`, '');
  }

  render() {
    const {
      formik: {
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        isSubmitting,
      },
    } = this.props;
    return (
      <Question id="numberOfDependents">
        <Label>
          How many other people (excluding your spouse and kids) live with you
          and receive more than half of their support from you? Enter 0 if you
          do not have any.
        </Label>
        <SmallTextInputContainer>
          <NumberInput
            name="tellUsAboutYou.numberOfDependents"
            value={values.numberOfDependents}
            touched={touched.numberOfDependents}
            error={errors.numberOfDependents}
            isSubmitting={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Number of dependents"
            validate={validateNumber()}
          />
        </SmallTextInputContainer>
      </Question>
    );
  }
}

export default NumberOfDependents;
