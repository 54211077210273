import React from 'react';

import { Form, Row } from 'components/pages/idr/wizard/shared/styles';
import {
  Address,
  Name,
  ReferenceEmail,
  ReferencePhone,
  RelationshipToUser,
} from 'components/pages/idr/wizard/steps/Submit/fields';
import { useFormDrawerFocus } from 'components/shared/FormDrawers';

const ReferenceForm = ({ formik, referenceNumber, show }) => {
  const referencePrefix = `reference${referenceNumber}`;
  const formRef = React.useRef(null);
  useFormDrawerFocus(formRef, show);

  return (
    <Form ref={formRef}>
      <Name formik={formik} prefix={referencePrefix} />
      <Row>
        <ReferenceEmail formik={formik} prefix={referencePrefix} />
        <ReferencePhone formik={formik} prefix={referencePrefix} />
      </Row>
      <Address formik={formik} prefix={referencePrefix} />
      <RelationshipToUser formik={formik} prefix={referencePrefix} />
    </Form>
  );
};

export default ReferenceForm;
