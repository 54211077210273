import {
  SET_IDR_CURRENT_STEP,
  SET_CURRENT_IDR_FORM,
} from 'constants/actionTypes';

const initialState = {
  formId: null,
  currentStep: null,
  status: null,
};

const currentIdrFormReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IDR_CURRENT_STEP:
      return { ...state, currentStep: payload.step };

    case SET_CURRENT_IDR_FORM:
      return {
        ...state,
        formId: payload.formId,
        currentStep: payload.currentStep,
        status: payload.status,
        signedAt: payload.signedAt,
      };
    default:
      return state;
  }
};

export default currentIdrFormReducer;
