import { push as redirectTo } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import LoadingAnimation from 'components/common/LoadingAnimation';
import { useIsPageLoading } from 'hooks/common';
import { isLoading } from 'redux/selectors/ui.selectors';

import { MfaPageContainer } from './shared';

const SignInAnimation = ({ redirectTo, location, isLoadingHydrateUser }) => {
  const [animationComplete, setAnimationComplete] = useState(false);
  const isLoading = useIsPageLoading(isLoadingHydrateUser);

  useEffect(
    function redirect() {
      if (animationComplete && !isLoading) {
        redirectTo(location.state?.origin || '/');
      }
    },
    [animationComplete, isLoading, location, redirectTo],
  );

  return (
    <MfaPageContainer>
      <AnimationContainer>
        <LoadingAnimation
          header="Signing you in..."
          waveSpeed={0.5}
          onAnimationEnd={() => setAnimationComplete(true)}
        />
      </AnimationContainer>
    </MfaPageContainer>
  );
};

const AnimationContainer = styled.div`
  margin-top: 64px;
`;

const mapStateToProps = state => ({
  isLoadingHydrateUser: isLoading(state, 'hydrateUser'),
});

export default withRouter(
  connect(mapStateToProps, { redirectTo })(SignInAnimation),
);
