import React from 'react';
import ReactAnimatedNumber from 'react-animated-number';
import PropTypes from 'prop-types';

export default class AnimatedNumber extends React.Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
    fontSize: PropTypes.number,
    formatValue: PropTypes.func,
    stepPrecision: PropTypes.number,
  };

  static defaultProps = {
    fontSize: 34,
    value: 0,
    formatValue: n => n,
    stepPrecision: 0,
  };

  render() {
    return (
      <ReactAnimatedNumber
        style={{
          fontSize: this.props.fontSize,
          transition: '0.5s ease-out',
          transitionProperty: 'background-color, color, opacity',
        }}
        stepPrecision={this.props.stepPrecision}
        duration={850}
        value={this.props.value}
        formatValue={this.props.formatValue}
      />
    );
  }
}
