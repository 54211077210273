import {
  FETCH_CSP_CALCULATOR_DATA,
  POST_CSP_CALCULATOR_RESULTS,
  NETWORK_SUCCESS,
  NETWORK_FAILURE,
  FETCH_ALL_CSP_SCHOLARSHIPS,
  FETCH_CSP_SCHOLARSHIP_BY_UID,
  CMS_SUCCESS,
} from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import { fetchDocByUid, fetchDocsByType } from 'redux/actions/cms.actions';
import {
  setCSPCalculatorData,
  setCSPCalculatorResults,
  setScholarships,
} from 'redux/actions/collegeSavingsPlanner.actions';
import handleSideEffects from 'redux/handleSideEffects';

const CMS_TYPE = 'scholarships';

const collegeSavingsPlannerMiddleware = handleSideEffects({
  [FETCH_CSP_CALCULATOR_DATA]: (dispatch, { meta }) => {
    dispatch(
      apiRequest({
        url: '/college-savings-planner/calculator-data',
        method: 'GET',
        fromAction: FETCH_CSP_CALCULATOR_DATA,
        label: 'fetchCSPCalculatorData',
        meta,
      }),
    );
  },

  [POST_CSP_CALCULATOR_RESULTS]: (dispatch, { payload, meta }) => {
    dispatch(
      apiRequest({
        url: '/college-savings-planner/calculate-results',
        method: 'POST',
        body: {
          ...payload,
        },
        fromAction: POST_CSP_CALCULATOR_RESULTS,
        label: 'postCSPCalculatorResults',
        meta,
      }),
    );
  },

  [NETWORK_SUCCESS]: (dispatch, { payload, meta }) => {
    if (meta.fromAction === FETCH_CSP_CALCULATOR_DATA) {
      dispatch(setCSPCalculatorData(payload.response.data));
    }
    if (meta.fromAction === POST_CSP_CALCULATOR_RESULTS) {
      dispatch(setCSPCalculatorResults(payload.response.data));

      if (meta.onSuccess) {
        meta.onSuccess();
      }
    }
  },

  [NETWORK_FAILURE]: (dispatch, { meta }) => {
    if (meta.fromAction === POST_CSP_CALCULATOR_RESULTS) {
      if (meta.onFailure) {
        meta.onFailure();
      }
    }
  },

  [FETCH_ALL_CSP_SCHOLARSHIPS]: (dispatch, { payload: { page, pageSize } }) => {
    dispatch(
      fetchDocsByType({
        type: CMS_TYPE,
        page,
        pageSize,
        fromAction: FETCH_ALL_CSP_SCHOLARSHIPS,
      }),
    );
  },
  [FETCH_CSP_SCHOLARSHIP_BY_UID]: (dispatch, { payload: { uid } }) => {
    dispatch(
      fetchDocByUid({
        type: CMS_TYPE,
        uid,
        fromAction: FETCH_CSP_SCHOLARSHIP_BY_UID,
      }),
    );
  },

  [CMS_SUCCESS]: (dispatch, { payload, meta }) => {
    if (meta.fromAction === FETCH_ALL_CSP_SCHOLARSHIPS) {
      const docs = payload.response.results;
      dispatch(setScholarships(docs));
    }

    if (meta.fromAction === FETCH_CSP_SCHOLARSHIP_BY_UID) {
      const docs = [payload.response];
      dispatch(setScholarships(docs));
    }
  },
});

export default collegeSavingsPlannerMiddleware;
