import { map, pick } from 'lodash';
import { set } from 'lodash/fp';

import { SET_PSLF_CALCULATIONS } from 'constants/actionTypes';

const initialState = {
  loanEligibility: [],
};

const loanEligibilityFields = ['loanId', 'pslfEligible', 'shouldConsolidate'];

const calculationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PSLF_CALCULATIONS: {
      const loanEligibility = map(
        payload.loanEligibility,
        loanEligibilityObject =>
          pick(loanEligibilityObject, loanEligibilityFields),
      );
      return set('loanEligibility', loanEligibility, state);
    }
    default:
      return state;
  }
};

export default calculationsReducer;
