import React from 'react';
import styled from 'styled-components';

import { PhoneInput } from '@summer/ui-components';

import { Question, Row } from 'components/pages/idr/wizard/shared/styles';

const Phone = ({
  formik: { errors, handleBlur, isSubmitting, setFieldValue, touched, values },
}) => (
  <Question>
    <Row>
      <PhoneInput
        label="Phone Number"
        name="phone"
        placeholder="Phone Number"
        touched={touched.phone}
        isSubmitting={isSubmitting}
        error={errors.phone}
        value={values.phone}
        onChange={setFieldValue}
        onBlur={handleBlur}
      />
      <Placeholder />
    </Row>
  </Question>
);

const Placeholder = styled.div`
  width: 100%;
`;

export default Phone;
