import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled, { css, keyframes } from 'styled-components';

import { CollapseSidebarSize, MobileSize } from 'constants/styleguide';
import { showToast, removeToast } from 'redux/actions/toast.actions';
import { getToastList, isSidebarVisible } from 'redux/selectors/ui.selectors';
import { helpWidgetSize } from 'services/chat';

import { Toast } from './Toast';

class ToastList extends React.Component {
  static propTypes = {
    toasts: PropTypes.array.isRequired,
    removeToast: PropTypes.func,
  };

  render() {
    const { removeToast, toasts, leftOffset, desktopBottom, sideBarVisible } =
      this.props;

    return (
      <TransitionGroup>
        <CSSTransition timeout={{ enter: 750, exit: 750 }}>
          <ToastBox
            leftOffset={leftOffset}
            desktopBottom={desktopBottom}
            sideBarVisible={sideBarVisible}
          >
            {toasts.map(toast => {
              const { id } = toast;
              return (
                <Toast
                  {...toast}
                  key={id}
                  onDismissClick={() => removeToast({ id })}
                />
              );
            })}
          </ToastBox>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

const mapStateToProps = state => ({
  toasts: getToastList(state),
  sideBarVisible: isSidebarVisible(state),
});

ToastList.defaultProps = {
  leftOffset: '0px',
};

export default connect(mapStateToProps, {
  showToast,
  removeToast,
})(ToastList);

const fadeUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(25px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeUpAnimation = css`
  ${fadeUp} 750ms ease-in-out;
`;

const ToastBox = styled.div`
  animation: ${fadeUpAnimation};

  z-index: 1;
  position: fixed;
  bottom: 32px;

  ${props => `left: calc(${props.leftOffset} + 38px);`}
  ${props => props.desktopBottom && `bottom: ${props.desktopBottom}px;`}

  @media (max-width: ${CollapseSidebarSize}) {
    ${props =>
      `left: calc(${props.sideBarVisible ? props.leftOffset : '0px'} + 38px);`}
  }

  @media (max-width: ${MobileSize}) {
    bottom: 20px;
    left: 20px;
    right: calc(${helpWidgetSize.mobile} + 8px);
  }
`;
