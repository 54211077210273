import { Formik } from 'formik';
import { debounce } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import {
  Header,
  Text,
  FlexColumn,
  MoneyInput,
  Slider,
} from '@summer/ui-components';

import { MobileSize } from 'constants/styleguide';
import { overpaymentAmountSchema } from 'schemas/overpayments';
import { setOverpaymentValues } from 'utils/overpayments';

const SliderInfo = ({ values, setFieldValue, sliderMax }) => {
  const { overpaymentAmount } = values;

  const handleSliderChange = debounce((sliderValue, values, setFieldValue) => {
    if (sliderValue <= sliderMax) {
      const newOverpayment = sliderValue * 100;
      setFieldValue('overpaymentAmount', newOverpayment);
      setOverpaymentValues(newOverpayment, values, setFieldValue);
    }
  }, 10);

  const handleSubmit = e => {
    const { overpaymentAmount = 0 } = e;
    const newOverpayment = overpaymentAmount / 100;
    handleSliderChange(newOverpayment, values, setFieldValue);
  };

  return (
    <SliderContainer>
      <SliderDetails>
        <SliderHeader>
          <Header as="h5" color="summer">
            What happens if you pay extra each month?
          </Header>
        </SliderHeader>
        <Text size="small">
          Adjust the slider or enter an amount (between $0 and ${sliderMax}) to
          see possible savings.
        </Text>
      </SliderDetails>
      <SliderWrapper>
        <Slider
          min={0}
          max={sliderMax}
          defaultValue={100}
          value={overpaymentAmount / 100}
          handleChange={sliderValue =>
            handleSliderChange(sliderValue, values, setFieldValue)
          }
          // TODO [ch16147]: Use onAfterChange after rc-slider issue is addressed https://github.com/react-component/slider/issues/659
          // onAfterChange={value => {
          //   setOverpaymentValues(values, setFieldValue);
          // }}
        />
      </SliderWrapper>
      <PaymentInput>
        <Formik
          initialValues={{
            overpaymentAmount: 10000,
          }}
          validationSchema={() => overpaymentAmountSchema(sliderMax * 100)}
          onSubmit={handleSubmit}
        >
          {formik => {
            const { errors, handleSubmit, setFieldValue, setFieldTouched } =
              formik;

            return (
              <form onSubmit={handleSubmit}>
                <MoneyInput
                  name="overpaymentAmount"
                  value={overpaymentAmount || '0'}
                  error={errors.overpaymentAmount}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  onBlur={handleSubmit}
                  height={40}
                  theme="noBorder"
                />
              </form>
            );
          }}
        </Formik>
      </PaymentInput>
    </SliderContainer>
  );
};

const SliderDetails = styled.div`
  @media (max-width: ${MobileSize}) {
    padding: 0px 16px;
    margin-bottom: 8px;
  }
`;

const SliderHeader = styled.div`
  margin: 8px;
`;

const SliderContainer = styled(FlexColumn)`
  user-select: none;
  margin: 24px 0px;
  text-align: center;

  @media (max-width: ${MobileSize}) {
    padding: 0px 8px;
  }
`;

const SliderWrapper = styled.div`
  margin-top: 16px;
`;

const PaymentInput = styled.div`
  margin-top: 16px;
  align-self: flex-end;

  @media (max-width: ${MobileSize}) {
    margin-top: 24px;
  }
`;
export default SliderInfo;
