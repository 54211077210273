import { includes } from 'lodash';
import React from 'react';

import { Alert, Text } from '@summer/ui-components';

import ContactSupport from 'components/pages/pslf/wizard/steps/yourEmployment/shared/ContactSupport';
import { NONE_OF_THE_ABOVE } from 'constants/pslf';

const EmployerPrimaryPurposeAlert = ({ values }) => {
  let theme;
  let text;

  if (includes(values.employerPrimaryPurpose, NONE_OF_THE_ABOVE)) {
    theme = 'warning';
    text = (
      <>
        <span role="img" aria-label="emoji">
          🤔
        </span>{' '}
        If none of these categories apply to this employer, you may not be
        eligible for PSLF during this period. However, you may qualify while
        working for a different employer. <ContactSupport />
      </>
    );
  }

  if (theme && text) {
    return (
      <Alert dismissable={false} showIcon={false} theme={theme}>
        <Text paragraph>{text}</Text>
      </Alert>
    );
  }

  return null;
};

export default React.memo(EmployerPrimaryPurposeAlert);
