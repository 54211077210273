import React from 'react';
import styled from 'styled-components';

import { COLORS } from '../../helpers/constants/styleguide';
import Text from '../typography/Text';

const Testimonial = ({ quote, name, image }) => {
  return (
    <div>
      <Headshot src={image} />
      <Container>
        <Quote>
          <Text paragraph color="dark" size="large" weight="medium">
            {quote}
          </Text>
        </Quote>
        <Text color="secondary" weight="medium">
          {name}
        </Text>
      </Container>
    </div>
  );
};

const Container = styled.div`
  border: solid 1px ${COLORS.azure};
  padding: 56px 32px 24px;
`;

const Quote = styled.div`
  margin-bottom: 8px;
`;

const Headshot = styled.img`
  position: relative;
  margin-top: -32px;
  top: 48px;
  width: 68px;
  height: 68px;
  border-radius: 100%;
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid white;
`;

export default Testimonial;
