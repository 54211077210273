import React from 'react';
import { connect } from 'react-redux';

import { Text, Strong, Alert } from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';
import { getHideSupportChat } from 'redux/selectors/user.selectors';
import { openChat } from 'services/chat';

const LoanMissingInfoAlert = ({ alertDismissed, hideSupportChat }) => {
  const theme = 'warning';
  return (
    <Alert
      theme={theme}
      dismissable={false}
      showIcon={true}
      externalDismissed={alertDismissed}
    >
      <Text small paragraph>
        <Strong>Please confirm the missing info on this loan. </Strong>
        {hideSupportChat ? (
          <>
            Email us at{' '}
            <Link underline inheritColor href={`mailto:${supportAddress}`}>
              {supportAddress}
            </Link>
          </>
        ) : (
          <Link underline inheritColor onClick={openChat}>
            Send us a message
          </Link>
        )}{' '}
        if you&rsquo;re having trouble finding this info.
      </Text>
    </Alert>
  );
};

const mapStateToProps = state => ({
  hideSupportChat: getHideSupportChat(state),
});

export default connect(mapStateToProps)(LoanMissingInfoAlert);
