/* eslint-disable react/display-name */
import { difference } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Header from 'components/pages/idr/wizard/steps/Submit/shared/Header';
import { useSetServicerFields } from 'components/pages/idr/wizard/steps/Submit/shared/servicersToSendIDR';
import FormDrawers from 'components/shared/FormDrawers';
import { MobileSize } from 'constants/styleguide';
import { employerInitialValues } from 'redux/reducers/IDR/submit.reducer';
import {
  doWeNeedSpouseInfo,
  selectedPathIncludesConsolidation,
} from 'redux/selectors/idr.selectors';
import { getKeysWithPrefix } from 'utils/object';

import ContactInfo from './ContactInfo';
import EmployerInfo from './EmployerInfo';
import PersonalInfo from './PersonalInfo';
import SpouseInfo from './SpouseInfo';

const YourContactInfo = ({
  values,
  errors,
  touched,
  idrHandlers,
  resetSubmitCount,
  currentStepSubmitCount,
  requiresSpouseInfo,
  includesConsolidation,
}) => {
  const formik = { values, errors, touched, ...idrHandlers };

  /* This hook useSetServicerFields is unrelated to contact info
   * We do it here because this step always appears and these fields currently need to be set on the client
   * There is a ticket to move to the server: [ch26965]
   */
  useSetServicerFields(formik.setFieldValue);

  const getEmployerFieldNames = () =>
    difference(getKeysWithPrefix(employerInitialValues, 'employer'), [
      `employerId`,
    ]);

  return (
    <Container>
      <Header>Your contact information</Header>
      <FormDrawers
        errors={errors}
        submitCount={currentStepSubmitCount}
        resetSubmitCount={resetSubmitCount}
        forms={[
          {
            name: 'personal',
            title: 'Personal Information',
            form: show => <PersonalInfo formik={formik} show={show} />,
            fields: ['firstName', 'lastName', 'formerName', 'dateOfBirth'],
          },
          {
            name: 'contact',
            title: 'Contact Information',
            form: show => <ContactInfo formik={formik} show={show} />,
            fields: ['streetAddress1', 'city', 'state', 'zipcode', 'phone'],
          },
          ...(requiresSpouseInfo
            ? [
                {
                  name: 'spouse',
                  title: "Spouse's Information",
                  form: show => <SpouseInfo formik={formik} show={show} />,
                  fields: [
                    'spouseFirstName',
                    'spouseLastName',
                    'spouseEmail',
                    'spouseDob',
                  ],
                },
              ]
            : []),
          ...(includesConsolidation
            ? [
                {
                  name: 'employer',
                  title: 'Employer Information',
                  form: show => <EmployerInfo formik={formik} show={show} />,
                  fields: getEmployerFieldNames(),
                },
              ]
            : []),
        ]}
      />
    </Container>
  );
};

const Container = styled.div`
  @media (max-width: ${MobileSize}) {
    margin: 0 -20px;
  }
`;

const mapStateToProps = state => ({
  requiresSpouseInfo: doWeNeedSpouseInfo(state),
  includesConsolidation: selectedPathIncludesConsolidation(state),
});

export default connect(mapStateToProps)(YourContactInfo);
