import React from 'react';

const WizardGif = () => (
  <>
    <img
      src="https://www.picgifs.com/graphics/m/magic/graphics-magic-699248.gif"
      alt="wizard"
      style={{
        position: 'absolute',
        width: '160px',
        left: 0,
      }}
    />
    <img
      src="https://www.picgifs.com/graphics/m/magic/graphics-magic-699248.gif"
      alt="wizard"
      style={{
        position: 'absolute',
        width: '160px',
        right: 0,
        transform: 'scaleX(-1)',
      }}
    />
  </>
);

export default WizardGif;
