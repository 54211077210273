import React, { Component } from 'react';

import { Text, MoneyInput } from '@summer/ui-components';

import {
  Label,
  LabelContainer,
  Question,
  SmallTextInputContainer,
} from 'components/pages/idr/wizard/shared/styles';
import InfoTooltip from 'components/shared/InfoTooltip';
import { validateNumber } from 'utils/validate';

class ExpectedAnnualGrossIncome extends Component {
  componentWillUnmount() {
    this.props.formik.setFieldValue(
      `tellUsAboutYou.expectedAnnualGrossIncome`,
      '',
    );
  }

  render() {
    const {
      formik: {
        values,
        errors,
        handleBlur,
        touched,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
      },
    } = this.props;
    return (
      <Question id="expectedAnnualGrossIncome">
        <LabelContainer>
          <Label>What is your expected annual gross taxable income?</Label>
          <InfoTooltip
            width={300}
            size={14}
            top={4}
            text={
              <>
                <Text small>
                  If you have income, then you are going to need to provide
                  proof of this income later by submitting your paystubs or a
                  written statement explaining your income.
                </Text>
                <br />
                <Text small>
                  If you don&apos;t have any income, enter $0. You would be
                  eligible for a $0 monthly payment.
                </Text>
              </>
            }
          />
        </LabelContainer>
        <LabelContainer>
          <Label>
            <Text paragraph as="span">
              {values.receivingUnemploymentIncome === 'false'
                ? 'Enter any other taxable income that you\u2019re expecting in the next 12 months.'
                : 'Enter your expected income for the next 12 months.'}
            </Text>
          </Label>
        </LabelContainer>
        <SmallTextInputContainer>
          <MoneyInput
            name="tellUsAboutYou.expectedAnnualGrossIncome"
            value={values.expectedAnnualGrossIncome}
            error={errors.expectedAnnualGrossIncome}
            touched={touched.expectedAnnualGrossIncome}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            isSubmitting={isSubmitting}
            onBlur={handleBlur}
            validate={validateNumber()}
          />
        </SmallTextInputContainer>
      </Question>
    );
  }
}

export default ExpectedAnnualGrossIncome;
