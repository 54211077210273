import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { FlexColumn, VerticalNav } from '@summer/ui-components';

import { DesktopHDSize } from 'constants/styleguide';

class Sidebar extends React.Component {
  state = {
    activeMenuItem: 0,
  };

  componentDidMount() {
    this.setActiveMenuItem();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setActiveMenuItem();
    }
  }

  setActiveMenuItem = () => {
    const { location, menuItems } = this.props;
    const matchingMenuItem = menuItems.findIndex(
      menuItem => menuItem.link === location.pathname,
    );
    this.setState({ activeMenuItem: matchingMenuItem });
  };

  render() {
    return (
      <Container>
        <VerticalNav
          menuItems={this.props.menuItems}
          activeIndex={this.state.activeMenuItem}
        />
      </Container>
    );
  }
}

const Container = styled(FlexColumn)`
  position: fixed;
  left: 32px;

  @media (max-width: ${parseInt(DesktopHDSize, 10) - 230}px) {
    visibility: hidden;
  }
`;

export default withRouter(Sidebar);
