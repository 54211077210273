import { set, unset } from 'lodash/fp';

import {
  PSLF_NETWORK_SUCCESS,
  PSLF_NETWORK_CLEAR,
} from 'constants/actionTypes';

const initialState = {};

const networkSuccessReducer = (state = initialState, { type, meta }) => {
  switch (type) {
    case PSLF_NETWORK_SUCCESS:
      return set(meta.label, true, state);

    case PSLF_NETWORK_CLEAR:
      return unset(meta.label, state);

    default:
      return state;
  }
};

export default networkSuccessReducer;
