import {
  FETCH_LOAN_TYPES,
  SET_LOAN_TYPES,
  FETCH_SERVICERS,
  SET_SERVICERS,
  FETCH_REPAYMENT_PLAN_TYPES,
  SET_REPAYMENT_PLAN_TYPES,
} from 'constants/actionTypes';

export const fetchLoanTypes = () => ({
  type: FETCH_LOAN_TYPES,
});

export const setLoanTypes = data => ({
  type: SET_LOAN_TYPES,
  payload: data,
});

export const fetchServicers = () => ({
  type: FETCH_SERVICERS,
});

export const setServicers = data => ({
  type: SET_SERVICERS,
  payload: data,
});

export const fetchRepaymentPlanTypes = () => ({
  type: FETCH_REPAYMENT_PLAN_TYPES,
});

export const setRepaymentPlanTypes = data => ({
  type: SET_REPAYMENT_PLAN_TYPES,
  payload: data,
});
