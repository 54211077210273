import { push as redirectTo } from 'connected-react-router';
import { orderBy } from 'lodash';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  Button,
  COLORS,
  FlexColumn,
  FlexRow,
  Header,
  Text,
} from '@summer/ui-components/src';
import Link from '@summer/ui-components/src/core/typography/Link';

import {
  savingsMarketplaceCapitalOneLink,
  savingsMarketplaceDiscoverLink,
  savingsMarketplaceAllyLink,
  savingsMarketplaceWealthfrontLink,
  savingsMarketplaceCreditKarmaLink,
  savingsMarketplaceSynchronyLink,
  savingsMarketplaceVaroLink,
  savingsMarketplaceBettermentLink,
  savingsMarketplaceMarcusLink,
} from 'constants/externalLinks/emergencySavings';
import { getIsEmergencySavingsV1Enabled } from 'redux/selectors/flags.selectors';
import { dollars, percentage } from 'utils/numbers';

const options = [
  {
    name: 'capitalOne',
    minDeposit: 0,
    apy: 0.034,
    url: savingsMarketplaceCapitalOneLink,
  },
  {
    name: 'discover',
    minDeposit: 0,
    apy: 0.036,
    url: savingsMarketplaceDiscoverLink,
  },
  {
    name: 'allyBank',
    minDeposit: 0,
    apy: 0.0375,
    url: savingsMarketplaceAllyLink,
  },
  {
    name: 'wealthfront',
    minDeposit: 0,
    apy: 0.043,
    url: savingsMarketplaceWealthfrontLink,
  },
  {
    name: 'creditKarma',
    minDeposit: 0,
    apy: 0.0375,
    url: savingsMarketplaceCreditKarmaLink,
  },
  {
    name: 'synchrony',
    minDeposit: 0,
    apy: 0.03,
    url: savingsMarketplaceSynchronyLink,
  },
  {
    name: 'varo',
    minDeposit: 0,
    apy: 0.04,
    url: savingsMarketplaceVaroLink,
  },
  {
    name: 'betterment',
    minDeposit: 0,
    apy: 0.042,
    url: savingsMarketplaceBettermentLink,
  },
  {
    name: 'marcus',
    minDeposit: 0,
    apy: 0.0375,
    url: savingsMarketplaceMarcusLink,
  },
];

const Option = ({ name, minDeposit, apy, url }) => {
  const src = `/images/emergencySavingsMarketplace/${name}.png`;

  return (
    <OptionRow>
      <Logo src={src} alt={name} />
      <OptionTerm>
        <Text bold>Min. Deposit</Text>
        <Text paragraph>{dollars(minDeposit)}</Text>
      </OptionTerm>
      <OptionTerm>
        <Text bold>APY</Text>
        <Text paragraph>{percentage(apy)}</Text>
      </OptionTerm>
      <GetStartedButtonContainer>
        <Link href={url}>
          <Button width={138} secondary>
            Get Started
          </Button>
        </Link>
      </GetStartedButtonContainer>
    </OptionRow>
  );
};

const EmergencySavingsMarketplace = () => {
  const dispatch = useDispatch();
  const returnToDashboard = useCallback(
    () => dispatch(redirectTo('/emergency-savings')),
    [dispatch],
  );

  const sortedOptions = orderBy(options, ['apy'], ['desc']);

  const showEmergencySavingsV1 = useSelector(getIsEmergencySavingsV1Enabled);

  if (showEmergencySavingsV1) {
    return (
      <ContainerV1>
        <HeaderContainer>
          <Header as="h1">High Yield Savings Accounts</Header>
        </HeaderContainer>
        <Text paragraph>
          Compare different savings products from different financial
          institutions. Summer does its best to keep current APR offerings up to
          date however they do change regularly so please check with each
          individual offer.
        </Text>
        <MarketplaceOptions>
          {sortedOptions.map((option, i) => (
            <Option key={i} {...option} />
          ))}
        </MarketplaceOptions>
      </ContainerV1>
    );
  }

  return (
    <Container>
      <Button width={240} secondary onClick={returnToDashboard}>
        Back to Emergency Savings
      </Button>
      <HeaderContainer>
        <Header as="h1">High Yield Savings Accounts</Header>
      </HeaderContainer>
      <Text paragraph>
        Compare different savings products from different financial
        institutions. Summer does its best to keep current APR offerings up to
        date however they do change regularly so please check with each
        individual offer.
      </Text>
      <MarketplaceOptions>
        {sortedOptions.map((option, i) => (
          <Option key={i} {...option} />
        ))}
      </MarketplaceOptions>
      <Button width={240} secondary onClick={returnToDashboard}>
        Back to Emergency Savings
      </Button>
    </Container>
  );
};

const ContainerV1 = styled(FlexColumn)`
  overflow-y: scroll;
  gap: 16px;
  height: 100%;
  width: 100%;
  background-color: #f7fbfe;
`;

const Container = styled(FlexColumn)`
  overflow-y: scroll;
  padding: 60px 88px;
  gap: 16px;
  height: 100%;
  background-color: #f7fbfe;
`;

const HeaderContainer = styled.div`
  margin-top: 8px;
`;

const MarketplaceOptions = styled(FlexColumn)`
  margin: 32px 0;
  gap: 12px;
`;

const OptionRow = styled(FlexRow)`
  width: 100%;
  gap: 10%;
  height: 132px;
  border-radius: 6px;
  border: solid 1px #ebeff4;
  box-shadow: 0px 2px 10px #e6e7e8;
  align-items: center;
  padding: 0 32px;
  background: ${COLORS.white};
`;

const GetStartedButtonContainer = styled.div`
  margin-left: auto;
`;

const Logo = styled.img`
  width: 138px;
`;

const OptionTerm = styled(FlexColumn)`
  gap: 4px;
`;

export default EmergencySavingsMarketplace;
