import { push as redirectTo } from 'connected-react-router';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  InfoIcon,
  IconMedallion,
  Button,
  FlexRow,
  Header,
  Text,
} from '@summer/ui-components';

import ModalPopup from 'components/shared/ModalPopup';
import { MobileSize } from 'constants/styleguide';
import { setPostOnboardingDestination } from 'redux/actions/onboard.actions';

const OnboardingRequiredModal = ({ isOpen, togglePopup }) => {
  const dispatch = useDispatch();
  const returnToOnboarding = useCallback(() => {
    dispatch(setPostOnboardingDestination('/refinancing'));
    dispatch(redirectTo('/onboard/questions'));
  }, [dispatch]);

  return (
    <ModalPopup
      isOpen={isOpen}
      togglePopup={togglePopup}
      width={700}
      component={() => {
        return (
          <Container>
            <IconRow>
              <IconMedallion SvgComponent={InfoIcon} size={56} />
            </IconRow>
            <Header as="h3">WE NEED A LITTLE MORE INFORMATION</Header>
            <Content>
              <Text paragraph>
                Before we take you to our refinancing calculator and
                marketplace, we need a little more info about your situation and
                student loans. This will allow us to give you personalized
                refinancing advise to help you make the best decision.
              </Text>
            </Content>

            <ButtonRow>
              <Button width={132} type="submit" onClick={returnToOnboarding}>
                Continue
              </Button>
            </ButtonRow>
          </Container>
        );
      }}
    />
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px 24px 16px;
  align-items: center;

  @media (max-width: ${MobileSize}) {
    overflow: scroll;
    flex-direction: column;
    padding: 0;
  }
`;

const Content = styled.div`
  margin-top: 52px;
  margin-bottom: 88px;
`;

const IconRow = styled(FlexRow)`
  justify-content: center;
  margin-bottom: 32px;
`;

const ButtonRow = styled(FlexRow)`
  justify-content: center;
`;

export default OnboardingRequiredModal;
