import _ from 'lodash';
import fp, { set } from 'lodash/fp';

import {
  SET_FF_PROGRAMS,
  SET_LOGOUT,
  SET_FF_FILTERS,
} from 'constants/actionTypes';
import { objectKeysToCamelCase } from 'utils/object';

const initialState = { programs: [], filters: {} };

const forgivenessFinderReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FF_PROGRAMS: {
      const { docs } = payload;
      const programs = _.flow(
        fp.unionBy('id', state.programs),
        fp.map(objectKeysToCamelCase),
      )(docs);
      return set('programs', programs, state);
    }

    case SET_FF_FILTERS: {
      const { filters } = payload;
      return set('filters', filters, state);
    }

    case SET_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default forgivenessFinderReducer;
