import { truncate, map, findIndex } from 'lodash';
import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';

import {
  Icon,
  SummerLogo,
  Header,
  FlexColumn,
  VerticalNav,
  COLORS,
} from '@summer/ui-components';

import { TabletSize } from 'constants/styleguide';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.menuItemsRef = React.createRef();
    this.customStyles = {
      control: provided => ({
        ...provided,
        height: '100%',
        borderRadius: '0',
        borderRight: '0',
        borderLeft: '0',
        borderColor: '#d9d9d9 #ccc #b3b3b3 !important',
        boxShadow: 'none !important',
        postion: 'relative',
      }),
      option: (provided, state) => ({
        ...provided,
        height: '48px',
        lineheight: '32px',
        color: state.isSelected ? COLORS.azure : COLORS.medGrey,
        backgroundColor: 'inherit',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 600,
      }),
      valueContainer: provided => ({
        ...provided,
        height: '48px',
        width: '100%',
        justifyContent: 'center',
        padding: '16px 16px 10px',
      }),
      singleValue: provided => ({
        ...provided,
        fontWeight: 600,
        color: COLORS.azure,
      }),
      indicatorsContainer: provided => ({
        ...provided,
        justifyContent: 'center',
        padding: '6px 0 6px 0',
        position: 'absolute',
        top: '0',
        right: '0',
      }),
      indicatorSeparator: provided => ({
        ...provided,
        display: 'none',
      }),
      input: provided => ({
        ...provided,
        height: '48px',
        padding: '0',
      }),
      menu: provided => ({
        ...provided,
        maxHeight: '800px',
        margin: '0px',
        borderRadius: '0',
      }),
      menuList: provided => ({
        ...provided,
        maxHeight: '800px',
        padding: '0px',
      }),
    };
  }

  handleChange = item => {
    this.props.handleClick(item.element);
  };

  render() {
    const { title, sections, activeSection, progress, handleClick } =
      this.props;
    const options = sections.map(item => {
      return {
        value: item.title,
        label: truncate(item.title, { length: 40, separator: ' ' }),
        element: item.element,
      };
    });

    const activeSectionIndex = findIndex(sections, { title: activeSection });
    const menuItems = map(sections, section => ({
      ...section,
      onClick: () => handleClick(section.element),
    }));

    return (
      <>
        <SidebarHeader>
          <Title>
            <Header h3>{title}</Header>
          </Title>
          <Select
            value={options.filter(option => option.value === activeSection)}
            options={options}
            onChange={this.handleChange}
            isSearchable={false}
            isClearable={false}
            styles={this.customStyles}
          />
          <Progress>
            <Bar progress={progress} />
          </Progress>
        </SidebarHeader>
        <SidebarContainer>
          <Logo fill={COLORS.lightBlue} SvgComponent={SummerLogo} />
          <VerticalNav
            menuItems={menuItems}
            activeIndex={activeSectionIndex}
            width={180}
          />
        </SidebarContainer>
      </>
    );
  }
}

const Bar = styled.div`
  position: absolute;
  background-color: ${COLORS.azure};
  width: ${props => props.progress}%;
  left: 0;
  height: 2px;

  transition: width 2s;
`;

const Progress = styled.div`
  width: 100%;
  background-color: ${COLORS.lightGreyText};
  height: 2px;
`;

const SidebarHeader = styled.div`
  z-index: 1;
  display: none;
  text-align: center;
  position: fixed;
  background-color: white;
  width: 100%;

  @media (max-width: ${TabletSize}) {
    display: block;
  }
`;

const SidebarContainer = styled(FlexColumn)`
  width: 180px;
  margin-left: 40px;
  height: 100%;
  position: relative;

  @media (max-width: ${TabletSize}) {
    display: none;
  }
`;

const Logo = styled(Icon)`
  margin-top: 40px;
  margin-bottom: 64px;
`;

const Title = styled.div`
  & > h3 {
    color: ${COLORS.azure};
  }

  @media (max-width: ${TabletSize}) {
    & > h3 {
      color: ${COLORS.greyishBrown};
    }
    margin: 12px;
    margin-right: 32px;
    margin-left: 32px;
    text-align: center;
  }
`;

export default Sidebar;
