import { map } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import {
  Alert,
  Header,
  Text,
  Strong,
  Icon,
  Checkmark,
  FlexRow,
  FlexColumn,
  COLORS,
} from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import ModalPopup from 'components/shared/ModalPopup';
import { Link } from 'components/shared/typography';
import { pslfGuideLink } from 'constants/externalLinks/marketingSite';

const EligibilityInfoModal = ({ platformType }) => {
  const isMobile = platformType === MOBILE;
  const criteriaInfo = [
    {
      header: 'The right employment',
      text: 'Only payments made while working at a qualifying employer will count.',
    },
    {
      header: 'The right loan type',
      text: 'Only Direct or Direct Consolidation loans are eligible.',
    },
    {
      header: 'The right repayment plan type',
      text: 'Only income-driven repayment and 10-year Standard plans are eligible. Any payments made while in default, deferment or forbearance are not eligible.',
    },
  ];
  const pslfWaiverCopy =
    'For a limited time, past payments on all repayment plans and most loan types are eligible for PSLF. Some loans will need to be consolidated to be eligible.';

  return (
    <ModalPopup
      displayStyle="inline"
      width={724}
      component={() => {
        return (
          <>
            <Header as={isMobile ? 'h2' : 'h1'}>
              Understanding payment eligibility
            </Header>
            <Content>
              <Text as="p" paragraph>
                In order for a payment to count toward the{' '}
                <Strong>120 monthly payments </Strong>
                needed for PSLF, it needs to meet all of the following
                requirements.
              </Text>
              <EligibilityCriteriaContainer>
                {map(criteriaInfo, criterionInfo => (
                  <EligibilityCriterion key={criterionInfo.header}>
                    <CheckmarkContainer>
                      <Icon SvgComponent={Checkmark} stroke={COLORS.jade} />
                    </CheckmarkContainer>
                    <FlexColumn>
                      <Header as="h4">{criterionInfo.header}</Header>
                      <Text as="p" paragraph>
                        {criterionInfo.text}
                      </Text>
                    </FlexColumn>
                  </EligibilityCriterion>
                ))}
                <StyledAlert theme="success" dismissable={false}>
                  <b>Limited PSLF Waiver and IDR Adjustment:</b>{' '}
                  {pslfWaiverCopy}
                </StyledAlert>
              </EligibilityCriteriaContainer>
              <Text as="span" paragraph>
                Want to learn more about PSLF?{' '}
                <Link href={pslfGuideLink}>Read our guide.</Link>
              </Text>
            </Content>
          </>
        );
      }}
    >
      <Link inheritColor underline={true}>
        Learn more about how this works.
      </Link>
    </ModalPopup>
  );
};

const EligibilityCriteriaContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 8px;
`;

const EligibilityCriterion = styled(FlexRow)`
  align-items: flex-start;
  margin-bottom: 24px;
`;

const CheckmarkContainer = styled.div`
  margin-right: 16px;
`;

const Content = styled.div`
  margin-top: 16px;
`;

const StyledAlert = styled(Alert)`
  background-color: #e4f8f0;
  z-index: 2;
  margin-bottom: 16px;
`;

export default Platform(EligibilityInfoModal);
