import { OPEN_VERIFY_MODAL, CLOSE_VERIFY_MODAL } from 'constants/actionTypes';
import { checkUser } from 'redux/actions/auth.actions';
import handleSideEffects from 'redux/handleSideEffects';

const userMiddleware = handleSideEffects({
  [OPEN_VERIFY_MODAL]: dispatch => {
    dispatch(checkUser());
  },

  [CLOSE_VERIFY_MODAL]: dispatch => {
    dispatch(checkUser());
  },
});

export default userMiddleware;
