import { addMonths } from 'date-fns';
import React from 'react';
import { object, number, date, string, bool } from 'yup';

import { InterestRateErrorText } from 'components/common/ManageLoans/fields/InterestRate';
import {
  showIncludesParentPlus,
  isConsolidationLoan,
  showLoanPayoffDate,
  loanIsActive,
  showRecertificationDate,
} from 'utils/loans';

const isActiveLoan = schema => {
  // this function returns an array of arguments for yup.when
  // should be spread into the function
  return [
    'principalBalance',
    {
      is: loanIsActive,
      then: schema,
    },
  ];
};

const recertDateError =
  'Your recertification date is typically 1 year after you applied for an IDR plan. Enter an approximate date if you\u2019re unsure.';

export const getIdrLoanSchema = (
  federalLoanTypes,
  repaymentPlanTypes,
  canEditField,
) =>
  object().shape({
    amountBorrowed: number()
      .integer('Please enter a number (without decimals)')
      .max(Number.MAX_SAFE_INTEGER, 'Please enter a lower number')
      .nullable()
      .when(
        ...isActiveLoan(
          number()
            .integer('Please enter a number (without decimals)')
            .max(Number.MAX_SAFE_INTEGER, 'Please enter a lower number')
            .nullable()
            .required('Please enter the amount borrowed'),
        ),
      ),
    principalBalance: number()
      .nullable()
      .required('Please enter the principal balance'),
    outstandingInterestBalance: number()
      .nullable()
      .when(
        ...isActiveLoan(
          number()
            .nullable()
            .required('Please enter the outstanding interest balance'),
        ),
      ),
    disbursementDate: date()
      .nullable()
      .required('Please enter date as mm/dd/yyyy')
      .min('1900-01-01', 'Please enter a valid date')
      .max(new Date(), 'Please enter a valid date'),
    educationLevel: string()
      .nullable()
      .when(
        ...isActiveLoan(string().nullable().required('Please choose a degree')),
      ),
    federalLoanTypeId: number()
      .nullable()
      .required('Please confirm your loan type'),
    idrRecertificationDate: date()
      .nullable()
      .when(['repaymentPlanTypeId', 'principalBalance'], {
        is: (repaymentPlanTypeId, principalBalance) =>
          showRecertificationDate(
            repaymentPlanTypeId,
            principalBalance,
            repaymentPlanTypes,
          ),
        then: date()
          .required('Please enter date as mm/dd/yyyy')
          .when(['id'], {
            is: loanId => canEditField(loanId, 'idrRecertificationDate'),
            then: date()
              .min(addMonths(new Date(), -18), recertDateError)
              .max(addMonths(new Date(), 18), recertDateError),
          }),
      }),
    includesParentPlus: bool()
      .nullable()
      .when('federalLoanTypeId', {
        is: federalLoanTypeId =>
          showIncludesParentPlus(federalLoanTypeId, federalLoanTypes),
        then: bool().required('Please make a selection'),
      }),
    interestRate: number()
      .nullable()
      .when(['principalBalance', 'federalLoanTypeId'], {
        is: (principalBalance, federalLoanTypeId) => {
          const isActive = loanIsActive(principalBalance);
          const isConsolidation = isConsolidationLoan(
            federalLoanTypeId,
            federalLoanTypes,
          );
          /* We don't require interest rate for synced, consolidation loans, because we can
           * calculate the interest rate in the Python calculations.
           */
          const isRequired = isActive && !isConsolidation;
          return isRequired;
        },
        then: number()
          .nullable()
          .min(0.00001, <InterestRateErrorText greaterThan0 />)
          .required(<InterestRateErrorText />),
      }),
    loanPayoffDate: date()
      .nullable()
      .when(
        [
          'disbursementDate',
          'federalLoanTypeId',
          'includesParentPlus',
          'principalBalance',
        ],
        {
          is: (
            disbursementDate,
            federalLoanTypeId,
            includesParentPlus,
            principalBalance,
          ) => {
            return showLoanPayoffDate(
              disbursementDate,
              federalLoanTypeId,
              includesParentPlus,
              principalBalance,
              federalLoanTypes,
            );
          },
          then: date()
            .required('Please enter date as mm/dd/yyyy')
            .min('1900-01-01', 'Please enter a valid date')
            .max(new Date(), 'Please enter a valid date'),
        },
      ),
    repaymentPlanTypeId: number()
      .nullable()
      .when(
        ...isActiveLoan(
          number().nullable().required('Please select a repayment plan'),
        ),
      ),
    servicerId: number()
      .nullable()
      .when(
        ...isActiveLoan(
          number().nullable().required('Please confirm your servicer'),
        ),
      ),
  });

export const pslfLoanSchema = object().shape({
  amountBorrowed: number()
    .integer('Please enter a number (without decimals)')
    .max(Number.MAX_SAFE_INTEGER, 'Please enter a lower number')
    .nullable(),
  principalBalance: number().nullable(),
  outstandingInterestBalance: number().nullable(),
  disbursementDate: date()
    .nullable()
    .min('1900-01-01', 'Please enter a valid date')
    .max(new Date(), 'Please enter a valid date'),
  federalLoanTypeId: number()
    .nullable()
    .required('Please confirm your loan type'),
  repaymentPlanTypeId: number().nullable(),
  servicerId: number().nullable(),
  pslfEligibilityAndConsolidationRec: object().shape({
    pslfEligible: bool().nullable(),
    shouldConsolidate: bool().nullable(),
  }),
});
