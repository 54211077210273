import { filter, findIndex, forEach, isNil, map } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  getActiveFsaLoanServicerNames,
  getInactiveFsaLoanServicerNames,
} from 'redux/selectors/loans.selectors';

const getNestedErrorIndex = loansByServicer => {
  let tableIndex;
  let rowIndex;

  forEach(loansByServicer, (setOfLoans, index) => {
    const rowIndexWithError = findIndex(setOfLoans, { valid: false });

    if (rowIndexWithError !== -1) {
      tableIndex = index;
      rowIndex = rowIndexWithError;
      return false; // stop iteration
    }
    return true;
  });

  return [tableIndex, rowIndex];
};

const animateRowWithError = rowWithError => {
  // eslint-disable-next-line no-param-reassign
  rowWithError.style.transition = 'all 1.5s ease-out';
  const animation = setInterval(() => {
    rowWithError.classList.toggle('error-focus');
  }, 1000);
  setTimeout(() => {
    clearInterval(animation);
    rowWithError.classList.remove('error-focus');
  }, 30000);
};

const useScrollToErrorRow = (normalizedLoans, { idr = false } = {}) => {
  const activeServicers = useSelector(getActiveFsaLoanServicerNames);
  const inactiveServicers = useSelector(getInactiveFsaLoanServicerNames);

  return useMemo(() => {
    let tableName;
    let tableIndex;
    let rowIndex;

    if (idr) {
      const groupLoans = (servicers, active) =>
        map(servicers, servicerName =>
          filter(normalizedLoans, { servicerName, active }),
        );

      const activeLoansByServicer = groupLoans(activeServicers, true);
      const inactiveLoansByServicer = groupLoans(inactiveServicers, false);

      [tableIndex, rowIndex] = getNestedErrorIndex(activeLoansByServicer);

      // look at paid off loans in no errors in active loans
      if (isNil(tableIndex) && isNil(rowIndex)) {
        [tableIndex, rowIndex] = getNestedErrorIndex(inactiveLoansByServicer);
        tableName = 'paid-off';
      }
    } else {
      // PSLF
      tableIndex = 0;
      rowIndex = findIndex(normalizedLoans, { valid: false });
    }

    return () => {
      const rowWithError = document.getElementById(
        `${tableName || 'table'}${tableIndex}-row${rowIndex}`,
      );
      if (rowWithError) {
        animateRowWithError(rowWithError);
        rowWithError.scrollIntoView({ behavior: 'smooth' });
      }
    };
  }, [normalizedLoans, idr, activeServicers, inactiveServicers]);
};

export default useScrollToErrorRow;
