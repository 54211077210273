import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Alert } from '@summer/ui-components';

import { getGlobalAlert } from 'redux/selectors/flags.selectors';

const GlobalAlert = ({ globalAlert }) => {
  if (globalAlert?.show) {
    return <StyledAlert>{globalAlert.message}</StyledAlert>;
  }

  return null;
};

const mapStateToProps = state => ({
  globalAlert: getGlobalAlert(state),
});

const StyledAlert = styled(Alert)`
  z-index: 10;
`;

export default connect(mapStateToProps)(GlobalAlert);
