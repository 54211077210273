import React, { Component } from 'react';

import { MoneyInput } from '@summer/ui-components';

import {
  Label,
  LabelContainer,
  Question,
  SmallTextInputContainer,
} from 'components/pages/idr/wizard/shared/styles';
import InfoTooltip from 'components/shared/InfoTooltip';
import { validateNumber } from 'utils/validate';

class SpouseAdjustedGrossIncome extends Component {
  componentWillUnmount() {
    this.props.formik.setFieldValue(
      `tellUsAboutYou.spouseAdjustedGrossIncome`,
      '',
    );
  }

  render() {
    const {
      formik: {
        values,
        errors,
        handleBlur,
        touched,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
      },
    } = this.props;
    return (
      <Question id="spouseAdjustedGrossIncome">
        <LabelContainer>
          <Label>
            What was your spouse&rsquo;s adjusted gross income (AGI) as reported
            on your federal taxes last year?
            <InfoTooltip
              size={14}
              top={2}
              leftOffset={6}
              text="You are going to need to provide proof of this income later by submitting your last federal tax return."
            />
          </Label>
        </LabelContainer>
        <SmallTextInputContainer>
          <MoneyInput
            name="tellUsAboutYou.spouseAdjustedGrossIncome"
            value={values.spouseAdjustedGrossIncome}
            error={errors.spouseAdjustedGrossIncome}
            touched={touched.spouseAdjustedGrossIncome}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            isSubmitting={isSubmitting}
            onBlur={handleBlur}
            validate={validateNumber()}
          />
        </SmallTextInputContainer>
      </Question>
    );
  }
}

export default SpouseAdjustedGrossIncome;
