import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { COLORS, FlexCenter, LoadingBars } from '@summer/ui-components/src';

import CompletePage from 'components/pages/tuitionReimbursement/dashboard/completePage';
import StartPage from 'components/pages/tuitionReimbursement/dashboard/startPage';
import { EDU_ASSIST_LANDING_PAGE_VIEWED } from 'constants/trackingEvents';
import { trackServerSide } from 'redux/actions/analytics.actions';
import { fetchTuitionReimbursement } from 'redux/actions/tuitionReimbursement.actions';
import { getTuitionReimbursement } from 'redux/selectors/tuitionReimbursement.selectors';
import { isLoadingWithInit } from 'redux/selectors/ui.selectors';

const TuitionReimbursementDashboard = () => {
  const tuitionReimbursement = useSelector(getTuitionReimbursement);
  const isLoading = useSelector(state =>
    isLoadingWithInit(state, 'fetchTuitionReimbursement'),
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTuitionReimbursement());
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading) {
      dispatch(
        trackServerSide(EDU_ASSIST_LANDING_PAGE_VIEWED, {
          integrations: { All: true },
        }),
      );
    }
  }, [isLoading, dispatch]);

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingBars color={COLORS.azure} />
      </LoadingContainer>
    );
  }
  if (tuitionReimbursement.courses.length > 0) {
    return <CompletePage />;
  }
  return <StartPage />;
};

const LoadingContainer = styled(FlexCenter)`
  height: 100%;
`;

export default TuitionReimbursementDashboard;
