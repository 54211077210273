import { string } from 'yup';

import { USStatesPostal } from '@simplifidev/shared/dist/constants/states';

export const phoneNumberSchema = string().min(
  10,
  'Please enter a 10-digit phone number',
);

export const stateSchema = string()
  .nullable()
  .oneOf(Object.values(USStatesPostal))
  .required('Please choose a state');
