import React from 'react';
import styled from 'styled-components';

import { FlexRow, Header, Text, Row, COLORS } from '@summer/ui-components';

import {
  DefaultResolutionGroupText,
  DefaultResolutionGroupContactInfo,
  BlueBox,
} from 'components/pages/default/wizard/layout/DefaultDrawerContent';
import InfoTooltip from 'components/shared/InfoTooltip';
import RecommendedBadge from 'components/shared/RecommendedBadge';
import { MobileSize, SmallDesktopSize } from 'constants/styleguide';
import { dollars } from 'utils/numbers';

import {
  Middle,
  BlueBoxContents as RehabBlueBoxContents,
} from './LoanRehabilitationV1';

export const HowToStartLoanRehabilitation = () => (
  <>
    <TextContent>
      <DefaultResolutionGroupText optionName="rehabilitation" />
    </TextContent>
    <DefaultResolutionGroupContactInfo />

    <BlueBox>
      <RehabBlueBoxContents />
    </BlueBox>
  </>
);

export const LoanRehabilitationRecommendation = ({
  estIdrMonthlyPayment,
  estRehabMonthlyPayment,
}) => (
  <>
    <ContentRow mobileCenter desktopStart>
      <RecommendedBadge />
    </ContentRow>
    <ContentRow mobileCenter desktopStart>
      <Header h2 data-testid="default-rec-header">
        Loan Rehabilitation
      </Header>
    </ContentRow>
    <Description paragraph>
      Loan Rehabilitation is your best option. To get your loans back on track,
      you will make nine on-time payments based on your income.
    </Description>
    <MonthlyPayments>
      <MonthlyPaymentsContent>
        <Payment>
          <PaymentText small>
            Est. monthly payment for the next 9 months:
          </PaymentText>
          <PaymentAmount>
            <HeaderInfoValue valueColor={COLORS.darkerGrey}>
              <span className="symbol">$</span>
              {dollars(estRehabMonthlyPayment, {
                precision: 0,
                showSymbol: false,
              })}
            </HeaderInfoValue>
            <PaymentsInfoTooltip>
              <InfoTooltip
                top={2}
                width={480}
                size={18}
                text="In loan rehabilitation, you’ll make nine on time monthly payments based on your income. We estimate that this is what your rehabilitation monthly payment would be. Once your loan is out of default at the end of nine months, you can enroll in an income-driven repayment plan to keep making payments based on your income."
              />
            </PaymentsInfoTooltip>
          </PaymentAmount>
        </Payment>
        <Breakline />
        <Payment>
          <PaymentText small>Est. monthly payment after (on IDR):</PaymentText>
          <PaymentAmount>
            <HeaderInfoValue valueColor={COLORS.azure}>
              <span className="symbol">$</span>
              {dollars(estIdrMonthlyPayment, {
                precision: 0,
                showSymbol: false,
              })}
            </HeaderInfoValue>
            <PaymentsInfoTooltip>
              <InfoTooltip
                top={2}
                width={480}
                size={18}
                text="Once your loan is out of default, you can enroll in an income-driven repayment (IDR) plan to keep making payments based on your income. We estimate that this is what your monthly payment would be in an IDR plan."
              />
            </PaymentsInfoTooltip>
          </PaymentAmount>
        </Payment>
      </MonthlyPaymentsContent>
    </MonthlyPayments>
    <TextContent>
      <Middle />
    </TextContent>
  </>
);

const ContentRow = styled(Row)`
  margin: 0;
`;

const Description = styled(Text)`
  margin-top: 16px;

  @media (max-width: ${MobileSize}) {
    margin: 8px 8px 0;
  }
`;

const MonthlyPayments = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
  margin: 24px 0;
  padding: 24px;
  background-color: ${COLORS.lighterGrey};
`;

const MonthlyPaymentsContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${SmallDesktopSize}) {
    flex-direction: column;
  }
`;

const Payment = styled(Text)`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  flex-direction: column;

  @media (max-width: ${SmallDesktopSize}) {
    flex-direction: row;
  }
`;

const PaymentText = styled(Text)`
  align-self: flex-start;
  margin-bottom: 4px;

  @media (max-width: ${SmallDesktopSize}) {
    margin-bottom: 0;
    margin-right: 24px;
  }
`;

const HeaderInfoValue = styled.span`
  font-size: 32px;
  font-weight: bold;
  color: ${({ valueColor }) => valueColor};

  @media (max-width: ${MobileSize}) {
    font-size: 24px;
  }

  .symbol {
    font-size: 18px;
    vertical-align: super;
    @media (max-width: ${MobileSize}) {
      font-size: 12px;
    }
  }
`;

const Breakline = styled.div`
  width: 2px;
  background-color: ${COLORS.grey};
  margin: 0 24px;

  @media (max-width: ${SmallDesktopSize}) {
    margin: 24px 0;
    height: 2px;
    width: 100%;
  }
`;
const PaymentAmount = styled(FlexRow)`
  align-self: flex-end;

  @media (max-width: ${MobileSize}) {
    align-self: center;
  }
`;

const TextContent = styled.div`
  @media (max-width: ${MobileSize}) {
    margin: 0 8px;
  }
`;

const PaymentsInfoTooltip = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;
