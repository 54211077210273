import React from 'react';
import { Route } from 'react-router-dom';

import Guide from 'components/layout/Guide/Guide';
import Onboarding from 'components/layout/OnBoarding';
import Dashboard from 'components/layout/dashboard/Dashboard';

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout {...props} {...rest}>
        <Component {...props} />
      </Layout>
    )}
  />
);

export const DashboardRoute = ({ component: Component, ...rest }) => (
  <AppRoute {...rest} layout={Dashboard} component={Component} />
);

export const OnboardingRoute = ({ component: Component, ...rest }) => (
  <AppRoute {...rest} layout={Onboarding} component={Component} />
);

export const GuideRoute = ({ component: Component, ...rest }) => (
  <AppRoute {...rest} layout={Guide} component={Component} />
);
