import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { idrNetworkClear } from 'redux/actions/idr.actions';
import { checkIdrNetworkSuccess } from 'redux/selectors/idr.selectors';

// This hook can be used to call a function after a
// network request has been completed successfully AND
// redux state has been updated in the IDR questionnaire
// (the part that uses the wizard hooks).
export const useOnSuccess = (requestLabel, onSuccess) => {
  const dispatch = useDispatch();
  const networkSuccess = useSelector(state =>
    checkIdrNetworkSuccess(state, requestLabel),
  );
  useEffect(() => {
    if (networkSuccess) {
      dispatch(idrNetworkClear({ meta: { label: requestLabel } }));
      onSuccess();
    }
  }, [networkSuccess, requestLabel, dispatch, onSuccess]);
};
