import {
  NETWORK_SUCCESS,
  POST_PLAID_INTEGRATION,
  DELETE_PLAID_INTEGRATION,
  CREATE_PLAID_LINK_TOKEN,
  CREATE_PLAID_UPDATE_LINK_TOKEN,
  REENABLE_PLAID_INTEGRATION,
  FETCH_PLAID_INTEGRATIONS,
  FETCH_PLAID_TRANSACTIONS,
} from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import { fetchAllLoans } from 'redux/actions/loans.actions';
import {
  setPlaidIntegrations,
  setPlaidTransactions,
  fetchPlaidIntegrations,
} from 'redux/actions/plaid.actions';
import handleSideEffects from 'redux/handleSideEffects';
import { getIsRetirementMatchDemo } from 'redux/selectors/flags.selectors';

const plaidMiddleware = handleSideEffects({
  [POST_PLAID_INTEGRATION]: (
    dispatch,
    { payload, institution, successAction },
    state,
  ) => {
    const isDemo = getIsRetirementMatchDemo(state);
    dispatch(
      apiRequest({
        url: '/v1/plaid',
        method: 'POST',
        body: {
          publicToken: payload.publicToken,
        },
        fromAction: POST_PLAID_INTEGRATION,
        label: 'postPlaidIntegration',
        meta: {
          successAction,
          institution,
          isDemo,
        },
      }),
    );
  },

  [DELETE_PLAID_INTEGRATION]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: `/v1/plaid/${payload.plaidIntegrationUuid}`,
        method: 'DELETE',
        fromAction: DELETE_PLAID_INTEGRATION,
        label: 'deletePlaidIntegration',
      }),
    );
  },

  [CREATE_PLAID_LINK_TOKEN]: (dispatch, { payload, onSuccess }) => {
    dispatch(
      apiRequest({
        url: '/v1/plaid/link-token',
        method: 'POST',
        body: payload,
        fromAction: CREATE_PLAID_LINK_TOKEN,
        label: 'createPlaidLinkToken',
        meta: { onSuccess },
      }),
    );
  },

  [CREATE_PLAID_UPDATE_LINK_TOKEN]: (dispatch, { payload, onSuccess }) => {
    const { linkCustomizationName } = payload;
    dispatch(
      apiRequest({
        url: `/v1/plaid/link-token/${payload.plaidIntegrationUuid}/update`,
        method: 'POST',
        body: { linkCustomizationName },
        fromAction: CREATE_PLAID_UPDATE_LINK_TOKEN,
        label: 'createPlaidUpdateLinkToken',
        meta: { onSuccess },
      }),
    );
  },

  [REENABLE_PLAID_INTEGRATION]: (dispatch, { payload, successAction }) => {
    dispatch(
      apiRequest({
        url: `/v1/plaid/${payload.plaidIntegrationUuid}`,
        method: 'PUT',
        fromAction: REENABLE_PLAID_INTEGRATION,
        label: 'reenablePlaidIntegration',
        meta: { successAction },
      }),
    );
  },

  [FETCH_PLAID_INTEGRATIONS]: dispatch => {
    dispatch(
      apiRequest({
        url: `/v1/plaid`,
        method: 'GET',
        fromAction: FETCH_PLAID_INTEGRATIONS,
        label: 'fetchPlaidIntegrations',
      }),
    );
  },

  [FETCH_PLAID_TRANSACTIONS]: dispatch => {
    dispatch(
      apiRequest({
        url: `/v1/plaid/transactions`,
        method: 'GET',
        fromAction: FETCH_PLAID_TRANSACTIONS,
        label: 'fetchPlaidTransactions',
      }),
    );
  },

  [NETWORK_SUCCESS]: (dispatch, { payload, meta }) => {
    if (meta.fromAction === POST_PLAID_INTEGRATION) {
      const { successAction } = meta;
      if (!meta.isDemo) {
        dispatch(fetchPlaidIntegrations());
      }
      if (successAction) {
        dispatch(successAction());
      }
    }

    if (meta.fromAction === DELETE_PLAID_INTEGRATION) {
      dispatch(fetchAllLoans());
    }

    if (meta.fromAction === REENABLE_PLAID_INTEGRATION) {
      dispatch(fetchAllLoans());
      if (meta.successAction) {
        dispatch(meta.successAction());
      }
    }

    if (meta.fromAction === CREATE_PLAID_LINK_TOKEN) {
      meta.onSuccess(payload.response.linkToken);
    }

    if (meta.fromAction === CREATE_PLAID_UPDATE_LINK_TOKEN) {
      meta.onSuccess(payload.response.linkToken);
    }

    if (meta.fromAction === FETCH_PLAID_INTEGRATIONS) {
      dispatch(setPlaidIntegrations(payload.response.plaidIntegrations));
    }

    if (meta.fromAction === FETCH_PLAID_TRANSACTIONS) {
      dispatch(setPlaidTransactions(payload.response.transactions));
    }
  },
});

export default plaidMiddleware;
