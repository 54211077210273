import React from 'react';
import styled from 'styled-components';

import {
  FlexCenter,
  FlexColumn,
  IconMedallion,
  Header,
  Icon,
  QuestionCircle,
  BackgroundSvg,
  COLORS,
} from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import { MobileSize, TabletSize } from 'constants/styleguide';
import { hexToRgba } from 'utils/common';

const SupportHeader = ({ platformType }) => {
  return (
    <HeaderBackground>
      <BackgroundShape
        fill={hexToRgba(COLORS.sand, 0.12)}
        SvgComponent={BackgroundSvg}
      />
      <Content>
        <IconMedallion SvgComponent={QuestionCircle} color={COLORS.sand} />
        <Title>
          <Header as={platformType === MOBILE ? 'h2' : 'h1'}>
            How can we help you?
          </Header>
        </Title>
      </Content>
    </HeaderBackground>
  );
};

const HeaderBackground = styled(FlexCenter)`
  padding-bottom: 40px;
  text-align: center;

  @media (max-width: ${MobileSize}) {
    padding: 16px 8px 40px;
  }
`;

const Title = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;

  @media (max-width: ${MobileSize}) {
    margin-top: 16px;
  }
`;

const Content = styled(FlexColumn)`
  height: 100%;
  max-width: 664px;
  align-items: center;
  z-index: 1;
`;

const BackgroundShape = styled(Icon)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: rotate(172deg) scale(40);
  z-index: 1;

  @media (max-width: ${TabletSize}) {
    left: 48%;
    transform: rotate(184deg) scale(36);
  }

  @media (max-width: ${MobileSize}) {
    left: 25%;
    transform: rotate(188deg) scale(24);
  }
`;

export default Platform(SupportHeader);
