import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { FormStatus } from '@simplifidev/shared/dist/constants/forms';

import { FlexRow, Text, LoadingBars, COLORS } from '@summer/ui-components';

import InfoTooltip from 'components/shared/InfoTooltip';
import { Link, Strong } from 'components/shared/typography';
import { MobileSize } from 'constants/styleguide';
import { createIdrForm, moveToIdrCurrentStep } from 'redux/actions/idr.actions';
import {
  getCurrentIdrFormStatus,
  getCurrentIdrFormStep,
} from 'redux/selectors/idr.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';

const CreateNewFormCTA = ({ idrStatus, currentStep, isLoading, dispatch }) => {
  const idrInProgress = idrStatus === FormStatus.STARTED;

  const ctaCopy = idrInProgress ? 'Continue' : 'Start a new application';

  const handleClick = () => {
    if (idrInProgress) {
      dispatch(moveToIdrCurrentStep(currentStep));
    } else {
      dispatch(createIdrForm());
    }
  };

  return (
    <Container>
      <Text bold left>
        <span role="img" aria-label="hand wave">
          👋🏼
        </span>
        &nbsp;&nbsp; Has your income decreased or is it time to recertify?
        <InfoTooltip
          text={
            <>
              <Strong>Recalculation:</Strong> If your income has decreased or if
              you recently had a child, then you can submit another IDR form for
              a lower monthly payment.
              <br />
              <br />
              <Strong>Recertification:</Strong> If it has been more than 10
              months since you last submitted your IDR application, then you
              should submit another IDR application to prevent your monthly
              payment from increasing.
            </>
          }
          top={2}
        />
      </Text>
      {isLoading ? (
        <LoadingBars color={COLORS.azure} />
      ) : (
        <Link onClick={handleClick}>{ctaCopy}</Link>
      )}
    </Container>
  );
};

const Container = styled(FlexRow)`
  background-color: ${COLORS.white};

  margin-bottom: 24px;
  padding: 24px;

  align-items: center;
  justify-content: space-between;

  .text {
    // Emoji span shifts the text baseline, margin is here to correct
    margin-top: -3px;
    margin-right: 8px;

    span {
      font-size: 20px;
    }
  }

  @media (max-width: ${MobileSize}) {
    flex-direction: column;

    & > .text,
    & > .link {
      width: 100%;
    }

    .link {
      margin-top: 12px;
      text-align: right;
    }

    .loading-bars {
      margin-top: 12px;
      margin-left: auto;
      margin-right: 0;
    }
  }
`;

const mapStateToProps = state => ({
  idrStatus: getCurrentIdrFormStatus(state),
  currentStep: getCurrentIdrFormStep(state),
  isLoading:
    isLoading(state, 'createIdrForm') || isLoading(state, 'fetchIdrForms'),
});

export default connect(mapStateToProps)(CreateNewFormCTA);
