import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Header, Icon, COLORS } from '@summer/ui-components';

import { CollapseSidebarSize } from 'constants/styleguide';
import { isSidebarVisible } from 'redux/selectors/ui.selectors';

const ContentHeader = ({
  children,
  SvgComponent,
  noIconOnMobile,
  iconClassName,
  showTitleOnMobile,
}) => {
  return (
    <>
      <IconContainer noIconOnMobile={noIconOnMobile}>
        <SvgIcon SvgComponent={SvgComponent} className={iconClassName} />
      </IconContainer>
      <Container showTitleOnMobile={showTitleOnMobile}>
        <Title showTitleOnMobile={showTitleOnMobile}>
          <Header h2 center>
            {children}
          </Header>
        </Title>
      </Container>
    </>
  );
};

const IconContainer = styled.div`
  height: 56px;

  @media (max-width: ${CollapseSidebarSize}) {
    height: 44px;
    ${({ noIconOnMobile }) => noIconOnMobile && `display: none;`}
  }
`;

const Container = styled.div`
  width: 100%;

  @media (max-width: ${CollapseSidebarSize}) {
    ${({ showTitleOnMobile }) => !showTitleOnMobile && `display: none;`}
  }
`;

const SvgIcon = styled(Icon)`
  margin: 0 auto;
  width: 56px;
  height: 56px;

  @media (max-width: ${CollapseSidebarSize}) {
    width: 44px !important;
    height: 44px !important;
  }

  & > svg > g {
    fill: ${COLORS.azure};
  }

  /* need to change color here because it can't be done with
   * Icon component props due to how these SVGs are constructed.
   */
  &.gear-icon > svg > g {
    fill: none;
    stroke: ${COLORS.azure};
  }

  &.presentation-icon > svg > g > g {
    fill: ${COLORS.azure};
  }

  &.refinancing-icon > svg > g {
    fill: none;
    stroke: ${COLORS.azure};
  }
`;

const Title = styled.div`
  margin: 16px 0 0 0;

  @media (max-width: ${CollapseSidebarSize}) {
    margin: 16px 8px 0;
    ${({ showTitleOnMobile }) => !showTitleOnMobile && `display: none;`}
  }
`;

const mapStateToProps = state => ({
  sideBarVisible: isSidebarVisible(state),
});

export default connect(mapStateToProps)(ContentHeader);
