import { map, findIndex } from 'lodash';
import { getOr, set } from 'lodash/fp';

import {
  SET_SIGNATURE_REQUEST_LIST,
  UPDATE_SIGNATURE_REQUEST,
  SET_SIGNATURE_REQUEST,
} from 'constants/actionTypes';

const initialState = [];

const signatureRequests = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SIGNATURE_REQUEST_LIST: {
      return getOr([], 'signatureRequestList', payload);
    }

    case SET_SIGNATURE_REQUEST:
      return [payload.url];

    case UPDATE_SIGNATURE_REQUEST: {
      const { signatureRequestId, complete } = payload;

      return map(state, signatureRequest => {
        const signerIndex = findIndex(signatureRequest.signers, {
          signatureRequestsId: signatureRequestId,
        });

        if (signerIndex !== -1) {
          return set(
            `signers[${signerIndex}].complete`,
            complete,
            signatureRequest,
          );
        }
        return signatureRequest;
      });
    }

    default:
      return state;
  }
};

export default signatureRequests;
