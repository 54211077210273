import React from 'react';

import { TextInput } from '@summer/ui-components';

import { InputWrapper } from 'components/pages/idr/wizard/steps/Submit/shared/Inputs';

const SpouseEmail = ({
  formik: { values, errors, handleChange, handleBlur, touched, isSubmitting },
}) => (
  <InputWrapper>
    <TextInput
      label="Email Address"
      name="submit.spouseEmail"
      touched={touched.spouseEmail}
      isSubmitting={isSubmitting}
      error={errors.spouseEmail}
      value={values.spouseEmail}
      onChange={handleChange}
      onBlur={handleBlur}
      inputMode="email"
    />
  </InputWrapper>
);

export default SpouseEmail;
