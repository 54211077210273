import {
  SET_CSP_INPUTS,
  FETCH_CSP_CALCULATOR_DATA,
  SET_CSP_CALCULATOR_DATA,
  POST_CSP_CALCULATOR_RESULTS,
  SET_CSP_CALCULATOR_RESULTS,
  FETCH_ALL_CSP_SCHOLARSHIPS,
  FETCH_CSP_SCHOLARSHIP_BY_UID,
  SET_CSP_SCHOLARSHIPS,
} from 'constants/actionTypes';

export const fetchCSPCalculatorData = () => ({
  type: FETCH_CSP_CALCULATOR_DATA,
});

export const setCSPCalculatorData = payload => ({
  type: SET_CSP_CALCULATOR_DATA,
  payload,
});

export const setCSPInputs = payload => ({
  type: SET_CSP_INPUTS,
  payload,
});

export const postCSPCalculatorResults = (
  payload,
  { onSuccess, onFailure },
) => ({
  type: POST_CSP_CALCULATOR_RESULTS,
  payload,
  meta: { onSuccess, onFailure },
});

export const setCSPCalculatorResults = payload => ({
  type: SET_CSP_CALCULATOR_RESULTS,
  payload,
});

export const fetchAllScholarships = (page, pageSize) => ({
  type: FETCH_ALL_CSP_SCHOLARSHIPS,
  payload: { page, pageSize },
});

export const fetchScholarshipByUid = uid => ({
  type: FETCH_CSP_SCHOLARSHIP_BY_UID,
  payload: {
    uid,
  },
});

export const setScholarships = docs => ({
  type: SET_CSP_SCHOLARSHIPS,
  payload: {
    docs,
  },
});
