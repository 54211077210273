import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Alert, Text } from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import {
  fsaPslfLink,
  fsaRefiHelpLink,
  fsaRepaymentPlansLink,
} from 'constants/externalLinks/fsa';
import { userHasFederalLoans } from 'redux/selectors/loans.selectors';

const FederalRefiAlert = ({ userHasFederalLoans }) => {
  return (
    userHasFederalLoans && (
      <StyledAlert theme="caution" dismissable={false}>
        Refinancing{' '}
        <Text as="em" bold small>
          federal loans
        </Text>{' '}
        will cause you to lose government protections and benefits, such as{' '}
        <Link inheritColor underline href={fsaRepaymentPlansLink}>
          federal repayment plans
        </Link>{' '}
        and{' '}
        <Link inheritColor underline href={fsaPslfLink}>
          loan forgiveness
        </Link>
        . Weigh the benefits against these risks before refinancing federal
        loans.{' '}
        <Link inheritColor underline href={fsaRefiHelpLink}>
          Learn more
        </Link>
        .
      </StyledAlert>
    )
  );
};

const StyledAlert = styled(Alert)`
  background-color: #fffcede6;
  margin-bottom: 16px;
  text-align: left;
`;

const mapStateToProps = state => ({
  userHasFederalLoans: userHasFederalLoans(state),
});

export default connect(mapStateToProps)(FederalRefiAlert);
