import React from 'react';
import styled from 'styled-components';

import {
  FlexRow,
  Header,
  LockNew,
  SvgImage,
  Text,
  COLORS,
} from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { PRIVACY_POLICY_LINK } from 'constants/externalLinks/supportLinks';
import { MobileSize } from 'constants/styleguide';

const SecurityFooter = () => (
  <OuterContainer>
    <InnerContainer>
      <HeaderContainer>
        <InlineIcon width={24} height={24} SvgComponent={LockNew} />
        <Header h3>We take security seriously.</Header>
      </HeaderContainer>
      <Text small>
        Providing the most secure service is our top priority.{' '}
        <Link inheritColor underline href={PRIVACY_POLICY_LINK}>
          Learn more.
        </Link>
      </Text>
    </InnerContainer>
  </OuterContainer>
);

const OuterContainer = styled.div`
  margin-top: auto;
  background-color: ${COLORS.lightestGrey};
  width: 100%;
  padding-top: 32px;
  padding-bottom: 80px;

  @media (max-width: ${MobileSize}) {
    margin-top: 16px;
  }
`;

const InnerContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 460px;

  @media (max-width: ${MobileSize}) {
    margin: 0 20px;
  }
`;

const InlineIcon = styled(SvgImage)`
  margin-right: 12px;
  align-items: center;
`;

const HeaderContainer = styled(FlexRow)`
  margin-bottom: 12px;
`;

export default SecurityFooter;
