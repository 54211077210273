import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ConsolidationSubmitBasic from 'components/pages/idr/wizard/shared/SummerBasicSubmitPage/scenarios/ConsolidationSubmitBasic';
import IdrSubmitBasic from 'components/pages/idr/wizard/shared/SummerBasicSubmitPage/scenarios/IdrSubmitBasic';
import PlanCard from 'components/pages/idr/wizard/steps/ReviewYourPlan/NewRYP/PlanCard';
import {
  getSelectedPath,
  selectedPathIncludesConsolidation,
} from 'redux/selectors/idr.selectors';

const SelectedPlan = ({ path }) => {
  return <PlanCard key={path.id} path={path} isSelectedPath />;
};

const SummerBasicSubmitPage = ({ selectedPath, includesConsolidation }) => {
  const InstructionsContent = includesConsolidation ? (
    <ConsolidationSubmitBasic />
  ) : (
    <IdrSubmitBasic />
  );

  return (
    <SubmitContainer>
      <SelectedPlan path={selectedPath} />
      <InstructionsContainer>{InstructionsContent}</InstructionsContainer>
    </SubmitContainer>
  );
};

const SubmitContainer = styled.div`
  width: 100%;
  max-width: 1192px;
  margin-left: auto;
  margin-right: auto;
`;

const InstructionsContainer = styled.div`
  border-radius: 12px;
  margin-bottom: 32px;
`;

const mapStateToProps = state => ({
  selectedPath: getSelectedPath(state),
  includesConsolidation: selectedPathIncludesConsolidation(state),
});

export default connect(mapStateToProps)(SummerBasicSubmitPage);
