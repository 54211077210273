import React from 'react';

import { Text } from '@summer/ui-components';

import { MOBILE } from 'components/hoc/Platform';

const DropZoneLabel = ({ isDragAccept, isDragReject, platformType }) => {
  if (isDragAccept) {
    return (
      <Text large weight="medium">
        Drop file
      </Text>
    );
  }
  if (isDragReject) {
    return (
      <Text large weight="medium">
        Wrong file type
      </Text>
    );
  }
  if (platformType === MOBILE) {
    return (
      <Text large weight="medium" decoration="underline">
        Browse files
      </Text>
    );
  }
  return (
    <Text large weight="medium">
      Drag and drop, or{' '}
      <Text as="span" large weight="medium" decoration="underline">
        browse files
      </Text>
    </Text>
  );
};

export default DropZoneLabel;
