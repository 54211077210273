import { push as redirectTo } from 'connected-react-router';
import React, { useEffect, useState, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';

import { COLORS, LoadingBars, TabGroup, Card } from '@summer/ui-components';

import PlaidExtra from 'components/pages/idr/wizard/steps/YourLoansPlaid/Loans/SyncView/PlaidExtra';
import Documents from 'components/pages/retirementMatch/dashboard/Documents';
import Servicers from 'components/pages/retirementMatch/dashboard/Servicers';
import SuccessModal from 'components/pages/retirementMatch/dashboard/SuccessModal';
import StartPage from 'components/pages/retirementMatch/dashboard/startPage';
import RetirementMatchLandingPage from 'components/pages/retirementMatch/shared/retirementMatchLandingPage';
import { usePlaid } from 'hooks/plaid';
import demoPlaidIntegrations from 'mocks/fixtures/plaidIntegrations.json';
import demoPlaidTransactions from 'mocks/fixtures/plaidTransactions.json';
import { fetchFileCollection } from 'redux/actions/fileCollections.actions';
import {
  fetchPlaidIntegrations,
  fetchPlaidTransactions,
  setPlaidIntegrations,
  setPlaidTransactions,
} from 'redux/actions/plaid.actions';
import { fetchRetirementMatch } from 'redux/actions/retirementMatch.actions';
import { getRetirementMatchDocuments } from 'redux/selectors/fileCollections.selectors';
import { getIsRetirementMatchDemo } from 'redux/selectors/flags.selectors';
import { getMatchedPlaidPayments } from 'redux/selectors/plaidTransactions.selectors';
import { hasUserConsentedRetirementMatch } from 'redux/selectors/retirementMatch.selectors';
import { isLoadingWithInit, isLoading } from 'redux/selectors/ui.selectors';

const RetirementMatch = ({
  userHasConsentedRetirementMatch,
  retirementMatchDocuments,
  plaidTransactions,
  isDemo,
  isLoading,
  location,
}) => {
  const [showPlaidCards, setShowPlaidCards] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isPlaidOpening, setIsPlaidOpening] = useState(false);
  const [isPlaidDone, setIsPlaidDone] = useState(false);

  const dispatch = useDispatch();
  const successAction = !isDemo ? fetchPlaidTransactions : null;

  const {
    open: openPlaid,
    event: plaidEvent,
    isLoading: isPlaidReady,
    institutionId,
  } = usePlaid({
    successAction,
  });

  const showStartPage = !userHasConsentedRetirementMatch;
  const fromFileUpload =
    location?.state?.origin === '/retirement-match/upload-documents';

  useEffect(
    function fetchRetirementMatchOnMount() {
      dispatch(fetchRetirementMatch());
    },
    [dispatch],
  );

  useEffect(
    function fetchFileCollectionsOnMount() {
      dispatch(
        fetchFileCollection({ collection: 'retirementMatchPaymentProof' }),
      );
    },
    [dispatch],
  );

  useEffect(
    function fetchPlaidOnMount() {
      if (!isDemo) {
        dispatch(fetchPlaidTransactions());
        dispatch(fetchPlaidIntegrations());
      }
    },
    [dispatch, isDemo],
  );

  useEffect(() => {
    if (plaidEvent === 'OPEN') {
      // Timeout padding to ensure that the Plaid fade in animation completes
      setTimeout(() => setIsPlaidOpening(false), 1000);
    }
  }, [plaidEvent, setIsPlaidOpening]);

  useEffect(() => {
    if (isDemo && plaidEvent === 'SUCCESS') {
      dispatch(setPlaidIntegrations(demoPlaidIntegrations.plaidIntegrations));
      dispatch(setPlaidTransactions(demoPlaidTransactions.transactions));
    }
  }, [isDemo, plaidEvent, dispatch]);

  useEffect(
    function showSuccessModal() {
      if (isPlaidDone || fromFileUpload) {
        setShowSuccessModal(true);
      }
    },
    [isPlaidDone, fromFileUpload],
  );

  const closeSuccessModal = useCallback(() => {
    setShowSuccessModal(false);
    dispatch(
      redirectTo(
        fromFileUpload
          ? '/retirement-match/dashboard/documents'
          : '/retirement-match/dashboard',
      ),
    );
  }, [dispatch, fromFileUpload, setShowSuccessModal]);

  if ((isLoading && !showPlaidCards) || isPlaidOpening) {
    return (
      <RetirementMatchLandingPage>
        <Card>
          <LoadingBars color={COLORS.azure} />
        </Card>
      </RetirementMatchLandingPage>
    );
  }

  return (
    <>
      <PlaidExtra
        openPlaid={openPlaid}
        plaidEvent={plaidEvent}
        isPlaidDone={isPlaidDone}
        setIsPlaidDone={setIsPlaidDone}
        showDataSummary={true}
        hasFederalLoans={false}
        showPlaidCards={showPlaidCards}
        setShowPlaidCards={setShowPlaidCards}
        showFederalCopy={false}
        institutionId={institutionId}
      />
      {!showPlaidCards && (
        <RetirementMatchLandingPage>
          {showStartPage ? (
            <StartPage
              openPlaid={openPlaid}
              setIsPlaidDone={setIsPlaidDone}
              isPlaidReady={isPlaidReady}
              setIsPlaidOpening={setIsPlaidOpening}
            />
          ) : (
            <>
              <SuccessModal
                isOpen={showSuccessModal}
                togglePopup={closeSuccessModal}
              />
              <TabGroup
                baseUrl="/retirement-match/dashboard"
                tabs={[
                  {
                    title: 'Servicers',
                    content: (
                      <Servicers
                        PlaidTransactionsData={plaidTransactions}
                        openPlaid={openPlaid}
                        setIsPlaidDone={setIsPlaidDone}
                        isPlaidReady={isPlaidReady}
                      />
                    ),
                  },
                  {
                    title: 'Documents',
                    content: (
                      <Documents
                        retirementMatchDocuments={retirementMatchDocuments}
                      />
                    ),
                    url: '/documents',
                  },
                ]}
              />
            </>
          )}
        </RetirementMatchLandingPage>
      )}
    </>
  );
};

const mapStateToProps = state => {
  const isDemo = getIsRetirementMatchDemo(state);

  const isPlaidLoadingDemo =
    isLoading(state, 'fetchPlaidIntegrations') ||
    isLoading(state, 'fetchPlaidTransactions');

  const isPlaidLoadingV1 =
    isLoadingWithInit(state, 'fetchPlaidIntegrations') ||
    isLoadingWithInit(state, 'fetchPlaidTransactions');

  const isPlaidDataLoading = isDemo ? isPlaidLoadingDemo : isPlaidLoadingV1;

  return {
    retirementMatchDocuments: getRetirementMatchDocuments(state),
    plaidTransactions: getMatchedPlaidPayments(state),
    userHasConsentedRetirementMatch: hasUserConsentedRetirementMatch(state),
    isDemo,
    isLoading:
      isLoadingWithInit(state, 'fetchRetirementMatch') ||
      isLoadingWithInit(state, 'fetchFileCollection') ||
      isPlaidDataLoading,
  };
};

export default connect(mapStateToProps)(RetirementMatch);
