import Prismic from 'prismic-javascript';

const apiEndpoint = 'https://summer.prismic.io/api/v2';
export const linkResolver = doc => {
  if (doc.type === 'forgiveness_programs') {
    return `forgiveness-finder/${doc.uid}`;
  }
  return '/';
};

class cms {
  static async getAllByType({ type, pageSize = 100, page = 1 }) {
    const option = { pageSize, page };
    const prismicClient = await Prismic.api(apiEndpoint);
    return prismicClient.query(
      Prismic.Predicates.at('document.type', type),
      option,
    );
  }

  static async getDocByUid({ type, uid }) {
    const prismicClient = await Prismic.api(apiEndpoint);
    return prismicClient.getByUID(type, uid);
  }
}

export default cms;
