import { flow, set, get, mapValues, isNil } from 'lodash/fp';

import { SET_PROFILE, SET_USER_SURVEY_DATA } from 'constants/actionTypes';

export const initialState = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  streetAddress1: '',
  city: '',
  state: '',
  zipcode: '',
  phone: '',
  gradStatus: '',
  currentEmployer: '',
  updatedAt: null,
};

const profileReducer = (state = initialState, { type, payload }) => {
  const profileData = get('profile', payload);

  switch (type) {
    case SET_PROFILE: {
      return flow([
        set('firstName', profileData.firstName),
        set('lastName', profileData.lastName),
        set('dateOfBirth', profileData.dateOfBirth),
        set('streetAddress1', profileData.streetAddress1),
        set('city', profileData.city),
        set('state', profileData.state),
        set('zipcode', profileData.zipcode),
        set('phone', profileData.phone),
        set('gradStatus', profileData.gradStatus),
        set('currentEmployer', profileData.currentEmployer),
        set('updatedAt', profileData.updatedAt || state.updatedAt),
        mapValues(value => (isNil(value) ? '' : value)),
      ])(state);
    }

    case SET_USER_SURVEY_DATA: {
      return flow([
        set('state', payload.state),
        set('phone', payload.phone),
        set('gradStatus', payload.gradStatus),
      ])(state);
    }

    default:
      return state;
  }
};

export default profileReducer;
