import React from 'react';
import styled from 'styled-components';

import {
  Header,
  FlexCenter,
  Text,
  SvgImage,
  SummerBasicConsolidation,
} from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { fsaConsolidationToolLink } from 'constants/externalLinks/fsa';
import { TabletSize } from 'constants/styleguide';

const ConsolidationSubmitBasic = () => {
  return (
    <Container data-testid="consolidation-submit-basic">
      <SummerBasicIcon SvgComponent={SummerBasicConsolidation} />
      <TextContainer>
        <InstructionsHeader h3>
          Consolidating your loans and enrolling in IDR
        </InstructionsHeader>
        <Text paragraph>
          Some types of federal student loans aren&rsquo;t eligible for all of
          the IDR plans that could lower your monthly payments. By consolidating
          those loans, you&rsquo;d be combining them into one new federal loan
          that&rsquo;s eligible for IDR.
        </Text>
        <br />
        <Text paragraph>
          You can complete the process of consolidating your loans and enrolling
          in the IDR plan recommended above through{' '}
          <Link underline inheritColor href={fsaConsolidationToolLink}>
            Federal Student Aid&rsquo;s online tool
          </Link>
          .
        </Text>
        <br />
        <Text paragraph>
          If you have multiple types of student loans, you&rsquo;ll have the
          option of selecting which loans to consolidate. Direct loans are
          already eligible for all IDR plans and don&rsquo;t need to be
          consolidated. If you have FFEL, Parent PLUS or Perkins loans, they
          must be consolidated to become eligible for most IDR plans.
        </Text>
        <br />
        <Text paragraph>
          Note: You should not consolidate Parent PLUS loans with other loans.
          Consolidated Parent PLUS loans are only eligible for one type of IDR
          plan, Income-Contingent Repayment (ICR).
        </Text>
      </TextContainer>
    </Container>
  );
};

const Container = styled(FlexCenter)`
  padding: 30px 90px;
  width: 100%;

  @media (max-width: ${TabletSize}) {
    padding: 0 16px;
    flex-direction: column;
  }
`;

const InstructionsHeader = styled(Header)`
  margin-bottom: 12px;
`;

const TextContainer = styled.div`
  margin-left: 98px;

  @media (max-width: ${TabletSize}) {
    margin-top: 16px;
    margin-left: 0px;
  }
`;

const SummerBasicIcon = styled(SvgImage)`
  max-width: 300px;

  @media (max-width: ${TabletSize}) {
    max-width: 200px;
  }
`;

export default ConsolidationSubmitBasic;
