export const refiTooltipText = {
  federalOnly: 'Monthly payment for your federal loans',
  privateOnly: 'Monthly payment for your private loans',
  federalAndPrivate:
    'Monthly payment for your combined federal and private loans',
};

export const getRefiKindObject = ({
  refiFederalRecommended,
  refiPrivateRecommended,
  hasFederalLoans,
  hasPrivateLoans,
  federalLoansEstimate = 0,
  privateLoansEstimate = 0,
}) => {
  if (refiFederalRecommended && refiPrivateRecommended) {
    return {
      copy: 'all your student loans',
      loansAmount: federalLoansEstimate + privateLoansEstimate,
    };
  }
  if (refiFederalRecommended) {
    return {
      copy: 'your federal loans',
      loansAmount: federalLoansEstimate,
    };
  }
  if (refiPrivateRecommended) {
    return {
      copy: 'your private loans',
      loansAmount: privateLoansEstimate,
    };
  }
  if (hasFederalLoans && hasPrivateLoans) {
    return {
      copy: 'all your student loans',
      loansAmount: federalLoansEstimate + privateLoansEstimate,
    };
  }
  if (hasFederalLoans) {
    return {
      copy: 'your federal loans',
      loansAmount: federalLoansEstimate,
    };
  }
  if (hasPrivateLoans) {
    return {
      copy: 'your private loans',
      loansAmount: privateLoansEstimate,
    };
  }

  return {
    copy: 'all your student loans',
    loansAmount: federalLoansEstimate + privateLoansEstimate,
  };
};

const FionaCreditRatingMap = {
  '750+': 'excellent',
  '700-749': 'excellent',
  '680-699': 'good',
  '600-679': 'fair',
  'below 600': 'poor',
};

export const mapPropsForFiona = ({
  annualIncome,
  creditScore,
  email,
  federalLoansEstimate,
  firstName,
  lastName,
  primaryPhone,
  privateLoansEstimate,
  userId,
  refiFederalRecommended,
  refiPrivateRecommended,
  hasFederalLoans,
  hasPrivateLoans,
}) => {
  const providedCreditRating = FionaCreditRatingMap[creditScore];

  return {
    app: {
      annualIncome: annualIncome / 100,
      email: encodeURIComponent(email),
      firstName,
      lastName,
      loanAmount:
        getRefiKindObject({
          federalLoansEstimate,
          privateLoansEstimate,
          refiFederalRecommended,
          refiPrivateRecommended,
          hasFederalLoans,
          hasPrivateLoans,
        }).loansAmount / 100,
      primaryPhone,
      ...(providedCreditRating && {
        providedCreditRating,
      }),
    },
    tag: {
      subid: userId,
    },
  };
};
