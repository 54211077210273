import { isNil } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { Text, FlexRow } from '@summer/ui-components';

import { MobileSize } from 'constants/styleguide';

export const DisplayData = ({ children, valueToCheck = null }) => {
  if (isNil(children) || isNil(valueToCheck)) {
    return (
      <Text as="span" size="inherit" color="secondary">
        Unavailable
      </Text>
    );
  }
  return children;
};

export const DrawerContentColumn = styled.div`
  flex: 1;

  & > div:not(:last-child) {
    margin-bottom: 24px;
  }

  & > div > div:first-child {
    margin-bottom: 8px;
  }

  @media (max-width: ${MobileSize}) {
    &:not(:first-child) > div:first-child {
      margin-top: 24px;
    }
  }
`;

export const DrawerContent = styled(FlexRow)`
  @media (max-width: ${MobileSize}) {
    flex-direction: column;
  }
`;
