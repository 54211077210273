import { last } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { COLORS } from '@summer/ui-components';

import { MobileSize } from 'constants/styleguide';

import {
  AdjustedGrossIncome,
  ChangeInIncome,
  CurrentlyEmployed,
  ReceivingUnemploymentIncome,
  UnemploymentIncome,
  UnemploymentEndDate,
  ExpectedNonUnemploymentIncome,
  ExpectedNextAnnualIncome,
  ExpectedAnnualGrossIncome,
  JointAdjustedGrossIncome,
  JointTaxReturn,
  MaritalStatus,
  NumberOfDependents,
  NumberOfKids,
  SpouseAdjustedGrossIncome,
  SpouseExpectedAnnualGrossIncome,
  SpouseHaveFsl,
  SpouseCurrentLoanBalance,
  SpouseIncomeChange,
  SpouseCurrentlyEmployed,
  SpouseReceivingUnemploymentIncome,
  SpouseUnemploymentIncome,
  SpouseUnemploymentEndDate,
  SpouseExpectedNonUnemploymentIncome,
  SpouseExpectedNextAnnualIncome,
  State,
} from './fields/index';

const UserExpectedIncomeQuestions = ({ formik, formik: { values } }) => {
  return (
    <>
      <CurrentlyEmployed formik={formik} />

      {values.currentlyEmployed === 'false' && (
        <>
          <ReceivingUnemploymentIncome formik={formik} />

          {values.receivingUnemploymentIncome === 'true' && (
            <UnemploymentIncome formik={formik} />
          )}

          {values.unemploymentIncome !== '' && (
            <UnemploymentEndDate formik={formik} />
          )}

          {values.unemploymentEndDate !== '' && (
            <ExpectedNonUnemploymentIncome formik={formik} />
          )}

          {values.expectedNonUnemploymentIncome !== '' && (
            <ExpectedNextAnnualIncome formik={formik} />
          )}
        </>
      )}

      {(values.currentlyEmployed === 'true' ||
        values.receivingUnemploymentIncome === 'false') && (
        <ExpectedAnnualGrossIncome formik={formik} />
      )}
    </>
  );
};

const SpouseExpectedIncomeQuestions = ({ formik, formik: { values } }) => {
  return (
    <>
      <SpouseCurrentlyEmployed formik={formik} />

      {values.spouseCurrentlyEmployed === 'false' && (
        <>
          <SpouseReceivingUnemploymentIncome formik={formik} />

          {values.spouseReceivingUnemploymentIncome === 'true' && (
            <SpouseUnemploymentIncome formik={formik} />
          )}

          {values.spouseUnemploymentIncome !== '' && (
            <SpouseUnemploymentEndDate formik={formik} />
          )}

          {values.spouseUnemploymentEndDate !== '' && (
            <SpouseExpectedNonUnemploymentIncome formik={formik} />
          )}

          {values.spouseExpectedNonUnemploymentIncome !== '' && (
            <SpouseExpectedNextAnnualIncome formik={formik} />
          )}
        </>
      )}

      {(values.spouseCurrentlyEmployed === 'true' ||
        values.spouseReceivingUnemploymentIncome === 'false') && (
        <SpouseExpectedAnnualGrossIncome formik={formik} />
      )}
    </>
  );
};

class TellUsAboutYou extends React.Component {
  constructor() {
    super();
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { children } = this.formRef.current;
    this.setState({
      questionsLength: children.length,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { children } = this.formRef.current;
    if (prevState && prevState.questionsLength < children.length) {
      this.setState({
        questionsLength: children.length,
      });
      // we using scrollIntoView and not scrollTo because Edge Browser
      last(children).scrollIntoView({ behavior: 'smooth' });
    }
  }

  render() {
    const { values, errors, touched, idrHandlers } = this.props;
    const formik = { values, errors, touched, ...idrHandlers };

    return (
      <Form ref={this.formRef}>
        {/* Generic Questions */}

        <State formik={formik} />

        <NumberOfKids formik={formik} />

        <NumberOfDependents formik={formik} />

        <MaritalStatus formik={formik} />

        {/* Single, separated & Can't Access questions */}
        {(values.maritalStatus === 'single' ||
          values.maritalStatus === 'separated' ||
          values.maritalStatus === 'cantAccess') && (
          <>
            <ChangeInIncome formik={formik} />

            {values.changeInIncome === 'no' && (
              <AdjustedGrossIncome formik={formik} />
            )}

            {(values.changeInIncome === 'yes' ||
              values.changeInIncome === 'not-filed') && (
              <UserExpectedIncomeQuestions
                formik={formik}
                location={this.props.location}
              />
            )}
          </>
        )}

        {/* Married questions */}
        {values.maritalStatus === 'married' && (
          <>
            <SpouseHaveFsl formik={formik} />

            {values.spouseHasFsl === 'true' && (
              <SpouseCurrentLoanBalance formik={formik} />
            )}

            <JointTaxReturn formik={formik} />

            {/*  Married - Filed Jointly */}
            {values.jointTaxReturn === 'yes' && (
              <>
                <ChangeInIncome formik={formik} />

                {(values.changeInIncome === 'yes' ||
                  values.changeInIncome === 'not-filed') && (
                  <>
                    <UserExpectedIncomeQuestions
                      formik={formik}
                      location={this.props.location}
                    />

                    {(values.expectedAnnualGrossIncome !== '' ||
                      values.expectedNonUnemploymentIncome !== '') && (
                      <SpouseExpectedIncomeQuestions
                        formik={formik}
                        location={this.props.location}
                      />
                    )}
                  </>
                )}

                {values.changeInIncome === 'no' && (
                  <>
                    <SpouseIncomeChange limitChoices formik={formik} />

                    {values.spouseIncomeChange === 'no' && (
                      <>
                        <JointAdjustedGrossIncome formik={formik} />
                      </>
                    )}

                    {(values.spouseIncomeChange === 'yes' ||
                      values.spouseIncomeChange === 'not-filed') && (
                      <>
                        {/* This is the case where we don't want to ask the user unemployment questions,
                            because they've already indicated that their income hasn't changed.
                         */}
                        <ExpectedAnnualGrossIncome formik={formik} />

                        <SpouseExpectedIncomeQuestions
                          formik={formik}
                          location={this.props.location}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}

            {/* Married - Filed Separately */}
            {values.jointTaxReturn === 'no' && (
              <>
                <ChangeInIncome formik={formik} />

                {(values.changeInIncome === 'yes' ||
                  values.changeInIncome === 'not-filed') && (
                  <UserExpectedIncomeQuestions
                    formik={formik}
                    location={this.props.location}
                  />
                )}

                {values.changeInIncome === 'no' && (
                  <AdjustedGrossIncome formik={formik} />
                )}

                {(values.expectedAnnualGrossIncome !== '' ||
                  values.adjustedGrossIncome !== '') && (
                  <>
                    <SpouseIncomeChange formik={formik} />

                    {(values.spouseIncomeChange === 'yes' ||
                      values.spouseIncomeChange === 'not-filed') && (
                      <SpouseExpectedIncomeQuestions
                        formik={formik}
                        location={this.props.location}
                      />
                    )}

                    {values.spouseIncomeChange === 'no' && (
                      <SpouseAdjustedGrossIncome formik={formik} />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Form>
    );
  }
}

const Form = styled.form`
  /* to fix an ios safari issue where navigating to the next step and then back
   * would make this form to be mounted but not be visible
   */
  transform: translateZ(0);
  max-width: 766px;
  border-radius: 3px;
  padding: 40px 32px;
  border: solid 1px ${COLORS.grey};
  background-color: ${COLORS.lightestGrey};
  margin: auto;

  @media (max-width: ${MobileSize}) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export default TellUsAboutYou;
