import React from 'react';
import { Route, Switch } from 'react-router-dom';

import BasicDemo from './BasicDemo';
import DynamicDemo from './DynamicDemo';
import NestedDemo from './NestedDemo';
import NestedWithInitPathDemo from './NestedDemoWithInitPath';
import NestedWithProgressDemo from './NestedWithProgressDemo';

const WizardDemos = () => {
  return (
    <Switch>
      <Route exact path="/wizard/basic" component={BasicDemo} />
      <Route exact path="/wizard/dynamic" component={DynamicDemo} />
      <Route exact path="/wizard/nested" component={NestedDemo} />
      <Route
        exact
        path="/wizard/nested-with-init-path"
        component={NestedWithInitPathDemo}
      />
      <Route
        exact
        path="/wizard/nested-with-progress"
        component={NestedWithProgressDemo}
      />
    </Switch>
  );
};

export default WizardDemos;
