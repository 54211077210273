import React from 'react';
import styled from 'styled-components';

import { Text } from '@summer/ui-components/src';

import { MobileSize } from 'constants/styleguide';
import { dollars } from 'utils/numbers';

const OnTrack = ({ surplus, monthsUntilStart }) => {
  return (
    <GreenBox>
      <Header weight="bold">
        <Emoji>
          <span role="img" aria-label="lightbulb">
            🤗
          </span>
        </Emoji>
        Nice job!
      </Header>
      <Text paragraph>
        Based on what you entered, it looks like you are on track to fully fund
        college attendance by the time you or your student enroll. In fact, you
        could:
      </Text>
      <Tips>
        <Tip>
          <Text size="medium" paragraph>
            Keep saving every month until you or your student enrolls and have a
            surplus of about{' '}
            <Text weight="bold" as="span" size="large">
              {dollars(surplus)}
            </Text>{' '}
            for college expenses
          </Text>
        </Tip>
        <Tip>
          <Text size="medium" paragraph>
            Continue saving every month for{' '}
            <Text weight="bold" as="span" size="large">
              {monthsUntilStart}
            </Text>{' '}
            months to fully fund attendance, and then divert your savings to
            other financial goals
          </Text>
        </Tip>
        <Tip>
          <Text paragraph>
            Check out our resource center below to find more ways to save
          </Text>
        </Tip>
      </Tips>
    </GreenBox>
  );
};

const Header = styled(Text)`
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: solid 1px #6ab3a9;
`;

const GreenBox = styled.div`
  background: #f0fdfa;
  padding: 40px 16px 40px;

  @media (min-width: ${MobileSize}) {
    padding: 21px 24px 38px;
  }
`;

const Emoji = styled.span`
  font-size: 24px;
  padding: 0 8px;
`;

const Tips = styled.ul`
  padding-left: 24px;
`;

const Tip = styled.li`
  padding: 8px 0;
`;

export default OnTrack;
