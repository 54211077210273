import {
  FETCH_IDR_PATH_OPTIONS,
  CLEAR_IDR_PATH_OPTIONS,
  CREATE_IDR_FORM,
  FETCH_IDR_FORMS,
  SET_IDR_FORMS,
  MOVE_TO_IDR_CURRENT_STEP,
  UPDATE_CURRENT_IDR_FORM,
  SAVE_IDR_CURRENT_STEP,
  SET_IDR_CURRENT_STEP,
  SET_CURRENT_IDR_FORM,
  SET_IDR_PATH_OPTIONS,
  SET_IDR_SELECTED_PATH_ID,
  IDR_NETWORK_SUCCESS,
  IDR_NETWORK_CLEAR,
} from 'constants/actionTypes';

export const createIdrForm = ({ form = {}, currentStep = '1' } = {}) => ({
  type: CREATE_IDR_FORM,
  payload: { form, currentStep },
});

export const fetchIdrForms = () => ({
  type: FETCH_IDR_FORMS,
});

export const updateCurrentIdrForm = (
  { currentStep, form, profile, financialProfile, status },
  options = { close: false, finish: false },
) => ({
  type: UPDATE_CURRENT_IDR_FORM,
  payload: { currentStep, form, profile, financialProfile, status },
  meta: {
    close: options?.close,
    finish: options?.finish,
    onSuccess: options?.onSuccess,
  },
});

export const setCurrentIdrForm = idrFieldsValues => ({
  type: SET_CURRENT_IDR_FORM,
  payload: {
    ...idrFieldsValues,
  },
});

export const setIdrForms = formsResponse => ({
  type: SET_IDR_FORMS,
  payload: { formsResponse },
});

export const saveIdrCurrentStep = step => ({
  type: SAVE_IDR_CURRENT_STEP,
  payload: {
    step,
  },
});

export const setIdrCurrentStep = step => ({
  type: SET_IDR_CURRENT_STEP,
  payload: {
    step,
  },
});

export const moveToIdrCurrentStep = (step, origin = null) => ({
  type: MOVE_TO_IDR_CURRENT_STEP,
  payload: {
    step,
    origin,
  },
});

export const fetchIdrPathOptions = (formId, loanSource = null) => ({
  type: FETCH_IDR_PATH_OPTIONS,
  payload: { formId, loanSource },
});

export const setIdrPathOptions = idrPathOptions => ({
  type: SET_IDR_PATH_OPTIONS,
  payload: idrPathOptions,
});

export const clearIdrPathOptions = () => ({
  type: CLEAR_IDR_PATH_OPTIONS,
});

export const setIdrSelectedPathId = selectedPathId => ({
  type: SET_IDR_SELECTED_PATH_ID,
  payload: { selectedPathId },
});

export const idrNetworkSuccess = ({ meta }) => ({
  type: IDR_NETWORK_SUCCESS,
  meta,
});

export const idrNetworkClear = ({ meta }) => {
  return {
    type: IDR_NETWORK_CLEAR,
    meta,
  };
};
