import { RichText } from 'prismic-reactjs';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { FlexRow, Header, Text, Button, COLORS } from '@summer/ui-components';

import Page from 'components/shared/dashboard/Page';
import { Link } from 'components/shared/typography';
import { MobileSize, TabletSize, DesktopHDSize } from 'constants/styleguide';
import { fetchProgramById } from 'redux/actions/forgivenessFinder.actions';
import { getProgramDataByUid } from 'redux/selectors/forgivenessFinder.selectors';
import { helpWidgetSize } from 'services/chat';
import { htmlSerializer } from 'utils/cms';

class ForgivenessProgramPage extends React.Component {
  componentDidMount() {
    if (!this.props.program) {
      this.props.fetchProgramById(this.props.match.params.uid);
    }
  }

  show = field => !!(field && field.length && field[0].text);

  render() {
    const {
      eligible,
      award,
      loansQualify,
      description,
      name,
      awardPreview,
      keepInMind,
      howToApply,
      link,
    } = this.props.program || {};

    const { show } = this;
    const showContent =
      show(eligible) ||
      show(award) ||
      show(loansQualify) ||
      show(keepInMind) ||
      show(howToApply);

    return (
      <>
        <Page>
          <Container>
            <HeaderBox>
              <HeaderContentContainer>
                <HeaderContent>
                  <Header>{name}</Header>
                  <Text color="secondary" bold>
                    {awardPreview}
                  </Text>
                  {description && (
                    <div>
                      <RichText
                        render={description}
                        htmlSerializer={htmlSerializer}
                      />
                    </div>
                  )}
                </HeaderContent>
              </HeaderContentContainer>
              <HeaderBackground>
                <Oval1 />
                <Oval2 />
                <Oval3 />
                <Oval4 />
              </HeaderBackground>
            </HeaderBox>
            {showContent && (
              <Content>
                <MainContent>
                  {show(eligible) && (
                    <>
                      <Header h3>Am I eligible?</Header>
                      <RichText
                        render={eligible}
                        htmlSerializer={htmlSerializer}
                      />
                    </>
                  )}
                  {show(award) && (
                    <>
                      <Header h3>How much is the award?</Header>
                      <RichText
                        render={award}
                        htmlSerializer={htmlSerializer}
                      />
                    </>
                  )}
                  {show(loansQualify) && (
                    <>
                      <Header h3>Which student loans qualify?</Header>
                      <RichText
                        render={loansQualify}
                        htmlSerializer={htmlSerializer}
                      />
                    </>
                  )}
                  {show(howToApply) && (
                    <HowToApply>
                      <Header h3 color="summer">
                        How do I apply?
                      </Header>
                      <RichText
                        render={howToApply}
                        htmlSerializer={htmlSerializer}
                      />
                    </HowToApply>
                  )}
                </MainContent>

                {show(keepInMind) && (
                  <KeepInMind>
                    <Header h3>Keep in mind:</Header>
                    <RichText
                      render={keepInMind}
                      htmlSerializer={htmlSerializer}
                    />
                  </KeepInMind>
                )}
              </Content>
            )}

            <BottomNav>
              <Link to="/forgiveness-finder">
                <Button secondary width={123}>
                  Back
                </Button>
              </Link>
              {link && link.url && (
                <Link href={link.url}>
                  <Button secondary width={152}>
                    Learn More
                  </Button>
                </Link>
              )}
            </BottomNav>
          </Container>
        </Page>
      </>
    );
  }
}

const Container = styled.div`
  width: 100%;
`;

const HeaderBox = styled.div`
  position: relative;
  height: auto;
  padding: 88px 72px 32px 72px;

  margin-top: -32px;
  margin-left: -72px;
  margin-right: -72px;

  @media (max-width: ${TabletSize}) and (min-width: ${MobileSize}) {
    margin-top: -24px;
    margin-left: -24px;
    margin-right: -24px;
    padding: 32px 24px;
  }

  @media (max-width: ${MobileSize}) {
    margin-top: -16px;
    margin-left: -16px;
    margin-right: -16px;
    padding: 32px 24px;
  }
`;

const HeaderContentContainer = styled.div`
  max-width: 1040px;
  margin: 0 auto;
`;

const HeaderContent = styled.div`
  position: relative;
  z-index: 2;
  max-width: 544px;

  & > div {
    margin-top: 16px;
  }
`;

const HeaderBackground = styled.div`
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.36;
  background-color: #e5f6ff;
  bottom: 0;

  margin-top: inherit;
  margin-left: inherit;
  margin-right: inherit;
  padding: inherit;
  overflow: hidden;

  @media (max-width: ${TabletSize}) and (min-width: ${MobileSize}) {
    left: 24px;
  }

  @media (max-width: ${MobileSize}) {
    left: 16px;
  }
`;

const Oval1 = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  bottom: -110px;
  right: 220px;
  transform: rotate(70deg);
  background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));
`;

const Oval2 = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 600px;
  height: 600px;
  bottom: -300px;
  right: -340px;
  transform: rotate(-56deg);
  opacity: 0.7;
  background-image: linear-gradient(
    183deg,
    rgba(204, 237, 255, 0.32),
    ${COLORS.azure}
  );
`;

const Oval3 = styled.div`
  opacity: 0.7;
  position: absolute;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  bottom: -130px;
  right: -200px;
  background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));
`;

const Oval4 = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  bottom: 120px;
  right: 200px;
  transform: rotate(210deg);
  mix-blend-mode: multiply;
  background-image: linear-gradient(to bottom, #fffbbe, rgba(255, 255, 255, 0));
`;

const MainContent = styled.div`
  max-width: 544px;

  & > h3:not(:first-child) {
    margin-top: 32px;
  }

  & > h3 {
    margin-bottom: 8px;
  }

  @media (max-width: ${MobileSize}) {
    padding-left: 8px;
    padding-right: 8px;
  }

  & li {
    margin-left: 24px;
  }
`;

const Content = styled(FlexRow)`
  max-width: 1040px;
  margin: 0 auto;
  padding: 32px 0;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
  }
`;

const KeepInMind = styled.div`
  width: 330px;
  padding: 24px 32px;
  background-color: ${COLORS.lightestGrey};
  border: 1px solid ${COLORS.lighterGrey};
  margin-left: 32px;

  & > h3 {
    margin-bottom: 16px;
  }

  @media (max-width: ${MobileSize}) {
    width: 100%;
    margin-left: 0;
    margin-top: 32px;
  }
`;

const HowToApply = styled.div`
  border-left: 4px solid ${COLORS.azure};
  padding-left: 24px;
  margin: 32px 0;

  & > h3 {
    margin-bottom: 12px;
  }
`;

const BottomNav = styled(FlexRow)`
  justify-content: space-between;
  max-width: 1040px;
  margin: 40px auto 0 auto;

  @media (max-width: ${DesktopHDSize}) {
    padding-left: 8px;
    // keeps help widget from overlapping right button
    padding-right: calc(${helpWidgetSize.mobile} + 8px);
  }
`;

const mapStateToProps = (state, props) => ({
  program: getProgramDataByUid(state, props.match.params.uid),
});

export default connect(mapStateToProps, {
  fetchProgramById,
})(ForgivenessProgramPage);
