import { string } from 'yup';

export const mattressFirmValidation = {
  mattressFirmId: string()
    .length(9, 'Active Directory ID must be exactly 9 characters')
    .matches(/^[0-9]+$/, 'Active Directory ID may only contain numbers')
    .required('Active Directory ID is a required field'),
};

export const getMattressFirmPartnerIdentifierData = values => {
  return values.mattressFirmId
    ? {
        identifierValue: values.mattressFirmId,
        identifierMetadata: {},
      }
    : undefined;
};
