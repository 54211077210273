export const MobileSize = '600px';
export const TabletSize = '768px';
export const SmallDesktopSize = '1024px';
export const DesktopHDSize = '1440px';

export const SidebarWidth = `224px`;
export const CollapseSidebarSize = SmallDesktopSize;

// guid layout
export const SCROLL_OFFSET = 88;
