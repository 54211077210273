import { SHOW_TOAST, REMOVE_TOAST } from 'constants/actionTypes';

const toast = (state = [], { type, payload }) => {
  switch (type) {
    case SHOW_TOAST: {
      // Check if toast with the same id already exists
      // We don't want multiple toasts with the sameid
      const found = state.find(toast => toast.id === payload.id);
      if (!found) {
        return [payload, ...state];
      }
      return state;
    }
    case REMOVE_TOAST: {
      const index = state.findIndex(toast => toast.id === payload.id);
      return [...state.slice(0, index), ...state.slice(index + 1)];
    }
    default:
      return state;
  }
};

export default toast;
