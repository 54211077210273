import { Pie } from '@nivo/pie';
import _, { filter, isNil, sumBy } from 'lodash';
import fp from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';

import {
  COLORS,
  Card,
  DotsPagination,
  FlexCenter,
  FlexColumn,
  FlexRow,
  Statistic,
  Text,
} from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import { MobileSize } from 'constants/styleguide';
import { usePieConfig } from 'hooks/myStudentLoans';

const TotalCurrentBalance = ({
  countLoansByServicer = [],
  totalActiveLoans = null,
  totalCurrentBalance = null,
}) => {
  const { commonProperties, contentRef } = usePieConfig({
    data: countLoansByServicer,
  });

  const showBalance = () => (
    <>
      <Pie {...commonProperties} />
      <CurrentBalanceTotal>
        <SummaryStatistic bold value={totalCurrentBalance} format="dollars" />
        <CurrentBalanceValue>
          <Text color="secondary" small align="center">
            {`Total ${
              totalActiveLoans === 1
                ? `from ${totalActiveLoans} loan`
                : `across all ${totalActiveLoans} loans`
            }`}
          </Text>
        </CurrentBalanceValue>
      </CurrentBalanceTotal>
    </>
  );

  const showBalanceUnavailable = () => (
    <>
      <SummaryStatistic bold value={totalCurrentBalance} format="dollars" />
      <Spacer />
      <Text paragraph color="secondary" small align="center">
        Total across all {totalActiveLoans} loans
      </Text>
    </>
  );

  return (
    <Card title="Total Current Balance">
      <Content ref={contentRef}>
        {!isNil(totalCurrentBalance) ? showBalance() : showBalanceUnavailable()}
      </Content>
    </Card>
  );
};

const NumberOfLoans = ({
  countLoansByServicer = [],
  totalActiveLoans = null,
}) => {
  const { commonProperties, contentRef } = usePieConfig({
    data: countLoansByServicer,
  });

  return (
    <Card title="Number of Loans">
      <Content ref={contentRef}>
        <Pie {...commonProperties} />
        <NumberOfLoansTotal>
          <SummaryStatistic bold value={totalActiveLoans} />
        </NumberOfLoansTotal>
      </Content>
    </Card>
  );
};

const AvgInterestRate = ({ avgInterestRate = null }) => {
  return (
    <Card title="Weighted Avg. Interest Rate">
      <Content>
        <SummaryStatistic bold value={avgInterestRate} format="percentage" />
        <Spacer />
        <Text paragraph color="secondary" small align="center">
          Avg. interest rate across all loans
        </Text>
      </Content>
    </Card>
  );
};

const SummaryBarContainer = Platform(
  class SummaryBarContainer extends Component {
    state = {
      swipeIdx: 0,
    };

    handleSwipeChangeIndex = idx => this.setState({ swipeIdx: idx });

    render() {
      const { platformType, children } = this.props;
      const { swipeIdx } = this.state;

      if (platformType === MOBILE) {
        return (
          <div>
            <SwipeableViews
              resistance
              index={swipeIdx}
              style={{
                marginLeft: '-16px',
                marginRight: '-16px',
              }}
              slideStyle={{ padding: '16px' }}
              onChangeIndex={this.handleSwipeChangeIndex}
            >
              {children}
            </SwipeableViews>
            <DotsPagination
              justifyContent="center"
              total={3}
              activeIndex={swipeIdx}
              onActiveIndexChange={this.handleSwipeChangeIndex}
            />
          </div>
        );
      }
      return <Box>{children}</Box>;
    }
  },
);

const SummaryBar = ({ normalizedLoans }) => {
  const activeLoans = filter(normalizedLoans, { active: true });
  const isMissingDataForAvgInterestRate = !!activeLoans.find(
    loan => isNil(loan.interestRate) || isNil(loan.currentBalance),
  );
  const totalCurrentBalance = activeLoans.find(loan =>
    isNil(loan.currentBalance),
  )
    ? null
    : sumBy(activeLoans, 'currentBalance');
  const avgInterestRate = isMissingDataForAvgInterestRate
    ? null
    : sumBy(activeLoans, loan => loan.interestRate * loan.currentBalance) /
      totalCurrentBalance;
  const totalActiveLoans = filter(activeLoans, { active: true }).length;
  const countLoansByServicer = _.flow(
    fp.countBy('servicerName'),
    fp.map((value, label) => ({ id: label, label, value })),
  )(activeLoans);

  return (
    <SummaryBarContainer>
      <CardBox>
        <TotalCurrentBalance
          countLoansByServicer={countLoansByServicer}
          totalActiveLoans={totalActiveLoans}
          totalCurrentBalance={totalCurrentBalance}
        />
      </CardBox>
      <CardBox>
        <AvgInterestRate avgInterestRate={avgInterestRate} />
      </CardBox>
      <CardBox>
        <NumberOfLoans
          countLoansByServicer={countLoansByServicer}
          totalActiveLoans={totalActiveLoans}
        />
      </CardBox>
    </SummaryBarContainer>
  );
};

SummaryBar.propTypes = {
  normalizedLoans: PropTypes.array,
};

const Box = styled(FlexRow)``;

const CardBox = styled.div`
  width: calc(100% / 3);
  margin-right: 15px;
  min-height: 280px;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: ${MobileSize}) {
    width: 100%;
    height: 100%;
  }
`;

const Content = styled(FlexColumn)`
  margin-top: 8px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const SummaryStatistic = styled(Statistic)`
  margin-bottom: 4px;
`;

const Spacer = styled.div`
  width: 48px;
  margin: 24px 0;
  border: solid 2px ${COLORS.grey};
`;

const NumberOfLoansTotal = styled(FlexCenter)`
  position: absolute;
  top: 8px;
  bottom: 0;

  & span {
    font-size: 56px;
  }
`;

const CurrentBalanceTotal = styled(FlexColumn)`
  position: absolute;
  top: 32px;
  bottom: 0;
  justify-content: center;
  align-items: center;
`;

const CurrentBalanceValue = styled.div`
  max-width: 70%;
  margin-top: 4px;
`;

export default SummaryBar;
