import { push as redirectTo } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { FlexColumn, Modal, COLORS } from '@summer/ui-components';

import LoadingAnimation from 'components/common/LoadingAnimation';
import { finish } from 'constants/onboardingSteps';
import { trackServerSide, trackAction } from 'redux/actions/analytics.actions';
import { setOnboardingStep } from 'redux/actions/onboard.actions';
import { fetchRecommendations } from 'redux/actions/recEngine.actions';
import { getPostOnboardingDestination } from 'redux/selectors/onboarding.selectors';
import {
  getPslfRecommendation,
  onlyDefaultRecommendation,
  onlyIdrRecommendation,
  onlyPslfAndIdrRecommendation,
  onlyRefiRecommendation,
} from 'redux/selectors/recEngine.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { getUserPartnerKey } from 'redux/selectors/user.selectors';

const animationSteps = [
  'Analyzing your income...',
  'Looking at your debt...',
  'Checking loan repayment and forgiveness options...',
  'Building your plan recommendation...',
];

const LoadingRecommendations = ({
  isLoading,
  isOnlyDefaultRecommended,
  isOnlyIdrRecommended,
  isOnlyPslfAndIdrRecommended,
  isOnlyRefiRecommended,
  isRecommendedPslf,
  userPartnerKey,
  postOnboardingDestination,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [animationDone, setAnimationDone] = useState(false);

  useEffect(
    function fetchRecommendation() {
      dispatch(fetchRecommendations());
      dispatch(setOnboardingStep(finish, true));

      // TODO remove the tag/trackAction once the move from MC -> Braze is
      // complete
      dispatch(trackAction('[USER] Finish on boarding'));
      dispatch(trackServerSide('[USER] Finish on boarding'));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!isLoading && animationDone) {
      if (postOnboardingDestination) {
        dispatch(
          redirectTo(postOnboardingDestination, {
            origin: location?.pathname,
          }),
        );
      } else if (userPartnerKey === 'fidelity' && isRecommendedPslf) {
        dispatch(redirectTo('/pslf', { origin: location?.pathname }));
      } else if (isOnlyDefaultRecommended) {
        dispatch(redirectTo('/plan', { origin: location?.pathname }));
      } else if (isOnlyIdrRecommended || isOnlyPslfAndIdrRecommended) {
        dispatch(redirectTo('/repayment', { origin: location?.pathname }));
      } else if (isOnlyRefiRecommended) {
        dispatch(redirectTo('/refinancing', { origin: location?.pathname }));
      } else {
        dispatch(redirectTo(finish, { origin: location?.pathname }));
      }
    }
  }, [
    isLoading,
    animationDone,
    dispatch,
    location,
    isOnlyDefaultRecommended,
    isOnlyIdrRecommended,
    isOnlyPslfAndIdrRecommended,
    isOnlyRefiRecommended,
    isRecommendedPslf,
    userPartnerKey,
    postOnboardingDestination,
  ]);

  const onAnimationEnd = () => {
    setAnimationDone(true);
  };

  return (
    <Modal isOpen={true}>
      <ModalContainer>
        <ContentContainer>
          <LoadingAnimation
            header="Hold tight! Let's see which programs might be a good fit for you."
            waveSpeed={0.5}
            checklist={animationSteps}
            onAnimationEnd={onAnimationEnd}
          />
        </ContentContainer>
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${COLORS.white};
  padding: 72px 40px;
`;

const ContentContainer = styled(FlexColumn)`
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const mapStateToProps = state => ({
  isOnlyDefaultRecommended: onlyDefaultRecommendation(state),
  isOnlyIdrRecommended: onlyIdrRecommendation(state),
  isOnlyPslfAndIdrRecommended: onlyPslfAndIdrRecommendation(state),
  isOnlyRefiRecommended: onlyRefiRecommendation(state),
  isRecommendedPslf: getPslfRecommendation(state),
  userPartnerKey: getUserPartnerKey(state),
  isLoading: isLoading(state, 'fetchRecommendations'),
  postOnboardingDestination: getPostOnboardingDestination(state),
});

export default connect(mapStateToProps)(LoadingRecommendations);
