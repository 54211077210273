import { findIndex, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  FlexRow,
  LoadingBars,
  Alert,
  Header,
  Text,
  Strong,
  COLORS,
} from '@summer/ui-components';

import DefaultBlocker from 'components/common/DefaultBlocker';
import ManageLoans from 'components/common/ManageLoans';
import { ManageLoansButton } from 'components/common/SyncLoans/Buttons';
import ConnectFsa from 'components/common/SyncLoans/ConnectFsa';
import NewLoansTable from 'components/common/ViewLoans/LoansTables/NewLoanTable';
import useScrollToErrorRow from 'components/common/ViewLoans/LoansTables/hooks/useScrollToErrorRow';
import Platform, { MOBILE } from 'components/hoc/Platform';
import StaleFsaAlert from 'components/pages/loans/components/alerts/StaleFsaAlert';
import Content from 'components/pages/pslf/wizard/layout/PslfWizardContent';
import Footer from 'components/pages/pslf/wizard/layout/PslfWizardFooter';
import ConsolidateAlert from 'components/pages/pslf/wizard/steps/yourLoans/alerts/ConsolidateAlert';
import PslfIneligibleAlert from 'components/pages/pslf/wizard/steps/yourLoans/alerts/PslfIneligibleAlert';
import EligibilityInfoModal from 'components/pages/pslf/wizard/steps/yourLoans/modals/EligibilityInfoModal';
import { getOutOfDefaultBulletsPslf } from 'constants/getOutOfDefault';
import { programTypes } from 'constants/manageLoans';
import { pslfIneligibleLoansCopy } from 'constants/pslf';
import { MobileSize, TabletSize, SmallDesktopSize } from 'constants/styleguide';
import {
  fetchLoanTypes,
  fetchRepaymentPlanTypes,
} from 'redux/actions/constants.actions';
import { fetchLoans } from 'redux/actions/loans.actions';
import { fetchPslfCalculations } from 'redux/actions/pslf.actions';
import { fetchRecommendations } from 'redux/actions/recEngine.actions';
import { fetchSyncStatus } from 'redux/actions/sync.actions';
import { openEditALoan } from 'redux/actions/ui.actions';
import {
  getLoansForPslf,
  getUserFederalLoans,
  getFederalLoanTypes,
} from 'redux/selectors/loans.selectors';
import { getUserHasCompletedOnboarding } from 'redux/selectors/onboarding.selectors';
import {
  getEmployerStartDate,
  getEmployerEndDate,
  getCurrentPslfFormId,
} from 'redux/selectors/pslf.selectors';
import { getGetOutOfDefaultRecommendation } from 'redux/selectors/recEngine.selectors';
import {
  getIsFsaLoanFileStale,
  hasSyncedLoans,
} from 'redux/selectors/sync.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { pslfLoanSchema } from 'schemas/loans';
import { getNormalizedFsaLoans, areLoansValid } from 'utils/loans';
import {
  areAllLoansIneligible,
  areAllLoansEligible,
  formatEmployerDates,
  hasLoansToConsolidate,
} from 'utils/pslf';

const syncHeaderText = (
  <Text paragraph>
    We need accurate information about your loans to determine your loan
    eligibility.{' '}
    <Strong>Connect to track your progress toward forgiveness.</Strong>
  </Text>
);

const YourLoans = ({
  userNormalizedLoansForPslf,
  userFederalLoans,
  loansLoading,
  employerStartDate,
  employerEndDate,
  platformType,
  navigation,
  formId,
  dispatch,
  hasSyncedLoans,
  hasLoansInDefault,
  isFsaLoanFileStale,
  userHasCompletedOnboarding,
}) => {
  useEffect(() => {
    dispatch(fetchLoans());
    dispatch(fetchLoanTypes());
    dispatch(fetchRepaymentPlanTypes());
    dispatch(fetchSyncStatus());
  }, [dispatch]);

  useEffect(() => {
    if (hasSyncedLoans) {
      dispatch(fetchPslfCalculations(formId));
      if (userHasCompletedOnboarding) {
        dispatch(fetchRecommendations());
      }
    }
  }, [
    dispatch,
    formId,
    hasSyncedLoans,
    userFederalLoans, // We need 'userFederalLoans' as a dependency here to trigger the calcs request if a user fills in missing info.
    userHasCompletedOnboarding,
  ]);

  const isMobile = platformType === MOBILE;

  const handleRowClick = normalizedLoans => loanId => {
    const loanIndex = findIndex(normalizedLoans, { id: loanId });
    dispatch(openEditALoan(loanIndex, programTypes.pslf));
  };

  const loansAreValid = areLoansValid(userNormalizedLoansForPslf);
  const allLoansAreIneligible = areAllLoansIneligible(
    userNormalizedLoansForPslf,
  );
  const allLoansAreEligible = areAllLoansEligible(userNormalizedLoansForPslf);
  const hasLoansForConsolidation = hasLoansToConsolidate(
    userNormalizedLoansForPslf,
  );
  const isValid = loansAreValid && !allLoansAreIneligible;

  const scrollToError = useScrollToErrorRow(userNormalizedLoansForPslf);

  const handleSave = () => {
    if (isValid) {
      navigation.next();
    } else {
      scrollToError();
    }
  };

  const renderUserLoans = () => {
    const delimiter = 'to';
    const employerDateText = formatEmployerDates(
      employerStartDate,
      employerEndDate,
      delimiter,
    );
    const headerText = `Your Loan Eligibility ${employerDateText}:`;
    const isLoansEmpty = isEmpty(userNormalizedLoansForPslf);

    const columnsToShow = [
      'loanType',
      'status',
      'repaymentPlan',
      'interestRate',
      ...(!isMobile ? ['currentBalance'] : []),
      'pslfEligibilityAndConsolidationRec',
    ];

    const loansContent =
      hasLoansInDefault && allLoansAreIneligible ? (
        <DefaultBlocker
          getOutOfDefaultBullets={getOutOfDefaultBulletsPslf}
          ineligibleProgram="PSLF"
        />
      ) : (
        <Container>
          <HeaderContainer>
            <Left>
              <Header as={isMobile ? 'h3' : 'h2'}>{headerText}</Header>
              {!allLoansAreIneligible ? (
                <EligibilityInfoContainer>
                  <Text as="span" paragraph>
                    Let’s see which loans and repayments you made were eligible
                    and translate to PSLF qualifying payments.{' '}
                    <EligibilityInfoModal />
                  </Text>
                </EligibilityInfoContainer>
              ) : null}
            </Left>
            <ButtonContainer>
              <ManageLoansButton />
            </ButtonContainer>
          </HeaderContainer>
          <IneligibleAlertContainer>
            {allLoansAreIneligible ? <PslfIneligibleAlert /> : null}
          </IneligibleAlertContainer>
          <AlertContainer>
            <StaleFsaAlert />
          </AlertContainer>
          <AlertContainer>
            {loansAreValid ? null : (
              <Alert dismissable={false} theme="warning">
                <Strong>
                  Confirm the missing information on the loans for this period.
                </Strong>{' '}
                You can find this information on your Servicer&rsquo;s website.
              </Alert>
            )}
          </AlertContainer>
          <LoansTableContainer>
            <NewLoansTable
              loans={userNormalizedLoansForPslf}
              onRowClick={handleRowClick(userNormalizedLoansForPslf)}
              columnsToShow={columnsToShow}
              isPslf
            />
          </LoansTableContainer>

          {hasLoansForConsolidation && <ConsolidateAlert />}
          {!allLoansAreIneligible && !allLoansAreEligible ? (
            <BottomContainer>
              <Label>
                <Text paragraph bold>
                  What will happen with my ineligible loans?
                </Text>
              </Label>
              <Info>
                <Text paragraph>{pslfIneligibleLoansCopy}</Text>
              </Info>
            </BottomContainer>
          ) : null}
        </Container>
      );

    return isLoansEmpty && !loansLoading ? (
      <ConnectFsa
        page="pslf"
        headerSize="h2"
        headerContent={syncHeaderText}
        headerImgPath="/images/sync/pslf-tracker.png"
        pageHasFooter
      />
    ) : (
      loansContent
    );
  };

  return (
    <Content navigation={navigation}>
      <ManageLoans />

      {loansLoading ? <LoadingBars color={COLORS.azure} /> : renderUserLoans()}

      <Footer
        isNextDisabled={allLoansAreIneligible || isFsaLoanFileStale}
        handleBack={navigation.back}
        handleSaveContinue={handleSave}
      />
    </Content>
  );
};

const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;

  @supports (-moz-appearance: none) {
    @media (min-width: ${MobileSize}) {
      padding-bottom: inherit;
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
    margin-bottom: 24px;
  }
`;

const EligibilityInfoContainer = styled.div`
  @media (max-width: ${MobileSize}) {
    margin-bottom: 24px;
  }
`;

const IneligibleAlertContainer = styled.div`
  margin-bottom: 16px;
`;

const AlertContainer = styled.div`
  margin-bottom: 24px;
`;

const LoansTableContainer = styled.div`
  @media (max-width: ${MobileSize}) {
    margin: 0 -16px;

    & .table-row {
      border-radius: 0px;
    }
  }
`;

const Left = styled.div`
  & > * {
    margin-bottom: 16px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
    width: 100%;
  }
`;

const BottomContainer = styled(FlexRow)`
  border-top: 1px solid ${COLORS.grey};
  padding-top: 16px;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (max-width: ${SmallDesktopSize}) {
    margin-top: unset;
  }

  @media (max-width: ${TabletSize}) {
    flex-direction: column;
  }
`;

const Label = styled.div`
  width: 220px;

  @media (max-width: ${TabletSize}) {
    width: 100%;
  }
`;

const Info = styled.div`
  width: calc(100% - 220px);
  margin-left: 24px;

  @media (max-width: ${TabletSize}) {
    width: 100%;
    margin-left: unset;
    margin-top: 8px;
  }
`;

const mapStateToProps = state => ({
  loansLoading:
    isLoading(state, 'fetchLoans') || isLoading(state, 'fetchPslfCalculations'),
  loanTypes: getFederalLoanTypes(state),
  userNormalizedLoansForPslf: getNormalizedFsaLoans(
    getLoansForPslf(state),
    pslfLoanSchema,
  ),
  userFederalLoans: getUserFederalLoans(state),
  employerStartDate: getEmployerStartDate(state),
  employerEndDate: getEmployerEndDate(state),
  formId: getCurrentPslfFormId(state),
  hasSyncedLoans: hasSyncedLoans(state),
  hasLoansInDefault:
    getGetOutOfDefaultRecommendation(state) && hasSyncedLoans(state),
  userHasCompletedOnboarding: getUserHasCompletedOnboarding(state),
  isFsaLoanFileStale: getIsFsaLoanFileStale(state),
});

export default Platform(connect(mapStateToProps)(YourLoans));
