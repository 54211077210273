import React from 'react';
import { connect } from 'react-redux';

import { Text } from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';
import { getHideSupportChat } from 'redux/selectors/user.selectors';
import { openChat } from 'services/chat';

const ContactSupport = ({ hideSupportChat }) => {
  return (
    <>
      <Text paragraph bold as="span">
        Still have questions?
      </Text>{' '}
      {hideSupportChat ? (
        <>
          Email us at{' '}
          <Link href={`mailto:${supportAddress}`}>{supportAddress}</Link>
        </>
      ) : (
        <Link onClick={openChat}>Chat with us</Link>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  hideSupportChat: getHideSupportChat(state),
});

export default connect(mapStateToProps)(ContactSupport);
