import { find, get } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { usePrevious } from 'hooks/common';

/**
 * Track when the new sign requests have been loaded. This will prevent the
 * hellosign embed from being opened before the /v3/sign request is completed.
 * That previously could happen if the user already had sign requests in the
 * reducer. Cannot rely on only isLoading because it is initially false.
 */
const useOpenOnNavigate = (
  isLoading,
  signatureRequestsList,
  handleButtonClick,
) => {
  const history = useHistory();
  const [signRequestsReady, setSignRequestsReady] = useState(false);
  const previousIsLoading = usePrevious(isLoading);
  useEffect(() => {
    if (previousIsLoading && !isLoading) {
      setSignRequestsReady(true);
    }
  }, [isLoading, previousIsLoading]);

  /**
   * When navigating to /pslf state.openForm can be set to an id that triggers
   * the related form to be opened.
   */
  useEffect(() => {
    if (!signRequestsReady) {
      return;
    }

    const openForm = get(history, 'location.state.openForm');

    const formToOpen = find(signatureRequestsList, { formId: openForm });

    if (formToOpen) {
      const borrower = find(formToOpen.signers, { role: 'borrower' });
      handleButtonClick(borrower.embeddedSignURL);

      /**
       * Prevent the form from being re-opened if the user cancels the sign
       * request (which triggers the sign requests to be re-loaded
       */
      history.replace({
        pathname: history.location.pathname,
        state: {},
      });
    }
  }, [signRequestsReady, history, signatureRequestsList, handleButtonClick]);
};

export default useOpenOnNavigate;
