import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  COLORS,
  FlexCenter,
  FlexColumn,
  LoadingBars,
} from '@summer/ui-components/src';

import LandingPage from 'components/shared/dashboard/LandingPage';
import { MobileSize } from 'constants/styleguide';
import { fetchEmergencySavingsV1 } from 'redux/actions/emergencySavings.actions';
import {
  calculateEmergencySavingsTarget,
  getEmergencySavingsPayrollProviders,
  getEmergencySavingsDeposits,
  getEmergencySavings,
} from 'redux/selectors/emergencySavings.selectors';
import {
  getIsEmergencySavingsV1Enabled,
  getIsEmergencySavingsDemoEnabled,
} from 'redux/selectors/flags.selectors';
import { isLoadingFunctional } from 'redux/selectors/ui.selectors';

import AutoDeposits from './AutoDeposits';
import EmergencySavingsMarketplace from './Marketplace';
import Resources from './Resources';
import SavingsHistory from './SavingsHistory';
import SavingsTarget from './SavingsTarget';

const EmergencySavingsDashboard = () => {
  const [account, setAccount] = useState(null);

  const demoSavingsTarget = useSelector(calculateEmergencySavingsTarget);
  const payrollProviders = useSelector(getEmergencySavingsPayrollProviders);
  const deposits = useSelector(getEmergencySavingsDeposits);
  const showEmergencySavingsV1 = useSelector(getIsEmergencySavingsV1Enabled);
  const showEmergencySavingsDemo = useSelector(
    getIsEmergencySavingsDemoEnabled,
  );
  const emergencySavings = useSelector(getEmergencySavings);
  const emergencySavingsLoading = useSelector(
    isLoadingFunctional('fetchEmergencySavingsV1'),
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (showEmergencySavingsV1) {
      dispatch(fetchEmergencySavingsV1());
    } else if (showEmergencySavingsDemo) {
      // Note: Demo does not fetch values from product server
    }
  }, [dispatch, showEmergencySavingsV1, showEmergencySavingsDemo]);

  const landingPageHeaderName = 'Emergency Savings';
  const landingPageSubHeaderText =
    'Use Summer’s tool to calculate a savings goal, compare high-yield savings accounts or sync your existing account, and set up recurring auto-deposits with our “set it and forget it” feature.';

  if (emergencySavingsLoading) {
    return (
      <LoadingContainer>
        <LoadingBars color={COLORS.azure} />
      </LoadingContainer>
    );
  }

  if (showEmergencySavingsV1) {
    return (
      <LandingPage
        headerTheme="cobaltDark"
        headerName={landingPageHeaderName}
        subHeader={landingPageSubHeaderText}
      >
        <Container>
          <EmergencySavingsContent>
            <SavingsTarget
              savingsTarget={
                emergencySavings.savingsTarget ?? demoSavingsTarget
              }
              account={account}
              setAccount={setAccount}
            />
            <EmergencySavingsMarketplace />
          </EmergencySavingsContent>
        </Container>
      </LandingPage>
    );
  }
  return (
    <LandingPage
      headerTheme="cobaltDark"
      headerName={landingPageHeaderName}
      subHeader={landingPageSubHeaderText}
    >
      <Container>
        <EmergencySavingsContent>
          <SavingsTarget
            savingsTarget={demoSavingsTarget}
            account={account}
            setAccount={setAccount}
          />
          <AutoDeposits
            enabled={!!account}
            payrollProviders={payrollProviders}
          />
          <SavingsHistory deposits={payrollProviders?.length && deposits} />
          <Resources />
        </EmergencySavingsContent>
      </Container>
    </LandingPage>
  );
};

const Container = styled.div`
  z-index: 1;
  position: relative;
`;

const LoadingContainer = styled(FlexCenter)`
  height: 100%;
`;

const EmergencySavingsContent = styled(FlexColumn)`
  z-index: 10;
  margin: 42px 88px;
  align-items: flex-start;
  gap: 32px;

  @media (max-width: ${MobileSize}) {
    margin: 10px;
  }
`;

export default EmergencySavingsDashboard;
