import { pick, omit, values } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { Accordion, Text, COLORS } from '@summer/ui-components';

import { idrFaqConfig } from 'components/pages/idr/dashboard/shared/faqConfig';
import { TabletSize } from 'constants/styleguide';
import { hexToRgba } from 'utils/common';

const getConfig = isIdrRecommended => {
  let config = pick(idrFaqConfig, [
    'recommended',
    'forgiveness',
    'whatElse',
    'alreadyEnrolled',
    'inDefault',
  ]);

  if (!isIdrRecommended) {
    config = omit(config, ['recommended']);
  }

  return values(config);
};

export const IdrStartViewFaq = ({ isIdrRecommended }) => {
  return (
    <AccordionContainer>
      <Accordion config={getConfig(isIdrRecommended)} align="left" />
    </AccordionContainer>
  );
};

export const IdrStartViewMessage = () => {
  return (
    <Message>
      <Text size="small" paragraph align="left">
        <Emoji>
          <span role="img" aria-label="star">
            ⭐&nbsp;&nbsp;
          </span>
        </Emoji>
        Borrowers who enroll in IDR with Summer lower their payments by
        $207/month on average.
      </Text>
    </Message>
  );
};

const Message = styled.div`
  padding: 16px 12px 16px 16px;
  background-color: ${hexToRgba(COLORS.azure, 0.1)};
  @media (max-width: ${TabletSize}) {
    margin-bottom: 24px;
  }
`;

const AccordionContainer = styled.div`
  text-align: left;
`;

const Emoji = styled.span`
  span {
    font-size: 14px;
  }
`;
