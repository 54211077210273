import _, { pick } from 'lodash';
import fp from 'lodash/fp';

import { SET_PSLF_FORMS } from 'constants/actionTypes';

const initialState = [];

const fields = [
  'formId',
  'employerHoursPerWeek',
  'employerName',
  'employerStartDate',
  'employerEndDate',
  'multipleEmployers',
  'hrEmailCorrect',
  'updatedAt',
  'forbearanceRequested',
];

const pslfFormsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PSLF_FORMS: {
      return _.flow(
        fp.get('formsResponse.forms'),
        fp.map(({ form, status, currentStep }) => ({
          ...pick(form, fields),
          status,
          currentStep,
        })),
      )(payload);
    }
    default:
      return state;
  }
};

export default pslfFormsReducer;
