import { format, parseISO } from 'date-fns';
import { isNil } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { Text } from '@summer/ui-components';

import {
  hasSyncedLoans,
  getMostRecentFsaUploadDate,
} from 'redux/selectors/sync.selectors';

const LastUpdatedFsaLoans = ({ hasSyncedLoans, fsaUploadDate }) => {
  if (hasSyncedLoans && !isNil(fsaUploadDate)) {
    return (
      <Text small color="secondary">
        Last updated {format(parseISO(fsaUploadDate), 'MMMM dd, yyyy')}
      </Text>
    );
  }
  return null;
};

const mapStateToProps = state => ({
  hasSyncedLoans: hasSyncedLoans(state),
  fsaUploadDate: getMostRecentFsaUploadDate(state),
});

export default connect(mapStateToProps)(LastUpdatedFsaLoans);
