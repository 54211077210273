import React from 'react';

import { Text } from '@summer/ui-components/src';
import Link from '@summer/ui-components/src/core/typography/Link';

import { supportAddress } from 'constants/emailLinks';
import { openChat } from 'services/chat';

const ContactSupportText = () => {
  return (
    <Text as="p" paragraph>
      Questions? Start a <Link onClick={openChat}>chat</Link> or email us at{' '}
      <Link href={`mailto:${supportAddress}`}>{supportAddress}</Link>.
    </Text>
  );
};

export default ContactSupportText;
