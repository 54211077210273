import React from 'react';
import styled from 'styled-components';

import {
  Text,
  Strong,
  Container,
  Row,
  ResponsiveColumn,
  COLORS,
} from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { MobileSize, TabletSize } from 'constants/styleguide';
import { openChat } from 'services/chat';

const Layout = ({ leftSection, boxColor, boxContent }) => {
  return (
    <Container fullWidth>
      <Content>
        <Row start>
          <ResponsiveColumn
            desktopCol={5}
            desktopOffset={1}
            tabletCol={12}
            tabletOffset={0}
            mobileCol={12}
            mobileOffset={0}
          >
            {leftSection}
          </ResponsiveColumn>
          <ResponsiveColumn
            desktopCol={4}
            desktopOffset={1}
            tabletCol={12}
            tabletOffset={0}
            mobileCol={12}
            mobileOffset={0}
          >
            <Box boxColor={boxColor}>{boxContent}</Box>
            <HelpTextContainer>
              <HelpText>
                <Strong>Not sure? We&rsquo;re here to help.</Strong>
              </HelpText>
              <Link underline inheritColor track onClick={openChat}>
                Send us a message.
              </Link>
            </HelpTextContainer>
          </ResponsiveColumn>
        </Row>
      </Content>
    </Container>
  );
};

const Content = styled.div`
  margin: 72px 0;

  @media (max-width: ${TabletSize}) {
    margin: 24px 0;
  }
`;

export const TextContainer = styled.div`
  @media (max-width: ${MobileSize}) {
    margin: 0 8px;
  }
`;

const Box = styled.div`
  background-color: ${({ boxColor }) => boxColor};
  padding: 24px;
  margin-bottom: 24px;

  @media (max-width: ${TabletSize}) {
    margin-top: 24px;
    margin-left: -16px;
    margin-right: -16px;
  }
`;

const HelpTextContainer = styled(TextContainer)`
  color: ${COLORS.darkerGrey};
  @media (max-width: ${TabletSize}) {
    margin-bottom: 40px;
  }
`;

const HelpText = styled(Text)`
  margin-bottom: 8px;
`;

export default Layout;
