/* eslint-disable react/display-name */
import { push as redirectTo } from 'connected-react-router';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import FormDrawersContainer from 'components/common/FormDrawersContainer';
import Content from 'components/pages/pslf/wizard/layout/PslfWizardContent';
import Footer from 'components/pages/pslf/wizard/layout/PslfWizardFooter';
import Header from 'components/pages/pslf/wizard/steps/submit/shared/Header';
import FormDrawers from 'components/shared/FormDrawers';
import { useCounter } from 'hooks/common';
import { useOnSuccess } from 'hooks/pslf';
import { getUserCurrentPslfForm } from 'redux/selectors/pslf.selectors';
import { getUserEmail } from 'redux/selectors/user.selectors';
import { employerInfoSchema } from 'schemas/pslf';

import EmployerEmailConfirmationModal from './modals/EmployerEmailConfirmationModal';
import EmployerInfo from './sections/EmployerInfo';

const EmployerInformation = ({
  dispatch,
  navigation,
  currentPslfForm,
  userEmail,
}) => {
  useOnSuccess('updateCurrentPslfForm', () => {
    dispatch(redirectTo('/pslf', { openForm: currentPslfForm.formId }));
  });

  const [isEmailConfirmModalOpen, setIsEmailConfirmModalOpen] = useState(false);
  const toggleEmailConfirmModal = () =>
    setIsEmailConfirmModalOpen(!isEmailConfirmModalOpen);

  const { employerName, employerWebsite, hrFirstName, hrLastName, hrEmail } =
    currentPslfForm;

  // Used for FormDrawers error behavior.
  // We can't use formik's submitCount because it is read-only.
  const [submitCount, resetSubmitCount, incrementSubmitCount] = useCounter();

  const handleSave = () => setIsEmailConfirmModalOpen(true);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        employerName,
        employerWebsite,
        hrFirstName,
        hrLastName,
        hrEmail,
      }}
      onSubmit={handleSave}
      validationSchema={employerInfoSchema(userEmail)}
    >
      {formik => {
        const { isValid, errors, handleSubmit } = formik;

        return (
          <Content navigation={navigation}>
            <form>
              <Header navigation={navigation}>Your employer information</Header>
              <FormDrawersContainer>
                <FormDrawers
                  errors={errors}
                  submitCount={submitCount}
                  resetSubmitCount={resetSubmitCount}
                  forms={[
                    {
                      name: 'employer',
                      title: 'Employer Information',
                      form: show => (
                        <EmployerInfo formik={formik} show={show} />
                      ),
                      fields: [
                        'employerName',
                        'employerWebsite',
                        'hrFirstName',
                        'hrLastName',
                        'hrEmail',
                      ],
                    },
                  ]}
                />
              </FormDrawersContainer>
              <EmployerEmailConfirmationModal
                isOpen={isEmailConfirmModalOpen}
                togglePopup={toggleEmailConfirmModal}
                formik={formik}
                navigation={navigation}
              />
              <Footer
                handleBack={navigation.back}
                isValid={isValid}
                submitCount={submitCount}
                handleSaveContinue={e => {
                  handleSubmit(e);
                  incrementSubmitCount();
                }}
                showPrivacyPolicy={true}
              />
            </form>
          </Content>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    currentPslfForm: getUserCurrentPslfForm(state),
    userEmail: getUserEmail(state),
  };
};

export default connect(mapStateToProps)(EmployerInformation);
