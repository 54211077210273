import { push as redirectTo } from 'connected-react-router';
import { get } from 'lodash';

import {
  FETCH_PSLF_FORMS,
  CREATE_PSLF_FORM,
  FETCH_CURRENT_PSLF_FORM,
  UPDATE_CURRENT_PSLF_FORM,
  SAVE_PSLF_CURRENT_STEP,
  FETCH_PSLF_CALCULATIONS,
  NETWORK_SUCCESS,
} from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import { setProfile } from 'redux/actions/profile.actions';
import {
  setPslfForms,
  setCurrentPslfForm,
  setPslfCalculations,
  pslfNetworkSuccess,
  setPslfCurrentStep,
} from 'redux/actions/pslf.actions';
import handleSideEffects from 'redux/handleSideEffects';

const PSLFMiddleware = handleSideEffects({
  [FETCH_PSLF_FORMS]: dispatch => {
    dispatch(
      apiRequest({
        url: '/form/pslf',
        method: 'GET',
        fromAction: FETCH_PSLF_FORMS,
        label: 'fetchPslfForms',
      }),
    );
  },
  [CREATE_PSLF_FORM]: (dispatch, { payload: { currentStep, form } }) => {
    dispatch(
      apiRequest({
        url: '/form/pslf',
        method: 'POST',
        body: { currentStep, form },
        fromAction: CREATE_PSLF_FORM,
        label: 'createPslfForm',
      }),
    );
  },
  [FETCH_CURRENT_PSLF_FORM]: (dispatch, { payload: { formId } }) => {
    dispatch(
      apiRequest({
        url: `/form/pslf/${formId}`,
        method: 'GET',
        fromAction: FETCH_CURRENT_PSLF_FORM,
        label: 'fetchCurrentPslfForm',
      }),
    );
  },
  [UPDATE_CURRENT_PSLF_FORM]: (
    dispatch,
    { payload: { currentStep, form, profile, status } },
  ) => {
    dispatch(
      apiRequest({
        url: `/form/pslf`,
        method: 'PUT',
        body: {
          id: form.formId,
          currentStep,
          form,
          profile,
          status,
        },
        fromAction: UPDATE_CURRENT_PSLF_FORM,
        label: 'updateCurrentPslfForm',
      }),
    );
  },
  [SAVE_PSLF_CURRENT_STEP]: (dispatch, { payload: { currentStep, form } }) => {
    dispatch(
      apiRequest({
        url: `/form/pslf`,
        method: 'PUT',
        body: { id: form.formId, currentStep, form },
        fromAction: SAVE_PSLF_CURRENT_STEP,
        label: 'savePslfCurrentStep',
      }),
    );
  },
  [FETCH_PSLF_CALCULATIONS]: (dispatch, { payload: { formId } }) => {
    dispatch(
      apiRequest({
        url: `/pslf/eligibility`,
        method: 'POST',
        body: { formId },
        fromAction: FETCH_PSLF_CALCULATIONS,
        label: 'fetchPslfCalculations',
      }),
    );
  },
  [NETWORK_SUCCESS]: (dispatch, { payload, meta }) => {
    if (meta.fromAction === FETCH_PSLF_FORMS) {
      dispatch(setPslfForms(payload.response));
    }

    if (
      meta.fromAction === CREATE_PSLF_FORM ||
      meta.fromAction === FETCH_CURRENT_PSLF_FORM ||
      meta.fromAction === UPDATE_CURRENT_PSLF_FORM
    ) {
      const form = get(payload, 'response.form');
      const profile = get(payload, 'response.profile');
      const status = get(payload, 'response.status');
      const currentStep = get(payload, 'response.currentStep');

      dispatch(
        setCurrentPslfForm({
          ...form,
          status,
          currentStep,
        }),
      );
      if (profile) {
        dispatch(setProfile(profile));
      }

      dispatch(pslfNetworkSuccess({ meta }));
    }

    if (meta.fromAction === SAVE_PSLF_CURRENT_STEP) {
      const currentStep = get(payload, 'response.currentStep');
      dispatch(setPslfCurrentStep(currentStep));
    }

    if (meta.fromAction === FETCH_CURRENT_PSLF_FORM) {
      dispatch(redirectTo('/pslf/form'));
    }

    if (meta.fromAction === FETCH_PSLF_CALCULATIONS) {
      dispatch(setPslfCalculations(payload.response));
    }
  },
});

export default PSLFMiddleware;
