import { keys, pick } from 'lodash';

import { SET_CSP_CALCULATOR_DATA } from 'constants/actionTypes';

const initialState = {
  averageAidByMinIncome: [],
  averageTuitions: {},
  institutionTuitions: [],
};

const calculatorDataReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CSP_CALCULATOR_DATA: {
      return pick(payload, keys(initialState));
    }

    default:
      return state;
  }
};

export default calculatorDataReducer;
