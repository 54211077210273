import {
  USStatesPostal,
  USStateDisplayNameMapping,
} from '@simplifidev/shared/dist/constants/states';

export const States = Object.values(USStatesPostal).map(postalCode => ({
  value: postalCode,
  label: USStateDisplayNameMapping[postalCode],
}));

export const StatesPostal = Object.values(USStatesPostal).map(postalCode => ({
  value: postalCode,
  label: postalCode,
}));
