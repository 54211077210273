import { format, parseISO } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import { Text, FlexRow, FlexColumn, COLORS } from '@summer/ui-components';

const MilestoneItem = ({ milestone, isFirstItem, submissionDate }) => {
  const dateFormat = 'MMM dd, yyyy';

  return (
    <ItemContainer>
      <Circle />
      <MilestoneTextContainer>
        {isFirstItem && submissionDate && (
          <Text bold size="small">
            {format(parseISO(submissionDate), dateFormat)}
          </Text>
        )}
        <Text size="small">{milestone}</Text>
      </MilestoneTextContainer>
    </ItemContainer>
  );
};

const Milestones = ({ milestones, submissionDate }) => (
  <TimelineContainer numOfMilestones={milestones.length}>
    {milestones.map((milestone, idx) => (
      <MilestoneItem
        milestone={milestone}
        isFirstItem={idx === 0}
        key={idx}
        submissionDate={submissionDate}
      />
    ))}
  </TimelineContainer>
);

const DurationLabels = ({ milestones }) => {
  const durationLabels = [
    'Employer review',
    'Summer review ~5\u00a0days',
    'MOHELA processing ~2\u20118\u00a0weeks',
    'Systems update ~4\u00a0weeks',
  ];
  return (
    <DurationLabelsContainer numOfMilestones={milestones.length}>
      {durationLabels.map((label, idx) => (
        <ItemContainer key={idx}>
          <DurationTextContainer>
            <Text size="small" color="secondary">
              {label}
            </Text>
          </DurationTextContainer>
        </ItemContainer>
      ))}
    </DurationLabelsContainer>
  );
};

const CertificationTimeline = ({ submissionDate }) => {
  const milestoneLabels = [
    'Certification submitted',
    'Your employer signs',
    'Summer sends Certification to MOHELA Servicing',
    'MOHELA Servicing Processes Certification',
    'Certified Payments show in Summer app',
  ];
  return (
    <CertificationTimelineContainer>
      <Text size="small" bold color="secondary">
        CERTIFICATION PROCESS TIMELINE
      </Text>
      <DurationLabels milestones={milestoneLabels} />
      <Milestones
        milestones={milestoneLabels}
        submissionDate={submissionDate}
      />
    </CertificationTimelineContainer>
  );
};

const CertificationTimelineContainer = styled.div`
  padding: 32px 24px;
  background-color: ${COLORS.lightestGrey};
  letter-spacing: 1.5px;
`;

const TimelineContainer = styled(FlexRow)`
  margin-top: 16px;
  padding-top: 8px;
  position: relative;
  align-items: baseline-center;

  &:before {
    background-color: ${COLORS.grey};
    content: '';
    height: 2px;
    width: ${({ numOfMilestones }) =>
      `calc(100% / ${numOfMilestones} * (${numOfMilestones} - 1))`};
    position: absolute;
    top: 0;
    margin: 0 ${({ numOfMilestones }) => `calc(100% / ${numOfMilestones} / 2)`};
  }
`;

const ItemContainer = styled(FlexColumn)`
  flex: 1;
  align-items: center;
  height: 50%;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }
`;

const MilestoneTextContainer = styled.div`
  margin-top: 16px;
  text-align: center;
`;

const Circle = styled.span`
  background-color: ${COLORS.white};
  border: 2px solid ${COLORS.grey};
  border-radius: 50%;
  position: absolute;
  top: -8px;
  width: 16px;
  height: 16px;
  z-index: 1;
`;

const DurationLabelsContainer = styled(FlexRow)`
  margin-top: 32px;
  padding: 0 ${({ numOfMilestones }) => `calc(100% / ${numOfMilestones} / 2)`};
  align-items: center;
`;

const DurationTextContainer = styled.div`
  padding: 0 12px;
  text-align: center;
`;

export default CertificationTimeline;
