import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  ArrowUp,
  Collapse,
  COLORS,
  FlexRow,
  Icon,
  InfoTooltip,
  ResponsiveColumn,
  Row,
  Text,
} from '@summer/ui-components/src';

import { getCollegeSavingsPlannerInputs } from 'redux/selectors/collegeSavingsPlanner.selectors';
import { dollars } from 'utils/numbers';

const Detail = ({ label, value }) => {
  return (
    <DetailContainer>
      <Label>{label}</Label>
      <Text weight="bold" size="large">
        {value}
      </Text>
    </DetailContainer>
  );
};

const SubDetail = ({ label, value }) => {
  return (
    <SubDetailContainer>
      <SubLabel size="small" color="secondary">
        {label}
      </SubLabel>
      <Text weight="bold">{value}</Text>
    </SubDetailContainer>
  );
};

const DetailsDisplay = ({ calculatorResults }) => {
  const { expectedAid } = useSelector(getCollegeSavingsPlannerInputs);

  const {
    netCost,
    futureSavings,
    surplus: shortfall,
    collegeCost,
  } = calculatorResults;

  const {
    total: totalFutureSavingsValue,
    futureValueContributions,
    futureValueSavings,
  } = futureSavings;

  return (
    <BorderBox>
      <CollapseContainer>
        <Collapse
          trigger={({ openState }) => (
            <CollapseTrigger>
              <Detail
                label={
                  <>
                    Expected Cost of Attendance
                    <InfoTooltipContainer>
                      {' '}
                      <InfoTooltip text="The total expected cost of attendance minus the total expected financial aid and scholarships." />
                    </InfoTooltipContainer>
                  </>
                }
                value={dollars(netCost)}
              />
              <Arrow
                open={openState}
                SvgComponent={ArrowUp}
                fill={COLORS.medGrey}
              />
            </CollapseTrigger>
          )}
        >
          <Row>
            <ResponsiveColumn mobileCol={12} tabletCol={6} smallDesktopCol={4}>
              <SubDetail
                label={
                  <>
                    Attendance Cost
                    <InfoTooltipContainer>
                      {' '}
                      <InfoTooltip text="This is the estimated cost (including tuition and room and board) of attending the school or type of school you selected in the enrollment year you selected." />
                    </InfoTooltipContainer>
                  </>
                }
                value={dollars(collegeCost)}
              />
            </ResponsiveColumn>
            <ResponsiveColumn mobileCol={12} tabletCol={6} smallDesktopCol={8}>
              <SubDetail
                label={
                  <>
                    Expected Financial Aid / Scholarships
                    <InfoTooltipContainer>
                      {' '}
                      <InfoTooltip text="The estimated amount you could received in a combination of federal financial aid and scholarships." />
                    </InfoTooltipContainer>
                  </>
                }
                value={dollars(expectedAid)}
              />
            </ResponsiveColumn>
          </Row>
        </Collapse>
      </CollapseContainer>
      <CollapseContainer>
        <Collapse
          trigger={({ openState }) => (
            <CollapseTrigger>
              <Detail
                label={
                  <>
                    Estimated Total Savings
                    <InfoTooltipContainer>
                      {' '}
                      <InfoTooltip text="This is the estimated value of your college savings in the enrollment year you selected, including your current savings and your future contributions." />
                    </InfoTooltipContainer>
                  </>
                }
                value={dollars(totalFutureSavingsValue)}
              />
              <Arrow
                open={openState}
                SvgComponent={ArrowUp}
                fill={COLORS.medGrey}
              />
            </CollapseTrigger>
          )}
        >
          <Row>
            <ResponsiveColumn mobileCol={12} tabletCol={6} smallDesktopCol={4}>
              <SubDetail
                label={
                  <>
                    Estimated Value of Current Savings
                    <InfoTooltipContainer>
                      {' '}
                      <InfoTooltip text="The estimated future value of your current savings at the expected rate of return." />
                    </InfoTooltipContainer>
                  </>
                }
                value={dollars(futureValueSavings)}
              />
            </ResponsiveColumn>
            <ResponsiveColumn mobileCol={12} tabletCol={6} smallDesktopCol={8}>
              <SubDetail
                label={
                  <>
                    Estimated Value of Monthly Contributions
                    <InfoTooltipContainer>
                      {' '}
                      <InfoTooltip text="The estimated future value of your monthly contributions at the expected rate of return." />
                    </InfoTooltipContainer>
                  </>
                }
                value={dollars(futureValueContributions)}
              />
            </ResponsiveColumn>
          </Row>
        </Collapse>
      </CollapseContainer>
      <DetailContainer>
        <Label>
          Savings Surplus / Shortfall{' '}
          <InfoTooltip text="The reason this number is not simply the difference between the expected cost and estimated savings is because payments for college are made incrementally, year by year.  Therefore, interest continues to accrue during college." />
        </Label>
        <Text weight="bold" size="extraLarge">
          {dollars(shortfall)}
        </Text>
      </DetailContainer>
    </BorderBox>
  );
};

const Arrow = styled(Icon)`
  cursor: pointer;
  transform: ${props => (props.open ? 'rotate(0deg)' : 'rotate(180deg)')};

  transition: transform 0.3s ease-out;
`;

const CollapseContainer = styled.div`
  border-bottom: 1px solid #bcd2e1;
`;

const CollapseTrigger = styled(FlexRow)`
  width: 100%;
  cursor: pointer;
  justify-content: space-between;
`;

const DetailContainer = styled.div`
  padding: 40px 8px;
`;

const Label = styled(Text)`
  margin-bottom: 16px;
`;

const SubDetailContainer = styled.div`
  padding: 8px 8px 40px;
`;

const SubLabel = styled(Text)`
  margin-bottom: 8px;
`;

const BorderBox = styled.div`
  margin: 16px 0 24px;
  border: 1px solid #bcd2e1;
  padding: 0 16px;
`;

const InfoTooltipContainer = styled.span`
  white-space: nowrap;
`;

export default DetailsDisplay;
