import React from 'react';
import styled from 'styled-components';

import { Alert } from '@summer/ui-components';

import Link from 'components/shared/typography/Link';
import { pslfWaiverLink } from 'constants/externalLinks/marketingSite';

const PslfWaiverAlert = () => (
  <StyledAlert theme="success" dismissable={false}>
    The Department of Education has expanded PSLF eligibility to more loan types
    and repayment plans. We&rsquo;ve updated our tool to reflect the changes,
    and you can{' '}
    <Link underline inheritColor href={pslfWaiverLink}>
      read more about the details here.
    </Link>
  </StyledAlert>
);

const StyledAlert = styled(Alert)`
  background-color: #e4f8f0;
  z-index: 2;
  margin-bottom: 16px;
`;

export default PslfWaiverAlert;
