import { keys, pick } from 'lodash';

import { SET_RETIREMENT_MATCH } from 'constants/actionTypes';

const initialState = {
  consentedAt: null,
};

const retirementMatchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_RETIREMENT_MATCH: {
      return { ...state, ...pick(payload, keys(initialState)) };
    }

    default:
      return state;
  }
};

export default retirementMatchReducer;
