import { format, parseISO } from 'date-fns';
import { isNil } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Text,
  Header,
  Icon,
  LoadingBars,
  CheckSmall,
  FlexColumn,
  COLORS,
} from '@summer/ui-components';

import FancyCard from 'components/shared/FancyCard';
import InfoTooltip from 'components/shared/InfoTooltip';
import { MobileSize } from 'constants/styleguide';
import { usePlatform } from 'hooks/platform';
import {
  getLastSubmittedIdrFormPlanType,
  getLastSubmittedIdrFormFirstPayment,
  getLastSubmittedIdrFormSignedAt,
} from 'redux/selectors/idr.selectors';
import { hexToRgba } from 'utils/common';
import { renderPlanTypeNames } from 'utils/idr';

const CustomLeftHeader = ({ isMobile }) => (
  <LeftHeaderContainer>
    <Text as="span" size="extraSmall" paragraph>
      Processing time: {isMobile && <br />} 4 – 8 weeks
    </Text>
    <InfoTooltip text="Your monthly payment will update once your loan servicer processes and accepts your application. This can take up to four to eight weeks." />
  </LeftHeaderContainer>
);

const SubmittedButton = ({ isLoading }) => (
  <SubmittedIndicator>
    {isLoading ? (
      <LoadingBars color={COLORS.blueBlack} />
    ) : (
      <>
        <CheckMarkIconStyled
          width={15}
          SvgComponent={CheckSmall}
          stroke={COLORS.darkerGrey}
        />
        <Text as="span" bold size="medium">
          Submitted
        </Text>
      </>
    )}
  </SubmittedIndicator>
);

const MostRecentApplication = ({
  firstPayment,
  planType,
  signedAt,
  isLoading,
}) => {
  const { isMobile } = usePlatform();

  return (
    <>
      <RecentApplicationHeader left small weight="medium" color="secondary">
        Your most recent application:
      </RecentApplicationHeader>
      <FancyCard
        testId="idr-most-recent-app-card"
        CustomLeftHeader={() => <CustomLeftHeader isMobile={isMobile} />}
        headerInfo={
          !isNil(firstPayment)
            ? [
                {
                  title: isMobile
                    ? 'Est. monthly payment:'
                    : 'Est. new monthly payment:',
                  value: firstPayment,
                },
              ]
            : []
        }
      >
        <CardContent>
          <PlanInfo>
            <Header h3>{renderPlanTypeNames(planType)}</Header>
            <Text paragraph weight="medium" size="small">
              {signedAt &&
                `Submitted ${format(parseISO(signedAt), 'MMM d, yyyy')}`}
            </Text>
          </PlanInfo>
          <SubmittedButton isLoading={isLoading} />
        </CardContent>
      </FancyCard>
    </>
  );
};

const RecentApplicationHeader = styled(Text)`
  margin-bottom: 8px;
`;

const LeftHeaderContainer = styled.div`
  display: flex;
  text-align: left;

  @media (max-width: ${MobileSize}) {
    width: 60%;
    margin-right: 24px;
  }
`;

const CheckMarkIconStyled = styled(Icon)`
  margin-right: 8px;
  display: inline;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
  }
`;

const PlanInfo = styled(FlexColumn)`
  text-align: left;
  margin-right: 8px;

  > * {
    margin-bottom: 16px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${MobileSize}) {
    margin-right: 0;
    margin-bottom: 8px;

    > * {
      margin-bottom: 8px;
    }
  }
`;

const SubmittedIndicator = styled.div`
  align-self: flex-end;
  width: auto;
  text-align: center;
  background-color: ${hexToRgba(COLORS.jade, '0.1')};
  border-radius: 3px;
  padding: 14px 16px;
`;

const mapStateToProps = state => ({
  planType: getLastSubmittedIdrFormPlanType(state),
  firstPayment: getLastSubmittedIdrFormFirstPayment(state),
  signedAt: getLastSubmittedIdrFormSignedAt(state),
});

export default connect(mapStateToProps)(MostRecentApplication);
