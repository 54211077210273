import { FETCH_RECOMMENDATIONS, NETWORK_SUCCESS } from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import { setRecommendations } from 'redux/actions/recEngine.actions';
import handleSideEffects from 'redux/handleSideEffects';

const recEngineMiddleware = handleSideEffects({
  [FETCH_RECOMMENDATIONS]: dispatch => {
    dispatch(
      apiRequest({
        url: '/user/v2/recommendations',
        method: 'GET',
        fromAction: FETCH_RECOMMENDATIONS,
        label: 'fetchRecommendations',
      }),
    );
  },

  [NETWORK_SUCCESS]: (dispatch, { payload, meta }) => {
    if (meta.fromAction === FETCH_RECOMMENDATIONS) {
      dispatch(setRecommendations(payload.response));
    }
  },
});

export default recEngineMiddleware;
