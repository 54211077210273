import { flow, set } from 'lodash/fp';

import { SET_CURRENT_IDR_FORM } from 'constants/actionTypes';

const initialState = {
  userTaxReturn: false,
  spouseTaxReturn: false,
  jointTaxReturn: false,
  userProofOfIncome: false,
  spouseProofOfIncome: false,
};

const supportingDocuments = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_IDR_FORM: {
      const {
        userTaxReturnFileRequired,
        spouseTaxReturnFileRequired,
        jointTaxReturnFileRequired,
        userProofOfIncomeFileRequired,
        spouseProofOfIncomeFileRequired,
      } = payload;

      return flow([
        set('userTaxReturn', userTaxReturnFileRequired),
        set('spouseTaxReturn', spouseTaxReturnFileRequired),
        set('jointTaxReturn', jointTaxReturnFileRequired),
        set('userProofOfIncome', userProofOfIncomeFileRequired),
        set('spouseProofOfIncome', spouseProofOfIncomeFileRequired),
      ])(state);
    }

    default:
      return state;
  }
};

export default supportingDocuments;
