import React from 'react';
import styled from 'styled-components';

import {
  Header,
  FlexCenter,
  Text,
  SvgImage,
  SummerBasicConsolidation,
} from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import {
  fsaConsolidationToolLink,
  fsaPslfConsolidationLink,
  fsaPslfToolLink,
} from 'constants/externalLinks/fsa';
import { TabletSize } from 'constants/styleguide';

const ConsolidatePslfSubmitBasic = () => {
  return (
    <Container data-testid="consolidate-pslf-submit-basic">
      <SummerBasicIcon SvgComponent={SummerBasicConsolidation} />
      <TextContainer>
        <InstructionsHeader h3>
          Consolidate your loans and certify your employment for PSLF
        </InstructionsHeader>
        <SectionContainer>
          <Text paragraph decoration="underLine">
            Consolidate Ineligible Loans
          </Text>
          <Text paragraph>
            Some types of federal student loans aren&rsquo;t eligible for all of
            the IDR plans that could lower your monthly payments. By
            consolidating those loans, you&rsquo;d be combining them into one
            new federal loan that&rsquo;s eligible for IDR as well as federal
            forgiveness programs like Public Service Loan Forgiveness (PSLF).
          </Text>

          <Text paragraph>
            You can complete the process of consolidating your loans and
            enrolling in the IDR plan recommended above through{' '}
            <Link underline inheritColor href={fsaConsolidationToolLink}>
              Federal Student Aid&rsquo;s online tool.
            </Link>
          </Text>

          <Text paragraph>
            If you have multiple types of student loans, you’ll have the option
            of selecting which loans to consolidate. If you have FFEL, Parent
            PLUS, or Perkins loans, they will need to be consolidated to become
            eligible for PSLF. Direct loans are already eligible for PSLF and
            don’t typically need to be consolidated. (For certain loan
            situations, it’s possible your forgiveness timeline could benefit
            from consolidating your Direct loans as well - you can read more
            about the details at{' '}
            <Link underline inheritColor href={fsaPslfConsolidationLink}>
              Federal Student Aid
            </Link>
            .)
          </Text>
        </SectionContainer>

        <SectionContainer>
          <Text paragraph decoration="underLine">
            Certify Your Employment
          </Text>

          <Text paragraph>
            To pursue PSLF, you’ll also need to certify your employment. You can
            digitally sign and submit the employment certification form using
            the{' '}
            <Link underline inheritColor href={fsaPslfToolLink}>
              PSLF Help Tool
            </Link>{' '}
            at Federal Student Aid. You can complete this form for every public
            service employer where you&rsquo;ve worked since 2007 to make sure
            you get all the credit you&rsquo;re eligible for.
          </Text>

          <InstructionsList>
            <li>
              <Text paragraph>
                First, you&rsquo;ll complete the form through the digital tool
              </Text>
            </li>
            <li>
              <Text paragraph>
                Second, follow up with the employer contact you listed, and
                verify that they have received the &rsquo;Docusign&rsquo; link
                to complete the form digitally
              </Text>
            </li>
            <li>
              <Text paragraph>
                Finally, once your employers digitally signs their section of
                the form, it will be electronically submitted to the PSLF
                servicer for processing.
              </Text>
            </li>
          </InstructionsList>
        </SectionContainer>
      </TextContainer>
    </Container>
  );
};

const Container = styled(FlexCenter)`
  padding: 30px 80px;
  margin: 24px 0;
  width: 100%;

  @media (max-width: ${TabletSize}) {
    padding: 0 16px;
    flex-direction: column;
  }
`;

const InstructionsHeader = styled(Header)`
  font-size: 24px;
  margin-bottom: 12px;
`;

const SectionContainer = styled.div`
  margin-bottom: 32px;
  > * {
    margin-bottom: 8px;
  }
`;

const TextContainer = styled.div`
  margin-left: 118px;

  @media (max-width: ${TabletSize}) {
    margin-top: 16px;
    margin-left: 0px;
  }
`;

const SummerBasicIcon = styled(SvgImage)`
  max-width: 300px;

  @media (max-width: ${TabletSize}) {
    max-width: 200px;
  }
`;

const InstructionsList = styled.ul`
  margin-left: 24px;

  > li {
    margin-top: 8px;
  }
`;

export default ConsolidatePslfSubmitBasic;
