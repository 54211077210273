import {
  NETWORK_SUCCESS,
  FETCH_LOAN_TYPES,
  FETCH_SERVICERS,
  FETCH_REPAYMENT_PLAN_TYPES,
} from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import {
  setLoanTypes,
  setServicers,
  setRepaymentPlanTypes,
} from 'redux/actions/constants.actions';
import handleSideEffects from 'redux/handleSideEffects';

const constantsMiddleware = handleSideEffects({
  [FETCH_LOAN_TYPES]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: '/loans/types',
        method: 'GET',
        body: payload,
        fromAction: FETCH_LOAN_TYPES,
        label: 'fetchLoanTypes',
      }),
    );
  },

  [FETCH_REPAYMENT_PLAN_TYPES]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: '/loans/repayment-plan-types',
        method: 'GET',
        body: payload,
        fromAction: FETCH_REPAYMENT_PLAN_TYPES,
        label: 'fetchRepaymentPlanTypes',
      }),
    );
  },

  [FETCH_SERVICERS]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: '/servicers',
        method: 'GET',
        body: payload,
        fromAction: FETCH_SERVICERS,
      }),
    );
  },

  [NETWORK_SUCCESS]: (dispatch, { payload, meta }) => {
    if (meta.fromAction === FETCH_LOAN_TYPES) {
      dispatch(setLoanTypes(payload.response));
    }

    if (meta.fromAction === FETCH_REPAYMENT_PLAN_TYPES) {
      dispatch(setRepaymentPlanTypes(payload.response));
    }

    if (meta.fromAction === FETCH_SERVICERS) {
      dispatch(setServicers(payload.response));
    }
  },
});

export default constantsMiddleware;
