import React from 'react';
import styled from 'styled-components';

import { Icon, LockWithKeyHole, COLORS } from '@summer/ui-components';

const SecurityHeader = () => (
  <IconContainer>
    <Icon SvgComponent={LockWithKeyHole} width={28} stroke={COLORS.azure} />
  </IconContainer>
);

const IconContainer = styled.div`
  margin-bottom: 32px;
`;

export default SecurityHeader;
