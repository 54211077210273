import { camelCase } from 'lodash';
import React from 'react';

import { TextInput } from '@summer/ui-components';

import { Row } from 'components/pages/idr/wizard/shared/styles';
import { InputWrapper } from 'components/pages/idr/wizard/steps/Submit/shared/Inputs';

const Name = ({
  prefix,
  formik: { values, errors, handleChange, handleBlur, touched, isSubmitting },
}) => {
  const namePrefix = 'submit.';
  const formikPrefix = prefix || '';

  const getFormikName = name => namePrefix + camelCase(formikPrefix + name);
  const getFormikPropertyName = name => camelCase(formikPrefix + name);

  return (
    <Row>
      <InputWrapper>
        <TextInput
          label="First Name"
          name={getFormikName('FirstName')}
          touched={touched[getFormikPropertyName('FirstName')]}
          isSubmitting={isSubmitting}
          error={errors[getFormikPropertyName('FirstName')]}
          value={values[getFormikPropertyName('FirstName')] || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="First Name"
        />
      </InputWrapper>
      <InputWrapper>
        <TextInput
          label="Last Name"
          name={getFormikName('LastName')}
          touched={touched[getFormikPropertyName('LastName')]}
          isSubmitting={isSubmitting}
          error={errors[getFormikPropertyName('LastName')]}
          value={values[getFormikPropertyName('LastName')] || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Last Name"
        />
      </InputWrapper>
    </Row>
  );
};

export default Name;
