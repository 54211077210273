import {
  API_REQUEST,
  NETWORK_FAILURE,
  NETWORK_PENDING,
  NETWORK_SUCCESS,
  S3_REQUEST,
} from 'constants/actionTypes';

export const apiRequest = ({
  urlWithoutBase,
  url,
  params,
  method,
  headers,
  body,
  fromAction,
  label,
  meta,
}) => ({
  type: API_REQUEST,
  payload: {
    urlWithoutBase,
    url,
    params,
    method,
    headers,
    body,
  },
  meta: {
    fromAction,
    label,
    ...meta,
  },
});

export const s3Request = ({ url, method, body, fromAction, label, meta }) => ({
  type: S3_REQUEST,
  payload: {
    url,
    method,
    body,
  },
  meta: {
    fromAction,
    label,
    ...meta,
  },
});

export const networkPending = ({ meta }) => ({
  type: NETWORK_PENDING,
  meta: {
    ...meta,
  },
});

export const networkSuccess = ({ response, meta }) => ({
  type: NETWORK_SUCCESS,
  payload: {
    response,
  },
  meta: {
    ...meta,
  },
});

export const networkFailure = ({ error, meta }) => ({
  type: NETWORK_FAILURE,
  payload: {
    error,
  },
  meta: {
    ...meta,
  },
});
