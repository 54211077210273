import React from 'react';
import styled from 'styled-components';

import Cleave from 'cleave.js/react';
import { format, parseISO } from 'date-fns';
import { compact, findIndex } from 'lodash';
import { Field } from 'formik';
import { COLORS } from '../../../helpers/constants/styleguide';
import InputContainer, { FormInput } from '../Input';

const DELIMITER = ' / ';

/*
 This is a shared date input that use cleave.
 Date Pattern:
  An Array value indicates the date pattern.
  To indicate what patterns it should apply, you can use (only): 'Y', 'm' and 'd'.
  Default value: ['d', 'm', 'Y']

  Important! this component only support full year.
  Important! this component support Pattern with only month and year
 */

const arrayPatternToStringPattern = arrayPattern => {
  return arrayPattern
    .map(item => {
      switch (item) {
        case 'Y':
          return 'yyyy';
        case 'm':
          return 'MM';
        case 'd':
          return 'dd';
        default:
          return null;
      }
    })
    .join('/');
};

class DateInput extends React.Component {
  static defaultProps = {
    disabled: false,
    datePattern: ['m', 'd', 'Y'],
    onFocus: () => {},
    onBlur: () => {},
    placeholder: 'MM / DD / YYYY',
  };

  formatDateWithPattern = () => {
    const { value, datePattern } = this.props;
    const stringPattern = arrayPatternToStringPattern(datePattern);

    return value ? format(parseISO(value), stringPattern) : '';
  };

  constructor(props) {
    super(props);
    this.state = {
      initValue: this.formatDateWithPattern(),
      ISODateValue: '',
    };
  }

  onDateInit = cleave => {
    this.setState({ dateCleave: cleave });
  };

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    const { dateCleave, ISODateValue } = this.state;

    if (prevProps.value !== value && value !== ISODateValue) {
      dateCleave.setRawValue(
        value ? this.formatDateWithPattern().replace(DELIMITER) : '',
      );
    }
  }

  setFormikValue = e => {
    const { datePattern, name } = this.props;
    const { dateCleave } = this.state;
    const valueAsArray = compact(e.target.value.split(DELIMITER));

    let ISODateValue = dateCleave.getISOFormatDate();
    this.setState({ ISODateValue });

    if (datePattern.length === 2 && valueAsArray.length === 2) {
      const yIndex = findIndex(datePattern, item => item === 'Y');
      const mIndex = findIndex(datePattern, item => item === 'm');

      if (yIndex >= 0 && mIndex >= 0 && valueAsArray[yIndex].length === 4) {
        ISODateValue = `${valueAsArray[yIndex]}-${valueAsArray[mIndex]}`;
        this.setState({
          ISODateValue,
        });
      }
    }

    this.props.onChange(name, ISODateValue.replace(' ', '')); // remove not necessary spaces
  };

  renderInputText = handleFocusChange => {
    const { placeholder, name, onBlur, disabled, datePattern, validate } =
      this.props;

    return (
      <Field type="text" name={name} validate={validate}>
        {() => (
          <CleaveInput
            inputMode="decimal"
            name={name}
            value={this.state.initValue}
            options={{
              date: true,
              datePattern,
              delimiter: DELIMITER,
            }}
            onInit={this.onDateInit}
            placeholder={placeholder}
            onChange={this.setFormikValue}
            onFocus={handleFocusChange}
            disabled={disabled}
            onBlur={e => {
              handleFocusChange();
              onBlur(e);
            }}
            data-testid={`input-date-${name}`}
            id={`${name}-input`}
          />
        )}
      </Field>
    );
  };

  render() {
    const { error, touched, isSubmitting, label, helpMessage } = this.props;
    return (
      <InputContainer
        error={error}
        touched={touched}
        isSubmitting={isSubmitting}
        label={label}
        helpMessage={helpMessage}
      >
        {this.renderInputText}
      </InputContainer>
    );
  }
}

const CleaveInput = styled(FormInput.withComponent(Cleave))`
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  margin: auto 0;
  caret-color: ${COLORS.darkerGrey};

  &::placeholder {
    color: ${COLORS.medGrey};
  }

  &:focus {
    outline: none;
  }
`;

export default DateInput;
