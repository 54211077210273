import { useEffect, useRef, useState } from 'react';

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useCompare = value => {
  const previousValue = usePrevious(value);
  return previousValue !== value;
};

export const useCounter = (initialState = 0) => {
  const [count, setCount] = useState(initialState);
  const resetCount = () => setCount(initialState);
  const incrementCount = () => setCount(count + 1);
  return [count, resetCount, incrementCount];
};

// on initial render, a loading prop will be false if it's looking at a request made on component mount.
// usePrevious(isLoading) === undefined checks that it is the initial render, so that we load the loading state on initial render.
export const useIsPageLoading = isLoading => {
  return usePrevious(isLoading) === undefined || isLoading;
};

export const useReanimateAlert = () => {
  /*
   * This hook is used to reanimate the missing info alert when a user clicks the disabled submit
   * button.
   * It sets the dismissed state to true and then immediately back to false, in order to
   * force the component to remount.
   */
  const [alertDismissed, setAlertDismissed] = useState(false);
  const reanimateAlert = () => setAlertDismissed(true);
  useEffect(
    function triggerNewAlertAnimation() {
      let timeout;
      if (alertDismissed === true) {
        timeout = setTimeout(() => setAlertDismissed(false), 0);
      }
      return () => clearTimeout(timeout);
    },
    [alertDismissed],
  );
  return { alertDismissed, reanimateAlert };
};

// Adapted from  https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(
    function rememberLatestCallback() {
      savedCallback.current = callback;
    },
    [callback],
  );

  useEffect(
    function setUpInterval() {
      if (delay !== null) {
        const id = setInterval(() => savedCallback.current(), delay);
        return () => clearInterval(id);
      }
      return null;
    },
    [delay],
  );
};

export const useTimeout = (callback, delay) => {
  const savedCallback = useRef(callback);

  useEffect(
    function rememberLatestCallback() {
      savedCallback.current = callback;
    },
    [callback],
  );

  useEffect(
    function setUpTimeout() {
      if (delay !== null) {
        const id = setTimeout(() => savedCallback.current(), delay);
        return () => clearTimeout(id);
      }
      return null;
    },
    [delay],
  );
};
