import { useEffect } from 'react';

export const useClearFieldOnUnmount = (
  fieldName,
  initialState,
  setFieldValue,
) =>
  useEffect(() => {
    return () => {
      setFieldValue(fieldName, initialState);
    };
  }, [setFieldValue, fieldName, initialState]);
