import React from 'react';

import { Strong } from '@summer/ui-components';

import {
  List,
  ListItem,
  SmallTitle,
  GuideText,
} from 'components/pages/resourceCenter/guides/shared';
import Section from 'components/pages/resourceCenter/guides/shared/Section';

class AccruedInterestGuide extends React.Component {
  render() {
    return (
      <>
        <Section title="Loan Basics 101">
          <GuideText>
            Time to learn a few key ideas that&rsquo;ll help you through the
            rest of the process. Let&rsquo;s start at the beginning.
          </GuideText>

          <SmallTitle>What is a loan?</SmallTitle>
          <GuideText>
            Simple question, right? Not always. A <Strong>loan</Strong> is money
            you borrowed from a <Strong>lender</Strong>—whether that&rsquo;s the
            US government or a bank—that you have to pay back.
          </GuideText>

          <SmallTitle>
            What does &ldquo;principal balance&rdquo; mean?
          </SmallTitle>
          <GuideText>
            The <Strong>principal balance</Strong> on your loan is the amount of
            money that you need to pay back on your loan. But keep in mind, the
            principal balance doesn&rsquo;t include interest.
          </GuideText>

          <SmallTitle>What&rsquo;s &ldquo;interest&rdquo;?</SmallTitle>
          <GuideText>
            <Strong>Interest</Strong> is the amount of money you have to pay
            back on top of what you borrowed. You might think of it as a fee you
            pay so you can borrow money for school.
          </GuideText>
          <GuideText>
            Your <Strong>interest rate</Strong> tells you exactly how much extra
            you owe on your loan. The higher the interest rate, the more
            expensive your loan, and the more you’ll have to pay back.
          </GuideText>

          <SmallTitle>Can you give me an example?</SmallTitle>
          <GuideText>
            Say that you borrowed $35,000 for school at a 6% interest rate.
            You&rsquo;re going to have to pay back $389 per month over 10 years,
            which adds up to $46,629 in total. The extra $11,629 that makes up
            the difference between what you borrowed and what you&rsquo;ll need
            to pay back is the <Strong>interest</Strong> on your loan, the
            amount you pay for being able to borrow.
          </GuideText>
        </Section>

        <Section title="What is accrued interest">
          <GuideText>
            Over time, the amount of interest that you owe on top of the amount
            you first borrowed grows, or “accrues”. Accrued interest is
            interest—the extra money you pay as a kind of fee to borrow money
            for school—that has built up as time has passed.
          </GuideText>

          <SmallTitle>Can you give me an example?</SmallTitle>
          <GuideText>
            Let’s say you borrowed $35,000 at a 6% interest rate. Let’s also
            assume that interest accrues daily (rather than monthly). Here’s how
            interest will accrue, or build up, as time passes.
          </GuideText>
          <GuideText>
            For each day that passes, you’ll owe an additional $1.67. Here’s how
            the math works out:
          </GuideText>

          <List>
            <ListItem>
              First, find the “daily” interest rate by calculating 6% divided by
              365 which equals 0.0167% (or 0.000167).
            </ListItem>
            <ListItem>
              Then, multiply 0.0167% by the amount you borrowed $35,000 which
              equals $5.85.
            </ListItem>
            <ListItem>
              This means that, for each day that passes, you’ll owe an
              additional $5.85 on your loans.
            </ListItem>
            <ListItem>
              Now let’s imagine that a year passes and you don’t make any
              payments on your loans. You’d owe an additional $2,133 on your
              loans after that time (with $5.85 in interest accruing each day
              for 365 days a year).
            </ListItem>
            <ListItem>
              The extra $2,133 that you owe is the{' '}
              <Strong>accrued interest</Strong>.
            </ListItem>
          </List>

          <SmallTitle>When does interest accrue?</SmallTitle>
          <GuideText>
            <Strong>Unsubsidized federal loans</Strong> accrue interest while
            you’re in school, which means you end up paying more than if you
            have <Strong>subsidized federal loans</Strong>, which do not accrue
            interest while you’re in school.
          </GuideText>
          <GuideText>
            <Strong>Private loans</Strong> may or may not accrue interest while
            you’re in school, depending on your lender.
          </GuideText>
          <GuideText>
            It’s important to remember that, depending on the type of loan you
            have, interest can also accrue even when you’re not required to make
            payments (when your loans are in-grace period, deferment, or
            forbearance, for example).
          </GuideText>
        </Section>

        <Section title="What is capitalized interest">
          <GuideText>
            Now that you know what accrued interest is, let’s talk about
            capitalized interest.
          </GuideText>
          <GuideText>
            <Strong>Capitalized interest</Strong> is unpaid accrued interest
            that gets added to the principal balance of your loan. When interest
            is “capitalized,” or added to your loan’s principal balance, you’re
            effectively paying “interest on interest,” which can drive up the
            cost of your loan.
          </GuideText>

          <SmallTitle>When does interest get capitalized?</SmallTitle>

          <GuideText>
            Interest can be “capitalized,” added to what you owe on your loan,
            at different times during the life of your loan. Here are a few
            examples of when this can happen:
          </GuideText>
          <List>
            <ListItem>
              After you’ve left school, when your grace periods ends and you
              have to start repaying your loans for the first time.
            </ListItem>
            <ListItem>
              After your deferment or forbearance periods end.{' '}
              <Strong>Deferment</Strong> and <Strong>forbearance</Strong> are
              temporary pauses on your monthly payments your lender can
              sometimes grant to you.
            </ListItem>
          </List>
        </Section>

        <Section title="Why pay off accrued interest before repayment">
          <SmallTitle>Continuing our previous example</SmallTitle>
          <GuideText>
            Just like before, let’s say you borrowed $35,000 at a 6% interest
            rate. We’ll also assume that interest accrues daily (instead of
            monthly). After a year, $2,133 of interest has accrued. In other
            words, you now owe $2,133 in interest in addition to the $35,000
            that you first borrowed.
          </GuideText>
          <GuideText>
            Now let’s imagine that you’re going to enter repayment tomorrow. You
            have two choices before you start paying back your loans: pay down
            your accrued interest before it gets capitalized (meaning added to
            your principal balance) or don’t. Let’s go through each choice and
            how it can affect you.
          </GuideText>

          <SmallTitle>
            Scenario 1 - You didn’t pay down accrued interest before entering
            repayment
          </SmallTitle>
          <GuideText>
            If you didn’t pay down the accrued interest, the $2,133 of accrued
            interest would get capitalized. The $2,133 would be added to the
            principal balance of your loan and the amount that you owe would
            increase to $37,133.
          </GuideText>
          <GuideText>
            If you have a standard 10-year repayment plan, that means that
            you’ll owe $412 per month, which would add up to $49,470 in total
            over 10 years. Of that amount, $12,337 would be interest alone.
          </GuideText>

          <SmallTitle>
            Scenario 2 - You paid down accrued interest before entering
            repayment
          </SmallTitle>
          <GuideText>
            If you did pay down your accrued interest of $2,133, that amount
            would not be added to the principal balance of your loan. Your
            overall savings, however, would end up being much more than the
            $2,133.
          </GuideText>
          <GuideText>
            If you have a standard 10-year repayment plan, for example, you’d
            owe $389 per month, which would total $46,629 over 10 years. Of that
            amount, $11,629 would be interest alone.
          </GuideText>

          <SmallTitle>Comparing the two scenarios:</SmallTitle>
          <GuideText>
            If you paid down your accrued interest before entering repayment,
            you could <Strong>save an additional $708</Strong> over the life of
            your loan. The $12,337 of interest you’d pay in in Scenario 1 is
            $708 more than the $11,629 of interest you’d pay in Scenario 2, so
            paying off your accrued interest before you start paying back your
            loan is the way to save more.
          </GuideText>
        </Section>

        <Section title="How can I pay down accrued interest and save money">
          <SmallTitle>
            Pay off your accrued interest before entering repayment:
          </SmallTitle>
          <GuideText>
            It’s best for you to pay off your accrued interest before you enter
            repayment because that’s when the accrued interest gets capitalized
            (added to the principal balance of your loan). If your loans are in
            a grace period, deferment, or forbearance, you should try to pay
            down the accrued interest before you enter repayment and have to
            start making payments on your loan again.
          </GuideText>

          <SmallTitle>Reach out to your servicer:</SmallTitle>
          <GuideText>
            To pay down your accrued interest, you should reach out to your loan
            servicer to let them know that you’d like to make an extra payment
            toward your accrued interest. Depending on your servicer, you can do
            this online or call up your servicer directly.
          </GuideText>

          <SmallTitle>What if I can’t pay down my accrued interest?</SmallTitle>
          <GuideText>
            If you can’t pay down all of your accrued interest, pay down as much
            as you can. If you can’t pay down any of your accrued interest
            before entering repayment, that’s okay, too. We’ll help find other
            opportunities for you to save money while you repay your loans.
          </GuideText>
        </Section>
      </>
    );
  }
}

export default AccruedInterestGuide;
