import React, { useEffect } from 'react';

import { MoneyInput } from '@summer/ui-components';

import {
  Question,
  Label,
  SmallTextInputContainer,
} from 'components/pages/idr/wizard/shared/styles';
import { validateNumber } from 'utils/validate';

const UnemploymentIncome = ({
  formik: {
    values,
    errors,
    handleBlur,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
  },
}) => {
  useEffect(
    function clearFieldOnUnmount() {
      return () => setFieldValue(`tellUsAboutYou.unemploymentIncome`, '');
    },
    [setFieldValue],
  );

  return (
    <div>
      <Question id="unemploymentIncome">
        <Label>
          How much in unemployment income are you receiving each month?
        </Label>
        <SmallTextInputContainer>
          <MoneyInput
            name="tellUsAboutYou.unemploymentIncome"
            value={values.unemploymentIncome}
            error={errors.unemploymentIncome}
            touched={touched.unemploymentIncome}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            isSubmitting={isSubmitting}
            onBlur={handleBlur}
            validate={validateNumber()}
          />
        </SmallTextInputContainer>
      </Question>
    </div>
  );
};

export default UnemploymentIncome;
