import { Elements } from 'prismic-reactjs';
import React from 'react';

import { Text, Header, Strong } from '@summer/ui-components';

import { Link } from 'components/shared/typography';

// -- Function to add unique key to props
const propsWithUniqueKey = (props, key) => {
  return Object.assign(props || {}, { key });
};

const linkResolver = () => {}; // placeholder

// -- HTML Serializer
// This function will be used to change the way the HTML is loaded
export const htmlSerializer = (type, element, content, children, key) => {
  let props = {};
  switch (type) {
    case Elements.paragraph:
      props = { paragraph: true, as: 'p' };
      if (element.label === 'extraSmall') {
        props.size = 'extraSmall';
      }

      return React.createElement(
        Text,
        propsWithUniqueKey(props, key),
        children,
      );

    case Elements.listItem:
      props = {
        paragraph: true,
        as: 'li',
        style: { marginLeft: '24px', marginTop: '2px', marginBottom: '2px' },
      };
      return React.createElement(
        Text,
        propsWithUniqueKey(props, key),
        children,
      );

    case Elements.hyperlink:
      props = {
        href: element.data.url || linkResolver(element.data),
        underline: true,
        inheritColor: true,
      };
      return React.createElement(
        Link,
        propsWithUniqueKey(props, key),
        children,
      );

    case Elements.strong: // Strong
      return React.createElement(
        Strong,
        propsWithUniqueKey(props, key),
        children,
      );

    case Elements.heading1: // Heading 1
      props = { as: 'h1' };
      return React.createElement(
        Header,
        propsWithUniqueKey(props, key),
        children,
      );

    case Elements.heading2: // Heading 2
      props = { as: 'h2' };
      return React.createElement(
        Header,
        propsWithUniqueKey(props, key),
        children,
      );

    case Elements.heading3: // Heading 3
      props = { as: 'h3' };
      return React.createElement(
        Header,
        propsWithUniqueKey(props, key),
        children,
      );

    case Elements.heading4: // Heading 4
      props = { as: 'h4' };
      return React.createElement(
        Header,
        propsWithUniqueKey(props, key),
        children,
      );

    case Elements.heading5: // Heading 5
      props = { as: 'h5' };
      return React.createElement(
        Header,
        propsWithUniqueKey(props, key),
        children,
      );

    case Elements.heading6: // Heading 6
      props = { as: 'h5' };
      return React.createElement(
        Header,
        propsWithUniqueKey(props, key),
        children,
      );

    // Return null to stick with the default behavior
    default:
      return null;
  }
};
