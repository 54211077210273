import {
  FETCH_SIGNATURE_REQUEST_LIST_V3,
  SET_SIGNATURE_REQUEST_LIST,
  FETCH_SIGNATURE_REQUEST_JWT,
  SET_SIGNATURE_REQUEST,
  UPDATE_SIGNATURE_REQUEST,
  UPDATE_SIGNATURE_REQUEST_JWT,
} from 'constants/actionTypes';

export const fetchSignatureRequestListV3 = forms => ({
  type: FETCH_SIGNATURE_REQUEST_LIST_V3,
  payload: {
    forms,
  },
});

export const setSignatureRequestList = signatureRequestList => ({
  type: SET_SIGNATURE_REQUEST_LIST,
  payload: {
    signatureRequestList,
  },
});

export const fetchSignatureRequestJwt = ({ token, signRequestId }) => ({
  type: FETCH_SIGNATURE_REQUEST_JWT,
  payload: { token, signRequestId },
});

export const updateSignatureRequest = (signatureRequestId, complete) => ({
  type: UPDATE_SIGNATURE_REQUEST,
  payload: {
    signatureRequestId,
    complete,
  },
});

export const setSignatureRequest = url => ({
  type: SET_SIGNATURE_REQUEST,
  payload: { url },
});

export const updateSignatureRequestJwt = (
  jwtToken,
  signatureRequestId,
  complete,
) => ({
  type: UPDATE_SIGNATURE_REQUEST_JWT,
  payload: {
    jwtToken,
    signatureRequestId,
    complete,
  },
});
