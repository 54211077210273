import React, { useEffect } from 'react';

import { DateInput } from '@summer/ui-components';

import {
  Question,
  Label,
  SmallTextInputContainer,
} from 'components/pages/idr/wizard/shared/styles';
import { validateString } from 'utils/validate';

const SpouseUnemploymentEndDate = ({
  formik: { values, errors, handleBlur, touched, isSubmitting, setFieldValue },
}) => {
  useEffect(
    function clearFieldOnUnmount() {
      return () =>
        setFieldValue(`tellUsAboutYou.spouseUnemploymentEndDate`, '');
    },
    [setFieldValue],
  );

  return (
    <div>
      <Question id="spouseUnemploymentEndDate">
        <Label>
          When does your spouse expect their unemployment income to end?
        </Label>
        <SmallTextInputContainer>
          <DateInput
            name="tellUsAboutYou.spouseUnemploymentEndDate"
            touched={touched.spouseUnemploymentEndDate}
            isSubmitting={isSubmitting}
            error={errors.spouseUnemploymentEndDate}
            value={values.spouseUnemploymentEndDate}
            onChange={setFieldValue}
            onBlur={handleBlur}
            validate={validateString('Please enter a valid date')}
          />
        </SmallTextInputContainer>
      </Question>
    </div>
  );
};

export default SpouseUnemploymentEndDate;
