import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { ResponsiveColumn, Row } from '@summer/ui-components';

import LoadingAnimation from 'components/common/LoadingAnimation';
import WizardContent from 'components/layout/wizard/WizardContent';
import { MobileSize } from 'constants/styleguide';
import { getUserFirstName } from 'redux/selectors/profile.selectors';

const animationSteps = [
  'Checking your loan status…',
  'Confirming your loan history…',
  'Determining your best path forward…',
];

const CalculationAnimation = ({ name, navigation }) => {
  return (
    <WizardContent>
      <Container>
        <Row center>
          <ResponsiveColumn desktopCol={7} mobileCol={10}>
            <LoadingAnimation
              header={`Nice work, ${name}! Hold tight while we identify your options.`}
              waveSpeed={0.5}
              checklist={animationSteps}
              onAnimationEnd={navigation.next}
            />
          </ResponsiveColumn>
        </Row>
      </Container>
    </WizardContent>
  );
};

const Container = styled.div`
  padding-top: 128px;

  @media (max-width: ${MobileSize}) {
    padding-top: 72px;
  }
`;

const mapStateToProps = state => ({ name: getUserFirstName(state) });

export default connect(mapStateToProps)(CalculationAnimation);
