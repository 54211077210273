import React from 'react';

import { FlexColumn } from '@summer/ui-components';

import Address from 'components/pages/pslf/wizard/steps/submit/YourContactInfo/fields/Address';
import Phone from 'components/pages/pslf/wizard/steps/submit/YourContactInfo/fields/Phone';
import { useFormDrawerFocus } from 'components/shared/FormDrawers';

const ContactInfo = ({ formik, show }) => {
  const formRef = React.useRef(null);
  useFormDrawerFocus(formRef, show);

  return (
    <FlexColumn ref={formRef}>
      <Address formik={formik} />
      <Phone formik={formik} />
    </FlexColumn>
  );
};

export default ContactInfo;
