import {
  CREATE_SPINWHEEL_TOKEN,
  CREATE_SPINWHEEL_USER,
  NETWORK_SUCCESS,
} from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import handleSideEffects from 'redux/handleSideEffects';

const spinwheelMiddleware = handleSideEffects({
  [CREATE_SPINWHEEL_TOKEN]: (dispatch, { onSuccess }) => {
    dispatch(
      apiRequest({
        url: '/v1/spinwheel/user/dim-token',
        method: 'POST',
        fromAction: CREATE_SPINWHEEL_TOKEN,
        label: 'createSpinwheelToken',
        meta: { onSuccess },
      }),
    );
  },

  [CREATE_SPINWHEEL_USER]: (dispatch, { onSuccess }) => {
    dispatch(
      apiRequest({
        url: '/v1/spinwheel/user',
        method: 'POST',
        fromAction: CREATE_SPINWHEEL_USER,
        label: 'createSpinwheelUser',
        meta: { onSuccess },
      }),
    );
  },

  [NETWORK_SUCCESS]: (dispatch, { payload, meta }) => {
    if (meta.fromAction === CREATE_SPINWHEEL_TOKEN) {
      meta.onSuccess(payload.response.data.dimToken);
    }

    if (meta.fromAction === CREATE_SPINWHEEL_USER) {
      meta.onSuccess();
    }
  },
});

export default spinwheelMiddleware;
