import { format, parseISO } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { DateInput } from '@summer/ui-components';

import StaticField from 'components/common/ManageLoans/fields/shared/StaticField';
import InfoTooltip from 'components/shared/InfoTooltip';
import { isLoanFieldEditable } from 'redux/selectors/loans.selectors';

const RecertificationDateLabel = () => (
  <>
    <span>Recertification Date</span>
    <InfoTooltip
      text="For income-driven repayment (IDR) plans such as PAYE, REPAYE, IBR, and ICR, you need to recertify your income every year before the Recertficiation Date. This requires sending in your proof of income to your loan servicer. If you do not recertify your income before this date, then your payment plan will change to the Standard Plan and your monthly payment may increase."
      width={300}
      top={2}
    />
  </>
);

class RecertificationDate extends React.Component {
  componentWillUnmount() {
    if (this.props.canEdit) {
      this.props.formik.setFieldValue('idrRecertificationDate', null);
    }
  }

  render() {
    const {
      formik: {
        values,
        errors,
        setFieldValue,
        handleBlur,
        touched,
        isSubmitting,
      },
      canEdit,
    } = this.props;

    return (
      <Container id="idrRecertificationDate">
        <InputContainer>
          {canEdit ? (
            <DateInput
              name="idrRecertificationDate"
              value={values.idrRecertificationDate}
              error={errors.idrRecertificationDate}
              touched={touched.idrRecertificationDate}
              onChange={setFieldValue}
              onBlur={handleBlur}
              isSubmitting={isSubmitting}
              label={<RecertificationDateLabel />}
              helpMessage={
                'This date is typically 1 year after you applied for an IDR plan. Enter an approximate date if you\u2019re unsure.'
              }
            />
          ) : (
            <StaticField
              label={<RecertificationDateLabel />}
              value={format(
                parseISO(values.idrRecertificationDate),
                'MM/dd/yyyy',
              )}
            />
          )}
        </InputContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  flex: 1;
`;

const InputContainer = styled.div`
  width: 100%;
`;

const mapStateToProps = (state, props) => {
  const loanId = props.formik.values.id;
  return {
    canEdit: isLoanFieldEditable(state, loanId, 'idrRecertificationDate'),
  };
};

export default connect(mapStateToProps)(RecertificationDate);
