/* eslint-disable no-nested-ternary */
import { isNil } from 'lodash';

import { SET_CURRENT_IDR_FORM } from 'constants/actionTypes';
import { addPrefixToKeys, getFields } from 'utils/object';
import { boolToString } from 'utils/toOptions';

export const initialIDRState = {
  spouseFirstName: '',
  spouseLastName: '',
  spouseEmail: '',
  spouseDob: '',
  servicersToSendIDR: [],
  multipleServicers: null,
  deferment: null,
  payFrequency: null,
  consentToIrsDisclosure: null,
};

export const employerInitialValues = {
  id: null,
  name: '',
  streetAddress1: '',
  city: '',
  state: '',
  zipcode: '',
};

export const referenceInitialValues = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  streetAddress1: '',
  city: '',
  state: '',
  zipcode: '',
  relationshipToUser: '',
};

export const initialEmployerState = addPrefixToKeys(
  employerInitialValues,
  'employer',
);

export const initialReferenceState = {
  ...addPrefixToKeys(referenceInitialValues, 'reference1'),
  ...addPrefixToKeys(referenceInitialValues, 'reference2'),
};

export const initialConsolidationState = {
  formerName: '',
  inGracePeriod: null,
  remainInGracePeriod: null,
  gracePeriodEndDate: '',
  targetServicerId: null,
  servedInMilitary: null,
};

export const initialState = {
  ...initialIDRState,
  ...initialConsolidationState,
  ...initialEmployerState,
  ...initialReferenceState,
};

const submitReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_IDR_FORM: {
      const {
        spouseFirstName,
        spouseLastName,
        spouseEmail,
        spouseDob,
        servicersToSendIDR,
        deferment,
        payFrequency,
        consentToIrsDisclosure,
        employer,
        reference1,
        reference2,
      } = payload;

      const {
        formerName,
        inGracePeriod,
        remainInGracePeriod,
        gracePeriodEndDate,
        targetServicerId,
        servedInMilitary,
      } = payload.consolidation || {};

      const employerFields = getFields(
        employer,
        employerInitialValues,
        'employer',
      );

      const reference1Fields = getFields(reference1, 'reference1');
      const reference2Fields = getFields(reference2, 'reference2');

      const consolidationFields = {
        formerName: formerName || initialState.formerName,
        inGracePeriod: !isNil(inGracePeriod)
          ? boolToString(inGracePeriod)
          : initialState.inGracePeriod,
        remainInGracePeriod: !isNil(remainInGracePeriod)
          ? boolToString(inGracePeriod)
          : initialState.remainInGracePeriod,
        gracePeriodEndDate:
          gracePeriodEndDate || initialState.gracePeriodEndDate,
        targetServicerId: targetServicerId || initialState.targetServicerId,
        servedInMilitary: !isNil(servedInMilitary)
          ? boolToString(servedInMilitary)
          : initialState.servedInMilitary,
      };

      const newState = {
        ...state,
        spouseFirstName,
        spouseLastName,
        spouseEmail,
        spouseDob,
        servicersToSendIDR,
        deferment,
        payFrequency,
        consentToIrsDisclosure: !isNil(consentToIrsDisclosure)
          ? boolToString(consentToIrsDisclosure)
          : initialState.consentToIrsDisclosure,
        ...consolidationFields,
        ...employerFields,
        ...reference1Fields,
        ...reference2Fields,
        // This field is not sent to server
        // Need to set to null here to control formik initial state
        keepMilitaryInterestCap: null,
      };

      return newState;
    }
    default:
      return state;
  }
};

export default submitReducer;
