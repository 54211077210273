import { includes } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import {
  Text,
  Icon,
  Checkmark,
  FlexColumn,
  FlexRow,
  COLORS,
} from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import {
  fsaCreateAccountLink,
  fsaSignInLink,
} from 'constants/externalLinks/fsa';
import { forgotPassword, forgotUsername } from 'constants/scraper';

const CheckmarkIcon = () => (
  <StyledIcon width={24} SvgComponent={Checkmark} stroke={COLORS.jade} />
);

export const connectFsaAccordionConfig = (isMobile, drawersToOpenByDefault) => [
  {
    title: 'How do I download my loan details?',
    content: (
      <DrawerContent>
        <TextContainer>
          <Text paragraph>
            <OrderedList>
              <li>
                <Link inheritColor underline track href={fsaSignInLink}>
                  Sign into your account at Federal Student Aid.
                </Link>
              </li>
              <li>
                In the upper right hand corner of the “My Aid” section, click on
                “View Details”.
              </li>
              <li>
                In the upper right hand corner of the “My Aid Summary” page,
                click on “Download My Aid Data” (If you&lsquo;re using a mobile
                device, rotate it horizontally to see this option.)
              </li>
            </OrderedList>
          </Text>
        </TextContainer>
        <TextContainer>
          <Text paragraph>
            After you click Continue to finish the download, a file called
            MyStudentData.txt will download to your computer. You can upload
            that file to the right.
          </Text>
        </TextContainer>
      </DrawerContent>
    ),
    openByDefault: includes(drawersToOpenByDefault, 4),
  },
  {
    title: 'What are the benefits of uploading my loan details?',
    content: (
      <DrawerContent>
        <Task>
          <CheckmarkIcon />
          <Text paragraph>
            Check your eligibility for federal loan forgiveness.
          </Text>
        </Task>
        <Task>
          <CheckmarkIcon />
          <Text paragraph>
            Lower your monthly payments with a customized repayment plan
            recommendation.
          </Text>
        </Task>
        <Task>
          <CheckmarkIcon />
          <Text paragraph>
            Get detailed individual loan assistance from Summer&lsquo;s expert
            team.
          </Text>
        </Task>
      </DrawerContent>
    ),
    openByDefault: includes(drawersToOpenByDefault, 1),
  },

  {
    title: `I don't remember my ${
      isMobile ? 'login' : 'FSA login credentials'
    }`,
    content: (
      <DrawerContent>
        <TextContainer>
          <Text paragraph>
            You set up this account when you first filled out your FAFSA and
            applied for federal loans.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text paragraph>
            If you&lsquo;re having trouble finding your login credentials, you
            can{' '}
            <Link inheritColor underline track href={forgotUsername}>
              retrieve your username
            </Link>{' '}
            or{' '}
            <Link inheritColor underline track href={forgotPassword}>
              reset your password
            </Link>
            .
          </Text>
        </TextContainer>
      </DrawerContent>
    ),
    openByDefault: includes(drawersToOpenByDefault, 2),
  },
  {
    title: 'I never had an account with FSA',
    content: (
      <DrawerContent>
        <TextContainer>
          <Text paragraph>
            If you haven&lsquo;t logged in to a U.S. Department of Education
            website since May 10, 2015 or if you&lsquo;ve never accessed your
            loans online, you probably don&lsquo;t have an FSA ID.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text paragraph>
            Try logging into your account first to check if you have an FSA ID.
            You can try your email address or mobile phone number instead of
            your username if you verified either one previously.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text paragraph>
            If you do not have an FSA ID, you can create one{' '}
            <Link inheritColor underline track href={fsaCreateAccountLink}>
              here
            </Link>
            .
          </Text>
        </TextContainer>
      </DrawerContent>
    ),
    openByDefault: includes(drawersToOpenByDefault, 3),
  },
];

const StyledIcon = styled(Icon)`
  margin-right: 16px;
`;

const DrawerContent = styled(FlexColumn)`
  text-align: left;
`;

const TextContainer = styled.div`
  margin: 8px 0;
`;

const Task = styled(FlexRow)`
  align-items: flex-start;
  width: 100%;
  margin: 12px 0;
`;

const OrderedList = styled.ol`
  margin: 0 1em;
  li {
    margin-bottom: 10px;
  }
`;
