import { map } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import {
  Button,
  Header,
  Text,
  Strong,
  SvgImage,
  COLORS,
} from '@summer/ui-components';

import Layout, {
  TextContainer,
} from 'components/pages/default/wizard/layout/layout';
import { getOutOfDefaultBulletsDefault } from 'constants/getOutOfDefault';
import { MobileSize } from 'constants/styleguide';
import { hexToRgba } from 'utils/common';

const LeftSection = ({ onClick }) => {
  return (
    <>
      <TextContainer>
        <PageHeader h2>
          When you have loans that are in default, it&rsquo;s important to get
          them back on track.
        </PageHeader>
        <Subtext paragraph>
          Summer will help with next steps and provide guidance on what to
          expect in this process.
        </Subtext>
      </TextContainer>
      <ContinueButton onClick={onClick}>Get started</ContinueButton>
    </>
  );
};

const BoxContent = () => {
  return (
    <>
      <BoxHeader>
        <Strong>Getting your loans out of default will:</Strong>
      </BoxHeader>
      <List>
        {map(getOutOfDefaultBulletsDefault, ({ image, text }) => (
          <ListItem key={text}>
            <ListIcon width={24} SvgComponent={image} />
            <Text paragraph>{text}</Text>
          </ListItem>
        ))}
      </List>
    </>
  );
};

const DefaultStartPage = ({ navigation }) => {
  return (
    <Layout
      leftSection={<LeftSection onClick={navigation.next} />}
      boxContent={<BoxContent />}
      boxColor={hexToRgba(COLORS.jade, 0.1)}
    />
  );
};

const PageHeader = styled(Header)`
  margin-bottom: 14px;

  @media (max-width: ${MobileSize}) {
    text-align: center;
  }
`;

const Subtext = styled(Text)`
  margin-bottom: 24px;
`;

const BoxHeader = styled(Text)`
  margin-bottom: 13px;
`;

const List = styled.ul`
  list-style-type: none;
`;

const ListIcon = styled(SvgImage)`
  margin-right: 10px;
  margin-bottom: auto;
`;

const ListItem = styled.li`
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;

  ${ListIcon} {
    margin-top: 4px;
  }
`;

const ContinueButton = styled(Button)`
  width: 100%;
  @media (min-width: ${MobileSize}) {
    max-width: 360px;
  }
`;

export default DefaultStartPage;
