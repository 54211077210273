import {
  CREATE_STRIPE_CHECKOUT,
  UPDATE_STRIPE_STATUS,
  SET_HAS_PAYMENT_SAVED,
} from 'constants/actionTypes';

export const createStripeCheckoutSession = payload => ({
  type: CREATE_STRIPE_CHECKOUT,
  payload,
});

export const updateStripeMembership = sessionId => ({
  type: UPDATE_STRIPE_STATUS,
  payload: {
    sessionId,
  },
});

export const setHasPaymentSaved = hasPaymentSaved => ({
  type: SET_HAS_PAYMENT_SAVED,
  payload: {
    hasPaymentSaved,
  },
});
