import React from 'react';
import styled from 'styled-components';

import { Header, Text, Row } from '@summer/ui-components';

import {
  DefaultResolutionGroupText,
  DefaultResolutionGroupContactInfo,
  BlueBox,
} from 'components/pages/default/wizard/layout/DefaultDrawerContent';
import RecommendedBadge from 'components/shared/RecommendedBadge';
import { MobileSize } from 'constants/styleguide';

import {
  Middle,
  BlueBoxContents as SettlementBlueBoxContents,
} from './SettlementV1';

export const LoanSettlementRecommendation = () => (
  <>
    <ContentRow mobileCenter desktopStart>
      <RecommendedBadge />
    </ContentRow>
    <ContentRow mobileCenter desktopStart>
      <Header h2 data-testid="default-rec-header">
        Pay Off Your Loans
      </Header>
    </ContentRow>
    <Description paragraph>
      <Middle />
    </Description>
    <TextContent>
      <DefaultResolutionGroupText optionName="settlement" />
    </TextContent>
    <DefaultResolutionGroupContactInfo />
    <BlueBox>
      <SettlementBlueBoxContents />
    </BlueBox>
  </>
);

const ContentRow = styled(Row)`
  margin: 0;
`;

const Description = styled(Text)`
  margin-top: 16px;

  @media (max-width: ${MobileSize}) {
    margin: 8px 8px 0;
  }
`;

const TextContent = styled.div`
  @media (max-width: ${MobileSize}) {
    margin: 0 8px;
  }
`;
