import React from 'react';
import styled from 'styled-components';

import {
  Text,
  Strong,
  FlexColumn,
  MoneyInput,
  PercentageInput,
  Button,
  Alert,
} from '@summer/ui-components';

import InfoTooltip from 'components/shared/InfoTooltip';
import { MONTHLY_PAYMENT_TOO_LOW_MESSAGE } from 'constants/overpayments';
import { MobileSize } from 'constants/styleguide';

const InputLabel = ({ title, text }) => (
  <>
    <InputTitle>{title}</InputTitle>
    <InfoTooltip text={text} top={2} />
  </>
);

const getError = (error, submitCount) => {
  return error && submitCount > 0 ? error : null;
};

const YourLoansInputs = ({ formik }) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    isSubmitting,
    isValid,
    submitCount,
  } = formik;

  return (
    <>
      <InputContainer>
        <BalanceAndIntRateContainer>
          <CurrentBalanceWrapper>
            <MoneyInput
              name="currentBalance"
              value={values.currentBalance}
              error={getError(errors.currentBalance, submitCount)}
              touched={touched.currentBalance}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              onBlur={handleBlur}
              isSubmitting={isSubmitting}
              label={
                <InputLabel
                  title="Current balance"
                  text="Enter the total amount that you owe as of today. It&rsquo;s ok to estimate."
                />
              }
            />
          </CurrentBalanceWrapper>
          <InterestRateWrapper>
            <PercentageInput
              name="interestRate"
              value={values.interestRate}
              error={getError(errors.interestRate, submitCount)}
              touched={touched.interestRate}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              onBlur={handleBlur}
              isSubmitting={isSubmitting}
              label={
                <InputLabel
                  title="Interest Rate (APR)"
                  text="The interest rate you&rsquo;re currently paying on your loan. If you&rsquo;re not sure, feel free to estimate or use a basic average interest rate of 5%."
                />
              }
            />
          </InterestRateWrapper>
        </BalanceAndIntRateContainer>
        <MonthlyPaymentContainer>
          <MoneyInput
            name="currentMonthlyPayment"
            value={values.currentMonthlyPayment}
            error={
              errors.interestRate &&
              errors.currentMonthlyPayment === MONTHLY_PAYMENT_TOO_LOW_MESSAGE
                ? null
                : getError(errors.currentMonthlyPayment, submitCount)
            }
            touched={touched.currentMonthlyPayment}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            onBlur={handleBlur}
            isSubmitting={isSubmitting}
            label={
              <InputLabel
                title="Current monthly payment"
                text="The amount you pay towards your loan every month, even if it&rsquo;s more than the minimum. There is no need to be exact, but your results will be more accurate the closer the number is to your actual monthly payment."
              />
            }
          />
        </MonthlyPaymentContainer>
      </InputContainer>
      <Button width={280} disabled={!isValid && submitCount > 0} type="submit">
        Calculate
      </Button>
    </>
  );
};

const YourLoans = ({ formik }) => (
  <Container>
    <TextContainer>
      <Text paragraph>
        <BoldTextContainer>
          <Strong>
            Enter your loan information to see your potential savings and payoff
            scenarios.
          </Strong>{' '}
        </BoldTextContainer>
        If you have multiple student loans, enter your total current loan
        balance to get the most accurate savings projections.
      </Text>
    </TextContainer>
    <YourLoansInputs formik={formik} />
    {formik.errors?.currentMonthlyPayment === MONTHLY_PAYMENT_TOO_LOW_MESSAGE &&
      !formik.errors?.interestRate &&
      formik.submitCount > 0 && (
        <AlertContainer>
          <Alert theme="info" dismissable={false}>
            It seems that your payments do not cover the interest on your loan.
          </Alert>
        </AlertContainer>
      )}
  </Container>
);

const InputTitle = styled.span`
  font-size: 14px;
`;

const Container = styled.div`
  padding: 24px 40px 40px;
  @media (max-width: ${MobileSize}) {
    padding: 8px 24px 24px;
  }
`;

const TextContainer = styled.div`
  margin-bottom: 40px;

  @media (max-width: ${MobileSize}) {
    margin-bottom: 24px;
  }
`;

const InputContainer = styled(FlexColumn)`
  margin-bottom: 24px;

  @media (max-width: ${MobileSize}) {
    margin-bottom: 8px;
  }
`;

const BoldTextContainer = styled.div`
  margin-bottom: 0px;
  @media (max-width: ${MobileSize}) {
    margin-bottom: 16px;
  }
`;

const BalanceAndIntRateContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${MobileSize}) {
    flex-direction: column;
  }
`;

const CurrentBalanceWrapper = styled.div`
  width: 280px;
  margin-right: 40px;
  @media (max-width: ${MobileSize}) {
    width: 100%;
    margin-right: 0;
  }
`;

const InterestRateWrapper = styled(FlexColumn)`
  width: 280px;
  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

const MonthlyPaymentContainer = styled.div`
  width: 280px;
  max-width: calc(50% - 20px);
  @media (max-width: ${MobileSize}) {
    width: 100%;
    max-width: 100%;
  }
`;

const AlertContainer = styled.div`
  margin-top: 32px;
`;

export default YourLoans;
