import { push as redirect } from 'connected-react-router';
import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {
  FlexCenter,
  FlexColumn as RegularFlexColumn,
  Icon,
  Checkmark,
  SwimmingPool,
  Button,
  Header,
  Text,
  COLORS,
} from '@summer/ui-components';

import CreateAccountForm from 'components/pages/onboarding/CreateAccountForm';
import Marketing from 'components/pages/onboarding/marketing';
import { Disclaimer } from 'components/pages/onboarding/shared';
import OAuthButtons from 'components/shared/OAuthButtons';
import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';
import { verify } from 'constants/onboardingSteps';
import { TabletSize, MobileSize } from 'constants/styleguide';
import { resetState } from 'redux/actions/resetAction.actions';
import { createAccount } from 'redux/actions/user.actions';
import { isLoading } from 'redux/selectors/ui.selectors';
import { createAccountValidationSchema } from 'schemas/onboard';
import { scrollToError } from 'utils/formik';

const CheckmarkIcon = () => (
  <Check SvgComponent={Checkmark} stroke={COLORS.jade} />
);

class CreateAccount extends React.Component {
  componentDidMount() {
    // Preserve checkUser loading state from App to prevent infinite loading animation
    this.props.resetState({ preserve: 'ui.loading.checkUser' });
  }

  handleSubmit = values => {
    const { email, password, firstName, lastName } = values;

    this.props.createAccount({
      firstName,
      lastName,
      email,
      password,
      currentStep: verify,
    });
  };

  renderForm = formik => {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      isValid,
    } = formik;

    return (
      <Form onSubmit={handleSubmit}>
        <CreateAccountContainer>
          <Container>
            <OAuthButtons
              type="create"
              oauthProviders={['google', 'facebook']}
            />
            <CreateAccountForm
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              isSubmitting={isSubmitting}
              handleBlur={handleBlur}
              emailHelpMessage="Use your personal email in case you change jobs or leave school."
            />
          </Container>
          <InfoContainer>
            <InfoHeaderContainer>
              <Header h5>Account benefits:</Header>
            </InfoHeaderContainer>
            <FlexColumn>
              <InfoItem>
                <CheckmarkIcon />
                <Text paragraph>Track your loans</Text>
              </InfoItem>
              <InfoItem>
                <CheckmarkIcon />
                <Text paragraph>Compare repayment plans</Text>
              </InfoItem>
              <InfoItem>
                <CheckmarkIcon />
                <Text paragraph>Enroll in forgiveness programs</Text>
              </InfoItem>
              <InfoItem>
                <CheckmarkIcon />
                <Text paragraph>Access guides and resources</Text>
              </InfoItem>
              <InfoItem>
                <CheckmarkIcon />
                <Text paragraph>Connect with student loan experts</Text>
              </InfoItem>
            </FlexColumn>
            <Divider />
            <InfoHeaderContainer>
              <Header h5>Premium Access</Header>
            </InfoHeaderContainer>
            <Text paragraph>
              Borrowers from our Premium partners get access to additional
              support and resources. If you heard about Summer from your union,
              nonprofit, employer or financial institution, email us at{' '}
              <EmailLink
                href={`mailto:${supportAddress}`}
                inheritColor
                underline
              >
                {supportAddress}
              </EmailLink>{' '}
              to unlock your premium account.
            </Text>
          </InfoContainer>
        </CreateAccountContainer>
        <ButtonContainer>
          <Container>
            <Button
              fakeDisabled={!isValid}
              isLoading={this.props.isLoading}
              width={436}
              type="submit"
              onClick={() => scrollToError(errors)}
            >
              Create my free account
            </Button>
          </Container>
          <Container>
            <Button
              width={360}
              type="button"
              onClick={() =>
                this.props.redirect('/login', {
                  origin: this.props.location.state?.origin,
                })
              }
              secondary
            >
              Already a member? Sign in
            </Button>
          </Container>
        </ButtonContainer>
      </Form>
    );
  };

  render() {
    return (
      <FlexColumn>
        <CreateAccountContainer>
          <Container>
            <PoolIcon SvgComponent={SwimmingPool} stroke={COLORS.azure} />
            <HeaderContainer>
              <Header center>
                Let&rsquo;s start by creating your account.
              </Header>
            </HeaderContainer>
          </Container>
        </CreateAccountContainer>
        <Formik
          initialValues={{
            email: '',
            password: '',
            firstName: '',
            lastName: '',
          }}
          validationSchema={createAccountValidationSchema()}
          onSubmit={this.handleSubmit}
        >
          {this.renderForm}
        </Formik>
        <CreateAccountContainer>
          <LegalContainer>
            <Disclaimer />
          </LegalContainer>
        </CreateAccountContainer>
        <Marketing />
      </FlexColumn>
    );
  }
}

/**
 * In order to support ie11 fully on this page, there are several instances
 * where style rules may seem redundant. Be sure to test on ie11 when making
 * changes to this component. Some of the ie11 specific fixes:
 *
 * - setting flex to 1 0 auto everywhere
 * - setting width explicitly everywhere (max-width is not enough)
 * - setting width/height of svg explicitly (otherwise they will be unbounded)
 */
const FlexColumn = styled(RegularFlexColumn)`
  flex: 1 0 auto;
  width: 100%;
`;

const CreateAccountContainer = styled(FlexCenter)`
  flex: 1 0 auto;
  margin: 0;
  width: 100%;
  padding: 0 24px;
  align-items: unset;
  margin-bottom: 16px;
  max-height: max-content;

  @media (max-width: ${TabletSize}) {
    align-items: center;
    flex-direction: column-reverse;
  }
`;

const Container = styled(FlexCenter)`
  max-width: 436px;
  width: 100%;
  align-self: flex-start;

  flex-direction: column;

  &:first-child {
    margin-right: 12px;
  }

  &:last-child {
    margin-left: 12px;
  }

  @media (max-width: ${TabletSize}) {
    align-self: center;

    &:first-child {
      margin-right: 0;
    }

    &:last-child {
      margin-left: 0;
    }
  }
`;

const InfoContainer = styled(FlexColumn)`
  max-width: 360px;
  width: 100%;
  background-color: ${COLORS.lightestGrey};
  border: 1px ${COLORS.grey} solid;
  border-radius: 3px;
  padding: 36px 48px;
  justify-content: flex-start;
  align-self: stretch;
  margin: 0px 0px 40px 12px;

  @media (max-width: ${TabletSize}) {
    max-width: 436px;
    align-self: center;
    margin: 0px 0px 24px;
    padding: 24px;
    height: unset;
  }
`;

const Divider = styled.div`
  width: 100%;
  margin: 8px 0px 24px;
  border-bottom: 1px solid ${COLORS.grey};
`;

const ButtonContainer = styled(CreateAccountContainer)`
  & > * {
    margin-bottom: 8px;

    button {
      width: 100%;
    }

    &:first-child {
      max-width: 436px;
    }

    &:last-child {
      max-width: 360px;

      @media (max-width: ${TabletSize}) {
        max-width: 436px;
      }
    }
  }

  @media (max-width: ${TabletSize}) {
    flex-direction: column;
  }
`;

const PoolIcon = styled(Icon)`
  width: 53px;
  height: 46px;
  margin-bottom: 24px;
`;

const LegalContainer = styled(Container)`
  max-width: 720px;
  margin-bottom: 72px;

  & > * {
    width: 100%;
  }

  @media (max-width: ${TabletSize}) {
    max-width: 436px;
  }
`;

const Check = styled(Icon)`
  margin-right: 24px;
  height: 16px;
  max-width: 16px;
  width: 16px;
  display: inline;
  flex: 1 0 auto;
`;

const InfoItem = styled.div`
  display: flex;
  margin-bottom: 16px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const InfoHeaderContainer = styled.div`
  margin-bottom: 8px;
  max-width: 100%;
`;

const EmailLink = styled(Link)`
  @media (max-width: ${MobileSize}) {
    word-wrap: break-word;
  }
`;

const Form = styled.form``;

const mapStateToProps = state => ({
  isLoading: isLoading(state, 'createAccount'),
});

export default withRouter(
  connect(mapStateToProps, {
    createAccount,
    resetState,
    redirect,
  })(CreateAccount),
);
