import { SHOW_TOAST, REMOVE_TOAST } from 'constants/actionTypes';

export const showToast = ({ id, canClose, bgColor }) => ({
  type: SHOW_TOAST,
  payload: {
    id,
    canClose,
    bgColor,
  },
});

export const removeToast = ({ id }) => ({
  type: REMOVE_TOAST,
  payload: {
    id,
  },
});
