import React from 'react';
import styled from 'styled-components';

import { FlexRow } from '@summer/ui-components';

import { MobileSize } from 'constants/styleguide';

const Section = ({ children, noBorder, flexDirection }) => (
  <Question>
    <FormSection noBorder={noBorder} flexDirection={flexDirection}>
      {children}
    </FormSection>
  </Question>
);

const FormSection = styled(FlexRow)`
  & > * {
    flex: 1;
    margin-right: 48px;
  }

  & > *:last-child {
    margin-right: 0;
  }

  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
    & > * {
      margin-right: 0;
    }
  }
`;

const Question = styled.div`
  align-items: center;
  margin-bottom: 12px;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
    width: 100%;
  }
`;

export default Section;
