import React from 'react';
import styled from 'styled-components';

import { Text, Accordion, COLORS } from '@summer/ui-components';

import SubmitToLoanServicer from 'components/shared/SubmitToLoanServicer';
import Link from 'components/shared/typography/Link';
import {
  fsaAnnouncementIdrAdjustmentLink,
  fsaAnnouncementPslfWaiverLink,
  fsaConsolidationToolLink,
  fsaIdrFormLink,
  fsaIdrToolLink,
  fsaServicersLink,
} from 'constants/externalLinks/fsa';

import { LearnMoreContainer } from './shared';

const getIdrOrConsolidationCopy = renderConsolidation => {
  if (renderConsolidation) {
    return (
      <BodyText paragraph>
        <Text color="dark" paragraph>
          You can submit consolidation and IDR forms directly to your loan
          servicer! Here’s how:
        </Text>
        <br />
        <OrderedList>
          <li>
            To consolidate your loans and enroll in IDR, you can download the{' '}
            <Link
              inheritColor
              inheritWeight
              underline
              href="/guides/Consolidation_Form_Guide_vJul2022.pdf"
            >
              consolidation form
            </Link>{' '}
            and the{' '}
            <Link inheritColor inheritWeight underline href={fsaIdrFormLink}>
              IDR form
            </Link>{' '}
            to fill out and send to your servicer, or{' '}
            <Link
              inheritColor
              inheritWeight
              underline
              href={fsaConsolidationToolLink}
            >
              use Federal Student Aid’s online consolidation tool.
            </Link>
          </li>
          <li>
            It’s important to think about which loans should be consolidated.
            <OrderedList type="a">
              <li>
                If you have Parent PLUS loans, you may want to consolidate those
                separately from other types since they will only be eligible for
                ICR, which has a higher monthly payment than the other IDR
                plans.
              </li>
              <li>
                Temporarily, consolidating will not reset the timeline toward
                forgiveness because of the waivers and adjustments to{' '}
                <Link
                  inheritColor
                  inheritWeight
                  underline
                  href={fsaAnnouncementIdrAdjustmentLink}
                >
                  IDR
                </Link>{' '}
                and{' '}
                <Link
                  inheritColor
                  inheritWeight
                  underline
                  href={fsaAnnouncementPslfWaiverLink}
                >
                  Public Service Loan Forgiveness (PSLF)
                </Link>
                , but later this year, those provisions will expire.
              </li>
              <li>
                Typically, there isn’t a reason to consolidate Direct loans
                unless you are working toward Public Service Loan Forgiveness
                and are looking to combine loans on multiple timelines under the{' '}
                <Link
                  inheritColor
                  inheritWeight
                  underline
                  href={fsaAnnouncementPslfWaiverLink}
                >
                  PSLF Limited Waiver.
                </Link>
              </li>
            </OrderedList>
          </li>
          <li>
            You’ll have the option to select a repayment plan as part of this
            process. To enroll in an IDR plan, you’ll need to choose one of the
            following options: Pay As You Earn (PAYE), Save On A Valuable
            Education (SAVE), Income-Based Repayment (IBR), or Income Contingent
            Repayment (ICR).
          </li>
          <li>
            You can either submit through the online tool, or{' '}
            <Link inheritColor inheritWeight underline href={fsaServicersLink}>
              send a PDF or hard copy to your loan servicer.
            </Link>{' '}
            You will have the option to select a new loan servicer as part of
            this process, or to stay with your current loan servicer.
          </li>
        </OrderedList>
      </BodyText>
    );
  }

  return (
    <BodyText paragraph>
      <Text color="dark" paragraph>
        You can submit IDR forms directly to your loan servicer! Here’s how:
      </Text>
      <br />
      <OrderedList>
        <li>
          To enroll in IDR, switch IDR plans, or recertify or recalculate your
          current plan, you can{' '}
          <Link
            inheritColor
            inheritWeight
            underline
            href="/guides/Idr_Form_Guide_vAug2021.pdf"
          >
            download the form from Federal Student Aid
          </Link>
          , or{' '}
          <Link inheritColor inheritWeight underline href={fsaIdrToolLink}>
            use their IDR tool.
          </Link>
        </li>
        <li>
          You’ll answer similar questions about your financial situation, and
          may need to provide proof of your income. Depending on your situation,
          this could be a tax return or a pay stub.
        </li>
        <li>
          You can either choose a specific IDR plan or choose to be put in the
          plan with the lowest monthly payment.
        </li>
        <li>
          <SubmitToLoanServicer />
        </li>
      </OrderedList>
    </BodyText>
  );
};

const learnMoreIDRAccordianConfig = [
  {
    title: 'What happens next?',
    content: (
      <Text paragraph>
        In approximately four to eight weeks, you’ll hear back from your loan
        servicer with your updated monthly payment and repayment plan. You’ll
        need to recertify your income every year to remain in the plan.
      </Text>
    ),
  },
  {
    title: 'What if something doesn’t look right?',
    content: (
      <Text paragraph>
        If you think there’s been a mistake in calculating your monthly payment,
        contact your loan servicer for assistance.
      </Text>
    ),
  },
];

const learnMoreIDRConsolidationAccordianConfig = [
  {
    title: 'What happens next?',
    content: (
      <Text paragraph>
        In approximately four to eight weeks, you’ll hear back from your loan
        servicer with your updated monthly payment and IDR plan. You’ll need to
        recertify your income every year to remain in the plan.
      </Text>
    ),
  },
  {
    title: 'What if something doesn’t look right?',
    content: (
      <Text paragraph>
        If you think there’s been a mistake in your consolidation or in
        calculating your monthly payment, contact your loan servicer for
        assistance.
      </Text>
    ),
  },
];

const LearnMoreIdr = props => {
  return (
    <LearnMoreContainer close={props.close}>
      <CopyText>
        {getIdrOrConsolidationCopy(props.renderConsolidation)}
      </CopyText>
      <Accordion
        config={
          props.renderConsolidation === 'true'
            ? learnMoreIDRConsolidationAccordianConfig
            : learnMoreIDRAccordianConfig
        }
      ></Accordion>
    </LearnMoreContainer>
  );
};

const CopyText = styled.div`
  max-width: 724px;
  margin-left: auto;
  margin-right: auto;
`;

const BodyText = styled(Text)`
  font-family: AvenirNext-DemiBold, Avenir, sans-serif;
  color: ${COLORS.blueBlack};
`;

const OrderedList = styled.ol`
  margin: 0 1em;
  li {
    margin-bottom: 10px;
  }
`;

export default LearnMoreIdr;
