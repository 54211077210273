import _, { filter } from 'lodash';
import fp from 'lodash/fp';
import { createSelector } from 'reselect';

import { FormTypes } from '@simplifidev/shared/dist/constants/forms';

export const getSignatureRequests = state => state.signatureRequests;

export const getPslfSignRequests = createSelector(
  getSignatureRequests,
  signatureRequests => filter(signatureRequests, { formType: FormTypes.pslf }),
);

export const getCompletedPslfSignRequests = createSelector(
  getPslfSignRequests,
  fp.filter(
    _.flow(
      fp.get('signers'),
      fp.find({ role: 'borrower' }),
      fp.get('complete'),
    ),
  ),
);
