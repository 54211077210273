import { object, number, string } from 'yup';

export const collegeSavingsPlannerSchema = object().shape({
  yearlyTuition: number().nullable().required('Please enter a number').min(0),
  startYear: number()
    .nullable()
    .required('Please enter a year')
    .min(new Date().getFullYear() + 1, 'Please enter a date in the future'),
  attendanceDuration: number()
    .nullable()
    .required('Please enter a number')
    .min(1, 'Please enter a number greater than zero'),
  costIncrease: number()
    .nullable()
    .required('Please enter a percentage')
    .min(0),
  currentSavings: number().nullable().required('Please enter a number').min(0),
  plannedContribution: number()
    .nullable()
    .required('Please enter a number')
    .min(0),
  rateOfReturn: number()
    .nullable()
    .required('Please enter a percentage')
    .min(0),
  expectedAid: number().nullable().required('Please enter a number').min(0),
  collegeCostMethod: string().nullable().required('Please make a selection'),
  expectedAidMethod: string().nullable().required('Please make a selection'),
});
