import { format, parseISO } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { DateInput } from '@summer/ui-components';

import StaticField from 'components/common/ManageLoans/fields/shared/StaticField';
import Placeholder from 'components/common/ManageLoans/shared/Placeholder';
import Section from 'components/common/ManageLoans/shared/Section';
import InfoTooltip from 'components/shared/InfoTooltip';
import { Link } from 'components/shared/typography';
import { loanPayoffDateLabelLink } from 'constants/externalLinks/fsa';
import { isLoanFieldEditable } from 'redux/selectors/loans.selectors';

const LoanPayoffDateLabel = () => (
  <>
    <span>When did you pay off this loan?</span>
    <InfoTooltip
      text={
        <>
          Enter the date that you made the last payment on this loan. If this
          loan was consolidated, then enter the date that the loan was
          consolidated. You can find this information at{' '}
          <Link href={loanPayoffDateLabelLink}>Federal Student Aid</Link> under
          the loan details of &ldquo;Inactive Loans&rdquo;. Look for the
          &ldquo;Status Date&rdquo; for the &ldquo;Paid in Full
          Consolidated&rdquo; status.
        </>
      }
      width={300}
      size={14}
    />
  </>
);

class LoanPayoffDate extends React.Component {
  componentWillUnmount() {
    if (this.props.canEdit) {
      this.props.formik.setFieldValue('loanPayoffDate', null);
    }
  }

  render() {
    const {
      formik: {
        values,
        errors,
        setFieldValue,
        handleBlur,
        touched,
        isSubmitting,
      },
      canEdit,
    } = this.props;

    return (
      <Section noBorder>
        <Container id="loanPayoffDate">
          <InputContainer>
            {canEdit ? (
              <DateInput
                name="loanPayoffDate"
                value={values.loanPayoffDate}
                error={errors.loanPayoffDate}
                touched={touched.loanPayoffDate}
                onChange={setFieldValue}
                onBlur={handleBlur}
                isSubmitting={isSubmitting}
                label={<LoanPayoffDateLabel />}
              />
            ) : (
              <StaticField
                label={<LoanPayoffDateLabel />}
                value={format(parseISO(values.loanPayoffDate), 'MM/dd/yyyy')}
              />
            )}
          </InputContainer>
        </Container>
        <Placeholder />
      </Section>
    );
  }
}

const Container = styled.div`
  flex: 1;
`;

const InputContainer = styled.div`
  width: 100%;
`;

const mapStateToProps = (state, props) => {
  const loanId = props.formik.values.id;
  return { canEdit: isLoanFieldEditable(state, loanId, 'loanPayoffDate') };
};

export default connect(mapStateToProps)(LoanPayoffDate);
