import React from 'react';

import { Link } from 'components/shared/typography';

export const xPaddingDesktop = '48px';
export const xPaddingMobile = '24px';
export const modalMargin = '16px';

export const programTypes = {
  idr: 'idr',
  pslf: 'pslf',
};

export const loanTypeTooltipText =
  'Federal loan types include names like Direct Stafford Subsidized, FFEL Stafford Unsubsidized, and Perkins. The type of federal loan you have depends on when you took out the loan and your situation. If you are/were a student that is paying back a Parent PLUS loan, do not include it as your loan because those loans technically belong to your parent.';

export const pslfLoanTypeTooltipText = (
  <>
    The type of federal loan you have depends on when you took out the loan and
    your situation. Typically, only Direct loans are eligible for Public Service
    Loan Forgiveness. As part of the Limited PSLF Waiver and the IDR Adjustment,
    previous payments on FFEL and Perkins loans are also eligible if they are
    consolidated into Direct loans.{' '}
    <Link href="https://www.meetsummer.com/post/department-of-education-announces-pslf-and-idr-adjustment-updates">
      Learn more
    </Link>
  </>
);

export const pslfEligibleTooltipText = (
  <>
    This column shows which of your loan types are currently eligible for PSLF.
    In addition to an eligible loan type, your employment and repayment status
    will also need to qualify for PSLF.
  </>
);

export const trackerQualifyingPaymentsTooltipText = (
  <>
    This shows the number of qualifying payments toward Public Service Loan
    Forgiveness that have already been approved by the Department of Education.
    This number will only update once an employment certification form is
    submitted and accepted.
  </>
);
