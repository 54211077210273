import { get, has } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import {
  CELL_TYPES,
  CardTable,
  Cell,
  FlexColumn,
  FlexRow,
  NumbersPagination,
  Text,
} from '@summer/ui-components/src';

import { MobileSize } from 'constants/styleguide';
import { useClientSidePagination } from 'hooks/pagination';
import { usePlatform } from 'hooks/platform';
import { dollars } from 'utils/numbers';

// TODO [sc-38199]: Create mobile version once design is ready
const TransactionsTable = ({
  transactions,
  limit = 10,
  hidePagination = false,
}) => {
  const { isMobile } = usePlatform();

  const formattedData = formatTransactions(transactions);
  const numEntries = transactions.length;

  const {
    dataToDisplay,
    overPageLimit,
    numPages,
    currentLower,
    currentUpper,
    currentPageIndex,
    setActiveIndex,
  } = useClientSidePagination({ data: formattedData, limit });

  const showPagination = overPageLimit && !hidePagination;

  return (
    <Container>
      <CardTable
        columns={[
          {
            Header: DatePaidHeader,
            Cell: DatePaidCell,
            accessor: 'Date of payment',
            show: true,
            width: isMobile ? 250 : 200,
          },
          {
            Cell: AmountPaidCell,
            Header: AmountPaidHeader,
            accessor: 'Amount Paid',
            show: true,
            width: isMobile ? 150 : 200,
          },
        ]}
        data={dataToDisplay}
      />
      <BottomContainer>
        <Text>
          Showing {currentLower} &ndash; {currentUpper} of {numEntries} Payments
        </Text>

        {showPagination && (
          <NumbersPagination
            total={numPages}
            activeIndex={currentPageIndex}
            onActiveIndexChange={setActiveIndex}
          />
        )}
      </BottomContainer>
    </Container>
  );
};

// TODO [sc-38196]: Add Tooltip Copy
const AmountPaidHeader = () => <AmountPaid>Amount Paid</AmountPaid>;

const AmountPaidCell = ({ cell, row }) => {
  return (
    <AmountCellWrapper>
      <Cell
        type={CELL_TYPES.MONEY}
        valid={validateCell('Amount Paid', row)}
        value={cell.value}
      />
    </AmountCellWrapper>
  );
};

// TODO [sc-38196]: Add Tooltip Copy
const DatePaidHeader = () => <DatePaid>Date of payment</DatePaid>;

const DatePaidCell = ({ cell, row }) => {
  return (
    <DateCellWrapper>
      <Cell
        type={CELL_TYPES.DATE}
        valid={validateCell('Date of payment', row)}
        value={cell.value}
        dateFormat="MMMM dd, yyyy"
      />
    </DateCellWrapper>
  );
};

const validateCell = (accessor, row) => {
  const errors = get(row, 'original.errors');
  return !has(errors, accessor);
};

const formatTransactions = transactions => {
  return transactions ? transactions.map(formatTransaction) : [];
};

const formatTransaction = transaction => {
  const { date, amount } = transaction;
  return {
    'Date of payment': date,
    'Amount Paid': dollars(amount),
  };
};

const Container = styled(FlexColumn)`
  width: 100%;
`;

const BottomContainer = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  padding: 0 10px;

  .text {
    margin-right: 8px;
  }

  @media (max-width: ${MobileSize}) {
    flex-direction: column-reverse;

    .text {
      margin-top: 16px;
      margin-right: 0;
    }
  }
`;

const AmountPaid = styled.div`
  padding-right: 130px;
  font-size: 14px;

  @media (max-width: ${MobileSize}) {
    padding-right: 10px;
  }
`;

const DatePaid = styled.div`
  padding-left: 20px;
  font-size: 14px;

  @media (max-width: ${MobileSize}) {
    padding-left: 0px;
  }
`;

const DateCellWrapper = styled.div`
  padding-left: 20px;

  @media (max-width: ${MobileSize}) {
    padding-left: 0px;
  }
`;

const AmountCellWrapper = styled.div`
  padding-right: 130px;

  @media (max-width: ${MobileSize}) {
    padding-right: 10px;
  }
`;

export default TransactionsTable;
