import { set, flow } from 'lodash/fp';

import {
  SET_LOGOUT,
  SET_NEXT_STEP,
  SET_USER,
  SET_PAGE_INFO,
  SET_POST_ONBOARDING_DESTINATION,
} from 'constants/actionTypes';

const initialState = {
  currentStep: null,
};

const onboardingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER: {
      return set('currentStep', payload.user.currentStep, state);
    }

    case SET_NEXT_STEP: {
      return set('currentStep', payload.currentStep, state);
    }

    case SET_PAGE_INFO:
      return flow([
        set('stepCount', payload.stepCount),
        set('stepMessage', payload.stepMessage),
      ])(state);

    case SET_LOGOUT: {
      return initialState;
    }

    case SET_POST_ONBOARDING_DESTINATION: {
      return set(
        'postOnboardingDestination',
        payload.postOnboardingDestination,
        state,
      );
    }

    default:
      return state;
  }
};

export default onboardingReducer;
