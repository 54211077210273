import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { SelectButton, Trash, COLORS } from '@summer/ui-components';

import SyncButton from 'components/common/SyncLoans/Buttons/SyncButton';
import DisableSync from 'components/common/SyncLoans/DisableSync';
import { MobileSize } from 'constants/styleguide';
import { openConnectFsa } from 'redux/actions/ui.actions';
import { hasSyncedLoans } from 'redux/selectors/sync.selectors';

const ManageLoansButton = ({ hasSyncedLoans, dispatch }) => {
  const [disableSyncIsOpen, setDisableSyncIsOpen] = useState(false);
  const toggleDisableSync = () => setDisableSyncIsOpen(!disableSyncIsOpen);
  const openFsa = () => dispatch(openConnectFsa());

  if (hasSyncedLoans) {
    return (
      <>
        <DisableSync
          togglePopup={toggleDisableSync}
          isPopupOpen={disableSyncIsOpen}
        />
        <SelectButton
          label="Manage your loans"
          selectOptions={[
            {
              label: 'Delete synced loans',
              textColor: 'error',
              icon: Trash,
              iconColor: COLORS.orange,
              handleClick: toggleDisableSync,
            },
          ]}
        />
      </>
    );
  }

  return (
    <InlineContainer>
      <SyncButton onClick={openFsa} />
    </InlineContainer>
  );
};

const InlineContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
    width: 100%;

    padding: 0 56px;

    & > * {
      margin-top: 24px;
    }
  }
`;

const mapStateToProps = state => ({
  hasSyncedLoans: hasSyncedLoans(state),
});

export default connect(mapStateToProps)(ManageLoansButton);
