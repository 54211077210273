import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Header } from '@summer/ui-components';

import { MobileSize } from 'constants/styleguide';
import { getUserFirstName } from 'redux/selectors/profile.selectors';

const MyPlanHeader = ({
  name,
  hasCurrentOrPendingPlans,
  hasRecommendations,
}) => {
  let headerText;

  if (hasCurrentOrPendingPlans) {
    headerText = `Welcome back, ${name}!`;
  } else if (hasRecommendations) {
    headerText = `Hi, ${name}! Review your options below`;
  } else {
    headerText = `Hi, ${name}!`;
  }

  return (
    <Container>
      <HeaderText>{headerText}</HeaderText>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 16px;
  padding-bottom: 120px;
  position: relative;

  @media (max-width: ${MobileSize}) {
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const HeaderText = styled(Header)`
  margin-bottom: 8px;
`;

const mapStateToProps = state => ({ name: getUserFirstName(state) });

export default connect(mapStateToProps)(MyPlanHeader);
