import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import styled from 'styled-components';

import { Header, COLORS, Button, Alert } from '@summer/ui-components/src';

import InitialLoadingScreen from 'components/pages/retirementMatch/dashboard/verification/InitialLoadingScreen';
import NoTransactionsScreen from 'components/pages/retirementMatch/dashboard/verification/NoTransactionsScreen';
import TransactionsTable from 'components/pages/retirementMatch/shared/TransactionsTable';
import { MobileSize } from 'constants/styleguide';
import { useTimeout, useInterval } from 'hooks/common';
import fetchPlaidIntegrationsMock from 'mocks/fixtures/plaidIntegrations.json';
import { fetchPlaidTransactions } from 'redux/actions/plaid.actions';
import { getIsRetirementMatchDemo } from 'redux/selectors/flags.selectors';
import { getPlaidPaymentsByInstitutionId } from 'redux/selectors/plaidTransactions.selectors';

const PaymentVerification = ({
  plaidTransactionsData = [],
  setDataSummaryOpen,
  isDemo,
}) => {
  const dispatch = useDispatch();
  const MIN_LOADING_PERIOD = 10000;
  const MAX_LOADING_PERIOD = 20000;

  const [initialIntervalElapsed, setInitialIntervalElapsed] = useState(false);
  const [secondaryIntervalElapsed, setSecondaryIntervalElapsed] =
    useState(false);

  useTimeout(() => setInitialIntervalElapsed(true), MIN_LOADING_PERIOD);
  useTimeout(() => setSecondaryIntervalElapsed(true), MAX_LOADING_PERIOD);

  const showInitialLoadingScreen =
    !initialIntervalElapsed ||
    (!secondaryIntervalElapsed && plaidTransactionsData?.length === 0);
  const showNoTransactionsScreen =
    secondaryIntervalElapsed && plaidTransactionsData?.length === 0;

  useInterval(() => {
    if (!isDemo && showInitialLoadingScreen) {
      dispatch(fetchPlaidTransactions());
    }
  }, 2000);

  if (showInitialLoadingScreen) {
    return (
      <FullPage>
        <InitialLoadingScreen />
      </FullPage>
    );
  }

  if (showNoTransactionsScreen) {
    return (
      <FullPage>
        <NoTransactionsScreen />
      </FullPage>
    );
  }

  return (
    <FullPage>
      <TableWrapper>
        <Alert theme="success" dismissable={false}>
          Great! Your student loans qualify for a match.
        </Alert>
        <CustHeader h2>
          Here are your most recent student loan payments:
        </CustHeader>
        <TransactionsTable
          transactions={plaidTransactionsData}
          hidePagination
        />
        <ButtonContainer>
          <Button
            type="button"
            width={180}
            onClick={() => setDataSummaryOpen(false)}
          >
            Looks good!
          </Button>
        </ButtonContainer>
      </TableWrapper>
    </FullPage>
  );
};

const FullPage = styled.div`
  display: flex;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-image: linear-gradient(to bottom, #fff, #eceff1 155%);

  z-index: 10;
`;

const TableWrapper = styled.div`
  max-width: 650px;
  width: 100%;
  color: ${COLORS.darkGrey};
  font-weight: 600;
  padding-top: 40px;

  @media (max-width: ${MobileSize}) {
    padding-top: 20px;
  }
`;

const CustHeader = styled(Header)`
  margin: 30px 10px 25px 10px;
`;

const ButtonContainer = styled.div`
  text-align: right;
  margin-right: 10px;
  padding-bottom: 50px;

  @media (max-width: ${MobileSize}) {
    padding-top: 30px;
    margin: 0 30px 0 30px;
  }
`;

const mapStateToProps = (state, { institutionId }) => {
  const isDemo = getIsRetirementMatchDemo(state);
  const demoInstitutionId =
    fetchPlaidIntegrationsMock.plaidIntegrations[0].institutionId;

  return {
    plaidTransactionsData: getPlaidPaymentsByInstitutionId(
      state,
      isDemo ? demoInstitutionId : institutionId,
    ),
    isDemo,
  };
};

export default connect(mapStateToProps)(PaymentVerification);
