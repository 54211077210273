import React, { useEffect } from 'react';

import { Text, MoneyInput } from '@summer/ui-components';

import {
  Question,
  Label,
  SmallTextInputContainer,
} from 'components/pages/idr/wizard/shared/styles';
import { validateNumber } from 'utils/validate';

const SpouseExpectedNextAnnualIncome = ({
  formik: {
    values,
    errors,
    handleBlur,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
  },
}) => {
  useEffect(
    function clearFieldOnUnmount() {
      return () =>
        setFieldValue(`tellUsAboutYou.spouseExpectedNextAnnualIncome`, '');
    },
    [setFieldValue],
  );

  return (
    <div>
      <Question id="spouseExpectedNextAnnualIncome">
        <Label>
          What do you expect your spouse&rsquo;s income to be in a year?
        </Label>
        <Label>
          <Text paragraph as="span">
            This helps us calculate what the total cost of your new payment plan
            would be.
          </Text>
        </Label>
        <SmallTextInputContainer>
          <MoneyInput
            name="tellUsAboutYou.spouseExpectedNextAnnualIncome"
            value={values.spouseExpectedNextAnnualIncome}
            error={errors.spouseExpectedNextAnnualIncome}
            touched={touched.spouseExpectedNextAnnualIncome}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            isSubmitting={isSubmitting}
            onBlur={handleBlur}
            validate={validateNumber()}
          />
        </SmallTextInputContainer>
      </Question>
    </div>
  );
};

export default SpouseExpectedNextAnnualIncome;
