import { Formik } from 'formik';
import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { DateInput } from '@summer/ui-components';

import { usePlatform } from 'hooks/platform';
import { updateCurrentIdrForm } from 'redux/actions/idr.actions';
import { toggleIdrQuestionnaire } from 'redux/actions/ui.actions';
import { getIdrRecertDate } from 'redux/selectors/financialProfile.selectors';
import {
  getCurrentIdrFormId,
  getCurrentIdrFormStep,
} from 'redux/selectors/idr.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { idrRecertDateSchema } from 'schemas/idr';

import Footer from '../Footer';
import { FormContents, Label, HelperText } from '../shared';

const RecertificationDate = ({
  currentIdrFormStep,
  formId,
  idrRecertDate,
  isLoading,
  navigation,
}) => {
  const { isMobile } = usePlatform();
  const dispatch = useDispatch();
  const { next, back, isLastStep } = navigation;

  // If this is the last step, we set the new current step for the larger IDR wizard,
  // and the IDR middleware will navigate for us.
  // Otherwise we pass in the sub-wizard's next function to the middleware as onSuccess.
  const reviewYourPlansStep = 3;

  const newIdrFormStep = isLastStep ? reviewYourPlansStep : currentIdrFormStep;

  const onSuccess = isLastStep
    ? () => dispatch(toggleIdrQuestionnaire(false))
    : next;

  const handleSave = ({ idrRecertDate }) => {
    // Update financial profile
    dispatch(
      updateCurrentIdrForm(
        {
          currentStep: newIdrFormStep,
          form: { formId },
          financialProfile: { idrRecertDate },
        },
        {
          close: false,
          finish: false,
          onSuccess,
        },
      ),
    );
  };

  return (
    <Formik
      initialValues={{ idrRecertDate }}
      onSubmit={handleSave}
      validationSchema={idrRecertDateSchema}
    >
      {({
        values,
        errors,
        handleBlur,
        handleSubmit,
        touched,
        isSubmitting,
        setFieldValue,
        isValid,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormContents>
              <Label h2 align={isMobile ? 'center' : 'left'} id="idrRecertDate">
                What is your recertification date for this plan?
              </Label>
              <HelperText>
                If you&rsquo;re unsure of your recertification date, you can
                just provide an estimate.
              </HelperText>
              <DateInput
                name="idrRecertDate"
                label="Recertification Date"
                value={values.idrRecertDate}
                error={errors?.idrRecertDate}
                touched={touched.idrRecertDate}
                onChange={setFieldValue}
                isSubmitting={isSubmitting}
                onBlur={handleBlur}
              />
            </FormContents>
            <Footer
              handleBack={back}
              isBackLoading={isLoading}
              isNextDisabled={!isValid}
              isNextLoading={isLoading}
              isValid={isValid}
            />
          </form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => ({
  currentIdrFormStep: getCurrentIdrFormStep(state),
  formId: getCurrentIdrFormId(state),
  idrRecertDate: getIdrRecertDate(state).toString(),
  isLoading: isLoading(state, 'updateCurrentIdrForm'),
});

export default connect(mapStateToProps)(RecertificationDate);
