import _, { find, isNil } from 'lodash';
import fp from 'lodash/fp';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { LoadingBars, SelectInput, Text } from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import { Row, Spacer } from 'components/pages/idr/wizard/shared/styles';
import { getActiveFsaLoanServicerNames } from 'redux/selectors/loans.selectors';
import { getConsolidationServicers } from 'redux/selectors/servicers.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';

const FooterMessage = ({ servicer }) => {
  if (isNil(servicer)) {
    return (
      <Text paragraph size="small" smallTracking={false}>
        Not sure which to select? We recommend choosing a servicer that you’re
        already familiar with.
      </Text>
    );
  }

  if (servicer.handlesConsolidation) {
    return (
      <Text paragraph size="small" smallTracking={false}>
        As you selected {servicer.companyName}, they will process your
        consolidation and service your loan/s moving forward.
      </Text>
    );
  }

  return (
    <>
      <Text paragraph size="small" smallTracking={false}>
        As you chose {servicer.companyName}, the initial processing will be
        handled by Aidvantage, who will communicate with you to begin with.
      </Text>
      <br />
      <Text paragraph size="small" smallTracking={false}>
        Once processing of your Direct Consolidation Loan is complete, it will
        be forwarded to {servicer.companyName} and they will service it from
        that point forward.
      </Text>
    </>
  );
};

const DropdownFooter = ({ servicer }) => {
  return (
    <Footer>
      <FooterMessage servicer={servicer} />
    </Footer>
  );
};

class ServicersToSendConsolidation extends React.Component {
  render() {
    const {
      formik: { setFieldTouched, setFieldValue, values, touched, errors },
      isLoading,
      servicers,
      platformType,
      currentServicers,
    } = this.props;

    const options = _.flow(
      fp.sortBy('companyName'),
      fp.map(servicer => {
        const { companyName, id } = servicer || {};
        return {
          label: companyName,
          value: id,
        };
      }),
    )(servicers);

    values.multipleServicers = currentServicers.length > 1;

    return (
      <>
        <Header id="targetServicerId">
          <Text size={platformType === MOBILE ? null : 'large'} bold paragraph>
            When you consolidate, you can pick any Servicer to manage the loan.
          </Text>
        </Header>
        {isLoading ? (
          LoadingBars
        ) : (
          <>
            <Row>
              <ServicerSelectContainer>
                <SelectInput
                  name="submit.targetServicerId"
                  placeholder="Type or scroll to select"
                  value={values.targetServicerId}
                  touched={touched.targetServicerId}
                  error={errors.targetServicerId}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  options={options}
                />
              </ServicerSelectContainer>
              <Spacer />
            </Row>
            <DropdownFooter
              servicer={find(servicers, s => s.id === values.targetServicerId)}
            />
          </>
        )}
      </>
    );
  }
}

const ServicerSelectContainer = styled.div`
  flex: 1;
`;

const Footer = styled.div`
  letter-spacing: 0.13px;
`;

const Header = styled.div`
  margin-bottom: 16px;
`;

const mapStateToProps = state => ({
  isLoading:
    isLoading(state, 'fetchLoans') || isLoading(state, 'fetchServicers'),
  currentServicers: getActiveFsaLoanServicerNames(state),
  servicers: getConsolidationServicers(state),
});

export default connect(mapStateToProps)(Platform(ServicersToSendConsolidation));
