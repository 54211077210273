import React from 'react';
import styled from 'styled-components';

import { Strong, Text } from '@summer/ui-components';

export const UploadHelpText = () => (
  <div style={{ marginBottom: '24px' }}>
    <Text paragraph>
      Provide your most recent federal income tax return or transcript. This is
      typically known as the Form 1040.
    </Text>
  </div>
);

export const IncomeHelpText = () => (
  <div style={{ marginBottom: '24px' }}>
    <Text>Provide one of the following:</Text>
    <div style={{ margin: '8px 0px' }} />
    <List>
      <ListItem>
        <Text as="li" paragraph>
          A <Strong>pay stub</Strong>
        </Text>
      </ListItem>

      <ListItem>
        <Text as="li" paragraph>
          A <Strong>letter from your employer</Strong> listing your gross pay
          and frequency of pay
        </Text>
      </ListItem>

      <ListItem>
        <Text as="li" paragraph>
          If you are currently on unemployment, please include your{' '}
          <Strong>proof of unemployment income</Strong>. This can be a
          screenshot or image of your payment history on your state&rsquo;s
          unemployment website, or it can be a letter or email that you received
          from your state&rsquo;s unemployment office.
        </Text>
      </ListItem>

      <ListItem>
        <Text as="li" paragraph>
          If the above options are not available,{' '}
          <Strong>a signed statement</Strong> explaining each source of income,
          including the name and address of each source.
        </Text>
      </ListItem>
    </List>
    <div style={{ margin: '8px 0px' }} />
    <Text paragraph>
      The date on this documentation must be no older than 90 days from the date
      you sign your IDR application.
    </Text>
  </div>
);

const List = styled.ol`
  list-style: none;
  counter-reset: list-counter;
`;
const ListItem = styled.div`
  & > li {
    counter-increment: list-counter;
    padding-left: 18px;
    text-indent: -18px;
  }

  & > li::before {
    content: counter(list-counter) '. ';
    font-weight: 600;
  }
`;
