import React from 'react';
import styled from 'styled-components';

import { Header, Text } from '@summer/ui-components';

export const Img = styled.img`
  margin-bottom: 10px;
`;

export const SmallTitle = ({ children }) => (
  <SmallTitleContainer>
    <Header h3>{children}</Header>
  </SmallTitleContainer>
);
const SmallTitleContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const SubTitle = ({ children }) => (
  <SubTitleContainer>
    <Header h3>{children}</Header>
  </SubTitleContainer>
);
const SubTitleContainer = styled.div`
  margin-bottom: 20px;
`;

export const GuideText = ({ children }) => (
  <TextContainer>
    <Text paragraph>{children}</Text>
  </TextContainer>
);
const TextContainer = styled.div`
  margin-bottom: 10px;
`;

export const List = styled.ul`
  margin-left: 40px;
`;

export const ListItem = ({ children }) => (
  <TextContainer>
    <Text as="li" paragraph>
      {children}
    </Text>
  </TextContainer>
);
