import { isNil } from 'lodash';

export const customFormValidation = values => {
  const errors = {};

  Object.entries(values).forEach(([key, val]) => {
    if (!isValidNumber(val)) {
      errors[key] = 'Amount must be between 0 and 10 million';
    }
  });

  if (allObjValuesNil(values)) {
    Object.keys(values).forEach(key => {
      errors[key] = 'Please enter at least one expense value';
    });
  }

  return errors;
};

const isValidNumber = number => {
  return number === null || (number >= 0 && number <= 1000000000);
};

const allObjValuesNil = obj => {
  return Object.values(obj).every(val => {
    return isNil(val) || val === '';
  });
};
