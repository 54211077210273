import { push as redirectTo } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';

import Container from 'components/layout/wizard/WizardContainer';
import WizardHeader from 'components/layout/wizard/WizardHeader';
import { useWizard } from 'hooks/Wizard/useWizard';
import { renderWizardSteps } from 'hooks/Wizard/utils';

import CurrentSituation from './CurrentSituation';

const EmergencySavingsWizard = () => {
  const dispatch = useDispatch();

  const config = [
    {
      title: 'Current Situation',
      component: CurrentSituation,
    },
  ];

  const { steps, headers, navigation, progress } = useWizard({
    config,
  });

  return (
    <Container>
      <WizardHeader
        headers={headers}
        currentStep={navigation.currentStep}
        progress={progress.overallProgress}
        handleClose={() => dispatch(redirectTo('/emergency-savings'))}
      />
      {renderWizardSteps(steps)}
    </Container>
  );
};

export default EmergencySavingsWizard;
