import { LOCATION_CHANGE } from 'connected-react-router';
import _, { isNil, get, omit } from 'lodash';

import { FormStatus } from '@simplifidev/shared/dist/constants/forms';

import { REPAYMENT_PLAN_TYPES } from 'components/pages/idr/wizard/steps/ReviewYourPlan/constants';
import {
  CREATE_ACCOUNT,
  CREATE_PSLF_FORM,
  DEFAULT_ACTION,
  FETCH_IDR_PATH_OPTIONS,
  FETCH_LOANS,
  FILL_LOAN_INFO,
  FILL_SURVEY,
  FETCH_CURRENT_PSLF_FORM,
  IDENTIFY,
  LOG_TOOLTIP,
  LOGIN,
  NETWORK_SUCCESS,
  POST_OAUTH_CODE,
  CREATE_IDR_FORM,
  UPDATE_CURRENT_IDR_FORM,
  TRACK_ACTION,
  FETCH_RECOMMENDATIONS,
  UPDATE_SIGNATURE_REQUEST,
  TRACK_SERVER_SIDE,
} from 'constants/actionTypes';
import { trackServerSide } from 'redux/actions/analytics.actions';
import { apiRequest } from 'redux/actions/api.actions';
import handleSideEffects from 'redux/handleSideEffects';
import {
  getCurrentIdrFormStep,
  getIdrWizardSteps,
} from 'redux/selectors/idr.selectors';
import { zendeskWidgetIdentify } from 'services/zendesk';
import {
  alias,
  identify,
  track,
  segmentIdentify,
  getAnalyticsTraits,
} from 'utils/track';

const trackingMiddleware = handleSideEffects({
  [LOCATION_CHANGE]: (dispatch, { payload }, state) => {
    const trackPayload = omit(
      {
        Pathname: payload.location.pathname,
        ...payload.location.state,
      },
      ['token'],
    );
    track(`[USER] Page View : ${payload.location.pathname}`, trackPayload);

    if (
      payload.location.pathname === '/refinancing' &&
      !isNil(state.currentUser)
    ) {
      segmentIdentify(_.toLower(state.currentUser.email), {});
      dispatch(
        trackServerSide(`[USER] Page View : ${payload.location.pathname}`),
      );
    }
  },

  [IDENTIFY]: (dispatch, payload, state) => {
    const { currentUser } = state;
    if (currentUser.id !== null) {
      identify(_.toLower(currentUser.email), currentUser);
    }
  },

  [LOG_TOOLTIP]: (dispatch, { payload }) => {
    const event = payload.kind || LOG_TOOLTIP;
    track(event);
  },

  [TRACK_ACTION]: (dispatch, { payload, meta }) => {
    track(`[USER] ${payload}`, meta);
  },

  [TRACK_SERVER_SIDE]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: '/v1/track',
        method: 'POST',
        fromAction: TRACK_SERVER_SIDE,
        label: 'serverSideTrack',
        body: payload,
      }),
    );
  },

  /**
   * Handle any required tracking updates. User profile can be updated in
   * Mixpanel based on the request body, response body, and
   * state.
   */
  [NETWORK_SUCCESS]: (dispatch, { payload, meta }, store) => {
    const { currentUser } = store;
    const { body } = meta.request;
    const resBody = payload.response;
    const id = currentUser.email && _.toLower(currentUser.email);
    const analyticsTraits = getAnalyticsTraits();

    const now = new Date();

    if (
      (meta.fromAction === CREATE_ACCOUNT &&
        payload.response.success !== false) ||
      (meta.fromAction === POST_OAUTH_CODE &&
        payload.response.user &&
        payload.response.isNew)
    ) {
      let user;

      if (meta.fromAction === POST_OAUTH_CODE) {
        ({ user } = payload.response.user);
      } else {
        ({ user } = payload.response);
      }

      const id = _.toLower(user.email);

      alias(id);

      segmentIdentify(id, {
        All: {
          ...user,
          createdAccount: now,
          createacc: now,
          userId: user.id,
        },
      });
      zendeskWidgetIdentify({
        firstName: user.firstName,
        lastName: user.lastName,
        email: id,
      });

      track(CREATE_ACCOUNT);
    } else if (
      meta.fromAction === LOGIN ||
      (meta.fromAction === POST_OAUTH_CODE &&
        payload.response.user &&
        !payload.response.isNew)
    ) {
      let user;

      if (meta.fromAction === POST_OAUTH_CODE) {
        user = get(payload, 'response.user.user', {});
      } else {
        user = get(payload, 'response.user', {});
      }

      segmentIdentify(_.toLower(user.email), {
        All: {
          ...user,
          userId: user.id,
        },
        Mixpanel: {},
        Zendesk: {
          accountVerified: user.isVerified,
        },
      });

      zendeskWidgetIdentify({
        firstName: user.firstName,
        lastName: user.lastName,
        email: _.toLower(user.email),
      });
      track(LOGIN);
    } else if (meta.fromAction === FILL_SURVEY) {
      // User has completed the onboarding page with occupational questions.
      const income = body.salary;
      const paymentAbility = body.troubleWithPayments;

      let industry;
      let zendeskIndustry;

      if (body.isPublicSector === 'not-sure') {
        industry = 'not-sure';
        zendeskIndustry = 'public_sector_maybe';
      } else if (body.isPublicSector === 'yes') {
        industry = 'public';
        zendeskIndustry = 'public_sector_yes';
      } else {
        industry = 'private';
        zendeskIndustry = 'public_sector_no';
      }

      let zendeskPaymentAbility;

      switch (paymentAbility) {
        case 'yes':
          zendeskPaymentAbility = 'cannot_pay';
          break;
        case 'no':
          zendeskPaymentAbility = 'can_easily_pay';
          break;
        case 'maybe':
          zendeskPaymentAbility = 'can_pay_but_difficult';
          break;
        default:
          break;
      }

      segmentIdentify(id, {
        Mixpanel: {
          income,
          finishedOnboarding: now,
          jobIndustry: industry,
          paymentAbility,
        },
        Zendesk: {
          finishedOnboarding: now,
          paymentAbility: zendeskPaymentAbility,
          incomeEstimate: income,
          publicSector: zendeskIndustry,
        },
      });
    } else if (meta.fromAction === FILL_LOAN_INFO) {
      // User has added estimates of their federal and private loans.
      const {
        federalLoansEstimate,
        privateLoansEstimate,
        loansInDefaultFederal,
        loansInDefaultPrivate,
      } = body;

      segmentIdentify(id, {
        Mixpanel: {
          federalLoansAmount: federalLoansEstimate,
          hasFederalLoans: federalLoansEstimate !== 0,
          privateLoansAmount: privateLoansEstimate,
          hasPrivateLoans: privateLoansEstimate !== 0,
        },
        Zendesk: {
          federalLoanEstimate: federalLoansEstimate,
          privateLoanEstimate: privateLoansEstimate,
          default: loansInDefaultFederal,
          default_private: loansInDefaultPrivate,
        },
      });
    } else if (meta.fromAction === FETCH_RECOMMENDATIONS) {
      // Request has been made to /users/repayment.
      // This happens when the repayment dashboard is loaded.
      const isIdrRecommended = get(resBody, 'recommended.idr.isRecommended');

      // This considers undefined to be not eligible. This is the response if
      // the user is still in school
      segmentIdentify(id, {
        All: {
          idrEligible: !!isIdrRecommended,
        },
      });
    } else if (meta.fromAction === CREATE_IDR_FORM) {
      segmentIdentify(id, {
        Mixpanel: {
          startedIdrQuestionnaire: now,
        },
      });
    } else if (meta.fromAction === UPDATE_CURRENT_IDR_FORM) {
      // User has saved a section in IDR.
      const { profile, financialProfile, status } = body;
      const newStep = Number(body.currentStep);
      const { state, dateOfBirth } = profile || {};
      const {
        maritalStatus,
        jointTaxReturn,
        numberOfKids,
        numberOfDependents,
        spouseHasFsl,
        changeInIncome,
        spouseIncomeChange,
        adjustedGrossIncome,
        expectedAnnualGrossIncome,
        spouseAdjustedGrossIncome,
        spouseExpectedAnnualGrossIncome,
        jointAdjustedGrossIncome,
      } = financialProfile || {};

      const currentStep = getCurrentIdrFormStep(store);
      const wizardSteps = getIdrWizardSteps(store);

      if (status === FormStatus.SIGNED) {
        track('[USER] Submitted IDR');

        segmentIdentify(id, {
          Mixpanel: {
            submittedApplication: now,
          },
          Zendesk: {
            idrFormSubmitted: now,
          },
        });
      }

      // only track if the user if moving to the next step
      if (newStep > currentStep) {
        const completedStepName = wizardSteps[currentStep];
        let mixpanelName;
        let zendeskName;
        let serverSideTrackName;

        switch (completedStepName) {
          case '/idr/tell-us-about-you':
            mixpanelName = 'finishedIdrQuestionnaire';
            zendeskName = 'finishedIdrQuestionnaire';
            serverSideTrackName = 'Finished IDR step: TUAY';
            break;
          case '/idr/your-loans':
            serverSideTrackName = 'Finished IDR step: your loans';
            break;
          case '/idr/review-your-plan':
            mixpanelName = 'selectedIdrPlan';
            zendeskName = 'selectedIdrPlan';
            serverSideTrackName = 'Finished IDR step: review your plan';
            break;
          case '/idr/submit/contact-info':
            mixpanelName = 'completedIdrAboutYou';
            zendeskName = 'completedIdrAboutYou';
            serverSideTrackName = 'Finished IDR step: contact info';
            break;
          case '/idr/submit/confirm-servicers':
            serverSideTrackName = 'Finished IDR step: confirm servicers';
            break;
          case '/idr/submit/upload-documents':
            mixpanelName = 'uploadedDocuments';
            zendeskName = 'uploadedDocuments';
            serverSideTrackName = 'Finished IDR step: upload documents';
            break;
          default:
            break;
        }

        if (mixpanelName) {
          if (isNil(analyticsTraits[mixpanelName])) {
            const traits = { Mixpanel: {}, Zendesk: {} };

            traits.Mixpanel[mixpanelName] = now;
            traits.Zendesk[zendeskName] = now;

            segmentIdentify(id, traits);
          }
        }

        if (serverSideTrackName) {
          dispatch(trackServerSide(serverSideTrackName));
        }
      }

      // Only being used for putting users in cohorts so it is not necessary to
      // determine birth month or day.
      const age = isNil(dateOfBirth)
        ? undefined
        : new Date(dateOfBirth).getFullYear();

      let zendeskMaritalStatus = maritalStatus;

      if (maritalStatus === 'married') {
        if (jointTaxReturn === 'yes') {
          zendeskMaritalStatus = 'married_filing_jointly';
        } else if (jointTaxReturn === 'no') {
          zendeskMaritalStatus = 'married_filing_separately';
        }
      } else if (maritalStatus === 'seperated') {
        zendeskMaritalStatus = 'married_other';
      } else if (maritalStatus === 'cantAccess') {
        zendeskMaritalStatus = 'married_cannot_access';
      }

      if (maritalStatus || state || adjustedGrossIncome) {
        segmentIdentify(id, {
          All: {
            state,
          },
          Mixpanel: {
            income: adjustedGrossIncome,
            maritalStatus,
            age,
          },
          Zendesk: {
            age,
            maritalStatus: zendeskMaritalStatus,
            numberKids: numberOfKids,
            numberDependents: numberOfDependents,
            spouseFederalLoans: spouseHasFsl,
            changeInIncome,
            spouseChangeInIncome: spouseIncomeChange,
            incomeAgi: adjustedGrossIncome,
            incomeExpectedAgi: expectedAnnualGrossIncome,
            spouseIncomeAgi: spouseAdjustedGrossIncome,
            spouseIncomeExpectedAgi: spouseExpectedAnnualGrossIncome,
            jointIncomeAgi: jointAdjustedGrossIncome,
            jointTaxReturn: jointTaxReturn === 'yes',
            spouseFiledTaxReturn:
              zendeskMaritalStatus === 'married filing separately',
          },
        });
      }
    } else if (meta.fromAction === FETCH_IDR_PATH_OPTIONS) {
      // Request has been made for paths from Review Your Plans.
      const { current, paths, pathsWithPSLF } = resBody;

      const isRecommended = name => path =>
        path.planType === name && path.recommended === true;

      const { PAYE, REPAYE, IBR, ICR } = REPAYMENT_PLAN_TYPES;

      const paye = !!paths.find(isRecommended(PAYE));
      const repaye = !!paths.find(isRecommended(REPAYE));
      const ibr = !!paths.find(isRecommended(IBR));
      const icr = !!paths.find(isRecommended(ICR));

      segmentIdentify(id, {
        Mixpanel: {
          recommendedPAYE: paye,
          recommendedREPAYE: repaye,
          recommendedIBR: ibr,
          recommendedICR: icr,
        },
        Zendesk: {
          payeRecommended: paye,
          repayeRecommended: repaye,
          ibrRecommended: ibr,
          icrRecommended: icr,
          standardPlanCalculations: current,
          idrPlanCalculations: { paths, pathsWithPSLF },
        },
      });
    } else if (meta.fromAction === FETCH_LOANS) {
      // TODO:[ch18004]Is there anything we need to do for mix panel?
      const revisions = resBody.map(loan => loan.loansRevision[0]);

      const federalLoansAmount = revisions.reduce((acc, val) => {
        return acc + val.principalBalance;
      }, 0);

      const inIdr = !!revisions.find(revision => {
        const plan = revision.repaymentPlanTypeRaw;

        return plan !== 'STANDARD REPAYMENT' && !isNil(plan);
      });

      segmentIdentify(id, {
        Mixpanel: {
          federalLoansAmount,
          alreadyInIdr: inIdr,
        },
        Zendesk: {
          alreadyInIdr: inIdr,
        },
      });
    } else if (
      meta.fromAction === CREATE_PSLF_FORM ||
      meta.fromAction === FETCH_CURRENT_PSLF_FORM
    ) {
      segmentIdentify(id, {
        Mixpanel: {
          currentPslfFormId: resBody.id,
        },
      });
    } else if (meta.fromAction === UPDATE_SIGNATURE_REQUEST) {
      const formType = store.signatureRequests.find(
        signRequest =>
          signRequest.signers.signatureRequestId === body.signatureRequestId,
      )?.formType;

      if (body.complete && formType === 'pslf') {
        segmentIdentify(id, {});
      }
    }
  },

  [DEFAULT_ACTION]: (dispatch, { meta }) => {
    if (meta && meta.trackAction) {
      track(`[USER] ${meta.trackAction}`);
    }
  },
});

export default trackingMiddleware;
