import { SET_REPAYMENT_PLAN_TYPES } from 'constants/actionTypes';

const initialState = [];

const repaymentPlanTypesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_REPAYMENT_PLAN_TYPES:
      return payload;

    default:
      return state;
  }
};

export default repaymentPlanTypesReducer;
