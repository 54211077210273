import { Formik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Header, TextInput, Button, COLORS } from '@summer/ui-components';

import GlobalError from 'components/shared/GlobalError';
import { TabletSize } from 'constants/styleguide';
import { updateProfile } from 'redux/actions/profile.actions';
import {
  getUserFirstName,
  getUserLastName,
} from 'redux/selectors/profile.selectors';
import { getErrorMessage, isLoading } from 'redux/selectors/ui.selectors';
import { updateNameSchema } from 'schemas/user';

const UpdateName = ({
  firstName,
  lastName,
  isLoading,
  serverError,
  dispatch,
}) => {
  const [submitted, updateSubmitted] = useState(false);

  const handleUpdateProfile = values => {
    dispatch(updateProfile(values));
    updateSubmitted(true);
  };

  return (
    <Container>
      <HeaderContainer>
        <Header h4 color="secondary">
          Update your information
        </Header>
      </HeaderContainer>

      <Formik
        initialValues={{ firstName, lastName }}
        validationSchema={updateNameSchema}
        onSubmit={handleUpdateProfile}
      >
        {({
          handleSubmit,
          touched,
          isSubmitting,
          isValid,
          errors,
          values,
          handleChange,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextInput
              label="First Name"
              name="firstName"
              touched={touched.firstName}
              isSubmitting={isSubmitting}
              error={errors.firstName}
              value={values.firstName || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextInput
              label="Last Name"
              name="lastName"
              touched={touched.lastName}
              isSubmitting={isSubmitting}
              error={errors.lastName}
              value={values.lastName || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <GlobalError label="updateProfile" />

            <Button
              disabled={!isValid || (!serverError && submitted)}
              isLoading={isLoading}
              type="submit"
            >
              {!serverError && submitted ? 'Name updated!' : 'Update'}
            </Button>
          </form>
        )}
      </Formik>
    </Container>
  );
};

const mapStateToProps = state => ({
  firstName: getUserFirstName(state),
  lastName: getUserLastName(state),
  serverError: getErrorMessage(state, 'updateProfile'),
  isLoading: isLoading(state, 'updateProfile'),
});

export default connect(mapStateToProps)(UpdateName);

const Container = styled.div`
  margin-top: 24px;
  margin-bottom: 48px;
  margin-right: 32px;
  max-width: 355px;

  @media (max-width: ${TabletSize}) {
    margin-right: auto;
    margin-left: auto;
  }
`;

const HeaderContainer = styled.div`
  border-bottom: 1px solid ${COLORS.grey};
  margin-bottom: 16px;
  padding-bottom: 8px;
`;
