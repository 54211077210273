/* eslint-disable react/display-name */

import { format, parseISO } from 'date-fns';
import { includes, isNil, map, set, get, has, isFunction } from 'lodash';
import React from 'react';

import { Text, CardTable } from '@summer/ui-components';

import { Cell, CELL_TYPES } from 'components/common/ViewLoans/LoansTables/Cell';
import { HeaderCell } from 'components/common/ViewLoans/LoansTables/HeaderCell';
import Platform, { MOBILE, TABLET } from 'components/hoc/Platform';
import {
  loanTypeTooltipText,
  pslfLoanTypeTooltipText,
  pslfEligibleTooltipText,
} from 'constants/manageLoans';
import { dollars } from 'utils/numbers';

import EligibilityCell from './customCells/EligibilityCell';

const validateCell = (accessor, row) => {
  const errors = get(row, 'original.errors');
  return !has(errors, accessor);
};

const headersTooltips = {
  educationLevel:
    'Select whether the loan was obtained for an undergraduate or graduate degree.',
  status: (
    <>
      <Text small>
        Status is the current payment state of your loan. Some example statuses
        include: In Repayment, Deferment, Forbearance, In-grace period, and
        Defaulted.
      </Text>
      <br />
      <Text small>
        If your loans are &quot;Defaulted&quot; then this means that
        you&rsquo;ve missed several payments and your financial situation may be
        negatively impacted. You should contact Summer immediately and
        we&rsquo;ll provide you with tips on how to get out of default.
      </Text>
    </>
  ),
  repaymentPlan: (
    <>
      <Text small>
        Repayment Plan determines how much and for how long you have to pay back
        your loans. All borrowers are automatically enrolled in the
        &quot;Standard Plan&quot; which means that you have to pay back your
        loans in 10 years.
      </Text>
      <br />
      <Text small>
        Income-Driven Repayment plans such as REPAYE, PAYE, IBR, and ICR tie
        your monthly payment to your income which can help make your payments
        more manageable. Click on &quot;Income-Driven Repayment&quot; in the
        Summer app and we&rsquo;ll help you get enrolled.
      </Text>
    </>
  ),
  interestRate:
    'Interest rate determines how much extra you have to pay back on top of the money you borrowed. The higher the interest rate, the more expensive your loan.',
  currentBalance:
    'Current balance includes any principal on your student loans that you may owe plus any outstanding interest.',
  pslfEligibility: pslfEligibleTooltipText,
};

const NewLoansTable = ({
  loans,
  onRowClick,
  platformType,
  columnsToShow,
  tableIndex,
  tableName,
  isPslf,
}) => {
  const isMobile = platformType === MOBILE;
  const isTablet = platformType === TABLET;

  const columns = React.useMemo(
    () => [
      { Header: 'Loan Id', accessor: 'id', show: false },
      {
        Header: () => (
          <HeaderCell
            label="Loan Type"
            tipText={isPslf ? pslfLoanTypeTooltipText : loanTypeTooltipText}
          />
        ),
        accessor: 'loanType',
        Cell: ({ cell, row }) => {
          const { amountBorrowed, dateBorrowed } = row.original;
          const dateBorrowedText = dateBorrowed
            ? `borrowed in ${format(parseISO(dateBorrowed), 'MMM yyyy')}`
            : 'borrowed';
          const moreInfo = `${dollars(amountBorrowed)} ${dateBorrowedText}`;
          return (
            <Cell
              valid={validateCell('federalLoanTypeId', row)}
              type={CELL_TYPES.STRING}
              value={cell.value}
              moreInfo={!isNil(amountBorrowed) ? moreInfo : null}
            />
          );
        },
        width: 200,
        maxWidth: 240,
      },
      {
        Header: () => (
          <HeaderCell label="Degree" tipText={headersTooltips.educationLevel} />
        ),
        accessor: 'educationLevel',
        Cell: ({ cell, row }) => (
          <Cell
            valid={validateCell('educationLevel', row)}
            type={CELL_TYPES.STRING}
            value={cell.value}
          />
        ),
        show: !isMobile && !isTablet,
      },
      {
        Header: () => (
          <HeaderCell
            label="Status"
            toolTipWidth={300}
            tipText={headersTooltips.status}
          />
        ),
        accessor: 'status',
        Cell: ({ cell, row }) => (
          <Cell
            valid={validateCell('status', row)}
            type={CELL_TYPES.STRING}
            value={cell.value}
          />
        ),
        show: !isMobile,
      },
      {
        Header: () => (
          <HeaderCell
            label="Repayment Plan"
            toolTipWidth={300}
            tipText={headersTooltips.repaymentPlan}
          />
        ),
        accessor: 'repaymentPlan',
        Cell: ({ cell, row }) => (
          <Cell
            valid={validateCell('repaymentPlanTypeId', row)}
            type={CELL_TYPES.STRING}
            value={cell.value}
          />
        ),
        show: !isMobile,
      },
      {
        Header: () => (
          <HeaderCell
            label="Interest Rate"
            tipText={headersTooltips.interestRate}
          />
        ),
        accessor: 'interestRate',
        Cell: ({ cell, row }) => (
          <Cell
            valid={validateCell('interestRate', row)}
            type={CELL_TYPES.PERCENT}
            value={cell.value}
          />
        ),
        show: !isMobile && !isTablet,
      },
      {
        Header: () => (
          <HeaderCell
            label="Current Balance"
            tipText={headersTooltips.currentBalance}
          />
        ),
        accessor: 'currentBalance',
        Cell: ({ cell, row }) => (
          <Cell
            valid={
              validateCell('principalBalance', row) &&
              validateCell('outstandingInterestBalance', row)
            }
            type={CELL_TYPES.MONEY}
            value={cell.value}
            loanHasBeenPaidOff={cell.loanPayoffDate}
          />
        ),
        headerStyle: { justifyContent: 'flex-end' },
        style: { textAlign: 'right' },
      },
      {
        Header: () => (
          <HeaderCell
            label="PSLF Eligible?"
            tipText={headersTooltips.pslfEligibility}
          />
        ),
        accessor: 'pslfEligibilityAndConsolidationRec',
        Cell: ({ cell }) => <EligibilityCell value={cell.value} />,
        headerStyle: { justifyContent: 'flex-end' },
        style: { textAlign: 'right' },
      },
    ],
    [isMobile, isTablet, isPslf],
  );

  map(columns, column => {
    const shouldShowCol =
      includes(columnsToShow, column.accessor) && column.show !== false;
    set(column, 'show', shouldShowCol);
  });

  return (
    <CardTable
      getCustomRowProps={(props, options) => {
        const { row } = options;
        const rowValid = get(row, 'original.valid');
        const rowId = get(row, 'original.id');
        const rowUuid = get(row, 'original.uuid');

        // combine row props With our custom row props
        const style = { ...props.style };
        return {
          ...props,
          style,
          onClick: isFunction(onRowClick)
            ? () => onRowClick(rowId || rowUuid)
            : null,
          valid: rowValid,
        };
      }}
      data={React.useMemo(() => loans, [loans])}
      columns={columns}
      tableIndex={tableIndex}
      tableName={tableName}
    />
  );
};

export default Platform(NewLoansTable);
