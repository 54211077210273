import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Header, Text, TextInput } from '@summer/ui-components';

import Content from 'components/pages/pslf/wizard/layout/PslfWizardContent';
import Footer from 'components/pages/pslf/wizard/layout/PslfWizardFooter';
import InfoTooltip from 'components/shared/InfoTooltip';
import { getCurrentStepFromNavigation } from 'hooks/Wizard/utils';
import { useOnSuccess } from 'hooks/pslf';
import {
  createPslfForm,
  updateCurrentPslfForm,
} from 'redux/actions/pslf.actions';
import { getUserCurrentPslfForm } from 'redux/selectors/pslf.selectors';
import { employerNameSchema } from 'schemas/pslf';

const EmployerName = ({
  currentPslfForm,
  createPslfForm,
  updateCurrentPslfForm,
  navigation,
}) => {
  const { formId, employerName } = currentPslfForm;

  useOnSuccess('createPslfForm', navigation.next);
  useOnSuccess('updateCurrentPslfForm', navigation.next);

  const handleSave = ({ employerName }) => {
    // Since this is the first step, we need to check if the form exists yet.
    if (formId) {
      updateCurrentPslfForm({ form: { employerName, formId } });
    } else {
      createPslfForm({
        currentStep: getCurrentStepFromNavigation(navigation),
        form: {
          employerName,
        },
      });
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ employerName }}
      onSubmit={handleSave}
      validationSchema={employerNameSchema}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        isValid,
        submitCount,
      }) => (
        <Content navigation={navigation}>
          <form onSubmit={handleSubmit}>
            <Container>
              <PslfQuestionHeader>
                <Header as="h2">
                  What employer would you like to certify?
                  <InfoTooltip text="This should be the official name of your employer. To be safe, ensure it matches what is listed on the employer website or in official documents." />
                </Header>
              </PslfQuestionHeader>
              <PslfQuestionText>
                <Text paragraph>
                  You can certify both past and present employers, but we
                  recommend certifying your current employer first.
                </Text>
              </PslfQuestionText>
              <TextInput
                name="employerName"
                placeholder="Employer Name"
                value={values.employerName}
                error={errors.employerName}
                touched={touched.employerName}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                isSubmitting={isSubmitting}
                onBlur={handleBlur}
              />
            </Container>
            <Footer isValid={isValid} submitCount={submitCount} />
          </form>
        </Content>
      )}
    </Formik>
  );
};

const PslfQuestionHeader = styled.div`
  margin-bottom: 16px;
`;

const PslfQuestionText = styled.div`
  margin-bottom: 16px;
`;

const Container = styled.div`
  max-width: 480px;
  margin: auto;
`;

const mapStateToProps = state => ({
  currentPslfForm: getUserCurrentPslfForm(state),
});

export default connect(mapStateToProps, {
  createPslfForm,
  updateCurrentPslfForm,
})(EmployerName);
