import React from 'react';

import {
  List,
  ListItem,
  SmallTitle,
  GuideText,
} from 'components/pages/resourceCenter/guides/shared';
import Section from 'components/pages/resourceCenter/guides/shared/Section';
import { Link } from 'components/shared/typography';
import {
  fsaPslfDefermentForbearanceLink,
  fsaPslfForgivenessFormLink,
} from 'constants/externalLinks/fsa';

class PSLFGuide extends React.Component {
  render() {
    return (
      <>
        <Section title="Overview">
          <GuideText>
            Imagine all your debt were gone—totally off your back forever.
            Sounds good, right? Public Service Loan Forgiveness (PSLF) could get
            you there, but you&rsquo;ll need to meet a few requirements to make
            it happen. Let&rsquo;s go over the basics of Public Service Loan
            Forgiveness to you find out whether it could work for you.
          </GuideText>

          <SmallTitle>What is Public Service Loan Forgiveness?</SmallTitle>
          <GuideText>
            Public Service Loan Forgiveness (PSLF) is a government program
            designed to forgive the debts of borrowers working in public sector
            and non-profit careers after 10 years.
          </GuideText>

          <SmallTitle>How does it work?</SmallTitle>
          <GuideText>
            The PSLF program forgives the remaining balance on your federal
            Direct Loans after you&rsquo;ve made 120 qualifying monthly payments
            under a qualifying repayment plan while working full-time for a
            qualifying employer. We’ll go into more detail on what “qualifying”
            means for each of these elements below.
          </GuideText>
        </Section>

        <Section title="Eligibility">
          <GuideText>
            To be eligible for PSLF, you&rsquo;ll need to meet all of the
            following requirements:
          </GuideText>

          <SmallTitle>You have federal Direct loans.</SmallTitle>
          <GuideText>
            Only federal Direct Stafford Loans qualify. Federal Family Education
            Loans (FFEL), Perkins Loans, and Parent PLUS loans do not qualify
            unless you consolidate them into a Direct Consolidation Loan.
          </GuideText>

          <List>
            <ListItem>
              If you consolidate your FFEL and/or Perkins Loans into a new
              Direct Consolidation Loan, only qualifying payments that you make
              on the new Direct Consolidation Loan can be counted toward the 120
              payments that you need to be eligible for PSLF. Any payments you
              made on your FFEL or Perkins Loans before you consolidated them
              don’t count.
            </ListItem>
            <ListItem>
              If you&rsquo;re thinking of consolidating both Direct Loans and
              other types of federal student loans to take advantage of PSLF,
              the qualifying payments made on the Direct Loans included in your
              consolidation loan will be credited to your consolidation loan
              using a weighted average of those payments.
            </ListItem>
          </List>

          <SmallTitle>
            You&rsquo;re employed by a qualifying public service employer
          </SmallTitle>
          <List>
            <ListItem>
              Qualifying public service employers include the government of a
              tax-exempt 501(c)(3) non-profit.
            </ListItem>
            <ListItem>
              You must be working as a full-time employee which means working at
              least 30 hours a week.
            </ListItem>
            <ListItem>
              Serving as a full-time AmeriCorps or Peace Corps volunteer counts
              as qualifying employment for PSLF.
            </ListItem>
            <ListItem>
              <GuideText>
                If you work for one of these types of employers, you do not
                qualify for PSLF:
              </GuideText>
              <List>
                <ListItem>Labor unions</ListItem>
                <ListItem>Partisan political organizations</ListItem>
                <ListItem>
                  For-profit organizations (including for-profit government
                  contractors)
                </ListItem>
                <ListItem>
                  Non-profit organizations that aren&rsquo;t tax-exempt under
                  Section 501(c)(3) of the Internal Revenue Code and don&rsquo;t
                  provide a qualifying public service as their primary function
                </ListItem>
              </List>
            </ListItem>
          </List>

          <SmallTitle>
            You&rsquo;re enrolled in an income-driven repayment plan (or a
            10-year standard repayment plan that doesn&rsquo;t end in debt
            forgiveness).
          </SmallTitle>
          <List>
            <ListItem>
              <GuideText>
                There are pros and cons to enrolling in an income-driven
                repayment plan, so make sure you learn more about them in our{' '}
                <Link to="/guides/idr">IDR guide</Link> before enrolling. Here
                are the different kinds of income-drivenrepayment plans:
              </GuideText>
              <List>
                <ListItem>Save on a Valuable Education (SAVE)</ListItem>
                <ListItem>Pay As You Earn (PAYE)</ListItem>
                <ListItem>Income-Based Repayment (IBR)</ListItem>
                <ListItem>Income-Contingent Repayment (ICR)</ListItem>
              </List>
            </ListItem>
            <ListItem>
              Although a 10-year standard repayment plan is a qualifying plan,
              you will not receive forgiveness unless you&rsquo;re enrolled in
              an income-driven repayment plan. This is due to the fact that,
              under a 10-year repayment plan, you will have paid off your loan
              balance in 10 years. You should only stay in the 10-year standard
              repayment plan if you’re not sure whether you plan to work in the
              public or non-profit sector for 10 years but still want for your
              payments to count toward your 120 qualifying monthly payments.
            </ListItem>
          </List>

          <SmallTitle>You make 120 qualifying monthly payments.</SmallTitle>
          <List>
            <ListItem>
              <GuideText>
                A qualifying monthly payment is one you make:
              </GuideText>
              <List>
                <ListItem>after Oct. 1, 2007,</ListItem>
                <ListItem>
                  while employed full-time by a qualifying employer,
                </ListItem>
                <ListItem>
                  at any time during that month while under a qualifying
                  repayment plan, and
                </ListItem>
                <ListItem>
                  for the full amount due, as shown on your bill; or
                </ListItem>
                <ListItem>
                  when you are in one of the{' '}
                  <Link href={fsaPslfDefermentForbearanceLink}>
                    accepted types of deferments or forbearance
                  </Link>{' '}
                  at any time during that month.
                </ListItem>
              </List>
            </ListItem>

            <ListItem>
              Your 120 qualifying monthly payments don&rsquo;t have to be made
              consecutively. If there&rsquo;s a period of time when you&rsquo;re
              not working in the public sector, you won&rsquo;t lose credit for
              prior qualifying payments.
            </ListItem>

            <ListItem>
              In general, you can&rsquo;t qualify for PSLF faster by making
              larger payments. There are some exceptions, though: if
              you&rsquo;re a volunteer with AmeriCorps or the Peace Corps, you
              can use your Segal Education Award or Peace Corps transition
              payment to make a single “lump-sum” payment that may count for as
              many as 12 qualifying PSLF payments. If you have lump-sum payments
              made on your behalf under a student loan repayment program
              administered by the U.S. Department of Defense, you could also get
              credit for more than one qualifying PSLF payment.
            </ListItem>
          </List>
        </Section>

        <Section title="Advantages and Disadvantages">
          <GuideText>
            Now you know whether you&rsquo;re eligible for Public Service Loan
            Forgiveness, but is it right for you? Let&rsquo;s break down the
            benefits and drawbacks of the program.
          </GuideText>

          <SmallTitle>Advantages</SmallTitle>
          <GuideText>
            Your loans are forgiven. After 10 years of working at a qualified
            public sector employer, your loans are completely forgiven. If you
            plan to work in the public or non-profit sector for at least 10
            years, PSLF may be the cheapest way to take care of your loans. And
            the 10 years don&rsquo;t even have to be consecutive, so if you
            switch to the private sector or take a couple years off, you still
            qualify for PSLF.
          </GuideText>

          <SmallTitle>Disadvantages</SmallTitle>
          <GuideText>
            Relying on PSLF can limit your career options. To take advantage of
            PSLF, you&rsquo;ll have to work in the public sector for 10 years,
            which could keep you from going after different types of jobs.
            Luckily, the 10 years in the public sector don&rsquo;t need to be
            consecutive. You can still have your loans forgiven, as long as you
            make 120 monthly payments while you&rsquo;re working full-time at a
            qualifying employer in the public sector and you&rsquo;re working at
            a qualifying employer at the time the remaining balance on your loan
            is forgiven.
          </GuideText>

          <GuideText>
            The future of the program is uncertain. PSLF is a federal program,
            so which way the political winds blow may affect whether the program
            is still around when your loans need to be forgiven. Our best guess,
            however, is that it will still be around. While we like to think
            that major changes to programs like these will be made in phases so
            that no one enrolled in the programs is hurt, it&rsquo;s hard to
            know for certain if the program will be around to forgive your
            loans.
          </GuideText>
        </Section>

        <Section title="Apply">
          <SmallTitle>When to apply</SmallTitle>
          <GuideText>
            If you’re a recent graduate, it’s best to apply after you’ve made at
            least one payment on your loan. Otherwise, you can apply whenever
            works for you.
          </GuideText>

          <SmallTitle>How to apply</SmallTitle>
          <GuideText>
            To apply, just fill out and submit the employment certification form
            by <Link to="/pslf">using our tool</Link>.
          </GuideText>
          <List>
            <ListItem>
              Submitting the form is a way to check that you&rsquo;re actually
              eligible for PSLF. If you&rsquo;re not eligible, the Department of
              Education will let you know the reasons why.
            </ListItem>
            <ListItem>
              You need to submit this form each year and every time you switch
              employers to make sure your employment is verified and counted.
              It&rsquo;s a good idea to set a recurring calendar reminder to
              make sure you submit you the form every year.
            </ListItem>
          </List>

          <SmallTitle>Getting loan forgiveness</SmallTitle>
          <GuideText>
            After you make your 120th qualifying monthly payment, celebrate!
            After that, you&rsquo;ll need to submit a final{' '}
            <Link href={fsaPslfForgivenessFormLink}>PSLF application</Link> to
            have your loans forgiven.
          </GuideText>
          <List>
            <ListItem>
              Note that: you&rsquo;ll want to check the box indicating you
              believe you qualify for forgiveness now
            </ListItem>
            <ListItem>
              You&rsquo;ll have to be working for a qualifying employer in the
              public sector when you submit your application for forgiveness and
              at the time the remaining balance on your loan is forgiven.
            </ListItem>
            <ListItem>
              The IRS doesn&rsquo;t consider the amount forgiven under the PSLF
              program to be income, so you won&rsquo;t have to pay income tax on
              the amount of your loans forgiven.
            </ListItem>
          </List>
        </Section>
      </>
    );
  }
}

export default PSLFGuide;
