/* eslint-disable no-restricted-globals */
import { get } from 'lodash';
import { flow, set } from 'lodash/fp';

import {
  SET_CURRENT_IDR_FORM,
  SET_IDR_PATH_OPTIONS,
  CLEAR_IDR_PATH_OPTIONS,
  SET_IDR_SELECTED_PATH_ID,
} from 'constants/actionTypes';
import { checkIdrPathsPayload } from 'utils/idr';

export const initialState = {
  blockedReason: null,
  includesSpouse: null,
  currentPath: null,
  paths: [],
  pathsWithPSLF: [],
  selectedPathId: null,
};

const calculationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IDR_PATH_OPTIONS: {
      checkIdrPathsPayload(payload);

      const { blockedReason, currentPath, paths, pathsWithPSLF } = payload;

      if (blockedReason) {
        return { ...initialState, blockedReason };
      }

      return {
        ...state,
        blockedReason,
        currentPath,
        paths,
        pathsWithPSLF,
      };
    }

    case SET_CURRENT_IDR_FORM: {
      const selectedPathId = get(payload, 'selectedPath.id');
      return flow([
        set('selectedPathId', selectedPathId),
        // selected path can be undefined if user has not gotten to the review
        // paths step yet
        set('paths', payload.selectedPath ? [payload.selectedPath] : []),
      ])(state);
    }

    case SET_IDR_SELECTED_PATH_ID:
      return { ...state, selectedPathId: payload.selectedPathId };

    case CLEAR_IDR_PATH_OPTIONS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default calculationsReducer;
