import React from 'react';
import styled from 'styled-components';

import { FlexColumn, Icon, Close, COLORS } from '@summer/ui-components';

import { TabletSize } from 'constants/styleguide';

const Content = React.forwardRef((props, ref) => {
  return (
    <ContentContainer ref={ref} as="form">
      <IconContainer onClick={props.toggleModal}>
        <Icon
          SvgComponent={Close}
          fill={COLORS.medGrey}
          width={16}
          height={16}
        />
      </IconContainer>
      {props.children}
    </ContentContainer>
  );
});

const ContentContainer = styled(FlexColumn)`
  position: relative;
  height: 100%;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: ${TabletSize}) {
    height: auto;
    min-height: 100vh;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  z-index: 999;
`;

Content.displayName = 'Content';

export default Content;
