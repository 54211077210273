import React from 'react';
import styled from 'styled-components';

import {
  Header,
  FlexCenter,
  Text,
  SvgImage,
  SummerBasicPslf,
} from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { fsaPslfToolLink } from 'constants/externalLinks/fsa';
import { TabletSize } from 'constants/styleguide';

const PslfSubmitBasic = () => {
  return (
    <Container data-testid="pslf-submit-basic">
      <SummerBasicIcon SvgComponent={SummerBasicPslf} />
      <TextContainer>
        <InstructionsHeader h3>Instructions</InstructionsHeader>
        <Text paragraph>
          You can digitally sign and submit the employment certification form
          using the{' '}
          <Link underline inheritColor href={fsaPslfToolLink}>
            PSLF Help Tool
          </Link>{' '}
          at Federal Student Aid. You can complete this form for every public
          service employer where you&apos;ve worked since 2007 to make sure you
          get all the credit you&apos;re eligible for.
        </Text>
        <InstructionsList>
          <li>
            <Text paragraph>
              First, you&apos;ll complete the form through the digital tool
            </Text>
          </li>
          <li>
            <Text paragraph>
              Second, follow up with the employer contact you listed, and verify
              they&apos;ve received the &apos;Docusign&apos; link to complete
              the form digitally
            </Text>
          </li>
          <li>
            <Text paragraph>
              Finally, once your employer digitally signs their section of the
              form, it will be electronically submitted to the PSLF servicer for
              processing
            </Text>
          </li>
        </InstructionsList>
      </TextContainer>
    </Container>
  );
};

const Container = styled(FlexCenter)`
  padding: 30px 80px;
  margin: 40px 0;
  width: 100%;

  @media (max-width: ${TabletSize}) {
    padding: 0 16px;
    flex-direction: column;
    margin: 24px 0;
  }
`;

const InstructionsHeader = styled(Header)`
  font-size: 24px;
  margin-bottom: 12px;
`;

const TextContainer = styled.div`
  margin-left: 118px;

  @media (max-width: ${TabletSize}) {
    margin-top: 16px;
    margin-left: 0px;
  }
`;

const SummerBasicIcon = styled(SvgImage)`
  max-width: 300px;

  @media (max-width: ${TabletSize}) {
    max-width: 200px;
  }
`;

const InstructionsList = styled.ul`
  margin-left: 24px;

  > li {
    margin-top: 8px;
  }
`;

export default PslfSubmitBasic;
