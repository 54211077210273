/* eslint-disable react/display-name */
import { push as redirectTo } from 'connected-react-router';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ModalView from 'components/layout/ModalView';
import CalculationAnimation from 'components/pages/default/wizard/steps/CalculationAnimation';
import DefaultOptionsPage from 'components/pages/default/wizard/steps/DefaultOptionsPage';
import DefaultRecommendationsPage from 'components/pages/default/wizard/steps/DefaultRecommendationsPage';
import DefaultStartPage from 'components/pages/default/wizard/steps/DefaultStartPage';
import DefaultSyncPage from 'components/pages/default/wizard/steps/DefaultSyncPage';
import useTrackStepChanges from 'hooks/Wizard/useTrackStepChanges';
import { useWizard } from 'hooks/Wizard/useWizard';
import { renderWizardSteps } from 'hooks/Wizard/utils';
import { usePrevious } from 'hooks/common';
import { fetchLoans } from 'redux/actions/loans.actions';
import { fetchRecommendations } from 'redux/actions/recEngine.actions';
import { hasSyncedLoans } from 'redux/selectors/sync.selectors';

const DefaultWizard = ({ hasSyncedLoans, history }) => {
  const [userSyncedLoansInWizard, setSyncedLoansInWizard] = useState(false);
  const [shouldShowCalculationAnimation, setShouldShowCalculationAnimation] =
    useState(false);

  const skipStartPage = get(history, 'location.state.skipStartPage', false);

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(redirectTo('/plan'));
  };

  useEffect(
    function fetchUserData() {
      dispatch(fetchLoans());
      dispatch(fetchRecommendations());
    },
    [dispatch],
  );

  const config = useMemo(
    () => [
      {
        render: props => <DefaultStartPage {...props} />,
        track: 'Get Out of Default Start Page',
      },
      {
        render: props => <DefaultSyncPage {...props} />,
        condition: !hasSyncedLoans || userSyncedLoansInWizard,
        track: 'Get Out of Default Sync Page',
      },
      {
        render: props => <CalculationAnimation {...props} />,
        condition: shouldShowCalculationAnimation,
        track: false,
      },
      {
        render: props => (
          <DefaultRecommendationsPage
            {...props}
            skippedStartPage={skipStartPage}
          />
        ),
        condition: hasSyncedLoans,
        track: false,
      },
      {
        render: props => (
          <DefaultOptionsPage {...props} hasSyncedLoans={hasSyncedLoans} />
        ),
        track: false,
      },
    ],
    [
      hasSyncedLoans,
      userSyncedLoansInWizard,
      shouldShowCalculationAnimation,
      skipStartPage,
    ],
  );

  const {
    steps,
    navigation: { currentStep, moveToStep },
  } = useWizard(
    {
      config,
    },
    useTrackStepChanges,
  );

  useEffect(
    function skipToRecommendationStep() {
      if (skipStartPage) {
        const recommendationStep = 4;
        moveToStep(recommendationStep);
      }
    },
    [skipStartPage, moveToStep],
  );

  const userHadPreviouslySynced = usePrevious(hasSyncedLoans);

  useEffect(
    /* We need to track if a user syncs in the Wizard in order for the condition for step 2 to work
     * correctly.
     * This is because the sync page and the post-sync loan summary page are the same step.
     * If we don't track this the condition will become false after a user syncs while they are
     * still on step 2, which causes problems for the wizard navigation.
     */
    function trackIfUserSyncedInWizard() {
      if (currentStep === 2 && !userHadPreviouslySynced && hasSyncedLoans) {
        setSyncedLoansInWizard(true);
        setShouldShowCalculationAnimation(true);
      }
    },
    [currentStep, hasSyncedLoans, userHadPreviouslySynced],
  );

  useEffect(
    function determineIfShouldShowCalculationAnimation() {
      if (currentStep === 4) {
        setShouldShowCalculationAnimation(false);
      }
    },
    [currentStep, userSyncedLoansInWizard],
  );

  return (
    <ModalView
      handleClose={handleClose}
      showLogo={true}
      headerText="Let&rsquo;s get your loans out of default."
    >
      {renderWizardSteps(steps)}
    </ModalView>
  );
};
const mapStateToProps = state => ({
  hasSyncedLoans: hasSyncedLoans(state),
});

export default withRouter(connect(mapStateToProps)(DefaultWizard));
