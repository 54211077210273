import { isEmpty, isEqual, some } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { LoadingBars, COLORS } from '@summer/ui-components';

import Platform from 'components/hoc/Platform';
import BlockingPage from 'components/pages/idr/wizard/shared/BlockingPage';
import NewRYP from 'components/pages/idr/wizard/steps/ReviewYourPlan/NewRYP';
import {
  fetchIdrPathOptions,
  updateCurrentIdrForm,
} from 'redux/actions/idr.actions';
import { fetchAllLoans } from 'redux/actions/loans.actions';
import { getSpouseHasFsl } from 'redux/selectors/financialProfile.selectors';
import {
  getIdrCalculations,
  blockUserOnPlansStep,
  getCurrentIdrFormId,
} from 'redux/selectors/idr.selectors';
import {
  userNeedsToRecertifyIdr,
  userHasLoansInIdr,
} from 'redux/selectors/loans.selectors';
import {
  hasFederalPlaidLoans,
  shouldUsePlaidFlow,
} from 'redux/selectors/plaid.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { userWorksInPublicSector } from 'redux/selectors/user.selectors';
import { showPlaidInIdr } from 'services/featureFlags/flags';
import { isPathSelected } from 'utils/idr';

class ReviewYourPlan extends React.Component {
  state = {
    isPSLF: false,
  };

  componentDidMount() {
    this.props.fetchAllLoans(null, this.checkIfUserShouldSyncPlaid);

    if (this.props.values.selectedPathId) {
      this.props.idrHandlers.validateForm();
    }

    if (this.props.userWorksInPublicSector) {
      this.setState({ isPSLF: true });
    }

    this.checkIfSelectedPathIsValid();
  }

  onPSLFToggle = isPSLF => {
    this.setState({ isPSLF });
  };

  fetchIdrPathOptions() {
    const loanSource = this.props.shouldUsePlaidFlow ? 'plaid' : null;
    this.props.fetchIdrPathOptions(this.props.formId, loanSource);
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(
        prevProps.idrPathOptions.paths,
        this.props.idrPathOptions.paths,
      ) ||
      prevProps.userNeedsToRecertifyIdr !== this.props.userNeedsToRecertifyIdr
    ) {
      this.checkIfSelectedPathIsValid();
    }

    if (
      !prevProps.readyToFetchIdrPathOptions &&
      this.props.readyToFetchIdrPathOptions
    ) {
      this.fetchIdrPathOptions();
    }
  }

  checkIfSelectedPathIsValid = () => {
    // if a user's info or loans have changed such that they have a different recommendation
    // clear their selectedPath if it is no longer recommended

    const { selectedPathId } = this.props.idrPathOptions;
    const idrPaths = this.getIdrPaths();

    const selectedPathIsValid = some(idrPaths, path =>
      isPathSelected(path.id, selectedPathId),
    );

    if (!selectedPathIsValid) {
      setTimeout(() => {
        this.props.idrHandlers.setFieldValue(
          'calculations.selectedPathId',
          null,
        );
      }, 0);
    }
  };

  getIdrPaths = () => {
    const {
      idrPathOptions: { paths, pathsWithPSLF },
    } = this.props;

    return (this.state.isPSLF ? pathsWithPSLF : paths).filter(
      // display recommended paths
      path => path.recommended,
    );
  };

  checkIfUserShouldSyncPlaid = () => {
    if (showPlaidInIdr() && !this.props.hasPlaidFederalLoans) {
      // Move users who have started the text file upload flow
      // back to Your Loans step to sync with Plaid
      this.props.updateCurrentIdrForm({
        form: { formId: this.props.formId },
        currentStep: 2,
      });
    }
  };

  renderPlans = () => {
    const {
      idrPathOptions: { currentPath },
      idrHandlers: { handleSubmit, incrementSubmitCount },
      platformType,
    } = this.props;

    const pslf = {
      onPSLFToggle: this.onPSLFToggle,
      isPslf: this.state.isPSLF,
    };

    return (
      <NewRYP
        platformType={platformType}
        currentPath={currentPath}
        paths={this.getIdrPaths()}
        pslf={pslf}
        handleSubmit={handleSubmit}
        incrementSubmitCount={incrementSubmitCount}
      />
    );
  };

  render() {
    const {
      isLoading,
      idrPathOptions: { currentPath },
      userShouldBeBlocked,
    } = this.props;

    const idrPaths = this.getIdrPaths();

    if (isLoading) {
      return <LoadingBars color={COLORS.azure} />;
    }

    if (userShouldBeBlocked) {
      // This is to handle blocker cases sent back from Python calcs.
      return <BlockingPage step="Review Your Plan" />;
    }

    if (currentPath && !isEmpty(idrPaths)) {
      return this.renderPlans();
    }

    return <BlockingPage step="Review Your Plan" />;
  }
}

const mapStateToProps = state => ({
  idrPathOptions: getIdrCalculations(state),
  isLoading:
    isLoading(state, 'fetchIdrPathOptions') ||
    isLoading(state, 'fetchAllLoans'),
  spouseHasFsl: getSpouseHasFsl(state),
  userWorksInPublicSector: userWorksInPublicSector(state),
  userNeedsToRecertifyIdr: userNeedsToRecertifyIdr(state),
  userHasLoansInIdr: userHasLoansInIdr(state),
  userShouldBeBlocked: blockUserOnPlansStep(state),
  formId: getCurrentIdrFormId(state),
  readyToFetchIdrPathOptions:
    !isLoading(state, 'updateCurrentIdrForm') && // only after PUT /form/idr has completed
    !isLoading(state, 'fetchAllLoans'), // determining loanSource depends on having loan data
  hasPlaidFederalLoans: hasFederalPlaidLoans(state),
  shouldUsePlaidFlow: shouldUsePlaidFlow(state),
});

export default Platform(
  connect(mapStateToProps, {
    fetchIdrPathOptions,
    fetchAllLoans,
    updateCurrentIdrForm,
  })(ReviewYourPlan),
);
