import React from 'react';
import styled from 'styled-components';

import { Text } from '@summer/ui-components';

const StaticField = ({ label, value }) => (
  <StaticFieldContainer>
    <div>
      <Text bold>{label}</Text>
    </div>
    <Text>{value}</Text>
  </StaticFieldContainer>
);

const StaticFieldContainer = styled.div`
  & > div:first-child {
    margin-bottom: 8px;
  }

  & > div:last-child {
    margin-bottom: 25px;
  }
`;

export default StaticField;
