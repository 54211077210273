import React from 'react';

import { Link } from 'components/shared/typography';
import {
  fsaIdrPlansLink,
  fsaAnnouncementSaveLink,
} from 'constants/externalLinks/fsa';

const RepayeTooltip = () => (
  <>
    The REPAYE plan bases your monthly payment on 10% of your discretionary
    income. You will pay for no more than 20 years if you only have
    undergraduate loans and 25 years if you have any graduate loans. Payments
    made under a standard plan will count for this plan and your repayment
    timeline would be less than the maximum 20/25 years.{' '}
    <Link href={fsaIdrPlansLink}>Learn more</Link>
  </>
);

const PayeTooltip = () => (
  <>
    The PAYE plan bases your monthly payment on 10% of your discretionary income
    but never more than the 10-year standard repayment plan amount. You will pay
    for no more than 20 years. Payments already made under a standard plan will
    count towards this plan and your repayment timeline would be less than the
    maximum 20 years. <Link href={fsaIdrPlansLink}>Learn more</Link>
  </>
);

const IbrTooltip = () => (
  <>
    The Old IBR plan bases your monthly payment on 15% of your discretionary
    income but never more than the 10-year standard repayment plan amount. You
    will pay for no more than 25 years. Payments already made under a standard
    plan will count towards this plan and your repayment timeline would be less
    than the maximum 25 years. <Link href={fsaIdrPlansLink}>Learn more</Link>
  </>
);

const IbrTooltipNew = () => (
  <>
    The New IBR plan bases your monthly payment on 10% of your discretionary
    income but never more than the 10-year standard repayment plan amount. You
    will pay for no more than 20 years. Payments already made under a standard
    plan will count towards this plan and your repayment timeline would be less
    than the maximum 20 years. <Link href={fsaIdrPlansLink}>Learn more</Link>
  </>
);

const IcrTooltip = () => (
  <>
    The ICR plan bases your monthly payment on 20% of your discretionary income
    but never more than a 12-year repayment plan amount. You will pay for no
    more than 25 years. Payments already made under a standard 10 year plan will
    count towards this plan and your repayment timeline would be less than the
    maximum 25 years. <Link href={fsaIdrPlansLink}>Learn more</Link>
  </>
);

const IsrTooltip = () => (
  <>
    The income-sensitive repayment plan bases your monthly payment on your
    annual income and is calculated so that your loans are paid in full within
    15 years. The formula for determining the monthly payment amount can vary
    from lender to lender. <Link href={fsaIdrPlansLink}>Learn more</Link>
  </>
);

const SaveTooltip = () => (
  <>
    The Saving on a Valuable Education (SAVE) Plan replaces the existing Revised
    Pay As You Earn (REPAYE) Plan. Borrowers on the REPAYE Plan will
    automatically get the benefits of the new SAVE Plan. The SAVE Plan, like
    other income-driven repayment (IDR) plans, calculates your monthly payment
    amount based on your income and family size. The SAVE Plan provides the
    lowest monthly payments of any IDR plan available to nearly all student
    borrowers. <Link href={fsaAnnouncementSaveLink}>Learn more</Link>
  </>
);

export const IDR_APPLICATION_TYPES = {
  RECERTIFY: 'recertify',
  RECALCULATE: 'recalculate',
  SWITCH: 'switch',
  NEW: 'new',
};

export const REPAYMENT_PLAN_TYPES = {
  STANDARD: 'STANDARD',
  IBR: 'IBR',
  ICR: 'ICR',
  PAYE: 'PAYE',
  REPAYE: 'REPAYE',
  SAVE: 'SAVE',
  IBR_NEW_BORROWER: 'IBR New Borrower',
  ISR: 'ISR',
  EXTENDED: 'EXTENDED',
  GRADUATED: 'GRADUATED',
  EXTENDED_GRADUATED: 'EXTENDED_GRADUATED',
  ALTERNATIVE_FIXED_TERM: 'ALTERNATIVE_FIXED_TERM',
};

const {
  STANDARD,
  IBR,
  ICR,
  PAYE,
  REPAYE,
  SAVE,
  IBR_NEW_BORROWER,
  ISR,
  EXTENDED,
  GRADUATED,
  EXTENDED_GRADUATED,
  ALTERNATIVE_FIXED_TERM,
} = REPAYMENT_PLAN_TYPES;

export const rypDisplayPlanTypes = {
  [STANDARD]: {
    title: 'Standard Plan',
    tooltipText:
      'In Standard plan, you’ll make equal monthly payments for the length of your repayment period. The repayment period can range from 10 years to up to 30 years for consolidated loans.',
  },
  [REPAYE]: {
    title: 'Revised Pay As You Earn Plan',
    tooltipText: <RepayeTooltip />,
  },
  [SAVE]: {
    title: 'SAVE Plan',
    tooltipText: <SaveTooltip />,
  },
  [PAYE]: {
    title: 'Pay As You Earn Plan',
    tooltipText: <PayeTooltip />,
  },
  [IBR]: {
    title: 'Income-Based Repayment Plan',
    tooltipText: <IbrTooltip />,
  },
  [IBR_NEW_BORROWER]: {
    title: 'IBR New Borrower Plan',
    tooltipText: <IbrTooltipNew />,
  },
  [ICR]: {
    title: 'Income-Contingent Repayment Plan',
    tooltipText: <IcrTooltip />,
  },
  [ISR]: {
    title: 'Income Sensitive Repayment Plan',
    tooltipText: <IsrTooltip />,
  },
  [EXTENDED]: {
    title: 'Extended Plan',
    tooltipText:
      'The extended repayment plan allows you to repay your loans over an extended period of time. Payments are made for up to 25 years.',
  },
  [GRADUATED]: {
    title: 'Graduated Plan',
    tooltipText:
      'The graduated repayment plan starts with lower payments that increase every two years. Payments are made for up to 10 years (between 10 and 30 years for consolidation loans).',
  },
  [EXTENDED_GRADUATED]: {
    title: 'Extended Graduated Plan',
    tooltipText:
      'The extended-graduated repayment plan starts with lower payments that increase every two years. Payments are made for up to 25 years.',
  },
  [ALTERNATIVE_FIXED_TERM]: {
    title: 'Alternative Fixed Term Plan',
    tooltipText:
      'An alternative fixed term repayment plan has the same payment over a number of years that are determined by your servicer.',
  },
};
