import React from 'react';
import styled from 'styled-components';

import InputContainer from '../Input';

import { COLORS } from '../../../helpers/constants/styleguide';

const TextAreaInput = ({
  placeholder = '',
  onChange,
  value,
  name,
  maxLength,
  height = '80px',
  disabled,
  validate,
  autoComplete,
  readOnly,
  noFormik,
  onKeyPress,
  error,
  touched,
  isSubmitting,
  label,
  helpMessage,
  onBlur = () => {},
}) => {
  const renderInputText = handleFocusChange => (
    <Input
      id={`${name}-input`}
      as={noFormik && 'input'}
      inputMode="textarea"
      type="textarea"
      placeholder={placeholder}
      name={name}
      height={height}
      validate={validate}
      maxLength={maxLength}
      value={value}
      onChange={onChange}
      onFocus={handleFocusChange}
      disabled={disabled}
      autoComplete={autoComplete}
      readOnly={readOnly}
      onKeyPress={onKeyPress}
      onBlur={e => {
        handleFocusChange();
        onBlur(e);
      }}
    />
  );

  return (
    <InputContainer
      id={name}
      error={error}
      touched={touched}
      height={height}
      isSubmitting={isSubmitting}
      label={label}
      helpMessage={helpMessage}
    >
      {renderInputText}
    </InputContainer>
  );
};

const Input = styled.textarea`
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 300;
  vertical-align: top;
  text-wrap: wrap;
  background-color: transparent;
  border: none;
  caret-color: ${COLORS.darkerGrey};
  color: ${COLORS.darkerGrey};
  padding: 16px;

  ${({ resize }) => `
  resize: ${resize ?? 'none'};
  `}

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${COLORS.medGrey};
    opacity: 1; /* Firefox */
  }
`;

export default TextAreaInput;
