import { push as redirectTo } from 'connected-react-router';
import { Formik, useFormikContext } from 'formik';
import { sum, omit } from 'lodash';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { COLORS, MoneyInput, Header, Text } from '@summer/ui-components';

import FormDrawersContainer from 'components/common/FormDrawersContainer';
import WizardContent from 'components/layout/wizard/WizardContent';
import WizardFooter from 'components/layout/wizard/WizardFooter';
import { Row, Spacer } from 'components/pages/idr/wizard/shared/styles';
import { InputWrapper } from 'components/pages/idr/wizard/steps/Submit/shared/Inputs';
import { MobileSize } from 'constants/styleguide';
import {
  setEmergencySavings,
  postEmergencySavingsV1,
} from 'redux/actions/emergencySavings.actions';
import { getEmergencySavings } from 'redux/selectors/emergencySavings.selectors';
import {
  getIsEmergencySavingsV1Enabled,
  getIsEmergencySavingsDemoEnabled,
} from 'redux/selectors/flags.selectors';
import { customFormValidation } from 'schemas/emergencySavings';

const WrappedMoneyInput = ({ name, label }) => {
  const {
    values,
    errors,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    touched,
    isSubmitting,
  } = useFormikContext();

  return (
    <InputWrapper>
      <MoneyInput
        label={label}
        name={name}
        touched={touched[name]}
        isSubmitting={isSubmitting}
        error={errors[name]}
        value={values[name]}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        onBlur={handleBlur}
      />
    </InputWrapper>
  );
};

const CurrentSituationForm = ({ showEmergencySavingsDemo }) => {
  return (
    <>
      <Row>
        <WrappedMoneyInput
          name="housingExpense"
          label="Monthly rent or mortgage"
        />
        <WrappedMoneyInput
          name="utilityExpense"
          label="Monthly utility payments"
        />
      </Row>
      <Row>
        <WrappedMoneyInput
          name="insuranceExpense"
          label="Monthly insurance payments"
        />
        <WrappedMoneyInput
          name="transportationExpense"
          label="Monthly transportation costs"
        />
      </Row>
      <Row>
        <WrappedMoneyInput name="debtExpense" label="Monthly debt payments" />
        <WrappedMoneyInput
          name="groceryExpense"
          label="Monthly grocery expenses"
        />
      </Row>
      {showEmergencySavingsDemo && <CurrentSituationFormDemoFields />}
    </>
  );
};

const CurrentSituationFormDemoFields = () => (
  <>
    <Text paragraph>
      Based on this information, we recommend that you save <Big>6 months</Big>{' '}
      worth of expenses in your emergency fund.
    </Text>
    <Divider />
    <Row>
      <WrappedMoneyInput
        name="savings"
        label="How much do you currently have in savings?"
      />
      <WrappedMoneyInput
        name="salary"
        label="What is your current annual salary?"
      />
    </Row>
    <Row>
      <WrappedMoneyInput
        name="savingsPerMonth"
        label="How much do you currently save per month?"
      />
      <InputWrapper>
        <Spacer />
      </InputWrapper>
    </Row>
  </>
);

const CurrentSituation = ({ navigation }) => {
  const dispatch = useDispatch();

  const showEmergencySavingsV1 = useSelector(getIsEmergencySavingsV1Enabled);
  const showEmergencySavingsDemo = useSelector(
    getIsEmergencySavingsDemoEnabled,
  );
  const emergencySavingsData = useSelector(getEmergencySavings);
  const {
    housingExpense,
    utilityExpense,
    insuranceExpense,
    transportationExpense,
    debtExpense,
    groceryExpense,
    // Note: Below values are for demo use only
    savings,
    savingsPerMonth,
    salary,
  } = emergencySavingsData;

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      const savingsTarget = sum(Object.values(values).filter(Number)) * 6;

      if (showEmergencySavingsV1) {
        replaceEmptyStringsWithNull(values);
        const demoFields = ['savings', 'savingsPerMonth', 'salary'];
        const filteredValues = omit(values, demoFields);

        dispatch(setEmergencySavings({ ...filteredValues, savingsTarget }));

        const postEmergencySavingsPayload = {
          ...filteredValues,
          savingsTarget,
        };

        dispatch(
          postEmergencySavingsV1(postEmergencySavingsPayload, {
            onSuccess: () => dispatch(redirectTo('/emergency-savings')),
            onFailure: () => setSubmitting(false),
          }),
        );
      } else if (showEmergencySavingsDemo) {
        // Note: Demo does not post data to the product server
        dispatch(setEmergencySavings({ ...values, savingsTarget }));
        dispatch(redirectTo('/emergency-savings'));
      }
    },
    [dispatch, showEmergencySavingsV1, showEmergencySavingsDemo],
  );

  const replaceEmptyStringsWithNull = obj => {
    Object.entries(obj).forEach(([key, value]) => {
      if (value === '') {
        // eslint-disable-next-line no-param-reassign
        obj[key] = null;
      }
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        housingExpense,
        utilityExpense,
        insuranceExpense,
        transportationExpense,
        debtExpense,
        groceryExpense,
        savings,
        salary,
        savingsPerMonth,
      }}
      onSubmit={handleSubmit}
      validate={customFormValidation}
    >
      {formik => {
        const { handleSubmit, isValid, isSubmitting } = formik;
        return (
          <WizardContent navigation={navigation}>
            <Form onSubmit={handleSubmit}>
              <FormDrawersContainer>
                <FormContainer>
                  <HeaderContainer>
                    <Header as="h3">Expense Information</Header>
                    <Text paragraph>
                      Input the amount spent on each of the items listed below
                    </Text>
                  </HeaderContainer>
                  <CurrentSituationForm
                    formik={formik}
                    showEmergencySavingsDemo={showEmergencySavingsDemo}
                  />
                </FormContainer>
              </FormDrawersContainer>
            </Form>
            <WizardFooter
              isNextFakeDisabled={!isValid}
              isNextDisabled={!isValid}
              isNextLoading={isSubmitting}
              isValid={isValid}
              handleSaveContinue={handleSubmit}
              isThisLastStep
            />
          </WizardContent>
        );
      }}
    </Formik>
  );
};

const Form = styled.form`
  max-width: 1024px;
  border-radius: 3px;
  padding: 32px;
  margin: auto;

  @media (max-width: ${MobileSize}) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const HeaderContainer = styled.div`
  margin-bottom: 32px;
`;

const FormContainer = styled.div`
  box-shadow: 0 0 4px 0 $dfe3e8;
  background-color: ${COLORS.lightestGrey};
  border: 1px solid ${COLORS.lighterGrey};
  padding: 24px;
`;

const Divider = styled.div`
  width: 100%;
  border: solid 1px ${COLORS.grey};
  margin-top: 48px;
  margin-bottom: 48px;
`;

const Big = styled.b`
  font-size: 22px;
`;

export default CurrentSituation;
