import { isNil } from 'lodash';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  FancyCard,
  FlexRow,
  Header,
  Text,
  Icon,
  Button,
  CheckNew,
  COLORS,
} from '@summer/ui-components';

import Link from 'components/shared/typography/Link';
import { MobileSize } from 'constants/styleguide';
import { usePlatform } from 'hooks/platform';
import { createIdrForm, moveToIdrCurrentStep } from 'redux/actions/idr.actions';
import { getCurrentIdrFormStep } from 'redux/selectors/idr.selectors';
import { getPslfRecommendation } from 'redux/selectors/recEngine.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { hexToRgba } from 'utils/common';

const IdrStartViewCard = ({
  estIdrMonthlyPayment,
  idrProgress = 0,
  showConfirmIdrPlan,
  currentStep,
  isLoading,
  isRecommendedPslf,
}) => {
  const dispatch = useDispatch();
  const { isMobile } = usePlatform();

  const handleStartIdr = () => {
    if (isNil(currentStep)) {
      dispatch(createIdrForm({ currentStep: '1' }));
    } else {
      dispatch(moveToIdrCurrentStep(currentStep));
    }
  };

  const headerText = () => {
    if (showConfirmIdrPlan) {
      return `Let’s confirm you’re on the plan with the lowest monthly payment`;
    }
    if (isRecommendedPslf) {
      return `Lower your monthly payments to get Public Service Loan Forgiveness`;
    }
    return `Let’s lower your monthly payment`;
  };

  const bodyText = () => {
    if (showConfirmIdrPlan) {
      return `We’ll double-check that you’re on the best plan for your situation. If you decide to switch to another IDR plan, we’ll help you enroll in a few steps:`;
    }
    if (isRecommendedPslf) {
      if (isMobile) {
        return (
          <>
            We’ll help you enroll in an{' '}
            <Link
              to={{ pathname: '/guides/idr', state: { goBack: true } }}
              underline
              inheritColor
              inheritWeight
            >
              income-driven repayment plan{' '}
            </Link>
            (IDR) in just a few steps:
          </>
        );
      }
      return `We’ll help you enroll in just a few steps:`;
    }
    return `We’ll help you enroll in an income-driven repayment plan (IDR) in just a few steps:`;
  };

  const lastParagraphText = () => {
    if (isRecommendedPslf && !showConfirmIdrPlan && !isMobile) {
      return `Consolidation is part of this application if needed. You can switch plans at any time if your situation changes.`;
    }
    return `Remember you can switch repayment plans at any time.`;
  };

  return (
    <Container>
      <FancyCard
        color="magenta"
        CustomLeftHeader={() => <CustomLeftHeader isMobile={isMobile} />}
        headerBorderWidth={idrProgress}
        headerInfo={
          !isNil(estIdrMonthlyPayment)
            ? [
                {
                  title: <>Est. monthly payment:</>,
                  value: estIdrMonthlyPayment,
                },
              ]
            : []
        }
      >
        <>
          <CardContent>
            <CardContentLeft>
              <img alt="IDR" src="/images/idr/IdrStartViewImg.png" />
            </CardContentLeft>
            <CardContentRight>
              <Header h2>{headerText()}</Header>
              <Text paragraph>{bodyText()}</Text>
              <List>
                <li>
                  <CheckmarkIcon />
                  <Text paragraph>Tell us about your situation</Text>
                </li>
                <li>
                  <CheckmarkIcon />
                  <Text paragraph>Confirm your loan information</Text>
                </li>
                <li>
                  <CheckmarkIcon />
                  <Text paragraph>Compare IDR plans and monthly payments</Text>
                </li>
                <li>
                  <CheckmarkIcon />
                  <Text paragraph>Submit your application online</Text>
                </li>
              </List>
              <Text paragraph>{lastParagraphText()}</Text>
            </CardContentRight>
          </CardContent>
          <ButtonContainer>
            <Button width={136} onClick={handleStartIdr} isLoading={isLoading}>
              Continue
            </Button>
          </ButtonContainer>
        </>
      </FancyCard>
    </Container>
  );
};

const CheckmarkIcon = () => (
  <Icon
    width={13}
    SvgComponent={CheckNew}
    fill={hexToRgba(COLORS.darkGreen, 0.6)}
  />
);

const CustomLeftHeader = ({ isMobile }) => {
  return (
    <LeftHeaderContainer>
      <Text as="span" size="extraSmall" paragraph>
        Est. time to complete: {isMobile && <br />} 5 – 10 min.
      </Text>
    </LeftHeaderContainer>
  );
};

const LeftHeaderContainer = styled.div`
  display: flex;
  text-align: left;

  @media (max-width: ${MobileSize}) {
    max-width: 104px;
    margin-right: 24px;
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 1;
`;

const CardContent = styled(FlexRow)`
  text-align: left;
  justify-content: center;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
  }
`;

const CardContentRight = styled.div`
  max-width: 448px;
  & > * {
    margin-bottom: 16px;
  }

  & button {
    margin-left: auto;
  }
`;

const CardContentLeft = styled.div`
  align-self: center;
  margin-right: 40px;

  img {
    width: 100%;
  }

  @media (max-width: ${MobileSize}) {
    margin-right: 0;
    margin-bottom: 24px;
  }
`;

const List = styled.ul`
  list-style-type: none;

  & li {
    display: flex;
    margin-bottom: 8px;
    align-items: baseline;

    :last-child {
      margin-bottom: 0;
    }

    .text {
      display: inline;
    }

    .icon {
      display: inline;
      margin-right: 16px;
    }
  }
`;

const ButtonContainer = styled.div`
  text-align: right;
`;

const mapStateToProps = state => ({
  currentStep: getCurrentIdrFormStep(state),
  isRecommendedPslf: getPslfRecommendation(state),
  isLoading:
    isLoading(state, 'createIdrForm') || isLoading(state, 'fetchIdrForms'),
});

export default connect(mapStateToProps, {
  createIdrForm,
  moveToIdrCurrentStep,
})(IdrStartViewCard);
