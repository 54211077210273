import { SET_PLAID_INTEGRATIONS } from 'constants/actionTypes';

const initialState = [];

const plaidIntegrationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PLAID_INTEGRATIONS: {
      return payload;
    }

    default:
      return state;
  }
};

export default plaidIntegrationsReducer;
