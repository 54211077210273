import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { PercentageInput } from '@summer/ui-components';

import StaticField from 'components/common/ManageLoans/fields/shared/StaticField';
import InfoTooltip from 'components/shared/InfoTooltip';
import { Link } from 'components/shared/typography';
import { interestRateHelpArticleUrl } from 'constants/externalLinks/support';
import { isLoanFieldEditable } from 'redux/selectors/loans.selectors';
import { percentage } from 'utils/numbers';

const InterestRateHelpLink = () => (
  <>
    Not sure?{' '}
    <Link inheritColor underline href={interestRateHelpArticleUrl}>
      Check here
    </Link>
    .
  </>
);

const InterestRateHelperText = () => (
  <>
    Please enter your interest rate. <br />
    <InterestRateHelpLink />
  </>
);

export const InterestRateErrorText = ({ greaterThan0 = false }) => (
  <>
    Enter an interest rate{greaterThan0 && ' greater than 0%'}. <br />
    <InterestRateHelpLink />
  </>
);

const InterestRateLabel = () => (
  <>
    <span>Interest Rate</span>
    <InfoTooltip
      text="Interest rate determines how much extra you have to pay back on top of the money you borrowed. The higher the interest rate, the more expensive your loan."
      top={2}
    />
  </>
);

class InterestRate extends React.Component {
  componentWillUnmount() {
    if (this.props.canEdit) {
      this.props.formik.setFieldValue('interestRate', null);
    }
  }

  render() {
    const {
      canEdit,
      formik: {
        values,
        errors,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        touched,
        isSubmitting,
      },
    } = this.props;

    return (
      <Container id="interestRate">
        <InputContainer>
          {canEdit ? (
            <PercentageInput
              height={40}
              name="interestRate"
              value={values.interestRate}
              error={errors.interestRate}
              touched={touched.interestRate}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              onBlur={handleBlur}
              isSubmitting={isSubmitting}
              label={<InterestRateLabel />}
              helpMessage={<InterestRateHelperText />}
            />
          ) : (
            <StaticField
              label={<InterestRateLabel />}
              value={percentage(values.interestRate)}
            />
          )}
        </InputContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  flex: 1;
`;

const InputContainer = styled.div`
  div > div:nth-child(2) {
    max-width: 145px;
  }
`;

const mapStateToProps = (state, props) => {
  const loanId = props.formik.values.id;
  return { canEdit: isLoanFieldEditable(state, loanId, 'interestRate') };
};

export default connect(mapStateToProps)(InterestRate);
