import { Formik } from 'formik';
import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { RadioButtons } from '@summer/ui-components';

import { usePlatform } from 'hooks/platform';
import { updateCurrentIdrForm } from 'redux/actions/idr.actions';
import { toggleIdrQuestionnaire } from 'redux/actions/ui.actions';
import { getConsolidationIncludedParentPlus } from 'redux/selectors/financialProfile.selectors';
import { getCurrentIdrFormId } from 'redux/selectors/idr.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { consolidationIncludedParentPlusSchema } from 'schemas/idr';

import Footer from '../Footer';
import { FormContents, Label, HelperText } from '../shared';

const ParentPlus = ({
  formId,
  consolidationIncludedParentPlus,
  isLoading,
  navigation,
  updateCurrentIdrForm,
}) => {
  const { isMobile } = usePlatform();
  const dispatch = useDispatch();

  const onSuccess = () => dispatch(toggleIdrQuestionnaire(false));

  const handleSave = ({ consolidationIncludedParentPlus }) => {
    // Update financial profile
    updateCurrentIdrForm(
      {
        currentStep: 3, // Set step to Review Your Plans
        form: { formId },
        financialProfile: { consolidationIncludedParentPlus },
      },
      {
        close: false,
        finish: false,
        onSuccess,
      },
    );
  };

  return (
    <Formik
      initialValues={{ consolidationIncludedParentPlus }}
      onSubmit={handleSave}
      validationSchema={consolidationIncludedParentPlusSchema}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        isValid,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormContents>
              <Label
                h2
                align={isMobile ? 'center' : 'left'}
                id="consolidationIncludedParentPlus"
              >
                Did any of your consolidated loans include Parent Plus loans?
              </Label>
              <HelperText>
                Parent Plus loans are taken out by a parent or guardian.
              </HelperText>
              <RadioButtons
                name="consolidationIncludedParentPlus"
                value={values.consolidationIncludedParentPlus}
                error={errors?.consolidationIncludedParentPlus}
                touched={touched.consolidationIncludedParentPlus}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                numberOfCol={2}
                isSubmitting={isSubmitting}
                onBlur={handleBlur}
                options={[
                  { value: 'true', label: 'Yes' },
                  { value: 'false', label: 'No' },
                ]}
              />
            </FormContents>
            <Footer
              handleBack={navigation.back}
              isBackLoading={isLoading}
              isNextDisabled={!isValid}
              isNextLoading={isLoading}
              isValid={isValid}
            />
          </form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => ({
  formId: getCurrentIdrFormId(state),
  consolidationIncludedParentPlus:
    getConsolidationIncludedParentPlus(state).toString(),
  isLoading: isLoading(state, 'updateCurrentIdrForm'),
});

export default connect(mapStateToProps, { updateCurrentIdrForm })(ParentPlus);
