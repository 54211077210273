/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { VerticalNav, COLORS } from '@summer/ui-components';

import PaywallPslf from 'components/pages/paywall/pslf/PaywallPslf';
import { Placeholder } from 'components/pages/pslf/utils';
import Content from 'components/pages/pslf/wizard/layout/PslfWizardContent';
import Footer from 'components/pages/pslf/wizard/layout/PslfWizardFooter';
import { hideSubmitSidebarSize } from 'constants/pslf';
import useTrackStepChanges from 'hooks/Wizard/useTrackStepChanges';
import { useWizard } from 'hooks/Wizard/useWizard';
import { renderWizardSteps } from 'hooks/Wizard/utils';
import { getIsSummerBasicEnabled } from 'redux/selectors/flags.selectors';
import { showPaywall } from 'redux/selectors/paywall.selectors';

import EmployerInformation from './EmployerInformation';
import PslfSummerBasicSubmitPage from './PslfSummerBasicSubmitPage';
import YourContactInfo from './YourContactInfo';

const SubmitWizard = ({ navigation: parentNavigation, parentProgress }) => {
  const { push: redirectTo } = useHistory();

  const shouldShowPaywall = useSelector(showPaywall);
  const showSummerBasic = useSelector(getIsSummerBasicEnabled);

  const config = useMemo(
    () => [
      {
        title: 'Your contact info',
        render: props => <YourContactInfo {...props} />,
      },
      {
        title: 'Your employer info',
        render: props => <EmployerInformation {...props} />,
      },
      {
        title: 'Submit the application forms',
        render: props => <Placeholder {...props} />,
      },
    ],
    [],
  );

  const { steps, headers, navigation } = useWizard(
    {
      config,
      parentNavigation,
      parentProgress,
    },
    useTrackStepChanges,
  );

  const currentStepIndex = navigation.currentStep - 1;

  if (shouldShowPaywall) {
    return <PaywallPslf wizardNavigation={navigation} />;
  }

  if (showSummerBasic) {
    return (
      <Content navigation={navigation} backgroundColor={COLORS.lighterGrey}>
        <PslfSummerBasicSubmitPage />
        <Footer
          handleBack={navigation.back}
          isValid={true}
          handleSaveContinue={() => {
            redirectTo('/plan');
          }}
          isThisLastStep
        />
      </Content>
    );
  }

  return (
    <>
      <SidebarContainer>
        <VerticalNav menuItems={headers} activeIndex={currentStepIndex} />
      </SidebarContainer>
      {renderWizardSteps(steps)}
    </>
  );
};

const SidebarContainer = styled.div`
  position: absolute;
  top: 148px;
  left: 32px;
  z-index: 1;

  @media (max-width: ${hideSubmitSidebarSize}) {
    display: none;
  }
`;

export default SubmitWizard;
