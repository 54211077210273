import { pick, omit, values } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Accordion } from '@summer/ui-components';

import { idrFaqConfig } from 'components/pages/idr/dashboard/shared/faqConfig';
import { usePlatform } from 'hooks/platform';
import { getIdrRecommendation } from 'redux/selectors/recEngine.selectors';

const getConfig = isIdrRecommended => {
  let config = pick(idrFaqConfig, [
    'recommended',
    'paymentUpdate',
    'whatElse',
    'afterFormSubmit',
  ]);

  if (!isIdrRecommended) {
    config = omit(config, ['recommended']);
  }

  return values(config);
};

const FAQ = ({ isIdrRecommended }) => {
  const { isMobile } = usePlatform();

  return (
    <AccordionContainer>
      <Accordion
        config={getConfig(isIdrRecommended)}
        align="left"
        numColumns={isMobile ? 1 : 2}
      />
    </AccordionContainer>
  );
};

const AccordionContainer = styled.div`
  text-align: left;
  margin-top: 24px;
`;

const mapStateToProps = state => ({
  isIdrRecommended: getIdrRecommendation(state),
});

export default connect(mapStateToProps)(FAQ);
