import { toString } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { SelectInput } from '@summer/ui-components';

import StaticField from 'components/common/ManageLoans/fields/shared/StaticField';
import Placeholder from 'components/common/ManageLoans/shared/Placeholder';
import InfoTooltip from 'components/shared/InfoTooltip';
import {
  getRepaymentPlanTypeNameById,
  getRepaymentPlanTypeOptions,
  getCurrentLoan,
  isLoanFieldEditable,
} from 'redux/selectors/loans.selectors';

const RepaymentPlanTypeLabel = () => (
  <>
    <span>Repayment Plan</span>
    <InfoTooltip
      text={
        <>
          Repayment Plan determines how much and for how long you have to pay
          back your loans. All borrowers are automatically enrolled in the
          &ldquo;Standard Plan&rdquo; which means that you have to pay back your
          loans in 10 years.
          <br />
          <br />
          Income-driven repayment plans such as REPAYE, PAYE, IBR, and ICR tie
          your monthly payment to your income which can help make your payments
          more manageable.
        </>
      }
      top={2}
    />
  </>
);

class RepaymentPlanType extends React.Component {
  componentWillUnmount() {
    if (this.props.canEdit) {
      this.props.formik.setFieldValue('repaymentPlanTypeId', null);
    }
  }

  render() {
    const {
      formik: {
        values,
        errors,
        setFieldValue,
        handleBlur,
        touched,
        isSubmitting,
      },
      canEdit,
      selectedRepaymentPlanTypeName,
      repaymentPlanTypeOptions,
    } = this.props;

    return (
      <Container id="repaymentPlanTypeId">
        <SelectContainer>
          {canEdit ? (
            <SelectInput
              name="repaymentPlanTypeId"
              options={repaymentPlanTypeOptions}
              value={toString(values.repaymentPlanTypeId)}
              error={errors.repaymentPlanTypeId}
              touched={touched.repaymentPlanTypeId}
              isSubmitting={isSubmitting}
              onChange={setFieldValue}
              onBlur={handleBlur}
              placeholder=""
              label={<RepaymentPlanTypeLabel />}
              helpMessage='If you have never changed your repayment plan, select "Standard"'
              menuPlacement="top"
            />
          ) : (
            <StaticField
              label={<RepaymentPlanTypeLabel />}
              value={selectedRepaymentPlanTypeName}
            />
          )}
        </SelectContainer>
        <Placeholder />
      </Container>
    );
  }
}

const Container = styled.div`
  flex: 1;
  width: 100%;
`;

const SelectContainer = styled.div`
  width: 100%;
`;

const mapStateToProps = (state, props) => {
  const loanId = props.formik.values.id;
  const { repaymentPlanTypeId } = props.formik.values;

  return {
    loan: getCurrentLoan(state),
    canEdit: isLoanFieldEditable(state, loanId, 'repaymentPlanTypeId'),
    repaymentPlanTypeOptions: getRepaymentPlanTypeOptions(state),
    selectedRepaymentPlanTypeName: getRepaymentPlanTypeNameById(
      state,
      repaymentPlanTypeId,
    ),
  };
};

export default connect(mapStateToProps)(RepaymentPlanType);
