import { Formik } from 'formik';
import { isNil } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Content from 'components/pages/pslf/wizard/layout/PslfWizardContent';
import Footer from 'components/pages/pslf/wizard/layout/PslfWizardFooter';
import { useOnSuccess } from 'hooks/pslf';
import { updateCurrentPslfForm } from 'redux/actions/pslf.actions';
import { getUserCurrentPslfForm } from 'redux/selectors/pslf.selectors';
import { employerHoursSchema } from 'schemas/pslf';
import { track } from 'utils/track';

import HoursPerWeekQuestion from './HoursPerWeekQuestion';
import MultipleEmployersQuestion from './MultipleEmployersQuestion';
import {
  QualifiesFullTimeAlert,
  QualifiesPartTimeAlert,
  DoesNotQualifyAlert,
} from './alerts';

const EmployerHours = ({
  currentPslfForm,
  updateCurrentPslfForm,
  navigation,
}) => {
  const { formId, employerHoursPerWeek, multipleEmployers } = currentPslfForm;

  useOnSuccess('updateCurrentPslfForm', navigation.next);

  const handleSave = ({ employerHoursPerWeek, multipleEmployers }) => {
    const valuesToSave = {
      formId,
      employerHoursPerWeek,
      multipleEmployers,
    };

    track('Set pslf employer hours', {
      currentPslfEmployerIsFullTime: employerHoursPerWeek >= 30,
      currentPslfEmployerHoursPerWeek: employerHoursPerWeek,
    });

    updateCurrentPslfForm({ form: valuesToSave });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ employerHoursPerWeek, multipleEmployers }}
      onSubmit={handleSave}
      validationSchema={employerHoursSchema}
    >
      {formik => {
        const { values, handleSubmit, touched, submitCount } = formik;
        const syncIsValid = employerHoursSchema.isValidSync(values);

        const showMultipleEmployersQuestion = () => {
          /* Logic here is to only display multiple employers
           * question after a user is finished typing number
           * in the first field.
           */
          if (isNil(employerHoursPerWeek) || employerHoursPerWeek === '') {
            return (
              touched.employerHoursPerWeek &&
              values.employerHoursPerWeek !== '' &&
              !isNil(values.employerHoursPerWeek) &&
              values.employerHoursPerWeek < 30
            );
          }
          return values.employerHoursPerWeek < 30;
        };

        const qualifiesFullTime = values.employerHoursPerWeek >= 30;
        const qualifiesPartTime = values.multipleEmployers === 'true';
        const doesNotQualify = values.multipleEmployers === 'false';

        return (
          <Content navigation={navigation}>
            <form onSubmit={handleSubmit}>
              <Container>
                <HoursPerWeekQuestion formik={formik} />
                {qualifiesFullTime && <QualifiesFullTimeAlert />}
                {showMultipleEmployersQuestion() && (
                  <MultipleEmployersQuestion formik={formik} />
                )}
                {qualifiesPartTime && <QualifiesPartTimeAlert />}
                {doesNotQualify && <DoesNotQualifyAlert />}
              </Container>
              <Footer
                handleBack={navigation.back}
                isValid={syncIsValid}
                submitCount={submitCount}
              />
            </form>
          </Content>
        );
      }}
    </Formik>
  );
};

const Container = styled.div`
  max-width: 464px;
  margin: auto;
`;

const mapStateToProps = state => ({
  currentPslfForm: getUserCurrentPslfForm(state),
});

export default connect(mapStateToProps, {
  updateCurrentPslfForm,
})(EmployerHours);
