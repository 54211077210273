import React from 'react';
import styled from 'styled-components';

import { Header, Text } from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import ModalPopup from 'components/shared/ModalPopup';

const LoanTypesModal = ({ children, platformType }) => {
  const isMobile = platformType === MOBILE;
  return (
    <ModalPopup
      width={770}
      kind="loan-types"
      component={() => (
        <Container>
          <HeaderContainer>
            <Header as={isMobile ? 'h3' : 'h1'}>
              How do I know what type of loans I have?
            </Header>
          </HeaderContainer>
          <Text as="p" paragraph>
            Federal loans are issued or guaranteed by the US government. In
            order to have received a federal loan, you must have submitted a
            FAFSA form. If you have federal loans, your loan servicer might be
            FedLoan Servicing, Navient, Nelnet, CornerStone, Great Lakes,
            Granite State, Edfinancial, Mohela, OSLA, Aidvantage, or ECSI.
          </Text>
          <Text as="p" paragraph>
            Private loans are made by a lender such as a bank or credit union.
            SoFi, Wells Fargo, and Sallie Mae are some private loan lenders.
          </Text>
        </Container>
      )}
    >
      {children}
    </ModalPopup>
  );
};

const Container = styled.div`
  p {
    margin-bottom: 20px;
  }

  h5 {
    margin-top: 24px;
    margin-bottom: 8px;
  }
`;

const HeaderContainer = styled.div`
  margin-bottom: 24px;
`;

export default Platform(LoanTypesModal);
