import React, { Component } from 'react';

import { SelectInput } from '@summer/ui-components';

import {
  Label,
  Question,
  SmallTextInputContainer,
} from 'components/pages/idr/wizard/shared/styles';
import { States } from 'constants/states';
import { validateString } from 'utils/validate';

class State extends Component {
  componentWillUnmount() {
    this.props.formik.setFieldValue(`tellUsAboutYou.state`, '');
  }

  render() {
    const {
      formik: {
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      },
    } = this.props;
    return (
      <Question id="state">
        <Label>What state do you live in?</Label>
        <SmallTextInputContainer>
          <SelectInput
            name="tellUsAboutYou.state"
            touched={touched.state}
            isSubmitting={isSubmitting}
            error={errors.state}
            options={States}
            value={values.state}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            placeholder="Type or scroll to select"
            validate={validateString()}
          />
        </SmallTextInputContainer>
      </Question>
    );
  }
}

export default State;
