import React from 'react';

import DocumentsDisplay from 'components/pages/retirementMatch/dashboard/Documents/DocumentsDisplay';
import EmptyDocumentsDisplay from 'components/pages/retirementMatch/dashboard/Documents/EmptyDocumentsDisplay';

const Documents = ({ retirementMatchDocuments }) => {
  const numDocuments = retirementMatchDocuments.length;

  return numDocuments === 0 ? (
    <EmptyDocumentsDisplay />
  ) : (
    <DocumentsDisplay retirementMatchDocuments={retirementMatchDocuments} />
  );
};

export default Documents;
