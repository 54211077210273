import { isNaN } from 'lodash';
import { string, object, number, array, date, ref, boolean } from 'yup';

const courseRequestFields = {
  courseProvider: string()
    .nullable()
    .trim()
    .max('255', 'Please enter a shorter provider name')
    .required('Please enter your course provider'),
  courseName: string()
    .nullable()
    .trim()
    .max('255', 'Please enter a shorter course name')
    .required('Please enter your course name'),
  category: string().trim().required('Please enter the education category'),
  courseStartDate: date()
    .nullable()
    .required('Please enter the start date')
    .typeError('Please enter the start date')
    .min(new Date(1990, 1, 1), 'Please enter a valid date'),
  courseEndDate: date()
    .nullable()
    .required('Please enter the end date')
    .typeError('Please enter the end date')
    .min(ref('courseStartDate'), 'End date must be after start date'),
  courseCost: number()
    .transform(value => (isNaN(value) ? undefined : value))
    .nullable()
    .max(10_000_000_00, 'Please enter a lower number')
    .min(1, 'Please enter an amount no less than $1')
    .required('Please enter your course cost'),
  attachmentFiles: array().min(1, 'Please upload at least one attachment'),
  additionalInformation: string()
    .nullable()
    .trim()
    .min(1, 'Please provide some additional information about the course.')
    .max(1440, 'Too long, please write a shorter entry'),
  isPolicyAccepted: boolean()
    .required(`Please review your employer\u2019s policy before proceeding`)
    .oneOf(
      [true],
      `Please review your employer\u2019s policy before proceeding`,
    ),
};

const courseCompletionFields = {
  paymentProofFiles: array().min(
    1,
    'Please upload at least one proof of payment file',
  ),
  completionProofFiles: array().min(
    1,
    'Please upload at least one proof of completion file',
  ),
};

export const courseRequestSchema = object().shape(courseRequestFields);
export const courseCompletionSchema = object().shape(courseCompletionFields);
