import {
  NETWORK_SUCCESS,
  FETCH_SLC_USER,
  CREATE_SLC_USER,
} from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import { fetchSlcUser, setSlcUser } from 'redux/actions/slc.actions';
import handleSideEffects from 'redux/handleSideEffects';

const slcMiddleware = handleSideEffects({
  [FETCH_SLC_USER]: (dispatch, { meta }) => {
    dispatch(
      apiRequest({
        url: '/v1/slc/user',
        method: 'GET',
        fromAction: FETCH_SLC_USER,
        label: 'fetchSlcUser',
        meta,
      }),
    );
  },

  [CREATE_SLC_USER]: (dispatch, { onSuccess, payload }) => {
    dispatch(
      apiRequest({
        url: '/v1/slc/user',
        method: 'POST',
        fromAction: CREATE_SLC_USER,
        label: 'createSlcUser',
        meta: { onSuccess },
        body: payload,
      }),
    );
  },

  [NETWORK_SUCCESS]: (dispatch, { payload, meta }) => {
    if (meta.fromAction === CREATE_SLC_USER) {
      dispatch(fetchSlcUser());
    }

    if (meta.fromAction === FETCH_SLC_USER) {
      const { enrolled } = payload.response.data;
      dispatch(setSlcUser({ enrolled }));
    }
  },
});

export default slcMiddleware;
