import { SET_LOANS, SET_LOGOUT, SET_LOAN } from 'constants/actionTypes';

const initialState = [];

const loansReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOANS: {
      return payload;
    }

    case SET_LOAN: {
      return state.map(loan => {
        if (loan.id !== payload.id) {
          return loan;
        }
        return {
          ...loan,
          ...payload,
        };
      });
    }

    case SET_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default loansReducer;
