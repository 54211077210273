import React from 'react';

import { TextInput } from '@summer/ui-components';

import { Row } from 'components/pages/idr/wizard/shared/styles';
import { InputWrapper } from 'components/pages/idr/wizard/steps/Submit/shared/Inputs';

export const employerNameHelpMessage =
  'If you are not employed, enter N/A for the name of your business and enter your home address and personal telephone number.';

const EmployerName = ({
  formik: { values, errors, handleChange, handleBlur, touched, isSubmitting },
}) => (
  <Row>
    <InputWrapper>
      <TextInput
        label="Employer Name"
        name="submit.employerName"
        touched={touched.employerName}
        isSubmitting={isSubmitting}
        error={errors.employerName}
        value={values.employerName}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Employer Name"
        helpMessage={employerNameHelpMessage}
      />
    </InputWrapper>
  </Row>
);

export default EmployerName;
