import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Text, FlexRow, COLORS } from '@summer/ui-components';

import { CollapseSidebarSize, MobileSize } from 'constants/styleguide';
import { setSidebarVisibility } from 'redux/actions/ui.actions';
import { isSidebarVisible } from 'redux/selectors/ui.selectors';

class MobileContentHeader extends React.Component {
  toggleSidebar = () => {
    this.props.setSidebarVisibility(!this.props.sideBarVisible);
  };

  render() {
    return (
      <Container>
        <MenuIcon>
          <div
            className={`hamburger hamburger--squeeze ${
              this.props.sideBarVisible && 'is-active'
            }`}
            onClick={this.toggleSidebar}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </div>
        </MenuIcon>
        <Title>
          <Text as="h2" small bold center>
            {this.props.children}
          </Text>
        </Title>
      </Container>
    );
  }
}

const Container = styled(FlexRow)`
  display: none;

  @media (max-width: ${CollapseSidebarSize}) {
    display: flex;

    align-items: center;
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    border-bottom: 1px solid ${COLORS.grey};
    background-color: white;
    z-index: 1;
    height: auto;

    transition: left 0.3s ease-out;
  }
`;

const Title = styled.div`
  display: none;
  margin: 18px 56px;
  flex-grow: 1;

  @media (max-width: ${CollapseSidebarSize}) {
    display: block;
  }

  @media (max-width: ${MobileSize}) {
    display: block;
  }
`;

const MenuIcon = styled.div`
  // giving container height the same height of the hamburger so it will center corectly to title
  position: absolute;
  top: 50%;
  left: 0;
  display: none;
  height: 24px;
  margin: 0 20px;
  transform: translate(0, -50%);

  & .hamburger {
    padding: 0;
    height: 24px;
  }

  & .hamburger-box {
    width: 30px;
    height: 24px;
  }

  & .hamburger-inner,
  .hamburger-inner:before,
  .hamburger-inner:after,
  .is-active .hamburger-inner,
  .is-active .hamburger-inner:after,
  .is-active .hamburger-inner:before {
    background-color: ${COLORS.azure};
    width: 30px;
    height: 3px;
    border-radius: 3px;
  }

  @media (max-width: ${CollapseSidebarSize}) {
    display: block;
  }
`;

const mapStateToProps = state => ({
  sideBarVisible: isSidebarVisible(state),
});

export default connect(mapStateToProps, { setSidebarVisibility })(
  MobileContentHeader,
);
