import React from 'react';
import styled from 'styled-components';

import { Header, Text, FlexRow, COLORS } from '@summer/ui-components';

import { Link } from 'components/shared/typography';
import { dollars } from 'utils/numbers';

const ScholarshipCard = ({ uid, data: { name, description, upToAmount } }) => {
  const shortDescription = description?.[0]?.text;

  return (
    <Link key={uid} to={`/college-savings-planner/scholarship-database/${uid}`}>
      <ScholarshipPreview>
        <Header h3>{name}</Header>
        <ScholarshipFlexRow>
          <Text small paragraph>
            {shortDescription}
          </Text>
          <Amount color="summer" bold right>
            {upToAmount && `Up to ${dollars(upToAmount)}`}
          </Amount>
        </ScholarshipFlexRow>
      </ScholarshipPreview>
    </Link>
  );
};

const ScholarshipPreview = styled.div`
  padding: 32px;
  border-radius: 3px;
  border: solid 1px ${COLORS.grey};
  background-color: ${COLORS.white};

  & > h3 {
    margin-bottom: 8px;
  }
`;

const ScholarshipFlexRow = styled(FlexRow)`
  align-items: flex-end;
`;

const Amount = styled(Text)`
  min-width: 120px;
  margin-left: 56px;
`;

export default ScholarshipCard;
