import React from 'react';
import styled from 'styled-components';

import { FlexCenter } from '../Flex';
import Icon, { CheckSmall, Close } from '../Icon';
import { hexToRgba } from '../../helpers/utils/common';
import { COLORS } from '../../helpers/constants/styleguide';

import Text from '../typography/Text';

const BooleanIcon = ({ value, size }) => {
  let content;
  const color = value ? COLORS.jade : COLORS.darkerGrey;

  const sizeMap = {
    large: { check: 14, cross: 13, ovalDiameter: 40 },
    small: { check: 10, cross: 10, ovalDiameter: 26 },
  };

  const sizeValues = sizeMap[size];

  if (value === true) {
    content = (
      <Icon width={sizeValues.check} SvgComponent={CheckSmall} stroke={color} />
    );
  } else if (value === false) {
    content = (
      <FalseIndicator size={size}>
        <Icon width={sizeValues.cross} SvgComponent={Close} fill={color} />
      </FalseIndicator>
    );
  } else {
    content = (
      <Text bold size={size}>
        ?
      </Text>
    );
  }

  return (
    <Oval color={color} diameter={sizeValues.ovalDiameter}>
      {content}
    </Oval>
  );
};

const FalseIndicator = styled.div`
  margin-left: ${({ size }) => (size === 'small' ? 0 : 1)}px;
`;

const Oval = styled(FlexCenter)`
  border-radius: 50%;
  width: ${({ diameter }) => diameter}px;
  height: ${({ diameter }) => diameter}px;
  background-color: ${({ color }) => hexToRgba(color, '0.08')};
  text-align: center;
`;

export default BooleanIcon;
