import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import LoanEligibilityAlert from 'components/common/ManageLoans/alerts/LoanEligibilityAlert';
import LoanMissingInfoAlert from 'components/common/ManageLoans/alerts/LoanMissingInfoAlert';
import { programTypes } from 'constants/manageLoans';
import {
  getCurrentLoan,
  getLoanFormProgramType,
} from 'redux/selectors/loans.selectors';

const ManageLoansAlert = ({
  isMissingInfo,
  programType,
  loan,
  alertDismissed,
}) => {
  if (isMissingInfo) {
    return <LoanMissingInfoAlert alertDismissed={alertDismissed} />;
  }

  if (programType === programTypes.pslf) {
    return <LoanEligibilityAlert isPslfEligible={get(loan, 'pslfEligible')} />;
  }

  return null;
};

const mapStateToProps = state => ({
  loan: getCurrentLoan(state),
  programType: getLoanFormProgramType(state),
});

export default connect(mapStateToProps)(ManageLoansAlert);
