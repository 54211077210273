import { SET_USER } from 'constants/actionTypes';

const flagsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_USER: {
      return payload.user.flags ?? {};
    }
    default:
      return state;
  }
};

export default flagsReducer;
