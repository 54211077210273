import React from 'react';

import { Text, FlexRow } from '@summer/ui-components';

import InfoTooltip from 'components/shared/InfoTooltip';

export const HeaderCell = ({ label, tipText, width }) => (
  <FlexRow>
    <Text size="small" weight="medium">
      {label}
    </Text>
    <InfoTooltip size={14} top={2} width={width} text={tipText} />
  </FlexRow>
);
